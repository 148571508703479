/**
 * FileValidationErrors : this is a object that will hold definations
 * for error / messages that could be generated via seperate functions
 * if we need to show a message or error we can use this to define it centrally
 *
 */
export const FileValidationErrors = {
  /**
   * SMALL_FILE : if file size is smaller than expected file size or zero
   * or less than zero
   */
  SMALL_FILE: { message: 'file size less than expected' },

  /**
   * LARGE_FILE: if file size is larger than expected
   */
  LARGE_FILE: { message: 'file size bigger than expected' },

  /**
   * CORRECT_FILE_SIZE: if file size is correct
   */
  CORRECT_FILE_SIZE: { message: 'file size is within bounds' },

  /**
   * ERROR_IN_FILE_SIZE_COMPARING: if there are unknown error in file
   * size comparison
   */
  ERROR_IN_FILE_SIZE_COMPARING: {
    message: 'file has error in comparing, please provide all arguments',
  },

  /**
   * CORRECT_EXTENSION: if extension required by component is correct
   */
  CORRECT_EXTENSION: { message: 'file has correct extension' },

  /**
   * INCORRECT_EXTENSION: if file extension is incorrect
   */
  INCORRECT_EXTENSION: { message: 'file has incorrect extension' },

  /**
   * ERROR_IN_EXTENSION_CHECK: if file extension check encounter
   * some error which is not defined
   */
  ERROR_IN_EXTENSION_CHECK: {
    message:
      'file extension comparison failed, please provide all arguments to the function',
  },

  /**
   * CORRECT_HEADER: if file uploaded have correct headers
   */
  CORRECT_HEADER: { message: 'file has correct headers' },

  /**
   * CORRECT_HEADER: if file uploaded have incorrect headers
   */
  INCORRECT_HEADER: { message: 'file has incorrect headers' },

  /**
   * CORRECT_HEADER: if file upload header check can not be processed
   */
  ERROR_IN_HEADER_CHECK: {
    message:
      'file header check failed, please provide all argument to function',
  },

  /**
   * DATA_GENERATED_SUCESSFULLY: if data was generated successfully
   */
  DATA_GENERATED_SUCESSFULLY: { message: 'data is generated succesfully' },

  /**
   * DATA_GENERATED_UNSUCESSFULLY: if data was not generated successfully and
   * there were some error encountered
   */
  DATA_GENERATED_UNSUCESSFULLY: {
    message: 'data is not generated succesfully, due to some unknown errors',
  },
}

// FIXME: We need a better strategy for handling http errors
export const httpResponseErrors = {
  500: 'Internal Server Error',
  401: 'Unauthorized',
  404: 'Not Found',
  400: 'Bad Request',
}
