import Gradient from 'javascript-color-gradient'

export const colorThemes = {
  numeric: ['#bddf26', '#29af7f', '#2e6f8e', '#482173'], // Viridis
  nonNumeric: [
    '#ff0000',
    '#ffa500',
    '#ffff00',
    '#008000',
    '#0000ff',
    '#4b0082',
    '#ee82ee',
  ], // rainbow
}

export default (type: string, numberOfElements: number): Gradient => {
  return new Gradient()
    .setGradient(...colorThemes[type])
    .setMidpoint(numberOfElements)
}
