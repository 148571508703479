import React, { useState, MouseEvent, useEffect } from 'react'
import {
  fade,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  Button,
  IconButton,
  ClickAwayListener,
  List,
  ListItem,
  Popover,
} from '@material-ui/core'
import { ChevronUp, ChevronDown } from 'react-feather'
import clsx from 'clsx'
import { Palette } from '@material-ui/core/styles/createPalette'
import useClickedElement from 'shared/hooks/useClickedElement'
import StyledChip from './StyledChip'

export interface Items {
  /** all dropdown items have id which uniquely identify them */
  id: string
  /** all items have label to display as text */
  label: string
  /** description */
  description: string
  /** items can have types or can have blank array [] */
  chips: Array<Chip>
}

interface Chip {
  /** chip has a label which shows as text */
  label: string
  /** chip have a name which determines its color */
  name: string
}

interface DashboardListItemProps {
  /** id of individual item in dropdown */
  id: string
  /** label to show the item as */
  label: string
  /** chips in an array of `{ name, label }` where the name determines the color */
  chips: Array<Chip>
  /** determines if the item is selected or not */
  isSelected: boolean
  /** event handdler on item click */
  onItemSelect: (e: MouseEvent<any>, id: string) => void
}

interface CustomDropdownProps {
  /** List of items to show inside dropdown */
  items: Array<Items> | Items
  /** id of item to be select as default on first page load */
  selectedItemId?: string
  /** event handler triggered on item select */
  onItemSelect?: (e: MouseEvent<any>, id: string) => void
  /** event hanlder used on button click on bottom of dropdown */
  onDashboardCreate?: (e: MouseEvent<any>) => void
}

const useListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '4px',
      '&:hover': {
        color: theme.palette.primary.light,
        backgroundColor: fade(theme.palette.info.main, 0.1),
        cursor: 'pointer',
      },
      '&:focus': {
        color: theme.palette.primary.light,
        backgroundColor: fade(theme.palette.info.main, 0.1),
      },
    },
    label: {
      margin: theme.spacing(0, 0, 0.5, 0),
      'text-transform': 'capitalize',
    },
    selected: {
      color: theme.palette.primary.light,
      //   There's a bug with MaterialUI where backgroundColor is not being replaced unless it is tagged with !important. No big deal but just FYI
      backgroundColor: `${fade(theme.palette.info.main, 0.1)} !important`,
    },
  }),
)

/**
 * DropDownListItem gives developer a way to render list items which can have certain style
 */
const DropDownListItem = ({
  id,
  label,
  chips,
  isSelected,
  onItemSelect,
}: DashboardListItemProps) => {
  const classes = useListItemStyles()
  return (
    <ListItem
      className={clsx(classes.root, isSelected && classes.selected)}
      key={id}
      onClick={(e) => onItemSelect(e, id)}
    >
      <div>
        <Typography
          variant="subtitle2"
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
        {chips.map((type) => (
          <StyledChip
            key={type.name}
            size="small"
            label={type.label}
            color={type.name as keyof Palette}
          />
        ))}
      </div>
    </ListItem>
  )
}

const useDropdownStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
    },
    dropDown: {
      width: '350px',
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.08)',
    },
    dropdownInnerHeader: {
      padding: theme.spacing(2, 2, 0),
    },
    list: { overflow: 'auto', height: '220px' },
    newDashboardButton: {
      width: '100%',
      height: '50px',
      color: theme.palette.info.main,
    },
    heading: {
      textTransform: 'capitalize',
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.text.primary,
    },
    chipDesc: {
      marginTop: theme.spacing(2),
    },
  }),
)

/**
 * Custom Dropdown allows developers to make a dropdown with a selected text and a dropdown option along with it
 *
 *
 * ```javascript
 * Array<Items> = {
 *   id: string // Unique for all the different dashboards
 *   label: string // Title to display in list item
 *   chips: array // have information about the chips like label for chip and name of chip which gives color to chip
 * }
 *
 * selectedItemId : string // Gives the heading to the dropdown , meaning the text besides the down arrow , it detemines the item which should be selected in the dropdown list, and also show the same item as the heading of dropdown
 *
 * onItemSelect: EventHandler // helps in passing the select item id to the parent component which can be used later to do other stuff
 *
 * onDashboardCreate: EventHandler // helps in performing actions on button click in botton onf dropdown
 * ```
 *
 * Items is a array of items , which can be of any type but should maintain basic structure of having id , label and an array of types which can be blank , passing selectedItemId helps in setting initial selected item as the page loads
 */

const DashboardDropdown = ({
  items,
  selectedItemId,
  onItemSelect,
  onDashboardCreate,
}: CustomDropdownProps) => {
  const [selectedItem, setSelectedItem] = useState<Items | null>(() =>
    Array.isArray(items)
      ? items.filter((item) => item.id === selectedItemId)[0] || null
      : items,
  )
  const classes = useDropdownStyles()

  const [anchorEl, handleMenuOpen, handleMenuClose] = useClickedElement()

  useEffect(() => {
    if (selectedItemId) {
      setSelectedItem(() =>
        Array.isArray(items)
          ? items.filter((item) => item.id === selectedItemId)[0]
          : items,
      )
    }
  }, [selectedItemId, items])

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <div className={classes.root}>
        <Typography variant="h3" component="div" className={classes.heading}>
          {selectedItem?.label || 'NO DASHBOARD SELECTED'}

          <IconButton
            aria-label="toggle-view-dashboards"
            title="toggle view dashboards"
            onClick={handleMenuOpen}
          >
            {anchorEl ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </Typography>

        <Typography variant="body2" component="div">
          {selectedItem?.description || 'NO DESCRIPTION PROVIDED'}
        </Typography>

        <div className={classes.chipDesc}>
          {selectedItem?.chips.map((type) => (
            <StyledChip
              key={type.name}
              size="small"
              label={type.label}
              color={type.name as keyof Palette}
            />
          ))}
        </div>
        {onItemSelect && Array.isArray(items) && (
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={clsx(classes.dropDown)}>
              <Typography
                variant="caption"
                component="div"
                className={classes.dropdownInnerHeader}
                color="textSecondary"
              >
                YOUR DASHBOARDS
              </Typography>
              <List className={classes.list}>
                {items.map(({ id, label, chips }) => (
                  <DropDownListItem
                    key={id}
                    id={id}
                    label={label}
                    chips={chips}
                    isSelected={id === selectedItemId}
                    onItemSelect={(e, itemId) => {
                      handleMenuClose()
                      onItemSelect(e, itemId)
                    }}
                  />
                ))}
              </List>
              <Divider />
              <Button
                disableElevation
                className={classes.newDashboardButton}
                onClick={onDashboardCreate}
              >
                Create New Dashboard
              </Button>
            </div>
          </Popover>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default DashboardDropdown
