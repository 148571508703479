import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import axiosApiClient from 'shared/apiClient'
import { isCalculation, isGroup } from 'shared/helpers'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import {
  AdjustmentCreateMeta,
  AdjustmentForecast,
  AdjustmentsMeta,
  BackendAdjustment,
  Calculation,
  DataSeriesSplitElement,
  Group,
  SelectableValue,
  SpatialFilter,
  Visualization,
  WeatherTimeSeries,
} from 'shared/types'
import { AxiosRequestConfig } from 'axios'
import {
  AvailableForecastItem,
  ForecastType,
} from '../products/loadForecast/components/LoadForecastTypes'

export enum MapeTypes {
  FORECAST_MAPE = 'forecast',
  BACKCAST_MAPE = 'backcast',
}

enum TimeParts {
  HourOfDay = 'HOUR_OF_DAY',
  DayOfWeek = 'DAY_OF_WEEK',
  MonthOfYear = 'MONTH_OF_YEAR',
  Year = 'YEAR',
}

type TimePartFilter = { [key in TimeParts]?: Array<number> }

interface TimeFilter {
  columnName: string
  dateRange: {
    fromDate: string
    toDate: string
  }
  timePartFilter?: TimePartFilter
}

export interface AvailableShortTermForecastsQueryResult {
  market: string
  forecast: number
  process_time: string
  timestamp: string
}

// TODO: Move out of this module
export interface QueryColumn {
  name: string
  aggregation: Calculation | string
}

export interface FilterColumn {
  name: string
  values: Array<SelectableValue>
}

export interface Filters {
  columns?: FilterColumn[]
  dateRange: {
    from: string
    to: string
  }
}

interface SingleGrouping {
  direction?: 'ascending' | 'descending'
  groupType: Group | string
}

export type Grouping = Array<SingleGrouping>

function buildTimePartFilter(
  group: Group,
  values: Array<any>,
): TimePartFilter | null {
  switch (group) {
    case Group.Hour24: {
      return {
        [TimeParts.HourOfDay]: values.map((x) => x),
      }
    }
    /* Values for day of week are 0-based, but druid understands them as 1-based */
    case Group.DayOfWeek: {
      return {
        [TimeParts.DayOfWeek]: values.map((x) => x + 1),
      }
    }
    case Group.Month12: {
      return {
        [TimeParts.MonthOfYear]: values,
      }
    }
    case Group.Yearly: {
      return {
        [TimeParts.Year]: values,
      }
    }
    default:
      return null
  }
}

/**
 * Build filters for druid
 * The filters could have a special weekday/weekend filter that is treated differently
 * by druid. We must send that type of filter as part of the timefilter.
 * Thus, we go through all filters and build our filterColumns (normal filters)
 * and then add special time filter if it's there.
 */
function buildDruidFilteringFields(
  appliedFilters: Filters,
  appliedRangeFilters?: RangeFilter[],
): {
  filters: Array<object>
  timeFilter: TimeFilter
  rangeFilters: Array<object> | undefined
} {
  /* Druid is excluding the superior limit values on its results, so it has to be incremented to return them.
  The format of the dates is well known, so ther shouldn't be problems */
  const timeFilter: TimeFilter = {
    columnName: '__time',
    dateRange: {
      fromDate: appliedFilters.dateRange.from,
      toDate: DateTime.fromISO(appliedFilters.dateRange.to)
        .plus({ days: 1 })
        .toISODate(),
    },
  }
  const filters: Array<object> = []
  if (appliedFilters.columns) {
    appliedFilters.columns.forEach((filter) => {
      if (isGroup(filter.name)) {
        const timePartFilter = buildTimePartFilter(filter.name, filter.values)
        if (timePartFilter) {
          timeFilter.timePartFilter = {
            ...timeFilter.timePartFilter,
            ...timePartFilter,
          }
        }
      } else {
        filters.push({
          columnName: filter.name,
          filterType: 'Include',
          filterValues: filter.values,
        })
      }
    })
  }

  const rangeFilters = appliedRangeFilters?.map((filter) => ({
    columnName: filter.name,
    filterType: 'Include',
    filterMinValue: filter.value[0],
    filterMaxValue: filter.value[1],
  }))

  return { timeFilter, filters, rangeFilters }
}

function buildDruidSelectedMeasures(columns: Array<QueryColumn>) {
  return columns.map(({ name, aggregation }) => {
    if (!isCalculation(aggregation)) {
      return {
        preferredColumnName: name,
        customAggregation: aggregation,
      }
    }
    return {
      columnName: name,
      aggregation,
      preferredColumnName: name,
    }
  })
}

interface DruidSplit {
  columnName: string
  sortType: 'dimension'
  direction: string
  selectedGranularity?: string
  timePart?: string
}

function buildDruidSplits(groupings: Grouping, splitIdentifier?: string) {
  const splits: Array<DruidSplit> = []

  if (splitIdentifier) {
    splits.push({
      columnName: splitIdentifier,
      sortType: 'dimension',
      direction: 'ascending',
    })
  }

  groupings.forEach(({ direction, groupType }) => {
    const split: DruidSplit = {
      columnName: '__time',
      sortType: 'dimension',
      direction: direction || 'ascending',
    }
    switch (groupType) {
      case Group.Yearly:
        split.selectedGranularity = 'P1Y'
        break
      case Group.Monthly:
        split.selectedGranularity = 'P1M'
        break
      case Group.Daily:
        split.selectedGranularity = 'P1D'
        break
      case Group.Hourly:
        split.selectedGranularity = 'PT1H'
        break
      case Group.QuaterHourly:
        split.selectedGranularity = 'PT15M'
        break
      case Group.HalfHourly:
        split.selectedGranularity = 'PT30M'
        break
      case Group.Month12:
        split.timePart = 'MONTH_OF_YEAR'
        break
      case Group.DayOfWeek:
        split.timePart = 'DAY_OF_WEEK'
        break
      case Group.Hour24:
        split.timePart = 'HOUR_OF_DAY'
        break
      default:
        split.columnName = groupType
        break
    }

    splits.push(split)
  })

  return splits
}

enum DruidResultTypes {
  Number = 'NUMBER',
  String = 'STRING',
  TimeRange = 'TIME_RANGE',
}

function getGroupingValueFromDruidDataObject(
  druidDataObject: any,
  accessor: string,
  dataType: DruidResultTypes,
) {
  if (dataType !== DruidResultTypes.TimeRange) {
    return druidDataObject[accessor]
  }
  return druidDataObject[accessor].start
}

function normalizeColumnsData(
  columnsMapping: Array<[string, string]>,
  druidDataObject: any,
): { [index: string]: any } {
  const data: { [index: string]: any } = {}

  columnsMapping.forEach(([druidColumn, column]) => {
    data[column] = druidDataObject[druidColumn]
  })

  return data
}

export function buildDataSeriesDataOutput(
  druidResponse: any,
  columns: Array<QueryColumn>,
  grouping: Grouping,
  splitIdentifier?: string,
): Array<DataSeriesSplitElement> {
  const columnsMapping = columns.map<[string, string]>(
    ({ name, aggregation }) => {
      const druidColumn = isCalculation(aggregation)
        ? `${aggregation}_${name}`
        : name
      return [druidColumn, name]
    },
  )

  const splits: Array<DataSeriesSplitElement> = []

  const isDataSplit = druidResponse.result.attributes.find(
    ({ name }) => name === 'SPLIT',
  )

  if (!isDataSplit || !splitIdentifier) {
    const mainGroupedData = normalizeColumnsData(
      columnsMapping,
      druidResponse.result.data[0],
    )

    splits.push({
      splitValue: null,
      groupedData: mainGroupedData,
      seriesData: !isDataSplit ? [mainGroupedData] : [],
    })

    if (!isDataSplit) {
      return splits
    }
  }

  const firstSplit = druidResponse.result.data[0].SPLIT

  /* If there is no data coming back from druid this error is thrown
   */
  if (firstSplit.data && firstSplit.data.length <= 0) {
    throw new Error(`No data found for this dataseries`)
  }

  if (splitIdentifier) {
    firstSplit.data.forEach((item) => {
      const splitValue = item[splitIdentifier]
      splits.push({
        splitValue,
        groupedData: normalizeColumnsData(columnsMapping, item),
        seriesData: [],
      })
    })
  }

  const splitElementsMap = new Map<any, DataSeriesSplitElement>(
    splits.map((splitSeries) => [splitSeries.splitValue, splitSeries]),
  )

  const groupingKeys = [
    ...(splitIdentifier ? [splitIdentifier] : []),
    ...grouping.map(({ groupType }) => groupType),
  ]

  /* The druid response object will be explored as a graph following the BFS approach (https://en.wikipedia.org/wiki/Breadth-first_search).
  New items will be added to the proper split series as "leaf"
  nodes are found.
   */
  const nodesQueue: Array<{
    item: any
    acc: object
    groupIndex: number
  }> = [
    {
      item: firstSplit,
      acc: {},
      groupIndex: 0,
    },
  ]

  /* The process will continue while there are nodes to be
  processed */
  for (
    let currentItem = nodesQueue.shift();
    currentItem;
    currentItem = nodesQueue.shift()
  ) {
    const { item, acc, groupIndex } = currentItem
    const [splitKey] = item.keys as Array<string>
    const splitKeyType = item.attributes.find(({ name }) => name === splitKey)
      ?.type as DruidResultTypes
    const groupKey = groupingKeys[groupIndex] || splitKey

    /* if there are no more splits, push in the results array the values along with the accumulated data for this item */
    if (item.attributes.find(({ name }) => name === 'SPLIT')) {
      /* if there are more splits, create a new accumulated data adding the value for the split key and add to the queue the data objects related to the accumulated data */
      item.data.forEach((splitItem) => {
        nodesQueue.push({
          item: splitItem.SPLIT,
          acc: {
            ...acc,
            [groupKey]: getGroupingValueFromDruidDataObject(
              splitItem,
              splitKey,
              splitKeyType,
            ),
          },
          groupIndex: groupIndex + 1,
        })
      })
    } else {
      item.data.forEach((dataItem) => {
        const seriesDataItem = {
          ...acc,
          ...normalizeColumnsData(columnsMapping, dataItem),
          [groupKey]: getGroupingValueFromDruidDataObject(
            dataItem,
            splitKey,
            splitKeyType,
          ),
        }

        const splitValue = splitIdentifier
          ? seriesDataItem[splitIdentifier]
          : null
        const splitElement = splitElementsMap.get(
          splitValue,
        ) as DataSeriesSplitElement

        splitElement.seriesData.push(seriesDataItem)
      })
    }
  }

  return splits
}

/**
 * Retrieves an object with the full definition of the columns available for
 * a specific datasource on the druid repository.
 * Each key of the retrieved object will be the name of a column and its correspondent
 * value will contain its definition.
 * @param datasource name of the datasource which columns to retrieve.
 */
export async function getDruidDatasourceColumns(
  datasource: string,
): Promise<any> {
  const response = await axiosApiClient.get(
    `/druid/api/v2/datasources/${datasource}`,
  )
  return response.data
}

/**
 * Retrieves an array with all the different values for a specific column in a
 * datasource on the data grouped inside the month prior the "timeContext" parameter
 * TODO: Make the time range parametrizable
 * @param datasource
 * @param columnName
 * @param timeContext timestamp on ISO format
 */
export async function getDruidDatasourceDistinctData(
  datasource: string,
  columnName: string,
  appliedFilters: Filters,
  limit?: number,
  direction?: string,
): Promise<any[]> {
  const { filters, timeFilter } = buildDruidFilteringFields(appliedFilters)

  const requestBody: any = {
    distinctDimension: {
      columnName,
    },
    sortType: 'dimension',
    filters,
    timeFilter,
  }

  if (limit) {
    requestBody.limit = limit
  }
  if (direction) {
    requestBody.direction = direction
  }

  const response = await axiosApiClient.post(
    `/druid/api/v2/datasources/${datasource}/distinct`,
    requestBody,
  )
  try {
    return response.data.result.data[0].SPLIT.data.map((x) => x[columnName])
  } catch {
    throw new Error(`The data retrieved cannot be formatted`)
  }
}

export async function getDruidDistinctAll(
  datasource: string,
  columns: string[],
): Promise<{ [key: string]: string }[]> {
  if (columns.length === 0) {
    return []
  }
  return axiosApiClient
    .post(`/druid/api/v2/datasources/${datasource}/multi-distinct`, {
      dimensions: columns,
    })
    .then((r) => {
      return r.data.result
    })
}

export async function getMinMaxForColumns(
  datasource: string,
  columns: string[],
): Promise<Array<{ minValue: number; maxValue: number; name: string }>> {
  if (columns.length === 0) {
    return []
  }
  try {
    const response = await axiosApiClient.post(
      `/druid/api/v2/datasources/${datasource}/min-max`,
      {
        dimensions: columns,
      },
    )

    return response.data.map((d) => ({
      minValue: Math.floor(d.minValue),
      maxValue: Math.ceil(d.maxValue),
      name: d.name,
    }))
  } catch {
    throw new Error(`Failed to fetch min max for range filters`)
  }
}

/**
 * Retrieves an array with all the different values for a specific column in a
 * @param datasource
 * @param columnName
 * @param appliedFilters filters to apply for column values (if any) and date range
 * @param limit limit the number of result to number provided
 * @param direction denotes order by direction ascending | descending
 */
export const getForecastDataSeriesInfoFromDruid = async (
  datasource: string,
  columnName: string,
  appliedFilters: Filters,
  limit: number = 0,
  direction: string = 'ascending',
): Promise<any[]> => {
  return getDruidDatasourceDistinctData(
    datasource,
    columnName,
    appliedFilters,
    limit,
    direction,
  )
}

export async function getDruidDatasourceMaxTime(
  datasource: string,
  columns: FilterColumn[] = [],
): Promise<string> {
  // TODO: Myabe check that the received data has ISO format
  const filters = columns.map((filter) => ({
    columnName: filter.name,
    filterType: 'Include',
    filterValues: filter.values,
  }))
  const response = await axiosApiClient.post(
    `/druid/api/v2/datasources/${datasource}/max-time`,
    { filters },
  )
  /*
    Druid api excludes the upper limit values on its results, so it is incremented to add inclusivity.
  */
  const result = DateTime.fromISO(response.data.maxTime, {
    zone: 'utc',
  }).toString()
  return result
}

export async function getDruidDatasourceMinTime(
  datasource: string,
  columns: FilterColumn[] = [],
): Promise<string> {
  // TODO: Myabe check that the received data has ISO format
  const filters = columns.map((filter) => ({
    columnName: filter.name,
    filterType: 'Include',
    filterValues: filter.values,
  }))
  const response = await axiosApiClient.post(
    `/druid/api/v2/datasources/${datasource}/min-time`,
    { filters },
  )

  return DateTime.fromISO(response.data.minTime, { zone: 'utc' }).toString()
}

/**
 * Get Druid Data Source time boundary metadata
 * @param datasource: string name of the data source in druid
 */
export async function getDruidDatasourceTimeBoundary(
  datasource: string,
): Promise<{ minISODate: string; maxISODate: string }> {
  const response = await axiosApiClient.get(
    `/druid/api/v2/datasources/${datasource}/time-boundary`,
  )

  const { minTime, maxTime } = response.data

  const minISODate = DateTime.fromISO(minTime, {
    zone: 'utc',
  }).toString()
  const maxISODate = DateTime.fromISO(maxTime, {
    zone: 'utc',
  }).toString()
  return { minISODate, maxISODate }
}

/**
 * Returns the full response data of a query request to a datasource
 * on the druid repository.
 *
 * The query will request the max value for specific columns provided in the
 * arguments on the
 * data a month prior the timeContext date introduced grouped daily.
 */
export async function queryDruidDatasource(
  options: {
    datasource: string
    columns: QueryColumn[]
    appliedFilters: Filters
    grouping: Grouping
    splitIdentifier?: string
    timezone?: string
    appliedRangeFilters?: Array<RangeFilter>
    spatialFilters?: SpatialFilter[]
    limit?: number
    skip?: number
    visualization?: Visualization
  },
  config?: AxiosRequestConfig,
): Promise<Array<DataSeriesSplitElement>> {
  const {
    datasource,
    columns,
    grouping,
    splitIdentifier,
    appliedFilters,
    timezone,
    appliedRangeFilters,
    spatialFilters,
    limit,
    skip,
    visualization,
  } = options

  if (!columns.length && visualization !== Visualization.MeterTable) {
    throw new Error('At least one column value must be requested')
  }

  const splits = buildDruidSplits(grouping, splitIdentifier)

  /**
   * Build filters for druid
   * The filters could have a special weekday/weekend filter that is treated differently
   * by druid. We must send that type of filter as part of the timefilter.
   * Thus, we go through all filters and build our filterColumns (normal filters)
   * and then add special time filter if it's there.
   */
  const { timeFilter, filters, rangeFilters } = buildDruidFilteringFields(
    appliedFilters,
    appliedRangeFilters,
  )

  /**
   * TODO: All queries to druid should be moved to use this new route as soon as we are comfortable with our tests
   */
  if (spatialFilters || visualization === Visualization.MeterTable) {
    const body = {
      selectedMeasures: buildDruidSelectedMeasures(columns),
      filters,
      timeFilter,
      splits,
      timezone,
      rangeFilters,
      queryId: uuidv4(), // tag the query so it can be cancelled by druid
      spatialFilters,
      limit,
      skip,
    }
    const response = await axiosApiClient.post(
      `/druid/api/v2/datasources/${datasource}/query-native`,
      body,
      config,
    )

    const spatialMeasures = body.selectedMeasures.filter((measure) =>
      spatialFilters?.find(
        (filter) => filter.columnName === measure.columnName,
      ),
    )

    // TODO: Need to handle splits - eventually (I don't think we are using grouped data anywhere)
    return [
      {
        groupedData: {},
        splitValue: null,
        seriesData:
          visualization === Visualization.MeterTable
            ? response.data
            : response.data.map((data) => {
                const series = { ...data }
                spatialMeasures.forEach((measure) => {
                  series[measure.preferredColumnName] = data[
                    measure.preferredColumnName
                  ]
                    .split(',')
                    .map((coordinate) => parseFloat(coordinate))
                })
                return series
              }),
      },
    ]
  }

  const response = await axiosApiClient.post(
    `/druid/api/v2/datasources/${datasource}/query`,
    {
      selectedMeasures: buildDruidSelectedMeasures(columns),
      filters,
      timeFilter,
      splits,
      timezone,
      rangeFilters,
      queryId: uuidv4(), // tag the query so it can be cancelled by druid
    },
    config,
  )

  return buildDataSeriesDataOutput(
    response.data,
    columns,
    grouping,
    splitIdentifier,
  )
}

export async function queryDruidDatasourceForMape(
  options: {
    datasource: string
    mapeType: MapeTypes
    appliedFilters: Filters
    grouping: Grouping
    splitIdentifier?: string
    timezone?: string
    appliedRangeFilters?: Array<RangeFilter>
  },
  config?: AxiosRequestConfig,
) {
  const {
    datasource,
    mapeType,
    appliedFilters,
    grouping,
    splitIdentifier,
    timezone,
    appliedRangeFilters,
  } = options
  const columns: Array<QueryColumn> = [
    {
      name: mapeType,
      aggregation: `$main.sum(\${actual}.subtract(\${${mapeType}}).absolute()).divide($main.sum(\${actual}))`,
    },
  ]

  return queryDruidDatasource(
    {
      datasource,
      columns,
      appliedFilters,
      grouping,
      splitIdentifier,
      timezone,
      appliedRangeFilters,
    },
    config,
  )
}

/**
 * Gets weather data from weather api between start and end date where endDate is all inclusive.
 * Meaning all hours specified in the endDate will be included 0-23.
 * Responds with an array of objects that includes utc_datetime which is the utc date offset by the provided timezone
 * @param options
 *  --  station station to request data (must be an official weather station id)
 *  --  startDate day to start request
 *  --  endDate day to end request
 *  --  series type of weather needed (forecast / actual)
 *  --  timezone optional argument to get data from a specific timezone offset. Defaults to utc
 *  -- processedDate - optional date processing
 * @param config: AxiosRequestConfig
 */
export async function getWeather(
  options: {
    station: string
    startDate: string
    endDate: string
    series?: WeatherTimeSeries
    timezone?: string
    forecastId?: string
  },
  config?: AxiosRequestConfig,
) {
  const {
    startDate,
    forecastId,
    endDate,
    station,
    series = WeatherTimeSeries.Forecast,
    timezone = 'utc',
  } = options
  const weatherForecastQueryObject =
    forecastId && DateTime.fromISO(forecastId).toISODate()
      ? {
          weather_stations: station,
          date_field: 'processed_date',
          start_date: `${DateTime.fromISO(forecastId).toISODate()} 01`,
        }
      : {
          weather_stations: station,
          date_field: 'weather_day',
          /**
           * To ensure we always cover timezone offsets we request a day before and after start and end date respectively.
           * This gets filtered from the response.
           */
          start_date: `${DateTime.fromISO(startDate)
            .minus({ days: 1 })
            .toISODate()} 01`,
          end_date: `${DateTime.fromISO(endDate)
            .plus({ days: 1 })
            .toISODate()}`,
        }

  // The normals endpoint only accepts dates in MM-dd format so we need to convert it
  const getWeatherNormalsQueryObject = () => {
    const startDateArr = startDate.split('-')
    const startYear = startDateArr.shift()
    const endDateArr = endDate.split('-')
    const endYear = endDateArr.shift()

    let updatedStartDate = startDateArr.join('-') // MM-DD
    let updatedEndDate = endDateArr.join('-')

    // Create a date range between these 2 if the dates is across 2 years
    if (startYear !== endYear) {
      updatedStartDate = '01-01'
      updatedEndDate = '12-31'
    }

    return {
      start_date: updatedStartDate,
      end_date: updatedEndDate,
    }
  }

  const weatherSeriesObject = (
    weatherTimeSeries: WeatherTimeSeries,
  ): [
    string,
    {
      weather_stations: string
      end_date?: string
      utc_flag?: string
      start_date?: string
      date_field?: string
    },
    string,
  ] => {
    switch (weatherTimeSeries) {
      case WeatherTimeSeries.Actual:
        return [
          `/weather/weather`,
          {
            weather_stations: station,
            start_date: startDate,
            end_date: endDate,
            utc_flag: 'true',
          },
          'adjusted_weather',
        ]
      case WeatherTimeSeries.Normals:
        return [
          `/weather/normals`,
          {
            weather_stations: station,
            ...getWeatherNormalsQueryObject(),
            utc_flag: 'true',
          },
          'normals',
        ]
      default:
        return [
          `/weather/forecast`,
          {
            ...weatherForecastQueryObject,
            utc_flag: 'true',
          },
          'adjusted_forecast',
        ]
    }
  }

  const [url, params, resultsField] = weatherSeriesObject(series)

  return axiosApiClient.get(url, { params, ...config }).then((res) => {
    const startOffset = DateTime.fromISO(startDate, { zone: timezone }).toUTC()
    // we add a day so we can return all hours within requested date, e.g. request end date = 2020-10-22 should include all hours within said day
    const endOffset = DateTime.fromISO(endDate, { zone: timezone })
      .plus({ days: 1 })
      .toUTC()
    const filteredData: Array<object> = []

    // Store the first year that appears in the date range
    const startYear = DateTime.fromISO(startDate).year
    const endYear = DateTime.fromISO(endDate).year

    res.data[resultsField].forEach((data) => {
      if (series === WeatherTimeSeries.Normals) {
        for (let y = startYear; y <= endYear; y += 1) {
          // Construct a new simple date YY-MM-DD using the currentYear
          const newDate = `${y}-${data.weather_day}`
          const utcDateTime = DateTime.fromISO(newDate, {
            zone: 'utc',
          }).set({ hour: data.weather_hour - 1 })
          if (utcDateTime >= startOffset && utcDateTime < endOffset) {
            filteredData.push({ ...data, utc_datetime: utcDateTime.toISO() })
          }
        }
      } else {
        const utcDateTime = DateTime.fromISO(data.weather_day, {
          zone: 'utc',
        }).set({ hour: data.weather_hour - 1 })
        if (utcDateTime >= startOffset && utcDateTime < endOffset) {
          filteredData.push({ ...data, utc_datetime: utcDateTime.toISO() })
        }
      }
    })
    return filteredData
  })
}

export async function getForecastForAdjustments({
  datasource,
  processTime,
  market,
  timezone,
  dateRange,
  /** We technically want to get all columns in the table...
   * since it is assumed the table is already at a settlement level...
   */
  attributeColumns,
}: {
  datasource: string
  processTime: string
  market: string
  timezone: string
  dateRange: { fromDate: string; toDate: string }
  attributeColumns: string[]
}) {
  const filters = [
    {
      columnName: 'process_time',
      filterType: 'Include',
      filterValues: [processTime],
    },
    {
      columnName: 'market',
      filterType: 'Include',
      filterValues: [market],
    },
  ]
  const query = {
    filters,
    timezone,
    selectedMeasures: [
      {
        columnName: 'forecast',
        aggregation: 'sum',
        preferredColumnName: 'forecast',
      },
      ...attributeColumns.map((elem) => ({
        columnName: elem,
        aggregation: 'stringFirst',
        preferredColumnName: elem,
      })),
    ],
    timeFilter: {
      columnName: '__time',
      dateRange,
    },
    splits: [
      {
        columnName: '__time',
        preferredColumnName: 'timestamp',
        sortType: 'dimension',
        direction: 'ascending',
        selectedGranularity: 'PT1H',
      },
    ],
    rangeFilters: [],
  }
  return axiosApiClient
    .post(`/druid/api/v2/datasources/${datasource}/query-native`, query)
    .then((result) => {
      return result.data.map((elem) => {
        const { ...rest } = elem
        return {
          ...rest,
          processTime,
        }
      }) as AdjustmentForecast[]
    })
}

export async function fetchAdjusmentVersions(adjustmentId) {
  const result = await axiosApiClient.get(
    `user/v1/user/adjustments/${adjustmentId}`,
  )
  return result?.data[0] as AdjustmentsMeta | undefined
}

export async function fetchStichedAdjustment(adjustmentId, versionId) {
  const result = await axiosApiClient.get(
    `user/v1/user/adjustments/${adjustmentId}/versions/${versionId}/stitched`,
  )
  return result?.data
}

export async function saveNewAdjustment(
  adjustmentId: string,
  adjustment: BackendAdjustment[],
) {
  const result = await axiosApiClient.post(
    `user/v1/user/adjustments/${adjustmentId}/versions`,
    adjustment,
  )
  return result
}

export async function updateAdjustment(
  adjustmentId: string,
  versionId: string,
  adjustment: BackendAdjustment[],
) {
  const result = await axiosApiClient.put(
    `user/v1/user/adjustments/${adjustmentId}/versions/${versionId}`,
    adjustment,
  )
  return result
}

export async function updateAdjustmentSummary(
  adjustmentId: string,
  summary: AdjustmentsMeta['summary'],
) {
  const result = await axiosApiClient.patch(
    `user/v1/user/adjustments/${adjustmentId}`,
    { summary },
  )
  return result
}

export async function createNewAdjustment(
  adjustmentDetails: AdjustmentCreateMeta,
) {
  const result = await axiosApiClient.post(
    `user/v1/user/adjustments`,
    adjustmentDetails,
  )
  return result.data
}

/**
 * Build Json Body to query database via api
 * @param market ISO
 * @param processDates List Process Times to query
 * @param fromDate Start forecast data
 * @param toDate  End forecast date
 * @param forecastType Type of load forecast [STF, LTF]
 * @param timezone
 * @returns Json Body to query database
 */
function buildQuery(
  market: string,
  processDates: string[],
  fromDate: string,
  toDate: string,
  forecastType: ForecastType,
  timezone: string = DateTime.local().zoneName,
): { [key: string]: any } {
  const filters = [
    {
      columnName: 'market',
      filterType: 'Include',
      filterValues: [market],
    },
    {
      columnName: 'process_time',
      filterType: 'Include',
      filterValues: processDates,
    },
  ]

  const selectedMeasures = [
    {
      columnName: 'forecast',
      aggregation: 'sum',
      preferredColumnName: 'forecast',
    },
  ]

  const timeFilter = {
    columnName: '__time',
    dateRange: {
      fromDate,
      toDate,
    },
  }

  const splits = [
    {
      columnName: '__time',
      preferredColumnName: 'timestamp',
      sortType: 'dimension',
      direction: 'ascending',
      selectedGranularity:
        forecastType === ForecastType.ShortTermForecast ? 'PT1H' : 'P1M',
    },
    {
      columnName: 'market',
      sortType: 'dimension',
      direction: 'ascending',
    },
    {
      columnName: 'process_time',
      sortType: 'dimension',
      direction: 'ascending',
      preferredColumnName: 'processTime',
    },
  ]

  return {
    filters,
    selectedMeasures,
    timeFilter,
    splits,
    timezone,
  }
}

/**
 * Fetch available forecast load forecast
 * @param datasource Druid Datasource
 * @param market ISO
 * @param processDates List Process Times to query
 * @param forecastStartDate
 * @param forecastEndDate
 * @param forecastType Type of load forecast [STF, LTF]
 * @param timezone
 * @returns Available Load Forecast
 */
export async function fetchAvailableForecastItems(
  datasource: string,
  market: string,
  processDates: string[],
  forecastStartDate: string,
  forecastEndDate: string,
  forecastType: ForecastType,
  timezone?: string,
): Promise<AvailableForecastItem[]> {
  if (!processDates.length) {
    return []
  }

  const query = buildQuery(
    market,
    processDates,
    forecastStartDate,
    forecastEndDate,
    forecastType,
    timezone,
  )

  const result = await axiosApiClient.post(
    `/druid/api/v2/datasources/${datasource}/query-native`,
    query,
  )
  return result?.data ?? []
}

export default axiosApiClient
