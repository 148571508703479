import { makeStyles, Menu, MenuList, MenuItem, styled } from '@material-ui/core'
import React, { MouseEventHandler, SyntheticEvent } from 'react'
import useClickedElement from 'shared/hooks/useClickedElement'
import { Play } from 'react-feather'
import { LegendPosition } from 'shared/types'

export interface Props {
  onDelete: MouseEventHandler
  selectedLegendOption: LegendPosition
  onLegendChange: (e: SyntheticEvent, legendPosition: LegendPosition) => void
  onDuplicate: MouseEventHandler
  isOverview: Boolean
  onAddToOverview: MouseEventHandler
  onRemoveFromOverview: MouseEventHandler
  isSummaryView: Boolean
  onSummaryOptionsClick: (e: SyntheticEvent) => void
}

const useMultiLevelDropDownStyles = makeStyles((theme) => ({
  selected: {
    color: theme.palette.primary.light,
    backgroundColor: 'none',
  },
}))

const SeeMore = styled(Play)(({ theme }) => ({
  verticalAlign: 'middle',
  display: 'inline-flex',
  marginRight: theme.spacing(0),
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[500],
  fill: theme.palette.grey[500],
  height: '.5em',
  width: '.5em',
}))

const legendOptions = [
  { value: LegendPosition.Top, label: 'Align Top' },
  { value: LegendPosition.Bottom, label: 'Align Bottom' },
  { value: LegendPosition.Right, label: 'Align Right' },
  { value: LegendPosition.Left, label: 'Align Left' },
  { value: LegendPosition.None, label: 'Align None' },
]

const MultiLevelDropDownMenu = ({
  onDelete,
  onDuplicate,
  selectedLegendOption,
  onLegendChange,
  onAddToOverview,
  onRemoveFromOverview,
  isOverview,
  isSummaryView,
  onSummaryOptionsClick,
}: Props) => {
  const [anchorEl, handleMenuOpen, handleMenuClose] = useClickedElement()
  const classes = useMultiLevelDropDownStyles()

  const open = Boolean(anchorEl)

  return (
    <MenuList>
      <MenuItem key="delete" onClick={onDelete}>
        Delete
      </MenuItem>
      {isOverview ? (
        <MenuItem key="overview-remove" onClick={onRemoveFromOverview}>
          Remove from Overview
        </MenuItem>
      ) : (
        <MenuItem key="overview-add" onClick={onAddToOverview}>
          Add to Overview
        </MenuItem>
      )}
      <MenuItem key="duplicate" onClick={onDuplicate}>
        Duplicate
      </MenuItem>
      <MenuItem onClick={onSummaryOptionsClick}>
        {isSummaryView ? `Options View` : `Summary View`}
      </MenuItem>
      <MenuItem
        key="move"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={open ? handleMenuClose : handleMenuOpen}
      >
        Legend Alignment
        <SeeMore />
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleMenuClose}
          disableRestoreFocus
        >
          {legendOptions.map((option) => {
            return (
              <MenuItem
                key={option.value}
                className={
                  selectedLegendOption === option.value ? classes.selected : ''
                }
                onClick={(e) => onLegendChange(e, option.value)}
              >
                {option.label}
              </MenuItem>
            )
          })}
        </Menu>
      </MenuItem>
    </MenuList>
  )
}

export default MultiLevelDropDownMenu
