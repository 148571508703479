import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { JobTypes } from 'shared/types'

interface Props {
  jobType: JobTypes
}

/* The specifications given on https://projects.invisionapp.com/d/main/default/#/console/19877042/417916632 
do not match the typography or intent colors of the theme (not even the border radius), 
sothese are set directly on this component's style as they are on the design. */
const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    height: '16px',
    lineHeight: '16px',
    width: '69px' /* TODO: Check if this width may be higher or lower */,
    fontSize: '10px',
    borderRadius: '2px',
    textAlign: 'center',
    color: '#fff',
    background: (props: Props) => {
      if (props.jobType === 'calculation') {
        return '#f6ab24'
      }
      if (props.jobType === 'upload') {
        return '#34aa44'
      }
      return '#6758f3'
    },
  },
})

const getJobTypeDisplay = (jobType: JobTypes) => {
  let display
  switch (jobType) {
    case 'calculation':
      display = 'Calculation'
      break
    case 'upload':
      display = 'Upload'
      break
    case 'check_status':
      display = 'Check Status'
      break
    default:
      display = ''
  }
  return display
}

/* TODO: Consider creating a generic component for these kind of badges if more
use cases arise so it can be reused */
/**
 * This component renders the different job type badges based on the job type
 *
 * It expects to be rendered on the pricing jobs table
 */
const JobTypeBadge = (props: Props) => {
  const { jobType } = props
  const classes = useStyles(props)
  return <span className={classes.root}>{getJobTypeDisplay(jobType)}</span>
}

export default JobTypeBadge
