import React from 'react'
import DashboardRoutes from 'shared/pages/DashboardRoutes'
import { RenderDashboard } from 'shared/pages/DashboardDetail'
import ScenarioAnalysis from '../loadForecast/pages/ScenarioAnalysis'
import ClusterAnalysis from './pages/ClusterAnalysis'

const DASHBOARD_TYPE = 'prod_demand_analytics'

const content = ({
  handleSave,
  dashboard,
  dashboardConfigItem,
}: RenderDashboard) => {
  const {
    category,
    datasource,
    filters,
    dynamicColumnsConfig,
    timeInterval,
    rangeFilters,
    weatherStations,
  } = dashboardConfigItem
  switch (category.name) {
    case 'loadDisagg':
      return (
        <ScenarioAnalysis
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          scenariosConfig={dynamicColumnsConfig}
          weatherStations={weatherStations || []}
          filtersDefinitions={filters}
          timeInterval={timeInterval}
          savedDashboardInfo={dashboard}
          rangeFiltersDefinition={rangeFilters}
        />
      )
    case 'clusterAnalysis':
      return (
        <ClusterAnalysis
          key={dashboard.dashboardId}
          onSave={handleSave}
          savedDashboardInfo={dashboard}
          datasource={datasource}
          timeInterval={timeInterval}
          filtersDefinitions={filters}
          rangeFiltersDefinition={rangeFilters}
        />
      )
    default:
      return (
        <div>
          <p>{`Category "${category.label}" not is not supported by any page on the Demand component`}</p>
        </div>
      )
  }
}

/**
 * Demand Analytics Dashboard page component. It takes care of the
 * routing.
 */
const DemandAnalyticsDashboard = () => {
  return <DashboardRoutes content={content} dashboardType={DASHBOARD_TYPE} />
}

export default DemandAnalyticsDashboard
