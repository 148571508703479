/* eslint-disable react/no-array-index-key */
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { AlertCircle } from 'react-feather'
import { TableCell, TableRow } from 'shared/components/TableElements'

export interface Props {
  headers: Array<Array<string>>
  items: Array<Array<string>>
}

const useStyles = makeStyles((theme) => ({
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'start',
  },
  valueCell: {
    whiteSpace: 'nowrap',
  },
  errorIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
    fontSize: '1em',
  },
  errorDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

// Arbitrary maximum (kind of...). Browser seems to start freezing at around 35k cells
const MAX_CELLS_TO_RENDER = 30 * 1000

/**
 * Encapsulated component for the table with the pivoted data on the card
 */
const PivotTable = ({ headers, items }: Props) => {
  const classes = useStyles()
  // FIXME: This is a very simple check to ensure we don't break the browser when attempting to render a very large table. Ideally we would use a virtualized grid to easily render the data. For now we won't render large tables.
  if (items.length && items.length * items[0].length > MAX_CELLS_TO_RENDER) {
    return (
      <div className={classes.errorDiv}>
        <AlertCircle className={classes.errorIcon} />
        <Typography variant="subtitle1" align="center" display="block">
          Current dataset is to large to display here :( Try to reduce the size
          of your query by filtering or reducing the date range.
        </Typography>
      </div>
    )
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((headerLabels, index) => (
              <TableCell key={index}>
                <div className={classes.headerContent}>
                  {headerLabels.map((header, headerIndex) => (
                    <span key={headerIndex}>{header}</span>
                  ))}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((values, index) => (
            <TableRow key={index}>
              {values.map((value, valueIndex) => (
                <TableCell key={valueIndex} className={classes.valueCell}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PivotTable
