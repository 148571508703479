import { ProductModuleConfig } from 'shared/types'
import DataInsights, { DataInsightsMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: DataInsightsMenu,
  MainContent: DataInsights,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
