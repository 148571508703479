import React, {
  useState,
  MouseEventHandler,
  useRef,
  useEffect,
  MouseEvent,
} from 'react'
import {
  Card,
  CardHeader,
  IconButton,
  makeStyles,
  CardContent,
  Typography,
  CardActionArea,
  MenuList,
  MenuItem,
  InputBase,
  withStyles,
  Popover,
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import { Palette } from '@material-ui/core/styles/createPalette'
import { MoreHorizontal, CheckCircle, XCircle } from 'react-feather'
import { DateTime } from 'luxon'
import {
  DASHBOARD_DESCRIPTION_LENGTH,
  DASHBOARD_NAME_MAX_LENGTH,
} from 'shared/constants'
import StyledChip from 'shared/components/StyledChip'
import useClickedElement from 'shared/hooks/useClickedElement'
import ConditionalWrap from './ConditionalWrap'
import ColoredAvatar from './ColoredAvatar'

interface Chip {
  name: string
  label: string
}

interface Props {
  /** Dashboard name to be displayed in Card header */
  name: string
  /** Optional dashboard description to be displayed in Card Content */
  description?: string
  /** Alternative text to display if no description is provided */
  missingDescriptionText?: string
  /** Dashboard creation date to display under the dashboard name. */
  addedOn: Date
  /** Chip metadata to render as Chips under the Card Header (`{ label, name }`) */
  chips: Array<Chip>
  /** Optional callback invoked when delete option is clicked. If undefined it will not display the delete option. */
  onDelete?: MouseEventHandler
  /** Callback invoked when Card Action is clicked */
  onSelect: MouseEventHandler
  /** Optional callback invoked when Save confirmation is clicked (circle checkmark). If undefined it will not display edit option. */
  onSave?: (e: MouseEvent, newName: string, newDescription: string) => void
  /** Optional callback invoked when copy dashboard option is clicked. If undefined it will not display share option. */
  onCopyDashboard?: MouseEventHandler
  /** Optional callback invoked when share dashboard option is clicked. If undefined it will not display share option. */
  onShareDashboard?: MouseEventHandler
  sharedUsers?: Array<string>
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 255,
    height: 375,
    display: 'flex',
    flexDirection: 'column',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
  },
  action: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  headerContent: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  headerTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

interface OptionsProps {
  onEdit?: MouseEventHandler
  onDelete?: MouseEventHandler
  onSave?: MouseEventHandler
  onEditCancel: MouseEventHandler
  editMode: boolean
  onCopyDashboard?: MouseEventHandler
  onShareDashboard?: MouseEventHandler
}

const Options = ({
  editMode,
  onEdit,
  onEditCancel,
  onDelete,
  onSave,
  onCopyDashboard,
  onShareDashboard,
}: OptionsProps) => {
  const [anchorEl, handleMenuOpen, handleMenuClose] = useClickedElement()
  const handleAction = (e, cb?: (e) => void) => {
    handleMenuClose()
    if (cb) {
      cb(e)
    }
  }
  if (editMode) {
    return (
      <>
        <IconButton
          size="small"
          aria-label="cancel"
          title="cancel"
          onClick={(e) => handleAction(e, onEditCancel)}
        >
          <XCircle />
        </IconButton>
        <IconButton
          size="small"
          aria-label="confirm edit"
          title="confirm edit"
          onClick={(e) => handleAction(e, onSave)}
        >
          <CheckCircle />
        </IconButton>
      </>
    )
  }
  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-label="options"
        title="options"
        size="small"
        onClick={handleMenuOpen}
      >
        <MoreHorizontal />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          {/* If we cannot save then we cannot edit */}
          {onSave && (
            <MenuItem onClick={(e) => handleAction(e, onEdit)}>Edit</MenuItem>
          )}
          {onCopyDashboard && (
            <MenuItem onClick={(e) => handleAction(e, onCopyDashboard)}>
              Copy
            </MenuItem>
          )}
          {onShareDashboard && (
            <MenuItem onClick={(e) => handleAction(e, onShareDashboard)}>
              Share
            </MenuItem>
          )}
          {onDelete && (
            <MenuItem onClick={(e) => handleAction(e, onDelete)}>
              Delete
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </>
  )
}

const StyledDescriptionInput = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  input: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
}))(InputBase) as typeof InputBase

const StyledTitleInput = withStyles((theme) => ({
  root: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  input: {
    ...theme.typography.h6,
    padding: 0,
  },
}))(InputBase) as typeof InputBase

/**
 * Component to display a dashboard summary. This component also gives the user options to edit the dashboard's name and description, and to delete the dashboard.
 */
const DashboardSummary = ({
  name,
  description,
  addedOn,
  chips,
  sharedUsers,
  onDelete,
  onSelect,
  onSave,
  onCopyDashboard,
  onShareDashboard,
  missingDescriptionText = 'NO DESCRIPTION PROVIDED',
}: Props) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState<boolean>(false)
  const inputNameRef = useRef<HTMLInputElement>(null)
  const inputDescriptionRef = useRef<HTMLInputElement>(null)
  const handleSave = (e) => {
    if (!onSave) {
      return
    }
    // Name cannot be empty, thus if value is '' we force to name
    const newName =
      inputNameRef.current !== null ? inputNameRef.current.value || name : name
    const newDescription =
      inputDescriptionRef.current !== null
        ? inputDescriptionRef.current.value
        : ''
    if (newName === name && newDescription === description) {
      setEditMode(false)
    } else {
      onSave(e, newName, newDescription)
    }
  }
  // Focus card name on edit to improve user awareness
  useEffect(() => {
    if (inputNameRef.current !== null) {
      inputNameRef.current.focus()
    }
  }, [editMode])

  useEffect(() => {
    setEditMode(false)
  }, [name, description])
  return (
    <Card className={classes.root} role="listitem">
      <CardHeader
        classes={{ content: classes.headerContent, title: classes.headerTitle }}
        titleTypographyProps={{ variant: 'h6' }}
        title={
          editMode ? (
            <StyledTitleInput
              aria-label="dashboard name"
              title="dashboard name"
              inputRef={inputNameRef}
              defaultValue={name}
              inputProps={{ maxLength: DASHBOARD_NAME_MAX_LENGTH }}
            />
          ) : (
            <span title={name}>{name}</span>
          )
        }
        subheader={DateTime.fromJSDate(addedOn, {
          zone: 'utc',
        }).toLocaleString(DateTime.DATETIME_MED)}
        action={
          !onDelete &&
          !onSave &&
          !onShareDashboard &&
          !onCopyDashboard ? null : (
            <Options
              editMode={editMode}
              onDelete={onDelete}
              onEdit={() => setEditMode(true)}
              onEditCancel={() => setEditMode(false)}
              onSave={onSave ? handleSave : undefined}
              onCopyDashboard={onCopyDashboard}
              onShareDashboard={onShareDashboard}
            />
          )
        }
      />
      <ConditionalWrap
        isWrapped={!editMode}
        wrap={(kids) => (
          <CardActionArea onClick={onSelect} className={classes.action}>
            {kids}
          </CardActionArea>
        )}
      >
        <CardContent className={classes.chips}>
          {chips.map((elem) => (
            <StyledChip
              key={elem.name}
              // We feel safe that if worse comes to worse... this will default to primary
              color={elem.name as keyof Palette}
              size="small"
              label={elem.label}
            />
          ))}
        </CardContent>
        <CardContent>
          {editMode ? (
            <StyledDescriptionInput
              aria-label="dashboard description"
              title="dashboard description"
              inputRef={inputDescriptionRef}
              defaultValue={description}
              multiline
              inputProps={{ maxLength: DASHBOARD_DESCRIPTION_LENGTH }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary" component="p">
              {description || missingDescriptionText}
            </Typography>
          )}
        </CardContent>
      </ConditionalWrap>
      <CardContent>
        <AvatarGroup max={6}>
          {sharedUsers?.map((user) => (
            <ColoredAvatar key={user}>
              {user.substring(0, 2).toLowerCase()}
            </ColoredAvatar>
          ))}
        </AvatarGroup>
      </CardContent>
    </Card>
  )
}

export default DashboardSummary
