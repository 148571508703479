import { useState } from 'react'
import axiosApiClient from 'shared/apiClient'
import { ForecastType, Level } from '../components/LoadForecastTypes'

interface AvailableForecastItem {
  market: string
  process_time: string
  forecast: number
  timestamp: string
}

interface AdjustmentItem {
  adjustmentId: string
  configId: string
  createdAt: string
  isHourEnding: boolean
  market: string
  name: string
  processTime: string
  timezone: string
  updatedAt: string
  dateRange: {
    from: string
    to: string
  }
  versions: {
    createdAt: string
    versionId: string
  }[]
}

interface StitchedItem {
  adjustment: string
  createdAt: string
  position: number
}

interface AdjustmentItemExtended extends AdjustmentItem {
  levelId: string
  levelName: string
  adjustmentName: string
  datasource: string
  forecasts?: AvailableForecastItem[]
  stitched?: StitchedItem[]
}

interface LoadMoreReturnValue {
  items: AdjustmentItemExtended[]
  canLoadMore: boolean
}

interface InitializeReturnValue {
  metadataByDatasource: Metadata
  totalProcessDatesCount?: number
}

interface Metadata {
  [level: string]: {
    configId: string
    limit: number
    skip: number
    markets: string[]
  }
}

interface ReturnValue {
  initialize: Function
  loadMore: Function
  isInitialized: boolean
}

const useAvailableAdjustments = (
  loadForecastType: ForecastType,
): ReturnValue => {
  const [isInitialized, setIsInitialized] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [adjustmentType] = useState<ForecastType>(loadForecastType)
  const [metadataByDatasource, setMetadataByDatasource] = useState<Metadata>({})

  async function loadMore(datasource: string): Promise<LoadMoreReturnValue> {
    if (!datasource) {
      return {
        items: [],
        canLoadMore: false,
      }
    }
    const { configId, limit, skip } = metadataByDatasource[datasource]
    const { data: adjustments } = await axiosApiClient.get(
      `user/v1/user/adjustments?configId=${configId}&limit=${limit}&skip=${skip}`,
    )

    setMetadataByDatasource((previous) => {
      // eslint-disable-next-line no-param-reassign
      previous[datasource].skip += previous[datasource].limit
      return { ...previous }
    })

    return {
      items: adjustments,
      canLoadMore: adjustments.length < 5, // tmp in place of getting count from server
    }
  }

  async function initialize(
    levels: Level[],
    limit: number = 5,
  ): Promise<InitializeReturnValue> {
    if (isInitialized) {
      return { metadataByDatasource }
    }

    const distinctResult = await Promise.all(
      levels.map(async ({ datasource, id, name, configId }) => {
        if (!datasource) {
          throw new Error(`ERROR: No Datasource provided for Level: ${name}`)
        }

        if (!configId) {
          throw new Error(`ERROR: No config id provided for Level: ${name}`)
        }

        const url = `user/v1/user/adjustments?configId=${configId}&limit=${limit}&skip=0`
        const response = await axiosApiClient.get(url)

        return {
          datasource,
          configId,
          adjustments:
            (response?.data.map((element) => ({
              ...element,
              datasource,
              adjustmentName: element.name,
              levelId: id,
              levelName: name,
            })) as AdjustmentItemExtended[]) ?? [],
        }
      }),
    )

    const metadata = distinctResult.reduce((memo, current) => {
      const { datasource, configId, adjustments } = current
      return {
        ...memo,
        [datasource]: {
          configId,
          limit,
          skip: 0,
          markets: Array.from(new Set([...adjustments.map((v) => v.market)])),
        },
      }
    }, {})

    setMetadataByDatasource(metadata)
    setIsInitialized(true)

    return {
      metadataByDatasource: metadata,
    }
  }

  return {
    initialize,
    isInitialized,
    loadMore,
  }
}

export default useAvailableAdjustments
