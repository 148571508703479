/* eslint-disable react/prop-types */
import React from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'
import {
  CssBaseline,
  StylesProvider as MuiStylesProvider,
  withStyles,
} from '@material-ui/core'
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'leaflet/dist/leaflet.css'
import { AlertContextProvider } from './shared/contexts/AlertsContext'

const baseTheme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto',
    body2: {
      color: '#6b6c6f',
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
  palette: {
    primary: {
      main: '#0072ce',
    },
    secondary: {
      main: '#84bd00',
    },
    error: {
      main: '#fc5a5a',
      dark: '#b03e3e',
      light: '#fc7b7b',
    },
    success: {
      main: '#4caf50',
      dark: '#388e3c',
      light: '#81c784',
    },
    warning: {
      main: '#ff974a',
      dark: '#b26933',
      light: '#ffab6e',
    },
    info: {
      main: '#2196f3',
      dark: '#1976d2',
      light: '#64b5f6',
    },
    text: {
      primary: '#3e3f42',
      secondary: '#6b6c6f',
      disabled: '#bcbcbc',
    },
    /**
     * TODO: Need to improve these definitions... Perhaps throw it at the module level
     * Especiially becausedemand level definitions will probably need to be defined by each company.
     * Maybe a color wheel is best for these...
     */
    demand: {
      main: '#2FADE2',
    },
    customer: {
      main: '#92278F',
    },
    supply: {
      main: '#F7941D',
    },
    shortTermPerformance: {
      main: '#0062ff',
    },
    longTermForecast: {
      main: '#ff974a',
    },
    shortTermForecast: {
      main: '#4caf50',
    },
    shortTermScenario: {
      main: '#F0B27A',
    },
    clusterAnalysis: {
      main: '#707AF3',
    },
    loadDisagg: {
      main: '#2FADE2',
    },
    meter: {
      main: '#6758F3',
    },
    system: {
      main: '#2196f3',
    },
    opco: {
      main: '#3e3f42',
    },
  },
}) /* TODO: Provide custom overrides to theme and/or classes */

const GlobalCss = withStyles((theme) => ({
  '@global': {
    '.react-grid-placeholder': {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
    '.calculator-selected-option': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.light),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      borderRadius: '4px',
      '&.has-menu': {
        cursor: 'pointer',
      },
    },
  },
}))(() => null)
/**
 * Wrapper that sets the contexts provider to be established at the root of the React
 * tree so the MaterialUI theme is customized with
 * our own overrides and other utilities can properly work.
 *
 * It is exposed primarily for StoryBook use, so the same contexts are used on it.
 *
 * # THEME
 *
 * Check https://material-ui.com/customization/theming/ and
 * https://material-ui.com/customization/default-theme/#default-theme for modifying
 * specific MaterialUI classes for all the app,
 * also look at https://material-ui.com/customization/globals/#css
 *
 * # DATE PICKERS
 *
 * Check https://material-ui.com/components/pickers and https://material-ui-pickers.dev/getting-started/usage
 * to get an idea of the usage of date and time pickers with Material UI.
 *
 */
const AppMaterialContextsProvider = ({ children }) => (
  <MuiPickersUtilsProvider utils={LuxonUtils}>
    <MuiStylesProvider injectFirst>
      <MuiThemeProvider theme={baseTheme}>
        <CssBaseline />
        <GlobalCss />
        <AlertContextProvider>{children}</AlertContextProvider>
      </MuiThemeProvider>
    </MuiStylesProvider>
  </MuiPickersUtilsProvider>
)

export default AppMaterialContextsProvider
