import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as LoadSchedulingIcon } from 'icons/load-scheduling-submodule.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'

const loadSchedulingMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: (<div> Load Scheduling Content In Development </div>) as any,
  },
]

export const LoadSchedulingMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Demand Analytics"
      color="demand"
      basePath={url}
      icon={<LoadSchedulingIcon />}
      routes={loadSchedulingMenuOptions}
    />
  )
}

const LoadScheduling = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${loadSchedulingMenuOptions[0].path}`} />
      </Route>
      {loadSchedulingMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default LoadScheduling
