import React, { ReactNode } from 'react'

export interface Props {
  /** Whether you need to wrap a component with something */
  isWrapped: boolean
  /** The actual wrap if needed. This would be a function with children (kids) parameters. You would return a JSX element and render the children in it so...
   * `(kids) => <SomeWrapper>{kids}</SomeWrapper>` see Collapsible.tsx for a good example
   * */
  wrap: (kids: ReactNode) => ReactNode
  /** what you want to wrap with a condition */
  children: ReactNode
}

/**
 * Props should explain how this works... look at Collapsible.tsx for a good example
 */
const ConditionalWrap = ({ isWrapped, wrap, children }: Props) => (
  <>{isWrapped ? wrap(children) : children}</>
)

export default ConditionalWrap
