// FIXME: D-02832 - Re-add sockets when connections with backend work again
import React, { useState, useEffect } from 'react'
import { Paper, makeStyles } from '@material-ui/core'
import JobsTable from 'modules/customer/products/pricing/components/JobsTable'
import { Job } from 'shared/types'
import { getPricingJobs, downloadJob } from '../apiClient'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  table: {
    /* FIXME: Define a default height on the table so it doesn't need to be guessed */
    height: theme.spacing(6 * 11),
  },
}))

export interface JobStatusProps {
  onJobRerunRedirect: (jobUid: string) => void
}

const JobStatus = ({ onJobRerunRedirect }: JobStatusProps) => {
  const classes = useStyles()
  const [jobs, setJobs] = useState<Job[]>([])

  useEffect(() => {
    getPricingJobs().then((pricingJobs) => {
      setJobs(pricingJobs)
    })

    // FIXME: D-02832 - Re-add sockets when connections with backend work again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // FIXME: Add initial loading state?
  const handleJobCancel = (event, job: Job) => {
    // FIXME: Provide real logic
    // eslint-disable-next-line
    alert(`Want to cancel job #${job.uid}`)
  }
  const handleJobDownload = (
    event,
    job: Job,
    resultType: 'monthly_price' | 'summary_price',
  ) => {
    downloadJob(job.uid, resultType)
  }
  const handleJobRerun = (event, job: Job) => {
    onJobRerunRedirect(job.uid)
  }
  return (
    <Paper className={classes.root}>
      <div className={classes.table}>
        <JobsTable
          jobs={jobs}
          onJobCancel={handleJobCancel}
          onJobDownload={handleJobDownload}
          onJobRerun={handleJobRerun}
        />
      </div>
    </Paper>
  )
}

export default JobStatus
