import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

interface TooltipProps {
  children: React.ReactElement
  value: number
}

const SliderToolTip = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}))(Tooltip)

/** Tooltip on top of each range slider control */
function ValueLabelComponent(props: TooltipProps) {
  const { children, value } = props
  return (
    <SliderToolTip enterTouchDelay={0} placement="top" title={value} arrow>
      {children}
    </SliderToolTip>
  )
}

const RangeSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[12],
    marginTop: -9,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      // boxShadow: Shadow,
      boxShadow: theme.shadows[12],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        // boxShadow: Shadow,
        boxShadow: theme.shadows[12],
      },
    },
  },
  active: {},
  valueLabel: {},
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: theme.palette.grey[400],
  },
}))(Slider)

const RangeSliderStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
}))

interface Props {
  /** Min value that the range slider can recieve or minimum range value the user can select */
  minRangeValue: number
  /** Max value that the range slider can recieve or maximum range value the user can accept */
  maxRangeValue: number
  /** Selected value in the range slider , it is in the format [minSelectedvalue, maxSelectedValue] format */
  value: [number, number]
  /** event handler for the range slider change */
  onChange: (newValue: [number, number] | number[] | number) => void
}

/** Simple range slider that helps in selecting a range of value between a max and min range of value */
const RangeSelector = ({
  minRangeValue,
  maxRangeValue,
  value,
  onChange,
}: Props) => {
  const classes = RangeSliderStyle()
  const [[minVal, maxVal], setSliderValue] = useState(value)

  function onInputChange(e, inputType, changeHandler) {
    const newValue = Number(e.target.value)
    let arr = [minVal, maxVal]
    if (newValue > minRangeValue && newValue < maxRangeValue) {
      const staticVal = inputType === 'min' ? maxVal : minVal
      arr = [newValue, staticVal].sort((a, b) => a - b)
    }
    return changeHandler(arr)
  }

  return (
    <div>
      <RangeSlider
        data-testid="slider"
        valueLabelDisplay="on"
        ValueLabelComponent={ValueLabelComponent}
        min={minRangeValue}
        step={1}
        max={maxRangeValue}
        value={[minVal, maxVal]}
        onChange={(e, val: any) => {
          setSliderValue(val)
        }}
        onChangeCommitted={(e, val) => onChange(val)}
      />
      <div className={classes.container}>
        <TextField
          type="number"
          variant="outlined"
          value={minVal}
          label="Min"
          margin="dense"
          onChange={(e) => onInputChange(e, 'min', setSliderValue)}
          onBlur={(e) => onInputChange(e, 'min', onChange)}
        />
        <TextField
          type="number"
          variant="outlined"
          label="Max"
          margin="dense"
          value={maxVal}
          onChange={(e) => onInputChange(e, 'max', setSliderValue)}
          onBlur={(e) => onInputChange(e, 'max', onChange)}
        />
      </div>
    </div>
  )
}

export default RangeSelector
