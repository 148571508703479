import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { useRouteMatch, Switch, Route } from 'react-router'
import DashboardManager from 'shared/pages/DashboardManager'
import DashboardDetail, { RenderDashboard } from 'shared/pages/DashboardDetail'
import useDashboardConfigItems from 'shared/hooks/useDashboardConfigItems'

interface Props {
  /** callback that should return a react element to display as part of the main dashboard layout */
  content: (obj: RenderDashboard) => ReactElement
  /** Main type of dashboard used to fetch appropriate dashboards */
  dashboardType: string
}

/**
 * Simple dashboard routing that will display an entry dashboard (dashboard summary cards etc) page on the current matched path, but will display the dashboard when the dashboard id parameter is passed (matched_path/:id). This routing system will determine the correct dashboard configuration items based on the dashboard type.
 */
const DashboardRoutes = ({ content, dashboardType }: Props) => {
  const history = useHistory()
  const match = useRouteMatch()

  const handleDashboardSelect = (id) => {
    history.push(`${match.url}/${id}`)
  }

  const { isLoading, dashboardConfigItems } = useDashboardConfigItems(
    dashboardType,
  )

  // TODO: Handle case where no good configuration has been retrieved
  if (isLoading) return <CircularProgress />

  // TODO: Check how this information page should be displayed
  if (!dashboardConfigItems)
    return (
      <div>
        No configuration for <b>{dashboardType}</b> dashboards has been found
      </div>
    )

  return (
    <>
      <Switch>
        <Route exact path={match.path}>
          <DashboardManager
            dashboardType={dashboardType}
            dashboardConfigItems={dashboardConfigItems}
            onDashboardSelect={handleDashboardSelect}
            onDashboardCreate={handleDashboardSelect}
          />
        </Route>
        <Route
          path={`${match.path}/:id`}
          render={({ match: routeMatch }) => (
            <DashboardDetail
              key={routeMatch.params.id}
              dashboardType={dashboardType}
              renderDashboard={content}
              dashboardConfigItems={dashboardConfigItems}
              id={routeMatch.params.id}
              onDashboardSelect={handleDashboardSelect}
              onDashboardCreate={handleDashboardSelect}
            />
          )}
        />
      </Switch>
    </>
  )
}

export default DashboardRoutes
