export enum PricingConfigurationMenuItems {
  Products = 'products',
  Components = 'components',
  Formulas = 'formulas',
  Attributes = 'attributes',
  Curves = 'curves',
  LoadProfiles = 'load-profiles',
  ProductComponent = 'product-components',
  ComponentCurves = 'component-curves',
}

// this function is used to determine which global filters apply to a given table
export const getTableSpecificFilters = (
  tableName,
  selectedFilters,
): { [key: string]: string[] } => {
  switch (tableName) {
    case PricingConfigurationMenuItems.Products: {
      return {
        code: selectedFilters.productCode || [],
      }
    }
    case PricingConfigurationMenuItems.ProductComponent: {
      return {
        product: selectedFilters.productCode || [],
        componentGroup: selectedFilters.componentGroup || [],
        component: selectedFilters.componentCode || [],
        granularity: selectedFilters.granularity || [],
        uom: selectedFilters.uom || [],
      }
    }
    case PricingConfigurationMenuItems.ComponentCurves: {
      return {
        component: selectedFilters.componentCode || [],
        curve: selectedFilters.curveType || [],
      }
    }
    case PricingConfigurationMenuItems.Components: {
      return {
        code: selectedFilters.componentCode || [],
        granularity: selectedFilters.granularity || [],
        level: selectedFilters.componentLevel || [],
        inputType: selectedFilters.componentInputType || [],
        uom: selectedFilters.uom || [],
      }
    }
    case PricingConfigurationMenuItems.Formulas: {
      return {
        product: selectedFilters.productCode || [],
        component: selectedFilters.componentCode || [],
      }
    }
    case PricingConfigurationMenuItems.Attributes: {
      return {
        attributeName: selectedFilters.attributeName || [],
        attributeValue: selectedFilters.attributeValue || [],
      }
    }
    case PricingConfigurationMenuItems.Curves: {
      return {
        name: selectedFilters.curveType || [],
      }
    }
    case PricingConfigurationMenuItems.LoadProfiles: {
      return {
        code: selectedFilters.loadProfile || [],
      }
    }
    default: {
      return { code: [] }
    }
  }
  return {}
}

// this mapping is used when fetching distinct fields for filter options from the backend
export const filterToTableMapping: {
  tableToFetchFrom: PricingConfigurationMenuItems
  filterDisplay: string
  fieldNameInTable: string
  fieldNameInFilter: string
}[] = [
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Products,
    filterDisplay: 'Product Code',
    fieldNameInTable: 'code',
    fieldNameInFilter: 'productCode',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.ProductComponent,
    filterDisplay: 'Component Group',
    fieldNameInTable: 'componentGroup',
    fieldNameInFilter: 'componentGroup',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Components,
    filterDisplay: 'Component Code',
    fieldNameInTable: 'code',
    fieldNameInFilter: 'componentCode',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Components,
    filterDisplay: 'Component Level',
    fieldNameInTable: 'level',
    fieldNameInFilter: 'componentLevel',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Components,
    filterDisplay: 'Component Input Type',
    fieldNameInTable: 'inputType',
    fieldNameInFilter: 'componentInputType',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Components,
    filterDisplay: 'Granularity',
    fieldNameInTable: 'granularity',
    fieldNameInFilter: 'granularity',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Components,
    filterDisplay: 'UOM',
    fieldNameInTable: 'uom',
    fieldNameInFilter: 'uom',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Curves,
    filterDisplay: 'Curve Type',
    fieldNameInTable: 'name',
    fieldNameInFilter: 'curveType',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.LoadProfiles,
    filterDisplay: 'Load Profile',
    fieldNameInTable: 'code',
    fieldNameInFilter: 'loadProfile',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Attributes,
    filterDisplay: 'Attribute Name',
    fieldNameInTable: 'attributeName',
    fieldNameInFilter: 'attributeName',
  },
  {
    tableToFetchFrom: PricingConfigurationMenuItems.Attributes,
    filterDisplay: 'Attribute Value',
    fieldNameInTable: 'attributeValue',
    fieldNameInFilter: 'attributeValue',
  },
]

// this object maps  fields to what the column name displayed on the UI
export const columnTitleMapping = {
  [PricingConfigurationMenuItems.Products]: {
    code: 'Product Code',
    name: 'Name',
    description: 'Description',
  },
  [PricingConfigurationMenuItems.ProductComponent]: {
    product: 'Product Code',
    component: 'Component Code',
    granularity: 'Granularity',
    uom: 'UOM',
    componentGroup: 'Component Group',
  },
  [PricingConfigurationMenuItems.ComponentCurves]: {
    component: 'Component Code',
    curve: 'Curve Type',
    asOfDatetime: 'As Of Datetime',
  },
  [PricingConfigurationMenuItems.Components]: {
    code: 'Component Code',
    name: 'Name',
    description: 'Description',
    granularity: 'Granularity',
    level: 'Component Level',
    uom: 'UOM',
    inputType: 'Component Input Type',
  },
  [PricingConfigurationMenuItems.Formulas]: {
    component: 'Component Code',
    product: 'Product Code',
    formula: 'Formula',
  },
  [PricingConfigurationMenuItems.Attributes]: {
    attributeName: 'Attribute Name',
    attributeValue: 'Attribute Value',
    attributeValueDisplay: 'Display Value',
    description: 'Description',
  },
  [PricingConfigurationMenuItems.Curves]: {
    name: 'Curve Type',
    description: 'Description',
  },
  [PricingConfigurationMenuItems.LoadProfiles]: {
    code: 'Load Profile',
    name: 'Name',
    description: 'Description',
  },
}

export default getTableSpecificFilters
