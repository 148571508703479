import React, { useContext, useEffect, useMemo, useState } from 'react'
import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import { DateTime } from 'luxon'
import {
  Button,
  makeStyles,
  Theme,
  ButtonGroup,
  Typography,
  CircularProgress,
  styled,
} from '@material-ui/core'
import {
  getDruidDatasourceColumns,
  getForecastForAdjustments,
} from 'modules/demand/common/apiClient'
import DashboardsConfigsContext from 'shared/contexts/DashboardsConfigsContext'
import useSimpleFilters from 'shared/hooks/useSimpleFilters'
import {
  AdjustmentForecast,
  DashboardConfigItem,
  AdjustmentsMeta,
} from 'shared/types'
import Alert from 'shared/components/Alert'
import {
  ChartArea,
  PageLayout,
  Table,
  RightMenu,
  Title,
  Options,
} from 'modules/demand/products/loadForecast/components/AdjustmentPageLayout'
import { Save } from '@material-ui/icons'
import clsx from 'clsx'
import { DownloadCloud, MousePointer, Codesandbox } from 'react-feather'
import Papa from 'papaparse'
import AdjustmentMenu from '../components/AdjustmentMenu'
import useAdjustmentsData from '../hooks/useAdjustmentsData'
import useAdjustmentVersionSelector from '../hooks/useAdjustmentVersionSelector'
import AdjustmentTable from '../components/AdjustmentTable'
import { downloadCsvFile } from '../../../../../shared/helpers'
import BulkUpdateDialog from '../components/BulkUpdateDialog'
import PasteFromClipDialog from '../components/PasteFromClipDialog'

const Plot = createPlotlyComponent(Plotly)

interface Props {
  adjustmentId: string
}

function getVersionNumber(
  versions: AdjustmentsMeta['versions'],
  selectedVersionId: string,
) {
  const index = versions.findIndex((v) => v.versionId === selectedVersionId)
  return versions.length - index
}

const useAdjustmentPageStyle = makeStyles((theme: Theme) => {
  return {
    buttonContainer: { margin: theme.spacing(1.5), float: 'right' },
    bulkUpdate: {
      margin: theme.spacing(1.5, 1.5, 1.5, 0),
    },
    titleList: {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      '& > *:not(:last-child):after': {
        content: '"•"',
        padding: theme.spacing(1.5),
      },
    },
  }
})

const CenterAllWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
})

export const LoadingContent = () => (
  <CenterAllWrapper>
    <CircularProgress />
  </CenterAllWrapper>
)

const AdjustmentsPage = ({ adjustmentId }: Props) => {
  const [isBulkUpdate, setIsBulkUpdate] = useState<boolean>(false)
  const [showPasteFromClipModal, setShowPasteFromClipModal] = useState<boolean>(
    false,
  )

  const {
    adjustmentsMeta,
    stichedAdjustment,
    selectedVersionId,
    changeVersion,
    saveAdjustments,
  } = useAdjustmentVersionSelector(adjustmentId)
  const classes = useAdjustmentPageStyle()

  const [adjustmentForecast, setAdjustmentForecast] = useState<
    AdjustmentForecast[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isAlertVersionChangeOpen, setIsAlertVersionChangeOpen] = useState(
    false,
  )
  const [isAlertSaveOpen, setIsAlertSaveOpen] = useState(false)
  const [pendingVersionId, setPendingVersionId] = useState<string | undefined>()
  const [
    forecastDashboardConfig,
    setForecastDashboardConfig,
  ] = useState<DashboardConfigItem>()

  const dashboardsConfigsContext = useContext(DashboardsConfigsContext)

  const {
    filteredData,
    availableFilters,
    availableFilterKeys,
    filter,
    clearFilters,
    getAggregateData,
    getAdjustments,
    update,
    sort: onSort,
    sortArray,
  } = useAdjustmentsData({
    forecast: adjustmentForecast,
    initialAdjustments: stichedAdjustment,
    timezone: adjustmentsMeta?.timezone,
  })

  const handleAlertSaveConfirmation = () => {
    setIsAlertSaveOpen(false)
    // TODO: This is cheating... should figure out why keyof T | Timefilters always gives string...
    const summary = getAggregateData(
      'timestamp',
      true,
    ) as AdjustmentsMeta['summary']
    const newAdjustments = getAdjustments()
    saveAdjustments(newAdjustments, summary)
  }

  const handleSave = () => {
    if (adjustmentsMeta?.versions[0].versionId === selectedVersionId) {
      handleAlertSaveConfirmation()
    } else {
      setIsAlertSaveOpen(true)
    }
  }

  const {
    filters,
    selectedFilters,
    onChange: onFilterChange,
  } = useSimpleFilters({ filterOptions: availableFilters, debouncerDelay: 200 })

  const chartData = useMemo(() => {
    const forecastY: number[] = []
    const adjustmentsY: number[] = []
    const timestampX: string[] = []
    const summary = getAggregateData('timestamp')
    summary.forEach((elem) => {
      forecastY.push(elem.forecast)
      adjustmentsY.push(elem.adjustedForecast || 0)
      timestampX.push(elem.timestamp as string)
    })
    return [
      {
        yaxis: 'y',
        line: {
          width: 2,
          dash: 'solid',
        },
        name: 'Forecast',
        x: timestampX,
        y: forecastY,
        type: 'scatter',
      },
      {
        yaxis: 'y',
        line: {
          width: 2,
          dash: 'dash',
        },
        name: 'Adjustment',
        x: timestampX,
        y: adjustmentsY,
        type: 'scatter',
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData])

  useEffect(() => {
    if (
      !adjustmentsMeta?.configId ||
      dashboardsConfigsContext.configs.length === 0
    ) {
      // TODO: Handle this
      return
    }
    const configs: DashboardConfigItem[] = []
    dashboardsConfigsContext.configs.forEach((elem) => {
      configs.push(...elem.items)
    })
    const selectedConfig = configs.find(
      (e) => e.configId === adjustmentsMeta?.configId,
    )
    if (selectedConfig?.datasource) {
      setForecastDashboardConfig(selectedConfig)
      getDruidDatasourceColumns(selectedConfig.datasource).then((columns) => {
        getForecastForAdjustments({
          datasource: selectedConfig.datasource,
          market: adjustmentsMeta.market,
          timezone: adjustmentsMeta.timezone,
          dateRange: {
            fromDate: adjustmentsMeta.dateRange.from,
            toDate: adjustmentsMeta.dateRange.to,
          },
          processTime: adjustmentsMeta.processTime,
          attributeColumns: Object.keys(columns).filter((col) => {
            return (
              /** We can ignore forecast and __time since these are already getting requested
               * and process_time is the same throughout
               */
              col !== 'forecast' && col !== '__time' && col !== 'process_time'
            )
          }),
        }).then((result) => {
          setIsLoading(false)
          setAdjustmentForecast(result)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustmentsMeta?.configId, dashboardsConfigsContext])

  useEffect(() => {
    if (selectedFilters.length === 0) {
      clearFilters()
    } else {
      // TODO: FIX TYPING
      filter(selectedFilters as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  const handleVersionChange = (versionId: string) => {
    const adjustments = getAdjustments()
    if (adjustments.length === 0) {
      changeVersion(versionId)
    } else {
      setPendingVersionId(versionId)
      setIsAlertVersionChangeOpen(true)
    }
  }

  const handleAlertVersionConfirmation = () => {
    changeVersion(pendingVersionId)
    setIsAlertVersionChangeOpen(false)
    setPendingVersionId(undefined)
  }

  const handleDownload = () => {
    const keysToKeep = [...availableFilterKeys, 'forecast', 'adjustedForecast']
    // Remove the unneeded columns from the download
    const adjustmentData = filteredData.map((d) => {
      const newObj = {}
      keysToKeep.forEach((key) => {
        newObj[key] = d[key]
      })
      return newObj
    })
    downloadCsvFile(
      adjustmentsMeta?.name || 'unknown-adjustment',
      Papa.unparse(adjustmentData),
    )
  }

  if (isLoading) {
    return <LoadingContent />
  }

  return (
    <PageLayout>
      <Alert
        title="Unsaved Changes"
        isOpen={isAlertVersionChangeOpen}
        message="You have unsaved changes in this current document. Do you want to discard changes and revert to previous version"
        onClose={() => {
          setIsAlertVersionChangeOpen(false)
        }}
        onAlertAction={handleAlertVersionConfirmation}
      />
      <Alert
        title="Saving on top of version"
        isOpen={isAlertSaveOpen}
        message={`This save will override any versions after v${
          adjustmentsMeta?.versions && selectedVersionId
            ? getVersionNumber(adjustmentsMeta.versions, selectedVersionId)
            : ''
        }`}
        onClose={() => {
          setIsAlertSaveOpen(false)
        }}
        onAlertAction={handleAlertSaveConfirmation}
      />
      <Title>
        <Typography variant="h4">{adjustmentsMeta?.name}</Typography>
        <ul className={classes.titleList}>
          <Typography variant="subtitle1" component="li">
            {adjustmentsMeta?.market}
          </Typography>
          <Typography variant="subtitle1" component="li">
            {forecastDashboardConfig?.level?.name}
          </Typography>
          <Typography variant="subtitle1" component="li">
            {DateTime.fromISO(adjustmentsMeta?.updatedAt || '').toLocaleString(
              DateTime.DATETIME_MED,
            )}
          </Typography>
        </ul>
      </Title>
      <Options>
        <ButtonGroup size="medium" className={clsx(classes.buttonContainer)}>
          <Button onClick={handleDownload} startIcon={<DownloadCloud />}>
            Download
          </Button>
          <Button onClick={handleSave} startIcon={<Save />}>
            Save
          </Button>
        </ButtonGroup>
      </Options>
      <ChartArea>
        <Plot
          data={chartData}
          config={{
            modeBarButtonsToRemove: ['toImage'],
            displaylogo: false,
            responsive: true,
          }}
          layout={{
            title: '',
            legend: {
              yanchor: 'bottom',
              y: 1.02,
              xanchor: 'right',
              x: 1,
              orientation: 'h',
            },
            font: {
              family: 'Roboto',
              size: '12px',
            },
            xaxis: {
              type: 'date',
              showgrid: true,
              showline: true,
              zeroline: false,
              linecolor: '#E9EBF1',
              automargin: true,
            },
            yaxis: {
              rangemode: 'normal',
              title: { text: 'Load (kWh)' },
              type: 'linear',
              showgrid: true,
              showline: true,
              zeroline: false,
              linecolor: '#E9EBF1',
              side: 'left',
              automargin: true,
            },
            autosize: true,
            margin: {
              l: 24,
              r: 24,
              t: 24,
              b: 24,
            },
          }}
          useResizeHandler
          style={{ width: '100%', height: '100%' }}
        />
      </ChartArea>
      <Table>
        <ButtonGroup size="medium" className={clsx(classes.bulkUpdate)}>
          <Button
            onClick={() => setShowPasteFromClipModal(true)}
            startIcon={<Codesandbox />}
          >
            Paste from Spreadsheet
          </Button>
          <Button
            onClick={() => setIsBulkUpdate(true)}
            startIcon={<MousePointer />}
          >
            Bulk Action
          </Button>
        </ButtonGroup>
        <AdjustmentTable
          data={filteredData}
          availableFilters={availableFilterKeys}
          onChange={(items) => update(items)}
          sortArray={sortArray}
          onSort={(column, direction: any) => onSort([{ column, direction }])}
        />
      </Table>
      <RightMenu>
        {adjustmentsMeta?.versions && (
          <AdjustmentMenu
            filters={filters}
            onFilterChange={onFilterChange}
            sortedVersions={adjustmentsMeta.versions}
            onVersionChange={(versionId) => {
              handleVersionChange(versionId)
            }}
            selectedVersionId={selectedVersionId}
          />
        )}
      </RightMenu>
      {isBulkUpdate && (
        <BulkUpdateDialog
          selectedRowCount={filteredData.filter((i) => i.selected).length || 0}
          onClose={() => setIsBulkUpdate(false)}
          onConfirm={(modelData) => {
            setIsBulkUpdate(false)
            update(
              filteredData
                .filter(({ selected }) => selected)
                .map((data) => {
                  return {
                    ...data,
                    adjustmentCalculation: modelData.calculation,
                    adjustmentValue: modelData.adjustmentValue,
                  }
                }),
            )
          }}
        />
      )}
      {showPasteFromClipModal && (
        <PasteFromClipDialog
          selectedRowCount={filteredData.filter((i) => i.selected).length || 0}
          onClose={() => setShowPasteFromClipModal(false)}
          onConfirm={(modelData) => {
            setShowPasteFromClipModal(false)
            update(
              filteredData
                .filter(({ selected }) => selected)
                .map((data, i) => {
                  return {
                    ...data,
                    adjustmentCalculation: modelData.calculation,
                    adjustmentValue: modelData.parsedPastedData[i],
                  }
                }),
            )
          }}
        />
      )}
    </PageLayout>
  )
}

export default AdjustmentsPage
