import React, { ChangeEvent } from 'react'
import { Autocomplete } from '@material-ui/lab'
import ClockDropdownTextField from './ClockDropdownTextField'

interface Props {
  // option that will be displayed as selected
  isHourEndingSelected: Boolean | undefined
  // Callback invoked when a user selects a new option
  onChange: (e: ChangeEvent, newHourBeginningOrEnding: Boolean) => void
}

/**
  This component is a auto complete dropdown to select the hour beginning or hour ending option
 */
const HourBeginningOrEndingSelector = ({
  isHourEndingSelected,
  onChange,
}: Props) => {
  const hourBeginningOrEndingOptions = [
    { name: 'Hour Beginning', value: false },
    { name: 'Hour Ending', value: true },
  ]

  return (
    <Autocomplete
      options={hourBeginningOrEndingOptions}
      getOptionLabel={(option) => option.name}
      onChange={(e, selected) =>
        onChange(e as ChangeEvent, selected?.value || false)
      }
      value={hourBeginningOrEndingOptions.find(
        (option) => option.value === isHourEndingSelected,
      )}
      disableClearable
      renderInput={(params) => (
        <ClockDropdownTextField params={params} placeHolder="Hour Start/End" />
      )}
    />
  )
}

export default HourBeginningOrEndingSelector
