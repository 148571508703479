import axios from 'axios'
import io from 'socket.io-client'
import { SocketsContextType } from 'shared/contexts/SocketContext'
import {
  UserType,
  isUserType,
  NotificationType,
  NestedDashboardConfig,
  Dashboard,
  DashboardConfigItem,
  DashboardUpdateMetaBody,
  DashboardUpdateBody,
} from 'shared/types'
import { DateTime } from 'luxon'

const { NODE_ENV } = process.env

/**
 * Module with the communication layer functionalities.
 */

/* Axios client instance for API interactions, this set up ensures the
base url for our api gateway will be used and also that the session cookie
will be sent on these requests */
const axiosApiClient = axios.create({
  baseURL: NODE_ENV === 'development' ? '/' : '/api',
  withCredentials: true,
})

export interface RedirectType {
  user?: UserType
  url?: string
}

/**
 * Ensures a correct UserType object
 */
function normalizeUserObject(receivedUserObject: any): UserType {
  if (isUserType(receivedUserObject)) {
    return receivedUserObject
  }
  const { userId, email, name, mods, organization } = receivedUserObject
  return {
    userId: (userId || 'someuserid') as string,
    email: (email || 'unset@email.com') as string,
    name: (name || 'Unset') as string,
    mods: (mods || []) as Array<string>,
    organization: (organization || 'Unset') as string,
  }
}

/**
 * this function is used to get the settting field which is needed for the backend
 * this filed has lots of information from the dashboar and is nested backed but flat in the fronted
 */
export const getSettingsFieldForBackend = (
  input: Dashboard | DashboardUpdateBody,
) => {
  const {
    calculators = [],
    timezone,
    isHourEndingSelected = false,
    from,
    to,
  } = input
  return {
    calculators,
    timezone,
    is_hour_ending: isHourEndingSelected,
    date_range:
      from && to
        ? {
            from,
            to,
          }
        : undefined,
  }
}

/**
 * Normalises the front end's dashboard object to be accepted by the back end
 * Mainly focused on the settings object which is flattened in the front end
 * @param dashboard - dashboard object
 */
function normaliseDashboard(dashboard: DashboardUpdateBody) {
  const { cards, filters, rangeFilters, timeFilters } = dashboard
  const settings = getSettingsFieldForBackend(dashboard)
  return {
    cards,
    filters,
    rangeFilters,
    timeFilters,
    settings,
  }
}

export const redirect = (state: string): Promise<RedirectType> => {
  return axiosApiClient
    .get('/auth/redirect', {
      params: { state },
      validateStatus: (status) => status === 200 || status === 302,
    })
    .then((d) => {
      const returnData: RedirectType = {}
      if (d.data.url) {
        returnData.url = d.data.url
      }
      if (d.data.user) {
        returnData.user = normalizeUserObject(d.data.user)
      }
      return returnData
    })
}

export const login = (code: string | null): Promise<UserType> => {
  return axiosApiClient
    .get('/auth/user', {
      params: { code },
    })
    .then((d) => normalizeUserObject(d.data))
}

export async function logout(): Promise<string> {
  return axiosApiClient({
    method: 'POST',
    url: '/auth/logout',
  }).then((d) => d.data)
}

export function createUserSockets(user: UserType): SocketsContextType {
  // TODO: This may change based on deployment
  const path = NODE_ENV === 'development' ? '' : '/api'
  const tempSockets = {
    personal: io('/', {
      path: `${path}/socket.io`,
    }),
  }
  user.mods.forEach((mod) => {
    const temp = io('/', {
      path: `${path}/socket.io/${mod}`,
    })
    tempSockets[mod] = temp
  })
  return tempSockets
}

export function getNotifications(): Promise<NotificationType[]> {
  return axiosApiClient
    .get('user/v1/user/notifications/?isRead=false&isGlobal=true')
    .then((d) => {
      return d.data
    })
}

export function clearNotifications(): Promise<Object> {
  return axiosApiClient.patch('user/v1/user/notifications/mark-all-as-read')
}

/**
 * Retrieves the dashboards related configurations
 */
export function getDashboardsConfig(): Promise<Array<NestedDashboardConfig>> {
  return axiosApiClient
    .get('user/v1/user/config/dashboards')
    .then((response) => {
      // API endpoint changed so we transform response to look how it is required
      const types: { [key: string]: DashboardConfigItem[] } = {}
      const data = response.data as DashboardConfigItem[]
      data.forEach((config) => {
        if (types[config.type]) {
          types[config.type].push(config)
        } else {
          types[config.type] = [config]
        }
      })
      return Object.entries(types).map(([type, configs]) => {
        return {
          dashboardType: type,
          items: configs,
        }
      })
    })
}

/**
 * Creates a a new dashboard on the remote repository and
 * returns its newly assigned id
 */
export function addNewDashboard(
  dashboard: object /* TODO: Add definition */,
): Promise<string> {
  return axiosApiClient
    .post('user/v1/user/dashboards', dashboard)
    .then((response) => {
      return response.data.dashboardId
    })
}

/**
 * Gets dashboards from user service returns array of dashboards
 */
export function getDashboards(
  dashboardType?: string,
): Promise<Array<Dashboard>> {
  const url = '/user/v1/user/dashboards/'
  return axiosApiClient
    .get(url, { params: { type: dashboardType } })
    .then((response) => {
      return response.data.map((dashboard) => {
        const copy = { ...dashboard }
        if (copy?.from?.value) {
          copy.from.value = DateTime.fromISO(copy.from.value)
        }
        if (copy?.to?.value) {
          copy.to.value = DateTime.fromISO(copy.to.value)
        }
        return copy
      })
    })
}

export function deleteDashboard(id: string): Promise<null> {
  return axiosApiClient.delete(`/user/v1/user/dashboards/${id}`)
}

/**
 * Updates the metadata of a dashboard (name, description ...)
 * This is separated so as to enable defaults of settings without
 * overwriting the object if it is not provided.
 * @param id - id of the dashboard
 * @param body - dashboard update object
 */
export function updateDashboardMeta(
  id: string,
  body: DashboardUpdateMetaBody,
): Promise<null> {
  return axiosApiClient.patch(`/user/v1/user/dashboards/${id}`, body)
}

/**
 * Updates the entire dashboard and normalises the settings object, sets defaults etc.
 * @param id - string: id of the dashboard
 * @param body - dashboard update object
 */
export function updateDashboard(
  id: string,
  body: DashboardUpdateBody,
): Promise<null> {
  return axiosApiClient.patch(
    `/user/v1/user/dashboards/${id}`,
    normaliseDashboard(body),
  )
}

/**
 * Gets the overview cards for a given user
 */
export function getOverviewCardsForUser(): Promise<any> {
  return axiosApiClient.get(`user/v1/user/cards/overview`)
}

/**
 * Updates the overview_card record for this card + dashboard(user) id
 * @param cardId - id of the card to have its dashboard_card association updated
 * @param parentDashboardId - id of the dashboard that own this card
 * @param body - { status: true/false }
 */
export function updateOverviewCardStatus(
  cardId: string,
  parentDashboardId: string,
  body: object,
): Promise<null> {
  return axiosApiClient.post(
    `user/v1/user/cards/${parentDashboardId}/${cardId}/overview`,
    body,
  )
}

/**
 * Updates the overview_card record for this card + user(user) id
 * @param cardId - id of the card to have its dashboard_card association updated
 * @param parentDashboardId
 * @param body - updated layout/overrides
 */
export function updateOverviewCard(
  cardId: string,
  parentDashboardId: string,
  body: object,
): Promise<null> {
  return axiosApiClient.patch(
    `user/v1/user/cards/${parentDashboardId}/${cardId}/overview`,
    body,
  )
}

export function shareDashboard(id: string, body: object): Promise<null> {
  return axiosApiClient
    .post(`user/v1/user/share/dashboard/${id}/users`, body)
    .then((response) => {
      return response.data.dashboardId
    })
}

export function shareOrgDashboard(id: string): Promise<null> {
  return axiosApiClient
    .post(`user/v1/user/share/dashboard/${id}/org`)
    .then((response) => {
      return response.data.dashboardId
    })
}

export function unShareOrgDashboard(id: string): Promise<null> {
  return axiosApiClient
    .delete(`user/v1/user/share/dashboard/${id}/org`)
    .then((response) => {
      return response.data.dashboardId
    })
}

export function fetchOrgUsers(): Promise<
  { id: string; email: string; name: string }[]
> {
  return axiosApiClient.get(`user/v1/org/users`).then((response) => {
    return response.data
  })
}

/* The client instance is returned so interceptors,
mocks or other custom configs can be made over it */
export default axiosApiClient
