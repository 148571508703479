import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import SideBarMenu from 'shared/components/SideBarMenu'
import {
  Group,
  Dashboard,
  DataSeriesType,
  Calculation,
  SingleCalculationOption,
  SplitOptions,
  GeneralDateSelector,
  SelectedFilter,
  DataSeries,
  SingleSplitOption,
  DashboardConfigItem,
} from 'shared/types'
import { DateTime } from 'luxon'
import DraggableMenu, {
  DraggableInnerMenuProps,
  MenuOptionProps,
} from 'shared/components/DraggableMenu'
import { getLoadDataSeriesTypeByCommodity } from 'shared/helpers'
import { HandleSave } from 'shared/pages/DashboardDetail'
import DateSelector from 'shared/components/DateSelector'
import BaseDashboard, {
  CardsDataSeriesManager,
  setDataSeriesData,
  BuildCalculatorDataSeries,
} from 'shared/components/BaseDashboard/BaseDashboard'
import { calculationOptions } from 'shared/common/dashboardOptions'
import { mergeDataSeries } from 'shared/utils/dataSeriesHelpers'
import { CalculatorDataSeriesOption } from 'shared/components/Calculator/Calculator'
import { getDataSeriesTypeFromUnit, getDefinition } from 'shared/common/units'
import useTimeAndDimensionFilters from 'shared/hooks/useTimeAndDimensionFilters'
import useDates from 'modules/demand/products/loadForecast/hooks/useDates'
import {
  getClusterDataSeriesId,
  parseClusterDataSeriesId,
  fetchDataForClusterProfile,
  ClusterDataSeriesType,
  ClustersFetchingArgument,
  getFetchingArgumentCommonValues,
} from 'modules/demand/common/helpers'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import { AxiosRequestConfig } from 'axios'
import useCancellableRequests from 'shared/hooks/useCancellableRequests'
import {
  getDruidDatasourceDistinctData,
  getDruidDatasourceMaxTime,
  getDruidDatasourceMinTime,
  getDruidDistinctAll,
  getMinMaxForColumns,
} from '../../../common/apiClient'

interface ClusterMenuItem {
  id: string
  title: string
  items: Array<DraggableInnerMenuProps>
}

function createClusterProfileMenuItems(
  menuItems: Array<{ id: string; name: string; items: Array<string> }>,
): Array<ClusterMenuItem> {
  return menuItems.map(({ name, items }) => {
    return {
      id: name,
      title: `${name}`,
      items: [
        {
          id: `clusterProfileGroup${name}`,
          isCollapsible: false,
          options: items.map((x) => ({
            id: x,
            title: `Cluster ${x}`,
          })) as MenuOptionProps[],
        },
      ],
    }
  })
}

type DateRange = null | { from: DateTime; to: DateTime }
type ClusterMinMax = null | { from: DateTime; to: DateTime }

interface UserClusterResources {
  menuItems: Array<ClusterMenuItem>
  processTimeList: Array<string>
  selectedProcessTime: string | null
  handleProcessTimeChange: (e: any, newOption: string | null) => void
  isLoading: boolean
  clusterMinMax: ClusterMinMax
}

const getClusterDataTransferMap = (commodityType, splitOptions) => ({
  [ClusterDataSeriesType.ClusterProfile]: {
    labelPrefix: 'Cluster Profile',
    selectedCalculation: calculationOptions.find(
      ([calc]) => calc === Calculation.Average,
    ),
    type: getLoadDataSeriesTypeByCommodity(commodityType),
    calculationOptions: undefined,
    splitOptions: undefined,
    selectedSplit: null,
  },
  [ClusterDataSeriesType.MeterProfile]: {
    labelPrefix: 'Meter Profile',
    selectedCalculation: calculationOptions[0],
    type: getLoadDataSeriesTypeByCommodity(commodityType),
    calculationOptions,
    splitOptions,
    selectedSplit: null,
  },
  [ClusterDataSeriesType.MeterCount]: {
    labelPrefix: 'Meter Count',
    selectedCalculation: [
      Calculation.CountDistinct,
      'countd',
    ] as SingleCalculationOption,
    type: DataSeriesType.Dimensionless,
    calculationOptions: undefined,
    splitOptions,
    selectedSplit: null,
  },
})

const DataMenu = ({
  menuItems,
  commodityType,
  splitOptions,
}: {
  menuItems: ClusterMenuItem[]
  commodityType: string
  splitOptions: SplitOptions
}) => {
  const [dataSeriesType, setDataSeriesType] = useState<ClusterDataSeriesType>(
    ClusterDataSeriesType.ClusterProfile,
  )

  const clusterDataTransferMap = getClusterDataTransferMap(
    commodityType,
    splitOptions,
  )

  return (
    <>
      <FormControl>
        <InputLabel shrink htmlFor="seriesType">
          Type
        </InputLabel>
        <Select
          value={dataSeriesType}
          name="seriesType"
          onChange={(e) => {
            setDataSeriesType(e.target.value as ClusterDataSeriesType)
          }}
          margin="dense"
        >
          {Object.entries(clusterDataTransferMap).map(([type, obj]) => {
            return (
              <MenuItem key={type} value={type}>
                {obj.labelPrefix}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      {menuItems.map(({ id, title, items }, i) => {
        return (
          <DraggableMenu
            key={`ClusterProfileItem${title}`}
            title={title}
            isCollapsible
            defaultMenuIsOpen={i <= 0}
            innerMenus={items}
            onDrag={(e, menuOption) => {
              const {
                labelPrefix,
                type,
                selectedCalculation,
                calculationOptions: localCalcOptions,
                splitOptions: localSplitOptions,
                selectedSplit,
              } = clusterDataTransferMap[dataSeriesType]
              setDataSeriesData(e.dataTransfer, {
                label: `${labelPrefix} ${title} - ${menuOption.id}`,
                id: getClusterDataSeriesId(dataSeriesType, id, menuOption.id),
                type,
                selectedCalculation,
                calculationOptions: localCalcOptions,
                splitOptions: localSplitOptions,
                selectedSplit,
              })
            }}
          />
        )
      })}
    </>
  )
}

function useClustersResources(
  datasource: string,
  generalMinMaxRange: DateRange,
): UserClusterResources {
  const [
    [selectedProcessTime, processTimeList],
    setProcessTimeState,
  ] = useState<[string | null, Array<string>]>([null, []])
  const [clusterMinMax, setClusterMinMax] = useState<ClusterMinMax>(null)
  const handleProcessTimeChange = (event, newSelectedOption: string | null) => {
    if (newSelectedOption) {
      setProcessTimeState((prevState) => {
        const [selected, list] = prevState
        if (
          selected !== newSelectedOption &&
          list.find((option) => option === newSelectedOption)
        ) {
          return [newSelectedOption, list]
        }
        return prevState
      })
    }
  }
  useEffect(() => {
    // we only fetch unique process times when we have no other options.
    if (processTimeList.length === 0 && generalMinMaxRange) {
      getDruidDatasourceDistinctData(datasource, 'process_time', {
        dateRange: {
          from: generalMinMaxRange.from.toISODate(),
          to: generalMinMaxRange.to.toISODate(),
        },
      }).then((data) => {
        if (data.length) {
          const optionsList = [...data].sort((a, b) => b - a)
          setProcessTimeState([optionsList[0], optionsList])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalMinMaxRange, processTimeList])

  const [[menuItems, isLoading], setState] = useState<
    [Array<ClusterMenuItem>, boolean]
  >(() => [createClusterProfileMenuItems([]), true])

  const fetchStuff = useCallback(async () => {
    if (selectedProcessTime) {
      setState([createClusterProfileMenuItems([]), true])
      const [minISODate, maxISODate] = await Promise.all([
        getDruidDatasourceMinTime(datasource, [
          {
            name: 'process_time',
            values: [selectedProcessTime],
          },
        ]),
        getDruidDatasourceMaxTime(datasource, [
          {
            name: 'process_time',
            values: [selectedProcessTime],
          },
        ]),
      ])
      const min = DateTime.fromISO(minISODate, { zone: 'utc' })
      const max = DateTime.fromISO(maxISODate, { zone: 'utc' })
      const clusterGroups = await getDruidDatasourceDistinctData(
        datasource,
        'clusters',
        {
          columns: [
            {
              name: 'process_time',
              values: [selectedProcessTime],
            },
          ],
          dateRange: {
            from: min.toISODate(),
            to: max.toISODate(),
          },
        },
      )
      const groupsAndClustersMap = clusterGroups.reduce((acc, val) => {
        const [group, cluster] = val.split('|')
        const groupInfo = acc.get(group)
        if (groupInfo) {
          groupInfo.items.push(cluster)
        } else {
          acc.set(group, { id: group, name: group, items: [cluster] })
        }
        return acc
      }, new Map<string, { id: string; name: string; items: Array<string> }>())

      setClusterMinMax({ from: min, to: max })
      setState([
        createClusterProfileMenuItems(
          Array.from(groupsAndClustersMap.values()),
        ),
        false,
      ])
    }
  }, [datasource, selectedProcessTime])

  useEffect(() => {
    fetchStuff()
  }, [fetchStuff])

  return {
    menuItems,
    selectedProcessTime,
    processTimeList,
    handleProcessTimeChange,
    isLoading,
    clusterMinMax,
  }
}

function createManager(
  options: {
    datasource: string
    dateRange: { from: DateTime; to: DateTime } | null
    selectedFilters: Array<SelectedFilter>
    selectedProcessTime: string | null
    selectedTimezone: string
    rangeFilters?: Array<RangeFilter>
  },
  config: AxiosRequestConfig,
): CardsDataSeriesManager<ClustersFetchingArgument> {
  const {
    datasource,
    dateRange,
    selectedFilters,
    selectedProcessTime,
    selectedTimezone,
    rangeFilters,
  } = options
  return {
    getArgumentToFetch: (card, dataSeries) => {
      if (!dateRange || !selectedProcessTime) {
        return null
      }
      const parsedClusterId = parseClusterDataSeriesId(dataSeries.id)
      return {
        ...getFetchingArgumentCommonValues(
          card,
          dataSeries,
          selectedFilters,
          selectedTimezone,
          rangeFilters,
        ),
        ...parsedClusterId,
        processTime: selectedProcessTime,
      }
    },
    mergeGroupedFetchedData: (keyExpression, data, parsedKeys) => {
      const { group, splitIdentifier } = parsedKeys[0]
      return mergeDataSeries(keyExpression, data, group[0], splitIdentifier)
    },
    correctCardDataSeries: (card, dataSeries) => dataSeries,
    fetchData: (fetchingArgument) => {
      return fetchDataForClusterProfile(
        {
          fetchingArgument,
          datasource,
          dateRange,
          timezone: selectedTimezone,
        },
        config,
      )
    },
    key: dateRange,
  }
}

interface Props {
  savedDashboardInfo: Dashboard
  datasource: string
  filtersDefinitions: DashboardConfigItem['filters']
  timeFilters?: DashboardConfigItem['timeFilters']
  timeInterval: number
  onSave: HandleSave
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
}

export const useClusterAnalysis = ({
  savedDashboardInfo,
  datasource,
  filtersDefinitions,
  timeInterval,
  rangeFiltersDefinition,
}: Omit<Props, 'onSave'>) => {
  const commodityType = savedDashboardInfo.commodity.name

  const defaultFrom: GeneralDateSelector = savedDashboardInfo.from || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'startData',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const defaultTo: GeneralDateSelector = savedDashboardInfo.to || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'endData',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const dates = useDates(datasource, defaultFrom, defaultTo)
  const { dateRange, setMinMax, edges } = dates
  const {
    menuItems,
    isLoading: areMenuItemsLoading,
    handleProcessTimeChange,
    processTimeList,
    selectedProcessTime,
    clusterMinMax,
  } = useClustersResources(datasource, edges)

  const createCancellableRequestConfig = useCancellableRequests()

  const filters = useTimeAndDimensionFilters(
    savedDashboardInfo.filters,
    timeInterval,
    () =>
      getDruidDistinctAll(
        datasource,
        filtersDefinitions.map((e) => e.name),
      ),
    filtersDefinitions,
    rangeFiltersDefinition,
    () =>
      getMinMaxForColumns(
        datasource,
        rangeFiltersDefinition?.map((e) => e.name) ?? [],
      ),
    savedDashboardInfo.rangeFilters,
    savedDashboardInfo.timeFilters,
  )

  const { dimensionFilters, timeFilters, rangeFilters } = filters

  const splitOptions = useMemo(() => {
    return dimensionFilters.filters.map(
      (filter) => [filter.name, filter.title] as SingleSplitOption,
    )
  }, [dimensionFilters.filters])

  /**
   * TODO: Eventually we will want to do more than just meter profile options. The problem is how we do the aggregation at the parent level, which for example: counts and meter profiles don't share. We would need to allow the user to select aggregation calculations when building the calculator.
   */
  const calculatorDataSeriesOptions = useMemo(() => {
    const arr: CalculatorDataSeriesOption[] = []
    const meterProfileInfo = getClusterDataTransferMap(
      commodityType,
      splitOptions,
    )[ClusterDataSeriesType.MeterProfile]
    const unit = getDefinition(meterProfileInfo.type).defaultUnitValue
    menuItems.forEach((item) => {
      item.items[0].options.forEach((option) => {
        arr.push({
          id: getClusterDataSeriesId(
            ClusterDataSeriesType.MeterProfile,
            item.id,
            option.id,
          ),
          title: `${meterProfileInfo.labelPrefix}`,
          subtitle: `${item.title} ${option.title}`,
          unit: unit !== 'dimensionless' ? unit : null,
          tagOptions: calculationOptions.map((calc) => ({
            value: calc[0],
            label: calc[1],
          })),
        })
      })
    })
    return arr
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems])

  useEffect(() => {
    // When we get a new min max we need to set this so our date range selectors refelct the new values
    if (clusterMinMax) {
      setMinMax(clusterMinMax.from, clusterMinMax.to)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterMinMax])

  const [selectedTimezone, setTimezone] = useState<string>(
    savedDashboardInfo?.timezone || DateTime.local().zoneName,
  )

  const [isHourEndingSelected, setIsHourEndingSelected] = useState<Boolean>(
    savedDashboardInfo?.isHourEndingSelected || false,
  )

  const manager = useMemo(
    () =>
      createManager(
        {
          datasource,
          dateRange,
          selectedFilters: [
            ...dimensionFilters.selectedFilters,
            ...timeFilters.selectedFilters,
          ],
          selectedProcessTime,
          selectedTimezone,
          rangeFilters: rangeFilters?.filters,
        },
        createCancellableRequestConfig(),
      ),
    [
      createCancellableRequestConfig,
      datasource,
      dateRange,
      dimensionFilters.selectedFilters,
      timeFilters.selectedFilters,
      selectedProcessTime,
      selectedTimezone,
      rangeFilters.filters,
    ],
  )

  const buildCalculatedDataSeries: BuildCalculatorDataSeries = (
    calculator,
    referenceSeries,
  ) => {
    const dataSeriesCombined: DataSeries[] = []
    const clusterDataTransferMap = getClusterDataTransferMap(
      commodityType,
      splitOptions,
    )
    Object.values(calculator.expressionDataIdMap).forEach((val) => {
      const info = parseClusterDataSeriesId(val.id)
      const clusterInfo = clusterDataTransferMap[info.type]
      const selectedCalculation = val.selectedOption
        ? ([val.selectedOption, val.selectedOption] as SingleCalculationOption)
        : undefined
      dataSeriesCombined.push({
        id: val.id,
        label: val.id.split('|').join(' '),
        calculationOptions: clusterInfo.calculationOptions,
        selectedCalculation,
        splitOptions,
        selectedSplit: null,
        type: clusterInfo.type,
        selectedFilters: dimensionFilters.selectedFilters,
        rangeFilters: rangeFilters.filters,
      })
    })
    return {
      ...referenceSeries,
      id: calculator.uid,
      label: calculator.name,
      splitOptions,
      selectedSplit: null,
      type: getDataSeriesTypeFromUnit(calculator.unit || ''),
      customUnit: calculator.unit || '',
      groupedItems: {
        expression: calculator.expression,
        expressionDataIdMap: calculator.expressionDataIdMap,
        expressionScope: calculator.expressionDataIdMap,
        dataSeries: dataSeriesCombined,
        key: calculator.expression,
      },
    }
  }
  return {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    menuItems,
    areMenuItemsLoading,
    handleProcessTimeChange,
    processTimeList,
    selectedProcessTime,
    clusterMinMax,
  }
}

/**
 * Cluster Analysis page
 */
const ClusterAnalysis = ({
  savedDashboardInfo,
  datasource,
  filtersDefinitions,
  timeInterval,
  onSave,
  rangeFiltersDefinition,
}: Props) => {
  const commodityType = savedDashboardInfo.commodity.name

  const {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    menuItems,
    areMenuItemsLoading,
    handleProcessTimeChange,
    processTimeList,
    selectedProcessTime,
    clusterMinMax,
  } = useClusterAnalysis({
    savedDashboardInfo,
    datasource,
    filtersDefinitions,
    timeInterval,
    rangeFiltersDefinition,
  })

  const { isInitializing, from, to, setFromDate, setToDate } = dates

  const {
    dimensionFilters,
    timeFilters,
    rangeFilters,
    onChange: onFilterChange,
    isInitializing: areFiltersInitializing,
  } = filters

  const isLoading = areMenuItemsLoading || !selectedProcessTime

  return (
    <BaseDashboard
      dashboardId={savedDashboardInfo.dashboardId}
      calculatorLabelAccessor={(option) => `${option.title} ${option.subtitle}`}
      calculatorDataSeriesOptions={calculatorDataSeriesOptions}
      buildCalculatorDataSeries={buildCalculatedDataSeries}
      calculators={savedDashboardInfo.calculators}
      isInitializing={isInitializing || areFiltersInitializing}
      areFiltersLoading={false}
      isLoading={isLoading}
      cardsDataSeriesManager={manager}
      defaultCards={savedDashboardInfo.cards}
      filtersDefinitions={filtersDefinitions}
      selectedTimeFilters={timeFilters.selectedFilters}
      filters={dimensionFilters.filters}
      timeFilters={timeFilters.filters}
      onFilterChange={onFilterChange}
      rangeFilters={rangeFilters.filters}
      onRangeFilterChange={rangeFilters.onChange}
      timeInterval={timeInterval}
      defaultNewCardGrouping={Group.Hourly}
      selectedTimezone={selectedTimezone}
      onTimezoneChange={(e, newTimezone) => setTimezone(newTimezone)}
      isHourEndingSelected={isHourEndingSelected}
      onIsHourEndingSelectedChange={(e, newIsHourEndingSelected) =>
        setIsHourEndingSelected(newIsHourEndingSelected)
      }
      onSave={(e, cards, extras) =>
        onSave(e, {
          ...extras,
          cards,
          filters: dimensionFilters.filters,
          timeFilters: timeFilters.filters,
          timezone: selectedTimezone,
          rangeFilters: rangeFilters.filters.map(({ name, value }) => ({
            name,
            value,
          })),
          isHourEndingSelected,
          from: from || undefined,
          to: to || undefined,
        })
      }
      extraOptions={
        clusterMinMax &&
        from &&
        to && (
          <>
            <DateSelector
              startData={clusterMinMax.from}
              endData={clusterMinMax.to}
              quantity={from.quantity}
              multiplier={from.multiplier}
              identifier={from.identifier}
              dateType={from.dateType}
              value={from.value}
              onChange={setFromDate}
              label={`Start (${
                from.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
            <DateSelector
              endData={clusterMinMax.from}
              startData={clusterMinMax.to}
              quantity={to.quantity}
              multiplier={to.multiplier}
              identifier={to.identifier}
              dateType={to.dateType}
              value={to.value}
              onChange={setToDate}
              label={`End (${
                to.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
          </>
        )
      }
    >
      <Autocomplete
        onChange={handleProcessTimeChange}
        options={processTimeList}
        value={selectedProcessTime}
        getOptionLabel={(option) =>
          DateTime.fromMillis(parseInt(option, 10)).toLocaleString(
            DateTime.DATETIME_MED,
          )
        }
        disabled={!selectedProcessTime}
        fullWidth
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder="Process Time"
            margin="dense"
            label="Process Time"
            variant="standard"
          />
        )}
      />
      <SideBarMenu title="Data Series" isLoading={isLoading}>
        <DataMenu
          menuItems={menuItems}
          commodityType={commodityType}
          splitOptions={splitOptions}
        />
      </SideBarMenu>
    </BaseDashboard>
  )
}

export default ClusterAnalysis
