/** These were moved to their own file for ease of mocking during tests */

// caret funcrtions are inspired by: https://js.plainenglish.io/how-to-find-the-caret-inside-a-contenteditable-element-955a5ad9bf81
export function getCaretCoordinates() {
  let x = 0
  let y = 0
  const isSupported = typeof window.getSelection !== 'undefined'
  if (isSupported) {
    const selection = window.getSelection()
    if (selection?.rangeCount !== 0) {
      const range = selection?.getRangeAt(0).cloneRange()
      range?.collapse(true)
      const rect = range?.getClientRects()[0]
      if (rect) {
        x = rect.left
        y = rect.top
      }
    }
  }
  return { x, y }
}

export function getCaretIndex(element) {
  let position = 0
  const isSupported = typeof window.getSelection !== 'undefined'
  if (isSupported) {
    const selection = window.getSelection()
    const range = window?.getSelection()?.getRangeAt(0)
    if (selection?.rangeCount !== 0 && range) {
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      position = preCaretRange.toString().length
    }
  }
  return position
}

export function setCaret(element: HTMLElement, position: number) {
  /** This is failing dramatically on our tests and we're not testing caret positions.
   * This could probably be mocked, but I don't necessarily think it's wise to take the caret functions out of here unless it's really necessary...
   * FIXME: When we use a better end to end testing tool such as cypress we can run this.
   */
  // if (process.env.NODE_ENV === 'test') {
  //   return
  // }
  const range = document.createRange()
  const sel = window.getSelection()
  let p = position
  if (element?.childNodes) {
    if (p < element.childNodes.length - 1 && p >= 0) {
      p = position + 1
    }
    range.setStart(element?.childNodes[p], 0)
    range.collapse(true)
    sel?.removeAllRanges()
    sel?.addRange(range)
  }
}
