import { Avatar, fade, styled, Theme } from '@material-ui/core'
import { Palette } from '@material-ui/core/styles/createPalette'
import { safeColor } from 'shared/utils/themeHelpers'

interface Props {
  // Material Theme
  theme: Theme
  // Optional color defined in the application's palette
  color?: keyof Palette
  // Whether avatar is disabled (will push disabled background color)
  disabled?: boolean
}
/**
 * Nothing much to this... just a nice little shortcut that allows users to set an "official background color" to the avatar component
 */
const ColoredAvatar = styled(Avatar)(
  ({ theme, color, disabled = false }: Props) => {
    if (!color) {
      return {}
    }
    if (disabled) {
      return {
        backgroundColor: `${theme.palette.grey[300]} !important`,
      }
    }
    return {
      backgroundColor: fade(safeColor(theme, color), 0.8),
    }
  },
)

export default ColoredAvatar
