import {
  fade,
  makeStyles,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Theme,
  withStyles,
} from '@material-ui/core'

/* 
  Module that provides styled components for some table elements so they can be reused on the
  application.
*/

const createRowStyles = (theme: Theme): any => ({
  '&:nth-child(even)': {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
  },
})

const createCellStyles = (theme: Theme): any => ({
  ...theme.typography.body2,
})

const createHeaderRowStyles = (theme: Theme): any => ({
  minHeight: 60,
  background: theme.palette.primary.main,
})

const createHeaderCellStyles = (theme: Theme): any => ({
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  textTransform: 'uppercase',
})

export const useTableRowsStyles = makeStyles<Theme>((theme) => ({
  headerRow: createHeaderRowStyles(theme),
  headerCell: createHeaderCellStyles(theme),
  row: createRowStyles(theme),
  cell: createCellStyles(theme),
}))

export const TableRow = withStyles((theme) => ({
  root: createRowStyles(theme),
  head: createHeaderRowStyles(theme),
}))(MuiTableRow)

export const TableCell = withStyles((theme) => ({
  root: createCellStyles(theme),
  head: createHeaderCellStyles(theme),
}))(MuiTableCell)
