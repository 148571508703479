import React, { useMemo, useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Dashboard,
  GeneralDateSelector,
  Group,
  DataSeries,
  SelectedFilter,
  DataSeriesType,
  SingleSplitOption,
  DashboardConfigItem,
  SingleCalculationOption,
  WeatherStation,
} from 'shared/types'
import TimeSeriesMenu from 'modules/demand/products/loadForecast/components/TimeSeriesMenu'
import useForecastsTimeSeries from 'modules/demand/products/loadForecast/hooks/useForecastsTimeSeries'
import { DateTime } from 'luxon'
import AlertContext from 'shared/contexts/AlertsContext'
import DateSelector from 'shared/components/DateSelector'
import { getLoadDataSeriesTypeByCommodity } from 'shared/helpers'
import BaseDashboard, {
  CardsDataSeriesManager,
  BuildCalculatorDataSeries,
} from 'shared/components/BaseDashboard/BaseDashboard'
import { HandleSave } from 'shared/pages/DashboardDetail'
import { mergeDataSeries } from 'shared/utils/dataSeriesHelpers'
import { getDataSeriesTypeFromUnit, getDefinition } from 'shared/common/units'
import { calculationOptions } from 'shared/common/dashboardOptions'
import {
  ForecastFetchingArgument,
  forecastsDataSeriesHandlers,
  getTimeSeriesItems,
  TimeSeriesItem,
  weatherDataSeriesHandlers,
  WeatherFetchingArgument,
  DataSeriesLabelItems,
} from 'modules/demand/common/helpers'
import useTimeAndDimensionFilters from 'shared/hooks/useTimeAndDimensionFilters'
import { getSelectedOptionLabel } from 'shared/components/Calculator/Calculator'
import { AxiosRequestConfig } from 'axios'
import {
  getDruidDistinctAll,
  getMinMaxForColumns,
} from 'modules/demand/common/apiClient'
import { addNewDashboard, updateDashboard } from 'shared/apiClient'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import useCancellableRequests from 'shared/hooks/useCancellableRequests'
import AddNewDashboardDialog from 'shared/components/AddNewDashboardDialog'
import useDashboardConfigItems from 'shared/hooks/useDashboardConfigItems'
import useDates from '../hooks/useDates'
import WeatherMenu from '../components/WeatherMenu'

/**
 * Upper limit of forecasts to load
 */
const MAX_NUMBER_OF_FORECASTS = 15

interface ManagerWeatherType {
  kind: 'weather'
  argument: WeatherFetchingArgument
}

interface ManagerForecastType {
  kind: 'forecast'
  argument: ForecastFetchingArgument
}

function getForectastDataSeriesInfo({
  id,
  title,
  subtitle,
  processTime,
}: TimeSeriesItem) {
  return {
    id,
    label: `${title} ${subtitle}`,
    legendText: subtitle,
    processTime,
  }
}

function getCorrectedDataSeries(
  dataSeriesItem: DataSeries,
  timeSeriesMap: Map<string, TimeSeriesItem>,
): DataSeries | null {
  const matchingTimeSeries = timeSeriesMap.get(dataSeriesItem.id)
  /* Only those dataseries that have a matching timeseries will be
          maintained */
  if (matchingTimeSeries) {
    if (dataSeriesItem.extras?.processTime === matchingTimeSeries.processTime) {
      /* If the process time is the same (same id && same process time),
              there is no need to change it */
      return dataSeriesItem
    }
    /* In other case (same id &6 DIFFERENT process time), some information
              has to be updated on the dataseries */
    const { label, processTime, legendText } = getForectastDataSeriesInfo(
      matchingTimeSeries,
    )
    return {
      ...dataSeriesItem,
      label,
      extras: { ...dataSeriesItem.extras, processTime, legendText },
    }
  }
  return null
}

function createManager(
  options: {
    selectedFilters: Array<SelectedFilter>
    datasource: string
    dateRange: { from: DateTime; to: DateTime } | null
    commodityType: string
    selectedTimezone: string
    areForecastsLoading: boolean
    timeSeries: TimeSeriesItem[]
    rangeFilters?: Array<RangeFilter>
  },
  config: AxiosRequestConfig,
): CardsDataSeriesManager<ManagerForecastType | ManagerWeatherType> {
  const {
    selectedFilters,
    datasource,
    dateRange,
    selectedTimezone,
    commodityType,
    rangeFilters,
    areForecastsLoading,
    timeSeries,
  } = options

  const forecastsDataProvider = forecastsDataSeriesHandlers.createDataProvider(
    {
      selectedFilters,
      datasource,
      dateRange,
      isPerformance: false,
      type: getLoadDataSeriesTypeByCommodity(commodityType),
      timezone: selectedTimezone,
      rangeFilters,
    },
    config,
  )

  const weatherDataProvider = weatherDataSeriesHandlers.createDataProvider(
    {
      dateRange,
      timezone: selectedTimezone,
    },
    config,
  )

  const timeSeriesMap = new Map(timeSeries.map((x) => [x.id, x]))
  return {
    getArgumentToFetch(card, dataSeries) {
      let fetchingArgument: ['weather' | 'forecast', any]
      switch (dataSeries.type) {
        case DataSeriesType.Temperature:
          fetchingArgument = [
            'weather',
            weatherDataProvider.getArgumentToFetch(card, dataSeries),
          ]
          break
        default:
          fetchingArgument = [
            'forecast',
            forecastsDataProvider.getArgumentToFetch(card, dataSeries),
          ]
      }
      const [kind, argument] = fetchingArgument
      if (argument === null) {
        return null
      }
      return { kind, argument }
    },
    fetchData(fetchingArgument) {
      switch (fetchingArgument.kind) {
        case 'weather':
          return weatherDataProvider.fetchData(fetchingArgument.argument)
        default:
          return forecastsDataProvider.fetchData(fetchingArgument.argument)
      }
    },
    correctCardDataSeries:
      dateRange && !areForecastsLoading
        ? (card, dataSeries) => {
            switch (dataSeries.type) {
              case DataSeriesType.Temperature:
                return dataSeries
              default:
                return getCorrectedDataSeries(dataSeries, timeSeriesMap)
            }
          }
        : undefined,
    mergeGroupedFetchedData: (
      keyExpression,
      data,
      parsedKeys,
      dataSeries,
      card,
    ) => {
      const newData = parsedKeys.map((key, index) => {
        if (key.kind === 'weather' && weatherDataProvider.prepareFetchedData) {
          return weatherDataProvider.prepareFetchedData(
            data[index],
            card,
            dataSeries[index],
          )
        }
        return data[index]
      })
      const splitIdentifiersSet = new Set<undefined | string>()
      parsedKeys.forEach((k) => {
        if (k.kind === 'weather') {
          splitIdentifiersSet.add(undefined)
        } else {
          splitIdentifiersSet.add(k.argument.splitIdentifier)
        }
      })
      const splitIdentifiersArr = Array.from(splitIdentifiersSet)
      if (splitIdentifiersArr.length > 1) {
        throw new Error('Dataseries cannot be split')
      }
      return mergeDataSeries(
        keyExpression,
        newData,
        card.group,
        splitIdentifiersArr[0],
      )
    },
    prepareFetchedData(fetchedData, card, dataSeries) {
      if (dataSeries.groupedItems) {
        return fetchedData
      }
      switch (dataSeries.type) {
        case DataSeriesType.Dimensionless:
          return fetchedData
        case DataSeriesType.Temperature:
          return (
            weatherDataProvider.prepareFetchedData?.(
              fetchedData,
              card,
              dataSeries,
            ) ?? fetchedData
          )
        default:
          return (
            forecastsDataProvider.prepareFetchedData?.(
              fetchedData,
              card,
              dataSeries,
            ) ?? fetchedData
          )
      }
    },
    key: dateRange,
  }
}

export const useLongTermForecast = ({
  datasource,
  filtersDefinitions,
  savedDashboardInfo,
  timeInterval,
  rangeFiltersDefinition,
  weatherStations,
}: Omit<Props, 'onSave'>) => {
  const commodityType = savedDashboardInfo.commodity.name

  const defaultFrom: GeneralDateSelector = savedDashboardInfo.from || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'today',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const defaultTo: GeneralDateSelector = savedDashboardInfo.to || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'endData',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const dates = useDates(datasource, defaultFrom, defaultTo)

  const createCancellableRequestConfig = useCancellableRequests()

  const filters = useTimeAndDimensionFilters(
    savedDashboardInfo.filters,
    timeInterval,
    () =>
      getDruidDistinctAll(
        datasource,
        filtersDefinitions.map((e) => e.name),
      ),
    filtersDefinitions,
    rangeFiltersDefinition,
    () =>
      getMinMaxForColumns(
        datasource,
        rangeFiltersDefinition?.map((e) => e.name) ?? [],
      ),
    savedDashboardInfo.rangeFilters,
    savedDashboardInfo.timeFilters,
  )

  const { dimensionFilters, timeFilters, rangeFilters } = filters

  const splitOptions = useMemo(() => {
    return dimensionFilters.filters.map(
      (filter) => [filter.name, filter.title] as SingleSplitOption,
    )
  }, [dimensionFilters.filters])

  const [selectedTimezone, setTimezone] = useState<string>(
    savedDashboardInfo?.timezone || DateTime.local().zoneName,
  )

  const [isHourEndingSelected, setIsHourEndingSelected] = useState<Boolean>(
    savedDashboardInfo?.isHourEndingSelected || false,
  )

  const { forecasts, areForecastsLoading } = useForecastsTimeSeries(
    datasource,
    MAX_NUMBER_OF_FORECASTS,
    dates.dateRange,
  )

  const timeSeries = useMemo(
    () => getTimeSeriesItems(forecasts, selectedTimezone),
    [forecasts, selectedTimezone],
  )

  const calculatorDataSeriesOptions = useMemo(() => {
    const dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
    return timeSeries.map((e) => {
      return {
        id: `forecast-${e.id}`,
        subtitle: `${e.subtitle}`,
        title: 'Forecast',
        unit: getDefinition(dataSeriesType).defaultUnitValue,
        tagOptions: calculationOptions.map((calc) => ({
          value: calc[0],
          label: calc[1],
        })),
      }
    })
  }, [timeSeries, commodityType])

  const manager = useMemo<
    CardsDataSeriesManager<ManagerForecastType | ManagerWeatherType>
  >(
    () =>
      createManager(
        {
          selectedFilters: [
            ...dimensionFilters.selectedFilters,
            ...timeFilters.selectedFilters,
          ],
          datasource,
          dateRange: dates.dateRange,
          commodityType,
          selectedTimezone,
          areForecastsLoading,
          timeSeries,
          rangeFilters: rangeFilters.filters,
        },
        createCancellableRequestConfig(),
      ),
    [
      createCancellableRequestConfig,
      areForecastsLoading,
      datasource,
      dates.dateRange,
      commodityType,
      dimensionFilters.selectedFilters,
      timeFilters.selectedFilters,
      selectedTimezone,
      timeSeries,
      rangeFilters.filters,
    ],
  )

  const [selectedStation, setWeatherStation] = useState(
    weatherStations ? weatherStations[0] : { label: '', id: '' },
  )

  const buildCalculatedDataSeries: BuildCalculatorDataSeries = (
    calculator,
    referenceSeries,
  ) => {
    const newExpressionDataIdMap: object = {}
    const dataSeriesCombined: DataSeries[] = []
    Object.entries(calculator.expressionDataIdMap).forEach(([k, val]) => {
      const [, id] = val.id.split('-')
      const selectedTimeSeries = timeSeries[parseInt(id, 10)]
      if (selectedTimeSeries) {
        const info = getForectastDataSeriesInfo(selectedTimeSeries)
        newExpressionDataIdMap[k] = id
        const selectedCalculation = val.selectedOption
          ? ([
              val.selectedOption,
              val.selectedOption,
            ] as SingleCalculationOption)
          : undefined
        dataSeriesCombined.push({
          ...referenceSeries,
          id: info.id,
          label: info.label,
          calculationOptions,
          selectedCalculation,
          splitOptions,
          selectedSplit: null,
          type: DataSeriesType.PowerLoad,
          extras: {
            processTime: info.processTime,
            legendText: info.legendText,
          },
          selectedFilters: dimensionFilters.selectedFilters,
          rangeFilters: rangeFilters.filters,
        })
      }
    })

    return {
      ...referenceSeries,
      id: calculator.uid,
      label: calculator.name,
      splitOptions,
      type: getDataSeriesTypeFromUnit(calculator.unit || ''),
      customUnit: calculator.unit || '',
      groupedItems: {
        expression: calculator.expression,
        expressionDataIdMap: calculator.expressionDataIdMap,
        expressionScope: newExpressionDataIdMap,
        dataSeries: dataSeriesCombined,
        key: calculator.expression,
      },
    }
  }

  return {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    forecasts,
    areForecastsLoading,
    timeSeries,
    selectedStation,
    setWeatherStation,
  }
}

interface Props {
  datasource: string
  filtersDefinitions: DashboardConfigItem['filters']
  savedDashboardInfo: Dashboard
  timeInterval: number
  onSave: HandleSave
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
  weatherStations?: Array<WeatherStation>
}

/**
 * Long Term page component
 */
const LongTermForecast = ({
  datasource,
  filtersDefinitions,
  savedDashboardInfo,
  timeInterval,
  onSave,
  rangeFiltersDefinition,
  weatherStations,
}: Props) => {
  const commodityType = savedDashboardInfo.commodity.name
  const [defaultCards, setDefaultCards] = useState(savedDashboardInfo.cards)

  const history = useHistory()
  const location = useLocation()

  const { dashboardConfigItems } = useDashboardConfigItems(
    savedDashboardInfo.type,
  )

  const [isFormOpen, setIsCreatingDashboard] = useState<boolean>(false)

  const onDashboardCreate = (id) => {
    history.push(`/demand/load-forecast/dashboard/${id}`)
  }

  const handleAddNewClose = () => {
    // if dialog is open and loading do not close
    setIsCreatingDashboard((prevState) => !prevState)
  }

  const publishNotification = useContext(AlertContext)

  const {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    areForecastsLoading,
    timeSeries,
    selectedStation,
    setWeatherStation,
    forecasts,
  } = useLongTermForecast({
    datasource,
    filtersDefinitions,
    savedDashboardInfo,
    timeInterval,
    rangeFiltersDefinition,
    weatherStations,
  })

  useEffect(() => {
    if (savedDashboardInfo.dashboardId === 'unsaved' && timeSeries.length > 0) {
      try {
        const query = new URLSearchParams(location.search)
        const encodedForecastObject = query.get('object')
        const decodedForecastObject = Buffer.from(
          encodedForecastObject as string,
          'base64',
        ).toString()
        const forecastToExplore = JSON.parse(decodedForecastObject)
        const processTimeForForecast = DateTime.fromISO(
          forecastToExplore.processTime.toString(),
        ).toUTC()

        const forecastToPutInCard = forecasts.find(
          (forecast) =>
            forecast === processTimeForForecast.toISO() ||
            forecast === processTimeForForecast.toISODate(),
        )

        const timeSeriesItem = timeSeries.find(
          (item) => item.processTime === forecastToPutInCard,
        )
        if (timeSeriesItem) {
          const {
            id,
            label,
            legendText,
            processTime,
          }: DataSeriesLabelItems = getForectastDataSeriesInfo(timeSeriesItem)

          const dataSeries: DataSeries[] = [
            {
              id,
              label,
              calculationOptions,
              selectedCalculation: calculationOptions[0],
              splitOptions,
              selectedSplit: null,
              type: getLoadDataSeriesTypeByCommodity(commodityType),
              extras: {
                processTime,
                legendText,
              },
            },
          ]
          const newDefaultCard = {
            ...savedDashboardInfo.cards[0],
            dataSeries,
          }
          setDefaultCards([newDefaultCard])
        }
      } catch (error) {
        setDefaultCards(savedDashboardInfo.cards)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedDashboardInfo, timeSeries])

  const { min, max, isInitializing, from, to, setFromDate, setToDate } = dates

  const {
    dimensionFilters,
    timeFilters,
    rangeFilters,
    onChange: onFilterChange,
    isInitializing: areFiltersInitializing,
  } = filters

  return (
    <BaseDashboard
      dashboardId={savedDashboardInfo.dashboardId}
      calculatorLabelAccessor={(option) => {
        const selectedOptionLabel = getSelectedOptionLabel(option)
        return [selectedOptionLabel, option.subtitle, 'Load']
          .filter((e) => e)
          .join(' ')
      }}
      calculatorDataSeriesOptions={calculatorDataSeriesOptions}
      buildCalculatorDataSeries={buildCalculatedDataSeries}
      calculators={savedDashboardInfo.calculators}
      isInitializing={isInitializing || areFiltersInitializing}
      isLoading={areForecastsLoading}
      cardsDataSeriesManager={manager}
      defaultCards={defaultCards}
      filtersDefinitions={filtersDefinitions}
      areFiltersLoading={false}
      filters={dimensionFilters.filters}
      selectedTimeFilters={timeFilters.selectedFilters}
      timeFilters={timeFilters.filters}
      rangeFilters={rangeFilters.filters}
      onRangeFilterChange={rangeFilters.onChange}
      timeInterval={timeInterval}
      defaultNewCardGrouping={Group.Yearly}
      selectedTimezone={selectedTimezone}
      onTimezoneChange={(e, newTimezone) => setTimezone(newTimezone)}
      isHourEndingSelected={isHourEndingSelected}
      onIsHourEndingSelectedChange={(e, newIsHourEndingSelected) =>
        setIsHourEndingSelected(newIsHourEndingSelected)
      }
      onFilterChange={onFilterChange}
      onSave={(e, cards, extras) => {
        if (savedDashboardInfo.dashboardId === 'unsaved') {
          setIsCreatingDashboard(true)
        } else {
          onSave(e, {
            ...extras,
            cards,
            filters: dimensionFilters.filters,
            rangeFilters: rangeFilters.filters.map(({ name, value }) => ({
              name,
              value,
            })),
            timeFilters: timeFilters.filters,
            timezone: selectedTimezone,
            isHourEndingSelected,
            from: from || undefined,
            to: to || undefined,
          })
        }
      }}
      extraOptions={
        min &&
        max &&
        from &&
        to && (
          <>
            <DateSelector
              startData={min}
              endData={max}
              quantity={from.quantity}
              multiplier={from.multiplier}
              identifier={from.identifier}
              dateType={from.dateType}
              value={from.value}
              onChange={setFromDate}
              label={`Start (${
                from.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
            <DateSelector
              startData={min}
              endData={max}
              quantity={to.quantity}
              multiplier={to.multiplier}
              identifier={to.identifier}
              dateType={to.dateType}
              value={to.value}
              onChange={setToDate}
              label={`End (${
                to.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
          </>
        )
      }
    >
      <TimeSeriesMenu
        timeSeries={timeSeries}
        onDrag={(e, selectedTimeSeries) => {
          const {
            id,
            label,
            legendText,
            processTime,
          } = getForectastDataSeriesInfo(selectedTimeSeries)
          forecastsDataSeriesHandlers.prepareDataTransfer(
            e.dataTransfer,
            id,
            label,
            splitOptions,
            getLoadDataSeriesTypeByCommodity(commodityType),
            legendText,
            processTime,
          )
        }}
        isLoading={areForecastsLoading}
      />
      {weatherStations && selectedStation && (
        <WeatherMenu
          showNormal
          stations={weatherStations}
          onDrag={(e, { id, series, station }) => {
            weatherDataSeriesHandlers.prepareDatatransfer(
              e.dataTransfer,
              id,
              `${station.label} ${series}`,
              { station: station.id, series, stationLabel: station.label },
            )
          }}
          selectedStation={selectedStation}
          onStationChange={(e, station) => setWeatherStation(station)}
        />
      )}
      {isFormOpen && (
        <AddNewDashboardDialog
          onAdd={(model) => {
            const {
              configId,
              filters: forecastFilters,
              rangeFilters: forecastRangeFilters,
              timeFilters: forecastTimeFilters,
              calculators,
              timezone,
              isHourEndingSelected: forecastHourEndingSelected,
              from: forecastFromDate,
              to: forecastToDate,
            } = savedDashboardInfo
            const dashboardObjectToSave = {
              cards: defaultCards,
              configId,
              filters: forecastFilters.map((filter) => ({
                ...filter,
                title: undefined,
              })),
              name: model?.name,
              description: model?.description,
              rangeFilters: forecastRangeFilters,
              timeFilters: forecastTimeFilters,
            }

            addNewDashboard(dashboardObjectToSave)
              .then((dashboardId) => {
                const dashboardUpdateBody = {
                  cards: defaultCards,
                  filters: forecastFilters.map((filter) => ({
                    ...filter,
                    title: undefined,
                  })),
                  rangeFilters: forecastRangeFilters ?? [],
                  timeFilters: forecastTimeFilters ?? [],
                  calculators,
                  timezone,
                  isHourEndingSelected: forecastHourEndingSelected,
                  from: {
                    ...forecastFromDate,
                    quantity: forecastFromDate?.quantity || 0,
                  },
                  to: {
                    ...forecastToDate,
                    quantity: forecastToDate?.quantity || 0,
                  },
                }
                updateDashboard(dashboardId, dashboardUpdateBody)
                publishNotification('Dashboard saved successfully', 'success')
                onDashboardCreate(dashboardId)
              })
              .catch((err) => {
                if (err.response.status === 404) {
                  /**
                   * A 404 error is technically not found. The backend will return a 404 because it tries to update a dashboard not owned by the requester.
                   */
                  publishNotification(
                    'You cannot save a dashboard that was shared with you',
                    'error',
                  )
                }
              })
          }}
          onClose={handleAddNewClose}
          dashboardConfigItems={dashboardConfigItems || []}
          copyMeta={{
            name: '',
            description: '',
            commodity: savedDashboardInfo?.commodity?.name,
            category: savedDashboardInfo?.category?.name,
            level: savedDashboardInfo?.level?.name,
          }}
          isUnsavedDashboard={savedDashboardInfo.dashboardId === 'unsaved'}
        />
      )}
    </BaseDashboard>
  )
}

export default LongTermForecast
