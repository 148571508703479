import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  styled,
  Select,
  MenuItem,
} from '@material-ui/core'
import { DataSeriesTypeUnit, DataSeriesType } from 'shared/types'
import { getDataSeriesTypeInfo } from 'shared/common/units'

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'block',
}))

interface Props {
  dataSeriesType: DataSeriesType
  selectedUnit?: DataSeriesTypeUnit
  onUnitSelect: any
}

const Units = ({ dataSeriesType, selectedUnit, onUnitSelect }: Props) => {
  /**
   * Custom data series will give you conversions
   */
  if (dataSeriesType === DataSeriesType.Custom) {
    return null
  }
  const {
    label,
    units,
    selectedUnit: realSelectedUnit,
  } = getDataSeriesTypeInfo(dataSeriesType, selectedUnit?.value)
  return (
    <StyledFormControl>
      <FormLabel component="legend">{label}</FormLabel>
      {units.length <= 3 ? (
        <RadioGroup
          row
          aria-label={dataSeriesType}
          name={dataSeriesType}
          value={realSelectedUnit.value}
        >
          {units.map((unit) => (
            <FormControlLabel
              key={unit.value}
              value={unit.value}
              label={unit.label}
              control={<Radio color="primary" />}
              onChange={(e) => {
                onUnitSelect(e, unit)
              }}
            />
          ))}
        </RadioGroup>
      ) : (
        <Select
          value={realSelectedUnit.value}
          name="units"
          onChange={(e) => {
            onUnitSelect(
              e,
              units.find((unit) => unit.value === e.target.value),
            )
          }}
        >
          {units.map((unit) => (
            <MenuItem key={unit.value} value={unit.value}>
              {unit.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </StyledFormControl>
  )
}

export default Units
