import React, { useContext, useState, MouseEvent } from 'react'
import { DateTime } from 'luxon'
import {
  makeStyles,
  Theme,
  TextField,
  InputLabel,
  Button,
  Card,
  styled,
} from '@material-ui/core'
import UserContext from 'shared/contexts/UserContext'
import { JobDescriptionModel, JobTypes } from 'shared/types'
import ImportCsvButton, {
  Props as ImportCsvButtonProps,
} from 'shared/components/ImportCsvButton'

export interface Props {
  /**
   * This callback is executed when the user means to create a job which would check the status of the input to check if it's ready for the calculations or not.
   *
   * <pre>
   * (event: MouseEvent, jobDescription: JobDescriptionModel) => any
   * </pre>
   */
  onCheckStatusClick: (
    event: MouseEvent,
    jobDescription: JobDescriptionModel,
  ) => any
  /**
   * This callback is executed when the user means to creates a job which runs the calculations of the valid input data (returned from the Check Status action)
   *
   * <pre>
   * (event: MouseEvent, jobDescription: JobDescriptionModel) => any
   * </pre>
   */
  onRunClick: (event: MouseEvent, jobDescription: JobDescriptionModel) => any
  /**
   * Callback function that will be invoked when the import CSV process finishes.
   *
   * It will provide the complete result of the process with the data and all aditable
   * information on the first argument.
   */
  onInputsImportComplete: ImportCsvButtonProps['onImportComplete']
  /**
   * Expected column names for the CSV field to import the inputs.
   */
  inputsFields: ImportCsvButtonProps['fields']
  /**
   * Function that will be invoked once per row of the CSV file so it can be validated
   * and transformed as it seems fit. It will receive the current object with the row
   * information as first argument and a function to report errors on it as the second.
   */
  processInput: ImportCsvButtonProps['processRow']
  /**
   * Value that indiates loading state. Typically used when posting to server
   */
  isLoading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  label: {
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(0, 1),
  },
  inputField: {
    width: '255px',
    margin: theme.spacing(0, 1),
    fontSize: theme.typography.body2.fontSize,
  },
  button: {
    width: '120px',
    margin: theme.spacing(0, 1),
    fontSize: theme.typography.button.fontSize,
  },
}))

const StyledFieldContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

/**
 * This component will enable the user to custom_name a job as well as add notes pertaining to that job.
 *
 * This section would also comes with 'Check Status' and 'Run' buttons which would allow the user to
 * create a job on the calculations engine, its type depending on the button pressed.
 *
 * It also allows the parent element to decide if its "Run" button should be enabled.
 */
const JobDescription = ({
  onCheckStatusClick,
  inputsFields,
  processInput,
  onInputsImportComplete,
  onRunClick,
  isLoading = false,
}: Props) => {
  const user = useContext(UserContext)
  const userName = user.email.split('@')[0]

  const [name, setName] = useState('')
  const [notes, setNotes] = useState('')

  const styles = useStyles()

  const clickHandler = (e, type: JobTypes) => {
    const jobName = name || `${userName}_${DateTime.local().toMillis()}`
    const jobDescription: JobDescriptionModel = {
      name: jobName,
      notes,
      type,
    }

    if (type === 'calculation') {
      onRunClick(e, jobDescription)
    } else if (type === 'check_status') {
      onCheckStatusClick(e, jobDescription)
    }
  }

  return (
    <Card className={styles.root}>
      <div>
        <StyledFieldContainer>
          <InputLabel className={styles.label} shrink>
            Name
          </InputLabel>
          <TextField
            disabled={isLoading}
            className={styles.inputField}
            id="outlined-basic"
            variant="outlined"
            inputProps={{
              /* TODO: Check if this assumed value is appropriate */
              maxLength: 30,
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="username_timestamp"
          />
        </StyledFieldContainer>
        <StyledFieldContainer>
          <InputLabel className={styles.label} shrink>
            Notes
          </InputLabel>
          <TextField
            disabled={isLoading}
            multiline
            rows={1}
            className={styles.inputField}
            id="outlined-multiline-flexible"
            value={notes}
            inputProps={{
              /* TODO: Check if this assumed value is appropriate */
              maxLength: 256,
            }}
            error={notes.length > 256}
            onChange={(e) => setNotes(e.target.value)}
            variant="outlined"
            placeholder="run description..."
          />
        </StyledFieldContainer>
      </div>
      <div>
        <ImportCsvButton
          fields={inputsFields}
          removeDuplicates
          onImportComplete={onInputsImportComplete}
          processRow={processInput}
          isDisabled={isLoading}
        />
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={(e) => clickHandler(e, 'calculation')}
        >
          Calculate
        </Button>
      </div>
    </Card>
  )
}

export default JobDescription
