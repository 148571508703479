/**
 * Function that returns a series of utilities to handle drag and drop
 * behavior for specific types of data.
 * @param mimePrefix A unique'ish "mime type" for the kind of data that
 * will be "drag & dropped".
 *
 * E.g: "x-mydata-"
 * @param getSuffix A function that will get a
 * string-like value for the data to be
 * dragged and add it to the mime type so multiple
 * elements can be dragged at once if needed, and also
 * allowing to check them before the "drop" event so
 * the a dropdzone can decide if it allows the data or not.
 */
export default function generateDragAndDropUtilities<T, U extends string>(
  mimePrefix: string,
  getSuffix: (data: T) => U,
) {
  const completeMimePrefix = `text/${mimePrefix}`
  const setDataItem = (dataTransfer: DataTransfer, dataItem: T) => {
    dataTransfer.setData(
      `${completeMimePrefix}${getSuffix(dataItem)}`,
      JSON.stringify(dataItem),
    )
  }

  function getDataItemsMimeTypes(dataTransfer: DataTransfer) {
    return dataTransfer.types.filter(
      (mimeType) => mimeType.indexOf(completeMimePrefix) === 0,
    )
  }

  const getDataItemsSuffixes = (dataTransfer: DataTransfer) => {
    return getDataItemsMimeTypes(dataTransfer).map(
      (mimeType) => mimeType.replace(completeMimePrefix, '') as U,
    )
  }

  const getDataItems = (dataTransfer: DataTransfer) => {
    return getDataItemsMimeTypes(dataTransfer).map(
      (mimeType) => JSON.parse(dataTransfer.getData(mimeType)) as T,
    )
  }
  return {
    setDataItem,
    getDataItemsSuffixes,
    getDataItems,
  }
}
