import React, { ReactNode, useCallback } from 'react'
import { SnackbarProvider, useSnackbar, VariantType } from 'notistack'
import { Button, IconButton, SvgIcon, makeStyles } from '@material-ui/core'
import { X } from 'react-feather'

export interface AlertContextType {
  (
    message: string,
    variant: VariantType,
    action?: { label: string; onExecute: () => void },
  ): void
}
interface PersonProps {
  children: ReactNode
}
const AlertContext = React.createContext<AlertContextType>(() => {})

const useStyles = makeStyles((theme) => ({
  warning: {
    color: `${theme.palette.warning.contrastText} !important`,
    backgroundColor: `${theme.palette.warning.light} !important`,
  },
  success: {
    color: `${theme.palette.success.contrastText} !important`,
    backgroundColor: `${theme.palette.success.light} !important`,
  },
  error: {
    color: `${theme.palette.error.contrastText} !important`,
    backgroundColor: `${theme.palette.error.light} !important`,
  },
  info: {
    color: `${theme.palette.info.contrastText} !important`,
    backgroundColor: `${theme.palette.info.light} !important`,
  },
}))

const autoHideDurationMap = {
  warning: 10000,
}

const NewSnackComponent = ({ children }: PersonProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const setData = useCallback<AlertContextType>(
    (message, typeVariant, action) => {
      enqueueSnackbar(message, {
        variant: typeVariant,
        autoHideDuration: autoHideDurationMap[typeVariant],
        action: (key) => (
          <>
            {action ? (
              <Button
                size="medium"
                variant="text"
                color="inherit"
                onClick={action.onExecute}
              >
                {action.label}
              </Button>
            ) : null}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <SvgIcon fontSize="small">
                <X />
              </SvgIcon>
            </IconButton>
          </>
        ),
      })
    },
    [enqueueSnackbar, closeSnackbar],
  )

  return (
    <AlertContext.Provider value={setData}>{children}</AlertContext.Provider>
  )
}

export const AlertContextProvider = ({ children }: PersonProps) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      preventDuplicate
      classes={{
        variantInfo: classes.info,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
        variantError: classes.error,
      }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <NewSnackComponent>{children}</NewSnackComponent>
    </SnackbarProvider>
  )
}

export default AlertContext
