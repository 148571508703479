import { v4 as uuidv4 } from 'uuid'
import {
  Calculation,
  Group,
  CardDefinition,
  Visualization,
  DataSeriesType,
  Checkbox,
  SelectableValue,
} from './types'

export const CalculationValues = Object.values(Calculation)
export function isCalculation(
  calculation: string | Calculation,
): calculation is Calculation {
  return CalculationValues.includes(calculation as any)
}

const GroupValues = Object.values(Group)
export function isGroup(group: string | Group): group is Group {
  return GroupValues.includes(group as any)
}

export const isTimeSeries = (
  groupType: Group | string | null,
): groupType is Group => {
  return Boolean(
    groupType !== null &&
      isGroup(groupType) &&
      ![Group.Hour24, Group.DayOfWeek, Group.DayType, Group.Month12].includes(
        groupType,
      ),
  )
}

export const isTimeFilter = (
  groupType: Group | string | null,
): groupType is Group => {
  return Boolean(groupType !== null && isGroup(groupType))
}

export const createNewCard = (
  defaultGroup: Group = Group.Monthly,
): CardDefinition => {
  return {
    id: uuidv4(),
    title: 'untitled card',
    dataSeries: [],
    group: defaultGroup,
    visualization: Visualization.Line,
  }
}

export const downloadCsvFile = (fileName: string, csv: string) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`,
  )
  element.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const getLoadDataSeriesTypeByCommodity = (
  commodity: string,
): DataSeriesType.PowerLoad | DataSeriesType.GasLoad => {
  if (commodity === 'gas') return DataSeriesType.GasLoad

  return DataSeriesType.PowerLoad
}

/**
 * Compares labels in the following order:
 * nulls (and/or undefined) first,
 * then numbers,
 * then local string comparison.
 */
function compareLabels(labelA: SelectableValue, labelB: SelectableValue) {
  if (labelA === null || labelB === null) {
    /* The null or undefined is always "lesser" than a 
    defined value.  */
    return Number(labelA !== null) - Number(labelB !== null)
  }

  if (typeof labelA !== typeof labelB) {
    /* In case the types are different, the numbers have always precedence over strings */
    return (
      Number(typeof labelA !== 'number') - Number(typeof labelB !== 'number')
    )
  }

  /* If both values have the same type, the comparison is specific to it */
  switch (typeof labelA) {
    case 'string':
      return labelA.localeCompare(labelB as string)
    case 'number':
      return labelA - (labelB as number)
    default:
      /* Unexpected types won't be comparable and deemed "equal" by that matter */
      return 0
  }
}

export const sortFilters = (currentItem: Checkbox, nextItem: Checkbox) => {
  return (
    Number(nextItem.isChecked) - Number(currentItem.isChecked) ||
    Number(currentItem.isDisabled) - Number(nextItem.isDisabled) ||
    compareLabels(currentItem.label, nextItem.label)
  )
}

export function createGroups(card): Array<Group> {
  let groups: Array<Group> = []
  if (card.visualization !== Visualization.Table) {
    if (card.group !== null) {
      groups.push(card.group)
    }
  } else {
    groups = (card.tableGroups || []).map(({ groupType }) => groupType).sort()
  }
  return groups
}
