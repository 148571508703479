import {
  queryDruidDatasource,
  queryDruidDatasourceForMape,
  getWeather,
  MapeTypes,
  FilterColumn,
} from 'modules/demand/common/apiClient'
import { DateTime } from 'luxon'
import crossfilter from 'crossfilter2'
import reductio from 'reductio'
import {
  cleanFiltersForValidNetworkRequest,
  mergeSelectedFilters,
} from 'shared/utils/filtersOperations'
import {
  Group,
  DataSeries,
  Calculation,
  WeatherTimeSeries,
  DataSeriesType,
  SplitOptions,
  DataSeriesData,
  CardDefinition,
  SelectedFilter,
  DynamicColumnsConfig,
  SpatialFilter,
  Visualization,
} from 'shared/types'
import { createGroups, isTimeSeries } from 'shared/helpers'
import { setDataSeriesData } from 'shared/utils/dataSeriesDragAndDrop'
import { calculationOptions } from 'shared/common/dashboardOptions'
import { DataProvider } from 'shared/hooks/useCardsDataProviders'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import { AxiosRequestConfig } from 'axios'

interface FetchingArgumentCommonValues {
  calculation: Calculation
  timezoneOffset: number
  group: Array<Group>
  selectedFilters: SelectedFilter[]
  splitIdentifier?: string
  selectedSeriesFilters?: SelectedFilter[]
  rangeFilters?: Array<RangeFilter>
  spatialFilters?: SpatialFilter[]
  visualization: Visualization
  limit?: number
  skip?: number
  columnName?: string
}

export interface DataSeriesLabelItems {
  id: string
  label: string
  legendText: string
  processTime?: string
}

export interface ScenariosFetchingArgument
  extends FetchingArgumentCommonValues {
  scenarios: Array<string>
  groupColumnsForMap: string[]
}

export interface ForecastFetchingArgument extends FetchingArgumentCommonValues {
  forecastId: string
  dashboardConfigItems?: DynamicColumnsConfig
}

export interface MapeFetchingArgument
  extends Omit<FetchingArgumentCommonValues, 'calculation'> {
  type: MapeTypes
}

export interface ClustersFetchingArgument extends FetchingArgumentCommonValues {
  type: string
  clusterName: string
  clusterItem: string
  processTime: string | null
}

export interface DataInsightsFetchingArgument
  extends FetchingArgumentCommonValues {
  dataInsights: Array<string>
}

export interface WeatherFetchingArgument {
  station: string
  series?: WeatherTimeSeries
  forecastId?: string
}

/**
 * Encapsulated helper function to get usual values for a fetching argument
 * using the card, dataseries, filters and timezone.
 * @param card
 * @param dataSeries
 * @param selectedFilters
 * @param selectedTimezone
 */
export function getFetchingArgumentCommonValues(
  card: CardDefinition,
  dataSeries: DataSeries,
  selectedFilters: Array<SelectedFilter>,
  selectedTimezone: string,
  rangeFilters?: Array<RangeFilter>,
  spatialFilters?: SpatialFilter[],
): FetchingArgumentCommonValues {
  const globalAndIndividualSeriesFilterIntersection = mergeSelectedFilters(
    selectedFilters,
    dataSeries.selectedFilters ?? [],
  )

  return {
    timezoneOffset: DateTime.local().setZone(selectedTimezone).offset,
    calculation: dataSeries.selectedCalculation?.[0] as Calculation,
    selectedFilters: cleanFiltersForValidNetworkRequest(
      globalAndIndividualSeriesFilterIntersection,
    ),
    group: createGroups(card),
    splitIdentifier: dataSeries.selectedSplit
      ? dataSeries.selectedSplit[0]
      : undefined,
    rangeFilters,
    spatialFilters,
    visualization: card.visualization,
    limit: card.meterTableOptions?.pageSize,
    skip: card.meterTableOptions
      ? card.meterTableOptions?.pageNumber * card.meterTableOptions?.pageSize
      : 0,
    columnName: dataSeries.extras?.columnName,
  }
}

const fetchDataForForecast = async (
  options: {
    datasource: string
    dateRange: null | {
      from: DateTime
      to: DateTime
    }
    isPerformance: boolean
    timezone: string
    fetchArgument: ForecastFetchingArgument
    dynamicColumnsConfig?: DynamicColumnsConfig
  },
  config?: AxiosRequestConfig,
): Promise<DataSeriesData> => {
  const {
    datasource,
    dateRange,
    isPerformance,
    timezone,
    fetchArgument,
    dynamicColumnsConfig,
  } = options
  const {
    forecastId,
    calculation,
    group,
    selectedFilters,
    splitIdentifier,
    rangeFilters,
    limit,
    skip,
    visualization,
    columnName,
  } = fetchArgument
  if (!dateRange) {
    throw new Error(`No date range specified`)
  }

  const queryColumn = isPerformance ? forecastId : 'forecast'

  const filterColumns = isPerformance
    ? selectedFilters
    : [{ name: 'process_time', values: [forecastId] }, ...selectedFilters]

  const groupingByVisulizationType =
    visualization === Visualization.MeterTable &&
    calculation === Calculation.CountDistinct
      ? []
      : group.map((groupType) => ({ groupType }))

  const splits = await queryDruidDatasource(
    {
      datasource,
      columns: [
        {
          name:
            calculation === Calculation.CountDistinct
              ? dynamicColumnsConfig?.meterIdColumn || ''
              : queryColumn,
          aggregation: calculation,
        },
      ],
      appliedFilters: {
        columns: filterColumns,
        dateRange: {
          from: dateRange.from.toISODate(),
          to: dateRange.to.toISODate(),
        },
      },
      grouping: groupingByVisulizationType,
      splitIdentifier,
      timezone,
      appliedRangeFilters: rangeFilters,
      limit: calculation === Calculation.CountDistinct ? undefined : limit,
      skip: calculation === Calculation.CountDistinct ? undefined : skip,
      visualization,
    },
    config,
  )

  return {
    mainValueAccessor: (() => {
      switch (calculation) {
        case Calculation.CountDistinct:
          return dynamicColumnsConfig?.meterIdColumn || ''
        case Calculation.StringFirst:
          return columnName || ''
        default:
          return queryColumn
      }
    })(),
    splits,
  }
}

/* Encapsulated logic to prepare the DataSeries for "forecasts" */
export const forecastsDataSeriesHandlers = {
  prepareDataTransfer: (
    dataTransfer: DataTransfer,
    id: string,
    label: string,
    splitOptions: SplitOptions,
    type: DataSeriesType,
    legendText?: string,
    processTime?: string,
    selectedFilters?: SelectedFilter[],
    rangeFilters?: Array<RangeFilter>,
    isMeterCount?: Boolean,
  ) => {
    setDataSeriesData(dataTransfer, {
      id,
      label,
      calculationOptions: isMeterCount ? undefined : calculationOptions,
      selectedCalculation: isMeterCount
        ? [Calculation.CountDistinct, 'countd']
        : calculationOptions[0],
      splitOptions,
      selectedSplit: null,
      type,
      extras: {
        processTime,
        legendText,
      },
      selectedFilters,
      rangeFilters,
    })
  },
  createDataProvider: (
    options: {
      selectedFilters: SelectedFilter[]
      datasource: string
      dateRange
      isPerformance: boolean
      type: DataSeriesType
      timezone: string
      rangeFilters?: Array<RangeFilter>
      dynamicColumnsConfig?: DynamicColumnsConfig
    },
    config?: AxiosRequestConfig,
  ): DataProvider<ForecastFetchingArgument, DataSeriesData> => {
    const {
      selectedFilters,
      datasource,
      dateRange,
      isPerformance,
      timezone,
      rangeFilters,
      dynamicColumnsConfig,
    } = options
    return {
      getArgumentToFetch(card, dataSeries) {
        return dateRange
          ? {
              ...getFetchingArgumentCommonValues(
                card,
                dataSeries,
                selectedFilters,
                timezone,
                rangeFilters,
              ),
              forecastId: dataSeries.extras?.processTime || dataSeries.id,
              dynamicColumnsConfig,
            }
          : null
      },
      fetchData(fetchArgument) {
        return fetchDataForForecast(
          {
            datasource,
            fetchArgument,
            dateRange,
            isPerformance,
            timezone,
            dynamicColumnsConfig,
          },
          config,
        )
      },
      prepareFetchedData(fetchedData, card, dataSeries) {
        return !dataSeries.extras?.legendText
          ? fetchedData
          : { ...fetchedData, label: dataSeries.extras.legendText }
      },
      key: dateRange,
    }
  },
}

const reduceGroup = reductio()
  .avg((d) => d.temperature)
  .min((d) => d.temperature)
  .max(true)

const REDUCTIO_CALCULATION_MAP = {
  [Calculation.Sum]: 'sum',
  [Calculation.Average]: 'avg',
  [Calculation.Max]: 'max',
  [Calculation.Min]: 'min',
}

export interface TimeSeriesItem {
  id: string
  title: string
  subtitle: string
  processTime?: string
}

export function getTimeSeriesItems(
  processTimes: string[],
  timeZone: string = 'utc',
) {
  let index = 0
  return processTimes.reduce((acc, processTime) => {
    const processTimeToUtc = DateTime.fromISO(processTime, {
      zone: 'utc',
    }).toISO()
    const date = DateTime.fromISO(processTimeToUtc, { zone: timeZone })
    if (date.isValid) {
      const dateString = date.toLocaleString(DateTime.DATETIME_MED)
      const title = `${index === 0 ? 'Latest' : 'Previous'} Forecast`
      const subtitle = dateString
      acc.push({
        id: index.toString(),
        title,
        subtitle,
        processTime,
      })
      index += 1
    }
    return acc
  }, [] as Array<TimeSeriesItem>)
}

export const getInnerMenu = (items: Array<TimeSeriesItem>) => {
  if (items.length === 0) {
    return []
  }

  const options = items.map((item) => ({
    id: item.id,
    title: item.title,
    subtitle: item.subtitle,
    extras: { processTime: item.processTime },
  }))

  const [curr, ...prev] = options

  return [
    {
      id: 'currentForecast',
      isCollapsible: false,
      options: [curr],
    },
    {
      id: 'previousForecasts',
      title: 'Previous Forecasts',
      options: prev,
    },
  ]
}

const prepareWeatherData = (
  dataSeries: DataSeries,
  fetchedDataSeriesData: any,
  group: CardDefinition['group'],
): DataSeriesData => {
  /* There is confidence on weather type dataseries to have the
  selected calculation defined */
  if (!dataSeries.selectedCalculation)
    throw new Error(`No calculation was specified`)

  const {
    groupMonth,
    groupMonthOfYear,
    groupDay,
    groupDayOfWeek,
    groupHour,
    groupHourOfDay,
    groupAll,
    groupYear,
  } = fetchedDataSeriesData.crossfilter

  const crossfilterGroupMap = {
    [Group.Hourly]: groupHour,
    [Group.Hour24]: groupHourOfDay,
    [Group.Daily]: groupDay,
    [Group.DayOfWeek]: groupDayOfWeek,
    [Group.Monthly]: groupMonth,
    [Group.Month12]: groupMonthOfYear,
    [Group.Yearly]: groupYear,
  }

  const crossfilterGroup = group ? crossfilterGroupMap[group] : groupAll

  if (!crossfilterGroup) {
    throw new Error('Cannot group by selection')
  }

  const reductioCalculation =
    REDUCTIO_CALCULATION_MAP[dataSeries.selectedCalculation[0]]

  const isUsingDateKeys = group ? isTimeSeries(group) : false

  const reducedGroupedData = reduceGroup(groupAll).value()
  const reducedSeriesData = group
    ? reduceGroup(crossfilterGroup)
        .all()
        .map(({ key, value }) => ({
          ...value,
          [group]: isUsingDateKeys ? new Date(key).toISOString() : key,
        }))
    : [reducedGroupedData]

  const dataSeriesData = {
    splits: [
      {
        splitValue: null,
        seriesData: reducedSeriesData,
        groupedData: reducedGroupedData,
      },
    ],
    mainValueAccessor: reductioCalculation,
    label: group ? dataSeries.extras?.legendText : dataSeries.label,
  }

  return dataSeriesData
}

const fetchDataForWeather = async (
  options: {
    fetchArgument: WeatherFetchingArgument
    dateRange: {
      from: DateTime
      to: DateTime
    }
    timezone: string
  },
  config?: AxiosRequestConfig,
): Promise<{ raw: any; crossfilter: any }> => {
  const { fetchArgument, dateRange, timezone } = options
  const { station, series, forecastId } = fetchArgument
  if (!dateRange) throw new Error('No date range set')
  const data = await getWeather(
    {
      station,
      startDate: dateRange.from.toISODate(),
      endDate: dateRange.to.toISODate(),
      series,
      timezone,
      forecastId,
    },
    config,
  )

  const cf = crossfilter(data)

  const groupHour = cf
    .dimension((d) => {
      // @ts-ignore
      return d.utc_datetime
    })
    .group()

  const groupHourOfDay = cf
    .dimension((d) => {
      // @ts-ignore
      return DateTime.fromISO(d.utc_datetime).hour
    })
    .group()

  const groupMonth = cf
    .dimension((d) => {
      // @ts-ignore
      return DateTime.fromISO(d.utc_datetime).startOf('month').toISODate()
    })
    .group()

  const groupMonthOfYear = cf
    .dimension((d) => {
      // @ts-ignore
      return DateTime.fromISO(d.utc_datetime).month
    })
    .group()

  const groupDay = cf
    .dimension((d) => {
      // @ts-ignore
      return DateTime.fromISO(d.utc_datetime).toISODate()
    })
    .group()

  const groupDayOfWeek = cf
    .dimension((d) => {
      // @ts-ignore
      return DateTime.fromISO(d.utc_datetime).weekday
    })
    .group()

  const groupYear = cf
    .dimension((d) => {
      // @ts-ignore
      return DateTime.fromISO(d.utc_datetime).startOf('year').toISODate()
    })
    .group()

  const groupAll = cf.groupAll()

  return {
    raw: data,
    crossfilter: {
      cf,
      groupMonth,
      groupMonthOfYear,
      groupDay,
      groupDayOfWeek,
      groupHour,
      groupHourOfDay,
      groupAll,
      groupYear,
    },
  }
}

export const weatherDataSeriesHandlers = {
  prepareDatatransfer: (
    dataTransfer: DataTransfer,
    id,
    label,
    stationAndSeries: {
      station: string
      stationLabel: string
      series?: WeatherTimeSeries
    },
    legendText?,
    processTime?: string,
  ) =>
    setDataSeriesData(dataTransfer, {
      id,
      label,
      type: DataSeriesType.Temperature,
      calculationOptions,
      selectedCalculation: calculationOptions[1],
      extras: { ...stationAndSeries, legendText, processTime },
    }),
  createDataProvider: (
    options: {
      dateRange
      timezone: string
    },
    config?: AxiosRequestConfig,
  ): DataProvider<WeatherFetchingArgument> => {
    const { dateRange, timezone } = options
    return {
      getArgumentToFetch(card, dataSeries) {
        return {
          forecastId: dataSeries?.extras?.processTime || dataSeries.id,
          timezoneOffset: timezone
            ? DateTime.local().setZone(timezone).offset
            : undefined,
          station: dataSeries.extras?.station,
          series: dataSeries.extras?.series,
          dateRange,
        }
      },
      fetchData(fetchArgument: WeatherFetchingArgument) {
        return fetchDataForWeather(
          {
            fetchArgument,
            dateRange,
            timezone,
          },
          config,
        )
      },
      prepareFetchedData(data, card, dataSeries) {
        return prepareWeatherData(dataSeries, data, card.group)
      },
    }
  },
}

export const fetchDataForMape = async (
  options: {
    filterColumns: FilterColumn[]
    group
    mapeType
    splitIdentifier
    datasource: string
    dateRange?: {
      from: DateTime
      to: DateTime
    } | null
    timezone
    rangeFilters
  },
  config?: AxiosRequestConfig,
): Promise<DataSeriesData> => {
  const {
    dateRange,
    datasource,
    mapeType,
    group,
    splitIdentifier,
    filterColumns,
    timezone,
    rangeFilters,
  } = options
  if (!dateRange) {
    throw new Error('No date range specified')
  }

  const splits = await queryDruidDatasourceForMape(
    {
      datasource,
      mapeType,
      appliedFilters: {
        columns: filterColumns,
        dateRange: {
          from: dateRange.from.toISODate(),
          to: dateRange.to.toISODate(),
        },
      },
      grouping: group.map((groupType) => ({ groupType })),
      splitIdentifier,
      timezone,
      appliedRangeFilters: rangeFilters,
    },
    config,
  )

  return {
    mainValueAccessor: mapeType,
    splits,
  }
}

export function getClusterDataSeriesId(
  type: string,
  clusterName: string,
  clusterItem: string,
) {
  return [type, clusterName, clusterItem].join('|')
}

export function parseClusterDataSeriesId(dataSeriesId: string) {
  const [type, clusterName, clusterItem] = dataSeriesId.split('|')
  return {
    type,
    clusterName,
    clusterItem,
  }
}

export async function fetchDataForScenario(
  options: {
    dateRange: any
    fetchingArgument: ScenariosFetchingArgument
    datasource: string
    timezone: string
    scenariosConfig: DynamicColumnsConfig
  },
  config,
): Promise<DataSeriesData> {
  const {
    datasource,
    dateRange,
    scenariosConfig,
    timezone,
    fetchingArgument,
  } = options

  const {
    scenarios,
    groupColumnsForMap,
    spatialFilters,
    selectedFilters,
    calculation,
    splitIdentifier,
    group,
    rangeFilters,
    limit,
    skip,
    visualization,
    columnName,
  } = fetchingArgument

  if (!dateRange) {
    throw new Error('No date range specified for the request')
  }

  const columns: any = [
    calculation
      ? {
          name: (() => {
            switch (calculation) {
              case Calculation.CountDistinct:
                return scenariosConfig.meterIdColumn || ''
              case Calculation.StringFirst:
                return columnName
              default:
                return scenariosConfig.valueColumn
            }
          })(),
          aggregation: calculation,
        }
      : undefined,
  ]

  if (spatialFilters) {
    columns.push({
      name: 'coordinates',
      aggregation: Calculation.StringFirst,
    })
    if (groupColumnsForMap) {
      groupColumnsForMap.forEach((groupColumn) => {
        columns.push({
          name: groupColumn,
          aggregation: Calculation.StringFirst,
        })
      })
    }
  }

  const filterColumns = [...selectedFilters]

  const groupingByVisulizationType =
    visualization === Visualization.MeterTable &&
    calculation === Calculation.CountDistinct
      ? []
      : group.map((groupType) => ({ groupType }))

  if (scenarios)
    filterColumns.push({
      name: scenariosConfig.valueTypeColumn,
      values: scenarios,
    })

  const splits = await queryDruidDatasource(
    {
      datasource,
      columns: columns.filter((col) => col),
      appliedFilters: {
        columns: [...filterColumns],
        dateRange: {
          from: dateRange.from.toISODate(),
          to: dateRange.to.toISODate(),
        },
      },
      /**
       * Removed the check to see if the calculation type was 'countd' because it was sending an empty
       * array '[]' as the  group value whereas we require proper grouping and splits to be applied to the query.
       */
      grouping: groupingByVisulizationType,
      splitIdentifier,
      timezone,
      appliedRangeFilters: rangeFilters,
      spatialFilters,
      limit: calculation === Calculation.CountDistinct ? undefined : limit,
      skip: calculation === Calculation.CountDistinct ? undefined : skip,
      visualization,
    },
    config,
  )

  return {
    mainValueAccessor: (() => {
      switch (calculation) {
        case Calculation.CountDistinct:
          return scenariosConfig.meterIdColumn || ''
        case Calculation.StringFirst:
          return columnName || ''
        default:
          return scenariosConfig.valueColumn
      }
    })(),
    splits,
  }
}

export enum ClusterDataSeriesType {
  ClusterProfile = 'clusterProfile',
  MeterProfile = 'meterProfile',
  MeterCount = 'meterCount',
}

export async function fetchDataForClusterProfile(
  options: {
    fetchingArgument: ClustersFetchingArgument
    datasource: string
    dateRange: { from: DateTime; to: DateTime } | null
    timezone?: string
  },
  config: AxiosRequestConfig,
): Promise<DataSeriesData> {
  const { fetchingArgument, datasource, dateRange, timezone } = options
  const {
    processTime,
    calculation,
    selectedFilters,
    clusterItem,
    clusterName,
    group,
    splitIdentifier,
    type,
    rangeFilters,
  } = fetchingArgument
  if (!dateRange) {
    throw new Error('No date range specified for the request')
  }

  if (!processTime) {
    throw new Error('No "process time" specified for the request')
  }

  const columnName =
    type === ClusterDataSeriesType.MeterCount ? 'meter_id' : 'meter_profile'

  const splits = await queryDruidDatasource(
    {
      datasource,
      columns: [{ name: columnName, aggregation: calculation }],
      appliedFilters: {
        columns: [
          { name: 'clusters', values: [`${clusterName}|${clusterItem}`] },
          { name: 'process_time', values: [processTime] },
          ...selectedFilters,
        ],
        dateRange: {
          from: dateRange.from.toISODate(),
          to: dateRange.to.toISODate(),
        },
      },
      grouping: group.map((groupType) => ({ groupType })),
      splitIdentifier,
      timezone,
      appliedRangeFilters: rangeFilters,
    },
    config,
  )

  return {
    mainValueAccessor: columnName,
    splits,
  }
}
