/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import Papa from 'papaparse'
import { styled, makeStyles } from '@material-ui/core/styles'
import { Typography, MenuList, MenuItem } from '@material-ui/core'
import UserContext from 'shared/contexts/UserContext'
import Card from 'shared/components/DashboardCard/Card'
import { getOverviewCardsForUser, updateOverviewCard } from 'shared/apiClient'
import { getGroupOptionsForTimeInterval } from 'shared/common/dashboardOptions'
import { useShortTermForecast } from 'modules/demand/products/loadForecast/pages/ShortTermForecast'
import { useLongTermForecast } from 'modules/demand/products/loadForecast/pages/LongTermForecast'
import {
  Dashboard,
  DashboardConfigItem,
  DraggableResizableCard,
  LayoutMinusI,
  SimpleDashboard,
} from 'shared/types'
import { useScenarioAnalysis } from 'modules/demand/products/loadForecast/pages/ScenarioAnalysis'
import { useClusterAnalysis } from 'modules/demand/products/demandAnalytics/pages/ClusterAnalysis'
import { useShortTermPerformance } from 'modules/demand/products/loadForecast/pages/ShortTermPerformance'
import { useShortAndLongTermNOP } from 'modules/supply/products/risk/pages/ShortAndLongTermNOP'
import { useSupplyAndMarkToMarketkToMarket } from 'modules/supply/products/risk/pages/SupplyAndMarkToMarket'
import useCardsDataProviders from 'shared/hooks/useCardsDataProviders'
import { downloadCsvFile } from 'shared/helpers'
import { nanoid } from 'nanoid'
import DraggableResizableGrid, {
  GridItem,
} from '../../shared/components/DraggableResizableGrid'
import AlertContext from '../../shared/contexts/AlertsContext'

const StyledContent = styled('div')(() => ({
  gridArea: 'body',
  width: '65%',
  margin: 'auto',
}))

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(5, 0, 4, 0),
    textTransform: 'capitalize',
  },
  desc: {
    margin: theme.spacing(0, 0, 4, 0),
  },
}))

export const StyledMainMenu = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyContent: 'space-between',
  rowGap: `${theme.spacing(4)}px`,
}))

interface DashboardProps {
  savedDashboardInfo: Dashboard
  layout: LayoutMinusI | any
  lookupId: string
  datasource: DashboardConfigItem['datasource']
  timeInterval: DashboardConfigItem['timeInterval']
  dynamicColumnsConfig?: DashboardConfigItem['dynamicColumnsConfig']
  weatherStations: DashboardConfigItem['weatherStations']
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
  filtersDefinitions: DashboardConfigItem['filters']
}

interface GeneralProps {
  savedDashboardInfo: Dashboard
  datasource: DashboardConfigItem['datasource']
  timeInterval: DashboardConfigItem['timeInterval']
  dynamicColumnsConfig?: DashboardConfigItem['dynamicColumnsConfig']
  weatherStations: DashboardConfigItem['weatherStations']
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
  filtersDefinitions: DashboardConfigItem['filters']
}

const buildDashboardUrl = (dashboardType: string, dashboardId: string) => {
  switch (dashboardType) {
    case 'prod_load_forecast': {
      return `/demand/load-forecast/dashboard/${dashboardId}`
    }
    case 'prod_demand_analytics': {
      return `/demand/demand-analytics/dashboard/${dashboardId}`
    }
    case 'prod_risk_management': {
      return `/risk/risk-management/dashboard/${dashboardId}`
    }
    case 'prod_data_insights': {
      return `/insights/data-insights/dashboard/${dashboardId}`
    }
    default:
      return '/'
  }
}

interface CardWrapperProps {
  dashboard: Dashboard
  dataProvider: any
}

const CardWrapper = ({ dashboard, dataProvider }: CardWrapperProps) => {
  const history = useHistory()
  const [cards, setCards] = useState(dashboard.cards)
  const [singleCard] = cards
  const handleDownload = (title: string, downloadData) => {
    const csv = Papa.unparse(downloadData)
    downloadCsvFile(title, csv)
  }
  return (
    <Card
      key={singleCard.id}
      dragIconClassName="dashboard-card-drag-handle"
      definition={singleCard}
      onDownload={(e, title, data) => handleDownload(title, data)}
      onDefinitionChange={() => {}}
      defaultTitle={singleCard.title}
      onDrop={() => {}}
      canDrop={() => false}
      dataSeriesFilters={[]}
      groupOptions={getGroupOptionsForTimeInterval(0)}
      getData={dataProvider.getDataProvider(singleCard)}
      moreActionsContent={
        <MenuList>
          <MenuItem
            key="go-to-source"
            onClick={() => {
              history.push(
                buildDashboardUrl(dashboard.type, dashboard.dashboardId),
              )
            }}
          >
            View Source
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCards((prevCards) => {
                return prevCards.map((card) => {
                  if (card.id === singleCard.id) {
                    return { ...card, isSummaryView: !card.isSummaryView }
                  }
                  return card
                })
              })
            }}
          >
            {singleCard.isSummaryView ? `Options View` : `Summary View`}
          </MenuItem>
        </MenuList>
      }
    />
  )
}

const ShortTermForecast = ({
  savedDashboardInfo,
  datasource,
  timeInterval,
  filtersDefinitions,
  rangeFiltersDefinition,
  weatherStations,
}: Omit<GeneralProps, 'dynamicColumnsConfig'>) => {
  const state = useShortTermForecast({
    savedDashboardInfo,
    datasource,
    timeInterval,
    filtersDefinitions,
    rangeFiltersDefinition,
    weatherStations,
  })

  const dataProvider = useCardsDataProviders(
    savedDashboardInfo.cards,
    state.manager,
  )

  return (
    <CardWrapper dashboard={savedDashboardInfo} dataProvider={dataProvider} />
  )
}
const LongTermForecast = ({
  savedDashboardInfo,
  datasource,
  timeInterval,
  filtersDefinitions,
  rangeFiltersDefinition,
  weatherStations,
}: Omit<GeneralProps, 'dynamicColumnsConfig'>) => {
  const state = useLongTermForecast({
    savedDashboardInfo,
    datasource,
    timeInterval,
    filtersDefinitions,
    rangeFiltersDefinition,
    weatherStations,
  })

  const dataProvider = useCardsDataProviders(
    savedDashboardInfo.cards,
    state.manager,
  )

  return (
    <CardWrapper dashboard={savedDashboardInfo} dataProvider={dataProvider} />
  )
}

const ScenarioAnalysis = ({
  savedDashboardInfo,
  datasource,
  timeInterval,
  filtersDefinitions,
  rangeFiltersDefinition,
  weatherStations,
  dynamicColumnsConfig,
}: GeneralProps) => {
  const state = useScenarioAnalysis({
    datasource,
    scenariosConfig: dynamicColumnsConfig,
    filtersDefinitions,
    savedDashboardInfo,
    timeInterval,
    weatherStations,
    rangeFiltersDefinition,
  })

  const dataProvider = useCardsDataProviders(
    savedDashboardInfo.cards,
    state.manager,
  )

  return (
    <CardWrapper dashboard={savedDashboardInfo} dataProvider={dataProvider} />
  )
}

const ClusterAnalysis = ({
  savedDashboardInfo,
  datasource,
  timeInterval,
  filtersDefinitions,
  rangeFiltersDefinition,
}: Omit<GeneralProps, 'dynamicColumnsConfig'>) => {
  const state = useClusterAnalysis({
    savedDashboardInfo,
    datasource,
    filtersDefinitions,
    timeInterval,
    rangeFiltersDefinition,
  })

  const dataProvider = useCardsDataProviders(
    savedDashboardInfo.cards,
    state.manager,
  )

  return (
    <CardWrapper dashboard={savedDashboardInfo} dataProvider={dataProvider} />
  )
}

const ShortTermPerformance = ({
  datasource,
  filtersDefinitions,
  rangeFiltersDefinition,
  savedDashboardInfo,
  weatherStations = [],
  timeInterval,
  dynamicColumnsConfig,
}: GeneralProps) => {
  const state = useShortTermPerformance({
    datasource,
    filtersDefinitions,
    rangeFiltersDefinition,
    savedDashboardInfo,
    weatherStations,
    timeInterval,
    dynamicColumnsConfig,
  })

  const dataProvider = useCardsDataProviders(
    savedDashboardInfo.cards,
    state.manager,
  )

  return (
    <CardWrapper dashboard={savedDashboardInfo} dataProvider={dataProvider} />
  )
}
const ShortAndLongTermNOP = ({
  datasource,
  filtersDefinitions,
  rangeFiltersDefinition,
  savedDashboardInfo,
  timeInterval,
}: Omit<GeneralProps, 'dynamicColumnsConfig' | 'weatherStations'>) => {
  const state = useShortAndLongTermNOP({
    savedDashboardInfo,
    datasource,
    filtersDefinitions,
    timeInterval,
    rangeFiltersDefinition,
  })

  const dataProvider = useCardsDataProviders(
    savedDashboardInfo.cards,
    state.manager,
  )

  return (
    <CardWrapper dashboard={savedDashboardInfo} dataProvider={dataProvider} />
  )
}

const SupplyAndMarkToMarketToMarket = ({
  datasource,
  filtersDefinitions,
  rangeFiltersDefinition,
  savedDashboardInfo,
  timeInterval,
}: Omit<GeneralProps, 'dynamicColumnsConfig' | 'weatherStations'>) => {
  const state = useSupplyAndMarkToMarketkToMarket({
    savedDashboardInfo,
    datasource,
    filtersDefinitions,
    timeInterval,
    rangeFiltersDefinition,
  })

  const dataProvider = useCardsDataProviders(
    savedDashboardInfo.cards,
    state.manager,
  )

  return (
    <CardWrapper dashboard={savedDashboardInfo} dataProvider={dataProvider} />
  )
}

const buildCards = (dashboards: DashboardProps[]) => {
  return dashboards.map((p) => {
    switch (p.savedDashboardInfo.category.name) {
      case 'shortTermForecast': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <ShortTermForecast {...p} />
          </GridItem>
        )
      }
      case 'longTermForecast': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <LongTermForecast {...p} />
          </GridItem>
        )
      }
      case 'shortTermPerformance': {
        return (
          <GridItem layout={p.layout}>
            <ShortTermPerformance key={p.lookupId} {...p} />
          </GridItem>
        )
      }
      case 'dataInsights': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <ScenarioAnalysis {...p} />
          </GridItem>
        )
      }
      case 'scenarioAnalysis': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <ScenarioAnalysis {...p} />
          </GridItem>
        )
      }
      case 'loadDisagg': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <ScenarioAnalysis {...p} />
          </GridItem>
        )
      }
      case 'clusterAnalysis': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <ClusterAnalysis {...p} />
          </GridItem>
        )
      }
      case 'nop': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <ShortAndLongTermNOP {...p} />
          </GridItem>
        )
      }
      case 'supplyAndMarkToMarket': {
        return (
          <GridItem layout={p.layout} key={p.lookupId}>
            <SupplyAndMarkToMarketToMarket {...p} />
          </GridItem>
        )
      }
      default:
        return <div />
    }
  })
}

const Overview = () => {
  const user = useContext(UserContext)
  const [dashboards, setDashboards] = useState<DashboardProps[]>([])
  const classes = useStyles()
  const publishNotification = useContext(AlertContext)

  const updateCardLayout = async (layouts) => {
    // eslint-disable-next-line array-callback-return,consistent-return
    const updatePromises = Object.keys(layouts).map((lookupId) => {
      const dashboard = dashboards.find((d) => d.lookupId === lookupId)
      if (dashboard) {
        const card = dashboard.savedDashboardInfo.cards[0]
        // Only update if the layout is different from the saved
        if (
          card &&
          JSON.stringify(card.layout) !== JSON.stringify(layouts[lookupId])
        ) {
          return updateOverviewCard(
            card.id,
            dashboard.savedDashboardInfo?.dashboardId || '',
            {
              ...card,
              layout: {
                w: layouts[lookupId].w,
                h: layouts[lookupId].h,
                x: layouts[lookupId].x,
                y: layouts[lookupId].y,
              },
            },
          )
        }
      }
    })
    if (updatePromises.length > 0) {
      return Promise.all(updatePromises)
    }
    return false
  }

  useEffect(() => {
    getOverviewCardsForUser().then(async (res) => {
      const data = res.data as {
        card: DraggableResizableCard
        config: DashboardConfigItem
        dashboard: SimpleDashboard
      }[]
      /**
       * We combine all dashboard cards belonging to the same dashboard.
       * This way we can easily re-utilize our logic from each individual dashboard type.
       * Remember, individual dashboards / cards have dependency on things outside the card itself.
       * For example, Short Term and Long Term dashboards need to know what the latest forecast is.
       * */
      const map: { [key: string]: DashboardProps } = {}
      data.forEach((elem) => {
        const { card, dashboard, config } = elem
        const lookupId = nanoid()
        map[lookupId] = {
          savedDashboardInfo: {
            ...dashboard,
            cards: [card],
            configId: config.configId,
            category: config.category,
            commodity: config.commodity,
            level: config.level,
            type: config.type,
          },
          // @ts-ignore
          layout:
            JSON.stringify(card.layout) === '{}'
              ? { x: 1, y: 1, w: 6, h: 2 }
              : card.layout,
          lookupId: `${lookupId}-${card.id}`,
          datasource: config.datasource,
          timeInterval: config.timeInterval,
          dynamicColumnsConfig: config.dynamicColumnsConfig,
          weatherStations: config.weatherStations,
          rangeFiltersDefinition: config.rangeFilters,
          filtersDefinitions: config.filters,
        }
      })
      setDashboards(Object.values(map))
    })
  }, [])

  return (
    <StyledContent>
      <Typography variant="h4" className={classes.heading}>
        Welcome {user.name} !
      </Typography>
      <Typography variant="body2" className={classes.desc}>
        Create your own Overview by creating a dashboard view for any product
        and clicking &lsquo;Add to Overview&rsquo;. Come back here to see
        highlights from across the Innowatts platform.
      </Typography>
      <DraggableResizableGrid
        draggableHandle=".dashboard-card-drag-handle"
        onLayoutChange={(newLayoutByKey) => {
          updateCardLayout(newLayoutByKey)
            .then((changed) => {
              if (changed) {
                publishNotification('Layout Saved', 'success')
              }
            })
            .catch(() => {
              publishNotification(
                'Something went wrong while saving the layout',
                'error',
              )
            })
        }}
      >
        {buildCards(dashboards)}
      </DraggableResizableGrid>
    </StyledContent>
  )
}

export default Overview
