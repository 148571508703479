import { Reducer } from 'react'
import {
  Adjustment,
  Forecast,
  ForecastType,
  Level,
  Market,
} from '../../../../modules/demand/products/loadForecast/components/LoadForecastTypes'

/**
 * hierarchy of the structure is variable -> category -> level -> market -> data
 *
 *   availableMarkets: {
 *    ShortTermForecast: {
 *      level: Market[]
 *    }
 *  }
 *
 *  items: {
    ShortTermForecast: {
      level: Forecast[] | any
    } }
  }
 *
 */

export interface SummaryItemsStateType {
  loading: boolean
  canLoadMore: {
    [category: string]: {
      [level: string]: boolean
    }
  }
  isInitialized: boolean
  marketsAreInitialized: boolean
  filteredLoadMore: boolean
  availableMarkets: {
    [category: string]: {
      [level: string]: Market[]
    }
  }
  selectedMarkets: {
    [category: string]: {
      [level: string]: string[]
    }
  }
  availableLevels: {
    [category: string]: Level[]
  }
  selectedLevel: {
    [category: string]: string
  }
  items: {
    [category: string]: { [level: string]: any[] }
  }
  filteredItems: {
    [category: string]: any[]
  }
}

const setLoading = ({ value }): { type: string; value: boolean } => ({
  type: 'SET_LOADING',
  value,
})

const setInitialized = ({ value }): { type: string; value: boolean } => ({
  type: 'SET_INITIALIZED',
  value,
})

const setAvailableMarkets = ({
  marketsByLevel,
  category,
}): {
  type: string
  marketsByLevel: { [id: string]: Market[] }
  category: ForecastType
} => ({
  type: 'SET_AVAILABLE_MARKETS',
  marketsByLevel,
  category,
})

const setSelectedMarkets = ({
  markets,
  category,
}): { type: string; markets: string[]; category: ForecastType } => ({
  type: 'SET_SELECTED_MARKETS',
  markets,
  category,
})

const setAvailableLevels = ({
  levels,
  category,
}): { type: string; levels: Level[]; category: ForecastType } => ({
  type: 'SET_AVAILABLE_LEVELS',
  levels,
  category,
})

const setSelectedLevel = ({
  level,
  category,
}): { type: string; level: string; category: ForecastType } => ({
  type: 'SET_SELECTED_LEVEL',
  level,
  category,
})

const initItemsForLevel = ({
  category,
  items,
  canLoadMore,
}): {
  type: string
  items: Forecast[] | Adjustment[]
  category: ForecastType
  canLoadMore?: boolean
} => ({
  type: 'INIT_ITEMS_FOR_LEVEL',
  items,
  category,
  canLoadMore,
})

const updateItemsForLevel = ({
  category,
  items,
  canLoadMore,
}): {
  type: string
  items: Forecast[] | Adjustment[]
  category: ForecastType
  canLoadMore?: boolean
} => ({
  type: 'UPDATE_ITEMS_FOR_LEVEL',
  items,
  category,
  canLoadMore,
})

type ReducerAction =
  | any
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setInitialized>
  | ReturnType<typeof setAvailableMarkets>
  | ReturnType<typeof setAvailableLevels>
  | ReturnType<typeof setSelectedMarkets>
  | ReturnType<typeof initItemsForLevel>
  | ReturnType<typeof updateItemsForLevel>
  | ReturnType<typeof setSelectedLevel>

const SummaryItemsReducer: Reducer<SummaryItemsStateType, ReducerAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.value,
      }
    case 'SET_INITIALIZED':
      return {
        ...state,
        isInitialized: action.value,
      }
    case 'SET_AVAILABLE_MARKETS':
      return {
        ...state,
        marketsAreInitialized: true,
        availableMarkets: {
          ...state.availableMarkets,
          [action.category]: Object.keys(action.marketsByLevel).reduce(
            (acc, level) => {
              acc[level] = action.marketsByLevel[level].markets.map(
                (market) => {
                  return {
                    id: market,
                    name: market,
                  } as Market
                },
              )
              return acc
            },
            {},
          ),
        },
        selectedMarkets: {
          ...state.selectedMarkets,
          [action.category]: Object.keys(action.marketsByLevel).reduce(
            (acc, level) => {
              acc[level] = action.marketsByLevel[level].markets
              return acc
            },
            {},
          ),
        },
      }
    case 'SET_SELECTED_MARKETS':
      // eslint-disable-next-line no-case-declarations
      const updatedSelectedMarketState = {
        ...state.selectedMarkets,
        [action.category]: {
          ...state.selectedMarkets[action.category],
          [state.selectedLevel[action.category]]:
            action.markets.length > 0
              ? action.markets
              : state.availableMarkets[action.category][
                  state.selectedLevel[action.category]
                ] &&
                state.availableMarkets[action.category][
                  state.selectedLevel[action.category]
                ].map((a) => a.id),
        },
      }

      localStorage.setItem(
        'selectedMarkets',
        JSON.stringify(updatedSelectedMarketState),
      )

      return {
        ...state,
        selectedMarkets: updatedSelectedMarketState,
        filteredItems: {
          ...state.filteredItems,
          [action.category]:
            action.markets && action.markets.length > 0
              ? state.items[action.category][
                  state.selectedLevel[action.category]
                ].filter((i) => action.markets.includes(i.market))
              : state.items[action.category][
                  state.selectedLevel[action.category]
                ],
        },
        filteredLoadMore: false,
      }
    case 'SET_AVAILABLE_LEVELS':
      return {
        ...state,
        availableLevels: {
          ...state.availableLevels,
          [action.category]: action.levels,
        },
        selectedLevel: {
          ...state.selectedLevel,
          [action.category]: action.levels[0]?.id,
        },
        canLoadMore: {
          ...state.canLoadMore,
          [action.category]: action.levels.reduce((acc, level) => {
            acc[level] = true
            return acc
          }, {}),
        },
      }
    case 'SET_SELECTED_LEVEL':
      // eslint-disable-next-line no-case-declarations
      const updatedSelectedLevelState = {
        ...state.selectedLevel,
        [action.category]: action.level,
      }
      localStorage.setItem(
        'selectedLevel',
        JSON.stringify(updatedSelectedLevelState),
      )
      return {
        ...state,
        selectedLevel: updatedSelectedLevelState,
        filteredItems: {
          ...state.filteredItems,
          [action.category]: state.items[action.category][action.level],
        },
      }
    case 'INIT_ITEMS_FOR_LEVEL':
      return {
        ...state,
        items: {
          ...state.items,
          [action.category]: {
            ...state.items[action.category],
            [state.selectedLevel[action.category]]: action.items,
          },
        },
        filteredItems: {
          ...state.filteredItems,
          [action.category]: action.items,
        },
        canLoadMore: {
          ...state.canLoadMore,
          [action.category]: {
            ...state.canLoadMore[action.category],
            [state.selectedLevel[action.category]]: action.canLoadMore,
          },
        },
        filteredLoadMore: false, // Best to reset this on level/tab change
      }
    case 'UPDATE_ITEMS_FOR_LEVEL':
      return {
        ...state,
        items: {
          ...state.items,
          [action.category]: {
            ...state.items[action.category],
            [state.selectedLevel[action.category]]: [
              ...state.items[action.category][
                state.selectedLevel[action.category]
              ],
              ...action.items,
            ],
          },
        },
        filteredItems: {
          ...state.filteredItems,
          [action.category]: state.selectedMarkets[action.category][
            state.selectedLevel[action.category]
          ]
            ? [
                ...state.filteredItems[action.category],
                ...action.items.filter((item) => {
                  return state.selectedMarkets[action.category][
                    state.selectedLevel[action.category]
                  ].includes(item.market)
                }),
              ]
            : action.items,
        },
        filteredLoadMore: state.selectedMarkets[action.category][
          state.selectedLevel[action.category]
        ]
          ? action.items.filter(
              (item) =>
                !state.selectedMarkets[action.category][
                  state.selectedLevel[action.category]
                ].includes(item.market),
            ).length > 0
          : false,
        canLoadMore: {
          ...state.canLoadMore,
          [action.category]: {
            ...state.canLoadMore[action.category],
            [state.selectedLevel[action.category]]: action.canLoadMore,
          },
        },
      }
    default:
      return state
  }
}

export default SummaryItemsReducer
