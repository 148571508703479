import React from 'react'
import { NestedDashboardConfig } from 'shared/types'

export interface DashboardsConfigsContextType {
  configs: Array<NestedDashboardConfig>
  isLoading: boolean
}

const DashboardsConfigsContext = React.createContext<DashboardsConfigsContextType>(
  {
    configs: [],
    isLoading: false,
  },
)

export default DashboardsConfigsContext
