import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as ProductManagementIcon } from 'icons/product-management-submodule.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'

const productManagementMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: (<div> Product Management Content In Development </div>) as any,
  },
]

export const ProductManagementMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Product Management"
      color="demand"
      basePath={url}
      icon={<ProductManagementIcon />}
      routes={productManagementMenuOptions}
    />
  )
}

const ProductManagement = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${productManagementMenuOptions[0].path}`} />
      </Route>
      {productManagementMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default ProductManagement
