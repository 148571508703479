import { useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import {
  fetchAdjusmentVersions,
  fetchStichedAdjustment,
  saveNewAdjustment,
  updateAdjustment,
  updateAdjustmentSummary,
} from 'modules/demand/common/apiClient'
import {
  AdjustmentsMeta,
  Adjustment,
  BackendAdjustment,
  ValidAdjustmentCalculations,
} from 'shared/types'
import AlertContext from 'shared/contexts/AlertsContext'

// TODO: add types as they become more permanent
const sortVersionFn = (a, b) => {
  if (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt)) {
    return -1
  }
  if (DateTime.fromISO(a.createdAt) < DateTime.fromISO(b.createdAt)) {
    return 1
  }
  return 0
}

const useAdjustmentVersionSelector = (adjustmentId: string) => {
  const publishNotification = useContext(AlertContext)
  const [adjustmentsMeta, setAdjustmentsMeta] = useState<AdjustmentsMeta>()
  const [stichedAdjustment, setStichedAdjustment] = useState([])
  const [selectedVersionId, setSelectedVersionId] = useState<
    string | undefined
  >(undefined)

  const initialize = () => {
    fetchAdjusmentVersions(adjustmentId).then((result) => {
      if (!result) {
        // TODO: handle bad id
        return
      }
      const resultWithSortedVersions = {
        ...result,
        versions: result.versions.sort(sortVersionFn),
      }

      setAdjustmentsMeta(resultWithSortedVersions)
      setSelectedVersionId(resultWithSortedVersions.versions[0]?.versionId)
    })
  }

  useEffect(() => {
    initialize()
    // on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedVersionId) {
      fetchStichedAdjustment(adjustmentId, selectedVersionId).then((result) => {
        setStichedAdjustment(result)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersionId])

  const saveAdjustments = (
    adjustments: Adjustment[],
    summary: AdjustmentsMeta['summary'],
  ) => {
    if (!adjustments.length) {
      // TODO: Handle this
      return
    }

    const cleanAdjustments: BackendAdjustment[] = adjustments.map(
      (adjustment) => ({
        id: adjustment.id,
        adjustmentCalculation:
          adjustment.adjustmentCalculation ||
          ValidAdjustmentCalculations.NoAction,
        adjustmentValue: adjustment.adjustmentValue,
      }),
    )
    const promises = [updateAdjustmentSummary(adjustmentId, summary)]
    if (selectedVersionId === adjustmentsMeta?.versions[0].versionId) {
      promises.push(saveNewAdjustment(adjustmentId, cleanAdjustments))
    } else if (selectedVersionId) {
      promises.push(
        updateAdjustment(adjustmentId, selectedVersionId, cleanAdjustments),
      )
    }

    Promise.all(promises)
      .then(() => {
        initialize()
      })
      .catch((e) => {
        if (e.response.status === 413) {
          publishNotification(
            'Payload too large... try reducing the amount saved by saving chunks (i.e. multiple times)',
            'error',
          )
        }
      })
  }

  return {
    adjustmentsMeta,
    stichedAdjustment,
    selectedVersionId,
    changeVersion: setSelectedVersionId,
    saveAdjustments,
  }
}

export default useAdjustmentVersionSelector
