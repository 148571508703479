import { intersect } from 'shared/utils/arrayOperations'
import { sortFilters } from 'shared/helpers'
import { Filter, Group, SelectableValue, SelectedFilter } from '../types'
import { Metadata } from '../components/CheckboxGroupWithUtilities'

export const generateModifiedFilters = (
  filters: Filter[],
  filterName: string,
  metadata: Metadata[],
) => {
  return filters.map<Filter>((filter) => {
    if (filter.name !== filterName) return filter
    const selected = new Set(
      filter.items.filter((x) => x.isChecked).map((x) => x.id),
    )
    metadata.forEach((elem) => {
      if (elem.isChecked) {
        selected.add(elem.id)
      } else {
        selected.delete(elem.id)
      }
    })
    return {
      ...filter,
      items: filter.items.map((item) => ({
        ...item,
        isChecked: selected.has(item.id) && !item.isDisabled,
      })),
    }
  })
}

export const generateNewFilters = (
  filters: Filter[],
  selectedFilters: SelectedFilter[],
): Filter[] => {
  return filters.map((filter) => {
    const parentFilter =
      selectedFilters?.find((selFilter) => selFilter.name === filter.name) ||
      false

    return {
      ...filter,
      items: filter.items
        .map((item) => ({
          ...item,
          isChecked: parentFilter && parentFilter.values.includes(item.label),
          isDisabled: false,
        }))
        .sort(sortFilters),
    }
  }) as Filter[]
}

/**
 * This function returns the result of mergin two different
 * selected filters arrays.
 * It will perform a union of the different elements of both
 * arrays, but it will only return the intersected values of
 * those which "name" is equal, even if that means an empty array:
 *
 * e.g:
 *
 * ```
 * input:
 * selectedFiltersA =
 *   [
 *     {name: "a", values: [1,2]},
 *     {name: "b", values: [1,2,3]},
 *     {name: "c", values: [1,2]}
 *   ]
 * selectedFiltersB =
 *   [
 *     {name: "b", values: [2,3,4]},
 *     {name: "c", values: [4,5]},
 *     {name: "d", values: [200, 300]}
 *   ]
 *
 * output =
 *   [
 *     {name: "a", values: [1,2,]},  // values from "selectedFiltersA"
 *     {name: "b", values: [2,3]},   // intersection between both
 *     {name: "c", values: []},      // intersection between both
 *     {name: "d", values: [200, 300]}  // values from "selectedFiltersB"
 *   ]
 * ```
 *
 * @param selectedFiltersA
 * @param selectedFiltersB
 */
export function mergeSelectedFilters(
  selectedFiltersA: SelectedFilter[],
  selectedFiltersB: SelectedFilter[],
) {
  if (!selectedFiltersA.length || !selectedFiltersB.length)
    return selectedFiltersA.length ? selectedFiltersA : selectedFiltersB

  const intersectedNames = intersect(
    selectedFiltersA.map((fil: SelectedFilter) => fil.name),
    selectedFiltersB.map((fil: SelectedFilter) => fil.name),
  )

  const newSelectedFilters: SelectedFilter[] = []

  intersectedNames.forEach((names) => {
    const array1Values = selectedFiltersA.find((el) => el.name === names)
      ?.values as Array<SelectableValue>
    const array2Values = selectedFiltersB.find((el) => el.name === names)
      ?.values as Array<SelectableValue>

    const interSectArray = intersect(array1Values, array2Values)

    newSelectedFilters.push({
      name: names,
      values: interSectArray,
    })
  })

  const uniqueNamesFromSelectedFilters = new Set<string>(intersectedNames)

  selectedFiltersA.forEach((filter) => {
    if (!uniqueNamesFromSelectedFilters.has(filter.name)) {
      newSelectedFilters.push(filter)
    }
  })

  selectedFiltersB.forEach((filter) => {
    if (!uniqueNamesFromSelectedFilters.has(filter.name)) {
      newSelectedFilters.push(filter)
    }
  })

  return newSelectedFilters
}

/**
 * TODO: Test this function
 * This function is intended to do a final pass to clean filters BEFORE they are used in a network request.
 * For example, the way truly filter by day type (weekend/weekday) is to if a user selects filter by weekend
 * then we send in a request for day-week: [5, 6], thus, this function serves as a final pass to build call kinds of final filter clean up
 */
export function cleanFiltersForValidNetworkRequest(
  selectedFilters: SelectedFilter[],
) {
  if (!selectedFilters) {
    return selectedFilters
  }
  const dayType = selectedFilters.find((val) => val.name === Group.DayType)
  const dayOfWeek = selectedFilters.find((val) => val.name === Group.DayOfWeek)
  const convertDayTypeValuesToDayOfWeekValues = (dayTypeValues) => {
    const weekday = [0, 1, 2, 3, 4]
    const weekend = [5, 6]
    return dayTypeValues.reduce(
      (acc: any, val) =>
        val === 0 ? [...acc, ...weekday] : [...acc, ...weekend],
      [],
    )
  }
  if (dayType && dayOfWeek) {
    const dayTypeValues = convertDayTypeValuesToDayOfWeekValues(dayType.values)
    const mergedDayTypeAndDayOfWeekValues = dayTypeValues.filter((value) =>
      dayOfWeek.values.includes(value),
    )
    return [
      ...selectedFilters.filter(
        (val) => !['day-type', 'day-week'].includes(val.name),
      ),
      { name: 'day-week', values: mergedDayTypeAndDayOfWeekValues },
    ]
  }
  if (dayType) {
    const dayTypeValues = convertDayTypeValuesToDayOfWeekValues(dayType.values)
    return [
      ...selectedFilters.filter((val) => !['day-type'].includes(val.name)),
      { name: 'day-week', values: dayTypeValues },
    ]
  }
  return selectedFilters
}

export function buildSelectedFilters(
  filters: Array<Filter>,
): Array<SelectedFilter> {
  return filters.reduce<SelectedFilter[]>((acc, filter) => {
    const selectedValues = filter.items
      .filter((x) => x.isChecked)
      .map((x) => x.id)
      .sort()
    if (selectedValues.length) {
      acc.push({ name: filter.name, values: selectedValues })
    }
    return acc
  }, [] as SelectedFilter[])
}

export function generateModifiedSelectedFilters(
  selectedFilters: Array<SelectedFilter>,
  filterName: string,
  metadata: Metadata[],
): Array<SelectedFilter> {
  const selectedFilterToUpdate = selectedFilters.find(
    (x) => x.name === filterName,
  )
  if (selectedFilterToUpdate) {
    const selectedValues = new Set(selectedFilterToUpdate.values)
    metadata.forEach(({ id, isChecked }) => {
      if (isChecked) {
        selectedValues.add(id)
      } else {
        selectedValues.delete(id)
      }
    })
    return selectedFilters
      .map((x) => {
        return x !== selectedFilterToUpdate
          ? x
          : {
              ...selectedFilterToUpdate,
              values: Array.from(selectedValues).sort(),
            }
      })
      .filter((x) => x.values.length > 0)
  }
  const newSelectedFilter: SelectedFilter = { name: filterName, values: [] }
  metadata.forEach(({ id, isChecked }) => {
    if (isChecked) {
      newSelectedFilter.values.push(id)
    }
  })
  if (newSelectedFilter.values.length) {
    return [...selectedFilters, newSelectedFilter]
  }
  return selectedFilters
}
