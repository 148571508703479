import { useState, useEffect } from 'react'
import { getForecastDataSeriesInfoFromDruid } from 'modules/demand/common/apiClient'
import { DateTime } from 'luxon'

/**
 * Simple hook to manage the state of the forecasts time series, handling the fetching
 * whenever the date range changes and providing the resulting data obtained
 * and its current fetching status (if its loading or not)
 * @param datasource Druid datasource to obtain the dataseries from
 * @param limit Maximum amount of dataseries to receive, always in descending order from the latest one
 * @param dateRange Date range to requests forecasts for. It's an object with the shape:
 * <pre>
 *  {
 *    from: DateTime,
 *    to: DateTime
 *  }
 * </pre>.
 * If it has a null value, no changes nor requests will
 * be done
 */
const useForecastsTimeSeries = (
  datasource: string,
  limit: number,
  dateRange: null | {
    from: DateTime
    to: DateTime
  },
) => {
  const [timeSeries, setDataSeries] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    /* Whenever the time context changes, the data series and the filter values
    need to be retrieved */
    if (dateRange) {
      setIsLoading(true)
      getForecastDataSeriesInfoFromDruid(
        datasource,
        'process_time',
        {
          dateRange: {
            from: dateRange.from.toISODate(),
            to: dateRange.to.toISODate(),
          },
        },
        limit,
        'descending',
      ).then((retrievedDataSeries) => {
        setDataSeries(retrievedDataSeries)
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  return { forecasts: timeSeries, areForecastsLoading: isLoading }
}

export default useForecastsTimeSeries
