/**
 *
 * @param arr1 is array
 * @param arr2 is array
 *
 * isEqual this function checks if two arrays are equal
 *
 */
export const isEqual = (arr1: Array<any>, arr2: Array<any>): boolean => {
  if (arr1.length !== arr2.length) return false

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}

/**
 *
 * @param arr1 is an array
 * @param arr2 is an array
 *
 * xor find difference in two arrays
 * example : [1,2,3] [1,2,5]
 * this is give [3,5]
 */
export const xor = (arr1: Array<any>, arr2: Array<any>): Array<any> => {
  const arrayDifference = arr1.filter((x) => !arr2.includes(x))

  return arrayDifference
}

interface SortRemoveUniqueOptions {
  limit?: number
  direction?: 1 | -1
}

export const sortRemoveUniqueNumbers = (
  arr: Array<number>,
  options?: SortRemoveUniqueOptions,
) => {
  const direction = options?.direction || -1
  const data = Array.from(new Set(arr)).sort((a, b) => direction * (a - b))
  if (options?.limit) {
    return data.slice(0, options.limit)
  }
  return data
}

export function intersect<T>(array1: Array<T>, array2: Array<T>): Array<T> {
  const mainArrayForNameSet = new Set<T>(array1)
  const intersectedArray = array2.filter((name) =>
    mainArrayForNameSet.has(name),
  )
  return intersectedArray
}
