import { useState, useEffect } from 'react'
import useSimpleFilters from 'shared/hooks/useSimpleFilters'
import { isTimeGroup } from 'shared/common/dashboardOptions'
import useTimeFilters from 'shared/hooks/useTimeFilters'
import useRangeFilters, {
  SavedRangeFilters,
} from 'shared/hooks/useRangeFilters'
import { DashboardConfigItem, SavedFilter } from 'shared/types'

/**
 * Given an asynchronous fetchDimensions function that returns an array of distinct filter combinations and a time interval,
 * this hook is a simple wrapper around the useSimpleFilters and useTimeFilters hook. It uses a simple getSimpleTitle function to calculate filter titles.
 * It returns dimension and time filters, alongside an isInitializing boolean, and a handleChange function that can be used to handle both time and dimension filters.
 * A user of this filter can also use the individual onChange functions returned by the dimension and time filters.
 */
const useTimeAndDimensionFilters = (
  savedFilters: SavedFilter[],
  timeInterval,
  fetchDimensions: () => Promise<{ [key: string]: string }[]>,
  filterDefinitions: DashboardConfigItem['filters'] = [],
  rangeFiltersDefinitions: DashboardConfigItem['rangeFilters'] = [],
  fetchRangeFilter?: () => Promise<
    Array<{ minValue: number; maxValue: number; name: string }>
  >,
  savedRangeFilters?: SavedRangeFilters[],
  savedTimeFilters?: SavedFilter[],
) => {
  const [state, setState] = useState<{ [key: string]: string }[]>([])
  const [isInitializing, setIsInitializing] = useState(true)
  const [rangeFilters, setRangeFilters] = useState<
    Array<{ minValue: number; maxValue: number; name: string }>
  >([])
  const [isRangeFilterInitializing, setRangeFilterIsInitializing] = useState(
    false,
  )

  useEffect(() => {
    fetchDimensions().then((dimensions) => {
      setState(dimensions)
      setIsInitializing(false)
    })

    if (rangeFiltersDefinitions.length > 0 && fetchRangeFilter) {
      setRangeFilterIsInitializing(true)
      fetchRangeFilter().then((dimensions) => {
        setRangeFilters(dimensions)
        setRangeFilterIsInitializing(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getSimpleTitle(name: string) {
    const definition = filterDefinitions?.find((d) => d.name === name)
    return definition?.title || name
  }

  function getRangeFilterTitle(name: string) {
    const definition = rangeFiltersDefinitions?.find((d) => d.name === name)
    return definition?.title || name
  }

  const simpleFilterState = useSimpleFilters({
    filterOptions: state,
    getTitle: getSimpleTitle,
    savedFilters,
  })

  const timeFilters = useTimeFilters(timeInterval, savedTimeFilters)

  const rangeTypeFilters = useRangeFilters(
    rangeFilters,
    getRangeFilterTitle,
    savedRangeFilters,
  )

  function handleChange(groupName, changes) {
    if (isTimeGroup(groupName)) {
      timeFilters.onChange(groupName, changes)
    } else {
      simpleFilterState.onChange(groupName, changes)
    }
  }

  return {
    dimensionFilters: simpleFilterState,
    rangeFilters: rangeTypeFilters,
    timeFilters,
    onChange: handleChange,
    isInitializing: isInitializing && isRangeFilterInitializing,
  }
}

export default useTimeAndDimensionFilters
