import React, { useState, useContext, useMemo } from 'react'
import { styled } from '@material-ui/core/styles'
import { ClickAwayListener } from '@material-ui/core'
import { Switch, Route } from 'react-router'
import NotificationsPanel from 'base/components/NotificationsPanel'
import ProductBar from 'base/components/ProductBar'
import { NotificationType } from 'shared/types'
import ProductSpace from 'base/pages/ProductSpace'
import { MenuNav } from 'base/pages/Menu'
import UserContext from 'shared/contexts/UserContext'
import getProductModulesInfoForUser from '../getProductModulesInfoForUser'

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
})

const StyledContent = styled('div')(() => ({
  position: 'relative',
  flex: '1 0 auto',
  backgroundColor: '#F5F6FA',
}))

// TODO: Add responsiveness to the full width
const StyledNotificationsPanel = styled(NotificationsPanel)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  width: '400px',
  overflow: 'auto',
})

export interface Props {
  notifications: NotificationType[]
  onNotificationsClear: () => void
  onLogout: () => void
}

const BaseLayout = ({
  notifications,
  onNotificationsClear,
  onLogout,
}: Props) => {
  const [areNotificationsVisible, setAreNotificationsVisible] = useState(false)

  const toggleNotificationsVisibility = () => {
    setAreNotificationsVisible((prevState) => !prevState)
  }

  const user = useContext(UserContext)

  const productModuleRoutes = useMemo(
    () => getProductModulesInfoForUser(user).content,
    [user],
  )

  return (
    <StyledContainer>
      <ProductBar
        onLogout={onLogout}
        onNotificationsButtonClick={toggleNotificationsVisibility}
        notifications={notifications.length}
      >
        <Switch>
          <Route path="/" exact component={() => <MenuNav />} />
          <Route path="/overview" exact component={() => <MenuNav />} />
          {productModuleRoutes.map(({ mod, products }) =>
            products.map(({ path, config }) => {
              return (
                <Route key={mod} path={path} component={config.HeaderContent} />
              )
            }),
          )}
        </Switch>
      </ProductBar>
      <StyledContent>
        <ProductSpace />
        {areNotificationsVisible && (
          <ClickAwayListener
            onClickAway={() => setAreNotificationsVisible(false)}
          >
            <StyledNotificationsPanel
              notifications={notifications}
              onClearAllClick={() => {
                onNotificationsClear()
                setAreNotificationsVisible(false)
              }}
            />
          </ClickAwayListener>
        )}
      </StyledContent>
    </StyledContainer>
  )
}

export default BaseLayout
