import axios from 'axios'
import axiosApiClient from 'shared/apiClient'
import { httpResponseErrors } from 'shared/common/errorMessages'
import { JobDescriptionModel, Input } from 'shared/types'
import Papa from 'papaparse'
import { DateTime } from 'luxon'
import { filterToTableMapping } from './mappers'

export interface PricingJobRequestResponse {
  success: boolean
  status: number
  data: { [key: string]: any }
}

export interface PricingCalculationParametersValues {
  market: Array<string>
  iso: Array<string>
  edc: Array<string>
  product: Array<string>
  profile: Array<string>
}

export enum PricingCalculationParameters {
  MARKET = 'market',
  ISO = 'iso',
  EDC = 'edc',
  PRODUCT = 'product',
  PROFILE = 'profile',
}

export function calculateJobStatus(job) {
  switch (job.state) {
    case 'watiting':
      return undefined
    case 'active':
      return job.progress
    default:
      return job.status
  }
}

export async function getCalculationParametersValues(
  calculationParameters: Array<string>,
): Promise<PricingCalculationParametersValues> {
  if (!calculationParameters.length)
    return Promise.reject(
      new Error(`"calculationsParameters" argument cannot be an ampty array`),
    )
  return axiosApiClient
    .get('/pricing/v1/calculation-parameters/distinct', {
      params: { fields: calculationParameters },
    })
    .then((r) => r.data)
}

export function getPricingJobs(): Promise<any[]> {
  return axiosApiClient('/pricing/v1/jobs').then((d) => {
    return d.data.map((job) => {
      let status
      switch (job.state) {
        case 'watiting':
          status = undefined
          break
        case 'active':
          status = job.progress
          break
        default:
          status = job.status
          break
      }
      return {
        uid: job.jobId,
        jobType:
          job.type === 'retail_cost_calculation' ? 'calculation' : job.type,
        name: job.name,
        initDatetime: new Date(job.addedOn),
        contractCount:
          job.contractCount == null || job.contractCount === undefined
            ? undefined
            : job.contractCount,
        passedCount:
          job.passedCount == null || job.passedCount === undefined
            ? undefined
            : job.passedCount,
        failedCount:
          job.failedCount == null || job.failedCount === undefined
            ? undefined
            : job.failedCount,
        progress: {
          endDatetime: job.finishedOn ? new Date(job.finishedOn) : undefined,
          status,
          message: status,
        },
      }
    })
  })
}

export async function createPricingJobs(
  jobDescription: JobDescriptionModel,
  inputs: Input[],
): Promise<PricingJobRequestResponse> {
  try {
    let engineJobType: string | null = null
    if (jobDescription.type === 'calculation') {
      engineJobType = 'retail_cost_calculation'
    } else if (jobDescription.type === 'check_status') {
      engineJobType = 'check_status'
    }
    const initialResponse = await axiosApiClient.post('/pricing/v1/jobs', {
      ...jobDescription,
      type: engineJobType,
    })
    const { url, id } = initialResponse.data
    const csv = Papa.unparse(
      inputs.map((input) => ({
        ...input,
        startDate: DateTime.fromJSDate(input.startDate).toISODate(),
        endDate: DateTime.fromJSDate(input.endDate).toISODate(),
        asOfDate: DateTime.fromJSDate(input.asOfDate).toISODate(),
      })),
    )
    await axios.put(url, csv, { headers: { 'Content-Type': 'text/csv' } })
    const response = await axiosApiClient.post(`/pricing/v1/jobs/${id}`)
    return {
      success: true,
      status: response.status,
      data: {
        ...response.data,
        name: jobDescription.name,
      },
    }
  } catch (error) {
    let message = ''
    if (error.response?.status === 409) {
      message = 'Jobs in queue rate has been reached'
    } else {
      message =
        httpResponseErrors[error.response?.status] || 'Something went wrong'
    }
    return {
      success: false,
      status:
        error.response?.status ||
        500 /* TODO: check if it is really necessary to pass a status value */,
      data: {
        message,
      },
    }
  }
}

export async function downloadJob(
  jobId: string,
  resultType: 'monthly_price' | 'summary_price',
) {
  const link = await axiosApiClient.get(
    `/pricing/v1/jobs/${jobId}/results/${resultType}`,
  )
  if (!link.data) {
    return
  }
  window.open(link.data)
}

export async function getRerunJobInputData(jobId: string) {
  const url = await axiosApiClient.get(`/pricing/v1/jobs/${jobId}/input/`)
  if (!url.data) {
    return null
  }
  const response = await axios.get(url.data, {
    headers: { 'Content-Type': 'text/csv' },
  })
  return response.data
}

export async function getPricingConfigurationData(
  tableName: string,
  page: number,
  pageSize: number,
  selectedFilters: { [key: string]: string[] },
  sort?: { sortBy: string; sortOrder: string },
) {
  const params = {
    limit: pageSize.toString(),
    skip: (page * pageSize).toString(),
  }
  let queryString = new URLSearchParams(params)
  if (sort) {
    queryString = new URLSearchParams({ ...params, ...sort })
  }
  const filtersQuery = Object.entries(selectedFilters).reduce(
    (acc, [key, arr]) => {
      if (!arr.length) {
        return acc
      }
      const newArr = arr.map(encodeURIComponent)
      return `${acc}&${key}[]=${newArr.join(`&${key}[]=`)}`
    },
    '',
  )

  const response = await axiosApiClient.get(
    `/pricing/v1/config/${tableName}?${queryString.toString()}${filtersQuery}`,
  )
  if (!response.data) {
    return null
  }
  return response.data
}

export async function getPricingConfigurationTableCount(
  tableName: string,
  selectedFilters: { [key: string]: string[] },
) {
  const filtersQuery = Object.entries(selectedFilters).reduce(
    (acc, [key, arr]) => {
      if (!arr.length) {
        return acc
      }
      const newArr = arr.map(encodeURIComponent)
      return `${acc}&${key}[]=${newArr.join(`&${key}[]=`)}`
    },
    '',
  )
  const response = await axiosApiClient.get(
    `/pricing/v1/config/${tableName}/count?${filtersQuery}`,
  )
  if (!response.data) {
    return null
  }
  return response.data?.count
}

export async function getPricingConfigurationFilters() {
  const promises = filterToTableMapping.map(
    ({ tableToFetchFrom, fieldNameInTable }) =>
      axiosApiClient.get(
        `/pricing/v1/config/${tableToFetchFrom}/distinct/?distinct[]=${fieldNameInTable}`,
      ),
  )
  return await Promise.all(promises).then((value) => {
    return value.reduce((acc: any, val, index) => {
      acc.push({
        name: filterToTableMapping[index].fieldNameInFilter,
        title: filterToTableMapping[index].filterDisplay,
        items: val.data
          ? val.data.map((item) => ({
              id: item,
              label: item,
              isChecked: false,
              isDisabled: false,
            }))
          : [],
      })
      return acc
    }, [])
  })
}
