import React, { useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

const CenterAllWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
})

export const LoadingContent = () => (
  <CenterAllWrapper>
    <CircularProgress />
  </CenterAllWrapper>
)

interface Props {
  onLogin: () => void
}
const Login = ({ onLogin }: Props) => {
  useEffect(() => {
    onLogin()
  })

  return <LoadingContent />
}

export default Login
