import {
  Button,
  makeStyles,
  styled,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useContext, useState, useRef } from 'react'
import AlertContext from 'shared/contexts/AlertsContext'
import { createNewAdjustment } from 'modules/demand/common/apiClient'
import { DateTime } from 'luxon'
import { Body } from 'shared/components/DashboardLayout'
import HourBeginningOrEndingSelector from 'shared/components/HourBeginningOrEndingSelector'
import { Header, Options, PageTitle } from 'shared/components/PageLayout'
import TimezoneSelector from 'shared/components/TimezoneSelector'
import { useHistory, useParams } from 'react-router'
import ForecastsList from './ForecastsList'
import { ForecastType, ForecastWithLevel } from './LoadForecastTypes'
import ForecastCard from './ForecastCard'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputWrappers: {
    display: 'flex',
    marginTop: theme.spacing(1),
    gap: theme.spacing(2),
  },
  input: {
    width: '100%',
  },
  floatingBelowSection: {
    position: 'fixed',
    bottom: '0px',
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    padding: theme.spacing(2),
    // to keep the floating bottom bar above page elements this needed to be done
    zIndex: 999999,
  },
  cancel: {
    marginRight: theme.spacing(2),
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.common.white,
  },
  accept: {
    color: theme.palette.primary.main,
    background: 'white',
  },
}))

const NameInput = withStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '4px',
  },
}))(TextField) as typeof TextField

const PageLayout = styled('div')(({ theme }) => ({
  minHeight: '90vh',
  display: 'grid',
  gridTemplateRows: 'auto 150px 1fr',
  gridTemplateColumns: '15% auto 15%',
  gridTemplateAreas: `
    "left-menu header right-menu"
    "left-menu options right-menu"
    "left-menu body right-menu"`,
  gap: theme.spacing(3, 3),
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}))

interface ParamTypes {
  forecast: string
}

const CreateNewAdjustment = () => {
  const history = useHistory()
  const classes = useStyles()
  const publishNotification = useContext(AlertContext)

  const { forecast: preSelectedForecast } = useParams<ParamTypes>()
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    DateTime.local().zoneName,
  )
  const [inputNameHasError, setInputNameHasError] = useState(false)
  const nameInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedIsHourEnding, setSelectedIsHourEnding] = useState<Boolean>(
    true,
  )
  const [selectedForecast, setSelectedForecast] = useState<
    ForecastWithLevel | undefined
  >(() => {
    if (preSelectedForecast) {
      const forecastFromParams = JSON.parse(preSelectedForecast)
      return {
        ...forecastFromParams,
        processTime: forecastFromParams.processTime,
        from: DateTime.fromISO(forecastFromParams.from),
        to: DateTime.fromISO(forecastFromParams.to),
      }
    }
    return undefined
  })
  const [isListMode, setIsListMode] = useState<boolean>(
    Boolean(preSelectedForecast),
  )

  const handleCreate = () => {
    const hasName = nameInputRef.current?.value.trim() !== ''
    if (
      selectedForecast &&
      hasName &&
      selectedTimezone &&
      selectedIsHourEnding
    ) {
      setInputNameHasError(false)
      createNewAdjustment({
        name: nameInputRef?.current?.value || '',
        configId: selectedForecast.level.configId,
        market: selectedForecast.market,
        processTime: selectedForecast.processTime,
        dateRange: {
          from: selectedForecast.from.toISODate(),
          to: selectedForecast.to.toISODate(),
        },
        summary: selectedForecast.forecast.map((forecst) => {
          return {
            timestamp: forecst.timestamp,
            forecast: forecst.forecast,
            adjustedForecast: 0,
          }
        }),
        timezone: selectedTimezone,
        isHourEnding: selectedIsHourEnding,
      })
        .then(({ adjustmentId }) => {
          history.push(`/demand/load-forecast/adjustments/${adjustmentId}`)
          publishNotification('Adjustment Created', 'success')
        })
        .catch(() => {
          publishNotification('Error creating adjustment', 'error')
        })
    } else {
      // TODO: Cannot get a notification to publish here....
      setInputNameHasError(true)
    }
  }

  const handleResetForecast = () => {
    setIsListMode(false)
    setSelectedForecast(undefined)
  }

  const handleCancel = () => {
    history.push('/demand/load-forecast/adjustments')
  }

  return (
    <div>
      <PageLayout>
        <Header>
          <PageTitle>
            <Typography variant="h4">
              Create a Short Term Forecast Adjustment
            </Typography>
          </PageTitle>
        </Header>

        <Options>
          <div>
            <Typography variant="caption" component="p">
              ENTER A NAME FOR THE ADJUSTMENT (required)
            </Typography>
            <NameInput
              error={inputNameHasError}
              inputRef={nameInputRef}
              aria-label="adjustment-name"
              data-testid="adjustment-name"
              variant="outlined"
              size="small"
              placeholder="Adjustment Name"
            />
          </div>
          <div className={classes.inputWrappers}>
            <div className={classes.input}>
              <TimezoneSelector
                selectedTimezone={selectedTimezone}
                onChange={(e, t) => setSelectedTimezone(t)}
              />
            </div>
            <div className={classes.input}>
              <HourBeginningOrEndingSelector
                isHourEndingSelected={selectedIsHourEnding}
                onChange={(e, newIsHourEndingSelected) =>
                  setSelectedIsHourEnding(newIsHourEndingSelected)
                }
              />
            </div>
          </div>
        </Options>
        <Body>
          {preSelectedForecast && selectedForecast && isListMode ? (
            <ForecastCard
              actions={[]}
              selected
              forecast={selectedForecast}
              data-testid="forecast-card"
            />
          ) : (
            <ForecastsList
              title="Select A Forecast To Adjust"
              errorMessage={
                inputNameHasError && !selectedForecast
                  ? 'A forecast must be selected'
                  : undefined
              }
              tabCategories={[
                {
                  title: 'Short Term',
                  category: ForecastType.ShortTermForecast,
                },
              ]}
              onSelect={(object) => setSelectedForecast(object)}
            />
          )}
        </Body>
      </PageLayout>
      <div className={classes.floatingBelowSection}>
        {preSelectedForecast && (
          <Button
            disabled={!isListMode}
            variant="outlined"
            className={classes.cancel}
            onClick={handleResetForecast}
          >
            Reset
          </Button>
        )}

        <Button
          variant="outlined"
          className={classes.cancel}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.accept}
          onClick={() => handleCreate()}
        >
          Accept
        </Button>
      </div>
    </div>
  )
}

export default CreateNewAdjustment
