import React, { DragEvent } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import DraggableMenu from 'shared/components/DraggableMenu'
import SideBarMenu from 'shared/components/SideBarMenu'
import { WeatherTimeSeries, WeatherStation } from 'shared/types'

export interface WeatherItem {
  id: string
  series: WeatherTimeSeries
  station: WeatherStation
}

interface Props {
  stations: Array<WeatherStation>
  selectedStation: WeatherStation
  onStationChange: (event: any, station: WeatherStation) => void
  onDrag: (event: DragEvent, weatherItem: WeatherItem) => void
  showActual?: boolean
  showForecast?: boolean
  showNormal?: boolean
}

/**
 * Specific draggable menu for weather information
 */
const WeatherMenu = ({
  stations = [],
  selectedStation,
  onDrag,
  onStationChange,
  showActual,
  showForecast,
  showNormal,
}: Props) => {
  const shownMenus = [
    ...(showActual ? [['Actual', WeatherTimeSeries.Actual]] : []),
    ...(showForecast ? [['Forecast', WeatherTimeSeries.Forecast]] : []),
    ...(showNormal ? [['Normal', WeatherTimeSeries.Normals]] : []),
  ] as Array<[string, WeatherTimeSeries]>

  return (
    <SideBarMenu title="Weather">
      <Autocomplete
        data-testid="weather-stations-selector"
        value={selectedStation}
        onChange={(event, newStation) => {
          onStationChange(event, newStation)
        }}
        options={stations}
        getOptionLabel={(option) => option.label}
        fullWidth
        disableClearable
        noOptionsText="No stations"
        aria-label="weather stations"
        renderInput={(params) => {
          return (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder="Station"
              margin="dense"
              label="Stations"
            />
          )
        }}
      />
      {shownMenus.map(([seriesLabel, series]) => (
        <DraggableMenu
          key={series}
          onDrag={(e, { id }) =>
            onDrag(e, {
              id,
              series,
              station: selectedStation,
            })
          }
          title={seriesLabel}
          defaultMenuIsOpen
          innerMenus={[
            {
              id: `${series}-${selectedStation.id}-temp-menu`,
              isCollapsible: false,
              options: [
                {
                  id: `${series}-${selectedStation.id}-temp`,
                  title: 'Temp',
                },
              ],
            },
          ]}
        />
      ))}
    </SideBarMenu>
  )
}

export default WeatherMenu
