import React, { ReactEventHandler, forwardRef } from 'react'
import { styled, makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core'
import {
  CheckCircleOutline,
  RadioButtonUnchecked,
  HelpOutline,
  ErrorOutline,
} from '@material-ui/icons'
import { NotificationType, NotificationKindType } from 'shared/types'

export interface Props {
  className?: string
  notifications: NotificationType[]
  onClearAllClick: ReactEventHandler
}

const StyledContainer = styled(Paper)({ padding: '24px' })

const StyledHeader = styled('header')({
  display: 'flex',
  alignItems: 'center',
  '& > :first-child': {
    marginRight: 'auto',
  },
})

const useAvatarStyles = makeStyles((theme) => ({
  colorDefault: ({ kind }: { kind: NotificationKindType }) => {
    if (kind !== 'neutral') {
      let color
      if (kind === 'positive') {
        color = theme.palette.success.main
      } else if (kind === 'warning') {
        color = theme.palette.warning.main
      } else {
        color = theme.palette.error.main
      }
      return { backgroundColor: color }
    }
    return {}
  },
}))

const NotificationIcon = ({ kind }: { kind: NotificationKindType }) => {
  const classes = useAvatarStyles({ kind })
  let iconElement
  if (kind === 'positive') {
    iconElement = <CheckCircleOutline />
  } else if (kind === 'neutral') {
    iconElement = <RadioButtonUnchecked />
  } else if (kind === 'warning') {
    iconElement = <HelpOutline />
  } else {
    iconElement = <ErrorOutline />
  }
  return <Avatar classes={classes}>{iconElement}</Avatar>
}

const NotificationsPanel = forwardRef(
  ({ className, notifications, onClearAllClick }: Props, ref) => (
    <StyledContainer
      className={className}
      elevation={3}
      ref={ref}
      data-testid="notifications-panel"
    >
      <StyledHeader>
        <Typography variant="overline">Latest</Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          size="small"
          onClick={onClearAllClick}
        >
          Clear All
        </Button>
      </StyledHeader>
      <List>
        {notifications.map(
          ({ kind, title, description, extraContent, date }, i) => (
            // FIXME: Change for real notifications with identifiers
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={`notification_${i}`} alignItems="flex-start">
              <ListItemAvatar>
                <NotificationIcon kind={kind} />
              </ListItemAvatar>
              <ListItemText
                primary={title}
                secondary={
                  <>
                    <div>{description}</div>
                    {extraContent}
                    <Typography variant="caption" component="div">
                      {new Date(date).toDateString()}
                    </Typography>
                  </>
                }
                secondaryTypographyProps={{
                  variantMapping: { body2: 'div', caption: 'div' },
                }}
              />
            </ListItem>
          ),
        )}
      </List>
    </StyledContainer>
  ),
)

export default NotificationsPanel
