import React, { ReactEventHandler, useState, ChangeEvent, useMemo } from 'react'
import {
  makeStyles,
  Theme,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { DashboardConfigItem } from 'shared/types'
import { X as Clear } from 'react-feather'
import {
  DASHBOARD_NAME_MAX_LENGTH,
  DASHBOARD_DESCRIPTION_LENGTH,
} from 'shared/constants'

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: 570 /* Arbitrarily based on the design provided on https://projects.invisionapp.com/d/main#/console/20425095/428743172/preview  */,
  },
  header: {
    display: 'flex',
    padding: theme.spacing(3, 4),
    '& > :first-child': {
      marginRight: 'auto',
    },
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(
      2.5,
      4,
    ) /* Weird spacing value (2.5) based on the design prvided on 
    https://projects.invisionapp.com/d/main#/console/20425095/428743172/preview 
    (vertical padding on the footer breaks the usual spacing consistency ) */,
    '& > :first-child': {
      marginRight: 'auto',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
    "name name" 
    "description description"
    "commodity category"
    "level ."
    `,
    gridGap: theme.spacing(2),
    padding: theme.spacing(3, 4),
  },
  name: {
    gridArea: 'name',
  },
  description: {
    gridArea: 'description',
  },
  category: {
    gridArea: 'category',
  },
  level: {
    gridArea: 'level',
  },
  commodity: {
    gridArea: 'commodity',
  },
}))

export interface NewDashboardModel {
  name: string
  description: string
  configId: string
}

export interface CopyMetaInterface {
  name: string | undefined
  description: string | undefined
  commodity: string | undefined
  category: string | undefined
  level: string | undefined
}

export interface Props {
  /**
   * Callback that will be invoked when a new dashboard has to be added
   * to its appropriate repository. It is ensured that all the required
   * values are given on the provided argument.
   */
  onAdd: (newDashboard: NewDashboardModel) => void
  /**
   * Callback that will be invoked when the user desires to dismiss the
   * dialog, via the cancel or close button or by clicking
   * outside the dialog.
   */
  onClose: ReactEventHandler
  /**
   * Array of categories with its appropriate levels for the user to selecto from.
   *
   * If the array is empty, the user won't be able to ever "Add" a new dashboard
   * as no category/level can be selected and the respective selector will be empty.
   */

  dashboardConfigItems: Array<DashboardConfigItem>
  /**
   * Optional value to indicate that the dialog is awaiting for some confirmation, so
   * no value should change on the form and it shouldn't be closed either. It will
   * also display a "loading" spinner on the "Create" button to indicate that some
   * process is ongoing.
   *
   * Its intent is to be set to "true" by its parent after the "onAdd" callback is invoked.
   */
  isAwaitingConfirmation?: boolean
  /**
   * Optional value to indicate that the dialog is on copy mode so
   * this means only the name and description can be edited
   * Its intent is to be set to "true" by its parent when user is trying to copy a dashboard
   */
  isOnCopyMode?: boolean
  /**
   optional value to prepopulate name field, level, commodity and category
   this is used when using the dialog to make a copy`
   */
  copyMeta?: CopyMetaInterface | undefined
  /**
   * Optional value to indicate if a dashboard is unsaved or not
   * Its intent is to be set to "true" by its parent
   * if the 'dashboardId' of the parent is "unsaved"
   */
  isUnsavedDashboard?: boolean
}

/**
 * Obtains the appropiate string values for the selectors for the
 * selected category and its default level, which is interesting when
 * the category changes.
 * If no value is given for a category, it will return the first one
 * by default.
 * @param configs
 * @param commodityValue
 */
function getDefaultCommodityCategoryLevel(
  dashboardConfigItems: Array<DashboardConfigItem>,
  commodityValue?: string,
) {
  const selectedCommodity = dashboardConfigItems.find((config, index) =>
    commodityValue ? config.commodity.name === commodityValue : index === 0,
  )

  if (!selectedCommodity) {
    return { commodity: '', category: '', level: '' }
  }

  return {
    commodity: selectedCommodity.commodity.name,
    category: selectedCommodity.category.name,
    level: selectedCommodity.level?.name,
  }
}

/**
 * Get correct category and level values , on change of category the level
 * does change
 * If no value is given for a category, it will return the first one
 * by default.
 * @param config
 * @param categoryValue
 */
function getCategoryAndLevel(
  dashboardConfigItems: Array<DashboardConfigItem>,
  categoryValue?: string,
) {
  const selectedCategory = dashboardConfigItems.find((config, index) =>
    categoryValue ? config.category.name === categoryValue : index === 0,
  )

  if (!selectedCategory) {
    return { category: '', level: '' }
  }

  return {
    category: selectedCategory.category.name,
    level: selectedCategory.level?.name,
  }
}

/**
 * New dashboard form dialog. Open by default, so whenever it is rendered, it will
 * also render the modal background.
 *
 * It requires an array of the available categories (and its nested levels) so it
 * can provide the appropriate options for the selectors.
 *
 * It disables the "Create" button until all the required fields have values introduced,
 * and also allows for an optional `isAwaitingConfirmation` prop that will disable
 * all the inputs and display a "loading" visual cue.
 *
 * TODO: Check if the different elements that compose this dialog should
 * be moved to some reusable components.
 */
const AddNewDashboardDialog = ({
  onClose,
  onAdd,
  dashboardConfigItems,
  isAwaitingConfirmation = false,
  isOnCopyMode = false,
  copyMeta = undefined,
  isUnsavedDashboard = false,
}: Props) => {
  const classes = useStyles()

  const [model, setModel] = useState(() => {
    const { category, commodity, level } = getDefaultCommodityCategoryLevel(
      dashboardConfigItems,
    )
    return {
      name: copyMeta?.name ?? '',
      description: copyMeta?.description ?? '',
      selectedCommodity: copyMeta?.commodity ?? commodity,
      selectedCategory: copyMeta?.category ?? category,
      selectedLevel: copyMeta?.level ?? level,
    }
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name: fieldName, value } = event.target
    setModel((prevModel) => ({ ...prevModel, [fieldName]: value }))
  }

  const handleCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setModel((prevModel) => {
      const { category, level } = getCategoryAndLevel(
        dashboardConfigItems,
        value,
      )
      return {
        ...prevModel,
        selectedCategory: category,
        selectedLevel: level,
      }
    })
  }

  const handleCommodityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setModel((prevModel) => {
      const { commodity, category, level } = getDefaultCommodityCategoryLevel(
        dashboardConfigItems,
        value,
      )
      return {
        ...prevModel,
        selectedCategory: category,
        selectedLevel: level,
        selectedCommodity: commodity,
      }
    })
  }

  const handleCreateClick = () => {
    const dashboardConfig = dashboardConfigItems.find(
      (config) =>
        config.commodity.name === model.selectedCommodity &&
        config.category.name === model.selectedCategory &&
        config?.level?.name === model.selectedLevel,
    )
    if (dashboardConfig) {
      onAdd({
        name: model.name,
        description: model.description,
        configId: dashboardConfig.configId,
      })
    }
  }

  const {
    name,
    description,
    selectedCategory,
    selectedLevel,
    selectedCommodity,
  } = model

  const commodityOptions = useMemo(() => {
    const options: { [key: string]: { name: string; label: string } } = {}
    dashboardConfigItems.forEach((config) => {
      options[config.commodity.name] = config.commodity
    })
    return Object.values(options)
  }, [dashboardConfigItems])

  const categoryOptions = useMemo(() => {
    const options: { [key: string]: { name: string; label: string } } = {}
    dashboardConfigItems.forEach((config) => {
      if (config.commodity.name === selectedCommodity && config.category) {
        options[config.category.name] = config.category
      }
    })
    return Object.values(options)
  }, [dashboardConfigItems, selectedCommodity])

  const levelOptions = useMemo(() => {
    const options: { [key: string]: { name: string; label: string } } = {}
    dashboardConfigItems.forEach((config) => {
      if (
        config.category.name === selectedCategory &&
        config.commodity.name === selectedCommodity &&
        config.level
      ) {
        options[config.level.name] = config.level
      }
    })
    return Object.values(options)
  }, [dashboardConfigItems, selectedCommodity, selectedCategory])

  const isCreating = () => {
    if (isAwaitingConfirmation) {
      return true
    }
    if (!name.trim().length || !selectedCategory.trim().length) {
      return true
    }
    if (levelOptions.length && !selectedLevel?.trim().length) {
      return true
    }
    if (commodityOptions.length && !selectedCommodity?.trim().length) {
      return true
    }
    return false
  }

  return (
    <Dialog
      open
      onClose={onClose}
      className={classes.dialog}
      maxWidth="md"
      classes={{ paperWidthMd: classes.dialog }}
    >
      <header className={classes.header}>
        <Typography variant="h6">
          {isOnCopyMode ? 'Copy Dashboard' : 'Create New Dashboard'}
        </Typography>
        <IconButton size="small" title="Cancel" onClick={onClose}>
          <Clear />
        </IconButton>
      </header>
      <DialogContent dividers className={classes.content}>
        <TextField
          name="name"
          autoComplete="off"
          label="Dashboard name"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: DASHBOARD_NAME_MAX_LENGTH }}
          className={classes.name}
          value={name}
          onChange={handleChange}
          disabled={isAwaitingConfirmation}
        />
        <TextField
          name="description"
          label="Description"
          InputLabelProps={{ shrink: true }}
          multiline
          inputProps={{ maxLength: DASHBOARD_DESCRIPTION_LENGTH }}
          rows={4}
          className={classes.description}
          value={description}
          onChange={handleChange}
          disabled={isAwaitingConfirmation}
        />
        <TextField
          name="selectedCommodity"
          label="Commodity"
          select
          className={classes.commodity}
          value={selectedCommodity}
          onChange={handleCommodityChange}
          disabled={
            isAwaitingConfirmation || isOnCopyMode || isUnsavedDashboard
          }
        >
          {commodityOptions.map((cat) => (
            <MenuItem key={`commodity_${cat.name}`} value={cat.name}>
              {cat.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="selectedCategory"
          label="Category"
          select
          className={classes.category}
          value={selectedCategory}
          onChange={handleCategoryChange}
          disabled={
            isAwaitingConfirmation || isOnCopyMode || isUnsavedDashboard
          }
        >
          {categoryOptions.map((cat) => (
            <MenuItem key={`category_${cat.name}`} value={cat.name}>
              {cat.label}
            </MenuItem>
          ))}
        </TextField>
        {levelOptions.length > 0 && (
          <TextField
            name="selectedLevel"
            label="Level"
            select
            className={classes.level}
            value={selectedLevel}
            onChange={handleChange}
            disabled={
              isAwaitingConfirmation || isOnCopyMode || isUnsavedDashboard
            }
          >
            {levelOptions.map((lev) => {
              if (!lev) {
                return null
              }
              return (
                <MenuItem key={`level_${lev.name}`} value={lev.name}>
                  {lev.label}
                </MenuItem>
              )
            })}
          </TextField>
        )}
      </DialogContent>
      <footer className={classes.footer}>
        <Button
          onClick={onClose}
          variant="contained"
          disabled={isAwaitingConfirmation}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateClick}
          variant="contained"
          color="primary"
          disabled={isCreating()}
        >
          {!isAwaitingConfirmation ? (
            `${isOnCopyMode ? 'Copy' : 'Create'}`
          ) : (
            <CircularProgress size="1em" />
          )}
        </Button>
      </footer>
    </Dialog>
  )
}

export default AddNewDashboardDialog
