import React, {
  ReactNode,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Box, Button, IconButton, styled } from '@material-ui/core'
import { Map as MapIcon, ArrowLeft } from 'react-feather'
import Papa from 'papaparse'
import Card, {
  getDraggedTableGroupsTypes,
  getDroppedTableGroups,
  setDraggedTableGroup,
} from 'shared/components/DashboardCard/Card'
import CheckboxGroupWithUtilities, {
  Metadata,
} from 'shared/components/CheckboxGroupWithUtilities'
import {
  LeftMenu,
  RightMenu,
  Options,
  Body,
  CreateNewCardButton,
  LeftSideOptions,
  StyledSaveIcon,
} from 'shared/components/DashboardLayout'
import Collapsible from 'shared/components/Collapsible'
import SideBarMenu from 'shared/components/SideBarMenu'
import {
  Group,
  DraggableResizableCard,
  Filter,
  CardDefinition,
  DataSeries,
  SelectedFilter,
  CalculatorState,
  DashboardConfigItem,
  Visualization,
  DataSeriesType,
  Calculation,
  LegendPosition,
  DynamicColumnsConfig,
  SingleCalculationOption,
} from 'shared/types'
import useDashboardCards, {
  buildDataSeriesOptionsForMeterTable,
} from 'shared/hooks/useDashboardCards'
import DraggableResizableGrid, {
  GridItem,
} from 'shared/components/DraggableResizableGrid'
import { downloadCsvFile, isTimeFilter } from 'shared/helpers'
import TimezoneSelector from 'shared/components/TimezoneSelector'
import SkeletonDashboard from 'shared/components/SkeletonDashboard'
import RangeSelector from 'shared/components/RangeSelector'

import { getGroupOptionsForTimeInterval } from 'shared/common/dashboardOptions'
import useCardsDataProviders, {
  DataProvider,
} from 'shared/hooks/useCardsDataProviders'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import { setDataSeriesData } from 'shared/utils/dataSeriesDragAndDrop'
import CalculatorMenu, { useCalculators } from '../Calculator/CalculatorMenu'
import AlertContext from '../../contexts/AlertsContext'
import HourBeginningOrEndingSelector from '../HourBeginningOrEndingSelector'
import { CalculatorDataSeriesOption } from '../Calculator/Calculator'
import { updateOverviewCardStatus } from '../../apiClient'
// import MeterMap from '../DashboardCard/MeterMap/MeterMap'
import MultiLevelDropDownMenu from './MultiLevelDropDownMenu'
import MeterMap from '../DashboardCard/MeterMap/MeterMap'

export { setDataSeriesData } from 'shared/utils/dataSeriesDragAndDrop'

const MapButton = styled(Button)(({ theme }) => ({
  zIndex: 1000,
  width: '200px',
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(1),
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: '0px',
  marginLeft: theme.spacing(1),
}))

const StyledMapIcon = styled(MapIcon)(({ theme }) => ({
  verticalAlign: 'middle',
  display: 'inline-flex',
  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing(1),
  },
}))

function getCorrectedDataSeriesGroup(
  dataSeriesItem: DataSeries,
  calculatorTagsMap: Map<string, CalculatorState>,
  buildCalculatedDataSeries?: (
    calculator: CalculatorState,
    originalDataSeries?: DataSeries,
  ) => DataSeries,
): DataSeries | null {
  const { label, groupedItems } = dataSeriesItem
  if (!groupedItems) {
    return dataSeriesItem
  }

  const matchingCalculatorTag = calculatorTagsMap.get(dataSeriesItem.id)
  if (matchingCalculatorTag && buildCalculatedDataSeries) {
    const newSeries = buildCalculatedDataSeries(
      matchingCalculatorTag,
      dataSeriesItem,
    )
    const mustUpdateLabel = newSeries.label !== label
    let mustUpdateCalculation = false
    if (newSeries.groupedItems) {
      /**
       * Since calculator updates on blurs... these checks will work well, as the calculator forces a user to blur when they select an option.
       */
      const areExpressionsDifferent =
        newSeries.groupedItems.expression !== groupedItems.expression
      const areExpressionScopesDifferent =
        JSON.stringify(newSeries.groupedItems.expressionScope) !==
        JSON.stringify(groupedItems.expressionScope)
      const areExpressionDataIdsDifferent =
        JSON.stringify(newSeries.groupedItems.expressionDataIdMap) !==
        JSON.stringify(groupedItems.expressionDataIdMap)
      mustUpdateCalculation =
        areExpressionsDifferent ||
        areExpressionScopesDifferent ||
        areExpressionDataIdsDifferent
    }

    if (mustUpdateLabel || mustUpdateCalculation) {
      return {
        ...newSeries,
      }
    }

    return dataSeriesItem
  }
  return null
}

export interface CardsDataSeriesManager<T extends object, U = any>
  extends DataProvider<T, U> {
  /** Function to execute per dataseries to check for corrections.
   * It should return the same dataseries instance if no corrections are needed, a new instance
   * in the opposite case. In last place, if null is returned, it will mean the dataseries should
   * be removed. */
  correctCardDataSeries?: (
    card: CardDefinition,
    dataSeries: DataSeries,
  ) => DataSeries | null
}

/**
 * Helper utility to create the proper corrections map for a cards manager,
 * so only relevant corrections are included.
 * @param cards Cards array to check for the corrections.
 * @param getUpdatedDataSeriesItem Function to execute per dataseries to check for corrections.
 * It should return the same dataseries instance if no corrections are needed, a new instance
 * in the opposite case. In last place, if null is returned, it will mean the dataseries should
 * be removed.
 */
function getCardsUpdates(
  cards: Array<CardDefinition>,
  calculators: CalculatorState[],
  buildCalculatedDataSeries?: (
    calculator: CalculatorState,
    originalDataSeries?: DataSeries,
  ) => DataSeries,
  getUpdatedDataSeriesItem?: (
    card: CardDefinition,
    dataSeries: DataSeries,
  ) => DataSeries | null,
) {
  const corrections: Array<[string, CardDefinition]> = []

  if (!getUpdatedDataSeriesItem) {
    return corrections
  }

  cards.forEach((card) => {
    const updatedDataSeries: Array<DataSeries> = []
    let hasChanged = false
    card.dataSeries.forEach((dataSeriesItem) => {
      let updatedDataSeriesItem
      if (dataSeriesItem.groupedItems) {
        const calculatorTagsMap = new Map(calculators.map((x) => [x.uid, x]))
        updatedDataSeriesItem = getCorrectedDataSeriesGroup(
          dataSeriesItem,
          calculatorTagsMap,
          buildCalculatedDataSeries,
        )
      } else if (getUpdatedDataSeriesItem) {
        updatedDataSeriesItem = getUpdatedDataSeriesItem(card, dataSeriesItem)
      }

      hasChanged = hasChanged || updatedDataSeriesItem !== dataSeriesItem

      if (updatedDataSeriesItem) {
        updatedDataSeries.push(updatedDataSeriesItem)
      }
    })

    if (hasChanged) {
      corrections.push([card.id, { ...card, dataSeries: updatedDataSeries }])
    }
  })

  return corrections
}

export type BuildCalculatorDataSeries = (
  calculator: CalculatorState,
  originalSeries?: DataSeries,
) => DataSeries

export interface Props<T extends object, U = any> {
  dashboardId: string
  defaultCards: Array<DraggableResizableCard>
  filtersDefinitions: DashboardConfigItem['filters']
  selectedTimeFilters: SelectedFilter[]
  filters: Filter[]
  timeFilters?: Filter[]
  dynamicColumnsConfig?: DynamicColumnsConfig
  timeInterval: number
  defaultNewCardGrouping: Group
  selectedTimezone?: string
  isHourEndingSelected?: Boolean
  isInitializing: boolean
  isLoading: boolean
  areFiltersLoading: boolean
  cardsDataSeriesManager: CardsDataSeriesManager<T, U>
  calculatorDataSeriesOptions?: CalculatorDataSeriesOption[]
  calculatorLabelAccessor?:
    | Exclude<keyof CalculatorDataSeriesOption, 'tagOptions'>
    | ((option: CalculatorDataSeriesOption) => string)
  buildCalculatorDataSeries?: BuildCalculatorDataSeries
  onIsHourEndingSelectedChange?: (
    event: SyntheticEvent,
    newIsHourEndingSelected: Boolean,
  ) => void
  calculators?: CalculatorState[]
  onTimezoneChange?: (event: SyntheticEvent, newTimezone: string) => void
  onSave: (
    event: SyntheticEvent,
    cards: Array<DraggableResizableCard>,
    extras: {
      calculators?: CalculatorState[]
    },
  ) => void
  onFilterChange: (name: string, metadata: Array<Metadata>) => void
  rangeFilters?: RangeFilter[]
  onRangeFilterChange?: (
    name: string,
    metadata: Array<[number, number]> | number | number[],
  ) => void
  extraOptions?: ReactNode
  children?: ReactNode
  meterGrouping?: Group | string
}

/**
 * Simple default manager that will make all dataseries to appear as "loading" until
 * a "real" manager is set.
 * */
const DEFAULT_MANAGER: CardsDataSeriesManager<any> = {
  getArgumentToFetch: () => null,
  fetchData: () => Promise.resolve(),
}

/**
 * Base component to be used by specific dashboards to handle all the common behaviors
 * and the cards state.
 */
// FIXME: Handle saved group tags
const BaseDashboard = <T extends object, U>({
  dashboardId,
  isInitializing,
  isLoading,
  areFiltersLoading,
  defaultCards,
  timeFilters = [],
  rangeFilters,
  onRangeFilterChange,
  filtersDefinitions,
  filters,
  timeInterval,
  dynamicColumnsConfig,
  defaultNewCardGrouping,
  selectedTimezone,
  isHourEndingSelected,
  extraOptions,
  children,
  cardsDataSeriesManager,
  onTimezoneChange,
  calculators,
  calculatorLabelAccessor,
  buildCalculatorDataSeries,
  calculatorDataSeriesOptions,
  onIsHourEndingSelectedChange,
  onFilterChange,
  onSave,
  meterGrouping,
}: Props<T, U>) => {
  const publishNotification = useContext(AlertContext)

  const [manager, setManager] = useState(DEFAULT_MANAGER)
  const [isMeterMapSelected, setMeterMapSelectedState] = useState<Boolean>(
    false,
  )

  const groupOptions = useMemo(
    () => getGroupOptionsForTimeInterval(timeInterval),
    [timeInterval],
  )

  /* The state of the cards is managed on this component */
  const {
    cards,
    addCard,
    getCardGroupOptions,
    addSeriesToCard,
    deleteCard,
    duplicateCard,
    updateCard,
    updateCards,
    changeCardLayout,
  } = useDashboardCards(
    defaultCards,
    filtersDefinitions,
    groupOptions,
    defaultNewCardGrouping,
    meterGrouping,
  )

  /**
   * Sets the cards isOverview property to the value of status to indicate if it is shown in the overview dashboard
   */
  const setOverviewCardStatus = async (cardId: string, status: boolean) => {
    try {
      await updateOverviewCardStatus(cardId, dashboardId, {
        status,
      })
      // Set the isOverview property on the card to status to update it in the UI
      updateCard(cardId, {
        ...((cards?.find(
          (card) => card.id === cardId,
        ) as DraggableResizableCard) || {}),
        isOverview: status,
      })
      publishNotification(
        `Card ${status ? 'added to' : 'removed from'} Overview`,
        'success',
      )
    } catch (e) {
      if (e?.response?.status === 404) {
        publishNotification(
          `Please save dashboard before adding to Overview `,
          'info',
        )
      } else {
        publishNotification(
          `Failed to ${status ? 'add card to' : 'remove card from'} Overview`,
          'error',
        )
      }
    }
  }

  /* For the current cards, the stored manager is used to handle the data that is
  fetched and cached so it can be consumed by individual cards  */
  const {
    clearCache,
    getDataProvider,
    clearGroupCache,
  } = useCardsDataProviders(cards, manager)

  const filterDefinitionsMap = useMemo(() => {
    const map = new Map()
    filtersDefinitions.forEach((def) => {
      map.set(def.name, def)
    })
    return map
  }, [filtersDefinitions])

  const calculatorMenuProps = useCalculators(
    calculators?.length ? calculators : [],
  )

  /* Each manager can "correct" each card's dataseries when is initially set, so
  whenever it changes, any corrections needed are also applied.  */
  useEffect(() => {
    if (isInitializing) {
      return
    }

    setManager(cardsDataSeriesManager)

    const corrections = getCardsUpdates(
      cards,
      calculatorMenuProps.calculators,
      buildCalculatorDataSeries,
      cardsDataSeriesManager.correctCardDataSeries,
    )

    if (corrections.length > 0) {
      updateCards(corrections)
    }

    /* If the "key" of the new manager is different that the previous one, the cache should be cleared */
    if (manager.key !== cardsDataSeriesManager.key) {
      clearCache()
      clearGroupCache()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsDataSeriesManager, isInitializing, calculatorMenuProps.calculators])

  const handleDownload = (title: string, downloadData) => {
    const csv = Papa.unparse(downloadData)
    downloadCsvFile(title, csv)
  }

  if (isInitializing) {
    return <SkeletonDashboard />
  }
  const mapCard = cards.find(
    (card) => card.visualization === Visualization.MeterMap,
  )
  return (
    <>
      {isMeterMapSelected && mapCard ? (
        <>
          <MeterMap
            card={mapCard}
            fetchMeters={(
              bounds,
              groupColumnsForMap,
              scenarios,
              mapSettings,
            ) => {
              const idVal = 'newMapDataSeries'
              const seriesToAdd: any = {
                label: '',
                id: idVal,
                selectedCalculation: [
                  Calculation.Sum,
                  ...groupColumnsForMap.map(() => Calculation.StringFirst),
                ],
                type: DataSeriesType.Dimensionless,
                spatialFilters: [
                  {
                    columnName: 'coordinates',
                    type: 'rectangular',
                    minCoords: [bounds.southWest.lat, bounds.southWest.lng],
                    maxCoords: [bounds.northEast.lat, bounds.northEast.lng],
                  },
                ],
                extras: {
                  groupColumnsForMap,
                  scenarios: scenarios.length ? scenarios : ['Residential'],
                },
              }
              const newCard = {
                ...mapCard,
                dataSeries: [seriesToAdd],
                mapSettings,
              }
              updateCard(mapCard.id, newCard)
            }}
            getData={getDataProvider(mapCard)}
            dynamicColumnsConfig={{
              valueColumn: dynamicColumnsConfig?.valueColumn,
              meterIdColumn: dynamicColumnsConfig?.meterIdColumn ?? '',
            }}
          />
        </>
      ) : (
        <>
          <Options>
            <LeftSideOptions>
              {
                /* TODO: Consider changing it to a render function so it can use the cards as argument */
                extraOptions
              }
              {onTimezoneChange && selectedTimezone && (
                <TimezoneSelector
                  selectedTimezone={selectedTimezone}
                  onChange={onTimezoneChange}
                />
              )}
              {onIsHourEndingSelectedChange &&
                isHourEndingSelected !== undefined && (
                  <HourBeginningOrEndingSelector
                    isHourEndingSelected={isHourEndingSelected}
                    onChange={onIsHourEndingSelectedChange}
                  />
                )}
            </LeftSideOptions>

            <Box flexGrow={1} />

            {Boolean(mapCard) && (
              <MapButton
                color="primary"
                variant="contained"
                onClick={() => setMeterMapSelectedState(true)}
              >
                <StyledMapIcon />
                <Box display={{ xs: 'none', lg: 'inline-block' }}>
                  Meter Map
                </Box>
              </MapButton>
            )}

            <StyledIconButton
              onClick={(e) => {
                onSave(e, cards, {
                  calculators: calculatorMenuProps.calculators.map(
                    ({ initOpen, ...restCalculator }) => restCalculator,
                  ),
                })
              }}
            >
              <StyledSaveIcon data-testid="save-dashboard" />
            </StyledIconButton>
          </Options>
          <Body>
            <DraggableResizableGrid
              draggableHandle=".dashboard-card-drag-handle"
              onLayoutChange={(newLayoutByKey) => {
                changeCardLayout(newLayoutByKey)
              }}
            >
              {cards
                .filter(
                  (singleCard) =>
                    singleCard.visualization !== Visualization.MeterMap,
                )
                .map((singleCard) => {
                  return (
                    <GridItem key={singleCard.id} layout={singleCard.layout}>
                      <Card
                        definition={singleCard}
                        onDefinitionChange={(event, updatedDefinition) => {
                          const changedDefinition = { ...updatedDefinition }
                          if (
                            singleCard.visualization !==
                            updatedDefinition.visualization
                          ) {
                            if (
                              updatedDefinition.visualization ===
                                Visualization.MeterTable &&
                              meterGrouping
                            ) {
                              changedDefinition.dataSeries = [
                                ...buildDataSeriesOptionsForMeterTable({
                                  ...updatedDefinition,
                                  group: meterGrouping,
                                }),
                              ]
                            } else {
                              changedDefinition.dataSeries = singleCard.dataSeries.filter(
                                (series) =>
                                  !series?.extras?.visualizationType &&
                                  series?.extras?.visualizationType !==
                                    Visualization.MeterTable,
                              )
                            }
                          }
                          updateCard(singleCard.id, changedDefinition)
                        }}
                        dragIconClassName="dashboard-card-drag-handle"
                        onDrop={(event, droppedSeries) => {
                          const [newTableGroups] = getDroppedTableGroups(
                            event.dataTransfer,
                          )

                          if (
                            singleCard.visualization ===
                              Visualization.MeterTable &&
                            newTableGroups
                          ) {
                            return addSeriesToCard(singleCard.id, {
                              id: newTableGroups.groupType,
                              label: newTableGroups.header,
                              type: DataSeriesType.Dimensionless,
                              selectedCalculation: [
                                Calculation.StringFirst,
                                'stringFirst',
                              ] as SingleCalculationOption,
                              // TODO : CHANGE THIS EXTRAS AND CHANGES THE WAY DATASERIES SEND COLUMS OR STUFF
                              extras: {
                                visualizationType: Visualization.MeterTable,
                                columnName: newTableGroups.groupType,
                              },
                            })
                          }
                          return addSeriesToCard(singleCard.id, {
                            ...droppedSeries,
                            extras: {
                              ...droppedSeries.extras,
                            },
                          })
                        }}
                        defaultTitle="untitled card"
                        canDrop={(e, type) => {
                          if (
                            singleCard.visualization !==
                            Visualization.MeterTable
                          ) {
                            return singleCard.dataSeries.length < 10
                          }

                          const dataSeriesTypes = new Set(
                            singleCard.dataSeries.map((series) => series.type),
                          )
                          const isWeather = type === DataSeriesType.Temperature
                          const allowDataSeries =
                            !isWeather && dataSeriesTypes.size > 0
                              ? true
                              : dataSeriesTypes.has(type)
                          return type
                            ? allowDataSeries &&
                                singleCard.dataSeries.length < 10
                            : getDraggedTableGroupsTypes(e.dataTransfer)
                                .length > 0 &&
                                !isTimeFilter(
                                  getDraggedTableGroupsTypes(e.dataTransfer)[0],
                                ) &&
                                singleCard.dataSeries.length < 10
                        }}
                        getData={getDataProvider(singleCard)}
                        groupOptions={getCardGroupOptions(singleCard)}
                        onDownload={(e, title, data) => {
                          handleDownload(title, data)
                        }}
                        moreActionsContent={
                          <MultiLevelDropDownMenu
                            onDelete={() => deleteCard(singleCard.id)}
                            onDuplicate={() => {
                              return duplicateCard(singleCard.id)
                                ? publishNotification(
                                    'Copy of the Card Created',
                                    'success',
                                  )
                                : publishNotification(
                                    'Failed to Duplicate the Card',
                                    'error',
                                  )
                            }}
                            onLegendChange={(e, newLegendPosition) => {
                              updateCard(singleCard.id, {
                                ...singleCard,
                                legendPosition: newLegendPosition,
                              })
                            }}
                            selectedLegendOption={
                              singleCard.legendPosition || LegendPosition.Right
                            }
                            isOverview={Boolean(singleCard.isOverview)}
                            isSummaryView={Boolean(singleCard.isSummaryView)}
                            onSummaryOptionsClick={() => {
                              updateCard(singleCard.id, {
                                ...singleCard,
                                isSummaryView: !singleCard.isSummaryView,
                              })
                            }}
                            onAddToOverview={() =>
                              setOverviewCardStatus(singleCard.id, true)
                            }
                            onRemoveFromOverview={() =>
                              setOverviewCardStatus(singleCard.id, false)
                            }
                          />
                        }
                        timezone={selectedTimezone}
                        dataSeriesFilters={filters}
                        isHourEndingSelected={isHourEndingSelected}
                      />
                    </GridItem>
                  )
                })}
            </DraggableResizableGrid>
            <CreateNewCardButton onClick={addCard} />
          </Body>
        </>
      )}
      <LeftMenu>
        {children}
        {!isMeterMapSelected && (
          <>
            {calculatorDataSeriesOptions && buildCalculatorDataSeries && (
              <CalculatorMenu
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...calculatorMenuProps}
                dataSeriesOptions={calculatorDataSeriesOptions}
                labelAccessor={calculatorLabelAccessor}
                onDrag={(e, calculator) => {
                  const dataSeries = buildCalculatorDataSeries(calculator)
                  setDataSeriesData(e.dataTransfer, dataSeries)
                }}
              />
            )}
          </>
        )}
      </LeftMenu>
      <RightMenu>
        {Boolean(mapCard) && isMeterMapSelected && (
          <MapButton
            color="primary"
            variant="contained"
            onClick={() => setMeterMapSelectedState(false)}
          >
            <ArrowLeft />
            Back
          </MapButton>
        )}
        {filters && filters.length > 0 && (
          <SideBarMenu
            title="Dimension Filters"
            isLoading={areFiltersLoading || isLoading}
          >
            {filters.map(({ name, title, items }, index) => {
              const hasManyItems = items.length > 10
              return (
                <Collapsible
                  title={title}
                  key={name}
                  defaultMenuIsOpen={index < 1}
                  onTitleDragStart={(e) => {
                    if (filterDefinitionsMap.has(name)) {
                      setDraggedTableGroup(e.dataTransfer, {
                        header: title,
                        groupType: name,
                      })
                    }
                  }}
                >
                  <CheckboxGroupWithUtilities
                    items={items}
                    onCheckboxChange={(e, metadata) => {
                      onFilterChange(name, metadata)
                    }}
                    hasSearch={hasManyItems}
                    hasSelectAllClearAll
                    isVirtual={hasManyItems}
                    virtualMinHeight={200}
                    virtualItemSize={30}
                  />
                </Collapsible>
              )
            })}
          </SideBarMenu>
        )}
        {rangeFilters && rangeFilters.length > 0 && !isMeterMapSelected && (
          <SideBarMenu
            title="Range Filters"
            isLoading={areFiltersLoading || isLoading}
          >
            {onRangeFilterChange &&
              rangeFilters.map(
                ({ name, title, minValue, maxValue, value }, index) => {
                  return (
                    <Collapsible
                      title={title}
                      key={name}
                      defaultMenuIsOpen={index < 1}
                    >
                      <RangeSelector
                        minRangeValue={minValue}
                        maxRangeValue={maxValue}
                        value={value}
                        onChange={(newValue) => {
                          onRangeFilterChange(name, newValue)
                        }}
                      />
                    </Collapsible>
                  )
                },
              )}
          </SideBarMenu>
        )}
        {!isMeterMapSelected && (
          <SideBarMenu title="Time Filters">
            {timeFilters?.map(({ name, title, items }) => {
              const hasItems = items.length > 0
              const hasManyItems = items.length > 10
              return (
                <Collapsible
                  title={title}
                  key={name}
                  onTitleDragStart={
                    name !== Group.DayType
                      ? (e) =>
                          setDraggedTableGroup(e.dataTransfer, {
                            header: title,
                            groupType: name,
                          })
                      : undefined
                  }
                  isCollapsible={hasItems}
                >
                  {hasItems && (
                    <CheckboxGroupWithUtilities
                      items={items}
                      onCheckboxChange={(e, metadata) => {
                        onFilterChange(name, metadata)
                      }}
                      hasSearch={hasManyItems}
                      hasSelectAllClearAll
                      isVirtual={hasManyItems}
                      virtualMinHeight={200}
                      virtualItemSize={30}
                    />
                  )}
                </Collapsible>
              )
            })}
          </SideBarMenu>
        )}
      </RightMenu>
    </>
  )
}

export default BaseDashboard
