import { ProductModuleConfig } from 'shared/types'
import Risk, { RiskMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: RiskMenu,
  MainContent: Risk,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
