import { ProductModuleConfig } from 'shared/types'
import RenewableManagement, { RenewableManagementMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: RenewableManagementMenu,
  MainContent: RenewableManagement,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
