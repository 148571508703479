import {
  TileLayer,
  CircleMarker,
  Circle,
  Popup,
  useMap,
  LayersControl,
} from 'react-leaflet'
import Gradient from 'javascript-color-gradient'
import React, { useState } from 'react'
import { Button, makeStyles, styled } from '@material-ui/core'
import generateDragAndDropUtilities from 'shared/utils/generateDragAndDropUtilities'
import {
  AxisId,
  CardDefinition,
  CardTableGroup,
  DataSeries,
} from 'shared/types'
import {
  getDataSeriesData,
  getDataSeriesTypes,
} from 'shared/utils/dataSeriesDragAndDrop'
import { GetDataHandler } from '../helpers'
import gradientMap from '../gradientMap'
import ChipsInput, { Chip } from '../ChipsInput'
import MapLegend from './MapLegend'
import {
  Bounds,
  LoadLegendMap,
  MapSettings,
  SeriesToColorAndSizeByTypes,
  SeriesNameAndType,
} from './common'

const {
  getDataItemsSuffixes: getDraggedTableGroupsTypes,
  getDataItems: getDroppedTableGroups,
} = generateDragAndDropUtilities(
  'x-card-table-group-',
  (data: Omit<CardTableGroup, 'direction'>) => data.groupType,
)

const DEFAULT_MARKER_RADIUS = 10
const DEFAULT_MARKER_STROKE_WIDTH = 2
const DEFAULT_MARKER_FILL_COLOR = '#0072ce'
const DEFAULT_MARKER_STROKE_COLOR = '#fefefe'
const DEFAULT_MARKER_FILL_OPACITY = 0.8

const MapButton = styled(Button)({
  marginLeft: 'auto',
  zIndex: 1000,
})

interface DynamicColumnsConfig {
  valueColumn: string | undefined
  meterIdColumn: string
}

interface Props {
  fetchMeters: (
    bounds: Bounds,
    groupColumnsForMap: string[],
    scenarios: string[],
    mapSettings: MapSettings,
  ) => void
  getData: GetDataHandler
  card: CardDefinition
  dynamicColumnsConfig: DynamicColumnsConfig
}

const useStyles = makeStyles((theme) => ({
  dropDownContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  rigid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 2000,
    width: '50%',
  },
  chipInput: {
    color: 'white',
    width: '37%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    zIndex: 10000,
  },
}))

const renderPopup = (
  meter: { [key: string]: number | string },
  dynamicColumnsConfig: DynamicColumnsConfig,
  colorValue?: string,
  sizeValue?: string,
) => {
  return (
    <Popup>
      <div>
        <strong>Meter: </strong> {meter[dynamicColumnsConfig.meterIdColumn]}
      </div>
      {dynamicColumnsConfig.valueColumn && (
        <div>
          <strong>Value: </strong> {meter[dynamicColumnsConfig.valueColumn]} kWh{' '}
          <br />
        </div>
      )}
      {colorValue !== dynamicColumnsConfig.valueColumn &&
        colorValue !== undefined && (
          <div>
            <strong>{colorValue}: </strong>
            {meter[colorValue]}
            <br />
          </div>
        )}
      {sizeValue !== dynamicColumnsConfig.valueColumn &&
        sizeValue !== undefined && (
          <>
            <strong>{sizeValue}: </strong>
            {meter[sizeValue]}
            <br />
          </>
        )}
    </Popup>
  )
}

export const getColorAndSizeFunctions = (
  colorByColumnName: string | undefined,
  colorValueType: SeriesToColorAndSizeByTypes | undefined,
  sizeByColumnName: string | undefined,
  meters: {}[],
): {
  getMarkerSize: Function
  getMarkerColor: Function
  legendMap: LoadLegendMap | object
} => {
  let getMarkerSize: (markerValue) => number = () => DEFAULT_MARKER_RADIUS
  let getMarkerColor: (markerValue) => any = () => DEFAULT_MARKER_STROKE_COLOR
  let legendMap = {}
  enum GradientTypes {
    numeric = 'numeric',
    nonNumeric = 'nonNumeric',
  }
  if (sizeByColumnName) {
    const uniqueValues: number[] = Array.from(
      new Set(
        meters.map((m) =>
          m[sizeByColumnName] ? parseFloat(m[sizeByColumnName]) : 0,
        ),
      ),
    )

    const maxSize = 50
    const minSize = 10
    const maxValue = Math.max(...uniqueValues)

    getMarkerSize = (markerValue) => {
      const percent = Math.ceil((parseFloat(markerValue) * 100) / maxValue)
      const size = Math.ceil((percent / 100) * maxSize)
      return size >= minSize ? size : minSize
    }
  }

  let minValue: number = 0
  let maxValue: number = 0

  let colorType

  if (colorByColumnName) {
    colorType = GradientTypes.numeric
    let uniqueValues: any[] = []
    if (colorValueType === SeriesToColorAndSizeByTypes.Group) {
      colorType = GradientTypes.nonNumeric
      // Color value is non numeric so just map the unique values
      uniqueValues = Array.from(
        new Set(
          meters
            .filter((m) => m[colorByColumnName])
            .map((m) => m[colorByColumnName]),
        ),
      )
    } else {
      uniqueValues = Array.from(
        new Set(
          meters
            .map((m) =>
              m[colorByColumnName] ? parseFloat(m[colorByColumnName]) : 1,
            )
            .sort((a, b) => a - b),
        ),
      )
      // eslint-disable-next-line prefer-destructuring
      minValue = uniqueValues[0]
      maxValue = uniqueValues[uniqueValues.length - 1]
    }

    const numberOfUniqueValues = uniqueValues.length
    // Create a gradient of the specified type with the number of unique values as color steps
    const gradient: Gradient = gradientMap(colorType, numberOfUniqueValues)

    getMarkerColor = (markerValue) => {
      // If there is no value for this marker return a default color
      if (!markerValue) {
        return '#cccccc'
      }
      const gradientIndex =
        uniqueValues.findIndex((value) =>
          colorValueType === SeriesToColorAndSizeByTypes.Load
            ? value === parseFloat(markerValue)
            : value === markerValue,
        ) + 1

      return gradient.getColor(gradientIndex)
    }
    if (uniqueValues.length > 0 && colorType === GradientTypes.numeric) {
      legendMap = {
        min: {
          value: Math.round(minValue),
          color: gradient.getColor(1),
        },
        max: {
          value: Math.round(maxValue),
          color: gradient.getColor(uniqueValues.length),
        },
      }
    } else {
      legendMap = uniqueValues.reduce((acc, val) => {
        acc[val] = getMarkerColor(val)
        return acc
      }, {})
    }
  }

  return { getMarkerColor, getMarkerSize, legendMap }
}

const renderMarkers = (
  meters: any[],
  dynamicColumnsConfig: DynamicColumnsConfig,
  getMarkerSize: Function,
  getMarkerColor: Function,
  sizeValue?: SeriesNameAndType,
  colorValue?: SeriesNameAndType,
): any[] => {
  const defaultProps = {
    radius: DEFAULT_MARKER_RADIUS,
    weight: DEFAULT_MARKER_STROKE_WIDTH,
    color: DEFAULT_MARKER_STROKE_COLOR,
    fillColor: DEFAULT_MARKER_FILL_COLOR,
    fillOpacity: DEFAULT_MARKER_FILL_OPACITY,
  }

  // If neither are set then just return the default markers
  if (!sizeValue && !colorValue) {
    // the reason why i am using Circle instead of CircleMarker is because,
    // other wise react-leaflet will not allow me to redraw the same exact set
    // of meters
    return meters.map((meter) => (
      <Circle
        center={meter.coordinates}
        key={meter[dynamicColumnsConfig.meterIdColumn]}
        radius={defaultProps.radius}
        weight={defaultProps.weight}
        fillColor={DEFAULT_MARKER_FILL_COLOR}
        fillOpacity={DEFAULT_MARKER_FILL_OPACITY}
      >
        {renderPopup(meter, dynamicColumnsConfig)}
      </Circle>
    ))
  }
  const colorByColumnName =
    colorValue?.type === SeriesToColorAndSizeByTypes.Load
      ? dynamicColumnsConfig.valueColumn
      : colorValue?.name
  const sizeByColumnName =
    sizeValue?.type === SeriesToColorAndSizeByTypes.Load
      ? dynamicColumnsConfig.valueColumn
      : sizeValue?.name

  return meters.map((meter) => (
    <CircleMarker
      center={meter.coordinates}
      key={meter[dynamicColumnsConfig.meterIdColumn]}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...defaultProps}
      radius={
        sizeByColumnName
          ? (getMarkerSize && getMarkerSize(meter[sizeByColumnName])) ||
            DEFAULT_MARKER_RADIUS
          : DEFAULT_MARKER_RADIUS
      }
      weight={DEFAULT_MARKER_STROKE_WIDTH}
      fillColor={
        colorByColumnName
          ? (typeof getMarkerColor === 'function' &&
              getMarkerColor(meter[colorByColumnName])) ||
            DEFAULT_MARKER_FILL_COLOR
          : DEFAULT_MARKER_FILL_COLOR
      }
    >
      {renderPopup(
        meter,
        dynamicColumnsConfig,
        colorByColumnName,
        sizeByColumnName,
      )}
    </CircleMarker>
  ))
}

const processData = (
  card,
  getData,
  meterIdColumnName: string,
): { meters: any[]; errors: any[] } => {
  const cumulativeMetersMap: any = new Map()
  const cumulativeErrors: Array<{
    dataSeriesElement: DataSeries
    error: Error
  }> = []
  card.dataSeries.forEach((dataSeriesElement) => {
    const { data, error } = getData(dataSeriesElement)
    if (!data) {
      if (error) {
        cumulativeErrors.push({ dataSeriesElement, error })
      }
    } else {
      data.splits[0].seriesData.forEach((meter) => {
        const existingMeter = cumulativeMetersMap.get(meter[meterIdColumnName])
        cumulativeMetersMap.set(meter[meterIdColumnName], {
          ...existingMeter,
          ...meter,
        })
      })
    }
  })
  return { meters: [...cumulativeMetersMap.values()], errors: cumulativeErrors }
}

const ChildMap = ({
  fetchMeters,
  getData,
  card,
  dynamicColumnsConfig,
}: Props) => {
  const map = useMap()

  const [colorAndSizeBy, setColorAndSizeBy] = useState<{
    size: SeriesNameAndType | undefined
    color: SeriesNameAndType | undefined
  }>({
    color: card?.mapSettings?.color,
    size: card?.mapSettings?.size,
  })

  // this state only gets updated once the search button is selected
  // the purpose of this is to make sure the color and size of markers do not change
  // unless the search is selected
  const [colorAndSizeBySelected, setColorAndSizeBySelected] = useState<{
    size: SeriesNameAndType | undefined
    color: SeriesNameAndType | undefined
  }>({
    color: card?.mapSettings?.color,
    size: card?.mapSettings?.size,
  })

  const { meters } = processData(
    card,
    getData,
    dynamicColumnsConfig.meterIdColumn,
  )

  const dropZones = [
    { id: 'meterMapColor', label: 'color', axisId: AxisId.Primary },
    { id: 'meterMapSize', label: 'size', axisId: AxisId.Secondary },
  ]
  const classes = useStyles()

  const colorByColumnName =
    colorAndSizeBySelected.color?.type === SeriesToColorAndSizeByTypes.Load
      ? dynamicColumnsConfig.valueColumn
      : colorAndSizeBySelected.color?.name
  const sizeByColumnName =
    colorAndSizeBySelected.size?.type === SeriesToColorAndSizeByTypes.Load
      ? dynamicColumnsConfig.valueColumn
      : colorAndSizeBySelected.size?.name
  const { getMarkerColor, getMarkerSize, legendMap } = getColorAndSizeFunctions(
    colorByColumnName,
    colorAndSizeBySelected.color?.type,
    sizeByColumnName,
    meters,
  )

  return (
    <>
      <section className={classes.dropDownContainer}>
        <section className={classes.rigid}>
          {dropZones.map(({ label }) => (
            <section className={classes.chipInput}>
              <ChipsInput
                key={label}
                label={label}
                canDrop={(ev) =>
                  getDraggedTableGroupsTypes(ev.dataTransfer).length > 0 ||
                  Boolean(getDataSeriesTypes(ev.dataTransfer))
                }
                onDrop={(ev) => {
                  let droppedData
                  if (getDataSeriesTypes(ev.dataTransfer).length > 0) {
                    const [droppedDataSeries] = getDataSeriesData(
                      ev.dataTransfer,
                    )
                    droppedData = droppedDataSeries
                    setColorAndSizeBy((ps) => ({
                      ...ps,
                      [label]: {
                        name: droppedData.id,
                        type: SeriesToColorAndSizeByTypes.Load,
                      },
                    }))
                  } else {
                    droppedData = getDroppedTableGroups(ev.dataTransfer)
                    setColorAndSizeBy((ps) => ({
                      ...ps,
                      [label]: {
                        name: droppedData[0].groupType,
                        type: SeriesToColorAndSizeByTypes.Group,
                      },
                    }))
                  }
                }}
              >
                {colorAndSizeBy[label] && (
                  <Chip
                    label={colorAndSizeBy[label].name}
                    onDelete={() => {
                      setColorAndSizeBy((ps) => ({ ...ps, [label]: undefined }))
                    }}
                    onClick={() => ''}
                  />
                )}
              </ChipsInput>
            </section>
          ))}
          <MapButton
            color="primary"
            variant="contained"
            onClick={() => {
              const [groupColumnsForMap, scenarios] = Object.entries(
                colorAndSizeBy,
              ).reduce(
                (acc: [string[], string[]], [, val]) => {
                  // this if statement ensures there is no duplicate values being added in groupColumnsForMap and scenarios
                  if (
                    acc[0].some((v) => v === val?.name) &&
                    acc[1].some((v) => v === val?.name)
                  ) {
                    return acc
                  }
                  if (
                    val?.name &&
                    val?.type === SeriesToColorAndSizeByTypes.Group
                  ) {
                    acc[0].push(val?.name)
                  }
                  if (
                    val?.name &&
                    val?.type === SeriesToColorAndSizeByTypes.Load
                  ) {
                    acc[1].push(val?.name)
                  }
                  return acc
                },
                [[], []],
              )
              fetchMeters(
                {
                  northEast: map.getBounds().getNorthEast(),
                  southWest: map.getBounds().getSouthWest(),
                },
                groupColumnsForMap,
                scenarios,
                {
                  mapCenterCoordinates: map.getBounds().getCenter(),
                  ...colorAndSizeBy,
                  zoom: map.getZoom(),
                },
              )
              setColorAndSizeBySelected(colorAndSizeBy)
            }}
          >
            Search
          </MapButton>
        </section>
      </section>

      <LayersControl position="bottomleft">
        <LayersControl.BaseLayer checked name="Street Map">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      {meters?.length > 0 &&
        renderMarkers(
          meters,
          dynamicColumnsConfig,
          getMarkerSize,
          getMarkerColor,
          colorAndSizeBySelected.size,
          colorAndSizeBySelected.color,
        )}
      {meters?.length > 0 && colorAndSizeBySelected.color ? (
        <MapLegend
          map={map}
          type={colorAndSizeBySelected.color.type}
          legendMap={legendMap}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default ChildMap
