import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  currentStatus: {
    ...theme.typography.caption,
    float: 'right',
    color: theme.palette.text.primary,
  },
  completedTask: {
    height: '5px',
    'border-radius': '10px',
    border: 'none',
    'margin-bottom': theme.spacing(1),
  },
  success: {
    'background-color': theme.palette.success.main,
  },
  warning: {
    'background-color': theme.palette.warning.main,
  },
  error: {
    'background-color': theme.palette.error.main,
  },
  statusMessage: {
    ...theme.typography.caption,
    'text-transform': 'capitalize',
    'margin-left': theme.spacing(0.2),
    'font-weight': theme.typography.fontWeightMedium,
  },
}))

export interface CompletedProgressBarProps {
  /**
   * Status defines how the component will look, which color will show and what is denoted. There can be three default status for the component
   * to accept , these are:
   *
   *  ```success | warning | error```
   *
   * Example: if a job passes without warning or error: `status = success`. However, if the job presents errors you can set `status = error | warninig` depending on the serverity
   */
  status: 'success' | 'error' | 'warning'

  /**
   *
   * Small message for a status that appears at the end
   */
  message: string

  /**
   * Time of completion to be displayed
   */
  completedAt: string
}

/**
 *
 * This component displays a completed status bar (`success | error | warning`), alongside a short message and time of completion.

 * Colors associated with completion
 *
 *```javascript
 {
   success: theme.palette.success.main,
   warning: theme.palette.warnng.main,
   error: theme.palette.error.main,
 }
 ```
 */
const CompletedProgressBar = ({
  status,
  message,
  completedAt,
}: CompletedProgressBarProps) => {
  const componentStyles = useStyles()

  return (
    <>
      <div
        className={`${componentStyles.completedTask} ${componentStyles[status]}`}
      />
      <span className={componentStyles.currentStatus}>
        Completed {completedAt} status:
        <span className={componentStyles.statusMessage}>{message}</span>
      </span>
    </>
  )
}

export default CompletedProgressBar
