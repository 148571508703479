import React, { DragEvent } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import DraggableMenu from 'shared/components/DraggableMenu'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import SideBarMenu from 'shared/components/SideBarMenu'
import { getInnerMenu, TimeSeriesItem } from 'modules/demand/common/helpers'
import { WeatherStation } from 'shared/types'

interface Props {
  /** String tells which menu is currently selected */
  selectedMenu: 'load' | 'weather' | 'meterCount'
  /** Event handler for menu option change */
  onMenuChange: (event) => void
  /** Array of forecast process times `Array<number>` */
  timeSeries: Array<TimeSeriesItem>
  /** Callback with parameters `(DragEvent, metadata)` where `metadata = { id, subtitle, title }` */
  onDrag: (e: DragEvent, timeSeries: TimeSeriesItem) => void
  /** Value that indicates if timeseries are being loaded at the current moment */
  isLoading: boolean
  /** Array of weather stations that the component can accepts and show in dropdown */
  stations?: Array<WeatherStation>
  /** Selected weather station which is usually an element from stations array */
  selectedStation?: WeatherStation
  /** On weather station change event trigger */
  onStationChange: (event: any, station: WeatherStation) => void
  hasMeterCount: Boolean
}

const WeatherAndLoadMenu = ({
  selectedMenu,
  onMenuChange,
  timeSeries,
  onDrag,
  isLoading,
  stations = [],
  selectedStation,
  onStationChange,
  hasMeterCount,
}: Props) => {
  const innerMenu = getInnerMenu(timeSeries)

  return (
    <SideBarMenu title="time series" isLoading={isLoading}>
      {(hasMeterCount || stations) && (
        <FormControl component="fieldset">
          <RadioGroup
            defaultValue="load"
            value={selectedMenu}
            onChange={onMenuChange}
          >
            <FormControlLabel
              value="load"
              control={<Radio color="primary" />}
              label="Load"
            />
            {stations && (
              <FormControlLabel
                value="weather"
                control={<Radio color="primary" />}
                label="Weather"
              />
            )}
            {hasMeterCount && (
              <FormControlLabel
                value="meterCount"
                control={<Radio color="primary" />}
                label="Meter Count"
              />
            )}
          </RadioGroup>
        </FormControl>
      )}
      {stations && selectedMenu === 'weather' ? (
        <Autocomplete
          data-testid="weather-stations-selector"
          value={selectedStation}
          onChange={(event, newStation) => {
            onStationChange(event, newStation)
          }}
          options={stations}
          getOptionLabel={(option) => option.label}
          fullWidth
          disableClearable
          noOptionsText="No stations"
          aria-label="weather stations"
          renderInput={(params) => {
            return (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                placeholder="Station"
                margin="dense"
                label="Stations"
              />
            )
          }}
        />
      ) : null}
      <DraggableMenu
        title="Forecast"
        onDrag={(e, metadata) => {
          const selectedTimeSeries = timeSeries.find(
            (x) => x.id === metadata.id,
          )
          if (selectedTimeSeries) {
            onDrag(e, selectedTimeSeries)
          }
        }}
        innerMenus={innerMenu}
        defaultMenuIsOpen
      />
    </SideBarMenu>
  )
}

export default WeatherAndLoadMenu
