import React, { MouseEventHandler, ReactElement } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  styled,
} from '@material-ui/core'

interface Props {
  isOpen: boolean
  isLoading?: boolean
  title: string
  message: string | ReactElement
  actionButtonText?: string
  onClose?: MouseEventHandler
  onAlertAction?: MouseEventHandler
}

const StyledTextSpan = styled('span')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}))

const Alert = ({
  isOpen,
  onClose,
  onAlertAction,
  title,
  message,
  actionButtonText = 'Confirm',
  isLoading = false,
}: Props) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button color="primary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
        )}
        {onAlertAction && (
          <Button color="primary" onClick={onAlertAction} disabled={isLoading}>
            {isLoading ? (
              <>
                <CircularProgress size="1em" />
                <StyledTextSpan>{actionButtonText}</StyledTextSpan>
              </>
            ) : (
              actionButtonText
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default Alert
