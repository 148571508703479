import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as SmartEngagementIcon } from 'icons/smart-engagement-module.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'

const smartEngagementMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: (<div> Smart Engagement Content In Development </div>) as any,
  },
]

export const SmartEngagementMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Smart Engagement"
      color="demand"
      basePath={url}
      icon={<SmartEngagementIcon />}
      routes={smartEngagementMenuOptions}
    />
  )
}

const SmartEngagement = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${smartEngagementMenuOptions[0].path}`} />
      </Route>
      {smartEngagementMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default SmartEngagement
