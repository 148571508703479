import { useEffect, useState } from 'react'
import {
  getDateTypeValues,
  getGroupOptionsForTimeInterval,
} from 'shared/common/dashboardOptions'
import {
  Filter,
  SavedFilter,
  SelectableValue,
  SelectedFilter,
} from 'shared/types'

const APPLY_FILTERS_CHANGES_DELAY = 1000

const useTimeFilters = (timeInterval, savedTimeFilters?: SavedFilter[]) => {
  const [filters, setFilters] = useState<Filter[]>([])
  const [hasChanged, setHasChanged] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([])

  const [debouncer] = useState<any>({ timeout: null })

  useEffect(() => {
    const isHourEndingSelected = false
    const dateTypeValues = getDateTypeValues(isHourEndingSelected)
    // Map -> { month-12: [1,4,8] used to check IsChecked Values on init
    const selectedFiltersMap = new Map(
      savedTimeFilters?.map((x) => [
        x.name,
        new Set(x.items.map((item) => item.id)),
      ]),
    )
    const dateFilterSet = new Set()
    const initialFilters = getGroupOptionsForTimeInterval(
      timeInterval,
    ).map<Filter>(([group, label]) => {
      const groupItems: Array<[string, SelectableValue]> =
        dateTypeValues[group] ?? []
      dateFilterSet.add(group)
      return {
        name: group,
        title: label,
        items:
          groupItems.map(([name, value]) => ({
            id: value,
            label: name,
            isChecked: selectedFiltersMap.get(group)?.has(value) ?? false,
            isDisabled: false,
          })) ?? [],
      }
    })
    // Set the hooks internal filters array which is not exposed
    setFilters(initialFilters)
    /* From the saved filters, initialise the selectedFilters
      so the saved values are used in the first query as it loads */
    const newSelectedFilters: { name: string; values: any[] }[] = []
    savedTimeFilters?.forEach((filter) => {
      const selected = filter.items
        .filter((item) => item.isChecked)
        .map((item) => item.id)
      if (selected.length > 0) {
        newSelectedFilters.push({ name: filter.name, values: selected })
      }
    })
    setSelectedFilters(newSelectedFilters)
  }, [timeInterval, savedTimeFilters])

  function handleChange(groupName, changes) {
    setFilters((prevFilter) => {
      return prevFilter.map((filter) => {
        if (filter.name !== groupName) {
          return filter
        }
        const itemsShallowCopy = [...filter.items]
        changes.forEach((elem) => {
          itemsShallowCopy[elem.index] = {
            ...itemsShallowCopy[elem.index],
            isChecked: elem.isChecked,
          }
        })
        return { ...filter, items: itemsShallowCopy }
      })
    })
    setHasChanged(true)
  }

  useEffect(() => {
    if (hasChanged) {
      if (debouncer.timeout) {
        clearTimeout(debouncer.timeout)
      }
      debouncer.timeout = setTimeout(() => {
        debouncer.timeout = null
        const newSelectedFilters: { name: string; values: any[] }[] = []
        filters.forEach((filter) => {
          const selected = filter.items
            .filter((item) => item.isChecked)
            .map((item) => item.id)
          if (selected.length > 0) {
            newSelectedFilters.push({ name: filter.name, values: selected })
          }
        })
        setSelectedFilters(newSelectedFilters)
        setHasChanged(false)
      }, APPLY_FILTERS_CHANGES_DELAY)
    }
    return () => {
      if (debouncer.timeout) {
        clearTimeout(debouncer.timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return {
    filters,
    selectedFilters,
    onChange: handleChange,
  }
}

export default useTimeFilters
