import React, { ReactElement } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Color } from 'shared/utils/themeHelpers'
import { MinusCircle, PlusCircle } from 'react-feather'
import { makeStyles } from '@material-ui/core/styles'
import SummaryCardInfoBox, {
  SummaryCardInfoBoxContent,
} from './SummaryCardInfoBox'
import SummaryCardInteractions from './SummaryCardInteractions'
import SummaryCardActions, { SummaryCardAction } from './SummaryCardActions'
import StyledChip from '../StyledChip'

const StyledCardHeader = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    paddingTop: '0 !important',
    marginBottom: theme.spacing(1),
    lineHeight: '24px',
    '& > *': {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
}))(CardHeader) as typeof CardHeader

const StyledCardContent = withStyles(() => ({
  root: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
}))(CardContent) as typeof CardContent

const InfoBoxes = withStyles((theme) => ({
  root: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    paddingY: theme.spacing(1),
  },
}))(CardContent) as typeof CardContent

const ContentWrapper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    '& > *': {
      padding: 0,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '& > *:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
}))(Box) as typeof Box

const SummaryFooter = withStyles((theme) => ({
  root: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey[200],
    padding: 0,
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}))(Box) as typeof Box

const StyledSelectButton = withStyles((theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(100%)',
    transition: 'transform 300ms ease-out',
    fontWeight: 500,
    zIndex: 10,
    bottom: 0,
    left: 0,
    width: '100%',
    height: theme.spacing(9),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    opacity: 0.85,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
}))(Box) as typeof Box

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: 275,
    minHeight: 355,
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 300ms ease-out',
    cursor: ({ onSelect, selected }: Pick<Props, 'onSelect' | 'selected'>) =>
      onSelect || selected ? 'pointer' : 'default',
    boxShadow: ({ selected }: Pick<Props, 'onSelect' | 'selected'>) =>
      selected ? '0px 0px 10px rgba(0, 114, 206, 0.5)' : 'none',
    '& > .styledSelect': {
      transform: ({ selected }: Pick<Props, 'onSelect' | 'selected'>) =>
        selected ? 'translateY(0%)' : 'translateY(100%)',
    },
    '&:hover': {
      boxShadow: ({ onSelect }: Pick<Props, 'onSelect' | 'selected'>) =>
        onSelect ? '0px 0px 10px rgba(0, 114, 206, 0.5)' : 'none',
      '& > .styledSelect': {
        transform: 'translateY(0%)',
      },
    },
  },
}))

const SummaryCaption = withStyles((theme) => ({
  root: {
    fontSize: '12px',
    color: theme.palette.grey[500],
  },
}))(Typography) as typeof Typography

interface Props {
  title: string
  actions: SummaryCardAction[]
  subtitle?: ReactElement
  badges?: { value: string; color?: Color }[]
  visualisation?: ReactElement
  infoBoxes?: SummaryCardInfoBoxContent[]
  interactions?: string[]
  useActions?: boolean
  selected?: boolean
  caption?: string
  onSelect?: (object) => void
}

const SummaryCard = ({
  title,
  actions,
  subtitle,
  badges,
  visualisation,
  infoBoxes,
  interactions,
  useActions,
  caption,
  onSelect,
  selected,
}: Props) => {
  const classes = useStyles({
    onSelect,
    selected,
  })
  return (
    <Card
      role="listitem"
      className={classes.root}
      onClick={onSelect}
      data-testid="summary-card"
    >
      <ContentWrapper>
        <StyledCardHeader
          title={title}
          disableTypography
          action={useActions && <SummaryCardActions actions={actions} />}
        />
        {badges ? (
          <StyledCardContent data-testid="summary-card-badges">
            {badges.map((badge) => (
              <StyledChip
                data-testid="summary-card-badge"
                key={badge.value}
                label={badge.value}
                color={badge.color}
              />
            ))}
          </StyledCardContent>
        ) : (
          ''
        )}
        {subtitle ? (
          <StyledCardContent data-testid="summary-card-subtitle">
            {subtitle}
          </StyledCardContent>
        ) : (
          ''
        )}
        {visualisation ? (
          <StyledCardContent data-testid="summary-card-visualisation">
            {visualisation}
          </StyledCardContent>
        ) : (
          ''
        )}
        {infoBoxes ? (
          <InfoBoxes data-testid="summary-card-info-boxes">
            <Grid container spacing={1}>
              {infoBoxes.map((info) => {
                return (
                  <Grid
                    item
                    xs={6}
                    key={info.header}
                    data-testid="summary-card-info-box"
                  >
                    <SummaryCardInfoBox
                      header={info.header}
                      value={info.value}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </InfoBoxes>
        ) : (
          ''
        )}
        {interactions || caption ? (
          <SummaryFooter data-testid="summary-card-footer" display="flex">
            {caption ? <SummaryCaption>{caption}</SummaryCaption> : ''}
            <Box display="flex" flexGrow={1} />
            {interactions ? (
              <SummaryCardInteractions users={interactions} />
            ) : (
              ''
            )}
          </SummaryFooter>
        ) : (
          ''
        )}
      </ContentWrapper>
      {selected || onSelect ? (
        <StyledSelectButton
          className="styledSelect"
          onClick={onSelect}
          data-testid="summary-card-select"
        >
          {selected ? <MinusCircle /> : <PlusCircle />}

          <span>{selected ? 'Selected' : 'Select'}</span>
        </StyledSelectButton>
      ) : (
        ''
      )}
    </Card>
  )
}

export default SummaryCard
