import { useEffect, useMemo, useState } from 'react'
import { nonTimeSeriesGroupOptions } from 'shared/common/dashboardOptions'
import { DEFAULT_PAGE_SIZE } from 'shared/components/DashboardCard/MeterTable'
import { createNewCard } from 'shared/helpers'
import {
  Calculation,
  CardDefinition,
  DashboardConfigItem,
  DataSeries,
  DataSeriesType,
  DraggableResizableCard,
  Group,
  GroupOptions,
  SingleGroupOption,
  Visualization,
} from 'shared/types'
import { LayoutByKey } from '../components/DraggableResizableGrid'

interface GroupSettings {
  groupOptions: GroupOptions
  defaultGroup: Group
}

interface CreateVisualizationHelpersOutput {
  createNewCardWithDefaultGroup: () => DraggableResizableCard
  getCardGroupOptions: (card: DraggableResizableCard) => GroupOptions
  updateSelectedGroup: (
    updatedCard: DraggableResizableCard,
  ) => DraggableResizableCard
}

export function buildDataSeriesOptionsForMeterTable(
  card: CardDefinition,
): DataSeries[] {
  if (card.visualization === Visualization.MeterTable) {
    return [
      {
        id: `Unique-${card.group}`,
        label: 'Meter Id',
        type: DataSeriesType.Dimensionless,
        extras: {
          visualizationType: Visualization.MeterTable,
        },
      },
      {
        id: `Unique-Count-${card.group}`,
        label: `count-meter-id`,
        type: DataSeriesType.Dimensionless,
        selectedCalculation: [Calculation.CountDistinct, 'countd'],
        extras: {
          visualizationType: Visualization.MeterTable,
        },
      },
      ...card.dataSeries.filter(
        (series) =>
          ![`Unique-${card.group}`, `Unique-Count-${card.group}`].includes(
            series.id,
          ),
      ),
    ]
  }
  return []
}

/**
 * Function to facilitate the use of different grouping
 * options and also get an appropriate default value for
 * the selected grouping depending on the selected visualization
 * for a card.
 *
 * This high order function has been created with the intent
 * of easing the burden of handling the differences in
 * behavior that arise with the "Stack" visualization on
 * cards. May be replaced by another approach.
 *
 * TODO: Remove by a better approach or move to a shared
 * module if it seems worthy.
 * @param nonStackSettings Group options and default group
 * value for "non stack" visualizations.
 * @param stackSettings Group options and default group
 * value for "stack" visuaflization.
 */
export function createVisualizationRelatedGroupHelpers(
  nonStackSettings: GroupSettings,
  stackSettings: GroupSettings,
  meterGroup?: Group | string | null,
): CreateVisualizationHelpersOutput {
  const getGroupSettings = (visualization) => {
    return visualization !== Visualization.Stack
      ? nonStackSettings
      : stackSettings
  }

  return {
    createNewCardWithDefaultGroup: () => {
      const card = createNewCard()
      card.group = getGroupSettings(card.visualization).defaultGroup

      if (meterGroup) {
        card.meterTableOptions = {
          pageNumber: 0,
          pageSize: DEFAULT_PAGE_SIZE,
        }
      }
      return card
    },
    getCardGroupOptions: (card) => {
      return getGroupSettings(card.visualization).groupOptions
    },
    updateSelectedGroup: (card) => {
      const { groupOptions, defaultGroup } = getGroupSettings(
        card.visualization,
      )
      const currentGroup = groupOptions.find((elem) => {
        return elem[0] === card.group
      })
      const updatedGroup = currentGroup ? currentGroup[0] : defaultGroup
      const group = card.group ? (updatedGroup as Group) : null

      let selectedVisualization: any = null

      switch (card.visualization) {
        case Visualization.Summary:
          selectedVisualization = null
          break
        case Visualization.MeterTable:
          selectedVisualization = meterGroup
          break
        case Visualization.MeterMap:
          selectedVisualization = meterGroup
          break
        default:
          selectedVisualization = group
          break
      }
      return {
        ...card,
        group: selectedVisualization,
      }
    },
  }
}

// Very simple group by builder...
// TODO: This will probably need to be a bit more complex to allow for more settings and options... for now this suffices
const useGroupByOptions = (
  filtersDefinitions: DashboardConfigItem['filters'],
  groupOptions: GroupOptions,
  defaultSelectedGroup: Group,
  meterGrouping?: Group | string,
) => {
  const {
    createNewCardWithDefaultGroup,
    getCardGroupOptions,
    updateSelectedGroup,
  } = useMemo(() => {
    const filterOptions = filtersDefinitions.map((filter) => [
      filter.name as Group,
      filter.title,
    ]) as SingleGroupOption[]
    const stackedOptions = [
      // Pick only stacking options from normal time...
      ...groupOptions.filter(([group]) => {
        return nonTimeSeriesGroupOptions.some(
          ([groupType]) => groupType === group,
        )
      }),
      ...filterOptions,
    ]
    const [defaultStackedOption] = stackedOptions[0]

    const defaultNonStackedGroup = groupOptions.find(
      ([group]) => group === defaultSelectedGroup,
    )
      ? defaultSelectedGroup
      : groupOptions[0][0]

    const nonStackedSettings = {
      groupOptions: [...groupOptions, ...filterOptions],
      defaultGroup: defaultNonStackedGroup as Group,
    }

    return createVisualizationRelatedGroupHelpers(
      nonStackedSettings,
      {
        groupOptions: stackedOptions,
        defaultGroup: defaultStackedOption as Group,
      },
      meterGrouping,
    )
  }, [filtersDefinitions, groupOptions, defaultSelectedGroup, meterGrouping])

  return {
    createNewCardWithDefaultGroup,
    getCardGroupOptions,
    updateSelectedGroup,
  }
}

interface Output {
  cards: DraggableResizableCard[]
  addCard(): void
  getCardGroupOptions: (card: DraggableResizableCard) => GroupOptions
  updateCard(cardId: string, updatedCard: DraggableResizableCard): void
  updateCards(updatedCardsInfo: Array<[string, DraggableResizableCard]>): void
  addSeriesToCard(cardId: string, dropSeries: DataSeries): void
  deleteSeriesFromCard(cardId: string, seriesId: string): void
  deleteCard(cardId: string): void
  changeCardLayout(layout: LayoutByKey): void
  duplicateCard(cardId: string): Boolean
}

/**
 * Simple hook that provides shortcuts to modify all aspects of a collection of dashboard cards.
 */
const useDashboardCards = (
  defaultCards: Array<DraggableResizableCard>,
  filtersDefinitions: DashboardConfigItem['filters'],
  groupOptions: GroupOptions,
  defaultSelectedGroup: Group,
  meterGrouping?: Group | string,
): Output => {
  const {
    getCardGroupOptions,
    createNewCardWithDefaultGroup,
    updateSelectedGroup,
  } = useGroupByOptions(
    filtersDefinitions,
    groupOptions,
    defaultSelectedGroup,
    meterGrouping,
  )

  const [cards, setCards] = useState<Array<DraggableResizableCard>>(() =>
    defaultCards.filter((card) => card.visualization !== Visualization.MeterMap)
      .length
      ? defaultCards.map((card) => {
          const dupCard = { ...card }
          if (meterGrouping && card.visualization !== Visualization.MeterMap) {
            dupCard.meterTableOptions = card.meterTableOptions ?? {
              pageNumber: 0,
              pageSize: DEFAULT_PAGE_SIZE,
            }

            if (card.visualization === Visualization.MeterTable) {
              dupCard.dataSeries = [
                ...buildDataSeriesOptionsForMeterTable(card),
              ]
            }
          }
          return dupCard
        })
      : [...defaultCards, createNewCardWithDefaultGroup()],
  )

  // Re-initialize cards IF default cards change (basically allowing for individual dashboards to decide how to initialize these)
  useEffect(() => {
    if (defaultCards.length === 0) {
      setCards([createNewCardWithDefaultGroup()])
    } else {
      setCards(defaultCards)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCards])

  const addCard = () => {
    setCards([...cards, createNewCardWithDefaultGroup()])
  }

  const modifyCardsById = (
    cardsModifications: Array<
      [string, (card: DraggableResizableCard) => DraggableResizableCard]
    >,
  ) => {
    const cardsModificationsMap = new Map<
      string,
      (card: DraggableResizableCard) => DraggableResizableCard
    >(cardsModifications)
    setCards((prevCards) =>
      prevCards.map(
        (card) => cardsModificationsMap.get(card.id)?.(card) ?? card,
      ),
    )
  }

  const updateCard = (
    cardId: string,
    updatedCard: DraggableResizableCard,
  ): void => {
    modifyCardsById([[cardId, () => updateSelectedGroup(updatedCard)]])
  }

  const addSeriesToCard = (cardId, draggedSeries) => {
    modifyCardsById([
      [
        cardId,
        (card) => {
          return {
            ...card,
            dataSeries: [...card.dataSeries, draggedSeries],
          }
        },
      ],
    ])
  }

  const deleteSeriesFromCard = (cardId, seriesId) => {
    modifyCardsById([
      [
        cardId,
        (card) => {
          return {
            ...card,
            dataSeries: [...card.dataSeries.filter((d) => d.id !== seriesId)],
          }
        },
      ],
    ])
  }

  const updateCards = (
    updatedCardsInfo: Array<[string, DraggableResizableCard]>,
  ) =>
    modifyCardsById(
      updatedCardsInfo.map(([id, updatedCard]) => [
        id,
        () => updateSelectedGroup(updatedCard),
      ]),
    )

  const deleteCard = (cardId) => {
    setCards((prevCards) => prevCards.filter((card) => card.id !== cardId))
  }

  const changeCardLayout = (layouts) => {
    setCards((prevCards) =>
      prevCards.map((card) => {
        return {
          ...card,
          layout: layouts[card.id],
        }
      }),
    )
  }

  const duplicateCard = (cardId) => {
    const currentCard = cards.find((card) => {
      return card.id === cardId
    })
    if (currentCard) {
      const newCard = createNewCardWithDefaultGroup()
      /**
       * We create a naive deep-copy. This works because we are storing exclusively serializable properties in the card. If this ever changes this is not safe.
       *
       * TODO: Improve object copy
       */
      const currentCardCopy = JSON.parse(JSON.stringify(currentCard))
      const duplicatedCard = {
        ...currentCardCopy,
        id: newCard.id,
        layout: newCard.layout,
      }
      setCards([...cards, duplicatedCard])
      return true
    }
    return false
  }

  return {
    cards,
    getCardGroupOptions,
    addSeriesToCard,
    deleteCard,
    deleteSeriesFromCard,
    updateCard,
    updateCards,
    addCard,
    changeCardLayout,
    duplicateCard,
  }
}

export default useDashboardCards
