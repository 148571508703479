import React, { ReactElement, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { DateTime } from 'luxon'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  RoundedSkeleton,
  StyledSummaryCard,
} from 'shared/components/SummaryPage/SummaryList/SkeletonSummaryElements'
import { Adjustment, AdjustmentType, ForecastType } from './LoadForecastTypes'
import AdjustmentCard from './AdjustmentCard'
import useAvailableAdjustments from '../hooks/useAvailableAdjustments'
import { AvailableAdjustmentItem } from '../hooks/useAvailableLoadForecasts'
import { SummaryCardAction } from '../../../../../shared/components/SummaryCard/SummaryCardActions'
import SummaryPage from '../../../../../shared/components/SummaryPage/SummaryPage'

const AdjustmentCardSkeleton = () => {
  return (
    <StyledSummaryCard>
      <Box display="flex" width="100%" alignItems="center">
        <Skeleton variant="text" width="80%" height={70} />
        <Box margin={1} />
        <Skeleton variant="circle" width={5} height={5} />
        <Skeleton variant="circle" width={5} height={5} />
        <Skeleton variant="circle" width={5} height={5} />
      </Box>
      <RoundedSkeleton variant="rect" width={100} height={20} />
      <RoundedSkeleton variant="rect" width="100%" height={145} />
      <RoundedSkeleton variant="rect" width="100%" height={60} />
      <RoundedSkeleton variant="rect" width="100%" height={90} />
      <RoundedSkeleton variant="rect" width="100%" height={1} />
      <RoundedSkeleton variant="rect" width="100%" height={30} />
    </StyledSummaryCard>
  )
}

interface Props {
  title: string
  useActions?: boolean
  pageActions?: ReactElement
  tabCategories: {
    title: string
    category: AdjustmentType
  }[]
  onSelect?: (adjustment: Adjustment) => void
}

const AdjustmentsList = ({
  title,
  useActions,
  pageActions,
  tabCategories,
  onSelect,
}: Props) => {
  const [selected, setSelected] = useState<string>('')
  const {
    initialize: initDraft,
    loadMore: loadMoreDraft,
  } = useAvailableAdjustments(ForecastType.ShortTermForecast)

  const history = useHistory()
  const { url } = useRouteMatch()

  const {
    initialize: initApproved,
    loadMore: loadMoreApproved,
  } = useAvailableAdjustments(ForecastType.ShortTermForecast)

  const renderCard = (adjustment) => {
    const actions: SummaryCardAction[] = [
      {
        title: 'Edit adjustment',
        action: () => {
          history.push(`${url}/${adjustment.adjustmentId}`)
        },
      },
    ]
    const key = JSON.stringify(adjustment)
    return (
      <AdjustmentCard
        actions={actions}
        onSelect={
          onSelect &&
          (() => {
            setSelected(key)
            onSelect(adjustment)
          })
        }
        selected={selected === key}
        useActions={useActions}
        key={key}
        adjustment={adjustment}
        data-testid="adjustment-card"
      />
    )
  }

  const renderCardSkeleton = (index) => {
    return (
      <AdjustmentCardSkeleton
        key={index}
        data-testid="adjustment-card-skeleton"
      />
    )
  }

  /**
   * Take in the raw item data and processes out the values we need
   * @param itemData: LoadMoreReturnValue - raw item data
   */
  const process = (itemData: AvailableAdjustmentItem[]) => {
    // Convert the timestamp to an integer and feed the results into cross filter and group by
    return itemData.map((item: AvailableAdjustmentItem) => {
      const forecast = item.summary.map((i) => i.forecast)
      const adjustedForecast = item.summary.map((i) => i.adjustedForecast)
      return {
        ...item,
        timestamp: new Date(item.createdAt),
        processTime: DateTime.fromISO(item.processTime),
        forecast,
        adjustedForecast,
        from: DateTime.fromISO(item.dateRange.from),
        to: DateTime.fromISO(item.dateRange.to),
        dayCount: Math.round(
          DateTime.fromISO(item.dateRange.to).diff(
            DateTime.fromISO(item.dateRange.from),
            ['days'],
          ).days,
        ),
        count: forecast.length,
        min: Math.round(forecast[0] * 100 + 4) / 100,
        max: Math.round(forecast[forecast.length - 1] * 100 + 4) / 100,
      }
    })
  }

  return (
    <SummaryPage
      dataFunctions={{
        [AdjustmentType.Draft]: {
          initialize: initDraft,
          loadMore: loadMoreDraft,
          process,
        },
        [AdjustmentType.Approved]: {
          initialize: initApproved,
          loadMore: loadMoreApproved,
          process,
        },
      }}
      renderCard={renderCard}
      renderCardSkeleton={renderCardSkeleton}
      tabCategories={tabCategories}
      title={title}
      pageActions={pageActions}
    />
  )
}

export default AdjustmentsList
