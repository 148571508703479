import React, { useState, useEffect } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { fade } from '@material-ui/core/styles/colorManipulator'
import {
  IconButton,
  InputAdornment,
  Popover,
  Select,
  TextField,
  MenuItem,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
} from '@material-ui/core'
import { Calendar } from 'react-feather'
import { DateTime } from 'luxon'
import useClickedElement from 'shared/hooks/useClickedElement'
import {
  DateIdentifier,
  DateMultiplier,
  DateSelectorType,
  GeneralDateSelector,
} from 'shared/types'

interface MultiplierOption {
  value: DateMultiplier
  label: string
}

interface IdentifierOption {
  value: DateIdentifier
  label: string
}

const identifierOptions: IdentifierOption[] = [
  { value: 'today', label: 'Today' },
  { value: 'startData', label: 'Start Data' },
  { value: 'endData', label: 'End Data' },
]

const multiplierOptions: MultiplierOption[] = [
  { value: 'days', label: 'Days' },
  { value: 'months', label: 'Months' },
  { value: 'years', label: 'Years' },
]

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
  },
  radioOptions: {
    marginLeft: theme.spacing(2),
  },
  relativeOptions: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: theme.spacing(4),
  },
  select: {
    width: '85px',
  },
  input: {
    width: '85px',
  },
  selectorInput: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 1px 2px 0 ${fade(theme.palette.common.white, 0.08)}`,
    padding: theme.spacing(0.5, 1),
    marginTop: `${theme.spacing(3)}px!important`,
  },
  calendarButton: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  calendarIcon: {
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
    width: '22px',
    height: '22px',
  },
}))

interface Props extends GeneralDateSelector {
  onChange: (inputs: GeneralDateSelector) => void
  /** DateTime to base off relative date start */
  startData: DateTime
  /** DateTime to base off relative date end */
  endData: DateTime
  /** Label to display on input */
  label?: string
}

/**
 * Calculates relative date based on the general date selector.
 * Function is exported for anyone to uses outside this component
 */
export function calculateRelativeDate(
  startData: DateTime,
  endData: DateTime,
  newState: GeneralDateSelector,
) {
  if (newState.dateType === 'fixed') {
    return newState.value
  }
  const map = {
    today: DateTime.local(),
    startData,
    endData,
  }
  if (!map[newState.identifier]) {
    return newState.value
  }
  const newDate = map[newState.identifier].plus({
    [newState.multiplier]: newState.quantity,
  })
  return newDate
}

/** Hook to manage the state of this component. It is exported for anyone to use easily. */
export const useDateSelector = (min, max, init?: any) => {
  const [state, setState] = useState<GeneralDateSelector>({
    quantity: 0,
    multiplier: 'days',
    identifier: 'today',
    dateType: 'fixed',
    value: DateTime.local(),
  })

  function handleChange(obj: Partial<GeneralDateSelector>) {
    setState((prev) => {
      const combined = { ...prev, ...obj }
      const newValue = calculateRelativeDate(min, max, combined)
      return { ...combined, value: newValue }
    })
  }

  useEffect(() => {
    handleChange(init)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max])

  return {
    ...state,
    handleChange,
  }
}

/**
 * Dynamic date selector that allows users to select from either fixed or relative dates. Where a relative date is a date correlating to either given start and end dates, or today. A user can add days, months, and years to a relative date. The calendar view is only interactive if the user has selected the `fixed` date type.
 *
 * This is a controlled component, but it maintains its own state for user interactivity but the final display value is expected to be provided by the user.
 *
 * The component will trigger the onChange callback when the dialog closes only if one of the following changed inside the dialog: `dateType`, `value`, `quantity`, `multiplier`, `identifier`. The callback will be called with the latest internal state as the parameter. The latest internal state includes the latest calculated value.
 */
const DateSelector = ({
  quantity,
  multiplier,
  identifier,
  dateType,
  onChange,
  startData,
  endData,
  value,
  label = 'Date',
}: Props) => {
  const [anchorEl, openMenu, closeMenu] = useClickedElement()
  const state = useDateSelector(startData, endData, {
    quantity,
    multiplier,
    dateType,
    identifier,
    value,
  })

  useEffect(() => {
    if (anchorEl) {
      state.handleChange({ quantity, multiplier, identifier, dateType, value })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl])

  const classes = useStyles()
  const isRelative = state.dateType === 'relative'
  return (
    <div data-testid="date-selector" data-what="what">
      <TextField
        label={label}
        value={value.toLocaleString(DateTime.DATE_SHORT)}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          className: classes.selectorInput,
          startAdornment: (
            <InputAdornment position="start" className={classes.calendarButton}>
              <IconButton
                onClick={openMenu}
                title="Change Date"
                className={classes.calendarIcon}
              >
                <Calendar />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => closeMenu()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.root}>
          <div>
            <RadioGroup
              className={classes.radioOptions}
              value={state.dateType}
              onChange={(e) =>
                state.handleChange({
                  dateType: e.target.value as DateSelectorType,
                })
              }
              row
            >
              <FormControlLabel
                label="Fixed"
                value="fixed"
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                label="Relative"
                value="relative"
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </div>
          {isRelative && (
            <div className={classes.relativeOptions}>
              <Select
                className={classes.select}
                value={state.identifier}
                onChange={(e) =>
                  state.handleChange({
                    identifier: e.target.value as DateIdentifier,
                  })
                }
              >
                {identifierOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                className={classes.input}
                type="number"
                value={state.quantity}
                onChange={(e) => {
                  state.handleChange({
                    quantity:
                      e.target.value !== ''
                        ? parseInt(e.target.value, 10)
                        : null,
                  })
                }}
              />
              <Select
                className={classes.select}
                value={state.multiplier}
                onChange={(e) =>
                  state.handleChange({
                    multiplier: e.target.value as DateMultiplier,
                  })
                }
              >
                {multiplierOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={state.value}
            onChange={(newDate) => {
              state.handleChange({ value: newDate as DateTime })
            }}
            disableFuture={isRelative}
            disablePast={isRelative}
          />
        </div>
        <DialogActions>
          <Button
            data-testid="date-selector-cancel"
            onClick={() => closeMenu()}
          >
            cancel
          </Button>
          <Button
            data-testid="date-selector-confirm"
            onClick={() => {
              // If something changed trigger
              if (
                dateType !== state.dateType ||
                value.toISODate() !== state.value.toISODate() ||
                quantity !== state.quantity ||
                multiplier !== state.multiplier ||
                identifier !== state.identifier
              ) {
                onChange({
                  quantity: state.quantity,
                  multiplier: state.multiplier,
                  dateType: state.dateType,
                  identifier: state.identifier,
                  value: state.value,
                })
              }
              closeMenu()
            }}
            color="primary"
          >
            ok
          </Button>
        </DialogActions>
      </Popover>
    </div>
  )
}

export default DateSelector
