import { LatLngExpression } from 'leaflet'
import { MapContainer } from 'react-leaflet'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CardDefinition } from 'shared/types'
import { GetDataHandler } from '../helpers'
import ChildMap from './ChildMap'
import { Bounds, MapSettings } from './common'

const minZoomForQueries = 11

interface DynamicColumnsConfig {
  valueColumn: string | undefined
  meterIdColumn: string
}

interface Props {
  fetchMeters: (
    bounds: Bounds,
    groupColumnsForMap: string[],
    scenarios: string[],
    mapSettings: MapSettings,
  ) => void
  getData: GetDataHandler
  card: CardDefinition
  dynamicColumnsConfig: DynamicColumnsConfig
}

const useStyles = makeStyles(() => ({
  container: {
    height: '90%',
    width: '97%',
    position: 'fixed',
  },
}))

const MeterMap = ({
  fetchMeters,
  getData,
  card,
  dynamicColumnsConfig,
}: Props) => {
  const position: LatLngExpression = [29.76, -95.36]
  const classes = useStyles()

  return (
    <>
      <MapContainer
        center={card?.mapSettings?.mapCenterCoordinates ?? position}
        zoom={card?.mapSettings?.zoom ?? minZoomForQueries}
        className={classes.container}
        scrollWheelZoom
        preferCanvas
        zoomControl={false}
      >
        <ChildMap
          fetchMeters={fetchMeters}
          getData={getData}
          card={card}
          dynamicColumnsConfig={dynamicColumnsConfig}
        />
      </MapContainer>
    </>
  )
}

export default MeterMap
