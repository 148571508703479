import Papa, { Parser, ParseResult } from 'papaparse'
import { FileValidationErrors } from 'shared/common/errorMessages'

export interface DuplicatesRemovedDetails {
  /**
   * Index inside the result data object where the original element has been returned.
   *
   * If it has a value < 0, it means the element has been skipped due to the user
   * returning a "null" value on the corresponding "processRow" funcion.
   * */
  dataIndex: number
  /**
   * Row index on the file where the original element was parsed from. It will
   * always be > 1 (being 0 the header row index)
   */
  fileRowIndex: number
  /**
   * An array with the row indexes of the rows that are duplicates from the
   * referenced original one
   */
  duplicatedFileRowIndexes: Array<number>
}

/**
 * Object returned on the "onImportComplete" callback with
 * information about the parsing process of the CSV file.
 */
export interface CsvImportResult {
  /**
   * Data parsed from the CSV file. If no transformation or
   * filtering has been done, it will contain an object per
   * row (same order) with a field name after each column and its value an
   * string corresponding its value on the column.
   *
   * If a "processRow" function was given, the order will be maintained,
   * but the shape will be that returned by the function for each
   * row, and some rows may have been filtered out.
   */
  data: Array<{ [field: string]: any }>
  /**
   * An array containing all the errors detected while processing
   * the file.
   *
   * Each entry will contain:
   * - The index of the result data it belongs to, or -1 if it
   *  isn't related to returned data, like file validation, header check
   *  or filtered out rows.
   * - The index of the file row it belongs to, being 0 the columns header
   *  row, and a -1 value indicating that it isn't even related to the
   *  file rows, like file validation.
   * - An array of strings with the errors found.
   */
  errors: Array<{
    dataIndex: number
    fileRowIndex: number
    errors: Array<string>
  }>
  /**
   * An object containing useful data about the process.
   */
  meta: {
    /**
     * Value that indicates if the process was aborted
     */
    aborted: boolean
    /**
     * In case duplicates are expected to be removed, this field
     * will contain an array of objects with the duplications found
     */
    duplicatesRemoved?: {
      total: number
      details: Array<DuplicatesRemovedDetails>
    }
    /* Amount of rows on the file that contained data (not counting the header). It will
    have a -1 value in case the process was aborted. */
    totalFileDataRows: number
    /* Amount of rows that have returned a null value on the "processRow" function. It will
    have a -1 value un case the process was aborted */
    processedAndSkippedRows: number
  }
}

export default async function processCsv(
  importData: File | string,
  processCsvOptions: {
    maxFileSize?: number
    validateFile?: (file: File) => boolean | string
    fields: Array<string>
    processRow?: (
      row: {
        [field: string]: string
      },
      addError: (error: string) => void,
    ) => { [field: string]: any } | null
    removeDuplicates?: boolean | Array<string>
  },
): Promise<CsvImportResult> {
  /* TODO: Do not just pass the props, have better defined arguments */
  const {
    maxFileSize = 3 * 2 ** 20 /* Defaults to 3 MB */,
    validateFile,
    fields,
    removeDuplicates = false,
    processRow = (row) => row,
  } = processCsvOptions
  const result: CsvImportResult = {
    data: [],
    errors: [],
    meta: {
      aborted: true,
      totalFileDataRows: 0,
      processedAndSkippedRows: 0,
    },
  }
  if (typeof importData !== 'string' && importData.size > maxFileSize) {
    result.errors.push({
      fileRowIndex: -1,
      dataIndex: -1,
      errors: [FileValidationErrors.LARGE_FILE.message],
    })
    return result
  }

  if (typeof importData !== 'string') {
    const isFileValid = !validateFile || validateFile(importData)
    if (typeof isFileValid === 'string' || !isFileValid) {
      result.errors.push({
        fileRowIndex: -1,
        dataIndex: -1,
        errors: [isFileValid || 'Failed file validation'],
      })
      return result
    }
  }
  const duplicatedRowsInfoMap = new Map<string, DuplicatesRemovedDetails>()

  const buildUniqueKey = (() => {
    let uniqueKeyFields: Array<string>
    if (typeof removeDuplicates === 'boolean')
      uniqueKeyFields = removeDuplicates ? fields : []
    else {
      const fieldsSet = new Set(fields)
      uniqueKeyFields = removeDuplicates.filter((field) => fieldsSet.has(field))
    }
    if (uniqueKeyFields.length)
      return (row) => {
        return JSON.stringify(uniqueKeyFields.map((key) => row[key]))
      }
    return undefined
  })()

  let fileRowIndex = 0 /* fileRowIndex=0  ==> header row */
  let dataInserted = 0
  let duplicatesFound = 0
  const handleStep = (rowInfo: ParseResult<any>, parser: Parser): void => {
    if (fileRowIndex === 0) {
      const rowFieldsSet = new Set(rowInfo.meta.fields)
      if (
        fields.length > rowFieldsSet.size ||
        fields.some((field) => !rowFieldsSet.has(field))
      ) {
        /* If the fields validation fails, there
    won't be more need to continue the parsing
    process */
        result.errors.push({
          dataIndex: -1,
          fileRowIndex: 0 /* fileRowIndex=0  ==> header row */,
          errors: [FileValidationErrors.INCORRECT_HEADER.message],
        })
        parser.abort()
        return
      }
      fileRowIndex += 1
      result.meta.aborted = false
    }
    const errors: Array<string> = []

    const uniqueValue = buildUniqueKey
      ? buildUniqueKey(rowInfo.data)
      : undefined

    const duplicatedRowInfo = uniqueValue
      ? duplicatedRowsInfoMap.get(uniqueValue)
      : undefined

    const processedRow = duplicatedRowInfo
      ? null
      : processRow(rowInfo.data as any, (message) => errors.push(message))

    const dataIndex = processedRow ? dataInserted : -1

    if (uniqueValue && !duplicatedRowInfo)
      duplicatedRowsInfoMap.set(uniqueValue, {
        dataIndex,
        fileRowIndex,
        duplicatedFileRowIndexes: [],
      })
    else if (duplicatedRowInfo) {
      duplicatedRowInfo.duplicatedFileRowIndexes.push(fileRowIndex)
      duplicatesFound += 1
    }

    if (errors.length) {
      result.errors?.push({
        dataIndex,
        fileRowIndex,
        errors,
      })
    }

    if (processedRow) {
      result.data?.push(processedRow as any)
      dataInserted += 1
    }

    fileRowIndex += 1
  }
  await new Promise((resolve) =>
    Papa.parse(importData, {
      header: true,
      step: handleStep,
      complete: resolve,
      error: resolve,
      skipEmptyLines: 'greedy',
    }),
  )

  if (removeDuplicates) {
    const duplicatesRemovedDetails = Array.from(duplicatedRowsInfoMap.values())
      .filter(
        ({ duplicatedFileRowIndexes }) => duplicatedFileRowIndexes.length > 0,
      )
      .sort((a, b) => a.fileRowIndex - b.fileRowIndex)
    result.meta.duplicatesRemoved = {
      total: duplicatesFound,
      details: duplicatesRemovedDetails,
    }
  }
  result.meta.totalFileDataRows = fileRowIndex - 1
  result.meta.processedAndSkippedRows =
    result.meta.totalFileDataRows -
    (result.meta.duplicatesRemoved ? result.meta.duplicatesRemoved.total : 0) -
    dataInserted

  return result
}
