import React from 'react'
import { Chip, makeStyles, ChipProps } from '@material-ui/core'
import { Color, safeColor } from 'shared/utils/themeHelpers'
import clsx from 'clsx'

interface Props extends Omit<ChipProps, 'color'> {
  color?: Color
}

interface StylesProps {
  color?: Color
  variant: 'default' | 'outlined'
}

const useChipStyles = makeStyles((theme) => ({
  root: {
    lineHeight: '16px',
    height: theme.spacing(2),
    padding: theme.spacing(0.25),
    margin: theme.spacing(0, 1, 1, 0),
    borderRadius: '100px',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: theme.typography.caption.fontSize,
    textOverflow: 'ellipsis',
  },
  default: {
    backgroundColor: ({ color }: StylesProps) =>
      color ? safeColor(theme, color) : theme.palette.grey[200],
    color: ({ color }: StylesProps) =>
      color ? theme.palette.common.white : theme.palette.grey[600],
  },
  outline: {
    backgroundColor: 'transparent',
    color: ({ color }: StylesProps) =>
      color ? safeColor(theme, color) : theme.palette.grey[400],
    borderColor: ({ color }: StylesProps) =>
      color ? safeColor(theme, color) : theme.palette.grey[400],
  },
}))

/**
 * Extends Material Chip to allow more colors based on Palette definition... If no color is passed or a not defined color is passed it will default to primary
 * TODO: Needs ability to onHover wth correct color. Works well as a static chip
 * */
const StyledChip = React.forwardRef(
  ({ color, variant = 'default', ...props }: Props, ref) => {
    const classes = useChipStyles({ color, variant })
    const classNames = clsx(
      classes.root,
      variant === 'default' && classes.default,
      variant === 'outlined' && classes.outline,
    )
    return (
      <Chip
        innerRef={ref}
        className={classNames}
        variant={variant}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    )
  },
)

export default StyledChip
