import React, { ChangeEvent, useMemo } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { getTimeZones, TimeZone } from '@vvo/tzdb'
import { DateTime } from 'luxon'
import ClockDropdownTextField from './ClockDropdownTextField'

const formatLabel = (option: TimeZone) => {
  // match +hh:mm or -hh:mm
  const regex = /[+-][0-9]{2}:[0-9]{2}\b/
  const matchArray = option.currentTimeFormat.match(regex)
  const prettyOffset = matchArray ? `(GMT ${matchArray[0]})` : ''
  return `${prettyOffset || ''} ${
    option.alternativeName
  } - ${option.mainCities.join(', ')}`
}

interface Props {
  // Timezone that will be displayed as selected
  selectedTimezone: string | null
  // Callback invoked when a user selects a timezone
  onChange: (e: ChangeEvent, timezoneName: string, timezone: TimeZone) => void
}

/**
 * Controlled timezone selector component that provides a sorted list of timezones grouped by continent. The component populates all available timezones and displays them in the selector grouped by continent and sorted as follows:
 *
 * - Keeps local continent at the top
 * - Sort the rest of the continents alphabetically
 * - Sort timezones within continents by current offset
 */
const TimezoneSelector = ({ selectedTimezone, onChange }: Props) => {
  const localZoneName = DateTime.local().zoneName
  const timezoneOptions = useMemo(() => {
    const timezones = getTimeZones()
    const localContinentCode = timezones.find(
      (elem) => elem.name === localZoneName,
    )?.continentCode
    /**
     * We group our timezones by continent so we sort as follows:
     * - Keep local continent at the top
     * - Sort the rest of the continents alphabetically
     * - Sort timezones within continents by current offset
     */
    return timezones
      .sort((a, b) => {
        if (localContinentCode === a.continentCode) {
          return -1
        }
        if (localContinentCode === b.continentCode) {
          return 1
        }
        return -b.continentCode.localeCompare(a.continentCode)
      })
      .sort((a, b) => {
        if (a.continentCode === b.continentCode) {
          return a.currentTimeOffsetInMinutes - b.currentTimeOffsetInMinutes
        }
        return 0
      })
  }, [localZoneName])

  return (
    <Autocomplete
      options={timezoneOptions}
      groupBy={(option) => option.continentName}
      getOptionLabel={(option) => formatLabel(option)}
      onChange={(e, value) =>
        onChange(e as ChangeEvent, value?.name || '', value)
      }
      value={timezoneOptions.find(
        (timezone) => timezone.name === selectedTimezone,
      )}
      disableClearable
      renderInput={(params) => (
        <ClockDropdownTextField params={params} placeHolder="timezone" />
      )}
    />
  )
}

export default TimezoneSelector
