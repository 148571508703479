import React, { DragEvent } from 'react'
import DraggableMenu from 'shared/components/DraggableMenu'
import SideBarMenu from 'shared/components/SideBarMenu'
import { getInnerMenu, TimeSeriesItem } from 'modules/demand/common/helpers'

interface Props {
  /** Array of forecast process times `Array<number>` */
  timeSeries: Array<TimeSeriesItem>
  /** Callback with parameters `(DragEvent, metadata)` where `timeSeries` the time series
   * dragged */
  onDrag: (e: DragEvent, timeSeries: TimeSeriesItem) => void
  /** Value that indicates if timeseries are being loaded at the current moment */
  isLoading: boolean
}

/**
 * Menu specific to long term forecast. Takes in forecasts process dates and builds appropriate menus. This component uses the `SideBarMenu` and `DraggableMenu` components, and adds some business logic to build a menu specific to this page. Forecasts have an __upper limit of 15__ (1 current + 14 previous) and a date format of `D MMM YYYY`.
 *
 * __NOTE:__ It assumes that process dates are still considered our forecast id.
 *
 * TODO : timeseries menu is open by default | can be changed later
 */
const TimeSeriesMenu = ({ timeSeries, onDrag, isLoading }: Props) => {
  const innerMenu = getInnerMenu(timeSeries)

  return (
    <SideBarMenu title="time series" isLoading={isLoading}>
      <DraggableMenu
        title="Forecast"
        onDrag={(e, metadata) => {
          const currentTimeSeries = timeSeries.find((x) => x.id === metadata.id)
          if (currentTimeSeries) {
            onDrag(e, currentTimeSeries)
          }
        }}
        innerMenus={innerMenu}
        defaultMenuIsOpen
      />
    </SideBarMenu>
  )
}

export default TimeSeriesMenu
