import React, { ChangeEvent, ReactEventHandler, useState } from 'react'
import {
  Button,
  Dialog,
  Theme,
  makeStyles,
  Typography,
  Divider,
  MenuItem,
  styled,
  TextField,
  Box,
  DialogContent,
} from '@material-ui/core'
import { ValidAdjustmentCalculations } from 'shared/types'

const StyledInput = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  display: 'flex',
  width: 'auto',
}))

const StyledSelect = styled(TextField)(() => ({
  margin: 0,
  width: '550px',
}))

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    width: '600px',
    minHeight: '200px',
    padding: theme.spacing(3),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  footer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    '& > :first-child': {
      marginRight: 'auto',
    },
  },
  header: {},
  label: {
    fontSize: '14px',
    fontWeight: 500,
  },
  selectLabel: {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
  margin: {
    marginBottom: theme.spacing(0.5),
  },
  cancel: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
}))

interface ModelData {
  calculation: ValidAdjustmentCalculations
  adjustmentValue: number
}
export interface Props {
  onClose: ReactEventHandler
  selectedRowCount: number
  onConfirm: (model: ModelData) => void
}

const BulkUpdateDialog = ({ onClose, onConfirm, selectedRowCount }: Props) => {
  const classes = useStyles()

  const [model, setModel] = useState<ModelData>({
    calculation: ValidAdjustmentCalculations.Addition,
    adjustmentValue: 0,
  })

  const { calculation, adjustmentValue } = model

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name: fieldName, value } = event.target
    setModel((prevModel) => ({ ...prevModel, [fieldName]: value }))
  }

  const isValid = () => {
    return calculation && adjustmentValue
  }

  const handleConfirm = () => {
    onConfirm(model)
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      classes={{ paperWidthMd: classes.dialogPaper }}
    >
      <header className={classes.header}>
        {/* {selectedRowCount > 0 ? ( */}
        <>
          <Typography variant="h6" className={classes.margin}>
            {selectedRowCount > 0 ? 'Bulk Actions' : 'No Element Selected'}
          </Typography>
          <Typography variant="caption">
            {selectedRowCount > 0
              ? `This action will apply to all selected ${
                  selectedRowCount > 1 ? 'items' : 'item'
                } items`
              : 'This action will apply 0 items'}
          </Typography>
        </>
      </header>
      <Divider light className={classes.margin} />
      <DialogContent>
        {selectedRowCount > 0 ? (
          <div>
            <Typography variant="overline" className={classes.margin}>
              Select the adjustment calculation to apply
            </Typography>
            <Box display="flex">
              <StyledSelect
                size="small"
                select
                margin="dense"
                variant="outlined"
                name="calculation"
                value={calculation}
                onChange={handleChange}
              >
                <MenuItem value={undefined}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={ValidAdjustmentCalculations.Addition}>
                  Add to Forecast
                </MenuItem>
                <MenuItem value={ValidAdjustmentCalculations.Percent}>
                  Percent of Forecast
                </MenuItem>
                <MenuItem value={ValidAdjustmentCalculations.Multiplication}>
                  Multiply Forecast By
                </MenuItem>
                <MenuItem value={ValidAdjustmentCalculations.Division}>
                  Divide Forecast By
                </MenuItem>
                <MenuItem value={ValidAdjustmentCalculations.Custom}>
                  Custom Value
                </MenuItem>
              </StyledSelect>
              <StyledInput
                value={adjustmentValue}
                size="small"
                type="number"
                variant="outlined"
                margin="dense"
                name="adjustmentValue"
                onChange={handleChange}
              />
            </Box>
          </div>
        ) : (
          <Typography variant="subtitle2" className={classes.margin}>
            You must have selected at least one element in order to apply bulk
            action.
          </Typography>
        )}
      </DialogContent>
      <footer className={classes.footer}>
        {selectedRowCount > 0 ? (
          <>
            <Button
              onClick={onClose}
              variant="outlined"
              className={classes.cancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={!isValid()}
            >
              Confirm
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={onClose}>
            I Understand
          </Button>
        )}
      </footer>
    </Dialog>
  )
}

export default BulkUpdateDialog
