import React, { useContext, useMemo } from 'react'
import { Switch, Route } from 'react-router'
import { styled, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import UserContext from 'shared/contexts/UserContext'
import Overview from 'base/pages/Overview'
import getProductModulesInfoForUser from '../getProductModulesInfoForUser'

const { PUBLIC_URL } = process.env

const imageurl = `${PUBLIC_URL}/images/header-bg.svg`

const StyledContent = styled('div')(() => ({
  width: '65%',
  margin: 'auto',
}))

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(5, 0, 4, 0),
    color: '#ffffff',
    textTransform: 'capitalize',
  },
  desc: {
    margin: theme.spacing(0, 0, 4, 0),
    color: '#ffffff',
  },
  banner: {
    position: 'absolute',
    width: '100%',
    height: '330px',
    backgroundImage: `url(${imageurl})`,
  },
}))

export const StyledMainMenu = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: '48% 48%',
  rowGap: `${theme.spacing(4)}px`,
}))

const ProductSpace = () => {
  const user = useContext(UserContext)

  const { buttons: buttonsInfo, content: routesInfo } = useMemo(
    () => getProductModulesInfoForUser(user),
    [user],
  )

  const classes = useStyles()

  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() => (
          <div className={classes.banner}>
            <StyledContent>
              <Typography variant="h4" className={classes.heading}>
                Welcome {user.name} !
              </Typography>
              <Typography variant="subtitle1" className={classes.desc}>
                Innowatts uses meter data and best-in-class AI to help
                electricity providers unlock grid edge transparency, increase
                customer lifetime value, and drive the new energy transition.
              </Typography>
              <StyledMainMenu>
                {buttonsInfo.map(({ ProductModuleButton, products, mod }) => (
                  <ProductModuleButton products={products} key={mod} />
                ))}
              </StyledMainMenu>
            </StyledContent>
          </div>
        )}
      />
      {/* TODO: We should probably get this route into it's own config... Particularly, we should probably get Overview out of the /base/pages directory */}
      <Route path="/overview" exact component={() => <Overview />} />
      {routesInfo.map(({ products }) =>
        products.map(({ product, path, config }) => {
          return (
            <Route key={product} path={path} component={config.MainContent} />
          )
        }),
      )}
      <Route>
        <div>404 Page not found</div>
      </Route>
    </Switch>
  )
}

export default ProductSpace
