import React, { SyntheticEvent, DragEvent } from 'react'
import {
  DataSeriesType,
  DataSeries,
  AxisId,
  DataSeriesTypeUnit,
  Filter,
  Visualization,
  Group,
} from 'shared/types'
import {
  getDataSeriesData,
  getDataSeriesTypes,
} from 'shared/utils/dataSeriesDragAndDrop'
import { Columns as TableColumns } from 'react-feather'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { makeStyles, styled } from '@material-ui/core'
import DataSeriesChip from './DataSeriesChip'
import Units from './Units'
import ChipsInput from './ChipsInput'
import YaxisRangeSelector from './YaxisRangeSelector'

export interface Props {
  axisId: AxisId
  /** DataSeries to display as chips */
  dataSeries: DataSeries[]
  /**
   * Explicitly set units per the different DataSeries types. If a type is not
   * included, that means it will be using its default unit.
   */
  selectedTypeUnits?: DataSeriesTypeUnit
  /** Label to display on input chip box */
  label: string
  /** Function triggered when a valid draggable element is dropped on card zone */
  onDrop: (e: DragEvent, series: DataSeries) => void
  /** Function that will be invoked when an acceptable element is dragged over the card, allows the user to specify if it can be dropped base on its "type", that is passed
   * on the second argument. */
  canDrop: (e: DragEvent, type: DataSeriesType) => boolean
  /** Function called when a dataSeries is modified */
  onDataSeriesChange?: (
    e: SyntheticEvent,
    newDataSeries: DataSeries,
    originalDataSeries: DataSeries,
  ) => void
  /** Function called when a dataSeries is deleted */
  onDataSeriesDelete: (e: SyntheticEvent, series: DataSeries) => void
  /** Function called when the units for any of the types allowed on this input changes */
  onSelectedTypeUnitsChange: (
    e: SyntheticEvent,
    newSelectedTypeUnits: DataSeriesTypeUnit,
  ) => void
  showTableDirectionSelector: boolean
  tableDirection: 'row' | 'column'
  onTableDirectionChange: (
    e: SyntheticEvent,
    newTableDirection: 'row' | 'column',
  ) => void

  /** Boolean value that tells weather the y axis range should start on 0 or not */
  selectedYAxisStartAtDataRange: Boolean | undefined

  /** Function called when the y-axis range for any of the dataseries input changes */
  onSelectedYaxisRangeChange: (
    e: SyntheticEvent,
    newSelectedYaxisRange: DataSeriesTypeUnit,
    axis: AxisId,
  ) => void
  dataSeriesFilters: Filter[]
  meterVisualizationSelected: boolean
  group?: Group | string | null
}

const TableRows = styled(TableColumns)({
  transform: 'rotate(90deg)',
})

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
  },
  selected: {
    backgroundColor: 'transparent !important',
    color: theme.palette.primary.main,
  },
}))

/** DataSeries input box.
 * It is a specialization of the `<ChipsInput />` component that allows dataseries to be dropped. It displays as chips, and provides simple methods when dataseries are deleted or changed */
const DataSeriesChipsInput = ({
  axisId,
  dataSeries,
  selectedTypeUnits,
  label,
  showTableDirectionSelector,
  group,
  tableDirection,
  onDrop,
  canDrop,
  onDataSeriesDelete,
  onDataSeriesChange,
  onSelectedTypeUnitsChange,
  onTableDirectionChange,
  selectedYAxisStartAtDataRange,
  onSelectedYaxisRangeChange,
  dataSeriesFilters,
  meterVisualizationSelected,
}: Props) => {
  const classes = useStyles()

  const innerCanDrop = (e) => {
    const [type] = getDataSeriesTypes(e.dataTransfer)
    return canDrop(e, type)
  }

  const handleDrop = (e: DragEvent) => {
    const [droppedDataSeries] = getDataSeriesData(e.dataTransfer)
    if (droppedDataSeries || meterVisualizationSelected) {
      onDrop(e, {
        ...droppedDataSeries,
        axisId,
      })
    }
  }

  const firstType = dataSeries[0]?.type

  return (
    <ChipsInput
      label={label}
      canDrop={innerCanDrop}
      onDrop={handleDrop}
      renderMenuContent={
        firstType &&
        firstType !== DataSeriesType.Custom &&
        !meterVisualizationSelected
          ? () => (
              <>
                <Units
                  dataSeriesType={firstType}
                  selectedUnit={selectedTypeUnits}
                  onUnitSelect={onSelectedTypeUnitsChange}
                />
                <YaxisRangeSelector
                  axisId={axisId}
                  selectedYAxisStartAtDataRange={selectedYAxisStartAtDataRange}
                  onRangeSelect={onSelectedYaxisRangeChange}
                />
              </>
            )
          : undefined
      }
      extraInputOptions={
        showTableDirectionSelector ? (
          <ToggleButtonGroup
            value={tableDirection}
            exclusive
            onChange={(e, val) => {
              // This throws null if value is already selected so we make a quick check
              if (val) {
                onTableDirectionChange(e, val)
              }
            }}
            size="small"
            className={classes.root}
          >
            <ToggleButton
              classes={{ root: classes.root, selected: classes.selected }}
              value="column"
              title="Display as Columns"
            >
              <TableColumns
                className={
                  tableDirection === 'column' ? classes.selected : undefined
                }
              />
            </ToggleButton>
            <ToggleButton
              classes={{ root: classes.root, selected: classes.selected }}
              value="row"
              title="Display as Rows"
            >
              <TableRows
                className={
                  tableDirection === 'row' ? classes.selected : undefined
                }
              />
            </ToggleButton>
          </ToggleButtonGroup>
        ) : null
      }
    >
      {dataSeries
        // TODO : special case to extract unique -group- count for specifically meter table count column tonot show in table
        .filter((series) => series.id !== `Unique-Count-${group}`)
        .map((series, index) => (
          <DataSeriesChip
            // We have to disable this eslint rule... Although not great, we need to determine if it's necessary we make a unique key here...
            // eslint-disable-next-line react/no-array-index-key
            key={`series_${series.type}_${series.id}_${index}`}
            dataSeries={series}
            dataSeriesFilters={
              series.extras?.visualizationType === Visualization.MeterTable
                ? []
                : dataSeriesFilters
            }
            onChipDelete={(e) => {
              onDataSeriesDelete(e, series)
            }}
            onDataSeriesChange={
              onDataSeriesChange &&
              ((e, modifiedDataSeries) => {
                if (onDataSeriesChange) {
                  onDataSeriesChange(e, modifiedDataSeries, series)
                }
              })
            }
            isMeterVisualization={meterVisualizationSelected}
          />
        ))}
    </ChipsInput>
  )
}

export default DataSeriesChipsInput
