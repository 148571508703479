import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  styled,
  makeStyles,
  fade,
} from '@material-ui/core'
import {
  DataGrid,
  GridSortModelParams,
  GridSortDirection,
  GridColumns,
  GridPageChangeParams,
  GridCellParams,
  isOverflown,
  GridRowsProp,
} from '@material-ui/data-grid'
import SideBarMenu from 'shared/components/SideBarMenu'
import Collapsible from 'shared/components/Collapsible'
import CheckboxGroupWithUtilities from 'shared/components/CheckboxGroupWithUtilities'
import { sortFilters } from 'shared/helpers'
import {
  getPricingConfigurationData,
  getPricingConfigurationFilters,
  getPricingConfigurationTableCount,
} from '../apiClient'
import {
  columnTitleMapping,
  getTableSpecificFilters,
  PricingConfigurationMenuItems,
} from '../mappers'

const APPLY_FILTERS_CHANGES_DELAY = 1000

/**
 *
 * Builds a layout with help of grids
 *
 */
const PageLayout = styled('div')(({ theme }) => ({
  minHeight: '90vh',
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '15% auto 15%',
  gridTemplateAreas: `
  "left-menu body right-menu"`,
  gap: theme.spacing(4, 4),
  padding: theme.spacing(2),
}))

/**
 *
 * MiddleSectionContainer - creates middle section which holds table
 *
 */
const MiddleSectionContainer = styled('div')(() => ({
  gridArea: 'body',
}))

/**
 *
 * SideLeftMenuContainers - left side bar menu
 *
 */
const SideLeftMenuContainer = styled('div')(() => ({
  gridArea: 'left-menu',
}))

/**
 *
 * SideLeftMenuContainers - left side bar menu
 *
 */
const SideRightMenuContainer = styled('div')(() => ({
  gridArea: 'right-menu',
}))

const transformToTableData = (
  data,
  selectedMenuItem: PricingConfigurationMenuItems,
) => {
  const columns: GridColumns = Object.entries(
    columnTitleMapping[selectedMenuItem],
  ).map(([key, value]) => ({
    field: key,
    headerName: value,
    flex: 1,
  }))
  return { rows: data, columns }
}

const useStyles = makeStyles((theme) => ({
  selectedListItem: {
    color: theme.palette.primary.main,
    // !important because material ui really likes its grey background
    backgroundColor: 'transparent !important',
  },
}))

const useDataGridStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-row': {
      '&:nth-child(even)': {
        backgroundColor: fade(theme.palette.primary.main, 0.1),
      },
    },
  },
}))

const ConfigurationsMenu = [
  { name: PricingConfigurationMenuItems.Products, label: 'Products' },
  {
    name: PricingConfigurationMenuItems.ProductComponent,
    label: 'Product-Component',
  },
  {
    name: PricingConfigurationMenuItems.ComponentCurves,
    label: 'Component-Curve',
  },
  { name: PricingConfigurationMenuItems.Components, label: 'Components' },
  { name: PricingConfigurationMenuItems.Formulas, label: 'Formulae' },
  { name: PricingConfigurationMenuItems.Attributes, label: 'Attributes' },
  { name: PricingConfigurationMenuItems.Curves, label: 'Curves' },
  { name: PricingConfigurationMenuItems.LoadProfiles, label: 'Load Profiles' },
]

interface State {
  selectedMenuItem: PricingConfigurationMenuItems
  isSelectedMenuItemChange: boolean
  isFilterStateChanged: boolean
  rows: GridRowsProp
  columns: GridColumns
  pageNumber: number
  rowCount: number
  pageSize: number
  sortOrder?: string
  sortBy?: string
}

const DEFAULT_PAGE_SIZE = 25

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]

const getSelectedFilters = (
  filterState,
  selectedMenuItem: PricingConfigurationMenuItems,
) => {
  const allSelectedFilters = filterState.reduce((acc, val) => {
    if (!val.items.some((v) => v.isChecked)) {
      return acc
    }
    return {
      ...acc,
      [val.name]: val.items.filter((v) => v.isChecked).map((v) => v.id),
    }
  }, {})
  return getTableSpecificFilters(selectedMenuItem, allSelectedFilters)
}

/**
 * Confiugrations page on the Pricing module
 */
const Configurations = () => {
  const [tableState, setTableState] = useState<State>({
    selectedMenuItem: PricingConfigurationMenuItems.Products,
    isSelectedMenuItemChange: true,
    isFilterStateChanged: true,
    rows: [],
    columns: [],
    pageNumber: 0,
    rowCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const [filterState, changeFilterState] = useState<any>([])
  const [debouncer] = useState<any>({ timeout: null })

  const classes = useStyles()
  const dataGridClasses = useDataGridStyles()

  useEffect(() => {
    getPricingConfigurationFilters().then((val) => {
      changeFilterState((ps) => [...ps, ...val])
    })
  }, [])

  useEffect(() => {
    const selectedFilters = getSelectedFilters(
      filterState,
      tableState.selectedMenuItem,
    )
    const { sortOrder, sortBy } = tableState
    const sort = sortOrder && sortBy ? { sortOrder, sortBy } : undefined
    const promises = [
      getPricingConfigurationData(
        tableState.selectedMenuItem,
        tableState.pageNumber,
        tableState.pageSize,
        selectedFilters,
        sort,
      ),
    ]
    if (
      tableState.isSelectedMenuItemChange ||
      tableState.isFilterStateChanged
    ) {
      promises.push(
        getPricingConfigurationTableCount(
          tableState.selectedMenuItem,
          selectedFilters,
        ),
      )
    }
    Promise.all(promises).then(([tableData, rowCount]) => {
      const { columns, rows } = transformToTableData(
        tableData,
        tableState.selectedMenuItem,
      )
      const newState =
        rowCount == null
          ? { columns, rows }
          : {
              columns,
              rows,
              rowCount,
              isSelectedMenuItemChange: false,
              isFilterStateChanged: false,
            }
      setTableState((pS) => ({ ...pS, ...newState }))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableState.selectedMenuItem,
    tableState.pageNumber,
    tableState.sortOrder,
    tableState.pageSize,
    tableState.isFilterStateChanged,
  ])

  const handleMenuItemChange = (selectedMenuItem) => {
    setTableState((pS) => ({
      ...pS,
      selectedMenuItem,
      isSelectedMenuItemChange: true,
      pageNumber: 0,
      sortOrder: undefined,
      sortBy: undefined,
    }))
  }

  const handleSortModelChange = (params: GridSortModelParams) => {
    if (params.sortModel.length === 0) {
      const { sortOrder, sortBy, ...rest } = tableState
      setTableState(rest)
    } else {
      setTableState((prev) => ({
        ...prev,
        sortBy: params.sortModel[0].field as string,
        sortOrder: params.sortModel[0].sort as string,
      }))
    }
  }

  const handlePageSizeChange = (params: GridPageChangeParams) => {
    const newPageNumber = Math.floor(
      (tableState.pageNumber * tableState.pageSize) / params.pageSize,
    )
    setTableState((prev) => ({
      ...prev,
      pageSize: params.pageSize,
      pageNumber: newPageNumber,
    }))
  }

  const handleCellHover = (cell: GridCellParams) => {
    // @ts-ignore
    if (cell.element && isOverflown(cell.element)) {
      // @ts-ignore
      cell.element.setAttribute('title', cell.value as string)
    }
  }

  const handlePageChange = (val: GridPageChangeParams) => {
    setTableState((pS) => ({ ...pS, pageNumber: val.page }))
  }

  const updateFilters = (groupName, changes) => {
    changeFilterState((prevFilter) => {
      return prevFilter.map((filter) => {
        if (filter.name !== groupName) {
          return filter
        }
        const itemsShallowCopy = [...filter.items]
        changes.forEach((elem) => {
          itemsShallowCopy[elem.index] = {
            ...itemsShallowCopy[elem.index],
            isChecked: elem.isChecked,
          }
        })
        return { ...filter, items: itemsShallowCopy }
      })
    })
    if (debouncer.timeout) {
      clearTimeout(debouncer.timeout)
    }
    debouncer.timeout = setTimeout(() => {
      debouncer.timeout = null
      changeFilterState((prevFilter) => {
        return prevFilter.map((filter) => {
          const sortedItems = filter.items.sort(sortFilters)
          return { ...filter, items: sortedItems }
        })
      })
      setTableState((ps) => ({ ...ps, isFilterStateChanged: true }))
    }, APPLY_FILTERS_CHANGES_DELAY)
  }

  const sortModel =
    tableState.sortBy && tableState.sortOrder
      ? [
          {
            field: tableState.sortBy,
            sort: tableState.sortOrder as GridSortDirection,
          },
        ]
      : []

  return (
    <PageLayout>
      {/* TODO: make this into a menu item */}
      <SideLeftMenuContainer>
        <SideBarMenu title="Pricing Configuration">
          <List component="nav" aria-label="contacts">
            {ConfigurationsMenu.map(({ name, label }) => {
              return (
                <ListItem
                  button
                  key={name}
                  selected={name === tableState.selectedMenuItem}
                  onClick={() => handleMenuItemChange(name)}
                  classes={{
                    selected: classes.selectedListItem,
                  }}
                >
                  <ListItemText primary={label} />
                </ListItem>
              )
            })}
          </List>
        </SideBarMenu>
      </SideLeftMenuContainer>
      <MiddleSectionContainer>
        <DataGrid
          disableColumnMenu
          disableSelectionOnClick
          density="standard"
          rows={tableState.rows}
          columns={tableState.columns}
          pageSize={tableState.pageSize}
          page={tableState.pageNumber}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          rowCount={tableState.rowCount}
          disableExtendRowFullWidth={false}
          onPageChange={handlePageChange}
          onCellOver={handleCellHover}
          sortModel={sortModel}
          className={dataGridClasses.root}
        />
      </MiddleSectionContainer>
      <SideRightMenuContainer>
        <SideBarMenu title="Filters">
          {filterState.map(({ name, title, items }, index) => {
            const hasManyItems = items.length > 10
            return (
              <Collapsible
                title={title}
                key={name}
                defaultMenuIsOpen={index < 1}
              >
                <CheckboxGroupWithUtilities
                  items={items}
                  onCheckboxChange={(e, metadata) =>
                    updateFilters(name, metadata)
                  }
                  hasSearch
                  hasSelectAllClearAll
                  isVirtual={hasManyItems}
                  virtualMinHeight={200}
                  virtualItemSize={30}
                />
              </Collapsible>
            )
          })}
        </SideBarMenu>
      </SideRightMenuContainer>
    </PageLayout>
  )
}

export default Configurations
