import React from 'react'
import { styled, makeStyles } from '@material-ui/core/styles'
import { Card, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Palette } from '@material-ui/core/styles/createPalette'
import ColoredAvatar from 'shared/components/ColoredAvatar'
import { ApplicationProduct } from 'shared/types'

interface Props {
  /**
   * String value to show on the component
   *
   * eg:- ```
   * label='Test'
   * ```
   */
  label: string
  /**
   * String value that helps in knowing the module type
   *
   * eg:- ```
   * name='demand'
   * ```
   *
   */
  name: string
  /**
   * String value to show as description on the component
   *
   * eg:- ```
   * label='this is a desc'
   * ```
   */
  description: string
  /**
   * Array of products inside a module
   *
   * eg:- demand can have load forecast and other sub modules
   */
  products: Array<ApplicationProduct>
}

interface SubModuleListProps {
  /**
   * Sub modules associated with main module ,
   * eg - demand is main module , load forecast is sub module
   */
  products: Array<ApplicationProduct>
  /**
   * Color of module associated
   */
  color: keyof Palette
}

const useStyles = makeStyles((theme) => ({
  module: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  moduleText: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    maxWidth: '100px',
    letterSpacing: 0,
  },
  disabledModuleText: {
    color: `${theme.palette.grey[500]} !important`,
  },
}))

const StyledCardContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3),
}))

const StyledModuleList = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'block',
}))

const StyledModuleText = styled(Typography)(() => ({
  lineHeight: '19px',
}))

const SubModuleList = ({ products, color }: SubModuleListProps) => {
  const classes = useStyles({ color })
  const buttonProps = (isExternalLink, path, active) => {
    return !isExternalLink
      ? {
          component: Link,
          to: path,
          disabled: !active,
        }
      : {
          target: '_blank',
          href: path,
          disabled: !active,
        }
  }

  return (
    <StyledModuleList>
      {products.map((module) => {
        return (
          <Button
            key={`main_buttom_${module.label}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps(module.isExternalLink, module.path, module.active)}
          >
            <ColoredAvatar disabled={!module.active} color={color}>
              {module.icon}
            </ColoredAvatar>
            <span
              className={clsx(
                classes.moduleText,
                !module.active && classes.disabledModuleText,
              )}
            >
              <StyledModuleText variant="subtitle1">
                {module.label}
              </StyledModuleText>
            </span>
          </Button>
        )
      })}
    </StyledModuleList>
  )
}

/**
 * Very styled button to display the information about
 * a product to the user and route it properly
 * from the main hub of the application.
 */
const MainMenuButton = (props: Props) => {
  const { label, description, products, name } = props

  return (
    <Card variant="outlined">
      <StyledCardContent>
        <Typography variant="h6" component="p" gutterBottom>
          {label}
        </Typography>
        <StyledDescription variant="body2">{description}</StyledDescription>
        <SubModuleList products={products} color={name as keyof Palette} />
      </StyledCardContent>
    </Card>
  )
}

export default MainMenuButton
