import { Reducer, useEffect, useReducer, useState } from 'react'

const APPLY_FILTERS_CHANGES_DELAY = 1000

export interface RangeFilter {
  name: string
  title: string
  minValue: number
  maxValue: number
  value: [number, number]
}

type SimpleKeyValue = { name: string; minValue: number; maxValue: number }

export interface SavedRangeFilters {
  name: string
  value: [number, number]
}
interface ReducerState {
  filters: RangeFilter[]
  lastChanged: string | null
}

const initialize = ({
  filterOptions,
  getTitle,
  savedFilters,
}): {
  type: 'INITIALIZE'
  filterOptions: SimpleKeyValue[]
  getTitle?: (name: string) => string
  savedFilters?: SavedRangeFilters[]
} => ({
  type: 'INITIALIZE',
  filterOptions,
  getTitle,
  savedFilters,
})

const handleFilterChange = ({
  filterName,
  changes,
}): {
  type: 'HANDLE_FILTER_CHANGE'
  filterName: string
  changes: [number, number]
} => ({
  type: 'HANDLE_FILTER_CHANGE',
  filterName,
  changes,
})

const setFinalFilterState = (): { type: 'SET_FINAL_FILTER_STATE' } => ({
  type: 'SET_FINAL_FILTER_STATE',
})

type ReducerAction =
  | ReturnType<typeof initialize>
  | ReturnType<typeof handleFilterChange>
  | ReturnType<typeof setFinalFilterState>

const reducer: Reducer<ReducerState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const filters: Array<RangeFilter> = []
      action.filterOptions.forEach((option) => {
        const saved: SavedRangeFilters | undefined = action.savedFilters?.find(
          (s) => s.name === option.name,
        )

        const filter: RangeFilter = {
          name: option.name,
          title: action.getTitle ? action.getTitle(option.name) : option.name,
          value: [option.minValue, option.maxValue],
          minValue: option.minValue,
          maxValue: option.maxValue,
        }

        if (saved) {
          filter.value = saved.value
        }

        filters.push(filter)
      })

      return {
        ...state,
        filters,
      }
    }
    case 'HANDLE_FILTER_CHANGE': {
      const newFilters = state.filters.map((filter) => {
        if (filter.name !== action.filterName) {
          return filter
        }

        return { ...filter, value: action.changes }
      })
      return {
        ...state,
        filters: newFilters,
        lastChanged: action.filterName,
        isDone: false,
      }
    }
    default: {
      return state
    }
  }
}

const useRangeFilters = (
  filterOptions: Array<SimpleKeyValue>,
  getTitle?: (name: string) => string,
  savedFilters?: SavedRangeFilters[],
) => {
  const [state, dispatch] = useReducer(reducer, {
    filters: [],
    lastChanged: null,
  })

  const { filters } = state

  const [debouncer] = useState<any>({ timeout: null })

  useEffect(() => {
    if (filterOptions && filterOptions.length > 0) {
      dispatch(initialize({ filterOptions, getTitle, savedFilters }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions, savedFilters])

  function handleChange(filterName, changes) {
    debouncer.timeout = setTimeout(() => {
      debouncer.timeout = null
      dispatch(handleFilterChange({ filterName, changes }))
    }, APPLY_FILTERS_CHANGES_DELAY)
  }

  return {
    filters,
    onChange: handleChange,
  }
}

export default useRangeFilters
