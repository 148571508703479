import React, { useState, useEffect, useMemo, useContext } from 'react'
import { styled } from '@material-ui/core'
import JobDescription from 'modules/customer/products/pricing/components/JobDescription'
import InputsTable, {
  Props as InputsTableProps,
} from 'modules/customer/products/pricing/components/InputsTable'
import { getDefaultStartDate } from 'modules/customer/products/pricing/helpers'
import { Input } from 'shared/types'
import { v4 as uuidv4 } from 'uuid'
import AlertContext from 'shared/contexts/AlertsContext'
import processCsv from 'shared/utils/csvFileValidationAndExtraction'
import {
  createPricingJobs,
  getCalculationParametersValues,
  PricingCalculationParameters,
  getRerunJobInputData,
} from '../apiClient'
import {
  createInputValidators,
  transformFileInputToTableInput,
  createUniqueInputValue,
} from './Calculations.helpers'

/**
 *
 * Builds a pricing page layout with help of grids
 *
 */
const PageContainer = styled('div')(({ theme }) => ({
  minHeight: '90vh',
  display: 'grid',
  gridTemplate: `
  'inputContainter'
  'middleSectionContainer'
  `,
  gridTemplateColumns: '1fr',
  gridTemplateRows: '100px 1fr',
  gap: theme.spacing(4, 4),
}))

/**
 *
 * input header - shows fields for name, notes and give option to run jobs
 *
 */
const InputContainter = styled('div')(() => ({
  gridArea: 'inputContainter',
  display: 'grid',
}))

/**
 *
 * MiddleSectionContainer - creates middle section which holds table
 *
 */
const MiddleSectionContainer = styled('div')(() => ({
  gridArea: 'middleSectionContainer',
  display: 'grid',
}))

const calculationParameters: Array<string> = Object.values(
  PricingCalculationParameters,
)

export interface CalculationsProps {
  rerunJobUid?: string
  onNewJobViewRequest: () => void
}

/**
 * Calculations page on the Pricing module
 */
const Calculations = ({
  rerunJobUid,
  onNewJobViewRequest,
}: CalculationsProps) => {
  const [defaultStartDate] = useState(() => getDefaultStartDate())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [inputs, setInputs] = useState<Array<Input>>([])
  const [options, setOptions] = useState<InputsTableProps['options']>({
    marketOptions: [],
    isoOptions: [],
    edcOptions: [],
    productOptions: [],
    profileOptions: [],
  })
  const publishNotification = useContext(AlertContext)

  const csvHeaders = [
    'meterId',
    'profile',
    'market',
    'iso',
    'edc',
    'product',
    'startDate',
    'endDate',
    'asOfDate',
  ]

  /* TODO: Check when these values get retrieved so the proper validators
  are created only on that case (shouldn't change anymore after that) */
  const { validateFileInput, validateTableInput } = useMemo(
    () =>
      createInputValidators(
        options.marketOptions,
        options.isoOptions,
        options.profileOptions,
        options.edcOptions,
        options.productOptions,
      ),
    [options],
  )

  /* A set of unique values (no "uid" fields) for the existing inputs
  is maintained to check every new input that is tried to be added 
  either from the table or an upload process */
  const uniqueInputValuesSet = useMemo(() => {
    return new Set(
      inputs.map((input) => {
        const inputModel: any = { ...input }
        delete inputModel.uid
        return createUniqueInputValue(inputModel)
      }),
    )
  }, [inputs])

  /* TODO: Some better visual cue should be displayed when some time consuming process is at work */

  const transformData = (inputRow, addError) => {
    const { error } = validateFileInput(inputRow)
    if (error) {
      error.details.forEach((errorDetails) => addError(errorDetails.message))
      return null
    }
    const inputModel = transformFileInputToTableInput(inputRow)
    if (uniqueInputValuesSet.has(createUniqueInputValue(inputModel))) {
      addError('The row already exists on the table')
      return null
    }

    return { ...inputModel, uid: uuidv4() }
  }

  const populateCsvToTable = ({ data, errors, meta }) => {
    if (data.length) {
      setInputs((prevInputs) => (data as Array<Input>).concat(prevInputs))
    }
    if (meta.aborted || meta.totalFileDataRows > data.length) {
      let message: string
      if (meta.aborted) {
        message = errors[0]?.errors[0]
      } else {
        /* TODO: Come up with a better method to display the audit information
          from the process */
        message = `From the ${meta.totalFileDataRows} rows on the file, ${
          meta.totalFileDataRows - data.length
        } have not be imported as are duplicates or have errors`
      }

      publishNotification(message, 'error')
      // TODO: Remove this console.warn when a better way to check audit information is implemented
      // eslint-disable-next-line no-console
      console.warn(`CSV Import Report`, { data, errors, meta })
    }
  }

  useEffect(() => {
    if (!isLoading && !inputs.length && rerunJobUid) {
      getRerunJobInputData(rerunJobUid).then((result) => {
        processCsv(result, {
          fields: csvHeaders,
          processRow: transformData,
        }).then(populateCsvToTable)
      })
    }
    // eslint-disable-next-line
  }, [isLoading])

  useEffect(() => {
    getCalculationParametersValues(calculationParameters)
      .then((data) => {
        setOptions({
          marketOptions: data.market,
          isoOptions: data.iso,
          edcOptions: data.edc,
          productOptions: data.product,
          profileOptions: data.profile,
        })
        setIsLoading(false)
      })
      .catch(() =>
        publishNotification(
          `Values for the inputs could't be loaded. Please, check your conection and/or try again in a few moments`,
          'error',
        ),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleOnActionClick(e, jobDescription) {
    setIsLoading(true)
    const response = await createPricingJobs(jobDescription, inputs)
    const message = response.success
      ? `Job with name: ${response.data.name} was created`
      : response.data.message

    if (response.success) {
      publishNotification(message, 'success', {
        label: 'View',
        onExecute: () => {
          onNewJobViewRequest()
        },
      })
    } else {
      publishNotification(message, 'error')
    }
    setIsLoading(false)
  }

  return (
    <PageContainer>
      <InputContainter>
        <JobDescription
          onCheckStatusClick={handleOnActionClick}
          onRunClick={handleOnActionClick}
          inputsFields={csvHeaders}
          processInput={transformData}
          onInputsImportComplete={populateCsvToTable}
          isLoading={isLoading}
        />
      </InputContainter>

      <MiddleSectionContainer>
        <InputsTable
          inputs={inputs}
          validateInput={(inputModel) => {
            const { error } = validateTableInput(inputModel)
            if (error) return false
            return !uniqueInputValuesSet.has(createUniqueInputValue(inputModel))
          }}
          options={options}
          onInputAdd={(event, newInput) => {
            setInputs((prevInputs) => [
              { ...newInput, uid: uuidv4() },
              ...prevInputs,
            ])
          }}
          onInputEdit={(event, newInput, originalInput) => {
            const newInputObj: Input = {
              ...originalInput,
              ...newInput,
            }
            setInputs((prevInputs) =>
              prevInputs.map((e) =>
                newInputObj.uid === e.uid ? newInputObj : e,
              ),
            )
          }}
          onInputDelete={(event, inputToDelete) => {
            setInputs((prevInputs) =>
              prevInputs.filter((input) => inputToDelete.uid !== input.uid),
            )
          }}
          defaultStartDate={defaultStartDate}
        />
      </MiddleSectionContainer>
    </PageContainer>
  )
}

export default Calculations
