import React, { ReactNode, MouseEventHandler } from 'react'
import { makeStyles, IconButton, Popover } from '@material-ui/core'
import clsx from 'clsx'
import { AlertCircle, Download, MoreHorizontal } from 'react-feather'
import useClickedElement from 'shared/hooks/useClickedElement'

export interface Props {
  children: ReactNode
  className?: string
  errorContent?: ReactNode
  moreActionsContent?: ReactNode
  onDownload: MouseEventHandler
}

const useToolbarWithMoreActionsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  error: {
    color: theme.palette.error.main,
    flex: '0 0 auto',
  },
  moreActions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}))

const ToolbarWithMoreActions = ({
  children,
  className,
  errorContent,
  moreActionsContent,
  onDownload,
}: Props) => {
  const classes = useToolbarWithMoreActionsStyles()

  const [anchorEl, handleMenuOpen, handleMenuClose] = useClickedElement()
  const [errorAnchorEl, handleErrorOpen, handleErrorClose] = useClickedElement()

  return (
    <div className={clsx(className, classes.root)}>
      <div data-testid="card-visualization-options" className={classes.content}>
        {children}
      </div>
      {Boolean(errorContent) && (
        <>
          <IconButton
            className={clsx(classes.error)}
            size="small"
            onClick={handleErrorOpen}
            title="Errors"
          >
            <AlertCircle />
          </IconButton>
          <Popover
            anchorEl={errorAnchorEl}
            open={Boolean(errorAnchorEl)}
            onClose={handleErrorClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {errorContent}
          </Popover>
        </>
      )}
      <div className={classes.moreActions}>
        <IconButton
          size="small"
          onClick={onDownload}
          title="Download"
          data-testid="card-download-data"
        >
          <Download />
        </IconButton>
        {moreActionsContent && (
          <>
            <IconButton
              data-testid="card-more-options"
              size="small"
              onClick={handleMenuOpen}
              title="More options"
            >
              <MoreHorizontal />
            </IconButton>
            <Popover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {moreActionsContent}
            </Popover>
          </>
        )}
      </div>
    </div>
  )
}

export default ToolbarWithMoreActions
