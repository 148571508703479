import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormGroup,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core'
import {
  Level,
  Market,
} from '../../../../modules/demand/products/loadForecast/components/LoadForecastTypes'
import IWButton from '../../IWButton'
import MultiSelectWithControls from '../../MultiSelectWithControls/MultiSelectWithControls'
import { SkeletonOptions } from './SkeletonSummaryElements'

interface Props {
  isLoading?: boolean
  isInitialized?: boolean
  availableMarkets: Market[]
  availableLevels: Level[]
  selectedMarkets: string[]
  selectedLevel?: string
  onSelectMarkets: (markets: string[]) => void
  onSelectLevel: (level: string) => void
}

const SelectEmpty = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    cursor: 'default',
  },
}))(Box) as typeof Box

const SelectLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    fontSize: '14px',
    fontWeight: 500,
  },
}))(Typography) as typeof Typography

const SelectWrapper = withStyles((theme) => ({
  root: {
    width: theme.spacing(30),
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.divider,
    overflow: 'hidden',
    borderRadius: '4px',
    marginRight: theme.spacing(2),
  },
}))(FormControl) as typeof FormControl

const SelectInput = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.1),
    '& > div': {
      height: theme.spacing(2),
    },
  },
}))(Select) as typeof Select

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
}))

const SummaryListOptions = ({
  isInitialized,
  isLoading,
  availableMarkets,
  availableLevels,
  selectedMarkets,
  selectedLevel,
  onSelectMarkets,
  onSelectLevel,
}: Props) => {
  const classes = useStyles()
  const [filtered, setFiltered] = useState<boolean>()

  const clearFilters = () => {
    onSelectMarkets(availableMarkets.map((m) => m.id))
  }

  const handleSelectLevel = (e) => {
    const { value } = e.target
    onSelectLevel(value)
  }

  useEffect(() => {
    setFiltered(!!selectedLevel || selectedMarkets.length > 0)
  }, [selectedLevel, selectedMarkets])

  return (
    <>
      {!isInitialized ? (
        <SkeletonOptions />
      ) : (
        <FormGroup className={classes.root}>
          <Box>
            <SelectLabel variant="caption" component="p">
              LEVEL
            </SelectLabel>
            <SelectWrapper data-testid="level-select">
              {availableLevels.length > 0 ? (
                <SelectInput
                  inputProps={{ 'data-testid': 'level-select-input' }}
                  variant="outlined"
                  value={selectedLevel}
                  disabled={isLoading}
                  name="selectedLevel"
                  onChange={handleSelectLevel}
                >
                  {availableLevels.map((level: Level) => {
                    return (
                      <MenuItem
                        data-testid="level-option"
                        key={level.id}
                        value={level.id}
                      >
                        {level.name}
                      </MenuItem>
                    )
                  })}
                </SelectInput>
              ) : (
                <SelectEmpty data-testid="no-levels">
                  No Levels Available
                </SelectEmpty>
              )}
            </SelectWrapper>
          </Box>
          <Box>
            <SelectLabel variant="caption" component="p">
              MARKETS
            </SelectLabel>
            <MultiSelectWithControls
              disabled={isLoading}
              options={availableMarkets.map((m) => ({
                id: m.id,
                name: m.name,
              }))}
              selectedOptions={selectedMarkets}
              onConfirm={onSelectMarkets}
            />
          </Box>
          <Box display="flex" flexGrow={1} />
          <IWButton
            data-testid="filter-reset"
            disabled={!filtered || isLoading}
            onClick={clearFilters}
          >
            Clear Filters
          </IWButton>
        </FormGroup>
      )}
    </>
  )
}

export default SummaryListOptions
