import { styled } from '@material-ui/core'

export const PageLayout = styled('div')(({ theme }) => ({
  minHeight: '90vh',
  display: 'grid',
  gridTemplateRows: '0.1fr 0.3fr 1fr',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: theme.spacing(3),
  padding: theme.spacing(5, 10, 0),
}))

export const Title = styled('div')(() => ({
  gridArea: '1 / 1 / 2 / 3',
}))

export const Options = styled('div')(() => ({
  gridArea: '1 / 4 / 2 / 6',
}))

export const ChartArea = styled('div')(() => ({
  gridArea: '2 / 1 / 3 / 6',
  minHeight: '350px',
}))

export const RightMenu = styled('div')(() => ({
  gridArea: '3 / 5 / 4 / 6',
}))

export const Table = styled('div')(() => ({
  gridArea: '3 / 1 / 4 / 5',
}))
