import React, { ReactElement } from 'react'
import { Box, Typography, withStyles } from '@material-ui/core'
import IWButton from '../../IWButton'
import { renderSkeletonListItems } from './SkeletonSummaryElements'

interface Props {
  items: any[]
  loading?: boolean
  hasData?: boolean
  canLoadMore?: boolean
  filteredLoadMore?: boolean
  onLoadMore: () => void
  renderCard: (item) => ReactElement
  renderCardSkeleton: (index) => ReactElement
}

export const CardList = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gridArea: 'body',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))(Box) as typeof Box

const GroupList = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}))(Box) as typeof Box

const EmptyListMessage = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
}))(Typography) as typeof Typography

const SummaryListItems = ({
  items,
  loading,
  renderCard,
  onLoadMore,
  canLoadMore,
  renderCardSkeleton,
  filteredLoadMore,
  hasData,
}: Props) => {
  return (
    <GroupList>
      {hasData ? (
        <CardList>
          {items.map(renderCard)}
          {loading
            ? Array.from({ length: 5 }, () => Math.random()).map(
                renderCardSkeleton,
              )
            : ''}
        </CardList>
      ) : (
        renderSkeletonListItems(renderCardSkeleton, 5)
      )}
      {!loading && hasData && items.length === 0 ? (
        <EmptyListMessage variant="h6" data-testid="no-items-msg">
          No items found
        </EmptyListMessage>
      ) : (
        ''
      )}
      {hasData && items.length > 0 && filteredLoadMore ? (
        <EmptyListMessage variant="h6" data-testid="filtered-load-msg">
          Your filters prevent some items from showing
        </EmptyListMessage>
      ) : (
        ''
      )}
      {items.length > 0 && !loading && canLoadMore ? (
        <Box width="100%" display="flex" justifyContent="center">
          <IWButton
            data-testid="load-more"
            disabled={loading}
            onClick={() => onLoadMore()}
          >
            Load More
          </IWButton>
        </Box>
      ) : (
        ''
      )}
    </GroupList>
  )
}

export default SummaryListItems
