import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core'
import IWButton from '../IWButton'

interface Option {
  id: string
  name: string
  count?: number
}

interface Props {
  disabled?: boolean
  options: Option[]
  selectedOptions: string[]
  onConfirm: any
}

const SelectEmpty = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    cursor: 'default',
  },
}))(Box) as typeof Box

const SelectWrapper = withStyles((theme) => ({
  root: {
    width: theme.spacing(30),
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.divider,
    overflow: 'hidden',
    borderRadius: '4px',
    marginRight: theme.spacing(2),
  },
}))(FormControl) as typeof FormControl

const ActionContainer = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
  },
}))(Box) as typeof Box

const SelectInput = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.1),
    '& > div': {
      height: theme.spacing(2),
    },
  },
}))(Select) as typeof Select

const MultiSelectWithControls = ({
  disabled,
  options,
  selectedOptions,
  onConfirm,
}: Props) => {
  const selectRef = useRef()
  const [selectedTmpOptions, setTmpSelectedOptions] = useState<string[]>(
    selectedOptions,
  )
  const [open, setOpen] = useState(false)

  const handleSelectOptions = (e) => {
    const { value } = e.target
    setTmpSelectedOptions(value.filter((v) => v))
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setTmpSelectedOptions(selectedOptions)
  }, [selectedOptions])

  return (
    <SelectWrapper>
      {options.length > 0 ? (
        <SelectInput
          multiple
          ref={selectRef}
          data-testid="option-select"
          disabled={disabled}
          variant="outlined"
          value={selectedTmpOptions}
          name="selectedOptions"
          open={open}
          onOpen={(e) => {
            e.stopPropagation()
            setOpen(true)
          }}
          onChange={handleSelectOptions}
        >
          {options.map((option: Option) => {
            return (
              <MenuItem
                role="option"
                data-testid="option-select-option"
                key={option.id}
                value={option.id}
              >
                {`${option.name} ${option.count ? `(${option.count})` : ''}`}
              </MenuItem>
            )
          })}
          <ActionContainer>
            <IWButton
              data-testid="select-confirm"
              onClick={() => {
                onConfirm(selectedTmpOptions)
                setOpen(false)
              }}
            >
              Confirm
            </IWButton>
            <IWButton
              data-testid="select-cancel"
              cancel
              onClick={(e) => {
                e.stopPropagation()
                setTmpSelectedOptions(selectedOptions)
                handleClose()
              }}
            >
              Cancel
            </IWButton>
          </ActionContainer>
        </SelectInput>
      ) : (
        <SelectEmpty data-testid="no-options">No Options Available</SelectEmpty>
      )}
    </SelectWrapper>
  )
}

export default MultiSelectWithControls
