import React, { useMemo } from 'react'
import { Checkbox } from '@material-ui/core'
import VirtualizedTable from 'shared/components/VirtualizedTable'
import { ValidAdjustmentCalculations } from 'shared/types'
import { Sort } from '../hooks/useAdjustmentsData'

interface Props<T extends object> {
  data: T[]
  onChange: (items: T[]) => void
  onSort?: (header: string, sortDirection: string) => void
  availableFilters: string[]
  sortArray: Sort[]
}

const adjustmentCalculationMap = {
  [ValidAdjustmentCalculations.NoAction]: '',
  [ValidAdjustmentCalculations.Addition]: 'add',
  [ValidAdjustmentCalculations.Multiplication]: 'multiply',
  [ValidAdjustmentCalculations.Division]: 'divide',
  [ValidAdjustmentCalculations.Percent]: 'percent',
  [ValidAdjustmentCalculations.Custom]: 'equal',
}

const timeFilters = ['date', 'hour', 'day', 'timestamp']

const AdjustmentTable = <T extends object>({
  data,
  availableFilters,
  onChange,
  onSort,
  sortArray,
}: Props<T>) => {
  const sortDirectionHandler = (header, direction) => {
    let sortDirection = direction
    if (!onSort) {
      return
    }
    if (!sortDirection) {
      sortDirection = 'asc'
    } else if (sortDirection === 'asc') {
      sortDirection = 'desc'
    } else if (sortDirection === 'desc') {
      sortDirection = undefined
    }
    onSort(header, sortDirection)
  }

  const columns = useMemo(() => {
    const left = 'left' as const
    const right = 'right' as const
    /**
     * All these are the available filters that are NOT TIME
     * They are aligned left because they're assumed text
     */
    const attributeColumns = availableFilters
      .filter((elem) => !timeFilters.includes(elem))
      .map((elem) => ({
        header: elem,
        render: elem,
        align: left,
        sortDirection: sortArray?.find((v) => v.column === elem)?.direction,
        onSortHandler: (e, sortDirection) =>
          sortDirectionHandler(elem, sortDirection),
      }))
    return [
      {
        header: () => (
          <Checkbox
            onChange={(e) =>
              onChange(
                data.map((item) => ({ ...item, selected: e.target.checked })),
              )
            }
          />
        ),
        render: (item) => {
          const { selected } = item
          return (
            <Checkbox
              checked={selected}
              onChange={(e) =>
                onChange([
                  {
                    ...item,
                    selected: e.target.checked,
                  },
                ])
              }
            />
          )
        },
        align: left,
      },
      {
        header: 'Date',
        render: 'date',
        align: left,
        width: '115px',
        sortDirection: sortArray?.find((v) => v.column === 'date')?.direction,
        onSortHandler: (e, sortDirection) =>
          sortDirectionHandler('date', sortDirection),
      },
      {
        header: 'Hour',
        render: 'hour',
        align: right,
        width: '80px',
        sortDirection: sortArray?.find((v) => v.column === 'hour')?.direction,
        onSortHandler: (e, sortDirection) =>
          sortDirectionHandler('hour', sortDirection),
      },
      ...attributeColumns,
      {
        header: 'Load',
        render: 'forecast',
        align: right,
        sortDirection: sortArray?.find((v) => v.column === 'forecast')
          ?.direction,
        onSortHandler: (e, sortDirection) =>
          sortDirectionHandler('forecast', sortDirection),
      },
      {
        header: 'Calc',
        align: left,
        render: (item) => {
          const { adjustmentCalculation, adjustmentValue } = item
          return (
            <span>
              {adjustmentCalculationMap[adjustmentCalculation]}{' '}
              {adjustmentValue}
            </span>
          )
        },
      },
      {
        header: 'New Load',
        render: 'adjustedForecast',
        align: right,
        sortDirection: sortArray?.find((v) => v.column === 'adjustedForecast')
          ?.direction,
        onSortHandler: (e, sortDirection) =>
          sortDirectionHandler('adjustedForecast', sortDirection),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilters, sortArray, data])
  return <VirtualizedTable items={data} itemKey="id" columns={columns as any} />
}

export default AdjustmentTable
