import { DateTime } from 'luxon'

export interface Level {
  id: string
  name: string
  datasource: string
  configId: string
}

export interface Market {
  id: string
  name: string
}

export interface Forecast {
  processTime: string
  market: string
  min: number
  max: number
  count: number
  from: DateTime
  to: DateTime
  dayCount: number
  forecast: {
    forecast: number
    processTime: string
    timestamp: string
  }[]
}

export interface Adjustment {
  name: string
  createdAt: string
  updatedAt: string
  processTime: string
  approvedBy?: string
  approvedOn?: DateTime
  market: string
  min: number
  max: number
  count: number
  from: DateTime
  to: DateTime
  dayCount: number
  forecast: number[]
  adjustedForecast: number[]
  stitched: any[]
}

export enum ForecastType {
  ShortTermForecast = 'shortTermForecast',
  LongTermForecast = 'longTermForecast',
}

export enum AdjustmentType {
  Draft = 'draft',
  Approved = 'approved',
}

export interface ForecastWithLevel extends Forecast {
  level: {
    configId: string
  }
}

export interface AvailableForecastItem {
  market: string
  processTime: string
  forecast: number
  timestamp: string
}

export interface AdjustmentFormData {
  name: string
  timezone: string
  isHourEnding: Boolean
  forecast: ForecastWithLevel
}
