/* eslint-disable no-param-reassign */
import { useState } from 'react'
import { DateTime } from 'luxon'
import {
  fetchAvailableForecastItems,
  getDruidDatasourceTimeBoundary,
  getDruidDistinctAll,
} from 'modules/demand/common/apiClient'
import {
  AvailableForecastItem,
  ForecastType,
  Level,
} from '../components/LoadForecastTypes'

export interface LoadMoreReturnValue {
  items: AvailableForecastItem[] | AvailableAdjustmentItem[]
  canLoadMore: boolean
}
export interface AvailableAdjustmentItem {
  adjustmentId: string
  dateRange: { from: string; to: string }
  market: string
  processTime: string
  summary: {
    timestamp: string
    forecast: number
    adjustedForecast: number
  }[]
  forecasts: {
    brand: string
    forecast: number
    market: string
    processTime: string
    timestamp: string
  }[]
  stitched: any[]
  createdAt: string
}

interface LoadMoreOptions {
  limit?: number
  timezone?: string
  forecastStartDate?: string
  forecastEndDate?: string
}

interface UniqueProcessDateAndMarketItem {
  configId?: string
  market: string
  process_time: string
  level: string
  datasource: string
}

interface Metadata {
  [level: string]: {
    configId: string
    limit: number
    skip: number
    markets: string[]
  }
}

interface MetadataMarketItem {
  [market: string]: {
    processDate: string
    fetched: boolean
  }[]
}

interface InitializeReturnValue {
  metadataByDatasource: Metadata
  totalProcessDatesCount?: number
}

interface ReturnValue {
  initialize: Function
  loadMore: Function
  isInitialized: boolean
}

/**
 * All unique process date & market combinations grouped by datasource
 * @param data List of unique processTime & market
 * @param limit
 * process date grouped by market & total count of process dates
 */
function onInit(
  data: UniqueProcessDateAndMarketItem[][],
  limit: number,
): Metadata {
  const flatten = data.reduce((memo, current) => {
    memo.push(...(current as UniqueProcessDateAndMarketItem[]))
    return memo
  }, [])

  const mapper = flatten.reduceRight((memo, current) => {
    const { datasource, market, process_time: processTime } = current
    if (!memo[datasource]) {
      memo[datasource] = {}
    }

    if (!memo[datasource][market]) {
      memo[datasource][market] = []
    }

    memo[datasource][market].push({
      processDate: processTime,
      fetched: false,
    })
    return memo
  }, {})

  Object.entries(mapper).forEach((item) => {
    const [key, values] = item
    mapper[key] = {
      ...mapper[key],
      limit,
      skip: 0,
      markets: Object.keys(values as { [key: string]: MetadataMarketItem[] }),
    }
  })

  return mapper
}

/**
 * React Custom Hook for Available Load Forecast
 * @param loadForecastType Forecast Type: This is use on database query
 * @returns React Custom Hook for Available Load Forecast
 */
const useAvailableLoadForecasts = (
  loadForecastType: ForecastType,
): ReturnValue => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [forecastType] = useState<ForecastType>(loadForecastType)
  const [metadataByDatasource, setMetadataByDatasource] = useState<Metadata>({})

  async function loadMore(
    datasource: string,
    options: LoadMoreOptions = {},
  ): Promise<{}> {
    if (!datasource) {
      return {
        canLoadMore: false,
        items: [] as AvailableForecastItem[],
      }
    }
    const { timezone, limit } = options

    const { minISODate, maxISODate } = await getDruidDatasourceTimeBoundary(
      datasource,
    )

    const forecastStartDate = DateTime.fromISO(minISODate).toISODate()
    const forecastEndDate = DateTime.fromISO(maxISODate).toISODate()

    const results = await Promise.all(
      metadataByDatasource[datasource].markets.map(async (market: string) => {
        const { limit: metadataLimit } = metadataByDatasource[datasource]
        const filteredProcessDates = metadataByDatasource[datasource][market]
          .filter((v) => v.fetched === false)
          .slice(0, limit || metadataLimit)
          .map((v) => v.processDate)

        if (!filteredProcessDates.length) {
          return {
            items: [],
            canLoadMore: false,
          }
        }

        const items = await fetchAvailableForecastItems(
          datasource,
          market,
          filteredProcessDates,
          forecastStartDate,
          forecastEndDate,
          forecastType,
          timezone,
        )

        setMetadataByDatasource((previous) => {
          previous[datasource].skip += previous[datasource].limit
          previous[datasource][market].forEach((element) => {
            if (
              filteredProcessDates.includes(element.processDate) &&
              !element.fetched
            ) {
              element.fetched = true
            }
          })
          return { ...previous }
        })

        const canLoadMore = metadataByDatasource[datasource][market].some(
          (v) => !v.fetched,
        )

        return { items, canLoadMore }
      }),
    )

    return {
      canLoadMore: !results.every((v) => !v.canLoadMore),
      items: results.reduce(
        (memo, current) => [
          ...memo,
          ...(current.items as AvailableForecastItem[]),
        ],
        [] as AvailableForecastItem[],
      ),
    }
  }

  async function initialize(
    levels: Level[],
    limit: number = 5,
  ): Promise<InitializeReturnValue> {
    if (isInitialized) {
      return { metadataByDatasource }
    }

    const columns = ['market', 'process_time']
    const distinctResult: UniqueProcessDateAndMarketItem[][] = await Promise.all(
      levels.map(async ({ datasource, id, name, configId }) => {
        if (!datasource) {
          throw new Error(`ERROR: No Datasource provided for Level: ${name}`)
        }
        const distinctData = await getDruidDistinctAll(datasource, columns)
        return distinctData.map((value) => {
          return {
            ...value,
            datasource,
            configId,
            level: id,
          } as UniqueProcessDateAndMarketItem
        })
      }),
    )

    const metadata = onInit(distinctResult, limit)
    setMetadataByDatasource(metadata)
    setIsInitialized(true)

    return {
      metadataByDatasource: metadata,
    }
  }

  return {
    initialize,
    loadMore,
    isInitialized,
  }
}

export default useAvailableLoadForecasts
