import { AppBar, Box, Typography } from '@material-ui/core'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import {
  Body,
  Header,
  Options,
  PageTitle,
  StyledTab,
  StyledTabGroup,
} from 'shared/components/PageLayout'
import {
  AdjustmentType,
  ForecastType,
  Level,
  Forecast,
  Adjustment,
} from '../../../modules/demand/products/loadForecast/components/LoadForecastTypes'
import AlertsContext from '../../contexts/AlertsContext'
import DashboardsConfigsContext from '../../contexts/DashboardsConfigsContext'
import SummaryItemsReducer, {
  SummaryItemsStateType,
} from './reducers/SummaryItemsReducer'
import { DashboardConfigItem } from '../../types'
import SummaryList from './SummaryList/SummaryList'

interface Props {
  title: string
  errorMessage?: string
  pageActions?: ReactElement
  renderCard: (item) => ReactElement
  renderCardSkeleton: (index) => ReactElement
  dataFunctions: {
    [id: string]: {
      initialize: Function
      loadMore: Function
      process: Function
    }
  }
  tabCategories: {
    title: string
    category: ForecastType | AdjustmentType
  }[]
}

const SummaryPage = ({
  title,
  pageActions,
  renderCard,
  renderCardSkeleton,
  dataFunctions,
  errorMessage,
  tabCategories,
}: Props) => {
  const publishAlert = useContext(AlertsContext)
  const dashboardsConfigsContext = useContext(DashboardsConfigsContext)

  const [hasData, setHasData] = useState(
    tabCategories.map((tc) => ({
      [tc.category]: false,
    })),
  )

  const initialState: SummaryItemsStateType = {
    isInitialized: false,
    marketsAreInitialized: false,
    loading: false,
    canLoadMore: tabCategories.reduce((acc, tab) => {
      acc[tab.category] = true
      return acc
    }, {}),
    filteredItems: tabCategories.reduce((acc, tab) => {
      acc[tab.category] = []
      return acc
    }, {}),
    filteredLoadMore: false,
    availableMarkets: tabCategories.reduce((acc, tab) => {
      acc[tab.category] = {}
      return acc
    }, {}),
    selectedMarkets: {},
    availableLevels: tabCategories.reduce((acc, tab) => {
      acc[tab.category] = []
      return acc
    }, {}),
    selectedLevel: {},
    items: tabCategories.reduce((acc, tab) => {
      acc[tab.category] = {}
      return acc
    }, {}),
  }

  const [tab, setTab] = useState(
    parseInt(localStorage.getItem('selectedTab') || '0', 10) || 0,
  )

  const handleTabChange = (e, newValue: number) => {
    setTab(newValue)
    localStorage.setItem('selectedTab', newValue.toString())
  }

  const [summaryState, dispatch] = useReducer(SummaryItemsReducer, initialState)

  const {
    loading,
    isInitialized,
    selectedMarkets,
    availableMarkets,
    selectedLevel = '1',
    availableLevels,
    filteredItems,
    filteredLoadMore,
    items,
    canLoadMore,
  } = summaryState

  const onGetItems = async (
    category: ForecastType | AdjustmentType,
    level?: string,
  ) => {
    dispatch({ type: 'SET_LOADING', value: true })

    try {
      const {
        items: newItems,
        canLoadMore: newCanLoadMore,
      } = await dataFunctions[category].loadMore(
        level || selectedLevel[category],
      )

      const newProcessedItems = dataFunctions[category].process(
        newItems,
        availableLevels[category].find(
          (l) => l.datasource === (level || selectedLevel[category]),
        ),
      )

      // Init if not already populated, append if it is
      const alreadyHasItems =
        items[category] && items[category][level || selectedLevel[category]]
      if (!alreadyHasItems) {
        dispatch({
          type: 'INIT_ITEMS_FOR_LEVEL',
          category,
          canLoadMore: newCanLoadMore,
          items: newProcessedItems,
        })
      } else {
        dispatch({
          type: 'UPDATE_ITEMS_FOR_LEVEL',
          category,
          canLoadMore: newCanLoadMore,
          items: newProcessedItems,
        })
      }
      setHasData({ ...hasData, [category]: true })
    } catch (e) {
      publishAlert(e.message, 'error')
    } finally {
      dispatch({ type: 'SET_LOADING', value: false })
    }
  }

  const onSetSelectedLevel = async (category, level) => {
    dispatch({ type: 'SET_SELECTED_LEVEL', level, category })
    // If the selected level updates we need to ensure we have it the items and markets initialised
    if (!items[category][level]) {
      await onGetItems(category, level)
    }
  }

  /**
   * Sets the visible property on a market keyed list of items to show/hide
   * @param category - Forecast/Adjustment type
   * @param marketList - string list of markets to display
   */
  const onSetSelectedMarkets = async (
    category: ForecastType | AdjustmentType,
    marketList: string[],
  ) => {
    dispatch({
      type: 'SET_SELECTED_MARKETS',
      category,
      markets: marketList,
    })
  }

  const initialize = async () => {
    const initPromises = tabCategories.map(async (tc) => {
      const { category } = tc
      let configCategory = category
      if (
        configCategory === AdjustmentType.Approved ||
        configCategory === AdjustmentType.Draft
      ) {
        // For adjustments, we need to set it to short term forecast to get the right data sources
        configCategory = ForecastType.ShortTermForecast
      }
      const config: DashboardConfigItem[] =
        dashboardsConfigsContext.configs
          .find(({ dashboardType }) => dashboardType === 'prod_load_forecast')
          ?.items.filter(({ commodity, category: c, isSettled }) => {
            if (c.name === ForecastType.ShortTermForecast) {
              return (
                commodity.name === 'power' &&
                c.name === configCategory &&
                isSettled
              )
            }
            if (c.name === ForecastType.LongTermForecast) {
              return commodity.name === 'power' && c.name === configCategory
            }
            return false
          }) ?? []
      const levels = config.map((dc: DashboardConfigItem) => {
        return {
          id: dc.datasource,
          name: dc?.level?.label,
          datasource: dc.datasource,
          configId: dc.configId,
        } as Level
      })

      // Init the levels
      dispatch({
        type: 'SET_AVAILABLE_LEVELS',
        category,
        levels,
      })

      const initRes = await dataFunctions[category].initialize(levels)

      dispatch({
        type: 'SET_AVAILABLE_MARKETS',
        category,
        marketsByLevel: initRes.metadataByDatasource,
      })

      return initRes
    })
    await Promise.all(initPromises)
  }

  useEffect(() => {
    if (dashboardsConfigsContext.isLoading) {
      return
    }
    initialize().then(() => {
      dispatch({ type: 'SET_INITIALIZED', value: true })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardsConfigsContext])

  const renderSummaryList = (category: ForecastType | AdjustmentType) => {
    // Ensures this list always renders in the correct (newest to oldest) order
    let sortedItems: Forecast[] | Adjustment[] = []
    if (filteredItems[category]) {
      sortedItems = filteredItems[category].sort((a, b) => {
        if (
          category === ForecastType.ShortTermForecast ||
          ForecastType.LongTermForecast
        ) {
          return b.processTime - a.processTime
        }
        return b.updatedAt - a.updatedAt
      })
    }
    return (
      <SummaryList
        key={category}
        renderCard={renderCard}
        renderCardSkeleton={renderCardSkeleton}
        canLoadMore={
          items[category] &&
          selectedLevel[category] &&
          canLoadMore[category][selectedLevel[category]]
        }
        items={sortedItems}
        isLoading={loading}
        isInitialized={isInitialized}
        hasData={hasData && hasData[category]}
        availableMarkets={
          availableMarkets[category] &&
          availableMarkets[category][selectedLevel[category]]
        }
        selectedMarkets={
          selectedMarkets[category] &&
          selectedMarkets[category][selectedLevel[category]]
        }
        availableLevels={availableLevels[category]}
        selectedLevel={selectedLevel[category]}
        onLoadMore={() => onGetItems(category)}
        onSelectLevel={(value) => onSetSelectedLevel(category, value)}
        onSelectMarkets={(value) => onSetSelectedMarkets(category, value)}
        filteredLoadMore={filteredLoadMore}
      />
    )
  }

  return (
    <>
      <Header>
        <PageTitle>
          <Typography variant="h4">{title}</Typography>
          <Box display="flex" flexGrow="1" />
          {pageActions}
        </PageTitle>
      </Header>
      {errorMessage && (
        <Typography variant="caption" component="div" color="error">
          {errorMessage}
        </Typography>
      )}
      {tabCategories.length > 1 ? (
        <Options>
          <AppBar position="static" color="transparent" elevation={0}>
            <StyledTabGroup
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
            >
              {tabCategories.map((t) => (
                <StyledTab disabled={loading} key={t.title} label={t.title} />
              ))}
            </StyledTabGroup>
          </AppBar>
        </Options>
      ) : (
        ''
      )}
      <Body>
        {tabCategories.map((t, index) => {
          if (tabCategories.length === 1 || tab === index) {
            return renderSummaryList(t.category)
          }
          return ''
        })}
      </Body>
    </>
  )
}

export default SummaryPage
