import React, { ReactElement } from 'react'
import { Box, Card, styled, withStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const OptionSelectSpan = styled('span')(() => ({
  display: 'flex',
  width: '100%',
  marginRight: '24px',
  '& > *': {
    marginRight: '16px',
    maxWidth: '300px',
  },
}))

export const RoundedSkeleton = withStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
}))(Skeleton) as typeof Skeleton

const SkeletonListItemsContainer = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gridArea: 'body',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))(Box) as typeof Box

export const SkeletonOptions = () => {
  return (
    <Box display="flex" flexDirection="row">
      <OptionSelectSpan>
        <Box width="100%">
          <Skeleton variant="text" height={20} width={50} />
          <RoundedSkeleton
            variant="rect"
            animation="pulse"
            width="100%"
            height={40}
          />
        </Box>
        <Box width="100%">
          <Skeleton variant="text" height={20} width={50} />
          <RoundedSkeleton
            variant="rect"
            animation="pulse"
            width="100%"
            height={40}
          />
        </Box>
      </OptionSelectSpan>
      <RoundedSkeleton
        variant="rect"
        animation="pulse"
        width={200}
        height={40}
      />
    </Box>
  )
}

export const StyledSummaryCard = withStyles((theme) => ({
  root: {
    width: 275,
    minHeight: 355,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(Card) as typeof Card

export const renderSkeletonListItems = (
  listItemSkeleton: (index) => ReactElement,
  quantity: number,
) => {
  return (
    <SkeletonListItemsContainer>
      {Array.from({ length: quantity }, () => Math.random()).map(
        listItemSkeleton,
      )}
    </SkeletonListItemsContainer>
  )
}
