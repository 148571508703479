import React, { useContext, ReactEventHandler, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import UserContext from 'shared/contexts/UserContext'
import { styled, makeStyles } from '@material-ui/core/styles'
import {
  ClickAwayListener,
  Button,
  Avatar,
  IconButton,
  Popover,
  MenuItem,
  AppBar,
  Toolbar,
  Badge,
} from '@material-ui/core'
import { NotificationsNone } from '@material-ui/icons'
import useClickedElement from 'shared/hooks/useClickedElement'

const { PUBLIC_URL } = process.env

const imageurl = `${PUBLIC_URL}/images/ease-logo.svg`

export interface Props {
  /**
   * Handles the logout functionality of the applicaation
   */
  onLogout: ReactEventHandler
  /**
   * Indicates number of notifications that are new or pending to be read by the user
   *
   * eg:- ```notifications={5}```
   */
  notifications: number
  /**
   * Function to handle the notification icon click.
   *
   * This action opens up the Notification panel to view or read new/unread notifications
   */
  onNotificationsButtonClick: ReactEventHandler
  /**
   * Page menu options for a particular module would be passed as children.
   *
   * Each child node in the menu options is a NavLink button pointing to a particular screen inside that module.
   *
   * eg:- JSX element, image, text, etc.
   */
  children: ReactNode
}

const StyledProductBar = styled(Toolbar)({
  alignItems: 'center',
  display: 'flex',
})

const StyledUserSection = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
})

const useStyles = makeStyles((theme) => ({
  logoButton: {
    padding: '0px',
    marginRight: theme.spacing(1),
  },
}))

/**
 * This is the top nav bar component which would be universal to all screens throughout the application.
 *
 * Consists of the logo, module menu options (children) and the user section.
 *
 * When a module is not selected, the Product Bar will not display any informations related to the modules i.e. module menu options sectin will be empty.
 *
 * Once, a module is selected, then the Product Bar displays the Module Menu options related to the selected module.
 *
 * eg:- If user selects the pricing module, then the Product Bar will display menu options for the Pricing module else that section on the Product Bar will be empty.
 */
const ProductBar = ({
  onLogout,
  notifications,
  onNotificationsButtonClick,
  children,
}: Props) => {
  const classes = useStyles()
  const user = useContext(UserContext)
  const twoLetters = user.name.substring(0, 2).toLowerCase()

  const [anchorEl, openMenu, closeMenu] = useClickedElement()

  return (
    <AppBar title="EASE 2.0" position="static" color="transparent">
      <ClickAwayListener onClickAway={closeMenu}>
        <StyledProductBar>
          <Button className={classes.logoButton} component={NavLink} to="/">
            <img src={imageurl} alt="ease logo" />
          </Button>
          {children}
          <StyledUserSection>
            <IconButton onClick={onNotificationsButtonClick}>
              <Badge badgeContent={notifications} color="primary">
                <NotificationsNone />
              </Badge>
            </IconButton>
            <IconButton size="small" onClick={openMenu}>
              <Avatar>{twoLetters}</Avatar>
            </IconButton>
          </StyledUserSection>
        </StyledProductBar>
      </ClickAwayListener>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem disabled>Settings</MenuItem>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Popover>
    </AppBar>
  )
}

export default ProductBar
