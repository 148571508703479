import { useMemo, useState } from 'react'
import {
  CardDefinition,
  DataSeries,
  AsyncData,
  DataSeriesData,
  isDataSeriesData,
} from 'shared/types'
import useCachedQueries from './useCachedQueries'

/**
 * Interface of a data provider to use to generate the different keys
 * for the cards and the dataseries provided, and also fetch the proper
 * data for them and prepare it for its visualization.
 */
export interface DataProvider<T extends object, U = any> {
  /**
   * Obtains the object to use as the main argument to fetch data for
   * this specific card's dataseries.
   * @param card
   * @param dataSeries
   */
  getArgumentToFetch(card: CardDefinition, dataSeries: DataSeries): T | null
  /**
   * Retrieves the appropriate data for an specific object returned by
   * "getArgumentToFetch". This method will be executed once per unique
   * object provided.
   * @param fetchingArgument
   */
  fetchData(fetchingArgument: T): Promise<U>
  /**
   * FIXME: Document
   * @param groupedFetchedData
   * @param groupingKey
   */
  mergeGroupedFetchedData?(
    groupingKey: string,
    groupedFetchedData: Array<U>,
    groupedArguments: Array<T>,
    dataSeries: Array<DataSeries>,
    card: CardDefinition,
  ): U
  /**
   * Optional method to ensure that the fetched data has the proper format to
   * be displayed on a card.
   * @param fetchedKeyData
   * @param card
   * @param dataSeries
   */
  prepareFetchedData?(
    fetchedKeyData: U,
    card: CardDefinition,
    dataSeries: DataSeries,
  ): DataSeriesData
  /**
   * Optional value to further differentiate between providers.
   */
  key?: any
}

/**
 * Generates both the array of unique keys for all the cards dataseries
 * and also a dictionary of all processed dataseries and their returned keys.
 * @param cards
 * @param provider
 */
function generateKeys(
  cards: Array<CardDefinition>,
  provider: DataProvider<any, any>,
): {
  keys: Array<string>
  groupKeys: Array<string>
  dataSeriesKeys: Map<DataSeries, string | null>
  dataSeriesErrors: Map<DataSeries, Error>
} {
  const keySet = new Set<string>()
  const groupKeySet = new Set<string>()
  const dataSeriesKeys = new Map<DataSeries, string | null>()
  const dataSeriesErrors = new Map<DataSeries, Error>()

  // Process all dataseries at first level
  cards.forEach((card) => {
    card.dataSeries.forEach((dataSeries) => {
      try {
        const { groupedItems } = dataSeries
        if (!groupedItems) {
          /* A Dataseries with no grouped items generates
        a single fetching key and it is stored its relation
        with either its dataseries or its owner group key */
          const fetchArgument = provider.getArgumentToFetch(card, dataSeries)
          const key =
            fetchArgument != null ? JSON.stringify(fetchArgument) : null
          if (key !== null) {
            keySet.add(key)
          }
          dataSeriesKeys.set(dataSeries, key)
        } else {
          /* Dataseries with grouped items only add its items
        to the queue so the process continues until the ungrouped
        dataseries get processed */
          const {
            key: groupIdentifier,
            dataSeries: groupedDataSeries,
          } = groupedItems

          if (groupedDataSeries.length > 0) {
            const groupedKeys: Array<string> = []

            groupedDataSeries.forEach((groupedItem) => {
              const fetchArgument = provider.getArgumentToFetch(card, {
                ...groupedItem,
                selectedCalculation: groupedItem.selectedCalculation,
                selectedSplit: dataSeries.selectedSplit,
                selectedFilters: dataSeries.selectedFilters,
              })
              if (fetchArgument !== null) {
                groupedKeys.push(JSON.stringify(fetchArgument))
              }
            })

            let groupKey: string | null = null
            if (groupedKeys.length === groupedDataSeries.length) {
              groupedKeys.forEach((key) => {
                keySet.add(key)
              })
              groupKey = JSON.stringify([
                groupIdentifier,
                groupedKeys,
                groupedDataSeries,
                card,
              ])
            }
            if (groupKey !== null) {
              groupKeySet.add(groupKey)
            }
            dataSeriesKeys.set(dataSeries, groupKey)
          }
        }
      } catch (error) {
        dataSeriesErrors.set(dataSeries, error)
      }
    })
  })

  return {
    keys: Array.from(keySet),
    groupKeys: Array.from(groupKeySet),
    dataSeriesKeys,
    dataSeriesErrors,
  }
}

/**
 * Hook that will generate the appropriate "getData" functions for
 * the cards provided.
 * The hook expects a "provider" object so the different
 * dataseries of the cards can have its external data fetched
 * and (optionally) prepared to be used on the cards.
 *
 * This hook will cache the data for every different dataseries based on
 * the data necessary to fetch it, so usually no unnecessary requests will be done
 * for dataseries on different cards that require the same data.
 *
 * The process of generating these functions will
 * run every time the cards collection change (a card is created,
 * deleted or modified) or the manager instance is modified.
 *
 * The hook will return an object with a function to obtain the proper "getData"
 * function for any given card, and also a "clearCache" function so the currently stored data
 * can be refreshed imperatively if needed.
 */
export default function useCardsDataProviders<T extends object, U = any>(
  cards: Array<CardDefinition>,
  manager: DataProvider<T, U>,
) {
  const [groupCache, setGroupCache] = useState<{
    results: { [key: string]: AsyncData<U> }
  }>({
    results: {},
  })

  // Generate keys from cards dataseries and settings
  const { keys, groupKeys, dataSeriesKeys, dataSeriesErrors } = useMemo(
    () => generateKeys(cards, manager),
    [cards, manager],
  )

  // Fetches and caches for the keys given the settings
  const { clearCache, results } = useCachedQueries(keys, (key) => {
    return manager.fetchData(JSON.parse(key))
  })

  const { mergeGroupedFetchedData } = manager
  groupKeys.forEach((groupKey) => {
    if (!groupCache.results[groupKey]) {
      groupCache.results[groupKey] = { data: null, error: null }
    }
    const { data, error } = groupCache.results[groupKey]
    if (data === null && error === null) {
      if (!mergeGroupedFetchedData) {
        groupCache.results[groupKey].error = new Error(
          `Grouping is not supported`,
        )
      } else {
        const [
          groupedItemsKey,
          groupedKeys,
          groupedDataSeries,
          groupedCard,
        ] = JSON.parse(groupKey) as [
          string,
          Array<string>,
          Array<DataSeries>,
          CardDefinition,
        ]
        const dataList: Array<U> = []
        const errorList: Array<Error> = []
        groupedKeys.forEach((key) => {
          const asyncData = results[key]
          if (asyncData) {
            if (asyncData.data) {
              dataList.push(asyncData.data)
            } else if (asyncData.error) {
              errorList.push(asyncData.error)
            }
          }
        })
        if (dataList.length + errorList.length === groupedKeys.length) {
          if (dataList.length === groupedKeys.length) {
            try {
              groupCache.results[groupKey].data = mergeGroupedFetchedData(
                groupedItemsKey,
                dataList,
                groupedKeys.map<T>((x) => JSON.parse(x)),
                groupedDataSeries,
                groupedCard,
              )
            } catch (mergeError) {
              groupCache.results[groupKey].error = mergeError
            }
          } else {
            groupCache.results[groupKey].error = new Error(
              'One or more of the groups had an error while fetching',
            )
          }
        }
      }
    }
  })

  // Returns a high order function that generates the proper "getData" per card
  return {
    getDataProvider: (card: CardDefinition) => (
      dataSeries: DataSeries,
    ): AsyncData<DataSeriesData> => {
      const errorGeneratingKeys = dataSeriesErrors.get(dataSeries)
      if (errorGeneratingKeys) {
        return {
          error: errorGeneratingKeys,
          data: null,
        }
      }

      const key = dataSeriesKeys.get(dataSeries)

      /* If no key is found, it could either be because is literally "null" or there is
      no entry for the requested dataseries (undefined) */
      if (key == null) {
        /* A literal "null" value for the key means the data must be interpreted "in progress" */
        if (key === null) {
          return {
            data: null,
            error: null,
          }
        }
        /* Not having an entry for a dataseries is an illegal situation, an error is returned  */
        return {
          data: null,
          error: new Error(`No data found for this dataseries`),
        }
      }

      try {
        /* "useCachedQueries" hook ensures there will always be a value for existent keys, no need
        for additional conditions on "result" validity. */
        const result = (dataSeries.groupedItems ? groupCache.results : results)[
          key
        ]

        /* No data: either it is still in progress or it has an error. Only need to be returned then. */
        if (result.data === null) {
          return {
            data: null,
            error: result.error,
          }
        }

        const data =
          manager.prepareFetchedData?.(result.data, card, dataSeries) ??
          result.data

        /* For this hook to ensure that the data returned will h ave the proper format,
        an additional check is done */
        if (!isDataSeriesData(data)) {
          return {
            data: null,
            error: new Error(
              `The retrieved data isn't in a recognizable format.`,
            ),
          }
        }

        return {
          data,
          error: null,
        }
      } catch (error) {
        return { error, data: null }
      }
    },
    clearCache,
    clearGroupCache: (keysToClear?: string[]) => {
      const realKeysToClear =
        keysToClear && keysToClear.length
          ? keysToClear
          : Object.keys(groupCache.results)
      const newCachedResults = { ...groupCache.results }
      groupCache.results = newCachedResults

      realKeysToClear.forEach((key) => {
        const cachedResult = newCachedResults[key]
        if (cachedResult && (cachedResult.data || cachedResult.error)) {
          delete newCachedResults[key]
        }
      })
      setGroupCache(groupCache)
    },
  }
}
