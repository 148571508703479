import React from 'react'
import MainMenuButton from 'shared/components/MainMenuButton'
import loadForecastProductModuleConfig from 'modules/demand/products/loadForecast'
import demandAnalyticsProductModuleConfig from 'modules/demand/products/demandAnalytics'
import gridConfig from 'modules/demand/products/gridEdgeManagement'
import { ReactComponent as LoadForecastIcon } from 'icons/load-forecast-submodule.svg'
import { ReactComponent as GridEdgeManagementIcon } from 'icons/grid-edge-management-submodule.svg'
import { ReactComponent as DemandAnalyticsIcon } from 'icons/demand-icon.svg'
import { ApplicationProduct, ProductModuleButtonComponent } from 'shared/types'

const buttonProps = {
  label: 'Demand',
  name: 'demand',
  description:
    'Understand customer usage, manage meter level loads, and prepare your grid for a renewable future.',
}

export const DEMAND_PRODUCTS: Array<ApplicationProduct> = [
  {
    mod: 'mod_demand',
    product: 'prod_load_forecast',
    label: 'Load Forecast',
    path: '/demand/load-forecast',
    config: loadForecastProductModuleConfig,
    icon: <LoadForecastIcon />,
    active: false,
    isExternalLink: false,
  },
  {
    mod: 'mod_demand',
    product: 'prod_demand_analytics',
    label: 'Demand Analytics',
    path: '/demand/demand-analytics',
    config: demandAnalyticsProductModuleConfig,
    icon: <DemandAnalyticsIcon />,
    active: false,
    isExternalLink: false,
  },
  {
    mod: 'mod_demand',
    product: 'prod_grid_edge_management',
    label: 'Grid Edge Management',
    path: '/demand/grid-management',
    config: gridConfig,
    icon: <GridEdgeManagementIcon />,
    isExternalLink: false,
    active: false,
  },
]

const DemandButton = (({
  products,
}: {
  products: Array<ApplicationProduct>
}) => {
  const { label, name, description } = buttonProps

  return (
    <MainMenuButton
      label={label}
      description={description}
      products={products}
      name={name}
    />
  )
}) as ProductModuleButtonComponent

export default DemandButton
