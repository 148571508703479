import React, { DragEvent, createContext, useContext } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Collapsible from './Collapsible'
import MenuOption, { UnStyledList } from './MenuOption'

// TODO: defaultMenuIsOpen and isCollapsible should come from CollapsibleProps (via Omit<>) however storybook can't find those props (https://github.com/storybookjs/storybook/blob/next/addons/docs/docs/props-tables.md)

const NoData = () => <Typography variant="subtitle1">No Data</Typography>
const onDragContext = createContext<OnDragType>(() => {})

interface MenuOptionMeta {
  /** Unique option identifier (recommended identifier for onDrag drop events) (part of `onDrag` metadata object) */
  id: string
  /** Option title to display (part of `onDrag` metadata object) */
  title: string
  /** Option subtitle to display (part of `onDrag` metadata object) */
  subtitle?: string
  /** Any extra data that you may want to store can go here. It is recommended to keep it simple and not too large. */
  extras?: { [field: string]: any }
}

export interface MenuOptionProps extends MenuOptionMeta {
  /** Whether individual option is draggable */
  isDraggable?: boolean
}

export type OnDragType = (e: DragEvent, metadata: MenuOptionMeta) => any

const useStyles = makeStyles(() => ({
  menu: {
    // order of hover and active matters here... Otherwise active won't work
    '&:hover': {
      cursor: 'grab',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
}))

const DraggableMenuOption = ({
  title,
  subtitle,
  id,
  extras = {},
  isDraggable = true,
}: MenuOptionProps) => {
  const onDrag = useContext(onDragContext)
  const classes = useStyles()
  return (
    <MenuOption
      title={title}
      subtitle={subtitle}
      props={{
        draggable: isDraggable,
        className: classes.menu,
        onDragStart: (e) => {
          if (typeof onDrag === 'function') {
            onDrag(e, { id, title, subtitle, extras })
          }
        },
      }}
    />
  )
}

export interface DraggableInnerMenuProps {
  /** Menu item option - see MenuOptionProps for more information */
  options: Array<MenuOptionProps>
  /** Title to display in menu */
  title?: string
  /** Unique id for menu (helps with React keys) */
  id: string
  /** If is collapsible whether the menu should start open */
  defaultMenuIsOpen?: boolean
  /** Whether to allow menu to collapse. If true it will display the appropriate icon button based on type */
  isCollapsible?: boolean
}

const DraggableInnerMenu = ({
  options,
  isCollapsible = true,
  title,
  defaultMenuIsOpen = false,
}: DraggableInnerMenuProps) => {
  return (
    <Collapsible
      type="secondary"
      title={title}
      defaultMenuIsOpen={defaultMenuIsOpen}
      isCollapsible={isCollapsible}
    >
      <UnStyledList>
        {options.length > 0 ? (
          options.map((option) => (
            <DraggableMenuOption
              key={option.id}
              id={option.id}
              isDraggable={option.isDraggable}
              title={option.title}
              subtitle={option.subtitle}
              extras={option.extras}
            />
          ))
        ) : (
          <NoData />
        )}
      </UnStyledList>
    </Collapsible>
  )
}

export interface Props {
  /** Main menu title to display */
  title?: string
  /** Array of inner menus - see DraggableInnerMenusProps for more information */
  innerMenus: Array<DraggableInnerMenuProps>
  /** If is collapsible whether the menu should start open */
  defaultMenuIsOpen?: boolean
  /** Whether to allow menu to collapse. If true it will display the appropriate icon button based on type */
  isCollapsible?: boolean
  /** Callback with parameters `(DragEvent, metadata)` where `metadata = { id, subtitle, title, extras }` */
  onDrag: OnDragType
}

/**
 * Draggable Menu allows developers to build menus with draggable options. This component makes use of `Collapsible`. Using this component fairly simple, all it requires is an array of inner menus and options. See `Array<InnerMenus>` and `Array<MenuOptionProps>` for prop details.
 *
 *
 * ```javascript
 * Array<InnerMenus> = {
 *   id: string // Unique id for menu (helps with React keys)
 *   title?: string // Title to display in menu
 *   defaultMenuIsOpen?: boolean // If is collapsible whether the menu should start open => default = false
 *   isCollapsible?: boolean // Whether to allow menu to collapse => default = true
 *   options: Array<MenuOptionProps>
 * }
 *
 * Array<MenuOptionProps> = {
 *   id: string // Unique option identifier (recommended identifier for onDrag drop events) (part of `onDrag` metadata object)
 *   title: string // Option title to display (part of `onDrag` metadata object)
 *   subtitle: string // Option subtitle to display (part of `onDrag` metadata object)
 *   extras: object // Any extra data needing storage in the menu option
 *   isDraggable?: boolean // Whether you can drag the specific option => default = true
 * }
 * ```
 *
 * After much debate this component is just that... a draggable menu, since it only makes use of a "new and unique" component in the form of `DraggableMenuOption`. The rest are `Collapsible`s that map through some options. It is a fairly simple component that can be used throughout for drag and drop operations.
 * ### handling drag event
 * A `onDrag` callback is used as a global event listener to provide developers with the ability to transfer data to the drop area. The callback passes the drag event and option metadata as parameters.
 *
 * Transferring data to the drop area is left to the developer as the method may be vary from case to case. Suffice to say it is recommended to use the drag event's `dataTransfer.setData(format, data)` with the `text/x-typeofdata` format to transfer data to a drop area.
 */
const DraggableMenu = ({
  title,
  innerMenus,
  defaultMenuIsOpen = false,
  isCollapsible = true,
  onDrag,
}: Props) => {
  return (
    <Collapsible
      title={title}
      isCollapsible={isCollapsible}
      type="primary"
      defaultMenuIsOpen={defaultMenuIsOpen}
    >
      {/* Context is used here so as to not pass down all the way to grandchild...
        mainly so if a developer comes here it is clear that the innerMenu prop does not require onDrag as a prop
        ... semantics
      */}
      <onDragContext.Provider value={onDrag}>
        {innerMenus.length > 0 ? (
          innerMenus.map((menu) => (
            <DraggableInnerMenu
              key={`draggable-inner-${menu.id}`}
              options={menu.options}
              isCollapsible={menu.isCollapsible}
              title={menu.title}
              id={menu.id}
            />
          ))
        ) : (
          <NoData />
        )}
      </onDragContext.Provider>
    </Collapsible>
  )
}

export default DraggableMenu
