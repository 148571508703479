import {
  CardDefinition,
  DataSeries,
  DataSeriesData,
  DataSeriesSplitElement,
  DataSeriesType,
} from 'shared/types'
import crossfilter from 'crossfilter2'
import * as math from 'mathjs'

/**
 * Merges multiple data series. DataSeries must be grouped and split by same values.
 * The merge happens by evaulating a valid mathematical expression for every dataseries interval and split. Each dataseries in the expression must be denoted by an x and an ordered index.
 * For example, if I want to add DataSeries A and DataSeries B  the valid expression is: x0 + x1.
 * If i want to find the percent diff between these I can always calculate (x0 - x1) / x1
 */
export function mergeDataSeries(
  expression: string,
  data: DataSeriesData[],
  group: CardDefinition['group'] | 'Overall',
  splitIdentifier?: string,
): DataSeriesData {
  const groupBy = group ?? 'Overall'
  const props = new Set()
  /**
   * We first merge all dataseries inside all splits into one large flat array.
   * For each data series a special key is created (denoted by `x0, x1, x2, ...xn`).
   * These special keys represent the context for which we shall evaluate the expression
   */
  const flatArr = Object.values(data)
    .map((d, i) => {
      const { mainValueAccessor } = d
      const key = `x${i}`
      return d.splits
        .map((s) => {
          return s.seriesData.map((elem) => {
            props.add(key)
            return { [key]: elem[mainValueAccessor], ...elem }
          })
        })
        .flat()
    })
    .flat()

  /**
   * We then feed the flat array to crossfilter and group by split and main value accessor. The subsequent reducer combines the different dataseries into one object element
   * */
  const ndx = crossfilter(flatArr)
  const dimension = ndx
    .dimension((d) => {
      const keys = [d[groupBy]]
      if (splitIdentifier) {
        keys.push(d[splitIdentifier])
      }
      return keys.join('|')
    })
    .group()

  function sumContexts(p, v) {
    // eslint-disable-next-line no-param-reassign
    p[`x${p.count}`] = p[`x${p.count}`] + v[`x${p.count}`]
    return { ...p, count: p.count + 1 }
  }

  function reduceInitial(p, v, initialKeys) {
    const obj = {}
    initialKeys.forEach((elem) => {
      obj[elem] = 0
    }, {})
    return { ...obj, count: 0 }
  }
  function reduceRemove(p) {
    return { ...p, count: p.count - 1 }
  }

  const reduction = dimension
    .reduce(
      sumContexts,
      reduceRemove,
      // @ts-ignore
      (p, v) => reduceInitial(p, v, [...props]),
    )
    .all()

  // From the grouped array we evaluate the math expression
  const calculatedFlatArray = reduction.map((elem) => {
    const [reducerGroup, reducerSplit] = elem.key.toString().split('|')
    const groupKey = groupBy
    const obj = {
      value: math.evaluate(expression, elem.value as {}),
      [groupKey]: Number(reducerGroup) || reducerGroup,
    }
    if (splitIdentifier) {
      obj[splitIdentifier] = reducerSplit
    }

    return obj
  })

  // After evaluating each grouped value we transformt the flatten array to a DataSeriesSplitElement
  let splits: DataSeriesSplitElement[] = []
  if (!splitIdentifier) {
    splits = [
      {
        splitValue: null,
        groupedData: {
          value: calculatedFlatArray.reduce(
            (accum, prev) => accum + prev.value,
            0,
          ),
        },
        seriesData: calculatedFlatArray,
      },
    ]
  } else {
    const splitsObj = {}
    calculatedFlatArray.forEach((elem) => {
      const splitValue = elem[splitIdentifier]
      if (!splitsObj[splitValue]) {
        splitsObj[splitValue] = {
          splitValue,
          groupedData: { value: elem.value },
          seriesData: [elem],
        }
      } else {
        const groupedDataAccum = splitsObj[splitValue].groupedData.value
        splitsObj[splitValue].groupedData = {
          value: groupedDataAccum + elem.value,
        }
        splitsObj[splitValue].seriesData.push(elem)
      }
    })
    splits = Object.values(splitsObj)
  }

  return {
    splits,
    mainValueAccessor: 'value',
  }
}

export function areDataSeriesSameType(d1: DataSeries, d2: DataSeries) {
  if (d1.type === d2.type) {
    if (d1.type === DataSeriesType.Custom && d1.customUnit !== d2.customUnit) {
      return false
    }
    return true
  }
  return false
}
