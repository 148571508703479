import JoiBase from 'joi'
import JoiDate from '@hapi/joi-date'
import { DateTime } from 'luxon'
import { InputModel } from 'shared/types'

const fileDateFormat = 'YYYY-MM-DD'

/**
 * Generates the appropriate validators for Inputs created
 * on a table or directly read from a CSV file
 * @param marketOptions
 * @param isoOptions
 * @param profileOptions
 * @param edcOptions
 * @param productOptions
 */
export function createInputValidators(
  marketOptions: string[],
  isoOptions: string[],
  profileOptions: string[],
  edcOptions: string[],
  productOptions: string[],
) {
  /* Needed extension to validate dates in text format */
  const Joi = JoiBase.extend(JoiDate) as JoiBase.Root
  const meterIdValidation = Joi.string().alphanum().required()
  const commonFieldNameValidations = {
    market: Joi.string()
      .valid(...marketOptions)
      .required(),
    iso: Joi.string()
      .valid(...isoOptions)
      .required(),
    profile: Joi.string()
      .valid(...profileOptions)
      .required(),
    edc: Joi.string()
      .valid(...edcOptions)
      .required(),
    product: Joi.string()
      .valid(...productOptions)
      .required(),
  }
  const fileDateValidation = (Joi.date().raw() as any).format([fileDateFormat])
  const tableDateValidation = Joi.date()

  const fileInputSchema: JoiBase.Schema = Joi.object({
    meterId: meterIdValidation,
    ...commonFieldNameValidations,
    startDate: fileDateValidation.required(),
    endDate: fileDateValidation.min(Joi.ref('startDate')).required(),
    asOfDate: fileDateValidation.required(),
  }).unknown()

  const tableInputSchema: JoiBase.Schema = Joi.object({
    meterId: meterIdValidation,
    ...commonFieldNameValidations,
    startDate: tableDateValidation,
    endDate: tableDateValidation.min(Joi.ref('startDate')).required(),
    asOfDate: tableDateValidation.required(),
  })
  return {
    validateFileInput: (fileInput) => fileInputSchema.validate(fileInput),
    validateTableInput: (inputModel) => tableInputSchema.validate(inputModel),
  }
}

/**
 * Transforms an already validated Input parsed from a CSV file
 * to a proper InputModel object.
 * @param inputRow
 */
export function transformFileInputToTableInput(inputRow: {
  [field: string]: string
}): InputModel {
  const inputModel: InputModel = {
    meterId: inputRow.meterId,
    market: inputRow.market,
    profile: inputRow.profile,
    iso: inputRow.iso,
    edc: inputRow.edc,
    product: inputRow.product,
    startDate: DateTime.fromISO(inputRow.startDate).toJSDate(),
    endDate: DateTime.fromISO(inputRow.endDate).toJSDate(),
    asOfDate: DateTime.fromISO(inputRow.asOfDate).toJSDate(),
  }
  return inputModel
}

/**
 * Creates a comparable value to be used on comparisons from
 * an InputModel.
 *
 * TODO: May have to be improved if the Input model come to be dynamic
 * @param inputModel
 */
export function createUniqueInputValue(inputModel: InputModel) {
  /* Only the values for an InputModel are needed. They are extracted
  in case a more specific type is passed with extra unforeseen fields */
  const {
    meterId,
    market,
    iso,
    edc,
    product,
    profile,
    startDate,
    endDate,
    asOfDate,
  } = inputModel
  /* It's a good chance to store a more compact structure than a JSON that
  sill still ensure unique strings for comparisons */
  return JSON.stringify([
    meterId,
    market,
    iso,
    edc,
    product,
    profile,
    startDate,
    endDate,
    asOfDate,
  ])
}
