import React, { useEffect, useMemo, useState } from 'react'
import DraggableMenu, {
  DraggableInnerMenuProps,
} from 'shared/components/DraggableMenu'
import {
  DataInsightsFetchingArgument,
  fetchDataForScenario,
  getFetchingArgumentCommonValues,
  ScenariosFetchingArgument,
  weatherDataSeriesHandlers,
  WeatherFetchingArgument,
} from 'modules/demand/common/helpers'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import SideBarMenu from 'shared/components/SideBarMenu'
import { DateTime } from 'luxon'
import {
  Group,
  Dashboard,
  GeneralDateSelector,
  DataSeriesData,
  DynamicColumnsConfig,
  DataSeriesType,
  DataSeries,
  SelectedFilter,
  SingleSplitOption,
  DashboardConfigItem,
  SingleCalculationOption,
  WeatherTimeSeries,
  Calculation,
  WeatherStation,
  Visualization,
} from 'shared/types'
import { getLoadDataSeriesTypeByCommodity } from 'shared/helpers'
import { HandleSave } from 'shared/pages/DashboardDetail'
import DateSelector from 'shared/components/DateSelector'
import { setDataSeriesData } from 'shared/utils/dataSeriesDragAndDrop'
import BaseDashboard, {
  CardsDataSeriesManager,
  BuildCalculatorDataSeries,
} from 'shared/components/BaseDashboard/BaseDashboard'
import { calculationOptions } from 'shared/common/dashboardOptions'
import { mergeDataSeries } from 'shared/utils/dataSeriesHelpers'
import useTimeAndDimensionFilters from 'shared/hooks/useTimeAndDimensionFilters'
import { getDataSeriesTypeFromUnit, getDefinition } from 'shared/common/units'
import { getSelectedOptionLabel } from 'shared/components/Calculator/Calculator'
import useCancellableRequests from 'shared/hooks/useCancellableRequests'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import { nanoid } from 'nanoid'
import {
  getDruidDatasourceDistinctData,
  getDruidDistinctAll,
  getMinMaxForColumns,
} from '../../../common/apiClient'
import useDates from '../hooks/useDates'
import WeatherMenu from '../components/WeatherMenu'

const DEFAULT_SCENARIOS_CONFIG: DynamicColumnsConfig = {
  valueTypeColumn: 'Item',
  valueColumn: 'value',
}

interface ManagerWeatherType {
  kind: 'weather'
  argument: WeatherFetchingArgument
}

interface InsightsScenarioType {
  kind: 'insights'
  argument: DataInsightsFetchingArgument
}

export type CalculationTypes = 'load' | 'meterCount'

function createScenarioDataSeriesMenuItems(
  scenarioDataSeries: Array<string>,
): DraggableInnerMenuProps[] {
  return [
    {
      id: 'scenarioDataSeriesMenuOptions',
      isCollapsible: false,
      options: scenarioDataSeries.map((x) => ({ id: x, title: x })),
    },
  ]
}

function useScenarioDataSeriesMenuItems(
  dateRange: null | { from: DateTime; to: DateTime },
  datasource: string,
  scenariosConfig: DynamicColumnsConfig,
): { menuItems: DraggableInnerMenuProps[]; isLoading: boolean } {
  const [[menuItems, isLoading], setState] = useState<
    [DraggableInnerMenuProps[], boolean]
  >(() => [createScenarioDataSeriesMenuItems([]), true])

  useEffect(() => {
    if (dateRange !== null) {
      /* Retrieve the different dataseries */
      getDruidDatasourceDistinctData(
        datasource,
        scenariosConfig.valueTypeColumn,
        {
          columns: [],
          dateRange: {
            from: dateRange.from.toISODate(),
            to: dateRange.to.toISODate(),
          },
        },
      )
        .catch(() => {
          // TODO: Handle error more gracefully
          return [] as Array<string>
        })
        .then((dataSeries) => {
          setState([createScenarioDataSeriesMenuItems(dataSeries), false])
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange !== null])

  return {
    menuItems,
    isLoading,
  }
}

/**
 * Create manager defines how the component will get and process data for render
 */
function createManager(
  datasource: string,
  dateRange: { from: DateTime; to: DateTime } | null,
  scenariosConfig,
  selectedFilters: Array<SelectedFilter>,
  selectedTimezone: string,
  rangeFilters: Array<RangeFilter>,
  config,
): CardsDataSeriesManager<InsightsScenarioType | ManagerWeatherType | any> {
  const weatherDataProvider = weatherDataSeriesHandlers.createDataProvider({
    dateRange,
    timezone: selectedTimezone,
  })
  return {
    getArgumentToFetch(card, dataSeries) {
      let kind
      let argument
      if (dataSeries.type === DataSeriesType.Temperature) {
        kind = 'weather'
        argument = weatherDataProvider.getArgumentToFetch(card, dataSeries)
      } else {
        kind = 'load'
        argument = (() => {
          const { extras, spatialFilters } = dataSeries
          const extraArgs = extras?.groupColumnsForMap
            ? {
                scenarios: extras?.scenarios,
                groupColumnsForMap: extras?.groupColumnsForMap,
              }
            : { scenarios: extras?.scenarios }
          return dateRange
            ? {
                ...getFetchingArgumentCommonValues(
                  card,
                  dataSeries,
                  selectedFilters,
                  selectedTimezone,
                  rangeFilters,
                  spatialFilters,
                ),
                ...extraArgs,
              }
            : null
        })()
      }

      if (!argument) {
        return null
      }

      return {
        kind,
        argument,
      }
    },
    fetchData(fetchingArgument) {
      // Weather data needs to be fetched differently to Scenario Data
      if (fetchingArgument.kind === 'weather') {
        return weatherDataProvider.fetchData(fetchingArgument.argument)
      }
      return fetchDataForScenario(
        {
          fetchingArgument: fetchingArgument.argument,
          datasource,
          dateRange,
          scenariosConfig,
          timezone: selectedTimezone,
        },
        config,
      )
    },
    prepareFetchedData(fetchedData, card, dataSeries): DataSeriesData | any {
      if (dataSeries.groupedItems) {
        return fetchedData
      }
      const specificPrepareFetchedData =
        dataSeries.type === DataSeriesType.Temperature
          ? weatherDataProvider.prepareFetchedData
          : null
      // Only Weather data needs to be prepared before rendering
      if (specificPrepareFetchedData) {
        return (
          specificPrepareFetchedData?.(fetchedData, card, dataSeries) ??
          fetchedData
        )
      }
      /* The falsy value here prevents is being called in useCardsDataProvider.ts
       and tell it just to return the data unmodified rather than post process */
      return null
    },
    mergeGroupedFetchedData: (
      keyExpression,
      data,
      parsedKeys,
      dataSeries,
      card,
    ) => {
      const newData = parsedKeys.map((key, index) => {
        if (key.kind === 'weather' && weatherDataProvider.prepareFetchedData) {
          return weatherDataProvider.prepareFetchedData(
            data[index],
            card,
            dataSeries[index],
          )
        }
        return data[index]
      })
      const splitIdentifiersSet = new Set<undefined | string>()
      parsedKeys.forEach((k) => {
        if (k.kind === 'weather') {
          splitIdentifiersSet.add(undefined)
        } else {
          splitIdentifiersSet.add(k.argument.splitIdentifier)
        }
      })
      const splitIdentifiersArr = Array.from(splitIdentifiersSet)
      if (splitIdentifiersArr.length > 1) {
        throw new Error('Dataseries cannot be split')
      }
      const groupSet = new Set<string>()
      parsedKeys.forEach((k) => {
        const { argument } = k
        const { group } = argument
        if (group.length > 1) {
          throw new Error('Dataseries cannot have more than one group')
        }
        groupSet.add(group[0])
      })
      const groupArr = Array.from(groupSet)
      return mergeDataSeries(
        keyExpression,
        newData,
        groupArr[0],
        splitIdentifiersArr[0],
      )
    },
    correctCardDataSeries: (card, dataSeries) => dataSeries,
    key: dateRange,
  }
}

export interface Props {
  datasource: string
  scenariosConfig?: DynamicColumnsConfig
  filtersDefinitions: DashboardConfigItem['filters']
  savedDashboardInfo: Dashboard
  timeInterval: number
  weatherStations?: Array<WeatherStation>
  onSave: HandleSave
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
}

export const useScenarioAnalysis = ({
  datasource,
  scenariosConfig = DEFAULT_SCENARIOS_CONFIG,
  filtersDefinitions,
  savedDashboardInfo,
  timeInterval,
  weatherStations = [],
  rangeFiltersDefinition,
}: Omit<Props, 'onSave'>) => {
  const commodityType = savedDashboardInfo.commodity.name

  const defaultFrom: GeneralDateSelector = savedDashboardInfo.from || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'startData',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const defaultTo: GeneralDateSelector = savedDashboardInfo.to || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'endData',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const dates = useDates(datasource, defaultFrom, defaultTo)

  const filters = useTimeAndDimensionFilters(
    savedDashboardInfo.filters,
    timeInterval,
    () =>
      getDruidDistinctAll(
        datasource,
        filtersDefinitions.map((e) => e.name),
      ),
    filtersDefinitions,
    rangeFiltersDefinition,
    () =>
      getMinMaxForColumns(
        datasource,
        rangeFiltersDefinition?.map((e) => e.name) ?? [],
      ),
    savedDashboardInfo.rangeFilters,
    savedDashboardInfo.timeFilters,
  )

  const { dimensionFilters, timeFilters, rangeFilters } = filters

  const splitOptions = useMemo(() => {
    return dimensionFilters.filters.map(
      (filter) => [filter.name, filter.title] as SingleSplitOption,
    )
  }, [dimensionFilters.filters])

  const createCancellableRequestConfig = useCancellableRequests()

  const [selectedTimezone, setTimezone] = useState<string>(
    savedDashboardInfo?.timezone || DateTime.local().zoneName,
  )

  const [isHourEndingSelected, setIsHourEndingSelected] = useState<Boolean>(
    savedDashboardInfo?.isHourEndingSelected || false,
  )
  const [calculationType, setCalculationType] = useState<CalculationTypes>(
    'load',
  )

  const {
    menuItems: dataSeriesMenuItems,
    isLoading: areDataSeriesMenuItemsLoading,
  } = useScenarioDataSeriesMenuItems(
    dates.dateRange,
    datasource,
    scenariosConfig,
  )

  const [selectedStation, setWeatherStation] = useState(
    weatherStations[0] || '',
  )

  const manager = useMemo<
    CardsDataSeriesManager<ScenariosFetchingArgument, DataSeriesData>
  >(
    () =>
      createManager(
        datasource,
        dates.dateRange,
        scenariosConfig,
        [...dimensionFilters.selectedFilters, ...timeFilters.selectedFilters],
        selectedTimezone,
        rangeFilters.filters,
        createCancellableRequestConfig(),
      ),
    [
      createCancellableRequestConfig,
      datasource,
      dates.dateRange,
      scenariosConfig,
      dimensionFilters.selectedFilters,
      timeFilters.selectedFilters,
      selectedTimezone,
      rangeFilters.filters,
    ],
  )

  const calculatorDataSeriesOptions = useMemo(() => {
    const dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
    const dataSeriesOptions = dataSeriesMenuItems[0].options.map((option) => ({
      id: `forecast-${option.id}`,
      title: 'Load',
      subtitle: option.title,
      unit: getDefinition(dataSeriesType).defaultUnitValue,
      tagOptions: calculationOptions.map((calc) => ({
        value: calc[0],
        label: calc[1],
      })),
    }))
    const dataSeriesCountOptions = dataSeriesMenuItems[0].options.map(
      (option) => ({
        id: `meterCount-${option.id}`,
        title: 'Meter Count',
        subtitle: option.title,
        unit: getDefinition(DataSeriesType.Dimensionless).defaultUnitValue,
        tagOptions: [
          {
            value: Calculation.CountDistinct,
            label: 'countd',
          },
        ],
      }),
    )
    const weatherOptions = weatherStations
      ?.map((station) => {
        return Object.values(WeatherTimeSeries)
          .map((weatherType) => {
            return {
              id: `weather-${station.id}-${weatherType}`,
              title: `Weather ${station.label}`,
              subtitle: `${weatherType}`,
              unit: getDefinition(DataSeriesType.Temperature).defaultUnitValue,
              tagOptions: calculationOptions.map((calc) => ({
                value: calc[0],
                label: calc[1],
              })),
            }
          })
          .flat()
      })
      .flat()
    return [...dataSeriesOptions, ...weatherOptions, ...dataSeriesCountOptions]
  }, [dataSeriesMenuItems, commodityType, weatherStations])

  const buildCalculatedDataSeries: BuildCalculatorDataSeries = (
    calculator,
    referenceSeries,
  ) => {
    const newExpressionDataIdMap: object = {}
    const dataSeriesCombined: DataSeries[] = []
    let hasWeather = false
    Object.entries(calculator.expressionDataIdMap).forEach(([k, val]) => {
      const [type, id, weatherType] = val.id.split('-')
      const option = calculatorDataSeriesOptions.find((o) => o.id === val.id)
      const info = {
        id: '',
        label: '',
      }
      let dataSeriesType

      switch (type) {
        case 'forecast':
          info.id = id
          info.label = option?.title || ''
          dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
          break
        case 'meterCount':
          info.id = id
          info.label = option?.title || ''
          break
        default:
          info.id = `${weatherType}-${id}-temp`
          info.label = `${id} ${weatherType}`
          hasWeather = true
          dataSeriesType = DataSeriesType.Temperature
          break
      }

      newExpressionDataIdMap[k] = val.id
      const selectedCalculation = val.selectedOption
        ? ([val.selectedOption, val.selectedOption] as SingleCalculationOption)
        : undefined
      dataSeriesCombined.push({
        id: info.id,
        label: info.label,
        calculationOptions: type === 'meterCount' ? [] : calculationOptions,
        selectedCalculation,
        splitOptions,
        selectedSplit: null,
        type: dataSeriesType,
        selectedFilters: dimensionFilters.selectedFilters,
        extras:
          type === 'weather'
            ? {
                station: id,
                stationLabel: id,
                series: weatherType,
              }
            : {
                scenarios: [id],
              },
        rangeFilters: rangeFilters.filters,
      })
    })
    return {
      ...referenceSeries,
      id: calculator.uid,
      label: calculator.name,
      splitOptions: hasWeather ? undefined : splitOptions,
      selectedSplit: null,
      type: getDataSeriesTypeFromUnit(calculator.unit || ''),
      customUnit: calculator.unit || '',
      groupedItems: {
        expression: calculator.expression,
        expressionScope: newExpressionDataIdMap,
        expressionDataIdMap: calculator.expressionDataIdMap,
        dataSeries: dataSeriesCombined,
        key: calculator.expression,
      },
    }
  }
  return {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    selectedStation,
    setWeatherStation,
    dataSeriesMenuItems,
    areDataSeriesMenuItemsLoading,
    calculationType,
    setCalculationType,
  }
}

/**
 * Scenario page
 */
const ScenarioAnalysis = ({
  datasource,
  scenariosConfig = DEFAULT_SCENARIOS_CONFIG,
  filtersDefinitions,
  savedDashboardInfo,
  timeInterval,
  weatherStations = [],
  onSave,
  rangeFiltersDefinition,
}: Props) => {
  const commodityType = savedDashboardInfo.commodity.name

  const {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    dataSeriesMenuItems,
    areDataSeriesMenuItemsLoading,
    selectedStation,
    setWeatherStation,
    calculationType,
    setCalculationType,
  } = useScenarioAnalysis({
    datasource,
    scenariosConfig,
    filtersDefinitions,
    savedDashboardInfo,
    timeInterval,
    weatherStations,
    rangeFiltersDefinition,
  })

  const { min, max, isInitializing, from, to, setFromDate, setToDate } = dates

  const {
    dimensionFilters,
    timeFilters,
    rangeFilters,
    onChange: onFilterChange,
    isInitializing: areFiltersInitializing,
  } = filters

  const defaultCards = useMemo(() => {
    return scenariosConfig.hasMeterMap
      ? [
          ...savedDashboardInfo.cards,
          savedDashboardInfo.cards.find(
            (v) => v.visualization === Visualization.MeterMap,
          ) ?? {
            id: nanoid(),
            title: 'Meter Map',
            dataSeries: [],
            visualization: Visualization.MeterMap,
            group: scenariosConfig.meterIdColumn ?? null,
          },
        ]
      : savedDashboardInfo.cards
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseDashboard
      dashboardId={savedDashboardInfo.dashboardId}
      calculatorLabelAccessor={(option) => {
        const selectedOptionLabel = getSelectedOptionLabel(option)
        return [selectedOptionLabel, option.title, option.subtitle]
          .filter((e) => e)
          .join(' ')
      }}
      calculatorDataSeriesOptions={calculatorDataSeriesOptions}
      buildCalculatorDataSeries={buildCalculatedDataSeries}
      calculators={savedDashboardInfo.calculators}
      isInitializing={isInitializing || areFiltersInitializing}
      areFiltersLoading={false}
      isLoading={areDataSeriesMenuItemsLoading}
      cardsDataSeriesManager={manager}
      defaultCards={defaultCards}
      filtersDefinitions={filtersDefinitions}
      filters={dimensionFilters.filters}
      timeFilters={timeFilters.filters}
      onFilterChange={onFilterChange}
      selectedTimeFilters={timeFilters.selectedFilters}
      rangeFilters={rangeFilters.filters}
      onRangeFilterChange={rangeFilters.onChange}
      timeInterval={timeInterval}
      defaultNewCardGrouping={Group.Hourly}
      selectedTimezone={selectedTimezone}
      onTimezoneChange={(e, newTimezone) => setTimezone(newTimezone)}
      isHourEndingSelected={isHourEndingSelected}
      onIsHourEndingSelectedChange={(e, newIsHourEndingSelected) =>
        setIsHourEndingSelected(newIsHourEndingSelected)
      }
      meterGrouping={scenariosConfig?.meterIdColumn}
      dynamicColumnsConfig={scenariosConfig}
      onSave={(e, cards, extras) =>
        onSave(e, {
          ...extras,
          cards,
          filters: dimensionFilters.filters,
          timeFilters: timeFilters.filters,
          rangeFilters: rangeFilters.filters.map(({ name, value }) => ({
            name,
            value,
          })),
          timezone: selectedTimezone,
          isHourEndingSelected,
          from: from || undefined,
          to: to || undefined,
        })
      }
      extraOptions={
        min &&
        max &&
        from &&
        to && (
          <>
            <DateSelector
              startData={min}
              endData={max}
              quantity={from.quantity}
              multiplier={from.multiplier}
              identifier={from.identifier}
              dateType={from.dateType}
              value={from.value}
              onChange={setFromDate}
              label={`Start (${
                from.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
            <DateSelector
              startData={min}
              endData={max}
              quantity={to.quantity}
              multiplier={to.multiplier}
              identifier={to.identifier}
              dateType={to.dateType}
              value={to.value}
              onChange={setToDate}
              label={`End (${
                to.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
          </>
        )
      }
    >
      <SideBarMenu
        title="data series"
        isLoading={areDataSeriesMenuItemsLoading}
      >
        {scenariosConfig.meterIdColumn && (
          <FormControl>
            <InputLabel shrink htmlFor="seriesType">
              Type
            </InputLabel>
            <Select
              value={calculationType}
              name="seriesType"
              onChange={(e) => {
                setCalculationType(e.target.value as CalculationTypes)
              }}
              margin="dense"
            >
              {[
                { label: 'Load', value: 'load' as CalculationTypes },
                {
                  label: 'Meter Count',
                  value: 'meterCount' as CalculationTypes,
                },
              ].map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        <DraggableMenu
          isCollapsible={false}
          innerMenus={dataSeriesMenuItems}
          onDrag={(e, metadata) => {
            setDataSeriesData(e.dataTransfer, {
              label: metadata.title,
              id: metadata.id,
              type:
                calculationType === 'meterCount'
                  ? DataSeriesType.Dimensionless
                  : getLoadDataSeriesTypeByCommodity(commodityType),
              calculationOptions:
                calculationType === 'meterCount'
                  ? undefined
                  : calculationOptions,
              selectedCalculation:
                calculationType === 'meterCount'
                  ? [Calculation.CountDistinct, 'countd']
                  : calculationOptions[0],
              splitOptions,
              selectedSplit: null,
              extras: {
                scenarios: [metadata.id],
              },
            })
          }}
        />
      </SideBarMenu>
      {weatherStations?.length > 0 ? (
        <WeatherMenu
          showActual
          showForecast
          showNormal
          stations={weatherStations}
          onDrag={(e, { id, series, station }) => {
            weatherDataSeriesHandlers.prepareDatatransfer(
              e.dataTransfer,
              id,
              `${station.label} ${series}`,
              { station: station.id, series, stationLabel: station.label },
            )
          }}
          selectedStation={selectedStation}
          onStationChange={(e, station) => setWeatherStation(station)}
        />
      ) : (
        ''
      )}
    </BaseDashboard>
  )
}

export default ScenarioAnalysis
