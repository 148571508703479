import React, { SyntheticEvent } from 'react'
import JobActionsMenu from 'modules/customer/products/pricing/components/JobActionsMenu'
import JobTypeBadge from 'modules/customer/products/pricing/components/JobTypeBadge'
import { Job, JobTypes } from 'shared/types'
import ProgressBar, {
  ProgressBarProps,
} from 'shared/components/progressbar/ProgressBar'
import VirtualizedTable, { Column } from 'shared/components/VirtualizedTable'
import { DateTime } from 'luxon'

/*
TODO: It seems that these are going to be polled form the server,
but it hasn'¡t ben yet esblished how (for the organization? for each job?)
 */
const jobsMilestones = [
  {
    id: '1',
    name: 'Data Validation',
  },
  {
    id: '2',
    name: 'Get Data',
  },
  {
    id: '3',
    name: 'Calculation',
  },
  {
    id: '4',
    name: 'Persist Results',
  },
]

export interface Props {
  /**
   * Complele ordered list of jobs to be displayed on the table.
   *
   * It follows the inner defined interface `Job` (look on code)
   */
  jobs: Job[]
  /**
   * Callback to be invoked when the user requests a job to be canceled.
   */
  onJobCancel: (event: SyntheticEvent, job: Job) => void
  /**
   * Callback to be invoked when the user requests a job to be rerun.
   */
  onJobRerun: (event: SyntheticEvent, job: Job) => void
  /**
   * Callback to be invoked when the user requests to see the details of a specific job.
   */
  onJobDownload: (
    event: SyntheticEvent,
    job: Job,
    resultType: 'monthly_price' | 'summary_price',
  ) => void
}

/**
 * Table to visualize essential information of Pricing **Jobs** and perform certain commands
 * over them, such as cancelling, re-running or display their details.
 *
 * A virtualized table is used on the inner implementation, so high volume of data
 * should be supported without major inconvenience.
 */
const JobsTable = ({ jobs, onJobCancel, onJobDownload, onJobRerun }: Props) => {
  const columns: Column<Job>[] = [
    {
      header: 'Job type',
      render: (job: Job) => {
        const { jobType } = job
        return <JobTypeBadge jobType={jobType as JobTypes} />
      },
      width: 148 /* TODO: Check this arbitrary value */,
    },
    {
      header: 'Job name',
      render: ({ name }) => name,
    },
    {
      header: 'Date inititated',
      render: (job) => {
        const { initDatetime } = job
        return (
          /* TODO: Check localization and timezones. Until a strategy is defined, only
          UTC date times will be displayed for the sake of consistant tests */
          <span>
            {DateTime.fromJSDate(initDatetime).toLocaleString(
              DateTime.DATETIME_MED,
            )}
          </span>
        )
      },
    },
    {
      header: 'Contract Count',
      render: (job: Job) => job.contractCount,
      width: 148,
    },
    {
      header: 'Passed',
      render: (job: Job) => job.passedCount,
      width: 148,
    },
    {
      header: 'Failed',
      render: (job: Job) => job.failedCount,
      width: 148,
    },
    {
      header: 'Progress',
      render: (job) => {
        const { progress, initDatetime } = job
        const { endDatetime, status, message } = progress
        return (
          <ProgressBar
            isTaskComplete={!!endDatetime}
            defaultStatus="In Queue"
            progressIndex={status as number}
            milestones={jobsMilestones}
            taskCompleteInfo={
              endDatetime
                ? ({
                    status,
                    message,
                    completedAt: DateTime.fromJSDate(
                      initDatetime,
                    ).toLocaleString(DateTime.DATETIME_MED),
                  } as ProgressBarProps['taskCompleteInfo'])
                : undefined
            }
          />
        )
      },
    },
    {
      header: 'actions',
      render: (job: Job) => {
        const { progress } = job
        const { status, endDatetime } = progress
        return (
          <JobActionsMenu
            jobStatus={status}
            isCompleted={!!endDatetime}
            onCancelClick={(e) => onJobCancel(e, job)}
            onRerunClick={(e) => onJobRerun(e, job)}
            onDownloadClick={(e, resultType) =>
              onJobDownload(e, job, resultType)
            }
          />
        )
      },
      width: 150 /* TODO: Check with the UX Team on demo for a better value */,
    },
  ]
  return <VirtualizedTable items={jobs} columns={columns} />
}

export default JobsTable
