import React, { ReactNode } from 'react'
import {
  Paper,
  Typography,
  makeStyles,
  CircularProgress,
  useTheme,
} from '@material-ui/core'

export interface Props {
  /**
   * Text to be displayed at the top of the element
   */
  title: string
  /**
   * Content to be rendered below the title
   */
  children: ReactNode
  /**
   * Optional custom content to render after the title.
   * It will try to set itself to the rightmost place of the container.
   */
  customHeaderContent?: ReactNode
  /**
   * Value to indicate if a "loading" visual cue should be
   * displayed alongside the title
   */
  isLoading?: boolean
}

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(2),
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoColumns: 'auto',
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  // TODO: This title may become wayyy more general after convo with UX
  title: {
    textTransform: 'uppercase',
    color: theme.palette.grey[500],
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 500,
  },
}))

/**
 * Simple styled wrapper that represents a menu on the
 * side of the main content.
 *
 * It just renders any children provided below a title string.
 *
 * It has an specific width and
 * max height, and will enable vertical scrolling in case the
 * content exceeds it.
 */
const SideBarMenu = ({
  title,
  children,
  isLoading = false,
  customHeaderContent,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Paper className={classes.paper}>
      <header className={classes.header}>
        <Typography className={classes.title} variant="overline">
          {title}
        </Typography>
        {customHeaderContent}
        {isLoading && (
          <CircularProgress size={theme.typography.overline.fontSize} />
        )}
      </header>
      {children}
    </Paper>
  )
}

export default SideBarMenu
