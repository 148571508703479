import { useState, useEffect, DragEventHandler, DragEvent } from 'react'
/**
 * Hook to provide "drop zone" behavior to any component.
 * 
 * It will return an object with the shape:
 * 
 * <pre>
 * {
 *  // Value that indicates the element is having a 
 *  // dragged element being hovered over it
 *  isBeingDraggedOver,
 *  // Event handlers to inject directly to an HTML element so
 *  // it has the appropriate behavior
 *  onDrop,
    onDragOver,
    onDragEnter,
    onDragLeave,
 * }
 * </pre>
 * @param isDropzone
 * @param canDrop
 * @param onDrop
 */
function useDropzone(
  isDropzone: boolean,
  canDrop: (e: DragEvent) => boolean,
  onDrop: DragEventHandler,
) {
  const [isBeingDraggedOver, setIsBeingDraggedOver] = useState(false)
  const [allowDrop, setAllowDrop] = useState<boolean>(() =>
    typeof canDrop === 'function' ? true : canDrop,
  )
  useEffect(() => {
    if (typeof canDrop === 'boolean') setAllowDrop(canDrop)
  }, [canDrop])

  return {
    isBeingDraggedOver,
    allowDrop,
    onDrop: (e) => {
      // need to prevent this default for Firefox specifically (but probably a good measure regardless)
      e.preventDefault()
      setIsBeingDraggedOver(false)
      if (isDropzone && allowDrop && typeof onDrop === 'function') {
        onDrop(e)
      }
    },
    onDragOver: (e) => {
      e.preventDefault()
    },
    onDragEnter: (e) => {
      setIsBeingDraggedOver(true)
      if (typeof canDrop === 'function') {
        setAllowDrop(canDrop(e))
      }
    },
    onDragLeave: (e) => {
      if (!e.currentTarget.contains(e.relatedTarget as Node)) {
        setIsBeingDraggedOver(false)
      }
    },
  }
}

export default useDropzone
