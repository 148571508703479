import React, { useMemo, useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import {
  Group,
  Dashboard,
  GeneralDateSelector,
  DataSeriesType,
  DataSeries,
  SelectedFilter,
  SingleSplitOption,
  DashboardConfigItem,
  SingleCalculationOption,
  DynamicColumnsConfig,
  Calculation,
  WeatherStation,
  Visualization,
} from 'shared/types'
import {
  ForecastFetchingArgument,
  forecastsDataSeriesHandlers,
  getTimeSeriesItems,
  TimeSeriesItem,
  weatherDataSeriesHandlers,
  WeatherFetchingArgument,
  DataSeriesLabelItems,
} from 'modules/demand/common/helpers'
import { getLoadDataSeriesTypeByCommodity } from 'shared/helpers'
import { HandleSave } from 'shared/pages/DashboardDetail'
import AlertContext from 'shared/contexts/AlertsContext'
import WeatherAndLoadMenu from 'modules/demand/products/loadForecast/components/WeatherAndLoadMenu'
import DateSelector from 'shared/components/DateSelector'
import { getSelectedOptionLabel } from 'shared/components/Calculator/Calculator'
import BaseDashboard, {
  CardsDataSeriesManager,
  BuildCalculatorDataSeries,
} from 'shared/components/BaseDashboard/BaseDashboard'
import { getDataSeriesTypeFromUnit, getDefinition } from 'shared/common/units'
import { mergeDataSeries } from 'shared/utils/dataSeriesHelpers'
import { calculationOptions } from 'shared/common/dashboardOptions'
import useTimeAndDimensionFilters from 'shared/hooks/useTimeAndDimensionFilters'
import {
  getDruidDistinctAll,
  getMinMaxForColumns,
} from 'modules/demand/common/apiClient'
import { addNewDashboard, updateDashboard } from 'shared/apiClient'
import { AxiosRequestConfig } from 'axios'
import useCancellableRequests from 'shared/hooks/useCancellableRequests'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import AddNewDashboardDialog from 'shared/components/AddNewDashboardDialog'
import useDashboardConfigItems from 'shared/hooks/useDashboardConfigItems'
import useForecastsTimeSeries from '../hooks/useForecastsTimeSeries'
import useDates from '../hooks/useDates'

const MAX_NUMBER_OF_FORECASTS = 15

function getWeatherDataSeriesInfo(
  { id, title, subtitle, processTime }: TimeSeriesItem,
  stationLabel: string,
) {
  return {
    id,
    label: `${title} ${subtitle} ${stationLabel} Weather`,
    processTime,
    legendText: `${subtitle} ${stationLabel} Weather`,
  }
}

function getForectastDataSeriesInfo({
  id,
  title,
  subtitle,
  processTime,
}: TimeSeriesItem) {
  return {
    id,
    label: `${title} ${subtitle} Load`,
    legendText: `${subtitle} Load`,
    processTime,
  }
}

function getMeterCountDataSeriesInfo({
  id,
  title,
  subtitle,
  processTime,
}: TimeSeriesItem) {
  return {
    id,
    label: `${title} ${subtitle}`,
    legendText: `${subtitle} Meter Count`,
    processTime,
  }
}

interface ManagerWeatherType {
  kind: 'weather'
  argument: WeatherFetchingArgument
}

interface ManagerForecastType {
  kind: 'forecast'
  argument: ForecastFetchingArgument
}

function getCorrectedDataSeries(
  dataSeries: DataSeries,
  timeSeriesMap: Map<string, TimeSeriesItem>,
): DataSeries | null {
  const matchingTimeSeries = timeSeriesMap.get(dataSeries.id)

  if (
    dataSeries.extras?.visualizationType &&
    dataSeries.extras?.visualizationType === Visualization.MeterTable
  )
    return dataSeries

  if (matchingTimeSeries) {
    if (dataSeries.extras?.processTime !== matchingTimeSeries.processTime) {
      const { label, processTime, legendText } =
        dataSeries.type !== DataSeriesType.Temperature
          ? getForectastDataSeriesInfo(matchingTimeSeries)
          : getWeatherDataSeriesInfo(
              matchingTimeSeries,
              dataSeries.extras?.stationLabel,
            )
      return {
        ...dataSeries,
        label,
        extras: {
          ...dataSeries.extras,
          processTime,
          legendText,
        },
      }
    }
    return dataSeries
  }

  return null
}

function createManager(
  options: {
    selectedFilters: Array<SelectedFilter>
    datasource: string
    dateRange: { from: DateTime; to: DateTime } | null
    commodityType: string
    selectedTimezone: string
    areDataSeriesLoading: boolean
    timeSeries: TimeSeriesItem[]
    rangeFilters: Array<RangeFilter>
    dynamicColumnsConfig: DynamicColumnsConfig | undefined
  },
  config?: AxiosRequestConfig,
): CardsDataSeriesManager<ManagerForecastType | ManagerWeatherType> {
  const {
    selectedFilters,
    datasource,
    dateRange,
    commodityType,
    selectedTimezone,
    timeSeries,
    areDataSeriesLoading,
    rangeFilters,
    dynamicColumnsConfig,
  } = options
  const forecastsDataProvider = forecastsDataSeriesHandlers.createDataProvider(
    {
      selectedFilters,
      datasource,
      dateRange,
      isPerformance: false,
      type: getLoadDataSeriesTypeByCommodity(commodityType),
      timezone: selectedTimezone,
      rangeFilters,
      dynamicColumnsConfig,
    },
    config,
  )

  const weatherDataProvider = weatherDataSeriesHandlers.createDataProvider(
    {
      dateRange,
      timezone: selectedTimezone,
    },
    config,
  )

  const timeSeriesMap = new Map(timeSeries.map((x) => [x.id, x]))

  return {
    correctCardDataSeries:
      dateRange && !areDataSeriesLoading
        ? (card, dataSeries) => {
            return getCorrectedDataSeries(dataSeries, timeSeriesMap)
          }
        : undefined,
    getArgumentToFetch(card, dataSeries) {
      const kind =
        dataSeries.type !== DataSeriesType.Temperature ? 'forecast' : 'weather'

      const argument: any =
        kind === 'forecast'
          ? forecastsDataProvider.getArgumentToFetch(card, dataSeries)
          : weatherDataProvider.getArgumentToFetch(card, dataSeries)

      if (argument === null) {
        return null
      }

      return {
        kind,
        argument,
      }
    },
    fetchData(fetchingArgument) {
      if (fetchingArgument.kind === 'forecast') {
        return forecastsDataProvider.fetchData(fetchingArgument.argument)
      }
      return weatherDataProvider.fetchData(fetchingArgument.argument)
    },
    prepareFetchedData(fetchedData, card, dataSeries) {
      if (dataSeries.groupedItems) {
        return fetchedData
      }
      const specificPrepareFetchedData =
        dataSeries.type !== DataSeriesType.Temperature
          ? forecastsDataProvider.prepareFetchedData
          : weatherDataProvider.prepareFetchedData
      return (
        specificPrepareFetchedData?.(fetchedData, card, dataSeries) ??
        fetchedData
      )
    },
    mergeGroupedFetchedData: (
      keyExpression,
      data,
      parsedKeys,
      dataSeries,
      card,
    ) => {
      const newData = parsedKeys.map((key, index) => {
        if (key.kind === 'weather' && weatherDataProvider.prepareFetchedData) {
          return weatherDataProvider.prepareFetchedData(
            data[index],
            card,
            dataSeries[index],
          )
        }
        return data[index]
      })
      const splitIdentifiersSet = new Set<undefined | string>()
      parsedKeys.forEach((k) => {
        if (k.kind === 'weather') {
          splitIdentifiersSet.add(undefined)
        } else {
          splitIdentifiersSet.add(k.argument.splitIdentifier)
        }
      })
      const splitIdentifiersArr = Array.from(splitIdentifiersSet)
      if (splitIdentifiersArr.length > 1) {
        throw new Error('Dataseries cannot be split')
      }
      return mergeDataSeries(
        keyExpression,
        newData,
        card.group,
        splitIdentifiersArr[0],
      )
    },
    key: dateRange,
  }
}

export const useShortTermForecast = ({
  savedDashboardInfo,
  datasource,
  timeInterval,
  filtersDefinitions,
  rangeFiltersDefinition,
  dynamicColumnsConfig,
  weatherStations,
}: Omit<Props, 'onSave'>) => {
  const commodityType = savedDashboardInfo.commodity.name

  const defaultFrom: GeneralDateSelector = savedDashboardInfo.from || {
    quantity: -11,
    multiplier: 'days',
    identifier: 'endData',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const defaultTo: GeneralDateSelector = savedDashboardInfo.to || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'endData',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const dates = useDates(datasource, defaultFrom, defaultTo)

  const filters = useTimeAndDimensionFilters(
    savedDashboardInfo.filters,
    timeInterval,
    () =>
      getDruidDistinctAll(
        datasource,
        filtersDefinitions.map((e) => e.name),
      ),
    filtersDefinitions,
    rangeFiltersDefinition,
    () =>
      getMinMaxForColumns(
        datasource,
        rangeFiltersDefinition?.map((e) => e.name) ?? [],
      ),
    savedDashboardInfo.rangeFilters,
    savedDashboardInfo.timeFilters,
  )

  const splitOptions = useMemo(() => {
    return filters.dimensionFilters.filters.map(
      (filter) => [filter.name, filter.title] as SingleSplitOption,
    )
  }, [filters.dimensionFilters.filters])

  const [selectedTimezone, setTimezone] = useState<string>(
    savedDashboardInfo?.timezone || DateTime.local().zoneName,
  )

  const [isHourEndingSelected, setIsHourEndingSelected] = useState<Boolean>(
    savedDashboardInfo?.isHourEndingSelected || false,
  )

  const {
    forecasts,
    areForecastsLoading: areDataSeriesLoading,
  } = useForecastsTimeSeries(
    datasource,
    MAX_NUMBER_OF_FORECASTS,
    dates.dateRange,
  )

  const timeSeries = useMemo(
    () => getTimeSeriesItems(forecasts, selectedTimezone),
    [forecasts, selectedTimezone],
  )

  const [selectedStation, setWeatherStation] = React.useState(
    weatherStations ? weatherStations[0] : { id: '', label: '' },
  )

  const createCancellableRequestConfig = useCancellableRequests()

  const manager = useMemo<
    CardsDataSeriesManager<ManagerForecastType | ManagerWeatherType>
  >(
    () =>
      createManager(
        {
          selectedFilters: [
            ...filters.dimensionFilters.selectedFilters,
            ...filters.timeFilters.selectedFilters,
          ],
          datasource,
          dateRange: dates.dateRange,
          commodityType,
          selectedTimezone,
          areDataSeriesLoading,
          timeSeries,
          rangeFilters: filters.rangeFilters.filters,
          dynamicColumnsConfig,
        },
        createCancellableRequestConfig(),
      ),
    [
      createCancellableRequestConfig,
      areDataSeriesLoading,
      datasource,
      dates.dateRange,
      commodityType,
      filters.dimensionFilters.selectedFilters,
      filters.timeFilters.selectedFilters,
      selectedTimezone,
      timeSeries,
      filters.rangeFilters.filters,
      dynamicColumnsConfig,
    ],
  )

  /**
   * TODO: Extend options to weather. However for this to happen we need to re-evaluate how weather data gets stored.
   */
  const calculatorDataSeriesOptions = useMemo(() => {
    const timeSeriesOptions: any = []
    const weatherOptions: any = []
    const dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
    timeSeries.forEach((e) => {
      timeSeriesOptions.push({
        id: `forecast-${e.id}`,
        subtitle: `${e.subtitle}`,
        title: 'Forecast',
        unit: getDefinition(dataSeriesType).defaultUnitValue,
        tagOptions: calculationOptions.map((calc) => ({
          value: calc[0],
          label: calc[1],
        })),
      })
      timeSeriesOptions.push({
        id: `meterCount-${e.id}`,
        subtitle: `${e.subtitle}`,
        title: 'Meter Count',
        unit: getDefinition(DataSeriesType.Dimensionless).defaultUnitValue,
        tagOptions: [
          {
            value: Calculation.CountDistinct,
            label: 'countd',
          },
        ],
      })
      weatherStations?.forEach((station) => {
        weatherOptions.push({
          id: `weather-${e.id}-${station.id}`,
          title: `Weather ${station.label}`,
          subtitle: `${e.subtitle}`,
          unit: getDefinition(DataSeriesType.Temperature).defaultUnitValue,
          tagOptions: calculationOptions.map((calc) => ({
            value: calc[0],
            label: calc[1],
          })),
        })
      })
    })
    return [...timeSeriesOptions, ...weatherOptions]
  }, [timeSeries, weatherStations, commodityType])

  const buildCalculatedDataSeries: BuildCalculatorDataSeries = (
    calculator,
    referenceSeries,
  ) => {
    const newExpressionDataIdMap: object = {}
    const dataSeriesCombined: DataSeries[] = []
    let hasWeather = false
    Object.entries(calculator.expressionDataIdMap).forEach(([k, val]) => {
      const [type, forecastId, weatherStationId] = val.id.split('-')
      let dataSeriesType
      const selectedTimeSeries = timeSeries[parseInt(forecastId, 10)]
      let info

      switch (type) {
        case 'forecast':
          dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
          info = getForectastDataSeriesInfo(selectedTimeSeries)
          break
        case 'meterCount':
          info = getMeterCountDataSeriesInfo(selectedTimeSeries)
          break
        default:
          hasWeather = true
          dataSeriesType = DataSeriesType.Temperature
          info = getWeatherDataSeriesInfo(selectedTimeSeries, weatherStationId)
          break
      }

      newExpressionDataIdMap[k] = forecastId
      const selectedCalculation = val.selectedOption
        ? ([val.selectedOption, val.selectedOption] as SingleCalculationOption)
        : undefined
      dataSeriesCombined.push({
        id: info.id,
        label: info.label,
        calculationOptions: type === 'meterCount' ? [] : calculationOptions,
        selectedCalculation,
        selectedSplit: null,
        type: dataSeriesType,
        extras: {
          processTime: info.processTime,
          legendText: info.legendText,
          station: weatherStationId,
        },
        selectedFilters: filters.dimensionFilters.selectedFilters,
        rangeFilters: filters.rangeFilters.filters,
      })
    })
    return {
      ...referenceSeries,
      id: calculator.uid,
      label: calculator.name,
      splitOptions: hasWeather ? undefined : splitOptions,
      type: getDataSeriesTypeFromUnit(calculator.unit || ''),
      customUnit: calculator.unit || '',
      groupedItems: {
        expression: calculator.expression,
        expressionDataIdMap: calculator.expressionDataIdMap,
        expressionScope: newExpressionDataIdMap,
        dataSeries: dataSeriesCombined,
        key: calculator.expression,
      },
    }
  }

  return {
    dates,
    filters,
    splitOptions,
    manager,
    timeSeries,
    buildCalculatedDataSeries,
    calculatorDataSeriesOptions,
    selectedTimezone,
    setTimezone,
    selectedStation,
    setWeatherStation,
    isHourEndingSelected,
    setIsHourEndingSelected,
    forecasts,
    areDataSeriesLoading,
  }
}

interface Props {
  datasource: string
  filtersDefinitions: DashboardConfigItem['filters']
  savedDashboardInfo: Dashboard
  weatherStations?: Array<WeatherStation>
  timeInterval: number
  onSave: HandleSave
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
  dynamicColumnsConfig?: DynamicColumnsConfig
}

/**
 * Short Term Forecast page
 */
const ShortTermForecast = ({
  datasource,
  filtersDefinitions,
  savedDashboardInfo,
  weatherStations,
  timeInterval,
  onSave,
  rangeFiltersDefinition,
  dynamicColumnsConfig,
}: Props) => {
  const commodityType = savedDashboardInfo.commodity.name
  const [selectedMenu, setSelectedMenu] = useState<
    'load' | 'weather' | 'meterCount'
  >('load')

  const [defaultCards, setDefaultCards] = useState(() => {
    if (dynamicColumnsConfig?.meterIdColumn) {
      return [
        ...savedDashboardInfo.cards,
        {
          id: 'meterMapID',
          title: 'Meter Map',
          dataSeries: [],
          visualization: Visualization.MeterMap,
          group: dynamicColumnsConfig.meterIdColumn,
        },
      ]
    }
    return savedDashboardInfo.cards
  })

  const history = useHistory()
  const location = useLocation()

  const { dashboardConfigItems } = useDashboardConfigItems(
    savedDashboardInfo.type,
  )

  const [isFormOpen, setIsCreatingDashboard] = useState<boolean>(false)

  const onDashboardCreate = (id) => {
    history.push(`/demand/load-forecast/dashboard/${id}`)
  }

  const handleAddNewClose = () => {
    // if dialog is open and loading do not close
    setIsCreatingDashboard((prevState) => !prevState)
  }

  const publishNotification = useContext(AlertContext)

  const {
    dates,
    filters,
    manager,
    buildCalculatedDataSeries,
    calculatorDataSeriesOptions,
    selectedTimezone,
    setTimezone,
    selectedStation,
    setWeatherStation,
    isHourEndingSelected,
    setIsHourEndingSelected,
    splitOptions,
    areDataSeriesLoading,
    timeSeries,
    forecasts,
  } = useShortTermForecast({
    savedDashboardInfo,
    datasource,
    timeInterval,
    filtersDefinitions,
    rangeFiltersDefinition,
    weatherStations,
    dynamicColumnsConfig,
  })

  useEffect(() => {
    // Build default dataseries for an unsaved card (basically forecast exploration)
    if (savedDashboardInfo.dashboardId === 'unsaved' && timeSeries.length > 0) {
      try {
        const query = new URLSearchParams(location.search)
        const encodedForecastObject = query.get('object')
        const decodedForecastObject = Buffer.from(
          encodedForecastObject as string,
          'base64',
        ).toString()
        const forecastToExplore = JSON.parse(decodedForecastObject)
        const processTimeForForecast = DateTime.fromISO(
          forecastToExplore.processTime.toString(),
        ).toUTC()

        const forecastToPutInCard = forecasts.find(
          (forecast) =>
            forecast === processTimeForForecast.toISO() ||
            forecast === processTimeForForecast.toISODate(),
        )

        const timeSeriesItem = timeSeries.find(
          (item) => item.processTime === forecastToPutInCard,
        )
        if (timeSeriesItem) {
          const {
            id,
            label,
            legendText,
            processTime,
          }: DataSeriesLabelItems = getForectastDataSeriesInfo(timeSeriesItem)

          const dataSeries: DataSeries[] = [
            {
              id,
              label,
              calculationOptions,
              selectedCalculation: calculationOptions[0],
              splitOptions,
              selectedSplit: null,
              type: getLoadDataSeriesTypeByCommodity(commodityType),
              extras: {
                processTime,
                legendText,
              },
            },
          ]
          const newDefaultCard = {
            ...savedDashboardInfo.cards[0],
            dataSeries,
          }
          setDefaultCards([newDefaultCard])
        }
      } catch (error) {
        setDefaultCards(savedDashboardInfo.cards)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedDashboardInfo, timeSeries])

  const { min, max, isInitializing, from, to, setFromDate, setToDate } = dates

  const {
    dimensionFilters,
    timeFilters,
    rangeFilters,
    onChange: onFilterChange,
    isInitializing: areFiltersInitializing,
  } = filters

  return (
    <BaseDashboard
      dashboardId={savedDashboardInfo.dashboardId}
      calculatorLabelAccessor={(option) => {
        const selectedOptionLabel = getSelectedOptionLabel(option)
        return [selectedOptionLabel, option.title, option.subtitle]
          .filter((e) => e)
          .join(' ')
      }}
      meterGrouping={dynamicColumnsConfig?.meterIdColumn}
      calculatorDataSeriesOptions={calculatorDataSeriesOptions}
      buildCalculatorDataSeries={buildCalculatedDataSeries}
      calculators={savedDashboardInfo.calculators}
      isInitializing={isInitializing || areFiltersInitializing}
      areFiltersLoading={areFiltersInitializing}
      isLoading={areDataSeriesLoading}
      cardsDataSeriesManager={manager}
      defaultCards={
        savedDashboardInfo.cards.length > 0
          ? defaultCards
          : savedDashboardInfo.cards
      }
      filtersDefinitions={filtersDefinitions}
      filters={dimensionFilters.filters}
      timeFilters={timeFilters.filters}
      onFilterChange={onFilterChange}
      selectedTimeFilters={timeFilters.selectedFilters}
      timeInterval={timeInterval}
      rangeFilters={rangeFilters.filters}
      onRangeFilterChange={rangeFilters.onChange}
      defaultNewCardGrouping={
        timeInterval === 15 ? Group.QuaterHourly : Group.Hourly
      }
      selectedTimezone={selectedTimezone}
      onTimezoneChange={(e, newTimezone) => setTimezone(newTimezone)}
      isHourEndingSelected={isHourEndingSelected}
      onIsHourEndingSelectedChange={(e, newIsHourEndingSelected) =>
        setIsHourEndingSelected(newIsHourEndingSelected)
      }
      onSave={(e, cards, extras) => {
        if (savedDashboardInfo.dashboardId === 'unsaved') {
          setIsCreatingDashboard(true)
        } else {
          onSave(e, {
            ...extras,
            cards,
            filters: dimensionFilters.filters,
            rangeFilters: rangeFilters.filters.map(({ name, value }) => ({
              name,
              value,
            })),
            timeFilters: timeFilters.filters,
            timezone: selectedTimezone,
            isHourEndingSelected,
            from: from || undefined,
            to: to || undefined,
          })
        }
      }}
      extraOptions={
        min &&
        max &&
        from &&
        to && (
          <>
            <DateSelector
              startData={min}
              endData={max}
              quantity={from.quantity}
              multiplier={from.multiplier}
              identifier={from.identifier}
              dateType={from.dateType}
              value={from.value}
              onChange={setFromDate}
              label={`Start (${
                from.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
            <DateSelector
              startData={min}
              endData={max}
              quantity={to.quantity}
              multiplier={to.multiplier}
              identifier={to.identifier}
              dateType={to.dateType}
              value={to.value}
              onChange={setToDate}
              label={`End (${
                to.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
          </>
        )
      }
    >
      <WeatherAndLoadMenu
        selectedMenu={selectedMenu}
        onMenuChange={(e) => setSelectedMenu(e.target.value)}
        timeSeries={timeSeries}
        onDrag={(e, selectedTimeSeries) => {
          switch (selectedMenu) {
            case 'load':
              {
                const {
                  id,
                  label,
                  legendText,
                  processTime,
                } = getForectastDataSeriesInfo(selectedTimeSeries)
                forecastsDataSeriesHandlers.prepareDataTransfer(
                  e.dataTransfer,
                  id,
                  label,
                  splitOptions,
                  getLoadDataSeriesTypeByCommodity(commodityType),
                  legendText,
                  processTime,
                )
              }
              break
            case 'meterCount':
              {
                const {
                  id,
                  label,
                  legendText,
                  processTime,
                } = getForectastDataSeriesInfo(selectedTimeSeries)
                forecastsDataSeriesHandlers.prepareDataTransfer(
                  e.dataTransfer,
                  id,
                  label,
                  splitOptions,
                  DataSeriesType.Dimensionless,
                  legendText,
                  processTime,
                  undefined,
                  undefined,
                  true,
                )
              }
              break
            case 'weather':
              {
                const {
                  id,
                  label,
                  processTime,
                  legendText,
                } = getWeatherDataSeriesInfo(
                  selectedTimeSeries,
                  selectedStation.label,
                )
                weatherDataSeriesHandlers.prepareDatatransfer(
                  e.dataTransfer,
                  id,
                  label,
                  {
                    station: selectedStation.id,
                    stationLabel: selectedStation.label,
                  },
                  legendText,
                  processTime,
                )
              }
              break
            default:
              break
          }
        }}
        isLoading={areDataSeriesLoading}
        stations={weatherStations}
        selectedStation={selectedStation}
        onStationChange={(e, station) => setWeatherStation(station)}
        hasMeterCount={dynamicColumnsConfig?.meterIdColumn !== undefined}
      />
      {isFormOpen && (
        <AddNewDashboardDialog
          onAdd={(model) => {
            const {
              configId,
              filters: forecastFilters,
              rangeFilters: forecastRangeFilters,
              timeFilters: forecastTimeFilters,
              calculators,
              timezone,
              isHourEndingSelected: forecastHourEndingSelected,
              from: forecastFromDate,
              to: forecastToDate,
            } = savedDashboardInfo
            const dashboardObjectToSave = {
              cards: defaultCards,
              configId,
              filters: forecastFilters.map((filter) => ({
                ...filter,
                title: undefined,
              })),
              name: model?.name,
              description: model?.description,
              rangeFilters: forecastRangeFilters,
              timeFilters: forecastTimeFilters,
            }

            addNewDashboard(dashboardObjectToSave)
              .then((dashboardId) => {
                const dashboardUpdateBody = {
                  cards: defaultCards,
                  filters: forecastFilters.map((filter) => ({
                    ...filter,
                    title: undefined,
                  })),
                  rangeFilters: forecastRangeFilters ?? [],
                  timeFilters: forecastTimeFilters ?? [],
                  calculators,
                  timezone,
                  isHourEndingSelected: forecastHourEndingSelected,
                  from: {
                    ...forecastFromDate,
                    quantity: forecastFromDate?.quantity || 0,
                  },
                  to: {
                    ...forecastToDate,
                    quantity: forecastToDate?.quantity || 0,
                  },
                }
                updateDashboard(dashboardId, dashboardUpdateBody)
                publishNotification('Dashboard saved successfully', 'success')
                onDashboardCreate(dashboardId)
              })
              .catch((err) => {
                if (err.response.status === 404) {
                  /**
                   * A 404 error is technically not found. The backend will return a 404 because it tries to update a dashboard not owned by the requester.
                   */
                  publishNotification(
                    'You cannot save a dashboard that was shared with you',
                    'error',
                  )
                }
              })
          }}
          onClose={handleAddNewClose}
          dashboardConfigItems={dashboardConfigItems || []}
          copyMeta={{
            name: '',
            description: '',
            commodity: savedDashboardInfo?.commodity?.name,
            category: savedDashboardInfo?.category?.name,
            level: savedDashboardInfo?.level?.name,
          }}
          isUnsavedDashboard={savedDashboardInfo.dashboardId === 'unsaved'}
        />
      )}
    </BaseDashboard>
  )
}

export default ShortTermForecast
