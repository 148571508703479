import React from 'react'
import { makeStyles, styled, Typography } from '@material-ui/core'

const StyledMenuOption = styled('li')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  listStyle: 'none',
  border: '2px dashed transparent',
  '&:hover': {
    border: '2px dashed',
    borderColor: theme.palette.divider,
  },
}))

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
  },
}))

// List with no padding/margin/listStyle
export const UnStyledList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
})

interface Props {
  /** Title displayed in component */
  title: string
  /** Optional subtitle displayed smaller and under title */
  subtitle?: string
  /** Any additional props acceptable by an `<li>` tag such as `onClick`, `onDrag`, `className`, etc... */
  props?: any
}

/**
 * Very simple menu options to be used throughout the app. Takes in a title an optional subtitle and any additional props applicable to an `<li>` tag
 */
const MenuOption = ({ title, subtitle, props }: Props) => {
  const classes = useStyles()
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledMenuOption {...props}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {Boolean(subtitle) && (
        <Typography variant="caption" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </StyledMenuOption>
  )
}

export default MenuOption
