import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import DemandDashboard from 'modules/demand/products/loadForecast/pages/LoadForecastDashboard'
import { ReactComponent as LoadForecastIcon } from 'icons/load-forecast-submodule.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'
import Forecasts from './pages/Forecasts'
import AdjustmentRoutes from './pages/AdjustmentRoutes'

const loadForecastingMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: DemandDashboard,
  },
  {
    label: 'Available Forecasts',
    path: '/forecasts',
    component: Forecasts,
  },
  {
    label: 'Short-Term Forecast Adjustments',
    path: '/adjustments',
    component: AdjustmentRoutes,
  },
]

export const LoadForecastMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Load Forecast"
      color="demand"
      basePath={url}
      icon={<LoadForecastIcon />}
      routes={loadForecastingMenuOptions}
    />
  )
}

const LoadForecast = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${loadForecastingMenuOptions[0].path}`} />
      </Route>
      {loadForecastingMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default LoadForecast
