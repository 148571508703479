export const getDefaultStartDate = (today: Date = new Date()) => {
  return new Date(today.getFullYear(), today.getMonth(), 1)
}

export const getDefaultEndDate = (startDate: Date) => {
  return new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    startDate.getDate() - 1,
  )
}
