import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridPageChangeParams,
  GridRowsProp,
  GridSortDirection,
} from '@material-ui/data-grid'
import { fade, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export const DEFAULT_PAGE_SIZE = 25
const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]

const useDataGridStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-row': {
      '&:nth-child(even)': {
        backgroundColor: fade(theme.palette.primary.main, 0.1),
      },
    },
  },
  label: {
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.grey[500]}`,
  },
  value: {
    padding: theme.spacing(1),
  },
  space: {
    margin: theme.spacing(2),
  },
}))

export interface TableState {
  rows: GridRowsProp
  columns: GridColumns
  pageNumber: number
  pageSize: number
  rowCount: number
  pageSizeOptions: Array<number>
  sorting:
    | Array<{
        field: string
        sort: GridSortDirection
      }>
    | []
}

const MeterTable = ({
  columns,
  rows,
  pageSize,
  pageNumber,
  rowCount,
  onPageChange,
  onPageSizeChange,
}: {
  columns: GridColDef[]
  rows: Array<any>
  pageSize: number
  pageNumber: number
  rowCount: number
  onPageChange: (params: GridPageChangeParams) => void
  onPageSizeChange: (val: GridPageChangeParams) => void
}) => {
  const dataGridClasses = useDataGridStyles()

  const [tableState, setTableState] = useState<TableState>({
    rows,
    columns,
    pageNumber,
    rowCount,
    pageSize,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    sorting: [],
  })

  useEffect(() => {
    if (columns.length > 0 && rows.length > 0 && rowCount > 0) {
      setTableState((prevState) => ({
        ...prevState,
        rows,
        columns,
        pageNumber,
        rowCount,
        pageSize,
      }))
    }
  }, [columns, rows, pageSize, pageNumber, rowCount])

  return (
    <div>
      <div className={dataGridClasses.space}>
        <Typography
          variant="h6"
          className={dataGridClasses.label}
          component="span"
        >
          Data Table
        </Typography>
        <Typography
          component="span"
          variant="body1"
          color="textSecondary"
          className={dataGridClasses.value}
        >
          {rowCount}
        </Typography>
      </div>
      <DataGrid
        disableColumnMenu
        disableSelectionOnClick
        density="standard"
        rows={tableState.rows}
        columns={tableState.columns}
        pageSize={tableState.pageSize}
        page={tableState.pageNumber}
        rowCount={tableState.rowCount}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={tableState.pageSizeOptions}
        paginationMode="server"
        sortingMode="server"
        disableExtendRowFullWidth={false}
        onPageChange={onPageChange}
        sortModel={tableState.sorting}
        className={dataGridClasses.root}
        autoHeight
      />
    </div>
  )
}

export default MeterTable
