import React from 'react'
import MainMenuButton from 'shared/components/MainMenuButton'
import productModuleConfig from 'modules/insights/products/dataInsights/index'
import { ApplicationProduct, ProductModuleButtonComponent } from 'shared/types'
import { ArrowRight } from 'react-feather'

const buttonProps = {
  label: 'Insights',
  name: 'insights',
  description:
    'Multi-cloud data platform deliver faster insights for all. Achieve sub-second analytic queries at any concurrency and scale.',
}

export const INSIGHT_PRODUCTS: Array<ApplicationProduct> = [
  {
    mod: 'mod_insights',
    product: 'prod_data_insights',
    path: '/insights/data-insights',
    config: productModuleConfig,
    label: 'Meter Insights',
    icon: <ArrowRight />,
    active: false,
    isExternalLink: false,
  },
  {
    mod: 'mod_insights',
    product: 'prod_meter_level_insights',
    path: 'https://bi.edge.innowatts.net/pivot/home',
    config: productModuleConfig,
    label: 'Data Workbench',
    icon: <ArrowRight />,
    isExternalLink: true,
    active: false,
  },
]

const InsightsButton = (({
  products,
}: {
  products: Array<ApplicationProduct>
}) => {
  const { label, name, description } = buttonProps

  return (
    <MainMenuButton
      label={label}
      description={description}
      products={products}
      name={name}
    />
  )
}) as ProductModuleButtonComponent

export default InsightsButton
