import { isTimeSeries } from 'shared/helpers'
import {
  CalculationOptions,
  Calculation,
  SingleGroupOption,
  Group,
  SelectableValue,
  GroupOptions,
} from 'shared/types'

export const calculationOptions: CalculationOptions = [
  [Calculation.Sum, 'Sum'],
  [Calculation.Average, 'Avg'],
  [Calculation.Max, 'Max'],
  [Calculation.Min, 'Min'],
]

const groupOptionsWithTimeInterval: Array<[SingleGroupOption, number]> = [
  [[Group.QuaterHourly, '15-min'], 15],
  [[Group.HalfHourly, '30-min'], 30],
  [[Group.Hourly, 'Hour-All'], 60],
  [[Group.Hour24, 'Hour-24'], 60],
  [[Group.Daily, 'Day-All'], 1440],
  [[Group.DayType, 'Day-Type'], 1440],
  [[Group.DayOfWeek, 'Day-Week'], 1440],
  [[Group.Monthly, 'Month-All'], 43200],
  [[Group.Month12, 'Month-12'], 43200],
  [[Group.Yearly, 'Year-All'], 525600],
]

export const groupOptions = groupOptionsWithTimeInterval.map(
  ([groupOption]) => groupOption,
)

export const timeGroupSet = new Set(
  groupOptionsWithTimeInterval.map(([groupOption]) => groupOption[0]),
)

export const isTimeGroup = (group) => timeGroupSet.has(group)

export const getGroupOptionsForTimeInterval = (
  timeInterval: number,
): GroupOptions =>
  groupOptionsWithTimeInterval
    .filter(([, minTimeInterval]) => minTimeInterval >= timeInterval)
    .map(([singleGroupOption]) => {
      return singleGroupOption
    })

export const getDateTypeValues = (
  isHourEndingSelected: Boolean | undefined,
): Record<
  Group.Hour24 | Group.DayOfWeek | Group.Month12 | Group.DayType,
  Array<[string, SelectableValue]>
> => ({
  /* 0-Based values => [0 ... 23] */
  [Group.Hour24]: Array.from(Array(24)).map<[string, number]>((val, index) => {
    let valueToQuery = index
    if (isHourEndingSelected) {
      valueToQuery = index === 0 ? 23 : index - 1
    }
    return [index.toString(), valueToQuery]
  }),
  [Group.DayType]: ['Weekday', 'Weekend'].map((label, index) => [label, index]),
  /* 0-Based values starting on Monday => [Monday=0 ... Sunday=6] */
  [Group.DayOfWeek]: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ].map((label, index) => [label, index]),
  /* 1-Based values starting on January => [January=1 ... December=12] */
  [Group.Month12]: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ].map((label, index) => [label, index + 1]),
})

export const nonTimeSeriesGroupOptions = groupOptions.filter(
  ([groupType]) => !isTimeSeries(groupType),
)
