import { ProductModuleConfig } from 'shared/types'
import GridEdgeManagement, { GridEdgeManagementMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: GridEdgeManagementMenu,
  MainContent: GridEdgeManagement,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
