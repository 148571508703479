import { ProductModuleConfig } from 'shared/types'
import DemandAnalytics, { DemandAnalyticsMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: DemandAnalyticsMenu,
  MainContent: DemandAnalytics,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
