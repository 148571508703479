import { Typography, AppBar, Box } from '@material-ui/core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { PlusCircle } from 'react-feather'
import { fetchOrgUsers } from 'shared/apiClient'
import AddNewDashboardDialog from 'shared/components/AddNewDashboardDialog'
import Alert from 'shared/components/Alert'
import DashboardSummary from 'shared/components/DashboardSummary'
import ShareDashboardDialog, {
  Group,
  User,
} from 'shared/components/ShareDashboardDialog'
import AlertContext from 'shared/contexts/AlertsContext'
import UserContext from 'shared/contexts/UserContext'
import useDashboards from 'shared/hooks/useDashboards'
import { Dashboard, DashboardConfigItem } from 'shared/types'
import {
  PageLayout,
  StyledTab,
  StyledTabGroup,
  Body,
  Header,
  PageTitle,
  Options,
} from '../components/PageLayout'
import IWButton from '../components/IWButton'

interface Props {
  /** All items in the dashboard type configuration */
  dashboardConfigItems: Array<DashboardConfigItem>
  /** callback that returns a selected dashboard from the summary card */
  onDashboardSelect: (dashboardId: string) => void
  /** callback that returns a newly generated id from a newly created dashboard */
  onDashboardCreate: (id) => void
  /** Main type of dashboard used to fetch appropriate dashboards */
  dashboardType: string
}

enum DashboardOptionTabs {
  myDashboards,
  sharedWithMe,
}

const deleteAlerts = (
  dashboardToDelete: Dashboard | null,
  isLoading,
  setDashboardToDelete,
  handleDeleteDashboard,
  publishNotification,
) => {
  if (!dashboardToDelete?.org && dashboardToDelete?.sharedUsers?.length === 0) {
    return (
      <Alert
        title="Delete Dashboard?"
        message={
          <span>
            This means <strong>{dashboardToDelete?.name}</strong> configuration
            will no longer be available.
          </span>
        }
        actionButtonText="Delete"
        isOpen={Boolean(dashboardToDelete)}
        onClose={() => {
          setDashboardToDelete(null)
        }}
        isLoading={isLoading}
        onAlertAction={() => {
          if (dashboardToDelete) {
            handleDeleteDashboard(
              dashboardToDelete.dashboardId,
              (actionState) => {
                if (
                  actionState.actionResultSeverity === 'error' &&
                  actionState.httpErrorStatusCode === 409
                ) {
                  publishNotification(
                    'Please unshare deleting',
                    actionState.actionResultSeverity,
                  )
                }
                setDashboardToDelete(null)
              },
            )
          }
        }}
      />
    )
  }
  // if (isDashboardIsBeingShared(dashboardToDelete)) {
  return (
    <Alert
      title="Cannot delete dashboard"
      message={
        <>
          <p>You cannot delete dashboards that you are currently sharing.</p>
          <p>
            To delete please unshare <strong>{dashboardToDelete?.name}</strong>{' '}
            from everyone and/or your organization then delete.
          </p>
        </>
      }
      actionButtonText="ok"
      isOpen={Boolean(dashboardToDelete)}
      onAlertAction={() => {
        if (dashboardToDelete) {
          setDashboardToDelete(null)
        }
      }}
    />
  )
}

/**
 * Component with the summary of the differents dashboards created so
 * they can be selected, edited, deleted, or create new ones.
 * This component wll fetch all dashboards according to the passed dashboard type prop.
 */
const DashboardManager = ({
  dashboardConfigItems,
  onDashboardSelect,
  onDashboardCreate,
  dashboardType,
}: Props) => {
  const { userId: currentUserId, organization } = useContext(UserContext)
  const publishNotification = useContext(AlertContext)

  const {
    dashboards,
    isLoading,
    handleDeleteDashboard,
    handleUpdateDashboard,
    handleAddNewDashboard,
    handleCopyDashboard,
    handleShareDashboard,
    handleGroupShareDashboard,
    handleGroupUnShareDashboard,
  } = useDashboards(dashboardType)

  const [userDashboards, setUserDashboards] = useState<Dashboard[] | null>(null)
  const [sharedDashboards, setSharedDashboards] = useState<Dashboard[] | null>(
    null,
  )

  const [tab, setTab] = useState<DashboardOptionTabs>(
    DashboardOptionTabs.myDashboards,
  )

  const [dashboardToShare, setDashboardToShare] = useState<Dashboard | null>(
    null,
  )
  const [dashboardToCopy, setDashboardToCopy] = useState<Dashboard | null>(null)
  const [isSharingOpen, setIsSharingOpen] = useState<boolean>(false)
  const [orgUsers, setOrgUsers] = useState<
    { id: string; name: string; email: string }[]
  >([])
  const [groups] = useState<any>([{ id: organization, name: organization }])

  useEffect(() => {
    fetchOrgUsers().then((data) => {
      const filterSameUser = data.filter((u) => u.id !== currentUserId)
      setOrgUsers(filterSameUser.sort((a, b) => a.name.localeCompare(b.name)))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const orgUsersMap = useMemo<any>(() => {
    return new Map(orgUsers.map((user) => [user.id, user]))
  }, [orgUsers])

  useEffect(() => {
    const usersDash = dashboards.filter((u) => u.owner === currentUserId)

    const sharedDash = dashboards.filter(
      (u) =>
        u.owner !== currentUserId &&
        (u.org || u.sharedUsers?.includes(currentUserId)),
    )

    setUserDashboards(usersDash)
    setSharedDashboards(sharedDash)
    // If user doesn't have any created dashboards but has been shared dashboards we default to shared
    if (usersDash.length === 0 && sharedDash.length > 0) {
      setTab(DashboardOptionTabs.sharedWithMe)
    }
  }, [currentUserId, dashboards])
  const [dashboardToDelete, setDashboardToDelete] = useState<Dashboard | null>(
    null,
  )
  const [isFormOpen, setIsCreatingDashboard] = useState<boolean>(false)

  const [isCopyingOpen, setIsCopyingDashboard] = useState<boolean>(false)

  const handleAddNewClose = () => {
    // if dialog is open and loading do not close
    setIsCreatingDashboard((prevState) => prevState && isLoading)
  }

  const handleClosingCopyPopUp = () => {
    // if dialog is open and loading do not close
    setIsCopyingDashboard((prevState) => prevState && isLoading)
  }

  const handleTabChange = (e, newValue: number) => {
    setTab(newValue)
  }

  function handleUserSharing(metadata: User[]) {
    const usersToAdd = metadata.filter((u) => u.isChecked).map((u) => u.id)
    const usersToRemove = metadata.filter((u) => !u.isChecked).map((u) => u.id)
    handleShareDashboard(
      dashboardToShare?.dashboardId,
      {
        add: usersToAdd,
        remove: usersToRemove,
      },
      (state) => {
        if (state.actionResultSeverity === 'success') {
          publishNotification('Dashboard shared', 'success')
        }
        setDashboardToShare(null)
        setIsSharingOpen(false)
      },
    )
  }

  function handleGroupSharing(metadata: Group[]) {
    const groupToAdd = metadata.filter((u) => u.isChecked).map((u) => u.name)
    const groupToRemove = metadata
      .filter((u) => !u.isChecked)
      .map((u) => u.name)

    if (groupToAdd && groupToAdd.length > 0) {
      handleGroupShareDashboard(
        dashboardToShare?.dashboardId,
        groupToAdd[0],
        () => {
          publishNotification('Dashboard shared successfully', 'success')
          setDashboardToShare(null)
          setIsSharingOpen(false)
        },
      )
    }

    if (groupToRemove && groupToRemove.length > 0) {
      handleGroupUnShareDashboard(dashboardToShare?.dashboardId, () => {
        publishNotification('Dashboard Unshared successfully', 'success')
        setDashboardToShare(null)
        setIsSharingOpen(false)
      })
    }
  }

  return (
    <PageLayout>
      <Header>
        <PageTitle>
          <Typography variant="h4">Dashboards</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {dashboards.length} created
          </Typography>
          <Box display="flex" flexGrow="1" />
          <IWButton
            startIcon={<PlusCircle />}
            onClick={() => setIsCreatingDashboard(true)}
          >
            Add New Dashboard
          </IWButton>
        </PageTitle>
      </Header>
      <Options>
        <AppBar position="static" color="transparent" elevation={0}>
          <StyledTabGroup
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
          >
            <StyledTab label="My Dashboards" />
            <StyledTab label="Shared With Me" />
          </StyledTabGroup>
        </AppBar>
      </Options>
      <Body>
        {tab === 0 &&
          userDashboards &&
          userDashboards.map((dashboard) => {
            const sharedUsers = dashboard.org
              ? orgUsers.map((e) => e.name)
              : dashboard.sharedUsers
                  .map((user) => {
                    return orgUsersMap.get(user)?.name
                  })
                  .filter((name) => name !== undefined)
            return (
              <DashboardSummary
                key={dashboard.dashboardId}
                sharedUsers={sharedUsers}
                name={dashboard.name}
                description={dashboard.description}
                chips={[
                  dashboard.category,
                  dashboard.level,
                  dashboard.commodity,
                ].filter(
                  (e): e is { name: string; label: string } => e !== undefined,
                )}
                addedOn={new Date(dashboard.addedOn)}
                onDelete={() => {
                  setDashboardToDelete(dashboard)
                }}
                onSelect={() => {
                  onDashboardSelect(dashboard.dashboardId)
                }}
                onSave={(e, newName, newDescription) =>
                  handleUpdateDashboard(dashboard.dashboardId, {
                    name: newName,
                    description: newDescription,
                  })
                }
                onCopyDashboard={() => {
                  setDashboardToCopy(dashboard)
                  setIsCopyingDashboard(true)
                }}
                onShareDashboard={() => {
                  setDashboardToShare(dashboard)
                  setIsSharingOpen(true)
                }}
              />
            )
          })}
        {tab === 1 &&
          sharedDashboards &&
          sharedDashboards.map((dashboard) => (
            <DashboardSummary
              key={dashboard.dashboardId}
              name={dashboard.name}
              description={dashboard.description}
              chips={[
                dashboard.category,
                dashboard.level,
                dashboard.commodity,
              ].filter(
                (e): e is { name: string; label: string } => e !== undefined,
              )}
              onSelect={() => {
                onDashboardSelect(dashboard.dashboardId)
              }}
              onCopyDashboard={() => {
                setDashboardToCopy(dashboard)
                setIsCopyingDashboard(true)
              }}
              sharedUsers={[orgUsersMap.get(dashboard.owner)?.name].filter(
                (e) => e,
              )}
              addedOn={new Date(dashboard.addedOn)}
            />
          ))}
      </Body>
      {deleteAlerts(
        dashboardToDelete,
        isLoading,
        setDashboardToDelete,
        handleDeleteDashboard,
        publishNotification,
      )}
      {isFormOpen && (
        <AddNewDashboardDialog
          onAdd={(model) =>
            handleAddNewDashboard(model, dashboardType, (id) => {
              setIsCreatingDashboard(false)
              onDashboardCreate(id)
            })
          }
          onClose={handleAddNewClose}
          dashboardConfigItems={dashboardConfigItems}
          isAwaitingConfirmation={isLoading}
        />
      )}
      {isCopyingOpen && (
        <AddNewDashboardDialog
          onAdd={(model) => {
            if (!dashboardToCopy) {
              return
            }
            handleCopyDashboard(model, dashboardToCopy, (id) => {
              setIsCopyingDashboard(false)
              onDashboardCreate(id)
            })
          }}
          onClose={handleClosingCopyPopUp}
          dashboardConfigItems={dashboardConfigItems}
          isAwaitingConfirmation={isLoading}
          isOnCopyMode
          copyMeta={{
            name: `Copy-${dashboardToCopy?.name}`,
            description: dashboardToCopy?.description,
            commodity: dashboardToCopy?.commodity?.name,
            category: dashboardToCopy?.category?.name,
            level: dashboardToCopy?.level?.name,
          }}
        />
      )}
      {isSharingOpen && dashboardToShare && (
        <ShareDashboardDialog
          sharedUsers={dashboardToShare.sharedUsers}
          allUsers={orgUsers}
          groups={groups}
          sharedGroups={dashboardToShare.org ? [dashboardToShare.org] : []}
          onUserShare={handleUserSharing}
          onGroupShare={handleGroupSharing}
          onClose={() => {
            setIsSharingOpen(false)
            setDashboardToShare(null)
          }}
        />
      )}
    </PageLayout>
  )
}

export default DashboardManager
