import { useState, useEffect, SyntheticEvent } from 'react'

/**
 * Arbitrary time for the timeout to restore
 * the input value in case it is left empty.
 *
 * Faster than what a human will appreciate, but
 * slow enough to not cause an undesired update
 * before the possible upper state keeps up
 * with the change.
 * */
const RESTORE_VALUE_TIMEOUT = 100

/**
 * Simple hook to provide proper "value",
 * and event handler props ("onChange", "onBlur" * and "onFocus") so it has the behavior of
 * a "never empty" text field.
 *
 * It requires both the "current value" (can be
 * empty) and the "default value", that will be
 * returned if the user tries to leave the input
 * empty or if the "current value" gives is empty.
 *
 * It makes the "onChange" callback to be
 * invoked when the input loses focus and it is
 * ensured it will always pass a "non empty"
 * value as argument.
 *
 * Also provides the handy behavior to "select all"
 * the test on the input in case the current value
 * is equal to the default value when the user focus
 * the text field.
 */
const useNonEmptyTextField = (
  currentValue: string,
  defaultValue: string,
  onChange: (event: SyntheticEvent, newValue: string) => void,
) => {
  if (!defaultValue) {
    // eslint-disable-next-line no-console
    console.warn(
      `A default value is expected for the 'useNonEmptyTextField' to work properly`,
    )
  }

  const [timeoutHandler] = useState<{ timeout: any }>({
    timeout: null,
  })

  const [value, setValue] = useState(currentValue || defaultValue)

  /* Whenever the "currentValue" changes, we want
  the "value" to be returned to keep up with it */
  useEffect(() => {
    if (currentValue && currentValue !== value) {
      setValue(currentValue)
    }

    // Removes possible timeout as cleanup
    return () => {
      if (timeoutHandler.timeout) {
        clearTimeout(timeoutHandler.timeout)
        timeoutHandler.timeout = null
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue])

  return {
    value,
    onChange: (e) => {
      const newValue = e.target.value
      setValue(newValue)
    },
    onBlur: (event) => {
      if (value !== currentValue) {
        const newValue = value || defaultValue
        onChange(event, newValue)
        /* Always will restore back the current value
        after a timeout. The parent is responsible to
        modify the "currentValue" prop. */
        timeoutHandler.timeout = setTimeout(() => {
          timeoutHandler.timeout = null
          setValue(currentValue || defaultValue)
        }, RESTORE_VALUE_TIMEOUT)
      }
    },
    onFocus: (e) => {
      if (e.target.value === defaultValue) {
        e.target.select()
      }
    },
  }
}

export default useNonEmptyTextField
