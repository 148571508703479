import { Reducer, useEffect, useReducer, useState } from 'react'
import crossfilter, { Dimension, Crossfilter } from 'crossfilter2'
import { sortFilters } from 'shared/helpers'
import { Checkbox, Filter, SavedFilter } from 'shared/types'

type SimpleKeyValue = { [key: string]: string }
type Dimensions = {
  [key: string]: Dimension<SimpleKeyValue, string>
}

type SelectedFilter = { name: string; values: any[] }

interface ReducerState {
  ndx: Crossfilter<SimpleKeyValue>
  dimensions: Dimensions
  filters: Filter[]
  lastChanged: string | null
  selectedFilters: SelectedFilter[]
}

const initialize = ({
  filterOptions,
  getTitle,
  savedFilters,
}): {
  type: 'INITIALIZE'
  filterOptions: SimpleKeyValue[]
  getTitle?: (name: string) => string
  savedFilters?: SavedFilter[]
} => ({
  type: 'INITIALIZE',
  filterOptions,
  getTitle,
  savedFilters,
})

const handleFilterChange = ({
  groupName,
  changes,
}): { type: 'HANDLE_FILTER_CHANGE'; groupName: string; changes: any[] } => ({
  type: 'HANDLE_FILTER_CHANGE',
  groupName,
  changes,
})

const setFinalFilterState = (): { type: 'SET_FINAL_FILTER_STATE' } => ({
  type: 'SET_FINAL_FILTER_STATE',
})

type ReducerAction =
  | ReturnType<typeof initialize>
  | ReturnType<typeof handleFilterChange>
  | ReturnType<typeof setFinalFilterState>

const reducer: Reducer<ReducerState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const ndx = crossfilter(action.filterOptions)
      const dimensions: Dimensions = {}
      const filters: Filter[] = []
      const selectedFilters: { name: string; values: any[] }[] = []
      Object.keys(action.filterOptions[0]).forEach((option) => {
        const dimension = ndx.dimension((d) => d[option])
        // @ts-ignore
        dimensions[option] = ndx.dimension((d) => d[option])
        const saved = action.savedFilters?.find((s) => s.name === option)
        const items: Checkbox[] = []
        const selected: string[] = []
        dimension
          .group()
          .all()
          .forEach((e) => {
            const id = e.key as string
            let isChecked = false
            if (saved && saved.items.length > 0) {
              isChecked = saved.items.some((i) => i.id === e.key && i.isChecked)
            }
            if (isChecked) {
              selected.push(id)
            }
            items.push({
              id,
              label: id,
              isChecked,
              isDisabled: false,
            })
          })

        if (selected.length > 0) {
          selectedFilters.push({ name: option, values: selected })
        }
        filters.push({
          name: option,
          title: action.getTitle ? action.getTitle(option) : option,
          items: items.sort(sortFilters),
        })
      })
      return {
        ...state,
        ndx,
        dimensions,
        filters,
        selectedFilters,
      }
    }
    case 'HANDLE_FILTER_CHANGE': {
      const newFilters = state.filters.map((filter) => {
        if (filter.name !== action.groupName) {
          return filter
        }
        const itemsShallowCopy = [...filter.items]
        action.changes.forEach((elem) => {
          itemsShallowCopy[elem.index] = {
            ...itemsShallowCopy[elem.index],
            isChecked: elem.isChecked,
          }
        })
        return { ...filter, items: itemsShallowCopy }
      })
      return {
        ...state,
        filters: newFilters,
        lastChanged: action.groupName,
        isDone: false,
      }
    }
    case 'SET_FINAL_FILTER_STATE': {
      const sf: { name: string; values: any[] }[] = []
      const { dimensions, ndx } = state
      state.filters.forEach((filter) => {
        if (dimensions && dimensions[filter.name]) {
          const selected = filter.items
            .filter((item) => item.isChecked)
            .map((item) => item.id)
          dimensions[filter.name].filterAll()
          if (selected.length > 0) {
            dimensions[filter.name].filter((f) => {
              // FIXME: Should not ignore this!
              // @ts-ignore
              return selected.includes(f)
            })
            sf.push({ name: filter.name, values: selected })
          }
        }
      })

      const newCrossfilter = crossfilter(ndx?.allFiltered())
      const newFilters = state.filters.map((filter) => {
        const set = new Set()
        newCrossfilter
          .dimension((d) => d[filter.name])
          .group()
          .all()
          .forEach((elem) => {
            set.add(elem.key)
          })
        const hasSomeSelected = filter.items.some((e) => e.isChecked)
        const newItems = filter.items
          .map((item) => {
            /**
             * if last changed AND still have some selected keep current disable status
             */
            let isDisabled = false
            if (item.isChecked) {
              isDisabled = false
            } else if (state.lastChanged === filter.name) {
              isDisabled = hasSomeSelected ? item.isDisabled : false
            } else {
              isDisabled = !set.has(item.id)
            }
            return {
              ...item,
              isDisabled,
            }
          })
          .sort(sortFilters)
        return { ...filter, items: newItems }
      })

      return {
        ...state,
        filters: newFilters,
        lastChanged: null,
        selectedFilters: sf,
      }
    }

    default: {
      return state
    }
  }
}

interface Props<T> {
  // Array of distinct filter combinations
  filterOptions: { [K in keyof T]: T[K] }[]
  // Callback that can be used to pass on the title for each filter group
  getTitle?: (name: string) => string
  // Filter selections used to initialize the state
  savedFilters?: SavedFilter[]
  // Defaults to 1000
  debouncerDelay?: number
}

/**
 * This hook can be used to select, deselct, and disable filter options built from an array of distinct filter combinations.
 * For example, you can pass it: 
 * [
  {
    brand: 'brand1',
    customer_type: 'COMMERCIAL',
    market: 'NEISO',
  },
  {
    brand: 'brand1',
    customer_type: 'COMMERCIAL',
    market: 'NYISO',
  },
  {
    brand: 'brand1',
    customer_type: 'RESIDENTIAL',
    market: 'NEISO',
  }]

  The hook would output a set of filters for brand, customer_type, and market. If a user then selects the market NYISO, then customer_type: 'RESIDENTIAL' would be disabled, since it's not a part of the selected intersections.

  Furthermore, this hook contains a simple debouncer that once activated will calculate the newest selectedFilters so as to provide the component using this hook with the latest selected filters. 
 */
const useSimpleFilters = <T>({
  filterOptions,
  getTitle,
  savedFilters,
  debouncerDelay = 1000,
}: Props<T>) => {
  const [state, dispatch] = useReducer(reducer, {
    ndx: crossfilter([]),
    dimensions: {},
    filters: [],
    lastChanged: null,
    selectedFilters: [],
  })
  const [debouncer] = useState<any>({ timeout: null })
  useEffect(() => {
    if (filterOptions.length > 0) {
      dispatch(initialize({ filterOptions, getTitle, savedFilters }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions, savedFilters])

  function handleChange(groupName, changes) {
    dispatch(handleFilterChange({ groupName, changes }))
  }

  useEffect(() => {
    if (state.lastChanged) {
      if (debouncer.timeout) {
        clearTimeout(debouncer.timeout)
      }
      debouncer.timeout = setTimeout(() => {
        debouncer.timeout = null
        dispatch(setFinalFilterState())
      }, debouncerDelay)
    }

    return () => {
      if (debouncer.timeout) {
        clearTimeout(debouncer.timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters])

  return {
    filters: state.filters,
    onChange: handleChange,
    selectedFilters: state.selectedFilters,
  }
}

export default useSimpleFilters
