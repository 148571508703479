import React from 'react'
import MainMenuButton from 'shared/components/MainMenuButton'
import productModuleConfig from 'modules/supply/products/risk'
import lsConfig from 'modules/supply/products/loadScheduling'
import renewableConfig from 'modules/supply/products/renewableMangement'
import { ReactComponent as RiskManagementIcon } from 'icons/risk-management-submodule.svg'
import { ReactComponent as LoadSchedulingIcon } from 'icons/load-scheduling-submodule.svg'
import { ReactComponent as RenewableManagementIcon } from 'icons/renewable-management-submodule.svg'
import { ApplicationProduct, ProductModuleButtonComponent } from 'shared/types'

const buttonProps = {
  label: 'Supply',
  name: 'supply',
  description:
    'Predict and control risk, schedule loads, and manage your renewable generation for today and tomorrow.',
}

export const SUPPLY_PRODUCTS: Array<ApplicationProduct> = [
  {
    mod: 'mod_supply',
    product: 'prod_risk_management',
    path: '/risk/risk-management',
    config: productModuleConfig,
    label: 'Risk Management',
    icon: <RiskManagementIcon />,
    active: false,
    isExternalLink: false,
  },
  {
    mod: 'mod_supply',
    product: 'prod_load_scheduling',
    path: 'https://ease.innowatts.com/load-scheduling/client/overview',
    config: lsConfig,
    label: 'Load Scheduling',
    icon: <LoadSchedulingIcon />,
    isExternalLink: true,
    active: false,
  },
  {
    mod: 'mod_supply',
    product: 'prod_renewable_management',
    path: '/risk/renewable-management',
    config: renewableConfig,
    label: 'Renewable Management',
    icon: <RenewableManagementIcon />,
    active: false,
    isExternalLink: false,
  },
]

const SupplyButton = (({
  products,
}: {
  products: Array<ApplicationProduct>
}) => {
  const { label, name, description } = buttonProps

  return (
    <MainMenuButton
      label={label}
      description={description}
      products={products}
      name={name}
    />
  )
}) as ProductModuleButtonComponent

export default SupplyButton
