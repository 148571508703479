import { ProductModuleConfig } from 'shared/types'
import LoadScheduling, { LoadSchedulingMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: LoadSchedulingMenu,
  MainContent: LoadScheduling,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
