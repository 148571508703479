import { LatLng } from 'leaflet'

export interface LoadLegendMap {
  min: {
    value: number
    color: string
  }
  max: {
    value: number
    color: string
  }
}

export interface Bounds {
  northEast: LatLng
  southWest: LatLng
}

export enum SeriesToColorAndSizeByTypes {
  Load = 'load',
  Group = 'group',
}

export interface SeriesNameAndType {
  name: string
  type: SeriesToColorAndSizeByTypes
}
export interface MapSettings {
  mapCenterCoordinates: LatLng
  color: SeriesNameAndType | undefined
  size: SeriesNameAndType | undefined
  zoom: number | undefined
}
