import React from 'react'
import { NavLink } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import { Paper, styled as muiStyled, Divider, Theme } from '@material-ui/core'

const commonNavLinkAttributes = {
  textDecoration: 'none',
  color: 'inherit',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  padding: '0 1em',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}

const StyledNavLink = muiStyled(NavLink)(({ theme }: { theme: Theme }) => ({
  ...commonNavLinkAttributes,
  fontWeight: 'bold',
  borderBottom: 'solid transparent 5px',
  '&.selected': {
    borderBottom: `solid ${theme.palette.primary.main} 5px`,
  },
}))

const StyledPaper = muiStyled(Paper)({
  height: '64px',
  padding: '0 1em',
  display: 'flex',
  alignItems: 'center',
})

const StyledMainNav = muiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  height: 'inherit',
})

/**
 * Common component used on the header bar for the
 * different modules.
 *
 * As new different content may be needed
 * for specific modules, the different parts could be encapsulated
 * and exported individually so the styling can be reused without
 * forcing the full behavior.
 */
const MenuHeader = ({
  routes,
}: {
  routes: Array<{ path: string; label: string }>
}) => {
  const { path: location } = useRouteMatch()
  return (
    <>
      <Divider flexItem orientation="vertical" />
      <StyledPaper elevation={0}>
        <StyledMainNav>
          {routes.map(({ path, label }) => (
            <StyledNavLink
              key={path}
              to={path}
              activeClassName={path === location ? 'selected' : ''}
            >
              {label}
            </StyledNavLink>
          ))}
        </StyledMainNav>
      </StyledPaper>
    </>
  )
}

export default MenuHeader
