import { useState, useEffect } from 'react'
import crossfilter, { Dimension, Crossfilter } from 'crossfilter2'
import reductio from 'reductio'
import { DateTime } from 'luxon'
import * as math from 'mathjs'
import { ValidAdjustmentCalculations, Adjustment } from 'shared/types'

interface SimpleForecast {
  timestamp: string
  processTime: string
  forecast: number
}

type IndividualForecast<T> = SimpleForecast & T

type Forecast<T> = Array<IndividualForecast<T>>

interface TimeFilters {
  timestamp: string
  date: string
  hour: number
  day: number
}

type AvailableFilters<T> = T & TimeFilters

export type AllAdjustmentsData<T> = SimpleForecast &
  TimeFilters &
  Adjustment & { selected: boolean } & T

type AdjustmentCrossfilter<T> = Crossfilter<AllAdjustmentsData<T>>

type FilterDimension<T> = Dimension<AvailableFilters<T>, any>

function generalSort(a, b, direction) {
  const dir = direction === 'asc' ? 1 : -1
  const isNumeric = typeof a === 'number'
  return (
    dir *
    a.toString().localeCompare(b.toString(), undefined, {
      numeric: isNumeric,
    })
  )
}

export interface Sort {
  column: string
  direction: 'asc' | 'desc'
}

const sortData = <T>(data: T[], arr: Sort[]) => {
  const sortedData = data.sort((a, b) => {
    return arr
      .map((s) => {
        const aValue = a[s.column]
        const bValue = b[s.column]
        if (aValue || bValue) {
          return generalSort(aValue, bValue, s.direction)
        }
        return 0
      })
      .reduce((prev, accum) => prev || accum)
  })
  return sortedData
}

const encodeAdjustmentId = <T>(elem: IndividualForecast<T>) => {
  const { processTime, forecast, ...rest } = elem
  return Object.values(rest).join('|')
}

const calculateAdjustedForecast = (
  forecast: number,
  roundBy: number,
  calculation?: ValidAdjustmentCalculations,
  value?: number,
) => {
  if (!value) {
    return forecast
  }
  if (!calculation || calculation === ValidAdjustmentCalculations.NoAction) {
    return forecast
  }
  const v = value || 0
  if (calculation === ValidAdjustmentCalculations.Custom) {
    return v
  }
  const evaluation = math.evaluate(`${calculation}`, { forecast, value })
  return math.round(evaluation, roundBy) as number
}

const replaceCrossfilterData = <T>(
  ndxInstance: AdjustmentCrossfilter<T>,
  updatedData: Partial<AllAdjustmentsData<T>>[],
  roundBy: number,
) => {
  const arr: AllAdjustmentsData<T>[] = []
  ndxInstance.remove((record) => {
    const recordToUpdate = updatedData.find((d) => d.id === record.id)
    if (recordToUpdate) {
      arr.push({
        ...record,
        ...recordToUpdate,
        adjustedForecast: calculateAdjustedForecast(
          record.forecast,
          roundBy,
          recordToUpdate.adjustmentCalculation,
          recordToUpdate.adjustmentValue,
        ),
      })
      return true
    }
    return false
  })
  ndxInstance.add(arr)
}

// To maintain order we always want to sort by id...
const cleanSortArray = (arr: Sort[]): Sort[] => {
  const hasIdColumn = arr.some((elem) => elem.column === 'id')
  if (hasIdColumn) {
    return [...arr]
  }
  return [...arr, { column: 'id', direction: 'asc' }]
}

const DEFAULT_SORT: Sort[] = [
  { column: 'date', direction: 'asc' },
  { column: 'id', direction: 'asc' },
]

interface Props<T> {
  // Trade-able forecast
  forecast: Forecast<T>
  // Array of initial adjustments to be added to the forecast on initialization
  initialAdjustments?: Adjustment[]
  // Initial sorting of data
  initialSortArray?: Sort[]
  timezone?: string
  roundBy?: number
}

/**
 * This hook controls data on adjustments... Feed it a forecast and you can filter, sort, and update the adjustment values.
 * This hook also keeps track of all new adjustments and can aggregate the forecast and adjustedForecast values
 */
const useAdjustmentsData = <T extends object>({
  forecast: forecastData,
  initialAdjustments,
  initialSortArray,
  timezone = 'utc',
  roundBy = 2,
}: Props<T>) => {
  const [ndx, setNdx] = useState<AdjustmentCrossfilter<T>>(crossfilter())
  const [availableFilters, setAvailableFilters] = useState<
    AvailableFilters<T>[]
  >([])
  const [dimensions, setDimensions] = useState<
    Map<keyof AvailableFilters<T>, FilterDimension<T>>
  >()
  const [filteredData, setFilteredData] = useState<AllAdjustmentsData<T>[]>([])
  const [availableFilterKeys, setAvailableFilterKeys] = useState<string[]>([])
  /** Used to check for new changes */
  const [initialAdjustmentsMap, setInitialAdjustmentsMap] = useState<
    Map<Adjustment['id'], Adjustment>
  >()
  /**
   * Primarily used to maintain a consistent sorting of crossfilter's allFiltered()
   */
  const [sortArray, setSortArray] = useState<Sort[]>([])
  /**
   * Primarily used for refiltering when doing crossfiltter mutations that need NO filters
   * (crossfilter doesn't have a way to get current filters)
   */
  const [internalFilters, setInternalFilters] = useState<Filter[]>([])

  // Initialization
  useEffect(() => {
    /**
     * All adjustments are based on a "encoded" adjustment id which is the unique row elements.... so all but the actual load
     */
    if (forecastData.length === 0) {
      return
    }
    const tempData = forecastData.map((elem) => {
      const timestampObj = DateTime.fromISO(elem.timestamp, {
        zone: timezone,
      })
      /**
       * Since we don't require adjustedCalculation as part of the forecast we initialize to NoAction
       * this ensures comparisons for new adjustments are accurate and simple.
       * Otherwise we would have a really complex check where we would need to look for undefined and no action calculations etc...
       */
      return {
        ...elem,
        id: encodeAdjustmentId(elem),
        forecast: math.round(elem.forecast, roundBy),
        adjustedForecast: math.round(elem.forecast, roundBy),
        adjustedCalculation: ValidAdjustmentCalculations.NoAction,
        date: timestampObj.toISODate(),
        hour: timestampObj.hour,
        day: timestampObj.day,
        selected: false,
      }
    })
    const tempNdx = crossfilter(tempData)
    /**
     * We want to make dimensions available that are equal to our AvailableFilters...
     */
    const tempDimension = tempNdx.dimension((dim) => {
      // from each dimension we remove unnecessary and unfilterable data... This is very difficult to type correctly in typescript
      const {
        forecast,
        id,
        adjustedForecast,
        processTime,
        adjustedCalculation,
        selected,
        ...rest
      } = dim
      setAvailableFilterKeys(Object.keys(rest))
      /**
       * we use the replacer function to preserve stringify key order at a top level
       * which should be sufficient
       */
      return JSON.stringify(rest, Object.keys(rest).sort())
    })
    const tempAvailableFilters = tempDimension
      .group()
      .reduceCount()
      .all()
      .map((elem) => JSON.parse(elem.key as string) as AvailableFilters<T>) // kind of cheating since... we're parsing...
    tempDimension.dispose()
    const {
      forecast,
      id,
      adjustedForecast,
      processTime,
      adjustedCalculation,
      selected,
      ...rest
    } = tempData[0]
    const tempDimensionMap: Map<
      keyof AvailableFilters<T>,
      FilterDimension<T>
    > = new Map()
    Object.keys(rest).forEach((key) => {
      const dimension = tempNdx.dimension((dim) => dim[key])
      tempDimensionMap.set(key as keyof AvailableFilters<T>, dimension)
    })

    if (initialAdjustments) {
      replaceCrossfilterData(
        tempNdx,
        initialAdjustments as Partial<AllAdjustmentsData<T>>[],
        roundBy,
      )
      // Build an initial adjustment map for quick comparisons
      const tempInitialAdjustmentsMap: Map<
        Adjustment['id'],
        Adjustment
      > = new Map()
      initialAdjustments.forEach((elem) => {
        tempInitialAdjustmentsMap.set(elem.id, elem)
      })
      setInitialAdjustmentsMap(tempInitialAdjustmentsMap)
    }

    const tempSortArray = cleanSortArray(initialSortArray || DEFAULT_SORT)

    setSortArray(tempSortArray)
    setDimensions(tempDimensionMap)
    setAvailableFilters(tempAvailableFilters)
    setNdx(tempNdx)
    setFilteredData(sortData(tempNdx.allFiltered(), tempSortArray))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastData, initialAdjustments, initialSortArray])

  const clearFilters = () => {
    dimensions?.forEach((val) => {
      val.filterAll()
    })
    setFilteredData(sortData(ndx.allFiltered(), sortArray))
  }

  interface Filter {
    name: keyof AvailableFilters<T>
    values: (string | number | boolean)[]
  }

  const filterData = (filters: Filter[]) => {
    dimensions?.forEach((dim, mapKey) => {
      dim.filterAll()
      const filter = filters.find((f) => f.name === mapKey)
      if (filter) {
        if (filter.values.length > 1) {
          dim?.filter((v) => filter.values.includes(v as any))
        } else {
          // Per crossfilter documentation this is a more performant filter since it is indexed for it.
          dim?.filter(filter.values[0])
        }
      }
    })
  }

  const updateData = (updatedData: Partial<AllAdjustmentsData<T>>[]) => {
    replaceCrossfilterData(ndx, updatedData, roundBy)
    setFilteredData(sortData(ndx.allFiltered(), sortArray))
  }

  const sort = (arr: Sort[]) => {
    const cleanArr = cleanSortArray(arr)
    setSortArray(cleanArr)
    const sortedData = sortData(ndx.allFiltered(), cleanArr)
    setFilteredData(sortedData)
  }

  const getAggregateData = (
    dim: keyof T | keyof TimeFilters = 'timestamp',
    shouldRemoveFilters?: boolean,
  ) => {
    if (shouldRemoveFilters) {
      dimensions?.forEach((d) => {
        d.filterAll()
      })
    }
    const dimension = dimensions?.get(dim)
    if (!dimension) {
      return []
    }
    const group = dimension.group()
    const reducer = reductio()
    reducer.value('forecast').sum('forecast')
    reducer.value('adjustedForecast').sum('adjustedForecast')
    const reduction = reducer(group).all() as {
      key: string | number
      value: {
        forecast: { sum: number }
        adjustedForecast: { sum: number }
      }
    }[]
    const result = reduction.map((e) => ({
      [dim]: e.key,
      forecast: e.value.forecast.sum,
      adjustedForecast: e.value.adjustedForecast.sum || 0, // in case it is undefined
    }))
    /**
     * IMPORTANT! This order matters... otherwise the result will mutate reduction
     * and will include filters...#raceconditions
     */
    if (shouldRemoveFilters) {
      filterData(internalFilters)
    }
    return result
  }

  const getAdjustments = (): Adjustment[] => {
    return ndx
      .all()
      .filter((elem) => {
        if (initialAdjustmentsMap?.get(elem.id)) {
          const initialAdjustmentValue = initialAdjustmentsMap.get(elem.id)
            ?.adjustmentValue
          const initialAdjustmentCalculation = initialAdjustmentsMap.get(
            elem.id,
          )?.adjustmentCalculation
          const isDifferent =
            initialAdjustmentValue !== elem.adjustmentValue ||
            initialAdjustmentCalculation !== elem.adjustmentCalculation
          return isDifferent
        }
        return (
          elem.adjustmentValue !== undefined ||
          elem.adjustmentCalculation !== undefined
        )
      })
      .map((elem) => ({
        id: elem.id,
        adjustmentValue: elem.adjustmentValue,
        adjustmentCalculation: elem.adjustmentCalculation,
        adjustedForecast: elem.adjustedForecast,
      }))
  }

  return {
    availableFilters,
    availableFilterKeys,
    filteredData,
    filter: (filters: Filter[]) => {
      filterData(filters)
      setInternalFilters(filters)
      setFilteredData(sortData(ndx.allFiltered(), sortArray))
    },
    update: updateData,
    clearFilters,
    sort,
    getAggregateData,
    getAdjustments,
    sortArray,
  }
}

export default useAdjustmentsData
