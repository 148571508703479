import React, { useState, ReactNode, DragEvent } from 'react'
import {
  Collapse,
  Typography,
  IconButton,
  styled,
  makeStyles,
} from '@material-ui/core'
import { MinusCircle, PlusCircle, ChevronDown, ChevronUp } from 'react-feather'
import ConditionalWrap from './ConditionalWrap'

interface CollapsibleProps {
  /**
   * Collapsible type best reflected by title's font size, style, and icon displayed. Options: `primary | secondary`
   */
  type?: 'primary' | 'secondary'
  /**
   * Title given collapsible that is displayed. If `type === 'primary'` this is required
   */
  title?: string
  /** If is collapsible whether the menu should start open */
  defaultMenuIsOpen?: boolean
  /** Whether to allow menu to collapse. If true it will display the appropriate icon button based on type */
  isCollapsible?: boolean
  /** Whether to remove collapse when not showing. This is especially important if you have nested collapsibles and you want a child collapsible to stay mounted even if hidden. This would essentially keep your collapse state in memory */
  unmountOnExit?: boolean
  /**
   * Optional function that will make the title (if given) draggable
   * and will be invoked on the "dragStart" event.
   */
  onTitleDragStart?: (e: DragEvent) => void
  /** Anything that should display under collapsible */
  children: React.ReactNode
}

const StyledTitleDiv = styled('div')(() => ({
  border: '2px dashed transparent',
  width: 'inherit',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '45px',
}))

// TODO: Consolidate all draggable styles / components as an element
const useStyles = makeStyles((theme) => ({
  draggable: {
    '&[draggable]:hover': {
      border: '2px dashed',
      borderColor: theme.palette.divider,
      cursor: 'grab',
    },
    '&[draggable]:active': {
      cursor: 'grabbing',
    },
  },
  collapsePrimaryTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  collapseSecondaryTitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
}))

/**
 * The Collapsible component is used throughout Innowatts Modules to build options such as __filters__ or __draggable menus__. It will render chldren passed to it.
 *
 * This component provides a primary or secondary view with optional collapsible property of its children. The collapsing action is controlled directly by the component
 *
 * This component looks great when wrapped by any presentational component such as `<Paper />` or `<Card />`. Its width will adapt to its parent width.
 *
 * __NOTE__: If collapsible component is primary it requires a title. Otherwise title is optional
 */
const Collapsible = ({
  type = 'primary',
  title,
  isCollapsible = true,
  defaultMenuIsOpen = false,
  unmountOnExit = false,
  onTitleDragStart,
  children,
}: CollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(defaultMenuIsOpen)
  const classes = useStyles()
  if (type === 'primary' && isCollapsible && !title) {
    throw new Error('Collapsible of type primary requires a title')
  }
  let top: ReactNode = null
  if (isCollapsible || title) {
    const wrapperProps = onTitleDragStart
      ? { draggable: true, onDragStart: onTitleDragStart }
      : null
    const draggableClass = onTitleDragStart ? classes.draggable : undefined
    top = (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <StyledTitleDiv className={draggableClass} {...wrapperProps}>
        <Typography
          variant="subtitle1"
          className={classes.collapsePrimaryTitle}
        >
          {title}
        </Typography>
        {isCollapsible && (
          <IconButton
            size="small"
            color="primary"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <MinusCircle /> : <PlusCircle />}
          </IconButton>
        )}
      </StyledTitleDiv>
    )
    if (type === 'secondary') {
      top = (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledTitleDiv className={draggableClass} {...wrapperProps}>
          {title && (
            <Typography
              variant="body1"
              className={classes.collapseSecondaryTitle}
            >
              {title}
            </Typography>
          )}
          {isCollapsible && (
            <IconButton
              size="small"
              title={title}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          )}
        </StyledTitleDiv>
      )
    }
  }
  return (
    <div>
      {top}
      <ConditionalWrap
        isWrapped={isCollapsible}
        wrap={(kids) => (
          <Collapse in={isOpen} timeout="auto" unmountOnExit={unmountOnExit}>
            {kids}
          </Collapse>
        )}
      >
        {children}
      </ConditionalWrap>
    </div>
  )
}

export default Collapsible
