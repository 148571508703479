import { Box, makeStyles, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import { DateRange, CheckCircleTwoTone } from '@material-ui/icons'
import { DateTime } from 'luxon'
import SummaryCard from '../../../../../shared/components/SummaryCard/SummaryCard'
import { Adjustment } from './LoadForecastTypes'
import { SummaryCardAction } from '../../../../../shared/components/SummaryCard/SummaryCardActions'

const { PUBLIC_URL } = process.env
const loadForecastIcon = `${PUBLIC_URL}/icons/load-forecast.svg`

const CardHeader = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    color: theme.palette.grey[600],
    lineHeight: '20px',
    fontWeight: 600,
  },
}))(Typography) as typeof Typography

const CardBody = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.grey[600],
  },
}))(Typography) as typeof Typography

const AdjustedSparklines = withStyles(() => ({
  root: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}))(Box) as typeof Box

const NoAdjustment = withStyles(() => ({
  root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    width: '100%',
    height: '100%',
  },
}))(Box) as typeof Box

const Details = withStyles((theme) => ({
  root: {
    '& > *:first-child': {
      marginTop: theme.spacing(1),
    },
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    '& > *:last-child': {
      marginBottom: theme.spacing(1),
    },
  },
}))(Box) as typeof Box

const GrayIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
}))(DateRange) as typeof DateRange

const ApprovedIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.success.light,
    marginRight: theme.spacing(1),
  },
}))(CheckCircleTwoTone) as typeof CheckCircleTwoTone

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  adjustment: Adjustment
  actions: SummaryCardAction[]
  useActions?: boolean
  onSelect?: (adjustment: Adjustment) => void
  selected?: boolean
}

const AdjustmentCard = ({
  adjustment,
  actions,
  useActions,
  onSelect,
  selected,
}: Props) => {
  const classes = useStyles()

  const renderSubtitle = () => {
    return (
      <Details>
        {adjustment.approvedBy && adjustment.approvedOn ? (
          <Box display="flex">
            <ApprovedIcon />
            <Box>
              <CardHeader variant="subtitle2" color="textSecondary">
                Approved by
              </CardHeader>
              <CardBody variant="subtitle2" color="textSecondary">
                {adjustment.approvedBy} on {adjustment.approvedOn.toISODate()}
              </CardBody>
            </Box>
          </Box>
        ) : (
          ''
        )}
        <Box display="flex" alignItems="flex-start">
          <img
            className={classes.icon}
            src={loadForecastIcon}
            alt="Load Forecast"
          />
          <Box>
            <CardHeader variant="subtitle2" color="textSecondary">
              Forecast used
            </CardHeader>
            <CardBody variant="subtitle2" color="textSecondary">
              Generated on{' '}
              {DateTime.fromISO(adjustment.processTime).toLocaleString({
                year: 'numeric',
                day: 'numeric',
                weekday: 'short',
                hour: 'numeric',
                minute: '2-digit',
                month: 'short',
              })}
            </CardBody>
          </Box>
        </Box>
        <Box display="flex">
          <GrayIcon />
          <Box>
            <CardHeader variant="subtitle2" color="textSecondary">
              Forecasted days
            </CardHeader>
            <CardBody variant="subtitle2" color="textSecondary">
              From {adjustment.from.toISODate()} to {adjustment.to.toISODate()}
            </CardBody>
          </Box>
        </Box>
      </Details>
    )
  }

  return (
    <SummaryCard
      key={adjustment.processTime.toString()}
      selected={selected}
      useActions={useActions}
      onSelect={onSelect}
      actions={actions}
      subtitle={renderSubtitle()}
      title={adjustment.name}
      badges={[{ value: adjustment.market, color: 'primary' }]}
      visualisation={
        <Box position="relative" minHeight={60}>
          {adjustment.adjustedForecast &&
          adjustment.adjustedForecast.length > 0 ? (
            <AdjustedSparklines data-testid="adjustment-spark">
              <Sparklines data={adjustment.adjustedForecast}>
                <SparklinesLine
                  color="#F97316"
                  style={{ fill: 'none', strokeWidth: 1.5 }}
                />
              </Sparklines>
            </AdjustedSparklines>
          ) : (
            <NoAdjustment>
              <Typography variant="subtitle2">No adjustment data</Typography>
            </NoAdjustment>
          )}
          <Sparklines data={adjustment.forecast} data-testid="forecast-spark">
            <SparklinesLine color="#0072CE" style={{ strokeWidth: 1.5 }} />
          </Sparklines>
        </Box>
      }
      infoBoxes={[
        { header: 'Min', value: adjustment.min },
        { header: 'Max', value: adjustment.max },
        { header: 'Days', value: adjustment.dayCount },
        { header: 'Total', value: adjustment.count },
      ]}
      caption={`${
        adjustment.createdAt === adjustment.updatedAt
          ? 'Created at '
          : 'Updated at '
      }${DateTime.fromISO(adjustment.updatedAt).toISODate()}`}
      interactions={adjustment.approvedBy ? [adjustment.approvedBy] : undefined}
    />
  )
}

export default AdjustmentCard
