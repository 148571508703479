import React from 'react'
import { useRouteMatch, Switch, Route } from 'react-router'
import Adjustments from 'modules/demand/products/loadForecast/pages/Adjustments'
import AdjustmentsPage from 'modules/demand/products/loadForecast/pages/AdjustmentsPage'
import CreateNewAdjustment from 'modules/demand/products/loadForecast/components/CreateNewAdjustment'

const AdjustmentRoutes = () => {
  const match = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={match.path}>
          <Adjustments />
        </Route>
        <Route exact path={`${match.path}/create`}>
          <CreateNewAdjustment />
        </Route>
        <Route exact path={`${match.path}/create/:forecast`}>
          <CreateNewAdjustment />
        </Route>
        <Route
          path={`${match.path}/:id`}
          render={({ match: routeMatch }) => {
            if (!routeMatch.params.id) {
              return null
            }
            return <AdjustmentsPage adjustmentId={routeMatch.params.id} />
          }}
        />
      </Switch>
    </>
  )
}

export default AdjustmentRoutes
