import React, {
  useState,
  useEffect,
  ReactElement,
  useContext,
  SyntheticEvent,
} from 'react'
import { useLocation } from 'react-router-dom'
import { updateDashboard } from 'shared/apiClient'
import AddNewDashboardDialog from 'shared/components/AddNewDashboardDialog'
import DashboardDropdown from 'shared/components/DashboardDropdown'
import PageLayout, { Body, Header } from 'shared/components/DashboardLayout'
import useDashboards from 'shared/hooks/useDashboards'
import {
  CalculatorState,
  Dashboard,
  DashboardConfigItem,
  DraggableResizableCard,
  Filter,
  GeneralDateSelector,
} from 'shared/types'
import SkeletonDashboard from 'shared/components/SkeletonDashboard'
import { createNewUnsavedDashboardObject } from 'shared/utils/dashboardHelpers'
import UserContext from 'shared/contexts/UserContext'
import { SavedRangeFilters } from 'shared/hooks/useRangeFilters'
import AlertContext from '../contexts/AlertsContext'

interface SaveObject {
  cards: Array<DraggableResizableCard>
  filters: Array<Filter>
  timeFilters: Array<Filter>
  calculators?: Array<CalculatorState>
  timezone?: string
  isHourEndingSelected?: Boolean
  from?: GeneralDateSelector
  to?: GeneralDateSelector
  rangeFilters?: Array<SavedRangeFilters>
}

export type HandleSave = (e: SyntheticEvent, saveObject: SaveObject) => void
export interface RenderDashboard {
  handleSave: HandleSave
  dashboard: Dashboard
  dashboardConfigItem: DashboardConfigItem
}

interface Props {
  /** Dashboard id to display */
  id: string | undefined
  /** All items in the dashboard type configuration */
  dashboardConfigItems: Array<DashboardConfigItem>
  /** callback that returns a selected dashboard from the header dopdown */
  onDashboardSelect: (dashboardId: string) => void
  /** callback that returns a newly generated id from a newly created dashboard */
  onDashboardCreate: (id: string) => void
  /** callback that should return a react element to display as part of the main dashboard layout */
  renderDashboard: (obj: RenderDashboard) => ReactElement
  /** Main type of dashboard used to fetch appropriate dashboards */
  dashboardType: string
}

/**
 * Renders the dashboard returned by `renderDashboard`. This component will fetch all user dashboards in the appropriate dashboard type. It will find its own dashboard to display based on the dashboard `id` passed as a prop. This page will display a skeleton of the dashboard as it loads. Additionally, if the id does not match a dashboard it will display a 404, and other error messages depending on the errors.
 */
const DashboardDetail = ({
  id,
  dashboardConfigItems,
  onDashboardSelect,
  onDashboardCreate,
  renderDashboard,
  dashboardType,
}: Props) => {
  const [state, setState] = useState<{
    dashboard: Dashboard | null
    dashboardConfigItem: DashboardConfigItem | null
  } | null>(null)

  const { userId: currentUserId } = useContext(UserContext)

  const publishNotification = useContext(AlertContext)

  const location = useLocation()

  const { handleAddNewDashboard, isLoading, dashboards } = useDashboards(
    dashboardType,
  )

  const [isFormOpen, setIsCreatingDashboard] = useState<boolean>(false)
  const handleAddNewClose = () => {
    // if dialog is open and loading do not close
    setIsCreatingDashboard((prevState) => prevState && isLoading)
  }

  useEffect(() => {
    /**
     * TODO: Handle usecase where refreshing page when unsaved it gives a 431 error
     */
    if (id === 'unsaved') {
      try {
        const query = new URLSearchParams(location.search)
        const encodedObject = query.get('object')
        const decodedObject = Buffer.from(
          encodedObject as string,
          'base64',
        ).toString()
        const objectToCreateDashboard = JSON.parse(decodedObject)
        const newUnsavedDash = createNewUnsavedDashboardObject(
          objectToCreateDashboard,
          dashboardConfigItems,
          currentUserId,
        ) as Dashboard
        if (newUnsavedDash) {
          setState({
            dashboard: newUnsavedDash,
            dashboardConfigItem:
              dashboardConfigItems.find(
                (config) => config.configId === newUnsavedDash?.configId,
              ) || null,
          })
        }
      } catch (error) {
        setState({
          dashboard: null,
          dashboardConfigItem: null,
        })
      }
    } else if (dashboards && dashboards.length > 0) {
      const selectedDashboard =
        dashboards.find((dashboard) => dashboard.dashboardId === id) || null
      const selectedDashboardConfigItem =
        dashboardConfigItems.find(
          (config) => config.configId === selectedDashboard?.configId,
        ) || null

      setState({
        dashboard: selectedDashboard,
        dashboardConfigItem: selectedDashboardConfigItem,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dashboards])

  const handleSave: HandleSave = (
    e,
    {
      cards,
      filters,
      rangeFilters,
      timeFilters,
      calculators,
      timezone,
      isHourEndingSelected,
      from,
      to,
    },
  ) => {
    if (state?.dashboard) {
      const cleanFilters = (filtersToClean) =>
        filtersToClean.map((f) => {
          return {
            name: f.name,
            items: f.items
              .filter((s) => s.isChecked)
              .map((s) => ({ id: s.id, isChecked: s.isChecked })),
          }
        })
      updateDashboard(state.dashboard.dashboardId, {
        cards,
        rangeFilters,
        filters: cleanFilters(filters),
        timeFilters: cleanFilters(timeFilters),
        calculators,
        timezone,
        isHourEndingSelected,
        // cast nulls to zeros (api only accepts numbers)
        from: { ...from, quantity: from?.quantity || 0 },
        to: { ...to, quantity: to?.quantity || 0 },
      })
        .then(() => {
          publishNotification('Dashboard saved successfully', 'success')
        })
        .catch((err) => {
          if (err.response.status === 404) {
            /**
             * A 404 error is technically not found. The backend will return a 404 because it tries to update a dashboard not owned by the requester.
             */
            publishNotification(
              'You cannot save a dashboard that was shared with you',
              'error',
            )
          }
        })
    }
  }

  const content = () => {
    if (state === null) {
      return <SkeletonDashboard />
    }
    if (!state.dashboard) {
      return (
        <Body>
          <h2>404</h2>
          <h3>DASHBOARD {id} NOT FOUND</h3>
        </Body>
      )
    }
    if (!state.dashboardConfigItem) {
      return (
        <Body>
          <h3>DASHBOARD DOES NOT HAVE VALID CONFIGURATION</h3>
        </Body>
      )
    }
    return renderDashboard({
      handleSave,
      dashboard: state.dashboard,
      dashboardConfigItem: state.dashboardConfigItem,
    })
  }

  return (
    <>
      <PageLayout>
        <Header>
          <DashboardDropdown
            items={dashboards.map((d) => {
              return {
                id: d.dashboardId,
                label: d.name,
                description: d.description,
                chips: [d.category, d.level, d.commodity].filter(
                  (e): e is { name: string; label: string } => e !== undefined,
                ),
              }
            })}
            selectedItemId={
              state?.dashboard ? state?.dashboard?.dashboardId : ''
            }
            onItemSelect={(e, newDashboardId) =>
              onDashboardSelect(newDashboardId)
            }
            onDashboardCreate={() => setIsCreatingDashboard(true)}
          />
        </Header>
        {content()}
      </PageLayout>
      {isFormOpen && (
        <AddNewDashboardDialog
          onAdd={(model) =>
            handleAddNewDashboard(model, dashboardType, (dashboardId) => {
              setIsCreatingDashboard(false)
              onDashboardCreate(dashboardId)
            })
          }
          onClose={handleAddNewClose}
          dashboardConfigItems={dashboardConfigItems}
          isAwaitingConfirmation={isLoading}
        />
      )}
    </>
  )
}

export default DashboardDetail
