import { GridColDef } from '@material-ui/data-grid'
import { DateTime } from 'luxon'
import reductio from 'reductio'
import { getDataSeriesTypeInfo } from 'shared/common/units'
import { isTimeSeries } from 'shared/helpers'
import {
  AsyncData,
  AxisId,
  CardTypeUnits,
  DataSeries,
  DataSeriesType,
  SingleCalculationOption,
  Visualization,
  SingleSplitOption,
  DataSeriesTypeUnit,
  CardDefinition,
  Group,
  DataSeriesData,
  Calculation,
  LegendPosition,
} from 'shared/types'
import createPivotTableData, { Field } from 'shared/utils/createPivotTableData'
import { areDataSeriesSameType } from 'shared/utils/dataSeriesHelpers'

/*
  Module for reusable functions or constants for the
  different modules on this folder.
*/

interface SeriesDataAccessor {
  (seriesDataItem: any): any
}

interface SeriesIdentifierAccessor {
  (seriesDataItem: any, grouping: CardDefinition['group']): any
}

type AsyncDataSeriesData = AsyncData<DataSeriesData>

export interface GetDataHandler {
  (dataSeries: DataSeries): AsyncDataSeriesData
}

interface ProcessedDataSeriesSplitElement {
  label: string
  seriesData: Array<object>
  groupedData: object
}

/**
 * The different DataSeriesData objects
 * received for the different dataseries
 * are to be processed so there is no
 * need for redundant conditions to access
 * the different fields on the items or
 * to display the proper name on the legend
 * or table header.
 */
interface ProcessedDataSeriesData {
  splits: Array<ProcessedDataSeriesSplitElement>
  identifierAccessor: SeriesIdentifierAccessor
  mainValueAccessor: SeriesDataAccessor
  aggregateItems: DataSeriesData['aggregateItems']
  dataSeriesElement: DataSeries
}

/**
 * Interface for data that can be "painted" on a Plotly chart.
 */
interface PlotlyTrace {
  x: any[]
  y: any[]
  yaxis?: string
  opacity?: number
  type: string
  name: string
  line: object
}

/**
 * Interface for data that can be presented easily as
 * a table
 */
export type DataTableData =
  | Array<Array<string>>
  | { fields: any; data: Array<Array<string>> }

interface ProcessOutput {
  /**
   * Collection of errors found when retrieving or
   * displaying the data.
   */
  errors: Array<{ dataSeriesElement: DataSeries; error: Error }>
  /**
   * Value that indicates if there is still data being
   * fetched.
   */
  isLoading: boolean
  /**
   * Value that indicates if the data received is time-based,
   * which means that every value is related to an specific
   * date.
   */
  isUsingDateIdentifiers: boolean
  /**
   * Utility function to retrieve the traces to display on
   * a Plotly component. These are the appropriate traces
   * for the given visualization.
   * If the visualization wouldn't show a plotly component,
   * then it's just an empty array.
   */
  getTraces: () => Array<PlotlyTrace>
  /**
   * Utility function to retrieve the data that should be
   * downloaded as an array of arrays.
   */
  getTableData: () =>
    | Array<Array<string>>
    | { fields: any; data: Array<Array<string>> }
  /**
   * Check
   */
  getPivotTableData: any
  getSummaryData: () => Array<{ label: string; value: number }>
  getMeterTableData: () => {
    columns: GridColDef[]
    rows: Array<any>
    rowCount: number
  }
}

/**
 * Return an appropriate string to be displayed as the label
 * (or title) for a dataseries with a given a calculation.
 * @param label DataSeries label or a custom text that may be
 * related with the provided selected calculation.
 * @param selectedCalculation Optional selected calculation
 * associated with the provided label.
 */
export function createCardDataSeriesLabel(
  label: string,
  selectedCalculation?: SingleCalculationOption,
  selectedSplit?: SingleSplitOption | null,
) {
  const calculationLabel = selectedCalculation
    ? `${selectedCalculation[1].toUpperCase()} `
    : ''
  const splitLabel = selectedSplit ? ` [${selectedSplit[1].toUpperCase()}]` : ''

  return `${calculationLabel}${label}${splitLabel}`
}

/**
 * Returns an appropriate string to be used as label
 * for the units of a chart displaying the data of a
 * Dataseries.
 * @param dataSeries
 */
export function getUnitsLabelForDataSeries(
  dataSeriesArr: DataSeries[],
  axisId: AxisId,
  cardSelectedUnits: CardTypeUnits,
  isSummary?: Boolean,
) {
  const dataSeries = dataSeriesArr.find((d) => d.axisId === axisId)

  if (!dataSeries) return ''

  const cardSelectedUnit = cardSelectedUnits[axisId]

  const { label, selectedUnit } = getDataSeriesTypeInfo(
    dataSeries.type,
    cardSelectedUnit?.value,
  )

  // We don't want to show anything in the axis for dimensionless units unles percent
  if (dataSeries.type === DataSeriesType.Dimensionless) {
    if (selectedUnit.value === 'percent') {
      return isSummary ? selectedUnit.label : `(${selectedUnit.label})`
    }
    return ''
  }

  const mainLabel = label
  let unitLabel = ''

  if (dataSeries.type === DataSeriesType.Custom && dataSeries.customUnit) {
    unitLabel = dataSeries.customUnit
  } else if (selectedUnit.label !== '') {
    unitLabel = selectedUnit.label
  }

  if (unitLabel) {
    return `${isSummary ? '' : mainLabel} ${
      isSummary ? unitLabel : `(${unitLabel})`
    }`
  }

  return mainLabel
}

/**
 * this function gets the legend position settings based on
 * the legendPostion argument passed
 */
export function getLegend(legendPosition: LegendPosition | undefined) {
  const legendSettings = {
    legend: { legend: { orientation: 'v', x: 1.1, y: 1 }, xanchor: 'left' },
  }
  if (!legendPosition) {
    return legendSettings
  }
  switch (legendPosition) {
    case LegendPosition.Bottom: {
      return { legend: { orientation: 'h', x: 0, y: -0.1, yanchor: 'top' } }
    }
    case LegendPosition.Top: {
      return {
        legend: { orientation: 'h', x: 0, y: 1.01, yanchor: 'bottom' },
      }
    }
    case LegendPosition.Left: {
      return { legend: { orientation: 'v', x: -0.1, y: 1, xanchor: 'right' } }
    }
    case LegendPosition.Right: {
      return { legend: { orientation: 'v', x: 1.1, y: 1 }, xanchor: 'left' }
    }
    case LegendPosition.None: {
      return { showlegend: false }
    }
    default: {
      return legendSettings
    }
  }
}

/**
 * Returns the appropriate value for the
 * "chart" property for a PlotLy trace component based
 * on the selected visualization and the "Y" axis
 * it has to be shown on.
 * @param selectedVisualization
 * @param axisId
 */
function getPlotlyTraceProperties(
  selectedVisualization: Visualization,
  axisId: DataSeries['axisId'],
): { type: string; [index: string]: any } {
  const traceProperties: any = { type: 'line' }
  switch (selectedVisualization) {
    case Visualization.Line:
      traceProperties.type = 'scatter'
      break
    case Visualization.MultiAxis:
      traceProperties.type =
        !axisId || axisId === AxisId.Primary ? 'line' : 'bar'
      break
    case Visualization.Bar:
      traceProperties.type = 'bar'
      break
    case Visualization.Table:
      traceProperties.type = 'table'
      break
    case Visualization.Area:
      traceProperties.type = 'scatter'
      traceProperties.stackgroup = 'stackarea'
      break
    case Visualization.Stack:
      traceProperties.type = 'bar'
      break
    default:
  }
  if (traceProperties.type === 'bar') {
    traceProperties.opacity = 0.7
  }
  return traceProperties
}

/**
 * Properly transforms a collection of processed data
 * from DataSeries to an appropriate collection of
 * traces to be displayed on a PlotLy component.
 *
 * The returned collection will have a trace for each
 * dataseries on the provided data list.
 * @param dataList
 * @param visualization
 */
export function transformDataListToTraces(
  dataList: Array<ProcessedDataSeriesData>,
  visualization: Visualization,
  grouping: CardDefinition['group'],
  isHourEndingSelected: Boolean | undefined,
): Array<PlotlyTrace> {
  const traces = [] as Array<PlotlyTrace>

  dataList.forEach(
    ({ dataSeriesElement, splits, identifierAccessor, mainValueAccessor }) => {
      const splitsCopy = [...splits]
      splitsCopy
        .sort((splitA, splitB) => {
          return (
            mainValueAccessor(splitB.groupedData) -
            mainValueAccessor(splitA.groupedData)
          )
        })
        .forEach(({ seriesData, label }) => {
          const traceProperties = getPlotlyTraceProperties(
            visualization,
            dataSeriesElement.axisId,
          )

          let seriesDataToUse = seriesData
          if (grouping === Group.Hour24) {
            seriesDataToUse = seriesData
              .map((val) => {
                return isHourEndingSelected
                  ? {
                      ...val,
                      'hour-24': val['hour-24'] === 23 ? 0 : val['hour-24'] + 1,
                    }
                  : val
              })
              .sort((a, b) => a['hour-24'] - b['hour-24'])
          }

          const { x, y } = seriesDataToUse.reduce<{ x: any[]; y: any[] }>(
            (accPlotData, item) => {
              accPlotData.x.push(identifierAccessor(item, grouping) ?? label)
              accPlotData.y.push(mainValueAccessor(item))
              return accPlotData
            },
            { x: [], y: [] },
          )

          const trace = {
            yaxis:
              !dataSeriesElement.axisId ||
              dataSeriesElement.axisId === AxisId.Primary
                ? 'y1'
                : 'y2',
            line: {
              width: 2,
            },
            name: label,
            x,
            y,
            ...traceProperties,
          }
          traces.push(trace)
        })
    },
  )
  return traces
}

/**
 * Properly transforms a collection of processed data
 * from DataSeries to an appropriate collection of
 * traces to be displayed specifically as stacked
 * bars on a PlotLy component.
 *
 * The returned collection will have as many traces as
 * different identifiers can be found on the data items,
 * and the X axis will have a value for each dataseries.
 * @param dataList
 */
function transformDataListToStackChartTraces(
  dataList: Array<ProcessedDataSeriesData>,
  grouping: CardDefinition['group'],
) {
  const traces: Array<PlotlyTrace> = []
  const stackedPlotDataMap = new Map<any, PlotlyTrace>()
  dataList.forEach(({ identifierAccessor, mainValueAccessor, splits }) => {
    splits.forEach(({ label, seriesData }) =>
      seriesData.forEach((item) => {
        const identifier = identifierAccessor(item, grouping) ?? label
        if (!stackedPlotDataMap.has(identifier)) {
          const identifierTrace = {
            name: identifier,
            x: [],
            y: [],
            type: 'bar',
            line: {
              width: 2,
            },
          }
          stackedPlotDataMap.set(identifier, identifierTrace)

          traces.push(identifierTrace)
        }
        const identifierStackPlotData = stackedPlotDataMap.get(
          identifier,
        ) as PlotlyTrace
        identifierStackPlotData.x.push(label)
        identifierStackPlotData.y.push(mainValueAccessor(item))
      }),
    )
  })
  return traces
}

/** Returns a function to properly format the values for a grouping when
 * they are to be presented on a table (numbers to days of the week, properly date formatting, etc)
 */
function createTableGroupFormatter(
  grouping: Group,
  timezone: string = 'utc',
  isHourEndingSelected: Boolean = false,
): ((x: any) => string) | undefined {
  const createDateFormatter = (format: string) => (x: string) => {
    return isHourEndingSelected
      ? DateTime.fromISO(x, { zone: timezone })
          .plus({ hours: 1 })
          .toFormat(format)
      : DateTime.fromISO(x, { zone: timezone }).toFormat(format)
  }
  switch (grouping) {
    case Group.DayOfWeek:
      return (x) => DateTime.local().set({ weekday: x }).toFormat('cccc')
    case Group.Yearly:
      return createDateFormatter('yyyy')
    case Group.Monthly:
      return createDateFormatter('yyyy-LL')
    case Group.Daily:
      return createDateFormatter('yyyy-LL-dd')
    case Group.Hourly:
      return createDateFormatter('yyyy-LL-dd HH:mm')
    case Group.HalfHourly:
      return createDateFormatter('yyyy-LL-dd HH:mm')
    case Group.QuaterHourly:
      return createDateFormatter('yyyy-LL-dd HH:mm')
    case Group.Month12:
      return (x) => DateTime.local().set({ month: x }).toFormat('LLL')
    case Group.Hour24:
      return isHourEndingSelected
        ? (x: string) => `${parseInt(x, 10) === 23 ? 0 : parseInt(x, 10) + 1}`
        : (x: string) => x
    default:
      return undefined
  }
}

/**
 * Returns a function to properly obtain the value to be displayed on a cell of the
 * pivoted table from the reduced object.
 *
 * It expects that the reducer used for the different groupings to generate an object
 * that matches the next shape:
 *
 * ```
 * { avg: number, min: number, max: number, sum: number, dataList: Array<object> }
 * ```
 */
function createPivotTableGroupedValueGetter({
  dataSeriesElement,
  aggregateItems,
}: ProcessedDataSeriesData) {
  const [calculation] = dataSeriesElement.selectedCalculation || []
  switch (calculation) {
    case Calculation.Sum:
      return (cellItem) => cellItem.sum
    case Calculation.Average:
      return (cellItem) => cellItem.avg
    case Calculation.Min:
      return (cellItem) => cellItem.min
    case Calculation.Max:
      return (cellItem) => cellItem.max
    case Calculation.CountDistinct:
      /**
       * Returns "sum" because when the data is cross-filtered and then accessed,
       * the count value for a cellItem displays no. of reocrds count in the cross-filtered data
       * and the sum/avg/min/max value for a cellItem gives us distinct meter counts which is needed.
       * Hence, we chose "sum" to display the answer that is needed.
       */
      return (cellItem) => cellItem.sum
    default: {
      if (aggregateItems) {
        return (cellItem) => {
          aggregateItems(cellItem.dataList)
        }
      }
      // By default it will return "average"
      return (cellItem) => cellItem.avg
    }
  }
}

/* The next symbols are to be added to the complete set of data that will be grouped by the pivot table
creation function. Using symbols will ensure that no preexisting fields are overriden from the original data */
const tableItemSplitDataIndex = Symbol('dataseries split index')
const tableItemValue = Symbol('item value')

/**
 * Returns the appropriate "columns" and "rows" collections to be passed to the pivot table creation function.
 * @param isTableVisualization
 * @param definition
 * @param timezone
 * @param hourBeginningOrEnding
 */
function getRowsAndColumnsFieldsForPivotTable(
  isTableVisualization: boolean,
  definition: CardDefinition,
  timezone?: string,
  isHourEndingSelected?: Boolean,
) {
  const rows: Array<Field> = []
  const columns: Array<Field> = []

  /* If it's using table visualization, the rows and columns will come from the "tableGroups" field, in other case, it
  will just add the current grouping as row (if any). Usually meant to generate the data for the downloaded file. */
  if (isTableVisualization) {
    const tableGroups = definition.tableGroups ?? []
    tableGroups.forEach(({ header, groupType, direction }) => {
      const field: Field = {
        header,
        accessor: groupType,
        format: createTableGroupFormatter(
          groupType as Group,
          timezone,
          isHourEndingSelected,
        ),
      }
      if (direction === 'row') {
        rows.push(field)
      } else {
        columns.push(field)
      }
    })
  } else {
    const { group } = definition
    if (group !== null) {
      rows.push({
        header: definition.group as string,
        accessor: definition.group as string,
        format: createTableGroupFormatter(
          definition.group as Group,
          timezone,
          isHourEndingSelected,
        ),
      })
    }
  }

  return {
    rows,
    columns,
  }
}

/**
 * TODO: Do NOT run if data list is too large. This way we don't freeze the browser. Alternatively we'll need to run this as a web worker.
 */
function transformDataListToPivotTableData(
  definition: CardDefinition,
  dataList: ProcessedDataSeriesData[],
  timezone?: string,
  isHourEndingSelected?: Boolean,
) {
  /* This array will have the information for each dataseries split so it can be referenced by index
  from any item on the whole set of data */
  const sortedSplitsData: Array<{
    label: string
    getGroupedValue: (item: any) => number
  }> = []

  /* The pivot table will receive ALL the entries from ALL the dataseries splits, with some
  additional information to perform the grouping and retrieve the right "value" from each */
  const data: Array<object> = []

  dataList.forEach((dataSeriesData) => {
    const { mainValueAccessor } = dataSeriesData
    const getSplitGroupedValue = createPivotTableGroupedValueGetter(
      dataSeriesData,
    )
    dataSeriesData.splits.forEach((split) => {
      const { label, seriesData } = split
      /* All elements from the current dataseries split will store the index of its
      data (label and how to get the appropriate value from the grouped entries).

      That index will be used to both group together entries from the same dataseries split and also
      for the "rows" or "columns" to have the proper order when displayed on the table */
      const index = sortedSplitsData.length

      sortedSplitsData.push({
        label,
        getGroupedValue: getSplitGroupedValue,
      })

      seriesData.forEach((item) => {
        data.push({
          ...item,
          [tableItemSplitDataIndex]: index,
          [tableItemValue]: mainValueAccessor(item),
        })
      })
    })
  })

  /* The rows and columns fields can be created using the card definition. It will take care of formatting
  the grouping values (datetimes, days of the week...) and any time shifting by end/start */
  const isTableVisualization = definition.visualization === Visualization.Table
  const { rows, columns } = getRowsAndColumnsFieldsForPivotTable(
    isTableVisualization,
    definition,
    timezone,
    isHourEndingSelected,
  )

  /* The grouping by dataseries always goes last */
  const dataSeriesField: Field = {
    header: '' /* if used as "row", the header is empty */,
    accessor: tableItemSplitDataIndex,
    format: (index: number) => sortedSplitsData[index].label,
  }

  if (isTableVisualization && definition.tableSeriesDirection === 'row') {
    rows.push(dataSeriesField)
  } else {
    columns.push(dataSeriesField)
  }

  /* The "getGroupedValue" for each dataseries split will know how to get the proper
  value from the reduced object on each cell, usually depending on the calculation
  associated with its parent dataseries. */
  const reduceGroupedData = (groupedData) =>
    reductio()
      .avg((item) => item[tableItemValue])
      .min((item) => item[tableItemValue])
      .max(true)
      .dataList(true)(groupedData)

  const getGroupedValue = (item) => {
    /* There is always at least one element, and they are always grouped by "split" first,
    that means that the value on the "tableItemSplitDataIndex" field will be the same
    for everyone. */
    const sortedSplitDataIndex = item.dataList[0][tableItemSplitDataIndex]
    /* The way to get the proper value from the grouped item is on the
    related item inside the sorted splits data collection  */
    return sortedSplitsData[sortedSplitDataIndex].getGroupedValue(item)
  }

  return createPivotTableData(data, rows, columns, {
    getGroupedValue,
    reduceGroupedData,
  })
}

function transformDataToMeterTable(
  dataList: ProcessedDataSeriesData[],
  definition: CardDefinition,
): { columns: GridColDef[]; rows: Array<any>; rowCount: number } {
  const tableOptions: {
    columns: GridColDef[]
    rows: Array<any>
    rowCount: number
  } = {
    columns: [],
    rows: [],
    rowCount: 0,
  }

  const { group } = definition

  if (!group) {
    return tableOptions
  }

  const seriesToShowInColumns: DataSeries[] = []

  const [groupBySeries, ...rest]: Array<DataSeries> = definition.dataSeries

  seriesToShowInColumns.push(groupBySeries)

  if (rest.length) {
    seriesToShowInColumns.push(...rest)
  }

  seriesToShowInColumns
    .filter((e) => e)
    ?.forEach((series, index) => {
      const { label, selectedCalculation, selectedSplit, type, id } = series
      tableOptions.columns.push({
        field:
          series.id === `Unique-${group}`
            ? group
            : `series_${type}_${id}_${index}`,
        headerName: createCardDataSeriesLabel(
          label,
          selectedCalculation,
          selectedSplit,
        ),
        sortable: false,
        width: 200,
      })
    })

  const tableRowMap = new Map()

  // getting meter id and meter count and rest of the series for row data
  const [ids, count, ...restOfSeries] = dataList
  ids?.splits?.forEach((series) => {
    series.seriesData.forEach((data, index) => {
      tableRowMap.set(data[group], {
        [group]: data[group],
        id: index,
      })
    })
  })

  count?.splits?.forEach((series) => {
    series.seriesData.forEach((data) => {
      tableOptions.rowCount = data[group]
    })
  })

  restOfSeries?.forEach((series, index) => {
    series.splits.forEach((split) => {
      split.seriesData.forEach((data) => {
        if (tableRowMap.has(data[group])) {
          tableRowMap.set(data[group], {
            ...tableRowMap.get(data[group]),
            // added this to add +1 index to counter the meter id column
            [`series_${series.dataSeriesElement.type}_${
              series.dataSeriesElement.id
            }_${index + 1}`]: series.mainValueAccessor(data),
          })
        }
      })
    })
  })

  tableOptions.rows = Array.from(tableRowMap.values())

  return tableOptions
}

function buildSeriesDataAccessor(accessor: string): SeriesDataAccessor {
  return (item: object) => item[accessor]
}

function applyUnitsToMainValueAccessor(
  accessor: SeriesDataAccessor,
  dataSeriesType: DataSeriesType,
  explicitUnit?: DataSeriesTypeUnit,
): SeriesDataAccessor {
  const { converter } = getDataSeriesTypeInfo(
    dataSeriesType,
    explicitUnit?.value,
  )

  if (!converter) return accessor

  return (item) => converter(accessor(item))
}

const defaultIdentifierAccessor: SeriesIdentifierAccessor = (item, grouping) =>
  grouping !== null ? item[grouping] : null

function buildIdentifierAccessor(
  timezone?: string,
  isHourEndingSelected?: Boolean,
): SeriesIdentifierAccessor {
  if (!timezone) {
    return defaultIdentifierAccessor
  }
  return (item, grouping) => {
    if (isTimeSeries(grouping)) {
      return isHourEndingSelected
        ? DateTime.fromISO(item[grouping], { zone: timezone })
            .plus({ hours: 1 })
            .toISO()
        : DateTime.fromISO(item[grouping], { zone: timezone }).toISO()
    }
    return defaultIdentifierAccessor(item, grouping)
  }
}

/**
 * Processes the appropriate data series and
 * returns all the necessary information and utilities
 * to properly render the card.
 * @param dataSeries
 * @param getData
 * @param visualization
 */
export function processCardDataSeries(
  definition: CardDefinition,
  getData: GetDataHandler,
  timezone?: string,
  isHourEndingSelected?: Boolean,
): ProcessOutput {
  const {
    dataSeries,
    visualization,
    group: grouping,
    typeUnits: cardTypeUnits = {},
  } = definition

  const dataSeriesInView =
    visualization !== Visualization.MultiAxis
      ? dataSeries.filter(({ axisId }) => !axisId || axisId === AxisId.Primary)
      : dataSeries

  const processedDataSeriesInView: Array<ProcessedDataSeriesData> = []
  const errors: Array<{ dataSeriesElement: DataSeries; error: Error }> = []
  let isLoading = false
  /**
   * Reference data series are used to compare all our dataseries to be processed, mainly for the usecase where we don't want different units per axis
   */
  type RefDataSeries = {
    [key in AxisId]: null | DataSeries
  }
  const refDataSeries: RefDataSeries = {
    [AxisId.Primary]: null,
    [AxisId.Secondary]: null,
  }

  dataSeriesInView.forEach((elem) => {
    const axis = elem.axisId ?? AxisId.Primary
    if (!refDataSeries[axis]) {
      refDataSeries[axis] = elem
    }
  })

  dataSeriesInView.forEach((dataSeriesElement) => {
    // We first need to make sure we don't process data that is not the same type and unit as the reference
    const axisId = dataSeriesElement.axisId ?? AxisId.Primary
    const referenceDataSeries = refDataSeries[axisId]
    if (referenceDataSeries) {
      if (visualization !== Visualization.MeterTable) {
        if (!areDataSeriesSameType(referenceDataSeries, dataSeriesElement)) {
          errors.push({
            dataSeriesElement,
            error: new Error(
              `${dataSeriesElement.label} does not share units with ${referenceDataSeries.label}`,
            ),
          })
          return
        }
      }
    }
    // Now we do not process any element that has no data. No data and no error means it's loading ;)
    const { data, error } = getData(dataSeriesElement)
    if (!data) {
      if (error) {
        errors.push({ dataSeriesElement, error })
      } else if (!isLoading) {
        isLoading = true
      }
      return
    }
    /* To avoid redundant type-checking on the
    accessors and the proper label to render, the received
    data is further normalized to a format easier to
    handle */
    const mainLabel = createCardDataSeriesLabel(
      data.label || dataSeriesElement.label,
      dataSeriesElement.selectedCalculation,
    )

    const identifierAccessor = buildIdentifierAccessor(
      timezone,
      isHourEndingSelected,
    )

    const mainValueAccessor = applyUnitsToMainValueAccessor(
      buildSeriesDataAccessor(data.mainValueAccessor),
      dataSeriesElement.type,
      cardTypeUnits[dataSeriesElement.axisId || AxisId.Primary],
    )

    const splits = data.splits.map(
      ({ seriesData, splitValue, groupedData }) => ({
        seriesData,
        groupedData,
        label: `${mainLabel}${splitValue ? ` (${splitValue})` : ''}`,
      }),
    )

    const dataSeriesData: ProcessedDataSeriesData = {
      dataSeriesElement,
      identifierAccessor,
      mainValueAccessor,
      splits,
      aggregateItems: data.aggregateItems,
    }

    processedDataSeriesInView.push(dataSeriesData)
  })

  /* To have different data lists displayed with the same label is jarring and can
  give problems when calculating stack chart traces or table data, so a process
  to append "(n)" to them is done */
  const labelCountMap = new Map<string, number>()
  processedDataSeriesInView.forEach(({ splits }) => {
    splits.forEach((dataItem) => {
      const originalLabel = dataItem.label
      const repeatedCount = labelCountMap.get(originalLabel) || 0
      if (repeatedCount > 0) {
        /* As the data is being created and managed here, there is no
      risk mutating it at this point */
        // eslint-disable-next-line no-param-reassign
        dataItem.label = `${originalLabel} (${repeatedCount})`
      }
      labelCountMap.set(originalLabel, repeatedCount + 1)
    })
  })

  let pivotTableData: ReturnType<typeof transformDataListToPivotTableData> = {
    headers: [],
    items: [],
  }

  let summaryData = [] as Array<{ label: string; value: number }>

  let meterTableData: {
    columns: GridColDef[]
    rows: Array<any>
    rowCount: number
  } = {
    columns: [],
    rows: [],
    rowCount: 0,
  }

  let traces: Array<PlotlyTrace> = []

  switch (visualization) {
    case Visualization.Table:
      pivotTableData = transformDataListToPivotTableData(
        definition,
        processedDataSeriesInView,
        timezone,
        isHourEndingSelected,
      )
      break
    case Visualization.Stack:
      traces = transformDataListToStackChartTraces(
        processedDataSeriesInView,
        grouping,
      )
      break
    case Visualization.Summary: {
      traces = transformDataListToTraces(
        processedDataSeriesInView,
        visualization,
        grouping,
        isHourEndingSelected,
      )

      summaryData = traces
        .filter((serie) => serie.name && serie.y[0])
        .map((e) => ({
          label: e.name,
          value: e.y[0],
        }))

      break
    }
    case Visualization.MeterTable: {
      if (definition.group) {
        meterTableData = transformDataToMeterTable(
          processedDataSeriesInView,
          definition,
        )
      }
      break
    }
    default:
      traces = transformDataListToTraces(
        processedDataSeriesInView,
        visualization,
        grouping,
        isHourEndingSelected,
      )
  }

  const isUsingDateIdentifiers = grouping ? isTimeSeries(grouping) : false

  return {
    /**
     * Collection of errors found when retrieving or
     * displaying the data.
     */
    errors,
    /**
     * Value that indicates if there is still data being
     * fetched.
     */
    isLoading,
    /**
     * Value that indicates if the data received is time-based,
     * which means that every value is related to an specific
     * date.
     */
    isUsingDateIdentifiers,
    /**
     * Utility function to retrieve the traces to display on
     * a Plotly component. These are the appropriate traces
     * for the given visualization.
     * If the visualization wouldn't show a plotly component,
     * then it's just an empty array.
     */
    getTraces: () => traces,
    /**
     * Utility function to retrieve the information to display on the
     * table visualization as "headers" and "items". If the visualization
     * is not "Table", both of these values will just be empty arrays.
     */
    getPivotTableData: () => pivotTableData,
    /**
     * Utility function to retrieve data to be downloaded no matter the
     * visualization.
     */
    getTableData: () => {
      if (visualization === Visualization.MeterTable) {
        return {
          fields: meterTableData.columns.map((col) => col.headerName),
          data: meterTableData.rows.map((row) => {
            return [
              ...Object.entries(row)
                .filter(([key]) => key !== 'id')
                .map(([, value]) => value as string),
            ]
          }),
        }
      }
      const { headers, items } =
        visualization === Visualization.Table
          ? pivotTableData
          : transformDataListToPivotTableData(
              definition,
              processedDataSeriesInView,
              timezone,
              isHourEndingSelected,
            )
      return [headers.map((x) => x.join('|')), ...items]
    },
    getSummaryData: () => summaryData,
    getMeterTableData: () => meterTableData,
  }
}
