import React, { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Paper,
  Typography,
  styled as muiStyled,
  Divider,
  Theme,
} from '@material-ui/core'
import ColoredAvatar from 'shared/components/ColoredAvatar'
import { Palette } from '@material-ui/core/styles/createPalette'

const commonNavLinkAttributes = {
  textDecoration: 'none',
  color: 'inherit',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  padding: '0 1em',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}

const StyledTitleNavLink = muiStyled(NavLink)({
  ...commonNavLinkAttributes,
  borderBottom: 'solid transparent 8px',
})

const StyledNavLink = muiStyled(NavLink)(({ theme }: { theme: Theme }) => ({
  ...commonNavLinkAttributes,
  borderBottom: 'solid transparent 5px',
  '&.selected': {
    borderBottom: `solid ${theme.palette.primary.main} 5px`,
  },
}))

const StyledPaper = muiStyled(Paper)({
  height: '64px',
  padding: '0 1em',
  display: 'flex',
  alignItems: 'center',
})

const StyledTitle = muiStyled(Typography)({
  margin: 0,
  padding: '0 1em',
  fontWeight: 'bold',
})

const StyledMainNav = muiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  height: 'inherit',
})

/**
 * Common component used on the header bar for the
 * different modules.
 *
 * As new different content may be needed
 * for specific modules, the different parts could be encapsulated
 * and exported individually so the styling can be reused without
 * forcing the full behavior.
 */
const ProductModuleHeader = ({
  basePath = '/',
  color,
  icon,
  title,
  routes,
}: {
  basePath?: string
  color: keyof Palette
  icon: ReactElement
  title: string
  routes: Array<{ path: string; label: string }>
}) => {
  return (
    <>
      <Divider flexItem orientation="vertical" />
      <StyledPaper elevation={0} square>
        <StyledTitleNavLink to={basePath}>
          <ColoredAvatar color={color}>{icon}</ColoredAvatar>
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleNavLink>
      </StyledPaper>
      <Divider flexItem orientation="vertical" />
      <StyledPaper elevation={0} square>
        <StyledMainNav>
          {routes.map(({ path, label }) => (
            <StyledNavLink
              key={path}
              to={`${basePath}${path}`}
              activeClassName="selected"
            >
              {label}
            </StyledNavLink>
          ))}
        </StyledMainNav>
      </StyledPaper>
    </>
  )
}

export default ProductModuleHeader
