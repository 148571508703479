import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import Calculations from 'modules/customer/products/pricing/pages/Calculations'
import JobStatus from 'modules/customer/products/pricing/pages/JobStatus'
import Configurations from 'modules/customer/products/pricing/pages/Configurations'
import { ReactComponent as PricingIcon } from 'icons/pricing-submodule.svg'
import { ProductModuleContentComponent } from 'shared/types'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

const CALCULATIONS_ROUTE = {
  label: 'Calculations',
  path: '/calculations',
}

const JOB_STATUS_ROUTE = {
  label: 'Job Status',
  path: '/jobstatus',
}

const CONFIGURATIONS_ROUTE = {
  label: 'Configurations',
  path: '/configurations',
}

const menuOptions = [CONFIGURATIONS_ROUTE, CALCULATIONS_ROUTE, JOB_STATUS_ROUTE]

export const PricingMenu = (() => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      color="customer"
      title="Pricing"
      basePath={url}
      routes={menuOptions}
      icon={<PricingIcon />}
    />
  )
}) as ProductModuleContentComponent

const Pricing = (({ match, history, location }) => {
  const { url } = match
  const calculationsUrl = `${url}${CALCULATIONS_ROUTE.path}`
  const jobStatusUrl = `${url}${JOB_STATUS_ROUTE.path}`
  const configurationsUrl = `${url}${CONFIGURATIONS_ROUTE.path}`
  return (
    <Switch>
      <Route exact path={url}>
        <Redirect to={configurationsUrl} />
      </Route>
      <Route
        path={calculationsUrl}
        render={() => {
          const locationState: any = location.state
          return (
            <Calculations
              rerunJobUid={locationState?.jobUid}
              onNewJobViewRequest={() => history.push(jobStatusUrl)}
            />
          )
        }}
      />
      <Route
        path={jobStatusUrl}
        render={() => (
          <JobStatus
            onJobRerunRedirect={(jobUid: any) => {
              history.push(calculationsUrl, { jobUid })
            }}
          />
        )}
      />
      <Route
        path={configurationsUrl}
        render={() => {
          return <Configurations />
        }}
      />
    </Switch>
  )
}) as ProductModuleContentComponent

export function getEnhancedNotificationDescription(msg, baseUrl) {
  if (msg.code === 'JOB_CALC_START' || msg.code === 'JOB_COMPLETE') {
    return (
      <div>
        <Button component={Link} to={`${baseUrl}${JOB_STATUS_ROUTE.path}`}>
          See Job
        </Button>
      </div>
    )
  }
  return null
}

export default Pricing
