import React, { useEffect, useMemo, useState } from 'react'
import {
  Dashboard,
  Group,
  DataSeriesType,
  DataSeriesData,
  Calculation,
  DashboardConfigItem,
} from 'shared/types'
import { TextField } from '@material-ui/core'
import SideBarMenu from 'shared/components/SideBarMenu'
import DraggableMenu, {
  DraggableInnerMenuProps,
} from 'shared/components/DraggableMenu'
import {
  fetchDataForRisk,
  RiskFetchingInfo,
} from 'modules/supply/products/risk/helper'
import { createGroups, getLoadDataSeriesTypeByCommodity } from 'shared/helpers'
import { Autocomplete } from '@material-ui/lab'
import { DateTime } from 'luxon'
import BaseDashboard, {
  CardsDataSeriesManager,
} from 'shared/components/BaseDashboard/BaseDashboard'
import { HandleSave } from 'shared/pages/DashboardDetail'
import { calculationOptions } from 'shared/common/dashboardOptions'
import { setDataSeriesData } from 'shared/utils/dataSeriesDragAndDrop'
import {
  cleanFiltersForValidNetworkRequest,
  mergeSelectedFilters,
} from 'shared/utils/filtersOperations'
import { mergeDataSeries } from 'shared/utils/dataSeriesHelpers'
import useTimeAndDimensionFilters from 'shared/hooks/useTimeAndDimensionFilters'
import {
  getLatestAsOfDate,
  getRiskDistinctData,
  getMinMaxForColumns,
} from '../apiClient'

interface Props {
  datasource: string
  filtersDefinitions: DashboardConfigItem['filters']
  savedDashboardInfo: Dashboard
  onSave: HandleSave
  timeInterval: number
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
}

enum DataSeries {
  VOLUME = 'volume',
  MARK_TO_MARKET = 'mark_to_market',
}

const timeSeriesInnerMenu: DraggableInnerMenuProps[] = [
  {
    id: 'dataSeriesMenuOptions',
    isCollapsible: false,
    options: [
      {
        id: DataSeries.VOLUME,
        title: 'Volume',
      },
      {
        id: DataSeries.MARK_TO_MARKET,
        title: 'Mark to Market',
      },
    ],
  },
]

const buildAsOfDateOption = (elem: DateTime) => ({
  value: elem.toISO(),
  label: elem.toLocaleString(DateTime.DATETIME_MED),
})

export const useSupplyAndMarkToMarketkToMarket = ({
  savedDashboardInfo,
  datasource,
  filtersDefinitions,
  timeInterval,
  rangeFiltersDefinition,
}: Omit<Props, 'onSave'>) => {
  const [isHourEndingSelected, setIsHourEndingSelected] = useState<Boolean>(
    savedDashboardInfo?.isHourEndingSelected || false,
  )

  const [asOfDate, setAsOfDate] = useState<null | DateTime>(null)
  const [
    availableAsOfDates,
    setAvailableAsOfDates,
  ] = useState<Array<any> | null>([])

  useEffect(() => {
    getLatestAsOfDate(datasource).then((asOfDates) => {
      const options = asOfDates.map((d) => DateTime.fromISO(d, { zone: 'utc' }))
      setAvailableAsOfDates(options)
      setAsOfDate(options[0])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filters = useTimeAndDimensionFilters(
    savedDashboardInfo.filters,
    timeInterval,
    () =>
      getRiskDistinctData(
        datasource,
        filtersDefinitions.map((e) => e.name),
      ),
    filtersDefinitions,
    rangeFiltersDefinition,
    () =>
      getMinMaxForColumns(
        datasource,
        rangeFiltersDefinition?.map((e) => e.name) ?? [],
      ),
    savedDashboardInfo.rangeFilters,
    savedDashboardInfo.timeFilters,
  )

  const { dimensionFilters, timeFilters, rangeFilters } = filters

  const selectedFilters = useMemo(
    () => [...dimensionFilters.selectedFilters, ...timeFilters.selectedFilters],
    [timeFilters.selectedFilters, dimensionFilters.selectedFilters],
  )

  const manager = useMemo<
    CardsDataSeriesManager<RiskFetchingInfo, DataSeriesData>
  >(
    () => ({
      getArgumentToFetch(
        card,
        { id, selectedCalculation, selectedFilters: seriesSelectedFilter },
      ) {
        if (!asOfDate) return null

        const globalAndIndividualSeriesFilterIntersection = mergeSelectedFilters(
          selectedFilters,
          seriesSelectedFilter ?? [],
        )

        return asOfDate
          ? {
              dataSeriesId: id,
              calculation: selectedCalculation?.[0] as Calculation,
              group: createGroups(card),
              selectedFilters: cleanFiltersForValidNetworkRequest(
                globalAndIndividualSeriesFilterIntersection,
              ),
              rangeFilters: rangeFilters.filters,
            }
          : null
      },
      fetchData(fetchingData) {
        return fetchDataForRisk(fetchingData, datasource, asOfDate)
      },
      mergeGroupedFetchedData: (keyExpression, data, parsedKeys) => {
        const { group } = parsedKeys[0]
        return mergeDataSeries(keyExpression, data, group[0])
      },
      correctCardDataSeries: (card, dataSeries) => dataSeries,
      key: asOfDate,
    }),
    [asOfDate, datasource, selectedFilters, rangeFilters.filters],
  )

  return {
    isHourEndingSelected,
    setIsHourEndingSelected,
    asOfDate,
    setAsOfDate,
    availableAsOfDates,
    setAvailableAsOfDates,
    manager,
    filters,
  }
}

const SupplyAndMarkToMarketkToMarket = ({
  savedDashboardInfo,
  datasource,
  onSave,
  filtersDefinitions,
  timeInterval,
  rangeFiltersDefinition,
}: Props) => {
  const commodityType = savedDashboardInfo.commodity.name

  const {
    isHourEndingSelected,
    setIsHourEndingSelected,
    asOfDate,
    setAsOfDate,
    availableAsOfDates,
    manager,
    filters,
  } = useSupplyAndMarkToMarketkToMarket({
    savedDashboardInfo,
    datasource,
    filtersDefinitions,
    timeInterval,
    rangeFiltersDefinition,
  })

  const {
    dimensionFilters,
    timeFilters,
    rangeFilters,
    onChange: onFilterChange,
    isInitializing: areFiltersInitializing,
  } = filters

  return (
    <BaseDashboard
      dashboardId={savedDashboardInfo.dashboardId}
      isInitializing={areFiltersInitializing}
      areFiltersLoading={false}
      isLoading={false}
      cardsDataSeriesManager={manager}
      defaultCards={savedDashboardInfo.cards}
      filtersDefinitions={filtersDefinitions}
      timeInterval={timeInterval}
      filters={dimensionFilters.filters}
      timeFilters={timeFilters.filters}
      selectedTimeFilters={timeFilters.selectedFilters}
      onFilterChange={onFilterChange}
      rangeFilters={rangeFilters.filters}
      onRangeFilterChange={rangeFilters.onChange}
      defaultNewCardGrouping={Group.Hourly}
      isHourEndingSelected={isHourEndingSelected}
      onIsHourEndingSelectedChange={(e, newIsHourEndingSelected) =>
        setIsHourEndingSelected(newIsHourEndingSelected)
      }
      onSave={(e, cards, extras) =>
        onSave(e, {
          ...extras,
          cards,
          filters: dimensionFilters.filters,
          timeFilters: timeFilters.filters,
          rangeFilters: rangeFilters.filters.map(({ name, value }) => ({
            name,
            value,
          })),
          isHourEndingSelected,
        })
      }
    >
      {availableAsOfDates && asOfDate && (
        <Autocomplete
          onChange={(e, newOption) => {
            setAsOfDate(DateTime.fromISO(newOption.value, { zone: 'utc' }))
          }}
          options={availableAsOfDates.map(buildAsOfDateOption)}
          value={buildAsOfDateOption(asOfDate)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => {
            return option.value === value.value
          }}
          disableClearable
          fullWidth
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder="As Of Date"
              margin="dense"
              label="As Of Date"
              variant="standard"
            />
          )}
        />
      )}
      <SideBarMenu title="time series" isLoading={false}>
        <DraggableMenu
          isCollapsible={false}
          innerMenus={timeSeriesInnerMenu}
          onDrag={(e, metadata) => {
            setDataSeriesData(e.dataTransfer, {
              id: metadata.id,
              type:
                metadata.id === DataSeries.VOLUME
                  ? getLoadDataSeriesTypeByCommodity(commodityType)
                  : DataSeriesType.Custom,
              /* TODO: Add proper kind of value for quantities */
              customUnit:
                metadata.id === DataSeries.MARK_TO_MARKET
                  ? '$ dollars'
                  : undefined,
              calculationOptions,
              selectedCalculation: calculationOptions[0],
              label: metadata.title,
            })
          }}
        />
      </SideBarMenu>
    </BaseDashboard>
  )
}

export default SupplyAndMarkToMarketkToMarket
