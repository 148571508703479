import { InputBase, withStyles } from '@material-ui/core'

/**
 * Just an styled version of the `InputBase` component from
 * MaterialUI
 */
const TransparentTextField = withStyles((theme) => ({
  root: {
    border: '1px solid transparent',
    // Ensures if element is focused it doesn't show hovered border
    '&:hover:not(.Mui-focused)': {
      borderColor: theme.palette.action.hover,
    },
  },
  focused: {
    borderColor: theme.palette.action.focus,
  },
}))(InputBase)

export default TransparentTextField
