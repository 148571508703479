import React, { ReactNode } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import Autosizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { makeStyles, styled } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import { useTableRowsStyles } from 'shared/components/TableElements'

/**
 * Row component used for the inner elements (and the header) of the table.
 *
 * It accepts an "index" prop so it'll display an accented background color on
 * "odd" numbered rows (0-index based, so [0: normal, 1:accented, 2:normal, ...]).
 *
 * It is intended to host **TableCell** elements as children.
 */
export const TableRow = styled((props: any) => {
  const tableRowClasses = useTableRowsStyles()
  const { header, className, ...otherProps } = props
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      className={clsx(
        tableRowClasses.row,
        header && tableRowClasses.headerRow,
        className,
      )}
    />
  )
})({
  display: 'flex',
  alignItems: 'center',
})

/**
 * Cell component used for the inner elements (and the header) of the table.
 *
 * If nested inside a "TableRow", they will share all the horizontal space evenly
 * (`display: flex`). As the rows are supposed to have a fixed height, it will
 * hide all the overflowing content and provide an "out of the box" ellipizing
 * of the text if it is provided as direct children.
 */
export const TableCell = styled((props: any) => {
  const tableRowsClasses = useTableRowsStyles()
  const { header, className, onSortRequest, ...otherProps } = props
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      className={clsx(header && tableRowsClasses.headerCell, className)}
    />
  )
})(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  boxSizing: 'border-box',
  padding: theme.spacing(0, 2),
}))

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  bodyContainer: {
    flex: '1 1 auto',
  },
})

interface Item {
  [index: string]: any
}

/**
 * Interface to be implemented by the optional render function to
 * render
 */
export interface CustomRowRenderer {
  (index: number, style: CSSProperties): ReactNode | void
}

interface VirtualizedTableRowProps {
  data: {
    renderCustomRow: CustomRowRenderer
    items: Item[]
    columns: Column<any>[]
  }
  style: CSSProperties
  index: number
}

/**
 * Component to render each row of the virtualized table. It is not
 * intended to be used on another context.
 *
 * All its props are to be passed by the `VirtualizedTable` component,
 */
const VirtualizedTableRow = ({
  data,
  style,
  index,
}: VirtualizedTableRowProps) => {
  const customRow = data.renderCustomRow && data.renderCustomRow(index, style)
  if (customRow) {
    return customRow
  }
  const item = data.items[index]
  return (
    <TableRow style={style}>
      {data.columns.map((column) => {
        const cellContent =
          typeof column.render !== 'function'
            ? (item[column.render] as ReactNode)
            : column.render(item, index)
        return (
          <TableCell
            key={`cell_${column.header}`}
            title={typeof cellContent === 'string' ? cellContent : ''}
            style={
              column.width
                ? {
                    width: column.width,
                    flex: '0 0 auto',
                    textAlign: column?.align,
                  }
                : { textAlign: column?.align }
            }
          >
            {cellContent}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

/**
 * A column's definition for the table.
 */
export interface Column<T extends object> {
  /**
   * Text to be displayed on the header row of the table for this column
   */
  header: string | (() => ReactNode)
  /**
   * Accessor key or rendering function to get the rendered content on
   * the cell for an specific item.
   *
   * - If a string is given:
   *
   * For each item, this cell will render the result of `item[render]`.
   *
   * - If a function is given:
   *
   * For each item, this function will be invoked and its returned value will be rendered.
   *
   */
  render: string | ((item: T, index: number) => ReactNode)
  /**
   * Optional specific width for this column
   */
  width?: number | string
  /**
   * Optional specific alignment of headers and columns
   */
  align?: 'left' | 'center' | 'right'
  /**
   * Returns the header name and sort direction if isSortable is set to true.
   */
  onSortHandler?: (e: Event, sortDirection: string | undefined) => void
  /**
   * Sorting direction for the given column
   */
  sortDirection?: 'asc' | 'desc'
}

type Columns = Column<any>[]

export interface Props {
  /**
   * Columns to be displayed on the table for each row. See `Column<T>` interface
   * documentation on the code for more information.
   */
  columns: Columns
  /**
   * Ordered array of objects to be displayed on the table. They can have any shape.
   */
  items: { [index: string]: any }[]
  /**
   * Optional accessor for the key value to add to the row element for every item.
   *
   * It is not necessary for pure display (without sorting) scenarios.
   */
  itemKey?: string
  /**
   * Optional subheader element to be rendered between the header row and the items rows.
   */
  subheaderRow?: ReactNode
  /**
   * Optional render function to allow rendering custom content for specific rows. Intended for
   * "edit mode" rows, "separators" and such.
   *
   * This function will be invoked for every visible row and render the returned value. If the
   * returned value is "falsy", the default content will be displayed instead.
   */
  renderCustomRow?: CustomRowRenderer
  /**
   * Optional fixed height (in pixels) of the table rows (header and items).
   *
   * Defaul value: 48
   */
  rowHeight?: number
}

/**
 * A virtualized table that provides simple data display behavior, while also
 * providing some extension points to render custom rows on an extra header if needed.
 *
 * The data rows have a fixed height (in pixels) that can be modified via props.
 *
 * ## Named exports
 *
 * For the sake of customization, the row and cells used internally are availabe to be imported. Also, additionally
 * to the default component props, some extra interfaces are available for type checking purposes.
 *
 * ### TableRow
 *
 *  Row component used for the inner elements (and the header) of the table.
 *
 *  It accepts an "index" prop so it'll display an accented background color on
 *  "odd" numbered rows (0-index based, so [0: normal, 1:accented, 2:normal, ...]).
 *
 *  It is intended to host **TableCell** elements as children.
 *
 *  ### TableCell
 *
 * Cell component used for the inner elements (and the header) of the table.
 *
 * If nested inside a "TableRow", they will share all the horizontal space evenly
 * (`display: flex`). As the rows are supposed to have a fixed height, it will
 * hide all the overflowing content and provide an "out of the box" ellipizing
 * of the text if it is provided as direct children.
 *
 */
const VirtualizedTable = ({
  columns,
  items,
  itemKey,
  subheaderRow,
  renderCustomRow,
  rowHeight = 48,
}: Props) => {
  const classes = useStyles()

  const sortIconHandler = (direction?: string) => {
    const style = { color: 'white' }
    const asc = 'asc' as const
    const desc = 'desc' as const

    let sortIcon
    if (!direction) {
      sortIcon = <span style={{ marginRight: '24px' }} />
    }
    if (direction === asc) {
      sortIcon = <ArrowDropUp style={style} />
    }
    if (direction === desc) {
      sortIcon = <ArrowDropDown style={style} />
    }
    return sortIcon
  }

  return (
    <Paper className={classes.container}>
      <TableRow header>
        {columns.map((column) => {
          const cellContent =
            typeof column.header !== 'function'
              ? (column.header as ReactNode)
              : column.header()
          return (
            <TableCell
              header
              key={`header_${column.header}`}
              onClick={(e) => {
                const { sortDirection, onSortHandler } = column
                if (onSortHandler) {
                  onSortHandler(e, sortDirection)
                }
              }}
              style={
                column.width
                  ? {
                      width: column.width,
                      flex: '0 0 auto',
                      textAlign: column?.align,
                    }
                  : { textAlign: column?.align }
              }
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: column?.align,
                  alignItems: 'center',
                  cursor: column.onSortHandler ? 'pointer' : 'cursor',
                }}
              >
                {cellContent}
                {sortIconHandler(column.sortDirection)}
              </div>
            </TableCell>
          )
        })}
      </TableRow>
      {subheaderRow}
      <div className={classes.bodyContainer}>
        <Autosizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemData={{ items, columns, renderCustomRow }}
              itemKey={
                itemKey
                  ? (index, data) => data.items[index][itemKey]
                  : undefined
              }
              itemSize={rowHeight}
              itemCount={items.length}
            >
              {VirtualizedTableRow}
            </FixedSizeList>
          )}
        </Autosizer>
      </div>
    </Paper>
  )
}

export default VirtualizedTable
