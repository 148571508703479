import React from 'react'
import IWButton from 'shared/components/IWButton'
import { useHistory, useRouteMatch } from 'react-router'
import { PlusCircle } from 'react-feather'
import { AdjustmentType } from '../components/LoadForecastTypes'
import AdjustmentsList from '../components/AdjustmentsList'
import { PageLayout } from '../../../../../shared/components/PageLayout'

const Adjustments = () => {
  const history = useHistory()
  const match = useRouteMatch()

  const renderActions = () => {
    return (
      <IWButton
        data-testid="add-adjustment"
        onClick={() => history.push(`${match.url}/create`)}
        startIcon={<PlusCircle />}
      >
        Add New Adjustment
      </IWButton>
    )
  }

  return (
    <PageLayout>
      <AdjustmentsList
        title="Short Term Forecast Adjustments"
        pageActions={renderActions()}
        tabCategories={[
          { title: 'Drafts', category: AdjustmentType.Draft },
          { title: 'Approved', category: AdjustmentType.Approved },
        ]}
        useActions
      />
    </PageLayout>
  )
}

export default Adjustments
