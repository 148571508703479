import React from 'react'

export interface SocketsContextType {
  [key: string]: SocketIOClient.Socket
}

/**
 * Context that will make the current websocket
 * clients available to any component of the app.
 */
const SocketContext = React.createContext({} as SocketsContextType)

export default SocketContext
