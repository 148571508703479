import React from 'react'
import { AvatarGroup } from '@material-ui/lab'
import ColoredAvatar from '../ColoredAvatar'

interface Props {
  users: Array<string>
}

const SummaryCardInteractions = ({ users }: Props) => {
  return (
    <AvatarGroup max={6} data-testid="avatar-group">
      {users?.map((user) => (
        <ColoredAvatar key={user} data-testid="avatar">
          {user.substring(0, 2).toLowerCase()}
        </ColoredAvatar>
      ))}
    </AvatarGroup>
  )
}

export default SummaryCardInteractions
