import React from 'react'
import { PageLayout } from 'shared/components/PageLayout'
import { ForecastType } from '../components/LoadForecastTypes'
import ForecastsList from '../components/ForecastsList'

const Forecasts = () => {
  return (
    <PageLayout>
      <ForecastsList
        title="Available Forecasts"
        tabCategories={[
          { title: 'Short Term', category: ForecastType.ShortTermForecast },
          { title: 'Long Term', category: ForecastType.LongTermForecast },
        ]}
        useActions
      />
    </PageLayout>
  )
}

export default Forecasts
