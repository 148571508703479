import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
// FIXME: D-02832 - Re-add sockets when connections with backend work again
import { login, redirect, logout } from 'shared/apiClient'
import { SocketsContextType } from 'shared/contexts/SocketContext'
import { UserType } from 'shared/types'

export const REDIRECT_PATH = '/authorization-code/callback'
const NONCE = 'nonce'

export const getLoginRedirectionUrl = async (redirectState: string) => {
  const response = await redirect(redirectState)
  if (response.url) {
    window.location.replace(response.url)
    return null
  }
  return response.user
}

export const attemptLogin = async (code: string | null) => {
  const user = await login(code).catch(() => null)
  return user
}

export const logoutUser = () => {
  logout().then((url) => {
    window.location.replace(url)
  })
}

interface AuthenticationData {
  userState: UserType
  socketsState: SocketsContextType
}

export interface ReturnValue {
  isChecking: boolean
  authenticationData: AuthenticationData | null
  login: () => void
  logout: () => void
}

interface NonceState {
  id: string
  redirectState: {
    url: string
    expiresOn: string
  }
}

/**
 * Full authentication flow behavior with
 * redirection logic and sockets connection
 * after the user data is retrieved.
 * @param changePathname
 */
export default function useAuthenticationFlow(
  changePathname: (string) => void,
): ReturnValue {
  const [isChecking, setIsChecking] = useState(true)

  const [
    authenticationData,
    setAuthenticationData,
  ] = useState<AuthenticationData | null>(null)

  const [nonce] = useState<NonceState>(() => {
    const cachedNonce = localStorage.getItem(NONCE)
    if (cachedNonce) {
      const cachedRedirectState = localStorage.getItem(cachedNonce)
      if (cachedRedirectState) {
        return {
          id: cachedNonce,
          redirectState: JSON.parse(cachedRedirectState),
        }
      }
    }
    const id = uuidv4()
    const redirectState = {
      url: window.location.pathname,
      expiresOn: DateTime.local().plus({ minutes: 15 }).toString(),
    }
    localStorage.setItem(NONCE, id)
    localStorage.setItem(id, JSON.stringify(redirectState))
    return {
      id,
      redirectState,
    }
  })

  function initialize(user, path?) {
    // FIXME: Handle permissions in the backend
    const newUser = { ...user }
    const newMods: string[] = []
    if (newUser.mods.includes('mod_load_forecast')) {
      newMods.push(
        'prod_load_forecast',
        'prod_demand_analytics',
        'prod_data_insights',
        'prod_meter_level_insights',
      )
    }
    if (newUser.mods.includes('mod_pricing')) {
      newMods.push(
        'prod_pricing_management',
        'prod_smart_engagement',
        'prod_product_management',
      )
    }
    if (newUser.mods.includes('mod_risk')) {
      newMods.push('prod_risk_management', 'prod_load_scheduling')
    }
    newUser.mods = newMods
    // FIXME: D-02832 - Re-add sockets when sockets are fixed
    setAuthenticationData({ userState: newUser, socketsState: {} })
    changePathname(path)
    localStorage.clear()
  }

  useEffect(
    () => {
      const code = new URLSearchParams(window.location.search).get('code')
      attemptLogin(code).then((user) => {
        const returnedNonce = new URLSearchParams(window.location.search).get(
          'state',
        )
        const isRedirectExpired: boolean =
          Number(
            DateTime.fromISO(nonce.redirectState.expiresOn).diffNow().toObject()
              .milliseconds,
          ) < 0
        const isNonceMatch = returnedNonce === nonce.id
        const isValidNonce = !isRedirectExpired && isNonceMatch
        const isFromAuthProviderRedirect =
          window.location.pathname === REDIRECT_PATH
        /**
         * If url not coming from auth provider redirect and we have a user
         * this means we have a valid session in our server thus no need to check nonce validity.
         */
        if (user && !isFromAuthProviderRedirect) {
          initialize(user)
        } else if (user && isFromAuthProviderRedirect && isValidNonce) {
          initialize(user, nonce.redirectState.url)
        }
        setIsChecking(false)
      })
    },
    /* This effect will only be executed on the initial mount phase, 
    so there is no need to have exhaustive deps */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    isChecking,
    authenticationData,
    login: () => {
      if (!isChecking && !authenticationData) {
        setIsChecking(true)
        getLoginRedirectionUrl(nonce.id).then((user) => {
          if (user) {
            initialize(user)
            setIsChecking(false)
          }
        })
      }
    },
    logout: () => {
      if (authenticationData) {
        localStorage.clear()
        logoutUser()
      }
    },
  }
}
