import { Layout } from 'react-grid-layout'
import { Color } from '@material-ui/lab/Alert'
import { DateTime } from 'luxon'
import { ComponentType, ReactElement, ReactNode } from 'react'
import { RouteComponentProps } from 'react-router'
import { RangeFilter, SavedRangeFilters } from './hooks/useRangeFilters'
import { MapSettings } from './components/DashboardCard/MeterMap/common'

export interface AsyncData<T, E = Error> {
  data: T | null
  error: E | null
}

export interface UserType {
  userId: string
  email: string
  name: string
  mods: Array<string>
  organization: string
}

export interface SpatialFilter {
  columnName: string
  type: 'rectangular' // we can add types 'radius'| 'polygon' if need as they are supported in the backend
  minCoords: [number, number]
  maxCoords: [number, number]
}

/**
 * User-defined type guard for "UserType", in case it is needed.
 */
export function isUserType(obj: { [field: string]: any }): obj is UserType {
  const { email, name, mods, organization } = obj
  return (
    typeof email === 'string' &&
    typeof name === 'string' &&
    Array.isArray(mods) &&
    typeof organization === 'string'
  )
}

export type NotificationKindType = 'positive' | 'neutral' | 'warning' | 'error'

export interface NotificationType {
  kind: NotificationKindType
  title: string
  description: string
  extraContent?: ReactNode
  date: Date
}

export type JobTypes = 'calculation' | 'upload' | 'check_status'

export interface Job {
  uid: string
  jobType: JobTypes
  name: string
  initDatetime: Date
  contractCount: number | undefined
  passedCount: number | undefined
  failedCount: number | undefined
  progress: {
    endDatetime?: Date
    status: number | 'success' | 'warning' | 'error' | undefined
    message?: string
  }
}

export interface InputModel {
  meterId: string
  market: string
  iso: string
  profile: string
  edc: string
  product: string
  startDate: Date
  endDate: Date
  asOfDate: Date
}

export interface Input extends InputModel {
  uid: string
}

export interface JobDescriptionModel {
  name: string
  notes?: string
  type: JobTypes
}

// Dataseries/DashboardCard Types
export enum DataSeriesType {
  PowerLoad = 'power-load',
  GasLoad = 'gas-load',
  Temperature = 'temperature',
  Dimensionless = 'dimensionless',
  Custom = 'custom',
}

export type SingleCalculationOption = [Calculation, string]
export type CalculationOptions = SingleCalculationOption[]

export type SingleSplitOption = [string, string]
export type SplitOptions = SingleSplitOption[]

export enum AxisId {
  Primary = 'primary_axis',
  Secondary = 'secondary_axis',
}

export interface YaxisRange {
  label: string
  value: any
}

export interface DataSeries {
  id: string
  label: string
  type: DataSeriesType
  axisId?: AxisId
  calculationOptions?: CalculationOptions
  selectedCalculation?: SingleCalculationOption
  splitOptions?: SplitOptions
  selectedSplit?: SingleSplitOption | null
  customUnit?: string
  groupedItems?: {
    dataSeries: Array<DataSeries>
    key: string
    // TODO: Maybe make these mandatory once group tags are removed
    expression?: string
    expressionScope?: object
    expressionDataIdMap?: {
      [key: string]: { id: string; selectedOption?: string }
    }
  }
  mapSettings?: MapSettings
  extras?: { [field: string]: any }
  selectedFilters?: SelectedFilter[]
  rangeFilters?: Array<RangeFilter>
  spatialFilters?: SpatialFilter[]
}

export type SingleGroupOption = [Group | string, string]

export type GroupOptions = SingleGroupOption[]

export enum Group {
  Yearly = 'year',
  Month12 = 'month-12',
  Monthly = 'month',
  DayType = 'day-type',
  DayOfWeek = 'day-week',
  Daily = 'day',
  Hour24 = 'hour-24',
  Hourly = 'hour',
  QuaterHourly = '15min',
  HalfHourly = '30min',
}

export enum Calculation {
  Sum = 'sum',
  Average = 'average',
  Max = 'max',
  Min = 'min',
  CountDistinct = 'countDistinct',
  StringFirst = 'stringFirst',
}

export enum Visualization {
  Line = 'line',
  Bar = 'bar',
  MultiAxis = 'combo chart',
  Table = 'table',
  Area = 'area',
  Stack = 'stack',
  MeterMap = 'meter map',
  Summary = 'summary',
  MeterTable = 'meter table',
}

export interface DataSeriesTypeUnit {
  value: string
  label: string
}

export enum HourBeginningOrEndingOptions {
  Beginning = 'Hour Beginning',
  Ending = 'Hour Ending',
}

export interface CardTypeUnits {
  [AxisId.Primary]?: DataSeriesTypeUnit
  [AxisId.Secondary]?: DataSeriesTypeUnit
}

export interface CardTableGroup {
  header: string
  groupType: Group | string
  direction: 'row' | 'column'
}

export interface ShouldYAxisStartAtDataRange {
  [AxisId.Primary]?: Boolean
  [AxisId.Secondary]?: Boolean
}

/**
 * Information that defines an unique card inside a system
 */
export interface CardDefinition {
  id: string
  /** Card component title displayed in upper left hand side. The title is the value to an input component, so an `onTitleChange` function is expected to control the title content. */
  title: string
  /** Array of `DataSeries` objects with the shape:
   * <pre>
   * {
   *   id: string
   *   label: string
   *   type: DataSeriesType
   *   extras?: { [field: string]: any }
   * }
   * </pre>
   * Use extras to embed any other necessary data that is not required but that your particular usecase may require.
   */
  dataSeries: Array<DataSeries>
  /** Data visualization type. The visualization type is selected with the icons at the top of the card */
  visualization: Visualization
  /** Determines the card's data grouping. Selection made from a `<Select />` element */
  group: Group | string | null
  /**
   * Determines the card's explicitly selected units for the different types of dataseries on each of its
   * inputs. Each dataseriestype not included means it will be using its default units. If the value is not
   * passed at all, it means all types are using their default units.
   */
  typeUnits?: CardTypeUnits
  /** FIXME: Further define before PR */
  tableGroups?: Array<CardTableGroup>
  tableSeriesDirection?: 'row' | 'column'
  /** Determines whether y-axis should start at 0 or min value */
  shouldYAxisStartAtDataRange?: ShouldYAxisStartAtDataRange
  /** Determines where the card's plot legend is displayed */
  legendPosition?: LegendPosition
  meterTableOptions?: {
    pageSize: number
    pageNumber: number
  }
  /* States whether the card is being used in the overview page */
  isOverview?: Boolean
  mapSettings?: MapSettings
  isSummaryView?: Boolean
}

export interface DataSeriesSplitElement {
  splitValue: string | null
  seriesData: Array<object>
  groupedData: object
}

export interface DataSeriesData {
  splits: Array<DataSeriesSplitElement>
  mainValueAccessor: string
  aggregateItems?: (items: Array<object>) => number
  label?: string
}

export function isDataSeriesData(item: any): item is DataSeriesData {
  if (typeof item === 'object') {
    return (
      Array.isArray(item.splits) &&
      typeof item.mainValueAccessor === 'string' &&
      (item.aggregateItems == null ||
        typeof item.aggregateItems === 'function') &&
      (item.label == null || typeof item.label === 'string')
    )
  }
  return false
}

/**
 * Different types of series
 */
export enum WeatherTimeSeries {
  Actual = 'actual',
  Forecast = 'forecast',
  Normals = 'normals',
}

export enum LegendPosition {
  Top = 'top',
  Bottom = 'bottom',
  Right = 'right',
  Left = 'left',
  None = 'none',
}
export type ForecastTypes = 'long-term' | 'short-term' | 'performance'

export type DynamicColumnsConfig = {
  valueTypeColumn: string
  valueColumn: string
  meterIdColumn?: Group | string
  hasMeterMap?: string
}

export interface WeatherStation {
  id: string
  label: string
}

/**
 * Interfaces for configuration objects
 */
export interface DashboardConfigItem {
  configId: string
  type: string
  category: { name: string; label: string }
  commodity: { name: string; label: string }
  level?: { name: string; label: string }
  market?: { name: string; label: string }
  datasource: string
  timeInterval: number
  filters: Array<{
    name: string
    title: string
  }>
  weatherStations?: Array<WeatherStation>
  dynamicColumnsConfig?: DynamicColumnsConfig
  rangeFilters?: Array<{
    name: string
    title: string
  }>
  timeFilters?: Array<{
    name: string
    title: string
  }>
  isSettled?: boolean
}

export interface CalculatorState {
  expression: string
  expressionDataIdMap: {
    [key: string]: { id: string; selectedOption?: string }
  }
  uid: string
  unit: string | null
  name: string
  isValidExpression: boolean
  initOpen?: boolean
}

export interface NestedDashboardConfig {
  dashboardType: string
  items: DashboardConfigItem[]
}

// We omit i because it gets set by the key which in turn === card.id
export type LayoutMinusI = Omit<Layout, 'i'>

export interface DraggableResizableCard extends CardDefinition {
  layout?: LayoutMinusI
}

export type DateMultiplier = 'days' | 'months' | 'years'
export type DateIdentifier = 'today' | 'startData' | 'endData'
export type DateSelectorType = 'fixed' | 'relative'

export interface GeneralDateSelector {
  /** Integer quantity to add to the relative date */
  quantity: number | null
  /** Date length to add to the identifier (goes with quantity) */
  multiplier: DateMultiplier
  /** Date to base the general relative date */
  identifier: DateIdentifier
  /** Will display relative options if `relative` is selected */
  dateType: DateSelectorType
  /** Component date value (displayed in read-only input) */
  value: DateTime
}

export interface SimpleDashboard {
  dashboardId: string
  addedOn: string
  name: string
  description: string
  cards: Array<DraggableResizableCard>
  filters: Array<SavedFilter>
  timeFilters?: Array<SavedFilter>
  calculators?: Array<CalculatorState>
  timezone?: string
  isHourEndingSelected?: Boolean
  from?: GeneralDateSelector
  to?: GeneralDateSelector
  org: string | null
  owner: string
  sharedUsers: Array<string>
  rangeFilters?: Array<SavedRangeFilters>
}

export type Dashboard = SimpleDashboard &
  Pick<
    DashboardConfigItem,
    'configId' | 'category' | 'commodity' | 'level' | 'type'
  >

export interface DashboardUpdateBody {
  cards: Array<DraggableResizableCard>
  filters: Array<SavedFilter>
  timeFilters: Array<SavedFilter>
  rangeFilters?: Array<SavedRangeFilters>
  groups?: Array<any>
  calculators?: Array<CalculatorState>
  timezone?: string
  isHourEndingSelected?: Boolean
  from: { quantity: number }
  to: { quantity: number }
}

export interface DashboardUpdateMetaBody {
  name: string
  description: string
}

export interface SnackData {
  severity?: Color
  message: string
}

interface BaseFilter {
  name: string
  title: string
}

export type SelectableValue = string | number | null

export interface Checkbox {
  /** Checkbox id used to build unique checkboxes in the group. Should be unique in the group */
  id: SelectableValue
  /** Label for denoting checkbox option */
  label: SelectableValue
  /** Checkbox current state */
  isChecked: boolean
  /** whether checkbox should be disabled... lol */
  isDisabled: boolean
}

export interface InitialFilter extends BaseFilter {
  items: Array<SelectableValue>
}

export interface Filter extends BaseFilter {
  items: Checkbox[]
}

export interface SavedFilter {
  name: string
  items: Pick<Checkbox, 'id' | 'isChecked'>[]
}

export interface SelectedFilter {
  name: string
  values: Array<SelectableValue>
}

export interface ApplicationProduct {
  mod: string
  product: string
  label: string
  path: string
  config: ProductModuleConfig
  icon: ReactElement
  active: boolean
  isExternalLink?: boolean
}

export type ProductModuleButtonComponent = ComponentType<{
  products: Array<ApplicationProduct>
}>

export type ProductModuleContentComponent = ComponentType<RouteComponentProps>

export interface ProductModuleNotificationCustomizer {
  (receivedInfo: any, moduleBasePath: string): ReactNode
}

export interface ProductModuleConfig {
  HeaderContent: ProductModuleContentComponent
  MainContent: ProductModuleContentComponent
  getNotificationExtraContent: ProductModuleNotificationCustomizer
}

export interface AdjustmentsMeta {
  adjustmentId: string
  name: string
  configId: string
  market: string
  processTime: string
  timezone: string
  isHourEnding: boolean | Boolean
  dateRange: {
    from: string
    to: string
  }
  summary: { timestamp: string; forecast: number; adjustedForecast: number }[]
  createdAt: string
  updatedAt: string
  versions: { versionId: string; createdAt: string }[]
}

export type AdjustmentCreateMeta = Omit<
  AdjustmentsMeta,
  'adjustmentId' | 'createdAt' | 'updatedAt' | 'versions'
>

export interface AdjustmentForecast {
  timestamp: string
  forecast: number
  processTime: string
}

export enum ValidAdjustmentCalculations {
  NoAction = 'noAction',
  Addition = 'forecast + value',
  Multiplication = 'forecast * value',
  Division = 'forecast / value',
  Custom = 'custom',
  Percent = '(forecast * value / 100) + forecast',
}

export interface Adjustment {
  id: string
  adjustedForecast?: number
  adjustmentCalculation?: ValidAdjustmentCalculations
  adjustmentValue?: number
}

export interface BackendAdjustment {
  id: string
  adjustmentCalculation: ValidAdjustmentCalculations
  adjustmentValue?: number
}
