import React from 'react'
import { makeStyles } from '@material-ui/core'
import ProgressBarWithMilestones, {
  Milestone,
} from './ProgressBarWithMilestones'
import CompletedProgressBar, {
  CompletedProgressBarProps,
} from './CompletedProgressBar'

export type { Milestone } from './ProgressBarWithMilestones'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
  },
})

export interface ProgressBarProps {
  /** If `true` it displays completed bar with `taskCompleteInfo` */
  isTaskComplete: boolean

  /**
   * Array of Milestones where Milestone is:
   * ```{ id: string, name: string, description: string }```
   *
   * - __id__: uniquely identify string
   * - __name__: milestone name
   * - __description__: milestone description to be displayed at the bottom of `<ProgressBar />`
   */
  milestones: Array<Milestone>

  /** Milestone index of current progress. If it is `undefined || < 0` and `isTaskComplete = false` it uses the default status */
  progressIndex?: number

  /**
   * Denotes what description to display under `<ProgressBar />` if `progressIndex = undefined || < 0` and `isTaskComplete = true`
   */
  defaultStatus?: string

  /**
   * This prop accept is required when `isTaskComplete = true`
   * It accepts an object with keys equal to props in `<CompletedProgressBar />`
   */
  taskCompleteInfo?: CompletedProgressBarProps
}

/**
 * Parent component for `<ProgressBarWithMilestones/>` and `<CompletedProgressBar />` that helps switch between `'complete' | 'defaultStatus' | 'progress'`.
 *
 * ### States
 * The component has three possible states: __progress__, __defaultStatus__, or __complete__.
 *
 * - __defaultStatus__ if `progressIndex = null | undefined | < 0` and `isTaskComplete = false` (displays an empty progress bar)
 * - __progress__ if `progressIndex >= 0` and `isTaskComplete = false` (displays a progress bar at the `progressIndex` milestone)
 * - __complete__ if `isTaskComplete = true` (displays completed bar)
 *
 * __NOTE:__ In the future a milestonesMetaInfo Prop (or similar) can be implemented to pinpoint errors and warnings at specific progress steps
 */

const ProgressBar = ({
  isTaskComplete,
  milestones,
  progressIndex,
  taskCompleteInfo,
  defaultStatus,
}: ProgressBarProps) => {
  const componentStyle: any = useStyles()
  return (
    <div className={componentStyle.container}>
      {isTaskComplete ? (
        taskCompleteInfo && (
          <CompletedProgressBar
            status={taskCompleteInfo.status}
            completedAt={taskCompleteInfo.completedAt}
            message={taskCompleteInfo.message}
          />
        )
      ) : (
        <ProgressBarWithMilestones
          milestones={milestones}
          progressIndex={progressIndex}
          defaultStatus={defaultStatus || ''}
        />
      )}
    </div>
  )
}

export default ProgressBar
