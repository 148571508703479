import { useEffect } from 'react'
import L from 'leaflet'
import { LoadLegendMap, SeriesToColorAndSizeByTypes } from './common'

export interface Props {
  map: object
  type: SeriesToColorAndSizeByTypes
  position?: string
  legendMap: LoadLegendMap | object
}

const MapLegend = ({
  map,
  legendMap,
  type = SeriesToColorAndSizeByTypes.Group,
  position = 'bottomleft',
}: Props) => {
  let legend

  const labelStyles =
    'margin-right:5px;border-radius:2px;display:block;width:10px;height:10px'

  const containerStyles =
    'width:200px;border-radius:5px;box-shadow:0 2px 1px -1px #ccc, 0 1px 1px 0 #eee, 0 1px 3px 0 #eee;background: #ffffff;padding:10px;margin-left:250px;'

  const renderByGroup = () => {
    const div = L.DomUtil.create('div', 'legend')
    L.DomEvent.on(div, 'mousewheel', L.DomEvent.stopPropagation)
    const labels = []

    Object.keys(legendMap).forEach((key) => {
      labels.push(
        // @ts-ignore
        `<div style='display:flex;align-items: center'>
              <i style='${labelStyles};background:${legendMap[key]}'></i>${key}</div>`,
      )
    })

    div.innerHTML = `<div style="${containerStyles}"><div>
                          <div style='color: #3e3f42;font-weight: 500;margin-bottom: 10px;'>Legend</div> 
                          <div style='min-width:100px;max-height: 150px;overflow-y: auto'>${labels.join(
                            '<br />',
                          )}</div>`
    return div
  }

  /**
   * Accepts a legendMap with min and max values with a color assigned to each
   */
  const renderByLoad = () => {
    const loadMap = legendMap as LoadLegendMap
    const div = L.DomUtil.create('div', 'legend')
    L.DomEvent.on(div, 'mousewheel', L.DomEvent.stopPropagation)

    div.innerHTML = `<div style="${containerStyles}"><div>
                          <div style='color: #3e3f42;font-weight: 500;margin-bottom: 10px;'>Legend</div> 
                          <div>
                            <div style='border-radius:5px;width:100%;height:20px;background:linear-gradient(90deg, ${loadMap.min.color} 0%, ${loadMap.max.color} 100%)'></div>
                            <div style='display: flex;margin-top:5px'>
                              <div>${loadMap.min.value}</div>  
                              <div style='flex: 1'></div>  
                              <div>${loadMap.max.value}</div>
                            </div>
                          </div>`
    return div
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (map && legendMap) {
      // @ts-ignore
      legend = L.control({ position })
      if (type === SeriesToColorAndSizeByTypes.Group) {
        legend.onAdd = renderByGroup
      } else {
        legend.onAdd = renderByLoad
      }

      legend.addTo(map)

      return () => {
        if (legend) {
          legend.remove()
        }
      }
    }
  }, [map, position, legendMap])

  return null
}

export default MapLegend
