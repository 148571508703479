import React from 'react'
import MainMenuButton from 'shared/components/MainMenuButton'
import PricingConfig from 'modules/customer/products/pricing'
import ProductManagementConfig from 'modules/customer/products/productManagement'
import SmartEngagementConfig from 'modules/customer/products/smartEngagement'
import { ReactComponent as SmartEngagementIcon } from 'icons/smart-engagement-module.svg'
import { ReactComponent as ProductManagementIcon } from 'icons/product-management-submodule.svg'
import { ReactComponent as PricingIcon } from 'icons/pricing-submodule.svg'
import { ApplicationProduct, ProductModuleButtonComponent } from 'shared/types'

const buttonProps = {
  label: 'Customer',
  name: 'customer',
  color: 'customer',
  description:
    'Secure your profit with pricing which accurately reflects your cost and help analyse your profit',
}

export const CUSTOMER_PRODUCTS: Array<ApplicationProduct> = [
  {
    mod: 'mod_customer',
    product: 'prod_smart_engagement',
    path: 'https://smartalerts.edge.innowatts.net/',
    config: SmartEngagementConfig,
    label: 'Smart Engagement',
    icon: <SmartEngagementIcon />,
    isExternalLink: true,
    active: false,
  },
  {
    mod: 'mod_customer',
    product: 'prod_product_management',
    path: 'https://smartalerts.edge.innowatts.net/',
    config: ProductManagementConfig,
    label: 'Product Management',
    icon: <ProductManagementIcon />,
    isExternalLink: true,
    active: false,
  },
  {
    mod: 'mod_customer',
    product: 'prod_pricing_management',
    path: '/pricing/pricing-management',
    config: PricingConfig,
    label: 'Pricing Management',
    icon: <PricingIcon />,
    active: false,
    isExternalLink: false,
  },
]

const CustomerButton = (({
  products,
}: {
  products: Array<ApplicationProduct>
}) => {
  const { label, name, description } = buttonProps

  return (
    <MainMenuButton
      label={label}
      description={description}
      products={products}
      name={name}
    />
  )
}) as ProductModuleButtonComponent

export default CustomerButton
