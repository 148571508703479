import { useState, MouseEvent, MouseEventHandler } from 'react'

/**
 * Simple hook to capture and store a clicked DOM element, usually
 * interesting to provide anchors for popovers or scrolling purposes.
 *
 * Returns an array with the next three values (in order) so there is
 * no need to rename object properties in the event of repeated use inside a component.
 *
 * - the current captured element (or null).
 * - An "onClick" event handler appropriate for any usual HTML element that will set the
 *     current captured element to the clicked one.
 * - A function to clear the current captured element and set it to null.
 *
 */
export default function useClickedElement(): [
  HTMLElement | null,
  MouseEventHandler,
  () => void,
] {
  const [element, setElement] = useState<HTMLElement | null>(null)
  const [[onClick, clear]] = useState<[MouseEventHandler, () => void]>([
    (e: MouseEvent<HTMLElement>) => setElement(e.currentTarget),
    () => setElement(null),
  ])
  return [element, onClick, clear]
}
