import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as DemandAnalyticsIcon } from 'icons/demand-icon.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'
import DataInsightsDashboard from 'modules/insights/products/dataInsights/DataInsightsDashboard'

const dataInsightsMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: DataInsightsDashboard,
  },
]

export const DataInsightsMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Meter Insights"
      color="demand"
      basePath={url}
      icon={<DemandAnalyticsIcon />}
      routes={dataInsightsMenuOptions}
    />
  )
}

const DataInsights = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${dataInsightsMenuOptions[0].path}`} />
      </Route>
      {dataInsightsMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default DataInsights
