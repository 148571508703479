import {
  Group,
  Calculation,
  DataSeriesData,
  SelectedFilter,
} from 'shared/types'
import { DateTime } from 'luxon'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import { queryRiskDatasource } from './apiClient'

export interface RiskFetchingInfo {
  calculation: Calculation
  dataSeriesId: string
  selectedFilters: SelectedFilter[]
  group: Array<Group>
  rangeFilters?: Array<RangeFilter>
}

/**
 * Fetches all the plot info for a dataSeries visualization given a key
 * created using "buildQueryKeys" function, its appropriate datasource
 * and the date range.
 */
// eslint-disable-next-line import/prefer-default-export
export const fetchDataForRisk = async (
  {
    calculation,
    dataSeriesId,
    selectedFilters,
    group,
    rangeFilters,
  }: RiskFetchingInfo,
  datasource: string,
  asOfDate: null | DateTime,
): Promise<DataSeriesData> => {
  if (!asOfDate) {
    throw new Error(`No as of date specified`)
  }

  const splits = await queryRiskDatasource(
    asOfDate,
    datasource,
    [{ name: dataSeriesId, aggregation: calculation }],
    selectedFilters,
    group.map((groupType) => ({ groupType })),
    rangeFilters,
  )

  return {
    splits,
    mainValueAccessor: dataSeriesId,
  }
}
