import { Theme } from '@material-ui/core'

import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette'

export type Color = keyof Palette

/**
 * This function takes theme and requestedColor as parameters
 * and returns a color for a specific modules defined inside the metarial ui theme
 * requested color is basically the name of module
 * eg:- ```
 * requestedColor = 'demand'
 * ```
 * and theme is just material ui theme object
 */
export function safeColor(theme: Theme, requestedColor: Color) {
  const paletteColor = theme.palette[requestedColor] as PaletteColor
  if (!paletteColor || !paletteColor.main) {
    return theme.palette.primary.main
  }
  return paletteColor.main
}
