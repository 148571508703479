import React, { MouseEventHandler } from 'react'
import { IconButton, MenuList, MenuItem, Popover } from '@material-ui/core'
import { MoreHorizontal } from 'react-feather'
import useClickedElement from 'shared/hooks/useClickedElement'

export interface SummaryCardAction {
  title: string
  action: MouseEventHandler
}

interface Props {
  actions: SummaryCardAction[]
}

const SummaryCardActions = ({ actions }: Props) => {
  const [anchorEl, handleMenuOpen, handleMenuClose] = useClickedElement()
  const handleAction = (e, cb?: (e) => void) => {
    handleMenuClose()
    if (cb) {
      cb(e)
    }
  }
  return (
    <>
      <IconButton
        data-testid="summary-action-button"
        aria-haspopup="true"
        aria-label="options"
        title="options"
        size="small"
        onClick={handleMenuOpen}
      >
        <MoreHorizontal />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          {actions.map((option: SummaryCardAction) => {
            return (
              <MenuItem
                data-testid="card-menu-item"
                key={option.title}
                onClick={(e) => handleAction(e, option.action)}
              >
                {option.title}
              </MenuItem>
            )
          })}
        </MenuList>
      </Popover>
    </>
  )
}

export default SummaryCardActions
