import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  progressBar: {
    display: 'flex',
    'flex-direction': 'row',
    width: '100%',
    margin: 0,
    padding: 0,
    position: 'relative',
    'margin-bottom': theme.spacing(0.2),
  },
  individualSteps: {
    'list-style': 'none',
    display: 'inline-block',
    'text-align': 'center',
    position: 'relative',
    'flex-grow': 1,
    'flex-basis': 0,
    cursor: 'pointer',

    '&:before': {
      width: '14px',
      height: '14px',
      content: '""',
      display: 'block',
      'border-radius': '50%',
      'background-color': theme.palette.grey[300],
      float: 'right',
    },

    '&:after': {
      width: '100%',
      height: '4px',
      content: '""',
      'border-top-left-radius': '4px',
      position: 'absolute',
      'background-color': theme.palette.grey[300],
      top: '5px',
      'z-index': -1,
      left: '0px',
      'border-bottom-left-radius': '4px',
    },
  },
  currentStatus: {
    ...theme.typography.caption,
    float: 'right',
    height: '40%',
    color: theme.palette.text.secondary,
    'font-weight': theme.typography.fontWeightMedium,
  },
  processDone: {
    '&:before, &:after': {
      'background-color': theme.palette.info.dark,
    },
  },
  inProgress: {
    '&:before': {
      'background-color': theme.palette.info.main,
    },
    '&:after': {
      'background-color': theme.palette.info.dark,
    },
  },
}))

export interface Milestone {
  /**
   *
   * Should be unique for a task
   *  and should uniquely identify the milestone
   *  at which the task is at
   *
   */
  id: string

  /**
   * this should be milestone description
   *  and this is what will be display in bottom
   *  of progress bar
   *
   */
  name: string
}

export interface MilestoneMetaInfo {
  /**
   *
   * this is associated with id and will help identify
   *  meta info to milestone
   *
   */
  id: Milestone['id']

  /**
   *
   * is either error or warning
   *  and will help group multiple message of same type
   *  for example multiple error messages together to show
   *  somewhere
   */
  type: 'error' | 'warning'

  /**
   *
   * should contain message related to meta info type
   *
   */
  message: string
}

export interface ProgressBarWithMilestonesProps {
  /**
   * Array of Milestones where Milestone is:
   * ```{ id: string, name: string, description: string }```
   *
   * - __id__: uniquely identify string
   * - __name__: milestone name
   * - __description__: milestone description to be displayed at the bottom of `<ProgressBar />`
   */
  milestones: Array<Milestone>

  /** Milestone index of current progress. If it is `undefined || < 0` and `isTaskComplete = false` it uses the default status */
  progressIndex?: number

  /**
   * Denotes what description to display under `<ProgressBar />` if `progressIndex = undefined || < 0` and `isTaskComplete = true`
   */
  defaultStatus?: string

  /**
   *
   * This is optional parameter , which mean this is currently in
   *
   * TODO stage, this will hold array of message in format of id, type , message
   *
   * id will be id of milestone and type will be error, warning and message will
   *
   * be extra info about error or warning , which can be used as marker to show errors
   *
   * and warning at milestone level if ever needed
   *
   * TODO : implement errors and warning
   *
   */
}

/**
 *
 * @param milestoneIndex takes index of milestone running in loop to determine current index
 * @param currentMilestoneIndexInProgress takes index of milestone that is in progress this can be
 * undefined if task is in queue
 * @param style style object to detetmine style for a milestone
 *
 * isMilestoneInProgress
 *    provides style to milestone depending on whether it's in progress, completed or
 *    in queue
 *
 */
const isMilestoneInProgress = (
  milestoneIndex: number,
  currentMilestoneIndexInProgress: number,
  style,
) => {
  if (milestoneIndex < currentMilestoneIndexInProgress) {
    return style.processDone
  }

  if (milestoneIndex === currentMilestoneIndexInProgress) {
    return style.inProgress
  }

  return ''
}

/**
 * Bar that denotes progress with milestones instead of percentage or time.
 *
 * This component requires ordered milestones and current progressIndex to denote milestone.
 * If progress hasn't started then the default status will be displayed.
 */
const ProgressBarWithMilestones = ({
  milestones,
  progressIndex = -1,
  defaultStatus,
}: ProgressBarWithMilestonesProps) => {
  const componentStyles = useStyles()

  const [currentIndex, setCurrentIndex] = useState(progressIndex)

  const currentMessage = milestones[currentIndex]?.name || defaultStatus

  useEffect(() => {
    setCurrentIndex(progressIndex)
  }, [progressIndex])

  return (
    <>
      <ul className={componentStyles.progressBar}>
        {milestones.map((milestone: Milestone, milestoneIndex: number) => {
          return (
            <li
              key={milestone.id}
              onMouseEnter={() => setCurrentIndex(milestoneIndex)}
              onMouseLeave={() => setCurrentIndex(progressIndex)}
              className={`${
                componentStyles.individualSteps
              } ${isMilestoneInProgress(
                milestoneIndex,
                currentIndex,
                componentStyles,
              )}`}
            />
          )
        })}
      </ul>
      <span className={componentStyles.currentStatus}>{currentMessage}</span>
    </>
  )
}

export default ProgressBarWithMilestones
