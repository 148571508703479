import React, { ReactElement, useContext, useMemo, useState } from 'react'
import crossfilter, { Grouping } from 'crossfilter2'
import { DateTime } from 'luxon'
import reductio from 'reductio'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import DashboardsConfigsContext from 'shared/contexts/DashboardsConfigsContext'
import SummaryPage from 'shared/components/SummaryPage/SummaryPage'
import {
  RoundedSkeleton,
  StyledSummaryCard,
} from 'shared/components/SummaryPage/SummaryList/SkeletonSummaryElements'
import { SummaryCardAction } from 'shared/components/SummaryCard/SummaryCardActions'
import { AvailableForecastItem, ForecastType, Level } from './LoadForecastTypes'
import useAvailableLoadForecasts from '../hooks/useAvailableLoadForecasts'
import ForecastCard from './ForecastCard'

const ForecastSkeletonCard = () => {
  return (
    <StyledSummaryCard>
      <Box display="flex" width="100%" alignItems="center">
        <Skeleton variant="text" width="80%" height={70} />
        <Box margin={1} />
        <Skeleton variant="circle" width={5} height={5} />
        <Skeleton variant="circle" width={5} height={5} />
        <Skeleton variant="circle" width={5} height={5} />
      </Box>
      <RoundedSkeleton variant="rect" width={100} height={20} />
      <RoundedSkeleton variant="rect" width="100%" height={80} />
      <RoundedSkeleton variant="rect" width="100%" height={80} />
      <RoundedSkeleton variant="rect" width="100%" height={100} />
      <RoundedSkeleton variant="rect" width="100%" height={1} />
      <RoundedSkeleton variant="rect" width="100%" height={30} />
    </StyledSummaryCard>
  )
}

interface Props {
  title: string
  useActions?: boolean
  pageActions?: ReactElement
  tabCategories: {
    title: string
    category: ForecastType
  }[]
  onSelect?: (object) => void
  errorMessage?: string
}

const ForecastsList = ({
  title,
  useActions,
  pageActions,
  tabCategories,
  onSelect,
  errorMessage,
}: Props) => {
  const history = useHistory()
  const dashboardsConfigsContext = useContext(DashboardsConfigsContext)
  const [selected, setSelected] = useState<string>('')
  const {
    initialize: initShortTerm,
    loadMore: loadMoreShortTerm,
  } = useAvailableLoadForecasts(ForecastType.ShortTermForecast)

  const {
    initialize: initLongTerm,
    loadMore: loadMoreLongTerm,
  } = useAvailableLoadForecasts(ForecastType.LongTermForecast)

  const forecastDashboardConfigs = useMemo(() => {
    return dashboardsConfigsContext.configs.find(
      (config) => config.dashboardType === 'prod_load_forecast',
    )?.items
  }, [dashboardsConfigsContext])

  /**
   * Take in the raw item data and processes out the values we need
   * @param itemData: LoadMoreReturnValue - raw item data
   * @param level: level that the item belongs to
   */
  const process = (itemData: AvailableForecastItem[], level?: Level) => {
    // Convert the timestamp to an integer and feed the results into cross filter and group by
    const cf = crossfilter(
      itemData.map((item) => ({
        ...item,
        timestamp: new Date(item.timestamp),
        processTime: item.processTime,
      })),
    )

    const dim = cf.dimension((d) => [d.market, d.processTime])

    const red = reductio()

    red.value('duration').min('timestamp').max('timestamp')

    red
      .value('forecast')
      .min('forecast')
      .max('forecast')
      .count(true)
      .dataList(true)

    const group = dim.group()

    red(group)

    const all = group.all()

    interface ValueBasedGrouping extends Grouping<any, any> {
      value: any
    }

    return all.map((g: ValueBasedGrouping) => {
      const [market, processTime] = g.key
      const { forecast, duration } = g.value
      const minDate = DateTime.fromMillis(duration.min)
      const maxDate = DateTime.fromMillis(duration.max)
      const dayCount = maxDate.diff(minDate, ['days'])
      return {
        market,
        processTime,
        min: Math.round(forecast.min * 100 + 4) / 100,
        max: Math.round(forecast.max * 100 + 4) / 100,
        count: Math.round(forecast.count),
        from: DateTime.fromMillis(duration.min),
        to: DateTime.fromMillis(duration.max),
        dayCount: Math.round(dayCount.days),
        forecast: forecast.dataList,
        level,
      }
    })
  }

  const renderCardSkeleton = (index) => {
    return (
      <ForecastSkeletonCard key={index} data-testid="forecast-card-skeleton" />
    )
  }

  const exploreSpecificForecast = (forecast) => {
    const encodedObject = Buffer.from(JSON.stringify(forecast)).toString(
      'base64',
    )
    history.push(
      `/demand/load-forecast/dashboard/unsaved?object=${encodedObject}`,
    )
  }

  const renderCard = (forecast) => {
    const configForForecast = forecastDashboardConfigs?.find(
      (e) => e.configId === forecast.level.configId,
    )
    let actions: SummaryCardAction[] = []
    if (configForForecast?.category.name === 'shortTermForecast') {
      actions = [
        {
          title: 'Explore in a dashboard',
          action: () => exploreSpecificForecast(forecast),
        },
        {
          title: 'Create an adjustment',
          action: () => {
            history.push(`adjustments/create/${JSON.stringify(forecast)}`)
          },
        },
      ]
    }
    const key = JSON.stringify(forecast)
    return (
      <ForecastCard
        actions={actions}
        selected={selected === key}
        onSelect={
          onSelect &&
          (() => {
            setSelected(key)
            onSelect(forecast)
          })
        }
        useActions={useActions}
        key={key}
        forecast={forecast}
        data-testid="forecast-card"
      />
    )
  }

  return (
    <SummaryPage
      key="AvailableForecasts"
      dataFunctions={{
        [ForecastType.ShortTermForecast]: {
          initialize: initShortTerm,
          loadMore: loadMoreShortTerm,
          process,
        },
        [ForecastType.LongTermForecast]: {
          initialize: initLongTerm,
          loadMore: loadMoreLongTerm,
          process,
        },
      }}
      renderCard={renderCard}
      renderCardSkeleton={renderCardSkeleton}
      tabCategories={tabCategories}
      title={title}
      errorMessage={errorMessage}
      pageActions={pageActions}
    />
  )
}

export default ForecastsList
