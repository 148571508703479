import { Button, makeStyles, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import CheckboxGroupWithUtilities, {
  Metadata,
} from 'shared/components/CheckboxGroupWithUtilities'
import Collapsible from 'shared/components/Collapsible'
import { Filter } from 'shared/types'
import AdjustmentVersionSelector from './AdjustmentVersionSelector'

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(2),
    height: '500px',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoColumns: 'auto',
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    paddingBottom: '2px',
  },
  titleSelected: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 1000,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    paddingBottom: '0px',
    borderRadius: 0,
  },
}))

export interface Props {
  sortedVersions: { createdAt: string; versionId: string }[]
  selectedVersionId?: string
  onVersionChange: (versionId: string) => void
  filters: Filter[]
  onFilterChange: (name: string, metadata: Array<Metadata>) => void
}

// TODO: give more specific names to adjustment menu
const AdjustmentMenu = ({
  sortedVersions,
  onVersionChange,
  selectedVersionId,
  filters,
  onFilterChange,
}: Props) => {
  const classes = useStyles()
  const [selectedOption, setSelectedOption] = useState<'filters' | 'history'>(
    'filters',
  )
  return (
    <>
      <div>
        <Button
          className={
            selectedOption === 'filters' ? classes.titleSelected : classes.title
          }
          onClick={() => setSelectedOption('filters')}
        >
          Filter
        </Button>
        <Button
          className={
            selectedOption === 'history' ? classes.titleSelected : classes.title
          }
          onClick={() => setSelectedOption('history')}
        >
          History
        </Button>
      </div>
      <Paper className={classes.paper}>
        {selectedOption === 'history' && (
          <AdjustmentVersionSelector
            sortedVersions={sortedVersions}
            onChange={onVersionChange}
            selectedVersionId={selectedVersionId}
          />
        )}
        {selectedOption === 'filters' && (
          <div>
            {filters.map(({ name, title, items }) => {
              return (
                <Collapsible
                  title={title}
                  key={name}
                  // defaultMenuIsOpen={index < 1}
                >
                  <CheckboxGroupWithUtilities
                    items={items}
                    onCheckboxChange={(e, metadata) => {
                      onFilterChange(name, metadata)
                    }}
                    hasSearch={items.length > 10}
                    hasSelectAllClearAll
                    isVirtual={items.length > 25}
                    virtualMinHeight={200}
                    virtualItemSize={30}
                  />
                </Collapsible>
              )
            })}
          </div>
        )}
      </Paper>
    </>
  )
}

export default AdjustmentMenu
