import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const SummaryVisualizationStyle = makeStyles((theme) => ({
  root: {
    '& > *:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  summaryItem: {
    padding: theme.spacing(1, 3),
    display: 'inline-block',
  },
}))

interface Props {
  /**
   * seriesData is array of label and value and these are coming from processing to
   * of data series in the cards. label is data series name and value is number
   */
  seriesData: Array<{ label: string; value: number }>
  /**
   * unit is a string value that represent a valid unit right now ,
   * this is same for every dataseries on the cards so that unit conversion can happen
   */
  unit: string
  /**
   * tranform is a function which accept number as in paramter and returns a tranformed string
   * which is used to display data in a transformed manner
   */
  transform?: (value: number) => string
}

/**
 * Encapsulated component for the summary visualization with summary data on the card
 * This is a controlled component as you can see by looking at props ,
 * this does not have a interaction and is for display purpose only
 * it helps in visualising data at an overall level
 */
const SummaryVisualization = ({ seriesData, transform, unit }: Props) => {
  const classes = SummaryVisualizationStyle()

  return (
    <div className={classes.root}>
      {seriesData.map(({ label, value }) => {
        return (
          <div key={`${label}${value}`} className={classes.summaryItem}>
            <Typography variant="overline" color="textSecondary" gutterBottom>
              {label}
            </Typography>
            <Typography variant="h4">
              {transform
                ? `${transform(value)} ${unit}`
                : `${value.toLocaleString()} ${unit}`}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

export default SummaryVisualization
