import {
  DashboardConfigItem,
  SimpleDashboard,
  Visualization,
} from 'shared/types'
import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'

export const createNewUnsavedDashboardObject = (
  forecast: any,
  dashboardConfigItems: Array<DashboardConfigItem>,
  userId: string,
) => {
  const dashboardSkeleton: SimpleDashboard = {
    dashboardId: 'unsaved',
    addedOn: DateTime.local().toISO(),
    name: 'Untitled',
    description: '',
    cards: [
      {
        title: 'untitled card',
        dataSeries: [],
        group: 'hour',
        visualization: Visualization.Line,
        id: nanoid(),
      },
    ],
    filters: [],
    timeFilters: [],
    calculators: [],
    isHourEndingSelected: true,
    from: {
      quantity: 0,
      multiplier: 'days',
      identifier: 'startData',
      dateType: 'fixed',
      value: DateTime.fromISO(forecast.from),
    },
    to: {
      quantity: 0,
      multiplier: 'days',
      identifier: 'endData',
      dateType: 'fixed',
      value: DateTime.fromISO(forecast.to),
    },
    org: null,
    owner: userId,
    sharedUsers: [],
    rangeFilters: [],
  }

  const dashboardConfig = dashboardConfigItems.find(
    (config) => config.configId === forecast.level.configId,
  )

  let unsavedDashboard = {
    ...dashboardSkeleton,
    ...dashboardConfig,
  }

  unsavedDashboard = {
    ...unsavedDashboard,
    filters: unsavedDashboard.filters.map((filter) => {
      if (filter.name === 'market') {
        return {
          ...filter,
          items: [{ id: forecast.market, isChecked: true }],
        }
      }

      return {
        ...filter,
        items: [],
      }
    }),
  }

  return unsavedDashboard
}

export default {}
