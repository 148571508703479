import React, { useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import {
  Calculation,
  Dashboard,
  DashboardConfigItem,
  DataSeries,
  DataSeriesData,
  DataSeriesType,
  Group,
  SingleCalculationOption,
} from 'shared/types'
import { calculationOptions } from 'shared/common/dashboardOptions'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import SideBarMenu from 'shared/components/SideBarMenu'
import DraggableMenu, {
  DraggableInnerMenuProps,
} from 'shared/components/DraggableMenu'
import {
  fetchDataForRisk,
  RiskFetchingInfo,
} from 'modules/supply/products/risk/helper'
import { createGroups, getLoadDataSeriesTypeByCommodity } from 'shared/helpers'
import BaseDashboard, {
  CardsDataSeriesManager,
  BuildCalculatorDataSeries,
} from 'shared/components/BaseDashboard/BaseDashboard'
import { HandleSave } from 'shared/pages/DashboardDetail'
import { setDataSeriesData } from 'shared/utils/dataSeriesDragAndDrop'
import {
  cleanFiltersForValidNetworkRequest,
  mergeSelectedFilters,
} from 'shared/utils/filtersOperations'
import { mergeDataSeries } from 'shared/utils/dataSeriesHelpers'
import { getDataSeriesTypeFromUnit, getDefinition } from 'shared/common/units'
import { getSelectedOptionLabel } from 'shared/components/Calculator/Calculator'
import useTimeAndDimensionFilters from 'shared/hooks/useTimeAndDimensionFilters'
import {
  getLatestAsOfDate,
  getRiskDistinctData,
  getMinMaxForColumns,
} from '../apiClient'

// TODO: Define filters!
interface Props {
  datasource: string
  filtersDefinitions: DashboardConfigItem['filters']
  savedDashboardInfo: Dashboard
  timeInterval: number
  onSave: HandleSave
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
}

enum TimeSeries {
  DEMAND = 'demand',
  SUPPLY = 'supply',
  NOP = 'position',
}

const timeSeriesInnerMenu: DraggableInnerMenuProps[] = [
  {
    id: 'dataSeriesMenuOptions',
    isCollapsible: false,
    options: [
      {
        id: TimeSeries.SUPPLY,
        title: 'Supply',
      },
      {
        id: TimeSeries.DEMAND,
        title: 'Demand',
      },
      {
        id: TimeSeries.NOP,
        title: 'Net Open Position',
      },
    ],
  },
]

const buildAsOfDateOption = (elem: DateTime) => ({
  value: elem.toISO(),
  label: elem.toLocaleString(DateTime.DATETIME_MED),
})

export const useShortAndLongTermNOP = ({
  savedDashboardInfo,
  datasource,
  filtersDefinitions,
  timeInterval,
  rangeFiltersDefinition,
}: Omit<Props, 'onSave'>) => {
  const commodityType = savedDashboardInfo.commodity.name

  const [isHourEndingSelected, setIsHourEndingSelected] = useState<Boolean>(
    savedDashboardInfo?.isHourEndingSelected || false,
  )

  const [asOfDate, setAsOfDate] = useState<null | DateTime>(null)
  const [
    availableAsOfDates,
    setAvailableAsOfDates,
  ] = useState<Array<any> | null>([])

  useEffect(() => {
    getLatestAsOfDate(datasource).then((asOfDates) => {
      const options = asOfDates.map((d) => DateTime.fromISO(d, { zone: 'utc' }))
      setAvailableAsOfDates(options)
      setAsOfDate(options[0])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculatorDataSeriesOptions = useMemo(() => {
    return timeSeriesInnerMenu[0].options.map((e) => {
      const dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
      return {
        id: e.id,
        title: e.title,
        unit: getDefinition(dataSeriesType).defaultUnitValue,
        tagOptions: calculationOptions.map((calc) => ({
          value: calc[0],
          label: calc[1],
        })),
      }
    })
  }, [commodityType])

  const filters = useTimeAndDimensionFilters(
    savedDashboardInfo.filters,
    timeInterval,
    () =>
      getRiskDistinctData(
        datasource,
        filtersDefinitions.map((e) => e.name),
      ),
    filtersDefinitions,
    rangeFiltersDefinition,
    () =>
      getMinMaxForColumns(
        datasource,
        rangeFiltersDefinition?.map((e) => e.name) ?? [],
      ),
    savedDashboardInfo.rangeFilters,
    savedDashboardInfo.timeFilters,
  )

  const { dimensionFilters, timeFilters, rangeFilters } = filters

  const selectedFilters = useMemo(
    () => [...dimensionFilters.selectedFilters, ...timeFilters.selectedFilters],
    [timeFilters.selectedFilters, dimensionFilters.selectedFilters],
  )

  const manager = useMemo<
    CardsDataSeriesManager<RiskFetchingInfo, DataSeriesData>
  >(
    () => ({
      getArgumentToFetch(
        card,
        { id, selectedCalculation, selectedFilters: seriesSelectedFilter },
      ) {
        if (!asOfDate) return null

        const globalAndIndividualSeriesFilterIntersection = mergeSelectedFilters(
          selectedFilters,
          seriesSelectedFilter ?? [],
        )

        return asOfDate
          ? {
              dataSeriesId: id,
              calculation: selectedCalculation?.[0] as Calculation,
              group: createGroups(card),
              selectedFilters: cleanFiltersForValidNetworkRequest(
                globalAndIndividualSeriesFilterIntersection,
              ),
              asOfDate,
              rangeFilters: rangeFilters.filters,
            }
          : null
      },
      fetchData(fetchingArgument) {
        return fetchDataForRisk(fetchingArgument, datasource, asOfDate)
      },
      mergeGroupedFetchedData: (keyExpression, data, parsedKeys) => {
        const { group } = parsedKeys[0]
        return mergeDataSeries(keyExpression, data, group[0])
      },
      correctCardDataSeries: (card, dataSeries) => dataSeries,
      key: asOfDate,
    }),
    [asOfDate, datasource, selectedFilters, rangeFilters.filters],
  )

  const buildCalculatedDataSeries: BuildCalculatorDataSeries = (
    calculator,
    referenceSeries,
  ) => {
    const dataSeriesCombined: DataSeries[] = []
    Object.values(calculator.expressionDataIdMap).forEach((val) => {
      const option = calculatorDataSeriesOptions.find((o) => o.id === val.id)
      const selectedCalculation = val.selectedOption
        ? ([val.selectedOption, val.selectedOption] as SingleCalculationOption)
        : undefined
      dataSeriesCombined.push({
        id: val.id,
        label: option?.title || '',
        calculationOptions,
        selectedCalculation,
        selectedSplit: null,
        type: DataSeriesType.PowerLoad,
        selectedFilters: dimensionFilters.selectedFilters,
        rangeFilters: rangeFilters.filters,
      })
    })
    return {
      ...referenceSeries,
      id: calculator.uid,
      label: calculator.name,
      selectedSplit: null,
      type: getDataSeriesTypeFromUnit(calculator.unit || ''),
      customUnit: calculator.unit || '',
      groupedItems: {
        expression: calculator.expression,
        expressionScope: calculator.expressionDataIdMap,
        expressionDataIdMap: calculator.expressionDataIdMap,
        dataSeries: dataSeriesCombined,
        key: calculator.expression,
      },
    }
  }

  return {
    isHourEndingSelected,
    setIsHourEndingSelected,
    asOfDate,
    setAsOfDate,
    availableAsOfDates,
    setAvailableAsOfDates,
    manager,
    filters,
    buildCalculatedDataSeries,
    calculatorDataSeriesOptions,
  }
}

const ShortAndLongTermNOP = ({
  savedDashboardInfo,
  datasource,
  onSave,
  filtersDefinitions,
  timeInterval,
  rangeFiltersDefinition,
}: Props) => {
  const commodityType = savedDashboardInfo.commodity.name

  const {
    isHourEndingSelected,
    setIsHourEndingSelected,
    asOfDate,
    setAsOfDate,
    availableAsOfDates,
    manager,
    filters,
    buildCalculatedDataSeries,
    calculatorDataSeriesOptions,
  } = useShortAndLongTermNOP({
    savedDashboardInfo,
    datasource,
    filtersDefinitions,
    timeInterval,
    rangeFiltersDefinition,
  })

  const {
    dimensionFilters,
    timeFilters,
    rangeFilters,
    onChange: onFilterChange,
    isInitializing: areFiltersInitializing,
  } = filters

  return (
    <BaseDashboard
      dashboardId={savedDashboardInfo.dashboardId}
      calculatorLabelAccessor={(option) => {
        const selectedOptionLabel = getSelectedOptionLabel(option)
        return [selectedOptionLabel, option.title].filter((e) => e).join(' ')
      }}
      calculatorDataSeriesOptions={calculatorDataSeriesOptions}
      buildCalculatorDataSeries={buildCalculatedDataSeries}
      calculators={savedDashboardInfo.calculators}
      isInitializing={areFiltersInitializing}
      areFiltersLoading={false}
      isLoading={false}
      cardsDataSeriesManager={manager}
      defaultCards={savedDashboardInfo.cards}
      filtersDefinitions={filtersDefinitions}
      filters={dimensionFilters.filters}
      timeFilters={timeFilters.filters}
      selectedTimeFilters={timeFilters.selectedFilters}
      onFilterChange={onFilterChange}
      rangeFilters={rangeFilters.filters}
      onRangeFilterChange={rangeFilters.onChange}
      timeInterval={timeInterval}
      defaultNewCardGrouping={Group.Hourly}
      isHourEndingSelected={isHourEndingSelected}
      onIsHourEndingSelectedChange={(e, newIsHourEndingSelected) =>
        setIsHourEndingSelected(newIsHourEndingSelected)
      }
      onSave={(e, cards, extras) =>
        onSave(e, {
          ...extras,
          cards,
          filters: dimensionFilters.filters,
          timeFilters: timeFilters.filters,
          rangeFilters: rangeFilters.filters.map(({ name, value }) => ({
            name,
            value,
          })),
          isHourEndingSelected,
        })
      }
    >
      {availableAsOfDates && asOfDate && (
        <Autocomplete
          onChange={(e, newOption) => {
            setAsOfDate(DateTime.fromISO(newOption.value, { zone: 'utc' }))
          }}
          options={availableAsOfDates.map(buildAsOfDateOption)}
          value={buildAsOfDateOption(asOfDate)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => {
            return option.value === value.value
          }}
          disableClearable
          fullWidth
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder="As Of Date"
              margin="dense"
              label="As Of Date"
              variant="standard"
            />
          )}
        />
      )}
      <SideBarMenu title="time series" isLoading={false}>
        <DraggableMenu
          isCollapsible={false}
          innerMenus={timeSeriesInnerMenu}
          onDrag={(e, metadata) => {
            setDataSeriesData(e.dataTransfer, {
              id: metadata.id,
              type: getLoadDataSeriesTypeByCommodity(commodityType),
              calculationOptions,
              selectedCalculation: calculationOptions[0],
              label: metadata.title,
            })
          }}
        />
      </SideBarMenu>
    </BaseDashboard>
  )
}

export default ShortAndLongTermNOP
