import { useEffect, useReducer, Reducer } from 'react'
import { DateTime } from 'luxon'
import { calculateRelativeDate } from 'shared/components/DateSelector'
import { GeneralDateSelector } from 'shared/types'
import { getDruidDatasourceTimeBoundary } from '../../../common/apiClient'

const initialize = ({
  min,
  max,
  from,
  to,
}: {
  min: DateTime
  max: DateTime
  from: GeneralDateSelector
  to: GeneralDateSelector
}): {
  type: 'INITIALIZE'
  min: DateTime
  max: DateTime
  from: GeneralDateSelector
  to: GeneralDateSelector
} => ({
  type: 'INITIALIZE',
  min,
  max,
  from,
  to,
})

const changeDateRange = ({
  main,
  state,
}: {
  main: 'from' | 'to'
  state: GeneralDateSelector
}): {
  type: 'CHANGE_DATE_RANGE'
  main: 'from' | 'to'
  state: GeneralDateSelector
} => ({
  type: 'CHANGE_DATE_RANGE',
  main,
  state,
})

const setMinMax = ({
  min,
  max,
}: {
  min: DateTime
  max: DateTime
}): {
  type: 'CHANGE_MIN_MAX'
  min: DateTime
  max: DateTime
} => ({
  type: 'CHANGE_MIN_MAX',
  min,
  max,
})

interface State {
  isInitializing: boolean
  from: GeneralDateSelector | null
  to: GeneralDateSelector | null
  min: DateTime | null
  max: DateTime | null
  dateRange: { from: DateTime; to: DateTime } | null
  edges: { from: DateTime; to: DateTime } | null
}

type ReducerAction =
  | ReturnType<typeof changeDateRange>
  | ReturnType<typeof initialize>
  | ReturnType<typeof setMinMax>

const reducer: Reducer<State, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const fromValue = calculateRelativeDate(
        action.min,
        action.max,
        action.from,
      )
      const toValue = calculateRelativeDate(action.min, action.max, action.to)
      return {
        isInitializing: false,
        min: action.min,
        max: action.max,
        from: { ...action.from, value: fromValue },
        to: { ...action.to, value: toValue },
        dateRange: { from: fromValue, to: toValue },
        edges: { from: action.min, to: action.max },
      }
    }

    case 'CHANGE_DATE_RANGE': {
      if (!state.min || !state.max) {
        return state
      }
      const newValue = calculateRelativeDate(state.min, state.max, action.state)
      const newState = {
        ...state,
        [action.main]: { ...action.state, value: newValue },
      }

      newState.dateRange =
        newState.from && newState.to
          ? { from: newState.from.value, to: newState.to.value }
          : null
      return newState
    }

    case 'CHANGE_MIN_MAX': {
      const newState = {
        ...state,
        min: action.min,
        max: action.max,
        edges: { from: action.min, to: action.max },
      }
      if (newState.from) {
        newState.from.value = calculateRelativeDate(
          action.min,
          action.max,
          newState.from,
        )
      }
      if (newState.to) {
        newState.to.value = calculateRelativeDate(
          action.min,
          action.max,
          newState.to,
        )
      }
      newState.dateRange =
        newState.from && newState.to
          ? { from: newState.from.value, to: newState.to.value }
          : null
      return newState
    }

    default:
      return state
  }
}

const useDates = (
  datasource: string,
  defaultFrom: GeneralDateSelector,
  defaultTo: GeneralDateSelector,
) => {
  const [
    { min, max, from, to, dateRange, isInitializing, edges },
    dispatch,
  ] = useReducer(reducer, {
    min: null,
    max: null,
    from: null,
    to: null,
    dateRange: null,
    isInitializing: true,
    edges: null,
  })

  useEffect(() => {
    getDruidDatasourceTimeBoundary(datasource).then(
      ({ minISODate, maxISODate }) => {
        const minDateTime = DateTime.fromISO(minISODate)
        const maxDateTime = DateTime.fromISO(maxISODate)
        dispatch(
          initialize({
            min: minDateTime,
            max: maxDateTime,
            from: defaultFrom,
            to: defaultTo,
          }),
        )
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasource])

  return {
    min,
    max,
    from,
    to,
    dateRange,
    edges,
    isInitializing,
    setFromDate: (state: GeneralDateSelector) => {
      dispatch(changeDateRange({ main: 'from', state }))
    },
    setToDate: (state: GeneralDateSelector) => {
      dispatch(changeDateRange({ main: 'to', state }))
    },
    setMinMax: (minDate: DateTime, maxDate: DateTime) => {
      dispatch(setMinMax({ min: minDate, max: maxDate }))
    },
  }
}

export default useDates
