import DemandButton, { DEMAND_PRODUCTS } from 'modules/demand/Main'
import CustomerButton, { CUSTOMER_PRODUCTS } from 'modules/customer/Main'
import SupplyButton, { SUPPLY_PRODUCTS } from 'modules/supply/Main'
import InsightsButton, { INSIGHT_PRODUCTS } from 'modules/insights/Main'
import {
  ApplicationProduct,
  ProductModuleButtonComponent,
  UserType,
} from 'shared/types'

interface ProductModule {
  mod: string
  moduleButton: any
}

const APPLICATION_MODULES: Array<ProductModule> = [
  {
    mod: 'mod_demand',
    moduleButton: DemandButton,
  },
  {
    mod: 'mod_supply',
    moduleButton: SupplyButton,
  },
  {
    mod: 'mod_customer',
    moduleButton: CustomerButton,
  },
  {
    mod: 'mod_insights',
    moduleButton: InsightsButton,
  },
]

/**
 * List of the Product Modules available on the app with their "mod"
 * identifier and their product identifier, the base path for its content and their respective configuration
 * objects, that include the specific components to be used for the
 * main Hub button, the header menus and its content when their route is accessed.
 */
const APPLICATION_PRODUCTS: Array<ApplicationProduct> = [
  ...DEMAND_PRODUCTS,
  ...CUSTOMER_PRODUCTS,
  ...SUPPLY_PRODUCTS,
  ...INSIGHT_PRODUCTS,
]

/**
 * Returns an structure taylored for a user with both the information for the buttons to be
 * used on the main hub and the different route related components for
 * the available product modules.
 * The "buttons" property array always include all the modules of the system, with an
 * "isEnabled" property set to true or false depending on the user permission to
 * access them.
 * The "routes" property array only returns those available for the current user.
 * @param user Target user for the returned product modules information.
 */
export default function getProductModulesInfoForUser(user: UserType) {
  const finalProductsForUser: {
    buttons: Array<{
      mod: string
      ProductModuleButton: ProductModuleButtonComponent
      products: Array<ApplicationProduct>
    }>
    content: Array<{
      mod: string
      products: Array<ApplicationProduct>
    }>
  } = { buttons: [], content: [] }

  APPLICATION_PRODUCTS.forEach((product) => {
    const { mod, product: productName } = product

    const isEnabled = user.mods.includes(productName)

    const isModInButtons = finalProductsForUser.buttons.findIndex(
      (el) => el.mod === mod,
    )

    if (isModInButtons > -1) {
      finalProductsForUser.buttons[isModInButtons].products.push({
        ...product,
        active: isEnabled,
      })
    } else {
      finalProductsForUser.buttons.push({
        mod,
        ProductModuleButton:
          APPLICATION_MODULES.find((el) => el.mod === mod)?.moduleButton ||
          null,
        products: [
          {
            ...product,
            active: isEnabled,
          },
        ],
      })
    }

    if (isEnabled) {
      const isModInContent = finalProductsForUser.content.findIndex(
        (el) => el.mod === mod,
      )

      if (isModInContent > -1) {
        finalProductsForUser.content[isModInContent].products.push({
          ...product,
        })
      } else {
        finalProductsForUser.content.push({
          mod,
          products: [
            {
              ...product,
            },
          ],
        })
      }
    }
  })

  return finalProductsForUser
}
