import React, {
  SyntheticEvent,
  useState,
  ReactEventHandler,
  useEffect,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, TextField, MenuItem } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import VirtualizedTable, {
  Column,
  TableRow,
  TableCell,
  CustomRowRenderer,
} from 'shared/components/VirtualizedTable'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { InputModel, Input } from 'shared/types'
import { getDefaultStartDate, getDefaultEndDate } from '../helpers'

interface SelectorsProps {
  /**
   * Options available for the "market" field on
   * the selectors for new Inputs or those being modified.
   *
   * It expects an array of simple strings.
   */
  marketOptions: string[]
  /**
   * Options available for the "iso" field on
   * the selectors for new Inputs or those being modified.
   *
   * It expects an array of simple strings.
   */
  isoOptions: string[]
  /**
   * Options available for the "profile" field on
   * the selectors for new Inputs or those being modified.
   *
   * It expects an array of simple strings.
   */
  profileOptions: string[]
  /**
   * Options available for the "edc" field on
   * the selectors for new Inputs or those being modified.
   *
   * It expects an array of simple strings.
   */
  edcOptions: string[]
  /**
   * Options available for the "product" field on
   * the selectors for new Inputs or those being modified.
   *
   * It expects an array of simple strings.
   */
  productOptions: string[]
}

/**
 * Returns a string with the properly formatted date
 * @param date
 */
function formatDate(date: Date): string {
  /* TODO: Consider if we'll be using diferent formats for different
  languages */
  return DateTime.fromJSDate(date).toFormat('LL/dd/yyyy')
}

const useTextFieldStyles = makeStyles((theme) => ({
  input: theme.typography.body2,
}))

const subHeaderStyle = makeStyles((theme) => ({
  subHeaderWithInput: {
    padding: theme.spacing(2, 0),
  },
}))

/**
 * Textfield properly styled and configured to be used in the forms
 * inside the table.
 */
const CustomTextField = (props) => {
  const classes = useTextFieldStyles()
  // eslint-disable-next-line react/prop-types
  const { name } = props
  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{ classes }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ 'aria-label': name }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

/**
 * DatePicker properly styled and configured to be used in the forms
 * inside the table.
 */
const MyDatePicker = (props) => {
  const classes = useTextFieldStyles()
  return (
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      inputVariant="outlined"
      InputProps={{ classes, margin: 'dense' }}
      format="LL/dd/yyyy"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      // autoOk
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

interface EditableInputRowProps extends SelectorsProps {
  inputModel: InputModel
  setInputModel: (callback: (prevModel: InputModel) => void) => void
  isValidModel: boolean
  onEditConfirm: ReactEventHandler
  onEditCancel?: ReactEventHandler
  style?: CSSProperties
  newInput?: boolean
}

/**
 * Row that acts as a form to create new Inputs or modify existing ones.
 *
 * It is completely controled. And it expects to be passed both a model object and a
 * function to update it (for example, that returned via "useState" hook).
 */
const EditableInputRow = ({
  inputModel,
  setInputModel,
  isValidModel,
  marketOptions,
  isoOptions,
  profileOptions,
  edcOptions,
  productOptions,
  onEditConfirm,
  onEditCancel,
  style,
  newInput,
}: EditableInputRowProps) => {
  const classes = subHeaderStyle()

  function updateModel(newValues) {
    setInputModel((prevState) => ({ ...prevState, ...newValues } as InputModel))
  }
  const selectorFields: Array<[string, string[]]> = [
    ['market', marketOptions],
    ['iso', isoOptions],
    ['profile', profileOptions],
    ['edc', edcOptions],
    ['product', productOptions],
  ]
  const dateFields: string[] = ['startDate', 'endDate', 'asOfDate']

  return (
    <TableRow style={style} className={classes.subHeaderWithInput}>
      <TableCell>
        <CustomTextField
          name="meterId"
          value={inputModel.meterId}
          onChange={(e) => {
            updateModel({ meterId: e.target.value })
          }}
        />
      </TableCell>
      {selectorFields.map(([fieldKey, options]: [string, string[]]) => {
        return (
          <TableCell key={`selectorField_${fieldKey}`}>
            <CustomTextField
              name={fieldKey}
              select
              value={inputModel[fieldKey] as string}
              onChange={(e) => {
                updateModel({ [fieldKey]: e.target.value })
              }}
            >
              {options.map((option) => (
                <MenuItem key={`${fieldKey}_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomTextField>
          </TableCell>
        )
      })}
      {dateFields.map((fieldKey) => {
        return (
          <TableCell key={`dateField_${fieldKey}`}>
            <MyDatePicker
              value={inputModel[fieldKey] as Date}
              onChange={(newLuxonDate) => {
                updateModel({ [fieldKey]: newLuxonDate.toJSDate() })
              }}
            />
          </TableCell>
        )
      })}
      <TableCell>
        <IconButton
          disabled={!isValidModel}
          size="small"
          title={newInput ? 'Add' : 'Edit'}
          onClick={onEditConfirm}
        >
          {newInput ? (
            <AddCircleOutlineOutlinedIcon />
          ) : (
            <CheckCircleOutlineOutlinedIcon />
          )}
        </IconButton>
        {!!(!newInput && onEditCancel) && (
          <IconButton size="small" title="Cancel" onClick={onEditCancel}>
            <CancelOutlinedIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

interface StatefulEditableInputRowProps extends SelectorsProps {
  defaultModel: Input
  validateInput: (model: InputModel) => boolean
  onEditConfirm: (event: SyntheticEvent, model: Input) => any
  onEditCancel: (event: SyntheticEvent) => any
  style: CSSProperties
  newInput?: boolean
}

/**
 * Stateful version of the EditableInputRow that will maintain its own model state.
 *
 * Specially intented to be used for editing existing Inputs.
 */
const StatefulEditableInputRow = ({
  defaultModel,
  marketOptions,
  isoOptions,
  profileOptions,
  edcOptions,
  productOptions,
  onEditConfirm,
  onEditCancel,
  validateInput,
  style,
}: StatefulEditableInputRowProps) => {
  const [model, setModel] = useState<InputModel>(() => {
    const { uid, ...copyWithoutUid } = defaultModel
    return copyWithoutUid
  })
  const isValid = validateInput(model)
  return (
    <EditableInputRow
      inputModel={model}
      setInputModel={setModel as any}
      isValidModel={isValid}
      marketOptions={marketOptions}
      isoOptions={isoOptions}
      profileOptions={profileOptions}
      edcOptions={edcOptions}
      productOptions={productOptions}
      onEditConfirm={(e) =>
        onEditConfirm(e, { ...model, uid: defaultModel.uid })
      }
      onEditCancel={onEditCancel}
      style={style}
    />
  )
}

export interface Props {
  /**
   * Complete list of inputs that will be displayed on the table.
   */
  inputs: Input[]
  /**
   * Object containing values for all the selector options inside SelectorProps that will be displayed in the table
   */
  options: SelectorsProps
  /**
   * Function that will be invoked to check if a form to create or edit
   * an **Input** is ready to be submitted.
   */
  validateInput: (model: InputModel) => boolean
  /**
   * Callback that will be invoked when the user submits a
   * new **Input**.
   *
   * By default the new **Input** form will be reset after this function ends
   * unless it returns an explicit *false* value.
   *
   * <pre>
   * (event: SyntheticEvent, newInput: InputModel) => false | void
   * </pre>
   */
  onInputAdd: (event: SyntheticEvent, newInput: InputModel) => false | void
  /**
   * Callback that will be invoked when the user submits modifications
   * for an existing **Input**.
   *
   * By default the appropriate row will stop being on "edit mode" after this function ends
   * unless it returns an explicit *false* value..
   *
   * <pre>
   * (
   *   event: SyntheticEvent,
   *   modifiedInput: InputModel,
   *   originalInput: Input,
   * ) => false | void
   * </pre>
   */
  onInputEdit: (
    event: SyntheticEvent,
    modifiedInput: InputModel,
    originalInput: Input,
  ) => false | void
  /**
   * Callback that will be invoked when the user requests for an input to
   * be deleted.
   *
   * <pre>
   * (event: SyntheticEvent, inputToDelete: Input) => void
   * </pre>
   */
  onInputDelete: (event: SyntheticEvent, inputToDelete: Input) => void
  /**
   * Optional classname to apply extra styling
   */
  className?: string
  /**
   * Optional default value for the "start date" input on the "new input" form.
   * Will also be used for "end date" and "as of date" selectors for now.
   * Mainly added for consistency on testing.
   *
   * If not included, the current date at the moment of its mounting will be used.
   */
  defaultStartDate?: Date
}

/**
 * Table to visualize and manage the **Inputs** for a **Calculation**.
 *
 * It provides a built-in form to create new **Inputs**, and also the possibility to
 * edit those currenlty displayed. It will act as an uncontrolled component on the
 * form elements, but won't actually mutate or store the modified data, but will
 * expect the parent component to apply the appropriate modifications to the source
 * data when certain callbacks are invoked (see the props documentation for more info).
 *
 * Validation for the new or modified data is also expected to be provided from the
 * parent element.
 *
 * A virtualized table is used on the inner implementation, so high volume of data
 * should be supported without major inconvenience.
 */
const InputsTable = ({
  inputs,
  options,
  validateInput,
  onInputDelete,
  onInputEdit,
  onInputAdd,
  defaultStartDate,
}: Props) => {
  const defaultMarket = options.marketOptions[0] || ''
  const defaultIso = options.isoOptions[0] || ''

  /*
    As the default state for the new input form will have may have to
    be set more than once (to reset) and the date time shouldn't be changing
    each time, it will stored on the inner state so it can be reused.
  */
  const [defaultNewInputModel, setDefault] = useState<any>(() => {
    const today = new Date()
    const realDefaultStartTime = getDefaultStartDate(defaultStartDate)

    const realDefaultEndTime = getDefaultEndDate(realDefaultStartTime)

    return {
      meterId: '',
      market: defaultMarket,
      iso: defaultIso,
      profile: '',
      edc: '',
      product: '',
      startDate: realDefaultStartTime,
      endDate: realDefaultEndTime,
      asOfDate: today,
    }
  })

  useEffect(() => {
    if (
      defaultNewInputModel.market !== defaultMarket ||
      defaultNewInputModel.iso !== defaultIso
    ) {
      setDefault((prevState: InputModel) => ({
        ...prevState,
        market: defaultMarket,
        iso: defaultIso,
      }))
    }
  }, [defaultMarket, defaultIso, defaultNewInputModel])

  const [newInputModel, setNewInputModel] = useState(defaultNewInputModel)

  useEffect(() => {
    setNewInputModel((prevState: InputModel) => ({
      ...prevState,
      ...defaultNewInputModel,
    }))
  }, [defaultNewInputModel])

  const isValidInputModel = validateInput(newInputModel)

  const [editingInputs, setEditingInputs] = useState<any>(new Set<string>())
  function toggleInputEdition(input: Input) {
    setEditingInputs((prevState) => {
      const newState = new Set(prevState)
      if (newState.has(input.uid)) newState.delete(input.uid)
      else newState.add(input.uid)
      return newState
    })
  }

  const columns: Column<Input>[] = [
    { header: 'Meter Id', render: 'meterId' },
    { header: 'Market', render: 'market' },
    { header: 'ISO', render: 'iso' },
    { header: 'Profile', render: 'profile' },
    { header: 'Edc', render: 'edc' },
    { header: 'Product', render: 'product' },
    {
      header: 'Start date',
      render: (item) => formatDate(item.startDate),
    },
    {
      header: 'End Date',
      render: (item) => formatDate(item.endDate),
    },
    {
      header: 'As of Date',
      render: (item) => formatDate(item.asOfDate),
    },
    {
      header: '',
      render: (item) => (
        <>
          <IconButton
            size="small"
            title="Edit"
            onClick={() => {
              toggleInputEdition(item)
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            size="small"
            title="Copy"
            onClick={() => {
              const { uid, ...newInput } = item
              setNewInputModel(newInput)
            }}
          >
            <FileCopyOutlinedIcon />
          </IconButton>
          <IconButton
            size="small"
            title="Delete"
            onClick={(event) => onInputDelete(event, item)}
          >
            <ClearOutlinedIcon />
          </IconButton>
        </>
      ),
    },
  ]

  const subheaderRow = (
    <EditableInputRow
      newInput
      inputModel={newInputModel}
      setInputModel={setNewInputModel as any}
      isValidModel={isValidInputModel}
      marketOptions={options.marketOptions}
      isoOptions={options.isoOptions}
      productOptions={options.productOptions}
      edcOptions={options.edcOptions}
      profileOptions={options.profileOptions}
      onEditConfirm={(event) => {
        if (onInputAdd(event, newInputModel) !== false) {
          setNewInputModel(defaultNewInputModel)
        }
      }}
    />
  )

  const renderCustomRow = ((index, style) => {
    const input = inputs[index]
    if (editingInputs.has(input.uid)) {
      return (
        <StatefulEditableInputRow
          style={style}
          validateInput={validateInput}
          defaultModel={input}
          marketOptions={options.marketOptions}
          isoOptions={options.isoOptions}
          productOptions={options.productOptions}
          edcOptions={options.edcOptions}
          profileOptions={options.profileOptions}
          onEditConfirm={(event, modifiedInput) => {
            if (onInputEdit(event, modifiedInput, input) !== false) {
              toggleInputEdition(input)
            }
          }}
          onEditCancel={() => {
            toggleInputEdition(input)
          }}
        />
      )
    }
    return null
  }) as CustomRowRenderer

  return (
    <VirtualizedTable
      columns={columns}
      items={inputs}
      itemKey="uid"
      subheaderRow={subheaderRow}
      renderCustomRow={renderCustomRow}
    />
  )
}

export default InputsTable
