import React, {
  MouseEventHandler,
  FunctionComponent,
  DragEvent,
  SyntheticEvent,
  ReactElement,
  ReactNode,
} from 'react'
import {
  Paper,
  styled,
  makeStyles,
  InputLabel,
  Select,
  FormControl,
  IconButton,
  Typography,
  MenuItem,
  SvgIcon,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import Plotly from 'plotly.js-basic-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import { Move } from 'react-feather'
import { ReactComponent as LineChart } from 'icons/line-chart.svg'
import { ReactComponent as ComboChart } from 'icons/combo-chart.svg'
import { ReactComponent as BarChart } from 'icons/bar-chart.svg'
import { ReactComponent as DataTable } from 'icons/data-table.svg'
import { ReactComponent as AreaChart } from 'icons/area-chart.svg'
import { ReactComponent as StackChart } from 'icons/stack-chart.svg'
import { ReactComponent as SummaryChart } from 'icons/summary-chart.svg'
import { ReactComponent as MeterTableIcon } from 'icons/meter-table.svg'
import {
  Visualization,
  DataSeries,
  DataSeriesType,
  CardDefinition,
  GroupOptions,
  AxisId,
  CardTypeUnits,
  LegendPosition,
  CardTableGroup,
  Group,
  Filter,
} from 'shared/types'
import { GridPageChangeParams } from '@material-ui/data-grid'
import useNonEmptyTextField from 'shared/hooks/useNonEmptyTextField'
import PivotTable from 'shared/components/DashboardCard/PivotTable'
import generateDragAndDropUtilities from 'shared/utils/generateDragAndDropUtilities'
import DataSeriesChipsInput from './DataSeriesChipsInput'
import TransparentTextField from '../TransparentTextField'
import ToolbarWithMoreActions from './ToolbarWithMoreActions'
import {
  createCardDataSeriesLabel,
  getUnitsLabelForDataSeries,
  processCardDataSeries,
  GetDataHandler,
  DataTableData,
} from './helpers'
import ChipsInput, { Chip } from './ChipsInput'
import SummaryVisualization from './SummaryVisualization'
import MeterTable from './MeterTable'

const Plot = createPlotlyComponent(Plotly)

const { PUBLIC_URL } = process.env

const LOADING_IMAGE = `${PUBLIC_URL}/icons/statistics.svg`

const NO_GROUP_VALUE = '__ALL__'
interface AxisDropzone {
  id: AxisId
  label: string
}

// TODO: Need to confirm wiith UX (better than previous)
const colorway = [
  '#636efA',
  '#00cc96',
  '#ef553b',
  '#ab63fa',
  '#ffa15a',
  '#19d3f3',
  '#ff6692',
  '#b6e880',
  '#ff97ff',
  '#fecb52',
]

const visualizationButtons: [
  Visualization,
  string,
  FunctionComponent<React.SVGProps<SVGSVGElement>>,
][] = [
  [Visualization.Line, 'Line Chart', LineChart],
  [Visualization.MultiAxis, 'Combo Chart', ComboChart],
  [Visualization.Bar, 'Bar Chart', BarChart],
  [Visualization.Area, 'Area Chart', AreaChart],
  [Visualization.Table, 'Data Table', DataTable],
  [Visualization.Stack, 'Stack Chart', StackChart],
  [Visualization.Summary, 'Summary Chart', SummaryChart],
  [Visualization.MeterTable, 'Meter Table', MeterTableIcon],
]

export const StyledNoDataImage = styled('img')(() => ({
  display: 'block',
  margin: '6% auto 0',
  width: '110px',
  height: '110px',
}))

export const StyledSpinner = styled(CircularProgress)(() => ({
  position: 'absolute',
  top: '45%',
  right: '50%',
  'z-index': '1',
}))

const DraggableIcon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  borderRight: `1px solid ${theme.palette.divider}`,
  paddingRight: theme.spacing(2),
  marginRight: theme.spacing(2),
  '&:hover': {
    cursor: 'grab',
  },
  '&:active': {
    cursor: 'grabbing',
  },
}))

export const DashedCard = styled(Paper)(({ theme }) => ({
  border: '2px dashed',
  borderColor: theme.palette.divider,
  height: 'inherit',
}))

const dropzones: Array<AxisDropzone> = [
  {
    id: AxisId.Primary,
    label: 'PRIMARY AXIS',
  },
  {
    id: AxisId.Secondary,
    label: 'SECONDARY AXIS',
  },
]

const commonYAxisConfig = {
  type: 'linear',
  showgrid: true,
  showline: true,
  zeroline: false,
  linecolor: '#E9EBF1',
  side: 'left',
  automargin: true,
}

function buildPlotLegendFields(legendPosition: LegendPosition) {
  const legendSettings = {
    legend: { legend: { orientation: 'v', x: 1.1, y: 1 }, xanchor: 'left' },
  }
  switch (legendPosition) {
    case LegendPosition.Bottom: {
      return { legend: { orientation: 'h', x: 0, y: -0.1, yanchor: 'top' } }
    }
    case LegendPosition.Top: {
      return {
        legend: { orientation: 'h', x: 0, y: 1.01, yanchor: 'bottom' },
      }
    }
    case LegendPosition.Left: {
      return { legend: { orientation: 'v', x: -0.1, y: 1, xanchor: 'right' } }
    }
    case LegendPosition.Right: {
      return { legend: { orientation: 'v', x: 1.1, y: 1 }, xanchor: 'left' }
    }
    case LegendPosition.None: {
      return { showlegend: false }
    }
    default: {
      return legendSettings
    }
  }
}

const {
  setDataItem: setDraggedTableGroup,
  getDataItemsSuffixes: getDraggedTableGroupsTypes,
  getDataItems: getDroppedTableGroups,
} = generateDragAndDropUtilities(
  'x-card-table-group-',
  (data: Omit<CardTableGroup, 'direction'>) => data.groupType,
)

export {
  setDraggedTableGroup,
  getDroppedTableGroups,
  getDraggedTableGroupsTypes,
}

export interface Props {
  /**
   * Card definition values related to this component
   */
  definition: CardDefinition
  /**
   * Callback that is invoked any time the definition of the card may have changed
   * due to an interaction with the component.
   */
  onDefinitionChange: (
    e: SyntheticEvent,
    modifiedDefinition: CardDefinition,
    originalDefinition: CardDefinition,
  ) => void
  /** Default card title in case `title` is empty */
  defaultTitle: string
  /** Function triggered when a valid draggable element is dropped on card zone */
  onDrop: (e: DragEvent, series: DataSeries) => void
  /** Function that will be invoked when an acceptable element is dragged over the card, allows the user to specify if it can be dropped base on its "type", that is passed
   * on the second argument. */
  canDrop: (e: DragEvent, type: DataSeriesType) => boolean
  /** Extra classname to add to root wrapping HTML element */
  className?: string
  /**
   * Determines the grouping options to be shown on the card.
   */
  groupOptions: GroupOptions
  /** Function triggered when download button (inside popover) is pressed */
  onDownload: (
    e: MouseEventHandler,
    title: string,
    downloadData: DataTableData,
  ) => void
  /** Classname for dragicon. This is necessary if you want to tie the dragging to a specific icon in the card (see react-grid-layout for more info).
   * Providing this will display a Move icon on the top left of the card
   */
  dragIconClassName?: string
  /**
   * Function that will be invoked once per dataseries to have the
   * data necessary to display its information on the card.
   */
  getData: GetDataHandler

  timezone?: string

  /** Used to shift data one hour forward */
  isHourEndingSelected?: Boolean

  /** React component injected to the external options popover */
  moreActionsContent?: ReactNode

  /** Filters to be added to the individual card chips */
  dataSeriesFilters: Filter[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataSeries: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  dragging: {
    borderColor: (props: any) =>
      props.canDrop ? theme.palette.success.light : theme.palette.error.light,
    boxShadow: (props: any) =>
      `inset 0px 0px 2px 0px ${
        props.canDrop ? theme.palette.success.light : theme.palette.error.light
      }`,
  },
  container: {
    padding: theme.spacing(2),
  },
  rigid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  defaultTitle: {
    '&:not(.Mui-focused)': {
      color: theme.palette.text.secondary,
    },
  },
  flexible: {
    flex: '1 1 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerActions: {
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    overflow: 'hidden',
  },
  functionField: {
    width: '100px',
  },
  visualizationContainer: {
    overflow: 'hidden',
    position: 'relative',
    '& > *': {
      height: '100%',
      overflowY: 'auto',
    },
  },
  iconSelected: {
    fill: theme.palette.primary.light,
  },
  iconNotSelected: {
    fill: theme.palette.text.secondary,
  },
}))

/**
 * Component that given appropriate data will allow users to visualize data in different forms. It allows users to:
 *
 * - drag and drop data
 * - select different types of charts / tables
 * - group and aggregate the data by different metrics
 * - name the card
 * - delete the card
 * - download card's data
 *
 * It is meant to be used mainly in user defined dashboards where the user can add, remove, resize, and move multiple `<DashboardCard />`
 *
 * __NOTE:__This is a controlled component (as can be observed by the large number of required `Props`). This means that anyone using it must control most state of the card. For example, a user must determine what to do when a valid draggable element is dropped on the component, or a user must control a title change on input changes.
 *
 */
const Card = (props: Props) => {
  const {
    onDrop,
    canDrop,
    definition,
    defaultTitle,
    dragIconClassName,
    getData,
    groupOptions,
    onDownload,
    className,
    onDefinitionChange,
    timezone,
    isHourEndingSelected,
    dataSeriesFilters,
    moreActionsContent,
  } = props
  const {
    isSummaryView,
    title,
    dataSeries,
    visualization: selectedVisualization,
    group: selectedGroup,
    typeUnits: selectedTypeUnits = {} as CardTypeUnits,
    legendPosition = LegendPosition.Right,
    meterTableOptions,
  } = definition
  const classes = useStyles(props)

  const textFieldProps = useNonEmptyTextField(
    title,
    defaultTitle,
    (event, newTitle) =>
      onDefinitionChange(
        event,
        {
          ...definition,
          title: newTitle,
        },
        definition,
      ),
  )

  const {
    errors,
    isLoading,
    isUsingDateIdentifiers,
    getTraces,
    getTableData,
    getPivotTableData,
    getSummaryData,
    getMeterTableData,
  } = processCardDataSeries(definition, getData, timezone, isHourEndingSelected)

  const isTableVisualization = selectedVisualization === Visualization.Table
  const isSummaryVisulization = selectedVisualization === Visualization.Summary
  const isMeterVisualization =
    selectedVisualization === Visualization.MeterTable
  const pivotTableData = getPivotTableData()
  const summaryData = getSummaryData()

  const {
    columns: meterTableCol,
    rows: meterTableRow,
    rowCount,
  } = getMeterTableData()

  const handleDownload = (e) => {
    if (!isLoading) {
      const downloadData = getTableData()
      onDownload(e, title, downloadData)
    }
  }

  return (
    <DashedCard elevation={0} className={clsx(className, classes.root)}>
      <header
        className={clsx(classes.header, classes.rigid, classes.container)}
      >
        {Boolean(dragIconClassName) && (
          <DraggableIcon className={dragIconClassName}>
            <SvgIcon fontSize="small">
              <Move />
            </SvgIcon>
          </DraggableIcon>
        )}
        <TransparentTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textFieldProps}
          className={clsx(
            classes.rigid,
            title === defaultTitle && classes.defaultTitle,
          )}
          inputProps={{
            maxLength: 25,
          }}
        />
        <ToolbarWithMoreActions
          className={clsx(classes.headerActions, classes.flexible)}
          errorContent={
            errors.length ? (
              <List>
                {errors.map(({ dataSeriesElement, error }, index) => (
                  /* It shouldn't be a problem to use the index
                  as key on these elements. */
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={index}>
                    <ListItemText
                      primary={createCardDataSeriesLabel(
                        dataSeriesElement.label,
                        dataSeriesElement.selectedCalculation,
                      )}
                      secondary={error.toString()}
                    />
                  </ListItem>
                ))}
              </List>
            ) : undefined
          }
          onDownload={handleDownload}
          moreActionsContent={moreActionsContent}
        >
          {/* TODO: Move this to the actual component since the <ToolbarWithMoreActions /> component is really only used here. */}
          {!isSummaryView &&
            visualizationButtons
              .filter((icons) => {
                if (meterTableOptions) {
                  return true
                }
                return icons[0] !== Visualization.MeterTable
              })
              .map(([visualization, buttonTitle, Icon]) => (
                <IconButton
                  key={`visualization_${visualization}`}
                  title={buttonTitle}
                  size="small"
                  onClick={
                    selectedVisualization !== visualization
                      ? (e) =>
                          onDefinitionChange(
                            e,
                            {
                              ...definition,
                              visualization,
                            },
                            definition,
                          )
                      : undefined
                  }
                >
                  <Icon
                    className={
                      selectedVisualization === visualization
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </IconButton>
              ))}
        </ToolbarWithMoreActions>
      </header>
      <main
        className={clsx(classes.content, classes.container, classes.flexible)}
      >
        {!isSummaryView && (
          <section className={classes.rigid}>
            {/* TODO: This should move elsewhere and be dependent fully on selectedVisualization */}
            {(selectedVisualization === Visualization.MultiAxis
              ? dropzones
              : [dropzones[0]]
            ).map(({ id, label }) => {
              return (
                <DataSeriesChipsInput
                  key={id}
                  axisId={id}
                  label={isMeterVisualization ? 'COLUMNS' : label}
                  dataSeriesFilters={dataSeriesFilters || ([] as Filter[])}
                  dataSeries={dataSeries.filter(
                    (e) =>
                      e.axisId === id || (!e.axisId && id === AxisId.Primary),
                  )}
                  onDataSeriesDelete={(e, deletedSeries) =>
                    onDefinitionChange(
                      e,
                      {
                        ...definition,
                        dataSeries: definition.dataSeries.filter((series) => {
                          if (!isMeterVisualization)
                            return series !== deletedSeries
                          return deletedSeries.id === `Unique-${selectedGroup}`
                            ? true
                            : series !== deletedSeries
                        }),
                      },
                      definition,
                    )
                  }
                  onDataSeriesChange={(e, modifiedSeries, originalSeries) =>
                    onDefinitionChange(
                      e,
                      {
                        ...definition,
                        dataSeries: definition.dataSeries.map((series) =>
                          series !== originalSeries ? series : modifiedSeries,
                        ),
                      },
                      definition,
                    )
                  }
                  onDrop={onDrop}
                  canDrop={(e, type) => {
                    const inputDataSeries = dataSeries.filter(
                      (series) =>
                        series.axisId === id ||
                        (!series.axisId && id === AxisId.Primary),
                    )
                    if (!isMeterVisualization) {
                      if (
                        type &&
                        (inputDataSeries.length === 0 ||
                          inputDataSeries[0].type === type)
                      ) {
                        return canDrop(e, type)
                      }
                      return false
                    }
                    return canDrop(e, type)
                  }}
                  selectedTypeUnits={selectedTypeUnits[id]}
                  onSelectedTypeUnitsChange={(event, selectedUnit) => {
                    onDefinitionChange(
                      event,
                      {
                        ...definition,
                        typeUnits: { ...selectedTypeUnits, [id]: selectedUnit },
                      },
                      definition,
                    )
                  }}
                  meterVisualizationSelected={isMeterVisualization}
                  showTableDirectionSelector={
                    isTableVisualization && !isMeterVisualization
                  }
                  tableDirection={definition.tableSeriesDirection || 'column'}
                  onTableDirectionChange={(e, newDirection) => {
                    onDefinitionChange(
                      e,
                      { ...definition, tableSeriesDirection: newDirection },
                      definition,
                    )
                  }}
                  selectedYAxisStartAtDataRange={
                    definition?.shouldYAxisStartAtDataRange?.[id]
                  }
                  onSelectedYaxisRangeChange={(
                    event,
                    selectedYaxisRange,
                    axis,
                  ) => {
                    onDefinitionChange(
                      event,
                      {
                        ...definition,
                        shouldYAxisStartAtDataRange: {
                          ...definition.shouldYAxisStartAtDataRange,
                          [axis]: selectedYaxisRange.value === 'normal',
                        },
                      },
                      definition,
                    )
                  }}
                  group={selectedGroup}
                />
              )
            })}
            {!isSummaryView &&
              !isTableVisualization &&
              !isSummaryVisulization &&
              !isMeterVisualization && (
                <FormControl className={classes.functionField}>
                  <InputLabel shrink htmlFor="groupBy">
                    {`${
                      selectedVisualization !== Visualization.Stack
                        ? 'Group'
                        : 'Stack'
                    } By`}
                  </InputLabel>
                  <Select
                    value={selectedGroup ?? NO_GROUP_VALUE}
                    name="groupBy"
                    onChange={(e) => {
                      onDefinitionChange(
                        e as any,
                        {
                          ...definition,
                          group:
                            e.target.value === NO_GROUP_VALUE
                              ? null
                              : (e.target.value as Group),
                        },
                        definition,
                      )
                    }}
                  >
                    <MenuItem value={NO_GROUP_VALUE}>Overall</MenuItem>
                    {groupOptions.map(([group, label]) =>
                      group !== Group.DayType ? (
                        <MenuItem key={group} value={group}>
                          {label}
                        </MenuItem>
                      ) : undefined,
                    )}
                  </Select>
                </FormControl>
              )}
          </section>
        )}
        {!isSummaryView &&
          isTableVisualization &&
          ((tableGroups) => {
            const inputs: Array<Array<ReactElement>> = [[], []]
            const [rows, columns] = inputs
            tableGroups.forEach(({ direction, header, groupType }) => {
              const element = (
                <Chip
                  key={groupType}
                  label={header}
                  onDelete={(e) => {
                    onDefinitionChange(
                      e,
                      {
                        ...definition,
                        tableGroups: (definition.tableGroups || []).filter(
                          (tableGroup) => tableGroup.groupType !== groupType,
                        ),
                      },
                      definition,
                    )
                  }}
                />
              )
              if (direction === 'row') rows.push(element)
              else columns.push(element)
            })
            return (
              <section className={classes.rigid}>
                {inputs.map((chips) => {
                  const isRows = chips === rows
                  const label = isRows ? 'Rows' : 'Columns'
                  return (
                    <ChipsInput
                      key={label}
                      label={label}
                      canDrop={(e) =>
                        getDraggedTableGroupsTypes(e.dataTransfer).length > 0
                      }
                      onDrop={(e) => {
                        const newTableGroups = getDroppedTableGroups(
                          e.dataTransfer,
                        )
                        onDefinitionChange(
                          e,
                          {
                            ...definition,
                            tableGroups: (definition.tableGroups || [])
                              .filter(
                                (currentTableGroup) =>
                                  !newTableGroups.some(
                                    (newTableGroup) =>
                                      currentTableGroup.groupType ===
                                      newTableGroup.groupType,
                                  ),
                              )
                              .concat(
                                newTableGroups.map((newTableGroup) => ({
                                  ...newTableGroup,
                                  direction: isRows ? 'row' : 'column',
                                })),
                              ),
                          },
                          definition,
                        )
                      }}
                    >
                      {chips}
                    </ChipsInput>
                  )
                })}
              </section>
            )
          })(definition.tableGroups || [])}
        <section
          className={clsx(classes.flexible, classes.visualizationContainer)}
        >
          {dataSeries.length <= 0 ? (
            <div>
              <StyledNoDataImage src={LOADING_IMAGE} alt="No data available" />
              <Typography variant="subtitle1" align="center" display="block">
                Add a time series to start monitoring your metrics
              </Typography>
            </div>
          ) : (
            <>
              {isLoading && <StyledSpinner />}
              {isTableVisualization && (
                <PivotTable
                  headers={pivotTableData.headers}
                  items={pivotTableData.items}
                />
              )}
              {isSummaryVisulization && (
                <SummaryVisualization
                  seriesData={summaryData}
                  unit={getUnitsLabelForDataSeries(
                    dataSeries,
                    AxisId.Primary,
                    selectedTypeUnits,
                    true,
                  )}
                />
              )}
              {isMeterVisualization && meterTableOptions && (
                <MeterTable
                  rows={meterTableRow}
                  columns={meterTableCol}
                  pageNumber={meterTableOptions?.pageNumber}
                  pageSize={meterTableOptions?.pageSize}
                  rowCount={rowCount}
                  onPageChange={(val: GridPageChangeParams) => {
                    onDefinitionChange(
                      {} as SyntheticEvent,
                      {
                        ...definition,
                        meterTableOptions: {
                          ...meterTableOptions,
                          pageNumber: val.page,
                        },
                      },
                      definition,
                    )
                  }}
                  onPageSizeChange={(params: GridPageChangeParams) => {
                    onDefinitionChange(
                      {} as SyntheticEvent,
                      {
                        ...definition,
                        meterTableOptions: {
                          ...meterTableOptions,
                          pageSize: params.pageSize,
                          pageNumber: params.page,
                        },
                      },
                      definition,
                    )
                  }}
                />
              )}
              {!isTableVisualization &&
                !isSummaryVisulization &&
                !isMeterVisualization && (
                  <Plot
                    data={getTraces()}
                    config={{
                      modeBarButtonsToRemove: ['toImage'],
                      displaylogo: false,
                      responsive: true,
                    }}
                    layout={{
                      title: '',
                      ...buildPlotLegendFields(legendPosition),
                      font: {
                        family: 'Roboto',
                        size: '12px',
                      },
                      xaxis: {
                        type: isUsingDateIdentifiers ? 'date' : undefined,
                        showgrid: true,
                        showline: true,
                        mirror: true,
                        zeroline: false,
                        linecolor: '#E9EBF1',
                        rangemode: isUsingDateIdentifiers
                          ? 'tozero'
                          : undefined,
                        automargin: true,
                      },
                      yaxis: {
                        ...commonYAxisConfig,
                        title: {
                          text: getUnitsLabelForDataSeries(
                            dataSeries,
                            AxisId.Primary,
                            selectedTypeUnits,
                          ),
                        },
                        rangemode: definition?.shouldYAxisStartAtDataRange
                          ?.primary_axis
                          ? 'normal'
                          : 'tozero',
                      },
                      yaxis2: {
                        ...commonYAxisConfig,
                        overlaying: 'y',
                        side: 'right',
                        title: {
                          text: getUnitsLabelForDataSeries(
                            dataSeries,
                            AxisId.Secondary,
                            selectedTypeUnits,
                          ),
                        },
                        rangemode: definition?.shouldYAxisStartAtDataRange
                          ?.secondary_axis
                          ? 'normal'
                          : 'tozero',
                      },
                      autosize: true,
                      margin: {
                        l: 24,
                        r: 24,
                        t: 24,
                        b: 24,
                      },
                      colorway,
                      barmode:
                        selectedVisualization === Visualization.Stack
                          ? 'stack'
                          : undefined,
                    }}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                  />
                )}
            </>
          )}
        </section>
      </main>
    </DashedCard>
  )
}

export default Card
