import React, { useState, DragEvent, ReactNode } from 'react'
import useDropzone from 'shared/hooks/useDropzone'
import {
  Chip,
  fade,
  IconButton,
  makeStyles,
  Popover,
  styled,
  withStyles,
} from '@material-ui/core'
import { ChevronUp, ChevronDown } from 'react-feather'
import clsx from 'clsx'
import useClickedElement from 'shared/hooks/useClickedElement'

/**
 * Styled Chip meant to be used inside the input component.
 */
const CardChip = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0.8),
    borderRadius: '4px',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    backgroundColor: fade(theme.palette.primary.main, 0.09),
  },
  deleteIconColorPrimary: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: fade(theme.palette.primary.main, 0.7),
    },
  },
  clickableColorPrimary: {
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
  },
}))(((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Chip color="primary" size="small" {...props} />
)) as typeof Chip)

export { CardChip as Chip }

const TransparentChip = styled(CardChip)(() => ({
  backgroundColor: 'transparent',
}))

export interface Props {
  /**
   * Label to be displayed over the chips input element, if "renderMenuContent" is provided, a clickable
   * elemente will be rendered at its side,
   */
  label: string
  /** Function that will be invoked when an element is dragged over the input, allows the user to specify if it can be dropped based on the information contained on the event */
  canDrop: (e: DragEvent) => boolean
  /** Function triggered when a valid draggable element (it means, it returned "true"
   * on "canDrop")  is dropped on card zone */
  onDrop: (e: DragEvent) => void
  /**
   * Optional function that will be invoked to render the content
   * of the popover menu that will appear after clicking on the chevron besides
   * the header label.
   *
   * If not defined, the chevron button won't be rendered and no popover menu will
   * show by any means.
   */
  renderMenuContent?: (closeMenu: () => void) => ReactNode
  /**
   * Optional extra content to be displayed on the right side of the input, besides the "expand" chevron
   */
  extraInputOptions?: ReactNode
  children: ReactNode
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflow: 'hidden',
    '&:not(:last-child)': {
      marginRight: theme.spacing(4),
    },
  },
  chipsInputWrapper: {
    border: '1px dashed',
    borderColor: theme.palette.divider,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto',
    overflow: 'hidden',
    borderRadius: '4px',
    background: theme.palette.common.white,
  },
  chipsInput: {
    display: 'flex',
    borderColor: theme.palette.divider,
    flexWrap: (props: any) => (props.isOpen ? 'wrap' : 'nowrap'),
    alignItems: 'center',
    overflow: 'hidden',
  },
  dragging: {
    borderColor: (props: any) =>
      props.allowDrop ? theme.palette.success.light : theme.palette.error.light,
    boxShadow: (props: any) =>
      `inset 0px 0px 2px 0px ${
        props.allowDrop
          ? theme.palette.success.light
          : theme.palette.error.light
      }`,
  },
}))

const ChipsInput = ({
  label,
  onDrop,
  canDrop,
  renderMenuContent,
  extraInputOptions,
  children,
}: Props) => {
  const [menuAnchorEl, handleOpenMenu, closeMenu] = useClickedElement()
  const [isOpen, setIsOpen] = useState(false)
  const { isBeingDraggedOver, allowDrop, ...dragAndDropHandlers } = useDropzone(
    true,
    canDrop,
    onDrop,
  )
  const classes = useStyles({ allowDrop, isOpen })
  return (
    <div
      className={clsx(classes.root)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dragAndDropHandlers}
    >
      <header className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-shrink">
        {label}
        {renderMenuContent && (
          <>
            <IconButton size="small" title="Select " onClick={handleOpenMenu}>
              {menuAnchorEl ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
            <Popover
              open={Boolean(menuAnchorEl)}
              anchorEl={menuAnchorEl}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {renderMenuContent(closeMenu)}
            </Popover>
          </>
        )}
      </header>
      <div
        className={clsx(
          classes.chipsInputWrapper,
          isBeingDraggedOver && classes.dragging,
        )}
      >
        <div data-testid="chipsList" className={classes.chipsInput}>
          {React.Children.count(children) > 0 ? (
            children
          ) : (
            // Used to force proper initial height
            <TransparentChip color="secondary" size="small" />
          )}
        </div>
        {extraInputOptions}
        <IconButton
          size="small"
          title="expand"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
      </div>
    </div>
  )
}

export default ChipsInput
