import { ProductModuleConfig } from 'shared/types'
import SmartEngagement, { SmartEngagementMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: SmartEngagementMenu,
  MainContent: SmartEngagement,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
