import { Tabs, Tab, styled, withStyles } from '@material-ui/core'

export const StyledTabGroup = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e4e1e1',
  },
  indicator: {
    borderBottom: `solid ${theme.palette.primary.main} 2px`,
  },
}))(Tabs) as typeof Tabs

export const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.body1.fontSize,
    textDecoration: 'none',
    textTransform: 'none',
    padding: '1em',
  },
  selected: {
    color: `${theme.palette.common.black}!important`,
  },
}))(Tab) as typeof Tab

export const PageLayout = styled('div')(({ theme }) => ({
  minHeight: '90vh',
  display: 'grid',
  gridTemplateRows: 'auto 60px 1fr',
  gridTemplateColumns: '15% auto 15%',
  gridTemplateAreas: `
  "left-menu header right-menu"
  "left-menu options right-menu"
  "left-menu body right-menu"`,
  gap: theme.spacing(3, 3),
  padding: theme.spacing(2),
}))

export const Header = styled('div')(({ theme }) => ({
  gridArea: 'header',
  display: 'flex',
  marginTop: theme.spacing(4),
}))

export const Options = styled('div')(() => ({
  gridArea: 'options',
}))

export const PageTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(2),
  },
}))

export const Body = styled('div')(({ theme }) => ({
  gridArea: 'body',
  display: 'flex',
  flexWrap: 'wrap',
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
