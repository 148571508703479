import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as GridEdgeManagementIcon } from 'icons/grid-edge-management-submodule.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'

const gridEdgeManagementOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: (<div> Grid Edge Mangement Content In Development </div>) as any,
  },
]

export const GridEdgeManagementMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Grid Edge Management"
      color="demand"
      basePath={url}
      icon={<GridEdgeManagementIcon />}
      routes={gridEdgeManagementOptions}
    />
  )
}

const GridEdgeManagement = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${gridEdgeManagementOptions[0].path}`} />
      </Route>
      {gridEdgeManagementOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default GridEdgeManagement
