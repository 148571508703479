import React, {
  ChangeEvent,
  ReactEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  makeStyles,
  Theme,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  Button,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  styled,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox as MaterialCheckbox,
  ListItemIcon,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core'
import FlexSearch from 'flexsearch'
import Autosizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { Search, X as Clear } from 'react-feather'
import withStyles, { CSSProperties } from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import ColoredAvatar from './ColoredAvatar'

const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.grey[400],
}))

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: 570,
  },
  header: {
    display: 'flex',
    padding: theme.spacing(3, 4),
    '& > :first-child': {
      marginRight: 'auto',
    },
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(
      2.5,
      4,
    ) /* Weird spacing value (2.5) based on the design prvided on 
    https://projects.invisionapp.com/d/main#/console/20425095/428743172/preview 
    (vertical padding on the footer breaks the usual spacing consistency ) */,
    '& > :first-child': {
      marginRight: 'auto',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
    "tabs"
    "search" 
    "extraoptions"
    "userlist"
    `,
    gridGap: theme.spacing(2),
    padding: theme.spacing(3, 4),
  },
  search: {
    gridArea: 'search',
  },
  extraoptions: {
    gridArea: 'extraoptions',
  },
  userlist: {
    gridArea: 'userlist',
  },
  tabs: {
    gridArea: 'tabs',
  },
}))

const useUserStyles = makeStyles(() => ({
  root: {
    minHeight: (props: Pick<UserCheckboxGroupProps, 'minHeight'>) =>
      props.minHeight,
  },
}))
interface MetaData {
  isChecked: boolean
  id: string
  index: number
}

export interface User {
  id: string
  name: string
  email?: string
  isChecked: boolean
}

export interface Group {
  id: string
  name: string
  isChecked: boolean
}

interface CheckboxProps {
  item: User
  onCheckboxChange: (e: ChangeEvent<any>, metadata: Array<MetaData>) => void
  index: number
}

enum TabOptions {
  Users,
  Organization,
}

const Checkbox = ({ item, onCheckboxChange, index }: CheckboxProps) => {
  const twoLetters = (string: string) => string.substring(0, 2).toLowerCase()

  return (
    <ListItem>
      <ListItemIcon>
        <MaterialCheckbox
          color="primary"
          size="small"
          checked={item.isChecked}
          onChange={(e, isChecked) =>
            onCheckboxChange(e, [{ id: item.id, isChecked, index }])
          }
        />
      </ListItemIcon>
      <ListItemAvatar>
        <ColoredAvatar>{twoLetters(item.name)}</ColoredAvatar>
      </ListItemAvatar>
      <ListItemText primary={item.name} secondary={item.email} />
    </ListItem>
  )
}

interface VirtualCheckboxProps {
  index: number
  style: CSSProperties
  data: {
    items: Array<User>
    onCheckboxChange: (e: ChangeEvent<any>, metadata: Array<MetaData>) => void
  }
}

const VirtualCheckbox = ({ index, style, data }: VirtualCheckboxProps) => {
  const { items, onCheckboxChange } = data
  return (
    <div style={style}>
      <Checkbox
        item={items[index]}
        index={index}
        onCheckboxChange={onCheckboxChange}
      />
    </div>
  )
}

interface UserCheckboxGroupProps {
  items: Array<User>
  onCheckboxChange: (e: ChangeEvent<any>, metadata: Array<MetaData>) => void
  minHeight: string
  itemSize: number
}

const UserCheckboxGroup = ({
  items,
  onCheckboxChange,
  minHeight,
  itemSize,
}: UserCheckboxGroupProps) => {
  const classes = useUserStyles({ minHeight })
  const dialogClass = useStyles()

  return (
    <div className={clsx(classes.root, dialogClass.userlist)}>
      <Autosizer>
        {(autoSizer) => (
          <FixedSizeList
            height={autoSizer.height}
            width={autoSizer.width}
            itemData={{
              items,
              onCheckboxChange,
            }}
            itemKey={(index, data) => data.items[index].id}
            itemSize={itemSize}
            itemCount={items.length}
          >
            {VirtualCheckbox}
          </FixedSizeList>
        )}
      </Autosizer>
    </div>
  )
}

const ExtraSmallButton = styled(Button)(() => ({
  fontSize: '.6rem',
  float: 'right',
}))

const SelectAllClearAll = ({
  tab,
  items,
  onCheckboxChange,
}: {
  tab: number
  items: Array<User>
  onCheckboxChange: (e: ChangeEvent<any>, metadata: Array<MetaData>) => void
}) => {
  const classes = useStyles()

  function handleMultiselect(e, isChecked) {
    const metadata = items.map((f, index) => ({ ...f, isChecked, index }))
    onCheckboxChange(e, metadata)
  }

  return (
    <div className={classes.extraoptions}>
      <Typography variant="h6" component="span">
        {tab === TabOptions.Users ? 'Team Members' : 'Organization'}{' '}
      </Typography>
      <Typography variant="caption" component="span">
        {items.length} {tab === TabOptions.Users ? 'Users' : 'orgs'}
      </Typography>
      <ExtraSmallButton
        onClick={(e) => handleMultiselect(e, true)}
        color="primary"
        size="small"
      >
        Select all
      </ExtraSmallButton>
      <ExtraSmallButton
        onClick={(e) => handleMultiselect(e, false)}
        color="primary"
        size="small"
      >
        Clear
      </ExtraSmallButton>
    </div>
  )
}

const StyledTabGroup = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e4e1e1',
  },
  indicator: {
    borderBottom: `solid ${theme.palette.primary.main} 5px`,
  },
}))(Tabs) as typeof Tabs

export interface Props {
  sharedUsers: Array<string>
  sharedGroups: Array<string>
  allUsers: any
  groups: Array<{ id: string; name: string }>
  onUserShare: (metadata: Array<User>) => void
  onGroupShare: (metadata: Array<Group>) => void
  onClose: ReactEventHandler
  isAwaitingConfirmation?: boolean
}

const ShareDashboardDialog = ({
  allUsers,
  sharedUsers,
  sharedGroups,
  groups,
  onClose,
  onUserShare,
  onGroupShare,
  isAwaitingConfirmation = false,
}: Props) => {
  const classes = useStyles()

  const [usersOption, setUserOption] = useState<User[]>(() => {
    const sharedUserSet = new Set(sharedUsers)
    return allUsers.map((user) => {
      let isChecked = false
      if (sharedUserSet.has(user.id)) {
        isChecked = true
      }
      return { ...user, isChecked }
    })
  })

  const [groupOption, setGroupOptions] = useState(() => {
    const sharedGroupsSet = new Set(sharedGroups)
    return groups.map((group) => {
      let isChecked = false
      if (sharedGroupsSet.has(group.id)) {
        isChecked = true
      }
      return { ...group, isChecked }
    })
  })

  const [tab, setTab] = useState(TabOptions.Users)

  // Whenever we mount let's make sure we default to the organization tab if and only if we have some groups selected and no users selected
  useEffect(() => {
    const someGroupsSelected = groupOption.some((group) => group.isChecked)
    const someUsersOptionSelected = usersOption.some((user) => user.isChecked)
    if (someGroupsSelected && !someUsersOptionSelected) {
      setTab(TabOptions.Organization)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }
  const [searchValue, setSearchValue] = useState('')

  const [isFirstClick, setIsFirstClick] = useState(false)

  const searchIndex = useMemo(() => {
    if (tab === TabOptions.Users) {
      return FlexSearch.create({
        doc: {
          id: 'id',
          field: ['name', 'email'],
        },
      }).add(usersOption)
    }
    return FlexSearch.create({
      doc: {
        id: 'id',
        field: ['name'],
      },
    }).add(groupOption)
  }, [usersOption, groupOption, tab])

  function handleSearchChange(e) {
    const { value } = e.target
    if (!isFirstClick) {
      setIsFirstClick(true)
    }
    setSearchValue(value)
  }

  const itemsInView = useMemo(() => {
    const options = tab === TabOptions.Users ? usersOption : groupOption
    return searchValue ? searchIndex.search(searchValue) : options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, usersOption, tab, groupOption])

  function handleCheckboxChange(e, metadata) {
    if (tab === TabOptions.Users) {
      const selectedSet = new Map<string, User>(
        metadata.map((selectedUser) => [selectedUser.id, selectedUser]),
      )
      setUserOption((prevUsersOption) => {
        return prevUsersOption.map((user) => {
          if (selectedSet.has(user.id)) {
            return {
              ...user,
              isChecked: selectedSet.get(user.id)?.isChecked || false,
            }
          }
          return user
        })
      })
    } else {
      const selectedSet = new Map<string, Group>(
        metadata.map((selectedGroup) => [selectedGroup.id, selectedGroup]),
      )
      setGroupOptions((prevGroupOption) => {
        return prevGroupOption.map((group) => {
          if (selectedSet.has(group.id)) {
            return {
              ...group,
              isChecked: selectedSet.get(group.id)?.isChecked || false,
            }
          }
          return group
        })
      })
    }
  }

  return (
    <Dialog
      open
      onClose={onClose}
      className={classes.dialog}
      maxWidth="md"
      classes={{ paperWidthMd: classes.dialog }}
    >
      <header className={classes.header}>
        <Typography variant="h6">Share Dashboard With</Typography>
        <IconButton size="small" title="Cancel" onClick={onClose}>
          <Clear />
        </IconButton>
      </header>
      <DialogContent dividers className={classes.content}>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          className="tabs"
        >
          <StyledTabGroup
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
          >
            <Tab label="Users" />
            <Tab label="Organization" />
          </StyledTabGroup>
        </AppBar>
        <OutlinedInput
          placeholder="Type to search"
          margin="dense"
          className={classes.search}
          value={searchValue}
          onChange={handleSearchChange}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <StyledSearchIcon />
            </InputAdornment>
          }
        />
        <SelectAllClearAll
          tab={tab}
          items={itemsInView}
          onCheckboxChange={handleCheckboxChange}
        />
        <UserCheckboxGroup
          items={itemsInView}
          onCheckboxChange={handleCheckboxChange}
          minHeight="400px"
          itemSize={70}
        />
      </DialogContent>
      <footer className={classes.footer}>
        <Button
          onClick={onClose}
          variant="contained"
          disabled={isAwaitingConfirmation}
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            tab === TabOptions.Users
              ? onUserShare(usersOption)
              : onGroupShare(groupOption)
          }
          variant="contained"
          color="primary"
        >
          {!isAwaitingConfirmation ? 'Share' : <CircularProgress size="1em" />}
        </Button>
      </footer>
    </Dialog>
  )
}

export default ShareDashboardDialog
