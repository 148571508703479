import SideBarMenu from 'shared/components/SideBarMenu'
import React from 'react'
import { IconButton, styled, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DateTime } from 'luxon'
import {
  Body,
  LeftMenu,
  LeftSideOptions,
  Options,
  RightMenu,
  StyledSaveIcon,
} from './DashboardLayout'
import { DashedCard, StyledNoDataImage } from './DashboardCard/Card'

const { PUBLIC_URL } = process.env
const loadingImage = `${PUBLIC_URL}/icons/emptystate.svg`

const SpacedDiv = styled('div')(({ theme }) => ({
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}))

const TallCard = styled(DashedCard)(() => ({
  height: '630px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
}))

const FlexSpan = styled('span')(() => ({
  display: 'flex',
}))

const ThreeTextWithCircle = () => (
  <SpacedDiv>
    <FlexSpan>
      <Skeleton variant="text" width="90%" />
      <Skeleton variant="circle" width={20} height={20} />
    </FlexSpan>
    <FlexSpan>
      <Skeleton variant="text" width="90%" />
      <Skeleton variant="circle" width={20} height={20} />
    </FlexSpan>
    <FlexSpan>
      <Skeleton variant="text" width="90%" />
      <Skeleton variant="circle" width={20} height={20} />
    </FlexSpan>
  </SpacedDiv>
)

/** Super simple skeleton component that represents how most of the Innowatts dashboards look. */
const SkeletonDashboard = () => {
  return (
    <>
      <LeftMenu>
        <SideBarMenu title="time series">
          <SpacedDiv>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
          </SpacedDiv>
        </SideBarMenu>
        <SideBarMenu title="metrics">
          <ThreeTextWithCircle />
        </SideBarMenu>
      </LeftMenu>
      <Options>
        <LeftSideOptions>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="LL/dd/yyyy"
            margin="normal"
            label="Start Date"
            value={DateTime.local()}
            disabled
            onChange={() => {}}
          />
        </LeftSideOptions>
        <IconButton onClick={() => {}} disabled>
          <StyledSaveIcon disabled />
        </IconButton>
      </Options>
      <Body>
        <TallCard>
          <StyledNoDataImage src={loadingImage} alt="No data available" />
          <Typography variant="subtitle1" align="center" display="block">
            We are building something awesome for you
          </Typography>
        </TallCard>
      </Body>
      <RightMenu>
        <SideBarMenu title="Dimension filters">
          <ThreeTextWithCircle />
        </SideBarMenu>
      </RightMenu>
    </>
  )
}

export default SkeletonDashboard
