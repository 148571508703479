import { useEffect, useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'

/**
 * A hook to allow request cancellation to ensure that pending requests
 * do not resolve when unmounting the component.
 *
 * This is accomplished by generating a cancel token which is passed
 * to the axios request and contains a function.
 * When the component unmounts, this function is called and cancels every
 * request with this token which is every request created by the parent
 * component which was passed createCancellableRequestConfig as config
 */
const useCancellableRequests = () => {
  const [source] = useState(axios.CancelToken.source())

  /**
   * Merges any provided request config options with a cancel token and returns
   * an AxiosRequestConfig object to be used in an axios request originating from
   * the parent component
   * @param requestConfig optional object containing request config
   */
  const createCancellableRequestConfig = (
    requestConfig?,
  ): AxiosRequestConfig => {
    return {
      ...(requestConfig || {}),
      cancelToken: source.token,
    }
  }

  /**
   * On component unmount, call the source cancel to cancel any pending requests
   * which have this token associated with their request config
   */
  useEffect(() => {
    return () => {
      try {
        // Pass an empty argument (undefined) here to prevent an alert showing the error in the axios interceptor
        source.cancel()
      } catch (e) {
        // Ignore any potential errors here
      }
    }
  }, [source])

  return createCancellableRequestConfig
}

export default useCancellableRequests
