import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as RiskManagementIcon } from 'icons/risk-management-submodule.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'
import RiskDashboards from './RiskDashboards'

const riskMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: RiskDashboards,
  },
]

export const RiskMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Risk Management"
      color="supply"
      icon={<RiskManagementIcon />}
      basePath={url}
      routes={riskMenuOptions}
    />
  )
}

const Risk = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${riskMenuOptions[0].path}`} />
      </Route>
      {riskMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default Risk
