import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from 'react-router'
import Login from 'base/pages/Login'
import UserContext from 'shared/contexts/UserContext'
import axiosApiClient, {
  getNotifications,
  clearNotifications,
  getDashboardsConfig,
} from 'shared/apiClient'
import SocketContext from 'shared/contexts/SocketContext'
import {
  NotificationType,
  NotificationKindType,
  ApplicationProduct,
} from 'shared/types'
import BaseLayout from 'base/pages/BaseLayout'
import useAuthenticationFlow from 'base/hooks/useAuthenticationFlow'
import DashboardsConfigsContext, {
  DashboardsConfigsContextType,
} from 'shared/contexts/DashboardsConfigsContext'
import AlertsContext from 'shared/contexts/AlertsContext'
import getProductModulesInfoForUser from './getProductModulesInfoForUser'

function getNotificationKind(code, status): NotificationKindType {
  if (code === 'JOB_CALC_START') {
    return 'neutral'
  }
  if (code === 'JOB_COMPLETE') {
    return status === 'success' ? 'positive' : status
  }
  return status
}

type Props = RouteComponentProps

const AxiosErrorHandler = () => {
  const publishAlert = useContext(AlertsContext)
  useEffect(() => {
    axiosApiClient.interceptors.response.use(undefined, (error) => {
      // We can set the error message to false here to silently error
      // This is used when cancelling requests on component destroy
      if (error?.message) {
        publishAlert(error.message, 'error')
      }
      return Promise.reject(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

/**
 * Main wrapper for the whole app
 */
export const Main = (props: Props) => {
  const { history } = props
  const {
    authenticationData: userAndSocketsState,
    login: onLogin,
    logout: onLogout,
  } = useAuthenticationFlow(history.push)

  const [notifications, setNotifications] = useState<NotificationType[]>([])
  const [
    dashboardsConfigsState,
    setDashboardsConfigsState,
  ] = useState<DashboardsConfigsContextType>({
    isLoading: true,
    configs: [],
  })

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (userAndSocketsState) {
      // FIXME: D-02832 - Re-add sockets when connections with backend work again
      const { userState } = userAndSocketsState

      getDashboardsConfig().then((receivedDashboardsConfig) => {
        setDashboardsConfigsState({
          isLoading: false,
          configs: receivedDashboardsConfig,
        })
      })

      const userProducts: Array<ApplicationProduct> = []

      // FIXME: This is pulling from .mods when it should probably be pulling from somewhere else like permissions or prods
      getProductModulesInfoForUser(userState).content.forEach(
        ({ products }) => {
          products.forEach((product) => userProducts.push(product))
        },
      )

      const extraContentGettersMap = new Map(
        userProducts.map(({ product, config, path }) => [
          product,
          (info) => config.getNotificationExtraContent(info, path),
        ]),
      )

      const handleNewNotification = (msg) => {
        const unformattedNotificatons = Array.isArray(msg) ? msg : [msg]
        const newNotifications = unformattedNotificatons.map((notification) => {
          const kind = getNotificationKind(
            notification.code,
            notification.meta.status,
          )

          const getExtraContent =
            extraContentGettersMap.get(notification.mod) || (() => null)

          return {
            kind,
            title: notification.title,
            description: notification.description,
            extraContent: getExtraContent(notification),
            date: notification.date,
          }
        })

        setNotifications((prevNotifications) => [
          ...newNotifications,
          ...prevNotifications,
        ])
      }

      // TODO: Check the kind of parameters the getNotifications(...) function will need
      // FIXME: HANDLE ERRORS!
      getNotifications().then((newNotifications) => {
        handleNewNotification(newNotifications)
      })

      // FIXME: D-02832 - Re-add sockets when connections with backend work again
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAndSocketsState])
  function handleClearNotifications() {
    setNotifications([])
    clearNotifications()
  }

  if (!userAndSocketsState) {
    return <Login onLogin={onLogin} />
  }

  return (
    <UserContext.Provider value={userAndSocketsState.userState}>
      <SocketContext.Provider value={userAndSocketsState.socketsState}>
        <DashboardsConfigsContext.Provider value={dashboardsConfigsState}>
          <AxiosErrorHandler />
          <BaseLayout
            onLogout={onLogout}
            notifications={notifications}
            onNotificationsClear={handleClearNotifications}
          />
        </DashboardsConfigsContext.Provider>
      </SocketContext.Provider>
    </UserContext.Provider>
  )
}

export default Main
