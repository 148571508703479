/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { makeStyles, createStyles, styled } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { Upload } from 'react-feather'
import processCsv, {
  CsvImportResult,
} from '../utils/csvFileValidationAndExtraction'

export interface Props {
  /**
   * Optional title for the button, in case the default one isn't
   * acceptable.
   *
   * Default: "Upload"
   */
  buttonTitle?: string
  /**
   * Callback that will be invoked whenever a file has been selected
   * and its contents fully processed.
   *
   * It will return an object containing all the processed rows data,
   * the possibe errors found and some useful metadata.
   */
  onImportComplete: (results: CsvImportResult) => void
  /**
   * Optional. Function to further validate a file before its contents
   * are processed. It will be called after a new file is selected, but
   * before it starts parsing its contents.
   *
   * If an explicit "true" value is returned, it will go on to parse the
   * file contents. In any other case, the parsing will be ignored and
   * "onImportComplete" will be called with no data and just an error with a
   * default 'Failed file validation' message or the string returned by
   * this function.
   */
  validateFile?: (file: File) => boolean | string
  /**
   * Optional. Max size accepted for the selected files in bytes.
   *
   * This check will be done even before the optional "validateFile" is executed.
   *
   * If the selected file has a size over this limit, the "onImportComplete" will
   * be invoked with no parsed data and a single error with the FileValidationErrors.LARGE_FILE
   * message, also, the "validateFile" step will be skipped on this case.
   *
   * Default: 3 MB
   */
  maxFileSize?: number
  /**
   * Columns to be expected to exist on the CSV file.
   *
   * If at least one column isn't found on the file, the process will be aborted and
   * "onImportComplete" will be invoked with no parsed data and a single error with the
   * FileValidationErrors.INCORRECT_HEADER message (and its fileRowIndex value === 0)
   *
   * It accepts files that include more columns than those specified.
   */
  fields: Array<string>
  /**
   * Optional. Function that, if provided, will be executed once per row of data on
   * the file (skipping the first line with the column's names). It provides a way to
   * both transform and/or validate the different data rows.
   *
   * If provided, it expects a function that will receive the current row being parsed as an object that
   * will have a name field for each of the columns of the CSV (which means that will at
   * least have those provided on the "fields" prop) and also an "addError" function so different
   * detected errors on the row can be reported to be accesible on the "onFileIUpload" result object.
   * It is ensured that all the values for every field on the provided "row" argument will strings.
   *
   * The returned value of this function will be the one returned on the appropriate index of the
   * "data" field on the "onImportComplete" result object. It also allows to "not" add this row data
   * to the result by returning an explicit "null" value (for example, if it isn't desired for those
   * rows that fail validation to be returned).
   *
   * Usage sample:
   *
   * <pre>
   * // ...
   * processRow={
   *  (row, addError) => {
   *    const errors = doSomeValidation(row)
   *    if (errors) {
   *      errors.forEach(error => addError(error))
   *      return null
   *    }
   *    return doSomeTransformation(row)
   *  }
   * }
   * onImportComplete={
   *  ({data, errors}) => {
   *    // All the items in "data" will have the format given after "doSomeTrandformation". It won't
   *    //  contain any rows that had validation errors
   *
   *    // Errors will contain an element per row that had errors, that will also contain an
   *    //  "errors" array with all the detected errors for the given row
   *
   *    // ...
   *  }   * }
   * // ...
   * </pre>
   */
  processRow?: (
    row: {
      [field: string]: string
    },
    addError: (error: string) => void,
  ) => { [field: string]: any } | null
  /**
   * Optional. Value to indicate that those lines that have the same exact values on some columns
   * on the file are not to be processed.
   *
   * It allows to pass an array with the fields which combined make a row "unique". An explicit
   * "true" value acts as a shorthand to use all the same values as the "fields" prop.
   *
   * In case an array is passed, only those values intersecting with the "fields" prop are to be used,
   * in case no common values are given, it will act as if an explicit "false" value was passed to the prop.
   *
   * It is ensured that "processRow" will only be invoked the first time an "unique" row is found, and
   * not with any duplicates found afterwards.
   *
   * Also, if this prop is provided and duplicates removed, the "meta" field on the "onImportComplete" result object
   * will contain a "duplicatesRemoved" with information about which rows on the file have been removed due to being
   * duplicated.
   *
   * Default: false
   */
  removeDuplicates?: boolean | Array<string>
  /**
   * Value to indicate if the button and input should be disabled
   */
  isDisabled?: boolean
}

export const useStyles = makeStyles(() =>
  createStyles({
    uploadInput: {
      display: 'none',
    },
  }),
)

const StyledUploadIcon = styled(Upload)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[100],
  borderRadius: '50%',
  padding: '10px',
  marginLeft: 'auto',
  cursor: 'pointer',
  width: 40,
  height: 40,
}))

/**
 * A button that will allow to parse CSV files data directly on the frontend so
 * its result can be used as deemed appropriate by the parent component.
 *
 * It provides a series of callbacks to add further control to the parsing process
 * so validation/filtering/transformation can be done on the CSV data and its
 * result audited on a value provided as an argument on the `onImportComplete` callback.
 */
const ImportCsvButton = (props: Props) => {
  const {
    fields,
    onImportComplete,
    buttonTitle = 'Import',
    isDisabled = false,
  } = props
  const [fileInputKey, setFileInputKey] = useState(0)
  const classes = useStyles()
  return (
    <div>
      <label>
        <input
          key={fileInputKey}
          accept="text/csv"
          type="file"
          className={classes.uploadInput}
          onChange={(event) => {
            const fileInput = event.target
            if (fields.length && fileInput.files?.length) {
              processCsv(fileInput.files[0], props).then((importOutput) => {
                onImportComplete(importOutput)
                // Changes the key of the file input so the selected file/s is reset
                setFileInputKey((prevValue) => (prevValue > 0 ? 0 : 1))
              })
            }
          }}
          disabled={isDisabled}
        />
        <IconButton
          component="span"
          color="secondary"
          title={buttonTitle}
          disabled={isDisabled}
        >
          <StyledUploadIcon />
        </IconButton>
      </label>
    </div>
  )
}

export default ImportCsvButton
