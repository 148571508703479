import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  styled,
} from '@material-ui/core'
import { YaxisRange, AxisId } from 'shared/types'

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'block',
}))

const YAXIS_RANGE_OPTIONS: Array<YaxisRange> = [
  {
    label: 'Force 0',
    value: 'tozero',
  },
  {
    label: 'Data Range',
    value: 'normal',
  },
]

interface Props {
  axisId: AxisId
  selectedYAxisStartAtDataRange: Boolean | undefined
  onRangeSelect: any
}

const YaxisRangeSelector = ({
  axisId,
  selectedYAxisStartAtDataRange,
  onRangeSelect,
}: Props) => {
  return (
    <StyledFormControl>
      <FormLabel component="legend">Axis Options</FormLabel>
      <RadioGroup
        row
        aria-label="AXIS OPTIONS"
        name="AXIS OPTIONS"
        value={
          selectedYAxisStartAtDataRange
            ? YAXIS_RANGE_OPTIONS[1].value
            : YAXIS_RANGE_OPTIONS[0].value
        }
      >
        {YAXIS_RANGE_OPTIONS.map((range) => (
          <FormControlLabel
            key={range.value}
            value={range.value}
            label={range.label}
            control={<Radio color="primary" />}
            onChange={(e) => {
              onRangeSelect(e, range, axisId)
            }}
          />
        ))}
      </RadioGroup>
    </StyledFormControl>
  )
}

export default YaxisRangeSelector
