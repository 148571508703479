import React, {
  MouseEventHandler,
  SyntheticEvent,
  useState,
  DragEvent,
} from 'react'
import { Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import { ChevronDown, ChevronRight, Plus } from 'react-feather'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import SideBarMenu from 'shared/components/SideBarMenu'
import useNonEmptyTextField from 'shared/hooks/useNonEmptyTextField'
import { CalculatorState } from 'shared/types'
import Calculator, { CalculatorDataSeriesOption } from './Calculator'
import TransparentTextField from '../TransparentTextField'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '2px dashed transparent',
    '&[draggable]:hover': {
      border: '2px dashed',
      borderColor: theme.palette.divider,
      cursor: 'grab',
    },
    '&[draggable]:active': {
      cursor: 'grabbing',
    },
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  dataSeriesZone: {
    border: '2px dashed',
    borderColor: theme.palette.divider,
    display: 'grid',
    gridTemplateRows: 'auto',
    gridAutoRows: 'auto',
    gridAutoFlow: 'row',
    padding: theme.spacing(2, 2, 2),
    color: theme.palette.text.secondary,
  },
  noDataSeries: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridGap: `${theme.spacing(1)}px`,
    justifyItems: 'center',
    textAlign: 'center',
  },
  draggedOver: {
    borderColor: theme.palette.primary.light,
  },
  dataSeries: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  dataSeriesName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const DEFAULT_CALCULATOR_NAME = 'New Calculator'

export const useCalculators = (initialState?: CalculatorState[]) => {
  const [calculators, setCalculators] = useState<CalculatorState[]>(
    initialState ?? [],
  )

  function handleAddNewCalculator() {
    const uid = uuidv4()
    setCalculators((prevState) => [
      ...prevState,
      {
        uid,
        name: DEFAULT_CALCULATOR_NAME,
        expression: '',
        expressionDataIdMap: {},
        unit: null,
        isValidExpression: false,
        initOpen: true,
      },
    ])
  }

  function handleCalculatorBlur(evt, modifiedCalculator, index) {
    setCalculators((prevState) => {
      return prevState
        .slice(0, index)
        .concat({ ...modifiedCalculator })
        .concat(prevState.slice(index + 1))
    })
  }

  function handleCalculatorDelete(evt, indexToDelete) {
    setCalculators((prevState) => {
      return prevState.filter((calc, index) => index !== indexToDelete)
    })
  }

  return {
    onCalculatorAdd: handleAddNewCalculator,
    onCalculatorBlur: handleCalculatorBlur,
    onCalculatorDelete: handleCalculatorDelete,
    calculators,
  }
}

interface CalculatorItemProps {
  item: CalculatorState
  defaultName: string
  dataSeriesOptions: CalculatorDataSeriesOption[]
  onBlur: (e, calculator: CalculatorState) => void
  onDelete: (e) => void
  onDrag: (event: DragEvent) => void
  labelAccessor: Props['labelAccessor']
  initOpen?: boolean
}

const CalculatorItem = ({
  item,
  defaultName,
  dataSeriesOptions,
  onBlur,
  onDelete,
  onDrag,
  initOpen,
  labelAccessor,
}: CalculatorItemProps) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(initOpen)
  const textFieldProps = useNonEmptyTextField(
    item.name,
    defaultName,
    (e, newName) => {
      onBlur(e, { ...item, name: newName })
    },
  )

  if (isOpen) {
    return (
      <div className={classes.root}>
        <header className={classes.title}>
          <TransparentTextField
            name="name"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...textFieldProps}
            inputProps={{
              maxLength: 20 /* TODO: Check if this arbitrary value is appropriate */,
            }}
          />
          <IconButton onClick={() => setIsOpen(!isOpen)} color="primary">
            <ChevronDown />
          </IconButton>
        </header>
        <Calculator
          labelAccessor={labelAccessor}
          shouldOpenOptionsAtTrigger={false}
          options={dataSeriesOptions}
          initialState={{
            expression: item.expression,
            expressionDataIdMap: item.expressionDataIdMap,
          }}
          onBlur={(e, newState) => {
            onBlur(e, { ...item, ...newState })
          }}
        />
        <Button title="delete" onClick={onDelete}>
          delete
        </Button>
      </div>
    )
  }

  return (
    <>
      <div
        draggable
        onDragStart={onDrag}
        className={clsx(classes.root, classes.title)}
        title={item?.expression}
      >
        {item.name}
        <IconButton onClick={() => setIsOpen(!isOpen)} color="primary">
          <ChevronRight />
        </IconButton>
      </div>
    </>
  )
}

interface Props {
  calculators: CalculatorState[]
  onCalculatorAdd: MouseEventHandler
  dataSeriesOptions: CalculatorDataSeriesOption[]
  onDrag: (event: DragEvent, calculator: CalculatorState, index: number) => void
  onCalculatorBlur: (
    event: SyntheticEvent,
    modifiedCalculator: CalculatorState,
    index: number,
  ) => void
  onCalculatorDelete: (event: SyntheticEvent, index: number) => void
  labelAccessor?:
    | Exclude<keyof CalculatorDataSeriesOption, 'tagOptions'>
    | ((option: CalculatorDataSeriesOption) => string)
}

/** This is a calculator wrapper component that allows users to add new / delete / interact with multiple calculators.
 *
 * This is a controlled component... To make it easy for the developer to use this component a handy `useCalculators` hook is provided with the comopnent. The hook provides methods to add / delete / update calculators, and the calculators states as well (provided by the `onBlur` prop in the `<Calculator />` itself)
 */
const CalculatorMenu = ({
  calculators,
  dataSeriesOptions,
  onDrag,
  onCalculatorAdd,
  onCalculatorBlur,
  onCalculatorDelete,
  labelAccessor,
}: Props) => {
  return (
    <SideBarMenu
      title="calculations"
      customHeaderContent={
        <Button onClick={onCalculatorAdd} color="primary">
          <Plus /> Add
        </Button>
      }
    >
      {calculators.length > 0 ? (
        calculators.map((calculator, index) => (
          <CalculatorItem
            key={calculator.uid}
            onDrag={(e) => onDrag(e, calculator, index)}
            item={calculator}
            dataSeriesOptions={dataSeriesOptions}
            defaultName={DEFAULT_CALCULATOR_NAME}
            onBlur={(e, newCalc) => onCalculatorBlur(e, newCalc, index)}
            onDelete={(e) => onCalculatorDelete(e, index)}
            labelAccessor={labelAccessor}
            initOpen={calculator.initOpen}
          />
        ))
      ) : (
        <Typography variant="body2">
          {'Click the '}
          <Typography component="span" variant="button" color="primary">
            +
          </Typography>
          {' icon to add a calculator and type '}
          <Typography component="span" variant="button" color="primary">
            @
          </Typography>
          {' to see a list of options.'}
        </Typography>
      )}
    </SideBarMenu>
  )
}

export default CalculatorMenu
