import React, { useState, ChangeEvent, MouseEvent } from 'react'
import {
  OutlinedInput,
  ClickAwayListener,
  InputAdornment,
  FormControlLabel,
  Checkbox as MaterialCheckbox,
  FormGroup,
  FormControl,
  styled,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import Autosizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { Search } from 'react-feather'
import { Checkbox as CheckboxType, SelectableValue } from 'shared/types'
import useFlexSearch from 'shared/hooks/useFlexSearch'
import ConditionalWrap from './ConditionalWrap'

const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.grey[400],
}))

const useStyles = makeStyles(() => ({
  root: {
    minHeight: (props: Pick<VirtualCheckboxGroupProps, 'minHeight'>) =>
      props.minHeight,
  },
}))

const useCheckBoxStyles = makeStyles((theme) => ({
  checkboxLabel: theme.typography.body2,
}))

type Index = number
type MinHeight = number | string
type ItemSize = number
type ClickAwayHandler = (e: MouseEvent) => void
type CheckboxChangeHandler = (
  e: ChangeEvent<any>,
  metadata: Array<Metadata>,
) => void

export interface Metadata {
  isChecked: boolean
  id: SelectableValue
  index: Index
  isDisabled?: boolean
}

interface CheckboxProps {
  item: CheckboxType
  onCheckboxChange: CheckboxChangeHandler
  index: Index
}

const Checkbox = ({ item, onCheckboxChange, index }: CheckboxProps) => {
  const classes = useCheckBoxStyles()
  return (
    <FormControlLabel
      classes={{
        label: classes.checkboxLabel,
      }}
      label={item.label}
      name={item.id?.toString() ?? ''}
      control={<MaterialCheckbox color="primary" size="small" />}
      checked={item.isChecked}
      onChange={(e, isChecked) =>
        onCheckboxChange(e, [{ id: item.id, isChecked, index }])
      }
      disabled={item.isDisabled}
    />
  )
}

interface VirtualCheckboxProps {
  index: number
  style: CSSProperties
  data: CheckboxGroupProps
}

const VirtualCheckbox = ({ index, style, data }: VirtualCheckboxProps) => {
  const { items, onCheckboxChange } = data
  return (
    <div style={style}>
      <Checkbox
        item={items[index]}
        index={index}
        onCheckboxChange={onCheckboxChange}
      />
    </div>
  )
}

interface CheckboxGroupProps {
  /** Checkboxes to display `Array<Checkbox>` where
   *
   * `Checkbox = { id, label, isChecked }` and:
   *
   * - `id: string` Used to build unique checkboxes in the group. Should be unique in the group
   * - `label: string` Used to denote checkbox option
   * - `isChecked: boolean` Checkbox current state
   */
  items: Array<CheckboxType>
  /** Callback with parameters `(e: ChangeEvent<any>, metadata: Array<Metadata>)` where:
   *
   * `Metadata = { isChecked, id, index }` and:
   *
   * - `isChecked` is the future value of the checkbox
   * - `index` is the `Array<Checkbox>` index
   * - `id` is the Checkbox id (see `items` prop)
   * */
  onCheckboxChange: CheckboxChangeHandler
}

/** Simple checkbox grouping. */
export const CheckboxGroup = ({
  items,
  onCheckboxChange,
}: CheckboxGroupProps) => {
  return (
    <FormControl>
      <FormGroup>
        {items.map((item, index) => (
          <Checkbox
            key={item.id ?? ''}
            item={item}
            index={index}
            onCheckboxChange={onCheckboxChange}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

interface VirtualCheckboxGroupProps extends CheckboxGroupProps {
  /** Since minimum height for virtualized list. This needs to be passed as a string e.g. `'100px' | '15%' | '12em'` */
  minHeight: MinHeight
  /** Checkbox / Label height. See more information in `<FixedSizeList />` */
  itemSize: ItemSize
}

export const VirtualCheckboxGroup = ({
  items,
  onCheckboxChange,
  minHeight,
  itemSize,
}: VirtualCheckboxGroupProps) => {
  const classes = useStyles({ minHeight })
  return (
    <div className={classes.root}>
      <Autosizer>
        {(autoSizer) => (
          <FixedSizeList
            height={autoSizer.height}
            width={autoSizer.width}
            itemData={{
              items,
              onCheckboxChange,
            }}
            itemKey={(index, data) => data.items[index].id}
            itemSize={itemSize}
            itemCount={items.length}
          >
            {VirtualCheckbox}
          </FixedSizeList>
        )}
      </Autosizer>
    </div>
  )
}

const ExtraSmallButton = styled(Button)(() => ({
  fontSize: '.6rem',
}))

const searchSettings = {
  doc: {
    id: 'id',
    field: 'label',
  },
}

const SelectAllClearAll = ({ items, onCheckboxChange }: CheckboxGroupProps) => {
  function handleMultiselect(e, isChecked) {
    const metadata = items.map((f, index) => ({ ...f, isChecked, index }))
    onCheckboxChange(e, metadata)
  }
  return (
    <div>
      <ExtraSmallButton
        onClick={(e) => handleMultiselect(e, true)}
        color="primary"
        size="small"
      >
        Select all
      </ExtraSmallButton>
      <ExtraSmallButton
        onClick={(e) => handleMultiselect(e, false)}
        color="primary"
        size="small"
      >
        Clear
      </ExtraSmallButton>
      <Typography variant="caption">{items.length} Count</Typography>
    </div>
  )
}

interface CheckboxGroupWithUtilitiesProps extends CheckboxGroupProps {
  /** Callback with parameter `(e: MouseEvent)`, and gets triggered when a user clicks outside component. Acts as an `onBlur` without truly being one... */
  onClickAway?: ClickAwayHandler
  /** Adds checkbox list searchbox */
  hasSearch?: boolean
  /** If true it will virtualize the checkbox (See `<VirtualCheckbox />`) */
  isVirtual?: boolean
  /** Displays Select / Clear buttons above checkbox group */
  hasSelectAllClearAll?: boolean
  /** Since minimum height for virtualized list. This needs to be passed as a string e.g. `'100px' | '15%' | '12em'` */
  virtualMinHeight?: MinHeight
  /** Checkbox / Label height. See more information in `<FixedSizeList />` */
  virtualItemSize?: ItemSize
}
/**
 * Component that helps build Checkbox groupings. This is is essentally a wrapper of two subcomponents `<CheckboxGroup />` and `<VirtualCheckbox />` that additionally provides optional searchbar and select/clear buttons.
 *
 * `{ CheckboxGroup, VirtualCheckbox } from 'shared/components/CheckboxWithUtilities'` and their respective `Props` can be seen in the Props table below.
 *
 * __NOTE:__ It is highly recommended that developers start virtualizing this component `isVirtual = true` on anything over 50 Checkboxes.
 * */
const CheckboxGroupWithUtilities = ({
  items,
  onCheckboxChange,
  onClickAway,
  hasSearch = true,
  isVirtual = false,
  hasSelectAllClearAll = true,
  virtualMinHeight = '200px',
  virtualItemSize = 30,
}: CheckboxGroupWithUtilitiesProps) => {
  const [searchValue, setSearchValue] = useState('')

  /**
   * This state is specifically used to make ClickAwayListener work as an onBlur.
   * We want to trigger the onClickAway callback only after the user clicks on the outside of the component after having interactec  with this component.
   * This should only happen once after interaction with this component (imitating-ish onBlur callbacks)
   *
   * Everytime the user interacts with the component (by typing on the searchbox or clicking on checkboxes / buttons) the state is reset so that the callback is triggered on outside interaction.
   *
   * !!!We don't use onBlur because it was causing issues with some child / grandchild components... Making it impossible to detect if interaction was actually this components scope!!!
   */
  const [isFirstClick, setIsFirstClick] = useState(false)

  function handleSearchChange(e) {
    const { value } = e.target
    if (!isFirstClick) {
      setIsFirstClick(true)
    }
    setSearchValue(value)
  }

  const { results: itemsInView } = useFlexSearch(
    items,
    searchValue,
    searchSettings,
  )
  function handleClickAway(e) {
    if (isFirstClick && typeof onClickAway === 'function') {
      onClickAway(e)
      setIsFirstClick(false)
    }
  }

  function handleCheckboxChange(e, metadata: Metadata[]) {
    if (!isFirstClick) {
      setIsFirstClick(true)
    }
    if (itemsInView.length !== items.length) {
      const newMetaData = metadata.map((data) => {
        const index = items.findIndex((item) => item.id === data.id)
        return { ...data, index }
      })
      onCheckboxChange(e, newMetaData)
    } else {
      onCheckboxChange(e, metadata)
    }
  }
  return (
    <ConditionalWrap
      isWrapped={!!onClickAway}
      wrap={(kids) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          {kids}
        </ClickAwayListener>
      )}
    >
      <div>
        {hasSearch && (
          <div>
            <OutlinedInput
              placeholder="Search Options"
              margin="dense"
              value={searchValue}
              onChange={handleSearchChange}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <StyledSearchIcon />
                </InputAdornment>
              }
            />
          </div>
        )}
        {hasSelectAllClearAll && (
          <SelectAllClearAll
            items={itemsInView}
            onCheckboxChange={handleCheckboxChange}
          />
        )}
        {isVirtual ? (
          <VirtualCheckboxGroup
            items={itemsInView}
            onCheckboxChange={(e, metadata) => {
              handleCheckboxChange(e, metadata)
            }}
            minHeight={virtualMinHeight}
            itemSize={virtualItemSize}
          />
        ) : (
          <CheckboxGroup
            items={itemsInView}
            onCheckboxChange={handleCheckboxChange}
          />
        )}
      </div>
    </ConditionalWrap>
  )
}

export default CheckboxGroupWithUtilities
