import { Box, Typography, useTheme, withStyles } from '@material-ui/core'
import React from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import { DateRange } from '@material-ui/icons'
import { DateTime } from 'luxon'
import { SummaryCardAction } from 'shared/components/SummaryCard/SummaryCardActions'
import SummaryCard from 'shared/components/SummaryCard/SummaryCard'
import { safeColor } from 'shared/utils/themeHelpers'
import { Forecast } from './LoadForecastTypes'

const CardHeader = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    color: theme.palette.grey[600],
    lineHeight: '20px',
    fontWeight: 600,
  },
}))(Typography) as typeof Typography

const CardBody = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.grey[600],
  },
}))(Typography) as typeof Typography

const GrayIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
}))(DateRange) as typeof DateRange

const Details = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    '& > :first-child': {
      marginTop: theme.spacing(1),
    },
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > :not(:only-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}))(Box) as typeof Box

const NoData = withStyles(() => ({
  root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    width: '100%',
    height: '100%',
  },
}))(Box) as typeof Box

interface Props {
  forecast: Forecast
  actions: SummaryCardAction[]
  useActions?: boolean
  onSelect?: (forecast: Forecast) => void
  selected?: boolean
}

const ForecastCard = ({
  forecast,
  actions,
  useActions,
  onSelect,
  selected,
}: Props) => {
  const renderSubtitle = () => {
    return (
      <Details>
        <Box display="flex">
          <GrayIcon />
          <Box>
            <CardHeader>Forecasted days</CardHeader>
            <CardBody>
              From {forecast.from.toISODate()} to {forecast.to.toISODate()}
            </CardBody>
          </Box>
        </Box>
      </Details>
    )
  }

  const theme = useTheme()

  const forecastSparklineColor = safeColor(theme, 'primary')
  const generatedOn = DateTime.fromISO(forecast.processTime).toLocaleString({
    year: 'numeric',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: '2-digit',
    month: 'short',
  })
  return (
    <SummaryCard
      key={forecast.processTime}
      selected={selected}
      useActions={useActions}
      onSelect={onSelect}
      actions={actions}
      subtitle={renderSubtitle()}
      title={`Generated on ${generatedOn}`}
      badges={[{ value: forecast.market, color: 'primary' }]}
      visualisation={
        <Box position="relative">
          <Sparklines
            data={forecast.forecast.map((d) => d.forecast)}
            data-testid="forecast-spark"
          >
            <SparklinesLine color={forecastSparklineColor} />
          </Sparklines>
          {forecast.forecast && forecast.forecast.length <= 1 ? (
            <NoData>
              <Typography variant="subtitle2" data-testid="not-enough-data">
                Not enough data
              </Typography>
            </NoData>
          ) : (
            ''
          )}
        </Box>
      }
      infoBoxes={[
        { header: 'Min', value: forecast.min },
        { header: 'Max', value: forecast.max },
        { header: 'Days', value: forecast.dayCount },
        { header: 'Total', value: forecast.count },
      ]}
      caption={`Generated on ${generatedOn}`}
    />
  )
}

export default ForecastCard
