import React from 'react'
import DashboardRoutes from 'shared/pages/DashboardRoutes'
import { RenderDashboard } from 'shared/pages/DashboardDetail'
import ShortAndLongTermNOP from './pages/ShortAndLongTermNOP'
import SupplyAndMarkToMarket from './pages/SupplyAndMarkToMarket'

const DASHBOARD_TYPE = 'prod_risk_management'

const content = ({
  handleSave,
  dashboard,
  dashboardConfigItem,
}: RenderDashboard) => {
  const {
    datasource,
    filters,
    category,
    timeInterval,
    rangeFilters,
  } = dashboardConfigItem
  switch (category.name) {
    case 'nop':
      return (
        <ShortAndLongTermNOP
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          filtersDefinitions={filters}
          savedDashboardInfo={dashboard}
          timeInterval={timeInterval}
          rangeFiltersDefinition={rangeFilters}
        />
      )
    case 'supplyAndMarkToMarket':
      return (
        <SupplyAndMarkToMarket
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          filtersDefinitions={filters}
          savedDashboardInfo={dashboard}
          timeInterval={timeInterval}
          rangeFiltersDefinition={rangeFilters}
        />
      )
    default:
      return (
        <div>
          <p>
            Category <strong>{category.label}</strong> not is not supported by
            any page on the <strong>{DASHBOARD_TYPE}</strong> component
          </p>
        </div>
      )
  }
}

const RiskDashboards = () => {
  return <DashboardRoutes content={content} dashboardType={DASHBOARD_TYPE} />
}

export default RiskDashboards
