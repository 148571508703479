import React, { SyntheticEvent, useState } from 'react'
import {
  Popover,
  withStyles,
  TextField,
  MenuItem,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core'
import { DataSeries, Filter, SelectedFilter } from 'shared/types'
import useClickedElement from 'shared/hooks/useClickedElement'
import {
  generateNewFilters,
  generateModifiedFilters,
  buildSelectedFilters,
} from 'shared/utils/filtersOperations'
import { createCardDataSeriesLabel } from './helpers'
import { Chip } from './ChipsInput'
import Collapsible from '../Collapsible'
import CheckboxGroupWithUtilities from '../CheckboxGroupWithUtilities'

const useStyles = makeStyles(() => ({
  root: {
    width: 300,
  },
}))

const StyledDialogContent = withStyles((theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'auto',
    gap: `${theme.spacing(2)}px`,
    maxHeight: 300,
  },
}))(DialogContent)

const PopoverContent = ({
  anchorEl,
  onClose,
  dataSeries,
  onDataSeriesChange,
  dataSeriesFilters,
  isMeterVisualization,
}: {
  anchorEl: HTMLElement
  onClose: () => void
  dataSeries: DataSeries
  onDataSeriesChange: any
  dataSeriesFilters: Filter[]
  isMeterVisualization: boolean
}) => {
  const classes = useStyles()

  const {
    calculationOptions,
    selectedCalculation,
    splitOptions,
    selectedSplit,
    selectedFilters: availableSelectedFilters,
  } = dataSeries

  const [filterOptions, setFilterOptions] = useState(() => {
    return generateNewFilters(
      dataSeriesFilters,
      availableSelectedFilters || ([] as SelectedFilter[]),
    )
  })

  const hasSplits =
    !isMeterVisualization && splitOptions && splitOptions.length > 0
  const hasOptions = calculationOptions && calculationOptions.length > 0
  const hasFunctions = hasSplits || hasOptions
  const hasFilters = filterOptions && filterOptions.length > 0

  const [tab, setTab] = React.useState(hasFunctions ? 0 : 1)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const [{ split, calculation }, setFormState] = useState<{
    split: string
    calculation?: string
    selectedFilters?: any
  }>({
    split: (selectedSplit && selectedSplit[0]) || '',
    calculation: (selectedCalculation && selectedCalculation[0]) || '',
  })

  const renderFunctions = () => {
    const renderingElements: any = []
    if (hasOptions) {
      renderingElements.push(
        <TextField
          key="selectedCalculation"
          name="selectedCalculation"
          label="Calculation"
          margin="dense"
          select
          value={calculation}
          onChange={(e) =>
            setFormState((prevState) => ({
              ...prevState,
              calculation: e.target.value,
            }))
          }
        >
          {calculationOptions?.map(([optionValue, optionLabel]) => (
            <MenuItem key={optionValue} value={optionValue}>
              {optionLabel}
            </MenuItem>
          ))}
        </TextField>,
      )
    }

    if (hasSplits) {
      renderingElements.push(
        <TextField
          key="selectedSplit"
          name="selectedSplit"
          label="Split By"
          margin="dense"
          select
          value={split}
          onChange={(e) =>
            setFormState((prevState) => ({
              ...prevState,
              split: e.target.value,
            }))
          }
        >
          <MenuItem value="">- None -</MenuItem>
          {splitOptions?.map(([optionValue, optionLabel]) => (
            <MenuItem key={optionValue} value={optionValue}>
              {optionLabel}
            </MenuItem>
          ))}
        </TextField>,
      )
    }

    return renderingElements
  }

  const renderFilters = () => {
    return (
      filterOptions &&
      filterOptions.map(({ name, title, items }, index) => {
        const hasManyItems = items.length > 10
        return (
          <Collapsible
            title={title}
            key={name}
            defaultMenuIsOpen={index < 1}
            onTitleDragStart={undefined}
          >
            <CheckboxGroupWithUtilities
              items={items}
              onCheckboxChange={(e, metadata) => {
                const individualSeriesFiltersOptions = generateModifiedFilters(
                  filterOptions,
                  name,
                  metadata,
                )

                setFilterOptions(individualSeriesFiltersOptions)
              }}
              hasSearch={hasManyItems}
              hasSelectAllClearAll={hasManyItems}
              isVirtual={hasManyItems}
              virtualMinHeight={200}
              virtualItemSize={30}
            />
          </Collapsible>
        )
      })
    )
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onClose}
    >
      <div className={classes.root}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab disabled={!hasFunctions} label="Functions" />
          <Tab disabled={!hasFilters} label="Filters" />
        </Tabs>
        <StyledDialogContent>
          {tab === 0 && renderFunctions()}
          {tab === 1 && renderFilters()}
        </StyledDialogContent>
      </div>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={(e) => {
            const modifiedDataSeries: DataSeries = { ...dataSeries }

            if (calculationOptions) {
              modifiedDataSeries.selectedCalculation =
                calculationOptions.find(
                  ([optionValue]) => optionValue === calculation,
                ) || calculationOptions[0]
            }
            if (splitOptions) {
              modifiedDataSeries.selectedSplit =
                splitOptions.find(([optionValue]) => optionValue === split) ||
                null
            }
            if (filterOptions) {
              const newSelectedFilters = buildSelectedFilters(filterOptions)
              modifiedDataSeries.selectedFilters = newSelectedFilters
            }

            onDataSeriesChange(e, modifiedDataSeries)
          }}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Popover>
  )
}

interface Props {
  /** DataSeries that is represented by the chip */
  dataSeries: DataSeries
  /** Function invoked when user clicks the delete button (x) */
  onChipDelete: (e: SyntheticEvent) => void
  /** Function invoked when the user changes either the calculation
   * or the "split by" option of the dataseries
   */
  onDataSeriesChange?: (
    e: SyntheticEvent,
    modifiedDataSeries: DataSeries,
  ) => void
  dataSeriesFilters: Filter[]
  isMeterVisualization: boolean
}

/**
 * Specialized version of the Chip expected by the Chips Input component, used by the `<DashboardCard />`, to display data series information. This component builds its label using a combination of `label` and `selectedCalculation`. If calculation options are sent then a popover menu is displayed that allows the user to change the current calculation level. */
const DataSeriesChip = ({
  dataSeries,
  onDataSeriesChange,
  onChipDelete,
  dataSeriesFilters,
  isMeterVisualization,
}: Props) => {
  const {
    label,
    calculationOptions,
    selectedCalculation,
    splitOptions,
    selectedSplit,
  } = dataSeries
  const [anchorEl, openMenu, closeMenu] = useClickedElement()
  const chipLabel = createCardDataSeriesLabel(
    label,
    selectedCalculation,
    selectedSplit,
  )
  const hasMenu = Boolean(
    onDataSeriesChange &&
      (calculationOptions || splitOptions || dataSeriesFilters),
  )
  const isMenuOpen = hasMenu && Boolean(anchorEl)

  return (
    <>
      <Chip
        label={chipLabel}
        onDelete={(event) => onChipDelete(event)}
        onClick={hasMenu ? (e) => openMenu(e) : undefined}
      />
      {isMenuOpen && (
        <PopoverContent
          anchorEl={anchorEl as HTMLElement}
          onClose={closeMenu}
          dataSeriesFilters={dataSeriesFilters}
          onDataSeriesChange={(e, modifiedDataSeries) => {
            /* It is already ensured that "onDataSeriesChange" has value at this
            point, but typescript has problems to identify it */
            // @ts-ignore
            onDataSeriesChange(e, modifiedDataSeries)
            closeMenu()
          }}
          dataSeries={dataSeries}
          isMeterVisualization={isMeterVisualization}
        />
      )}
    </>
  )
}

export default DataSeriesChip
