import { ProductModuleConfig } from 'shared/types'
import LoadForecast, { LoadForecastMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: LoadForecastMenu,
  MainContent: LoadForecast,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
