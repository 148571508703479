import React from 'react'
import { InputAdornment, TextField, makeStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { Clock } from 'react-feather'
import { AutocompleteRenderInputParams } from '@material-ui/lab'

interface Props {
  // option that will be displayed as selected
  params: AutocompleteRenderInputParams
  // Callback invoked when a user selects a new option
  placeHolder: string
}

const useStyles = makeStyles((theme) => ({
  input: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 1px 2px 0 ${fade(theme.palette.common.white, 0.08)}`,
    padding: theme.spacing(0.5, 1),
    marginTop: `${theme.spacing(3)}px!important`,
  },
  clockButton: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  clockIcon: {
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
    width: '22px',
    height: '22px',
  },
}))

const ClockDropdownTextField = ({ params, placeHolder }: Props) => {
  const classes = useStyles()

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...params}
      placeholder={placeHolder}
      label={placeHolder}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        className: classes.input,
        startAdornment: (
          <InputAdornment position="start" className={classes.clockButton}>
            <Clock className={classes.clockIcon} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default ClockDropdownTextField
