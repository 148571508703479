import React from 'react'
import { MoreHorizontal } from 'react-feather'
import { Popover, MenuItem, Button, Divider } from '@material-ui/core'
import { Job } from 'shared/types'
import useClickedElement from 'shared/hooks/useClickedElement'

export interface Props {
  /**
   * Indicates if the job related to this menu has already completed (successfully or with errors) or not
   * (in queue, in progress, ...).
   *
   * It will be used to decide which actions are available.
   */
  isCompleted: boolean
  /**
   * This callback is executed when the user wants to cancel the related job. It is ensured that
   * will only be invoked if the `completed` prop has a **false** value.
   *
   * <pre>
   * (event: React.MouseEvent<HTMLElement>) => any
   * </pre>
   */
  onCancelClick: (event: React.MouseEvent<HTMLElement>) => any
  /**
   * This callback is executed when the user wants to re-run the related job. It is ensured that
   * will only be invoked if the `completed` prop has a **true** value.
   *
   * <pre>
   * (event: React.MouseEvent<HTMLElement>) => any
   * </pre>
   */
  onRerunClick: (event: React.MouseEvent<HTMLElement>) => any
  /**
   * This callback is executed whenever user wants to donwload the job.
   *
   * <pre>
   * (event: React.MouseEvent<HTMLElement>) => any
   * </pre>
   */
  onDownloadClick: (
    event: React.MouseEvent<HTMLElement>,
    resultType: 'monthly_price' | 'summary_price',
  ) => any
  /**
   * Indicates the status of the job as being 'success' | 'warning' | 'error' |
   *
   * It will be used to decide which actions are available.
   */
  jobStatus: Job['progress']['status']
}

/**
 * This component would enable an user to perform certain operations on a job.
 *
 * The options in the menu change depending if the related job is in a "completed" status or not.
 *
 * If the job is NOT completed (in queue, in progress, other...), then the available options will be
 * `Cancel` and `View Details`.
 *
 * If the job is completed (either successfully or with errors), then the options will be `Rerun` and `View Details`.
 */
const JobActionsMenu = ({
  isCompleted,
  onDownloadClick,
  onRerunClick,
  jobStatus,
}: Props) => {
  const [anchorEl, handleMenuOpen, handleMenuClose] = useClickedElement()
  return (
    <>
      <Button onClick={handleMenuOpen}>
        <MoreHorizontal />
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={(e) => onDownloadClick(e, 'monthly_price')}
          disabled={
            !isCompleted || (jobStatus !== 'success' && jobStatus !== 'warning')
          }
        >
          Download Monthly
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(e) => onDownloadClick(e, 'summary_price')}
          disabled={
            !isCompleted || (jobStatus !== 'success' && jobStatus !== 'warning')
          }
        >
          Download Summary
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(e) => {
            onRerunClick(e)
          }}
        >
          Rerun
        </MenuItem>
      </Popover>
    </>
  )
}

export default JobActionsMenu
