import axiosApiClient from 'shared/apiClient'
import { isCalculation } from 'shared/helpers'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import {
  Calculation,
  DataSeriesSplitElement,
  Group,
  SelectableValue,
} from 'shared/types'

export async function getLatestAsOfDate(
  datasource: string,
  datesBefore?: string,
): Promise<Array<string>> {
  const params: { datesBefore?: Date } = {}
  if (datesBefore) params.datesBefore = new Date(datesBefore)
  return axiosApiClient
    .get(`/risk/v1/risk-data/${datasource}/as-of-date`, { params })
    .then((d) => d.data)
}

interface FilterColumn {
  name: string
  values: Array<SelectableValue>
}

interface QueryColumn {
  name: string
  aggregation: Calculation
}
interface SingleGrouping {
  direction?: 'ascending' | 'descending'
  groupType: Group | string
}

type Grouping = Array<SingleGrouping>
export interface RiskFilters {
  columns?: FilterColumn[]
}

interface AppliedFilters {
  name: string
  values: Array<SelectableValue>
}

function normalizeRiskData(
  columnsMapping: Array<[string, string]>,
  dataList: Array<any>,
  grouping: Grouping,
): Array<DataSeriesSplitElement> {
  /* TODO: At some point multiple splits whould be returned (when multi-grouping is available) */
  const result: Array<DataSeriesSplitElement> = [
    { groupedData: {}, splitValue: null, seriesData: [] },
  ]

  const [onlySplit] = result

  const { groupedData, seriesData } = onlySplit

  dataList.forEach((dataObject) => {
    const data: object = {}

    columnsMapping.forEach(([riskColumn, column]) => {
      const value = dataObject[riskColumn]
      data[column] = value
      groupedData[column] = value + (groupedData[column] || 0)
    })

    grouping.forEach(({ groupType }) => {
      data[groupType] = dataObject[groupType]
    })

    seriesData.push(data)
  })

  /* Data is sorted in grouping order for all the splits */
  result.forEach((split) => {
    split.seriesData.sort((itemA, itemB) => {
      let comparison = 0
      for (let i = 0; i < grouping.length && comparison === 0; i += 1) {
        const { groupType } = grouping[i]
        const valueA = itemA[groupType]
        const valueB = itemB[groupType]
        if (typeof valueA === 'string') {
          comparison = valueA.localeCompare(valueB)
        } else {
          comparison = (valueA || 0) - (valueB || 0)
        }
      }
      return comparison
    })
  })

  return result
}

/**
 * Returns the full response data of a query request from risk repository.
 */
export async function queryRiskDatasource(
  asOfDate,
  datasource: string,
  columns: QueryColumn[],
  appliedFilters: AppliedFilters[],
  grouping: Grouping,
  appliedRangeFilters?: Array<RangeFilter>,
): Promise<Array<DataSeriesSplitElement>> {
  const fixedColumns = columns.map((v) =>
    v.aggregation === Calculation.Average ? { ...v, aggregation: 'avg' } : v,
  ) as QueryColumn[]
  const response = await axiosApiClient.post(
    `/risk/v1/risk-data/${datasource}/query`,
    {
      asOfDate,
      group: grouping.map((g) => g.groupType),
      columns: fixedColumns,
      selectedFilters: appliedFilters,
      rangeFilters: appliedRangeFilters,
    },
  )

  const columnsMapping = fixedColumns.map<[string, string]>(
    ({ name, aggregation }) => {
      // We need to find either a "valid calculation" or because risk takes in avg as average we also need to compare here...
      // TODO: Consider changing backend to average...
      const druidColumn =
        isCalculation(aggregation) || aggregation === 'avg'
          ? `${aggregation}_${name}`
          : name
      return [druidColumn, name]
    },
  )
  return normalizeRiskData(columnsMapping, response.data, grouping)
}

// TODO: type return value for function...
export async function getRiskDistinctData(
  datasource: string,
  fields: Array<string>,
): Promise<{ [key: string]: string }[]> {
  return axiosApiClient
    .post(`/risk/v1/risk-data/${datasource}/distinct`, { fields })
    .then((d) => d.data)
}

export async function getMinMaxForColumns(
  datasource: string,
  fields: Array<string>,
): Promise<Array<{ minValue: number; maxValue: number; name: string }>> {
  return axiosApiClient
    .post(`/risk/v1/risk-data/${datasource}/min-max`, { fields })
    .then((d) =>
      d.data.map((data) => ({
        minValue: Math.floor(data.minValue),
        maxValue: Math.ceil(data.maxValue),
        name: data.name,
      })),
    )
}

export default {}
