import React from 'react'
import { Box, Typography, withStyles } from '@material-ui/core'

const InfoBoxHeader = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    fontWeight: 600,
  },
}))(Typography) as typeof Typography

const InfoBoxValue = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: `${theme.spacing(3)}px`,
    marginTop: theme.spacing(0.5),
  },
}))(Typography) as typeof Typography

export interface SummaryCardInfoBoxContent {
  header: string
  value: number
}

const SummaryCardInfoBox = ({ header, value }: SummaryCardInfoBoxContent) => {
  return (
    <Box display="flex" flexDirection="column" data-testid="summary-info-box">
      <InfoBoxHeader variant="caption" component="span">
        {header}
      </InfoBoxHeader>
      <InfoBoxValue variant="subtitle1" color="primary" component="span">
        {value}
      </InfoBoxValue>
    </Box>
  )
}

export default SummaryCardInfoBox
