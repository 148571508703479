import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import MainComponent from 'base/Main'
import AppMaterialContextsProvider from './AppMaterialContextsProvider'

function App() {
  /* This component just sets the initial wrappers for routing, global state
  and contexts for the whole app */
  return (
    <AppMaterialContextsProvider>
      <BrowserRouter>
        <Route component={MainComponent} />
      </BrowserRouter>
    </AppMaterialContextsProvider>
  )
}

export default App
