import React, {
  MouseEvent,
  useRef,
  useEffect,
  useReducer,
  Reducer,
  SyntheticEvent,
  useState,
} from 'react'
import * as math from 'mathjs'
import Autosizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import {
  makeStyles,
  Paper,
  ListItem,
  ListItemText,
  Theme,
  fade,
  Popover,
  MenuItem,
} from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import ContentEditable from 'react-contenteditable'
import useClickedElement from 'shared/hooks/useClickedElement'
import { getDefinition } from 'shared/common/units'
import { DataSeriesType } from 'shared/types'
import useFlexSearch from '../../hooks/useFlexSearch'
import StyledChip from '../StyledChip'
import { getCaretCoordinates, getCaretIndex, setCaret } from './caretHelpers'

const TRIGGER = '@'
const NON_BREAKING_SPACE = '&nbsp;'

export function getSelectedOptionLabel(option: CalculatorDataSeriesOption) {
  const selectedOptionLabel = option.tagOptions?.find(
    (t) => t.value === option.selectedOption,
  )
  return selectedOptionLabel
    ? selectedOptionLabel.label.toUpperCase()
    : undefined
}

function getUnit(expression, scope = {}): 'string' | null {
  try {
    const result = math.evaluate(expression, scope)
    return typeof result === 'object' ? result.toJSON().unit : 'dimensionless'
  } catch (error) {
    return null
  }
}

function htmlStringToElement(text) {
  const template = document.createElement('template')
  template.innerHTML = text
  return template.content
}

/**
 *
 * @param value html string to containing <span /> tags that can be parsed to build a valid calculator expression.
 * @returns object with:
 *   expression: valid math expression that can be used alongside math.js to perform some calculation. Each unique series + selected option will be denoted as x0, x1, x2, ... xn
 *   pureText: total innerText of the entire string - for each <span /> tag it removes the tag and leaves the innerText
 *   expressionUnitScope: unit assigned to each expresssion scope piece (x0, x1, etc)
 *   expressionIdMap: id and selected option assigned to each expression piece (x0, x1) , { id, selectedOption }
 *   unit: unit of expression calculation
 */
export function transformToExpressionAndPure(value: string) {
  const element = htmlStringToElement(value)
  const spans = element.querySelectorAll('[data-id]')
  let expression = value
  let pureText = value
  const expressionUnitScope = {}
  const expressionDataIdMap = {}
  const idScopeMap = {}
  const expressionArr: any[] = []
  let count = 0
  spans.forEach((el) => {
    let scope = ''
    const id = el.getAttribute('data-id')
    const selectedOption = el.getAttribute('data-selectedoption')
    const unit = el.getAttribute('data-unit')
    const key = [id, selectedOption].join('|')
    if (!idScopeMap[key]) {
      scope = `x${count}`
      count += 1
      idScopeMap[key] = scope
    } else {
      scope = idScopeMap[key]
    }
    expressionArr.push({
      id,
      unit,
      selectedOption,
      scope,
      outerHtml: el.outerHTML,
      innerHtml: el.innerHTML,
    })
  })

  expressionArr.forEach((elem) => {
    if (
      elem.unit &&
      elem.unit !== getDefinition(DataSeriesType.Dimensionless).defaultUnitValue
    ) {
      expressionUnitScope[elem.scope] = math.unit(`1 ${elem.unit}`)
    } else {
      // this handles dimensionless units
      expressionUnitScope[elem.scope] = 1
    }
    expressionDataIdMap[elem.scope] = {
      id: elem.id,
      selectedOption: elem.selectedOption,
    }
    expression = expression.replace(elem.outerHtml, elem.scope)
    pureText = pureText.replace(elem.outerHtml, elem.innerHtml)
  })
  /** TODO: ideally this would be replaceAll BUT the method doesn't exist in Node (as of v14.15) thus we cannot test */
  const regex = new RegExp(NON_BREAKING_SPACE, 'g')
  expression = expression.replace(regex, ' ')
  pureText = pureText.replace(regex, ' ')
  const calculatedUnit = getUnit(expression, expressionUnitScope)
  return {
    expression,
    pureText,
    expressionUnitScope,
    expressionDataIdMap,
    unit: calculatedUnit,
  }
}

interface ReducerState {
  isMenuOpen: boolean
  search: string
  caretPosition: { x: number; y: number }
  triggerIndex: number
  displayHtml: string
  expression: string
  unit: string | null
  expressionUnitScope: object
  expressionDataIdMap: {
    [key: string]: { id: string; selectedOption?: string }
  }
  hasJustMadeSelection: boolean
  isValidExpression: boolean
}

const openMenu = ({
  displayHtml,
}): { type: 'OPEN_MENU'; displayHtml: string } => ({
  type: 'OPEN_MENU',
  displayHtml,
})

const closeMenu = ({
  displayHtml,
}): { type: 'CLOSE_MENU'; displayHtml: string } => ({
  type: 'CLOSE_MENU',
  displayHtml,
})

const searchMenu = ({
  element,
  displayHtml,
}): { type: 'SEARCH_MENU'; element: HTMLElement; displayHtml: string } => ({
  type: 'SEARCH_MENU',
  element,
  displayHtml,
})

const normalChange = ({
  displayHtml,
}): { type: 'NORMAL_CHANGE'; displayHtml: string } => ({
  type: 'NORMAL_CHANGE',
  displayHtml,
})

const selectMenuOption = ({
  displayHtml,
}): {
  type: 'SELECT_MENU_OPTION'
  displayHtml: string
} => ({ type: 'SELECT_MENU_OPTION', displayHtml })

const selectMenuOptionEffect = ({
  displayHtml,
}): {
  type: 'SELECTED_MENU_OPTION_EFFECT'
  displayHtml: string
} => ({ type: 'SELECTED_MENU_OPTION_EFFECT', displayHtml })

type ReducerAction =
  | ReturnType<typeof openMenu>
  | ReturnType<typeof closeMenu>
  | ReturnType<typeof searchMenu>
  | ReturnType<typeof normalChange>
  | ReturnType<typeof selectMenuOption>
  | ReturnType<typeof selectMenuOptionEffect>

const reducer: Reducer<ReducerState, ReducerAction> = (state, action) => {
  const transformations = transformToExpressionAndPure(action.displayHtml)
  const isValidExpression =
    Boolean(transformations.unit) && transformations.expression.length > 0
  switch (action.type) {
    case 'OPEN_MENU': {
      let triggerIndex = 0
      const element = htmlStringToElement(action.displayHtml)
      element.childNodes.forEach((node, index) => {
        if (node?.textContent?.includes(TRIGGER)) {
          triggerIndex = index
        }
      })

      const coords = getCaretCoordinates()
      return {
        search: '',
        isMenuOpen: true,
        caretPosition: coords,
        triggerIndex,
        displayHtml: action.displayHtml,
        hasJustMadeSelection: false,
        isValidExpression,
        ...transformations,
      }
    }
    case 'CLOSE_MENU': {
      return {
        ...state,
        isMenuOpen: false,
        search: '',
        caretPosition: { x: 0, y: 0 },
        displayHtml: action.displayHtml,
        isValidExpression,
        ...transformations,
      }
    }
    case 'SEARCH_MENU': {
      // The search starts from the trigger to the current caret postion
      const start = transformations.pureText.indexOf(TRIGGER) + 1
      const end = getCaretIndex(action.element)
      return {
        ...state,
        isMenuOpen: true,
        search: transformations.pureText.substring(start, end),
        displayHtml: action.displayHtml,
        isValidExpression,
        ...transformations,
      }
    }
    case 'NORMAL_CHANGE': {
      return {
        ...state,
        displayHtml: action.displayHtml,
        isValidExpression,
        ...transformations,
      }
    }
    case 'SELECT_MENU_OPTION': {
      return {
        ...state,
        search: '',
        isMenuOpen: false,
        caretPosition: { x: 0, y: 0 },
        displayHtml: `${action.displayHtml}`,
        hasJustMadeSelection: true,
        isValidExpression,
        ...transformations,
      }
    }
    case 'SELECTED_MENU_OPTION_EFFECT': {
      return {
        ...state,
        hasJustMadeSelection: false,
        isValidExpression,
        ...transformations,
      }
    }
    default: {
      return state
    }
  }
}

const getBorderColor = (
  isValid: ReducerState['isValidExpression'],
  expression: ReducerState['expression'],
  theme: Theme,
): string => {
  if (expression.length === 0) {
    return theme.palette.divider
  }
  if (isValid) {
    return theme.palette.success.main
  }
  return theme.palette.error.main
}

const useMenuStyles = makeStyles(() => ({
  menu: {
    position: 'absolute',
    width: '200px',
    height: '200px',
    overflow: 'auto',
    left: (props: any) =>
      props.shouldOpenOptionsAtTrigger ? props.caretPosition.x : 0,
    // Tends to stay below buttons
    zIndex: 1,
  },
}))

const useContentStyles = makeStyles((theme) => ({
  textbox: {
    padding: theme.spacing(0.5),
    lineHeight: theme.spacing(0.25),
    border: '2px dashed',
    borderColor: (props: any) =>
      getBorderColor(props.isValidExpression, props.expression, theme),
    '&:focus': {
      outlineColor: (props: any) =>
        getBorderColor(props.isValidExpression, props.expression, theme),
    },
  },
  summary: {
    display: 'flex',
    flexDirection: 'column',
  },
  textSummaryLabel: {
    color: theme.palette.text.secondary,
  },
  textSummaryValue: {
    backgroundColor: fade(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
}))

type TagOption = { value: string; label: string }

export interface CalculatorDataSeriesOption {
  id: string
  title: string
  subtitle?: string
  unit: string | null
  tagOptions?: TagOption[]
  selectedOption?: TagOption['value']
}

interface DropdownMenuProps
  extends Pick<ReducerState, 'caretPosition' | 'isMenuOpen'> {
  options: CalculatorDataSeriesOption[]
  shouldOpenOptionsAtTrigger: boolean
  onOptionSelect: (e: MouseEvent, option: CalculatorDataSeriesOption) => void
}

const DropdownMenu = ({
  isMenuOpen,
  caretPosition,
  onOptionSelect,
  shouldOpenOptionsAtTrigger,
  options,
}: DropdownMenuProps) => {
  const classes = useMenuStyles({ caretPosition, shouldOpenOptionsAtTrigger })
  if (!isMenuOpen) {
    return null
  }
  return (
    <Paper role="menu" className={classes.menu}>
      <Autosizer>
        {(autoSizer) => (
          <FixedSizeList
            height={autoSizer.height}
            width={autoSizer.width}
            itemSize={60} // very much a random number... that seems to fit
            itemCount={options.length}
            itemData={options}
            itemKey={(index, data) => {
              const item = data[index]
              return item.id
            }}
          >
            {({ index, style }) => (
              <ListItem
                style={style}
                role="menuitem"
                key={options[index].id}
                button
                onClick={(e) => onOptionSelect(e, options[index])}
              >
                <ListItemText
                  primary={options[index].title}
                  secondary={options[index].subtitle}
                />
              </ListItem>
            )}
          </FixedSizeList>
        )}
      </Autosizer>
    </Paper>
  )
}

type NewState = Pick<
  ReducerState,
  'expression' | 'unit' | 'isValidExpression' | 'expressionDataIdMap'
>
export type InitialState = Pick<
  ReducerState,
  'expression' | 'expressionDataIdMap'
>
interface Props {
  /** Options to build dropdown menu, each option must have a unique id */
  options: CalculatorDataSeriesOption[]
  /** Will contain arguments `event | expression | expressionDataIdMap | unit | isValidExpression` */
  onBlur?: (evt: SyntheticEvent, newState: NewState) => void
  /** expression and expression scope to id map that allows initialization of component */
  initialState?: InitialState
  /** Should the menu option x position be positioned by the caret */
  shouldOpenOptionsAtTrigger?: boolean
  /** Function or string that will be used to build the selected option label */
  labelAccessor?:
    | Exclude<keyof CalculatorDataSeriesOption, 'tagOptions'>
    | ((option: CalculatorDataSeriesOption) => string)
}

const searchSettings = {
  doc: {
    id: 'id',
    field: ['title', 'subtitle', 'id'],
  },
}

/**
 * following the known react-contenteditable issue, we use this function to correctly reference different component callbacks such as onBlur()
 * https://github.com/lovasoa/react-contenteditable/issues/161#issuecomment-736053428
 */
const useRefCallback = <T extends any[]>(
  value: ((...args: T) => void) | undefined,
  deps?: React.DependencyList,
): ((...args: T) => void) => {
  const ref = React.useRef(value)

  React.useEffect(() => {
    ref.current = value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? [value])

  const result = React.useCallback((...args: T) => {
    ref.current?.(...args)
  }, [])

  return result
}

function getLabel(
  option: CalculatorDataSeriesOption,
  labelAccessor: Props['labelAccessor'],
): string {
  if (typeof labelAccessor === 'function') {
    return labelAccessor(option)
  }
  if (!labelAccessor) {
    return option.title
  }
  /**
   * Basically, since some option properties are optional the output may be undefined...
   * I would've wanted to put !option[labelAccesor] at the top, but typescript wasn't having any of it. lol
   */
  return option[labelAccessor] ?? option.title
}

/**
 * This is a very different input. It should be used to visualize and allow a user to build valid mathjs expressions and their scopes. It's almost a function... it's uncontrolled... it's kind of weird.
 *
 * This component is an uncontrolled input component (an editable html component really) that allows a user to trigger a menu of options when the `@` key is pressed. When a value is selected from the options list the input box adds the value to the textbox. A user can type anything, and the component will always try to determine if it's a valid expression (in simple terms, can mathjs parse it?). The options passed to this component must have either a unit that is valid via mathjs or no unit at all. When a user selects a menu option the component will build an appropriate mathjs expression and a scope following an `x0, x1, x2, xn...` pattern. Where `x0` represents a unique option selected from the list.
 *
 * Instead of having an `onChange` callback this component provides an `onBlur`. This decision was made to optimize performance, meaning we're not forcing many renders as a user types. Remember, some of the calculations can be expensive. The callback will provide values for the calculation expression, the expression scope, an expression to option id map (Where the ids are the reference found in the options), the calculated unit, and whether the expression is valid. The `<Calculator />` can be initialized with a value if given an expression and an expresssion to id map.
 */
const Calculator = ({
  initialState,
  labelAccessor = 'title',
  options,
  onBlur,
  shouldOpenOptionsAtTrigger = false,
}: Props) => {
  const editableContentEl = useRef<HTMLInputElement>(null)

  const [state, dispatch] = useReducer(reducer, {
    expression: initialState?.expression ?? '',
    expressionDataIdMap: initialState?.expressionDataIdMap ?? {},
    isMenuOpen: false,
    search: '',
    caretPosition: { x: 0, y: 0 },
    triggerIndex: 0,
    displayHtml: '',
    unit: '',
    hasJustMadeSelection: false,
    isValidExpression: false,
    expressionUnitScope: {},
  })

  const classes = useContentStyles(state)

  const { results: optionsInView } = useFlexSearch(
    options,
    state.search,
    searchSettings,
  )

  // TODO: Add index to x0... the real id should option id and calc
  function buildSpan(option: CalculatorDataSeriesOption, label: string) {
    const span = document.createElement('span')
    /**
     * We cannot use a "dynamic" style or classname because finding the spans becomes complex
     */
    span.classList.add('calculator-selected-option')
    span.innerText = label
    span.setAttribute('data-id', option.id)
    span.setAttribute('data-uid', uuidv4())
    if (option.unit) {
      span.setAttribute('data-unit', option.unit)
    }
    if (option.tagOptions) {
      span.classList.add('has-menu')
      span.setAttribute('data-tagoptions', JSON.stringify(option.tagOptions))
      span.setAttribute(
        'data-selectedoption',
        option.selectedOption || option.tagOptions[0].value,
      )
    }
    span.setAttribute('contentEditable', 'false')
    return span
  }

  useEffect(() => {
    if (state.expression && Object.keys(state.expressionDataIdMap).length > 0) {
      let newHtml = state.expression
      Object.entries(state.expressionDataIdMap).forEach(([key, val]) => {
        const option = options.find((o) => o.id === val.id)
        if (option) {
          const updatedOption = {
            ...option,
            selectedOption: val.selectedOption,
          }
          const label = getLabel(updatedOption, labelAccessor)
          const span = buildSpan(updatedOption, label)
          const htmlContent = `${span.outerHTML}${NON_BREAKING_SPACE}`
          /** TODO: ideally this would be replaceAll BUT the method doesn't exist in Node (as of v14.15) thus we cannot test */
          const regex = new RegExp(key, 'g')
          newHtml = newHtml.replace(regex, htmlContent)
        }
      })
      dispatch(normalChange({ displayHtml: newHtml }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (evt) => {
    const { value } = evt.target
    if (!editableContentEl.current) {
      return
    }
    /**
     * We used to check evt.nativeEvent.data === TRIGGER however it was very difficult to test the open trigger. The testing tool can only test the editable component by changing the inner text! Thus, for ease of testing we naively check if it has the trigger in the value. This works for this application because we assume the entire expression will be relatively short. Regardless we have to check if the string includes the trigger because that's what tells us a user is searching the options.
     */
    const hasTrigger = value.includes(TRIGGER)
    const hasOpenedMenu = hasTrigger && !state.isMenuOpen
    if (hasOpenedMenu) {
      dispatch(openMenu({ displayHtml: value }))
    } else if (state.isMenuOpen) {
      // We are searching if we have the trigger in the value
      if (hasTrigger) {
        dispatch(
          searchMenu({
            element: editableContentEl.current,
            displayHtml: value,
          }),
        )
      } else {
        dispatch(closeMenu({ displayHtml: value }))
      }
    } else {
      dispatch(normalChange({ displayHtml: value }))
    }
  }

  useEffect(() => {
    if (state.hasJustMadeSelection && editableContentEl.current) {
      // The addition of 1 to the index denotes moving the caret ahead of the newly created <span />
      setCaret(editableContentEl.current, state.triggerIndex + 1)
      dispatch(
        selectMenuOptionEffect({
          displayHtml: state.displayHtml,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isMenuOpen])

  const handleOptionClick = (option: CalculatorDataSeriesOption) => {
    const updatedOption = { ...option }
    if (!updatedOption.selectedOption && updatedOption.tagOptions) {
      updatedOption.selectedOption = updatedOption.tagOptions[0].value
    }
    const label = getLabel(updatedOption, labelAccessor)
    const span = buildSpan(updatedOption, label)
    const htmlContent = `${span.outerHTML}${NON_BREAKING_SPACE}`
    const newHtml = state.displayHtml.replace(
      `${TRIGGER}${state.search}`,
      htmlContent,
    )

    dispatch(
      selectMenuOption({
        displayHtml: newHtml,
      }),
    )
  }

  /**
   * There's an issue with react-contenteditable where callback references are not being created correctly (due to how it uses shouldComponentUpdate) (https://github.com/lovasoa/react-contenteditable#known-issues)
   */
  const handleBlur = useRefCallback((e) => {
    if (onBlur) {
      onBlur(e, {
        expression: state.expression,
        expressionDataIdMap: state.expressionDataIdMap,
        unit: state.unit,
        isValidExpression: state.isValidExpression,
      })
    }
  })

  const [anchorEl, openPopup, closePopup] = useClickedElement()
  const [postion, setPosition] = useState({ x: 0, y: 0 })
  const [tagOptions, setTagOptions] = useState<null | TagOption[]>(null)
  const [tagId, setTagId] = useState<null | string>(null)

  const handleTagOptionClick = (e, tagOption: TagOption) => {
    closePopup()
    setTagOptions(null)
    if (editableContentEl.current) {
      for (let i = 0; i < editableContentEl.current?.children.length; i += 1) {
        const child = editableContentEl.current.children[i]
        const id = child.getAttribute('data-id')
        const option = options.find((o) => o.id === id)
        if (child.getAttribute('data-uid') === tagId && option) {
          child.setAttribute('data-selectedoption', tagOption.value)
          const label = getLabel(
            { ...option, selectedOption: tagOption.value },
            labelAccessor,
          )
          child.textContent = label
        }
      }
    }
    dispatch(
      normalChange({ displayHtml: editableContentEl.current?.innerHTML }),
    )
  }

  return (
    <>
      <div className={classes.summary}>
        <span>
          <StyledChip
            title="output expression"
            variant="outlined"
            size="small"
            label={`expression: ${state.expression}`}
          />
        </span>
        <span>
          <StyledChip
            title="output unit"
            variant="outlined"
            size="small"
            label={`unit: ${state.unit}`}
          />
        </span>
      </div>
      <ContentEditable
        role="textbox"
        onClick={(e) => {
          const target = e.target as HTMLButtonElement
          if (
            target.dataset &&
            target.dataset.id &&
            target.dataset.tagoptions &&
            target.dataset.uid
          ) {
            openPopup(e)
            setPosition({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY })
            setTagOptions(JSON.parse(target.dataset.tagoptions))
            setTagId(target.dataset.uid)
          }
        }}
        className={classes.textbox}
        onKeyDown={(evt) => {
          // do not allow new lines
          if (evt.key === 'Enter') {
            evt.preventDefault()
          }
        }}
        onBlur={handleBlur}
        innerRef={editableContentEl}
        tabIndex={0}
        html={state.displayHtml}
        onChange={handleChange}
      />
      <DropdownMenu
        shouldOpenOptionsAtTrigger={shouldOpenOptionsAtTrigger}
        isMenuOpen={state.isMenuOpen}
        caretPosition={state.caretPosition}
        options={optionsInView}
        onOptionSelect={(e, option) => handleOptionClick(option)}
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: postion.y, left: postion.x }}
      >
        {tagOptions?.map((option) => {
          return (
            <MenuItem
              key={option.value}
              onClick={(e) => handleTagOptionClick(e, option)}
            >
              {option.label}
            </MenuItem>
          )
        })}
      </Popover>
    </>
  )
}

export default Calculator
