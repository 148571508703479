import { ProductModuleConfig } from 'shared/types'
import ProductManagement, { ProductManagementMenu } from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: ProductManagementMenu,
  MainContent: ProductManagement,
  getNotificationExtraContent: () => null,
}

export default productModuleConfig
