import { DateTime } from 'luxon'
import React from 'react'
import { Radio, makeStyles, styled, Typography } from '@material-ui/core'

const StyledMenuOption = styled('li')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  listStyle: 'none',
  borderBottom: '1px solid #E2E5ED',
}))

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
  },
}))

const BlueRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

export interface Props {
  sortedVersions: { createdAt: string; versionId: string }[]
  selectedVersionId?: string
  onChange: (versionId: string) => void
}
/* must pass in a list of sorted versions */
const AdjustmentVersionSelector = ({
  sortedVersions,
  onChange,
  selectedVersionId,
}: Props) => {
  const classes = useStyles()
  const versionArrLen = sortedVersions.length

  return (
    <div>
      {sortedVersions.map((version, index) => {
        const { versionId } = version
        const remainder = versionArrLen - index - 1
        const versionNumber = `v.${remainder}`
        return (
          <StyledMenuOption key={versionId}>
            <Typography variant="h6" className={classes.title}>
              <BlueRadio
                checked={versionId === selectedVersionId}
                value={versionId}
                color="default"
                onChange={(event) => onChange(event.target.value)}
                size="small"
              />
              {index === 0
                ? `${versionNumber} - Latest Version`
                : versionNumber}
            </Typography>

            <Typography variant="caption" className={classes.subtitle}>
              {`${DateTime.fromISO(version.createdAt)
                .toLocal()
                .toLocaleString(DateTime.DATETIME_FULL)}`}
            </Typography>
          </StyledMenuOption>
        )
      })}
    </div>
  )
}

export default AdjustmentVersionSelector
