import React from 'react'
import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import { Color } from '../utils/themeHelpers'

interface Props extends Omit<ButtonProps, 'color'> {
  disabled?: boolean
  cancel?: boolean
  color?: Color
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.75),
  },
}))

const IWButton = React.forwardRef(({ color, cancel, ...props }: Props, ref) => {
  const classes = useStyles()
  return (
    <Button
      className={classes.root}
      innerRef={ref}
      size="medium"
      color={color || cancel ? 'default' : 'primary'}
      variant="contained"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    />
  )
})

export default IWButton
