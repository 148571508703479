import { useState, useEffect } from 'react'
import { AsyncData } from 'shared/types'

/* TODO: Add some sort of strategy to remove
old data or control when the cache gets too large */

interface CachedResults<T, E> {
  [key: string]: AsyncData<T, E>
}

/**
 * This hook is used to fetch data only once per key that are are
 *  passed as an argument. It ensures only one "fetcher" invocation
 * per different key.
 *
 * It will return an object with the next shape:
 *
 * <pre>
 * {
 *  results: {
 *    [key]: {
 *      data: null | any
 *      error: null | any
 *    }
 *  }
 *  clearCache: (keys?) => void
 * }
 * </pre>
 *
 * The results object is ensured to have a field for every key
 * requested on the hook. If both "data" and "error" are null for
 * a given key, that means that request is ongoing
 *
 * The "clearCache"
 * @param keys Unique keys for making requests. Only one
 * request will be done for each and its returned
 * value cached for subsequent requests.
 * @param fetcher fetcher function to retrieve data. It will
 * be invoked exactly once for each different key passed
 * on the "keys" argument, passing the appropriate
 * key as first and only argument when invoked.
 * It is expected to return a Promise that resolves on the value to be cached.
 * Cached results can be accessed through "data" field
 * for the appropriate results[key] field on the
 * hook's returned object.
 */
const useCachedQueries = <T = any, E = Error>(
  keys: string[],
  fetcher: (key: string) => Promise<T>,
): { results: CachedResults<T, E>; clearCache: (keys?: string[]) => void } => {
  /* cacheManager is expected to reference the same object
  instance over this hook's entire lifetime. It is wrapped on an
  array so "setHandler" can be used to force updates when some
  fetcher has finished requesting */
  const [[cacheManager], setCacheManager] = useState<any>([
    {
      cachedResults: {},
      cancelUpdates: false,
    },
  ])

  useEffect(
    () => () => {
      /* This mutation is done to avoid updating the
      state when the parent component unmounts */
      cacheManager.cancelUpdates = true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  keys.forEach((key) => {
    if (!cacheManager.cachedResults[key]) {
      /** This sets the intermediate state
       * While we wait for fetcher to resolve.
       * Remember... data = null and error = null
       * signifies information is fetching (good for loading states etc)
       */
      cacheManager.cachedResults = {
        ...cacheManager.cachedResults,
        [key]: { data: null, error: null },
      }

      fetcher(key)
        .then((data) => ({ data, error: null }))
        .catch((error) => ({ data: null, error }))
        .then((newKeyData) => {
          cacheManager.cachedResults = {
            ...cacheManager.cachedResults,
            [key]: newKeyData,
          }
          if (!cacheManager.cancelUpdates) {
            /* This is done to force and update while maintaining
            the handler instance */
            setCacheManager([cacheManager])
          }
        })
    }
  })

  return {
    results: cacheManager.cachedResults,
    clearCache: (keysToClear?: string[]) => {
      const realKeysToClear =
        keysToClear && keysToClear.length
          ? keysToClear
          : Object.keys(cacheManager.cachedResults)
      const newCachedResults = { ...cacheManager.cachedResults }
      cacheManager.cachedResults = newCachedResults

      realKeysToClear.forEach((key) => {
        const cachedResult = newCachedResults[key]
        if (cachedResult && (cachedResult.data || cachedResult.error)) {
          delete newCachedResults[key]
        }
      })
      setCacheManager([cacheManager])
    },
  }
}

export default useCachedQueries
