import * as mathjs from 'mathjs'
import { DataSeriesTypeUnit, DataSeriesType } from 'shared/types'
import roundDecimals from 'shared/utils/roundDecimals'

interface UnitDefinition extends DataSeriesTypeUnit {
  mathUnit?: string
  mathUnitDefinition?: string | math.UnitDefinition
  convert?: (x: number) => number
}
interface DataSeriesTypeDefinition {
  label: string
  defaultUnitValue: string
  units: Array<UnitDefinition>
}

const powerLoadUnits: DataSeriesTypeDefinition = {
  label: 'Load',
  defaultUnitValue: 'kWh',
  units: [
    { value: 'MWh', label: 'MWh', mathUnit: 'MWh' },
    { value: 'kWh', label: 'kWh', mathUnit: 'kWh' },
  ],
}

/* MathJS already has joules (J) and BTU supported,
definitions are included for the rest based on 
"Therms", which in turn is based on BTUs */
const gasLoadUnits: DataSeriesTypeDefinition = {
  label: 'Load',
  defaultUnitValue: 'therm',
  units: [
    {
      value: 'therm',
      label: 'Therm',
      mathUnit: 'therm',
      mathUnitDefinition: {
        definition: mathjs.unit(10 ** 5, 'BTU'),
        aliases: ['therms'],
      },
    },
    { value: 'BTU', label: 'BTU', mathUnit: 'BTU' },
    {
      value: 'MMBTU',
      label: 'MMBTU',
      mathUnit: 'MMBTU',
      mathUnitDefinition: { definition: mathjs.unit(10 ** 6, 'BTU') },
      /* "MM" stands for "million"BTU */
    },
    {
      value: 'Decatherms',
      label: 'DTH',
      mathUnit: 'Decatherms',
      mathUnitDefinition: `10 therms`,
    },
    {
      value: 'CCF',
      label: 'CCF',
      mathUnit: 'CCF',
      mathUnitDefinition: `${roundDecimals(1 / 0.98039, 5)} therms`,
    },
    {
      value: 'MCF',
      label: 'MCF',
      mathUnit: 'MCF',
      mathUnitDefinition: `10 CCF`,
    },
    {
      value: 'M3',
      label: 'M3',
      mathUnit: 'M3',
      mathUnitDefinition: `${roundDecimals(1 / 2.77616, 5)} therms`,
    },
    {
      value: 'GJ',
      label: 'GJ',
      mathUnit: 'GJ',
    },
  ],
}

const temperatureUnits: DataSeriesTypeDefinition = {
  label: 'Temperature',
  defaultUnitValue: 'degF',
  units: [
    { value: 'degF', label: 'Fº', mathUnit: 'degF' },
    {
      value: 'degC',
      label: 'Cº',
      mathUnit: 'degC',
    },
  ],
}

const dimensionlessUnits: DataSeriesTypeDefinition = {
  label: 'Dimensionless',
  defaultUnitValue: 'dimensionless',
  units: [
    { label: 'Dimensionless', value: 'dimensionless' },
    {
      label: '%',
      value: 'percent',
      convert: (x) => x * 100,
    },
  ],
}

const customUnits: DataSeriesTypeDefinition = {
  label: 'Custom',
  defaultUnitValue: 'custom',
  units: [{ label: '', value: 'custom' }],
}

const definitions: {
  [key in DataSeriesType]: DataSeriesTypeDefinition
} = {
  [DataSeriesType.PowerLoad]: powerLoadUnits,
  [DataSeriesType.GasLoad]: gasLoadUnits,
  [DataSeriesType.Temperature]: temperatureUnits,
  [DataSeriesType.Dimensionless]: dimensionlessUnits,
  [DataSeriesType.Custom]: customUnits,
}

const unitToType = {
  [powerLoadUnits.defaultUnitValue]: DataSeriesType.PowerLoad,
  [gasLoadUnits.defaultUnitValue]: DataSeriesType.GasLoad,
  [temperatureUnits.defaultUnitValue]: DataSeriesType.Temperature,
  [dimensionlessUnits.defaultUnitValue]: DataSeriesType.Dimensionless,
}

/* New units on "MathJs" are created as needed */
Object.values(definitions).forEach((definition) => {
  const { units } = definition
  units.forEach(({ mathUnit, mathUnitDefinition }) => {
    if (mathUnit && mathUnitDefinition) {
      mathjs.createUnit(mathUnit, mathUnitDefinition, { prefixes: 'long' })
    }
  })
})

export function getDefinition(seriesType: DataSeriesType) {
  if (seriesType === DataSeriesType.GasLoad) {
    const { units, ...definition } = definitions[seriesType]
    const definitionUnits = units.map((item) => {
      const { mathUnitDefinition, ...unit } = item
      return unit
    })

    return { ...definition, units: definitionUnits }
  }
  return definitions[seriesType] ?? definitions[DataSeriesType.Custom]
}

export function getDataSeriesTypeFromUnit(unit: string) {
  return unitToType[unit] || DataSeriesType.Custom
}

export function getDataSeriesTypeInfo(
  seriesType: DataSeriesType,
  unitValue?: string,
) {
  const definition = getDefinition(seriesType)
  const { label, units, defaultUnitValue } = definition

  const defaultUnit = units.find(
    (x) => x.value === defaultUnitValue,
  ) as UnitDefinition

  let selectedUnit = defaultUnit
  if (unitValue) {
    selectedUnit = units.find((x) => x.value === unitValue) ?? selectedUnit
  }

  let converter: null | ((x: number) => number) = null

  if (selectedUnit !== defaultUnit) {
    const { convert, mathUnit: targetUnit } = selectedUnit
    const { mathUnit: initialUnit } = defaultUnit
    if (convert) {
      converter = convert
    } else if (initialUnit && targetUnit) {
      converter = (x) => mathjs.unit(x, initialUnit).toNumber(targetUnit)
    }
  }

  return {
    label,
    units,
    mathUnit: defaultUnit.mathUnit ?? '',
    defaultUnit,
    selectedUnit,
    converter,
  }
}
