import React, { useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import {
  getDruidDistinctAll,
  getMinMaxForColumns,
  MapeTypes,
} from 'modules/demand/common/apiClient'
import SideBarMenu from 'shared/components/SideBarMenu'
import { DateTime } from 'luxon'
import {
  Group,
  DataSeriesType,
  Dashboard,
  GeneralDateSelector,
  SelectedFilter,
  DataSeries,
  SingleSplitOption,
  DashboardConfigItem,
  SingleCalculationOption,
  WeatherTimeSeries,
  DynamicColumnsConfig,
  Calculation,
  WeatherStation,
  Visualization,
} from 'shared/types'
import {
  fetchDataForMape,
  ForecastFetchingArgument,
  forecastsDataSeriesHandlers,
  getFetchingArgumentCommonValues,
  MapeFetchingArgument,
  weatherDataSeriesHandlers,
  WeatherFetchingArgument,
} from 'modules/demand/common/helpers'
import DraggableMenu, {
  DraggableInnerMenuProps,
} from 'shared/components/DraggableMenu'
import { getLoadDataSeriesTypeByCommodity } from 'shared/helpers'
import BaseDashboard, {
  CardsDataSeriesManager,
  BuildCalculatorDataSeries,
} from 'shared/components/BaseDashboard/BaseDashboard'
import { HandleSave } from 'shared/pages/DashboardDetail'
import DateSelector from 'shared/components/DateSelector'
import { mergeDataSeries } from 'shared/utils/dataSeriesHelpers'
import { setDataSeriesData } from 'shared/utils/dataSeriesDragAndDrop'
import { getDataSeriesTypeFromUnit, getDefinition } from 'shared/common/units'
import { calculationOptions } from 'shared/common/dashboardOptions'
import useTimeAndDimensionFilters from 'shared/hooks/useTimeAndDimensionFilters'
import { getSelectedOptionLabel } from 'shared/components/Calculator/Calculator'
import { RangeFilter } from 'shared/hooks/useRangeFilters'
import useCancellableRequests from 'shared/hooks/useCancellableRequests'
import { AxiosRequestConfig } from 'axios'
import WeatherMenu from '../components/WeatherMenu'
import useDates from '../hooks/useDates'

enum TimeSeries {
  Actual = 'actual',
  Forecast = 'forecast',
  Backcast = 'backcast',
}

interface ManagerForecastArgument {
  kind: 'forecast'
  argument: ForecastFetchingArgument
}

interface ManagerWeatherArgument {
  kind: 'weather'
  argument: WeatherFetchingArgument
}

interface ManagerMapeArgument {
  kind: 'mape'
  argument: MapeFetchingArgument
}

export type CalculationTypes = 'load' | 'meterCount'

/**
 * Creates an appropriate card manager for the dashboard for a given context
 */
function createManager(
  options: {
    datasource: string
    dateRange: { from: DateTime; to: DateTime } | null
    selectedFilters: Array<SelectedFilter>
    commodityType: string
    selectedTimezone: string
    rangeFilters?: Array<RangeFilter>
    dynamicColumnsConfig?: DynamicColumnsConfig | undefined
  },
  config: AxiosRequestConfig,
): CardsDataSeriesManager<
  ManagerForecastArgument | ManagerWeatherArgument | ManagerMapeArgument
> {
  const {
    datasource,
    dateRange,
    selectedFilters,
    commodityType,
    selectedTimezone,
    rangeFilters,
    dynamicColumnsConfig,
  } = options
  const forecastsDataProvider = forecastsDataSeriesHandlers.createDataProvider(
    {
      selectedFilters,
      datasource,
      dateRange,
      isPerformance: true,
      type: getLoadDataSeriesTypeByCommodity(commodityType),
      timezone: selectedTimezone,
      rangeFilters,
      dynamicColumnsConfig,
    },
    config,
  )
  const weatherDataProvider = weatherDataSeriesHandlers.createDataProvider(
    {
      dateRange,
      timezone: selectedTimezone,
    },
    config,
  )
  return {
    getArgumentToFetch(card, dataSeries) {
      let fetchingArgument: ['mape' | 'weather' | 'forecast', any]
      switch (dataSeries.type) {
        case DataSeriesType.Dimensionless:
          {
            const argument: MapeFetchingArgument = {
              ...getFetchingArgumentCommonValues(
                card,
                dataSeries,
                selectedFilters,
                selectedTimezone,
                rangeFilters,
              ),
              type: dataSeries.extras?.mapeType,
            }
            fetchingArgument = ['mape', argument]
          }
          break
        case DataSeriesType.Temperature:
          fetchingArgument = [
            'weather',
            weatherDataProvider.getArgumentToFetch(card, dataSeries),
          ]
          break
        default:
          fetchingArgument = [
            'forecast',
            forecastsDataProvider.getArgumentToFetch(card, dataSeries),
          ]
      }
      const [kind, argument] = fetchingArgument
      if (argument === null) {
        return null
      }
      return { kind, argument }
    },
    fetchData(fetchingArgument) {
      switch (fetchingArgument.kind) {
        case 'mape':
          return fetchDataForMape(
            {
              filterColumns: fetchingArgument.argument.selectedFilters,
              group: fetchingArgument.argument.group,
              mapeType: fetchingArgument.argument.type,
              datasource,
              splitIdentifier: fetchingArgument.argument.splitIdentifier,
              dateRange,
              timezone: selectedTimezone,
              rangeFilters,
            },
            config,
          )
        case 'weather':
          return weatherDataProvider.fetchData(fetchingArgument.argument)
        default:
          return forecastsDataProvider.fetchData(fetchingArgument.argument)
      }
    },
    correctCardDataSeries: (card, dataSeries) => dataSeries,
    mergeGroupedFetchedData: (
      keyExpression,
      data,
      parsedKeys,
      dataSeries,
      card,
    ) => {
      const newData = parsedKeys.map((key, index) => {
        if (key.kind === 'weather' && weatherDataProvider.prepareFetchedData) {
          return weatherDataProvider.prepareFetchedData(
            data[index],
            card,
            dataSeries[index],
          )
        }
        return data[index]
      })
      const splitIdentifiersSet = new Set<undefined | string>()
      parsedKeys.forEach((k) => {
        if (k.kind === 'weather') {
          splitIdentifiersSet.add(undefined)
        } else {
          splitIdentifiersSet.add(k.argument.splitIdentifier)
        }
      })
      const splitIdentifiersArr = Array.from(splitIdentifiersSet)
      if (splitIdentifiersArr.length > 1) {
        throw new Error('Dataseries cannot be split')
      }
      return mergeDataSeries(
        keyExpression,
        newData,
        card.group,
        splitIdentifiersArr[0],
      )
    },
    prepareFetchedData(fetchedData, card, dataSeries) {
      if (dataSeries.groupedItems) {
        return fetchedData
      }
      switch (dataSeries.type) {
        case DataSeriesType.Dimensionless:
          return fetchedData
        case DataSeriesType.Temperature:
          return (
            weatherDataProvider.prepareFetchedData?.(
              fetchedData,
              card,
              dataSeries,
            ) ?? fetchedData
          )
        default:
          return (
            forecastsDataProvider.prepareFetchedData?.(
              fetchedData,
              card,
              dataSeries,
            ) ?? fetchedData
          )
      }
    },
    key: dateRange,
  }
}

const timeSeriesInnerMenu: DraggableInnerMenuProps[] = [
  {
    id: 'dataSeriesMenuOptions',
    isCollapsible: false,
    options: [
      {
        id: TimeSeries.Actual,
        title: 'Stitched Actual',
      },
      {
        id: TimeSeries.Forecast,
        title: 'Official Forecast',
      },
      {
        id: TimeSeries.Backcast,
        title: 'Backcast',
      },
    ],
  },
]

const metricsInnerMenu: DraggableInnerMenuProps[] = [
  {
    id: 'mapeMenuOptions',
    isCollapsible: false,
    options: [
      {
        id: MapeTypes.FORECAST_MAPE,
        title: 'Forecast MAPE % ',
      },
      {
        id: MapeTypes.BACKCAST_MAPE,
        title: 'Backcast MAPE %',
      },
    ],
  },
]

interface Props {
  datasource: string
  filtersDefinitions: DashboardConfigItem['filters']
  rangeFiltersDefinition: DashboardConfigItem['rangeFilters']
  savedDashboardInfo: Dashboard
  weatherStations: Array<WeatherStation>
  timeInterval: number
  dynamicColumnsConfig?: DynamicColumnsConfig
  onSave: HandleSave
}

export const useShortTermPerformance = ({
  datasource,
  filtersDefinitions,
  rangeFiltersDefinition,
  savedDashboardInfo,
  weatherStations,
  timeInterval,
  dynamicColumnsConfig,
}: Omit<Props, 'onSave'>) => {
  const commodityType = savedDashboardInfo.commodity.name

  const defaultFrom: GeneralDateSelector = savedDashboardInfo.from || {
    quantity: -1,
    multiplier: 'months',
    identifier: 'today',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const defaultTo: GeneralDateSelector = savedDashboardInfo.to || {
    quantity: 0,
    multiplier: 'days',
    identifier: 'today',
    dateType: 'relative',
    value: DateTime.local(),
  }

  const dates = useDates(datasource, defaultFrom, defaultTo)

  const filters = useTimeAndDimensionFilters(
    savedDashboardInfo.filters,
    timeInterval,
    () =>
      getDruidDistinctAll(
        datasource,
        filtersDefinitions.map((e) => e.name),
      ),
    filtersDefinitions,
    rangeFiltersDefinition,
    () =>
      getMinMaxForColumns(
        datasource,
        rangeFiltersDefinition?.map((e) => e.name) ?? [],
      ),
    savedDashboardInfo.rangeFilters,
    savedDashboardInfo.timeFilters,
  )

  const { dimensionFilters, timeFilters, rangeFilters } = filters

  const splitOptions = useMemo(() => {
    return dimensionFilters.filters.map(
      (filter) => [filter.name, filter.title] as SingleSplitOption,
    )
  }, [dimensionFilters.filters])

  const calculatorDataSeriesOptions = useMemo(() => {
    const dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
    const dataSeriesOptions = timeSeriesInnerMenu[0].options.map((e) => {
      return {
        id: `forecast-${e.id}`,
        title: 'Load',
        subtitle: e.title,
        unit: getDefinition(dataSeriesType).defaultUnitValue,
        tagOptions: calculationOptions.map((calc) => ({
          value: calc[0],
          label: calc[1],
        })),
      }
    })
    const dataSeriesCountOptions = timeSeriesInnerMenu[0].options.map((e) => {
      return {
        id: `meterCount-${e.id}`,
        title: 'Meter Count',
        subtitle: e.title,
        unit: getDefinition(DataSeriesType.Dimensionless).defaultUnitValue,
        tagOptions: [
          {
            value: Calculation.CountDistinct,
            label: 'countd',
          },
        ],
      }
    })
    const weatherOptions = weatherStations
      .map((station) => {
        return Object.values(WeatherTimeSeries)
          .map((weatherType) => {
            return {
              id: `weather-${station.id}-${weatherType}`,
              title: `Weather ${station.label}`,
              subtitle: `${weatherType}`,
              unit: getDefinition(DataSeriesType.Temperature).defaultUnitValue,
              tagOptions: calculationOptions.map((calc) => ({
                value: calc[0],
                label: calc[1],
              })),
            }
          })
          .flat()
      })
      .flat()
    return [...dataSeriesOptions, ...dataSeriesCountOptions, ...weatherOptions]
  }, [commodityType, weatherStations])

  const [selectedTimezone, setTimezone] = useState<string>(
    savedDashboardInfo?.timezone || DateTime.local().zoneName,
  )

  const [isHourEndingSelected, setIsHourEndingSelected] = useState<Boolean>(
    savedDashboardInfo?.isHourEndingSelected || false,
  )

  const [calculationType, setCalculationType] = useState<CalculationTypes>(
    'load',
  )

  const [selectedStation, setWeatherStation] = useState(weatherStations[0])

  const buildCalculatedDataSeries: BuildCalculatorDataSeries = (
    calculator,
    referenceSeries,
  ) => {
    const newExpressionDataIdMap: object = {}
    const dataSeriesCombined: DataSeries[] = []
    let hasWeather = false
    Object.entries(calculator.expressionDataIdMap).forEach(([k, val]) => {
      const [type, id, weatherType] = val.id.split('-')
      const option = calculatorDataSeriesOptions.find((o) => o.id === val.id)
      const info = {
        id: '',
        label: '',
      }
      let dataSeriesType

      switch (type) {
        case 'forecast':
          info.id = id
          info.label = option?.title || ''
          dataSeriesType = getLoadDataSeriesTypeByCommodity(commodityType)
          break
        case 'meterCount':
          info.id = id
          info.label = option?.title || ''
          break
        default:
          info.id = `${weatherType}-${id}-temp`
          info.label = `${id} ${weatherType}`
          hasWeather = true
          dataSeriesType = DataSeriesType.Temperature
          break
      }

      newExpressionDataIdMap[k] = id
      const selectedCalculation = val.selectedOption
        ? ([val.selectedOption, val.selectedOption] as SingleCalculationOption)
        : undefined
      dataSeriesCombined.push({
        id: info.id,
        label: info.label,
        calculationOptions: type === 'meterCount' ? [] : calculationOptions,
        selectedCalculation,
        splitOptions,
        selectedSplit: null,
        type: dataSeriesType,
        selectedFilters: dimensionFilters.selectedFilters,
        extras:
          type === 'weather'
            ? {
                station: id,
                stationLabel: id,
                series: weatherType,
              }
            : {},
        rangeFilters: rangeFilters.filters,
      })
    })
    return {
      ...referenceSeries,
      id: calculator.uid,
      label: calculator.name,
      splitOptions: hasWeather ? undefined : splitOptions,
      selectedSplit: null,
      type: getDataSeriesTypeFromUnit(calculator.unit || ''),
      customUnit: calculator.unit || '',
      groupedItems: {
        expression: calculator.expression,
        expressionDataIdMap: calculator.expressionDataIdMap,
        expressionScope: newExpressionDataIdMap,
        dataSeries: dataSeriesCombined,
        key: calculator.expression,
      },
    }
  }

  const createCancellableRequestConfig = useCancellableRequests()

  const manager = useMemo<
    CardsDataSeriesManager<
      ManagerForecastArgument | ManagerWeatherArgument | ManagerMapeArgument
    >
  >(
    () =>
      createManager(
        {
          datasource,
          dateRange: dates.dateRange,
          selectedFilters: [
            ...dimensionFilters.selectedFilters,
            ...timeFilters.selectedFilters,
          ],
          commodityType,
          selectedTimezone,
          rangeFilters: rangeFilters.filters,
          dynamicColumnsConfig,
        },
        createCancellableRequestConfig(),
      ),
    [
      createCancellableRequestConfig,
      datasource,
      dates.dateRange,
      dimensionFilters.selectedFilters,
      timeFilters.selectedFilters,
      commodityType,
      selectedTimezone,
      rangeFilters.filters,
      dynamicColumnsConfig,
    ],
  )

  return {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    calculationType,
    setCalculationType,
    selectedStation,
    setWeatherStation,
  }
}

/**
 * Short Term Performance page component
 */
const ShortTermPerformance = ({
  datasource,
  filtersDefinitions,
  rangeFiltersDefinition,
  savedDashboardInfo,
  weatherStations,
  timeInterval,
  onSave,
  dynamicColumnsConfig,
}: Props) => {
  const commodityType = savedDashboardInfo.commodity.name

  const defaultCards = useMemo(() => {
    return dynamicColumnsConfig?.meterIdColumn
      ? [
          ...savedDashboardInfo.cards,
          {
            id: 'meterMapID',
            title: 'Meter Map',
            dataSeries: [],
            visualization: Visualization.MeterMap,
            group: dynamicColumnsConfig.meterIdColumn,
          },
        ]
      : savedDashboardInfo.cards
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    dates,
    filters,
    splitOptions,
    manager,
    calculatorDataSeriesOptions,
    buildCalculatedDataSeries,
    selectedTimezone,
    setTimezone,
    isHourEndingSelected,
    setIsHourEndingSelected,
    calculationType,
    setCalculationType,
    selectedStation,
    setWeatherStation,
  } = useShortTermPerformance({
    datasource,
    filtersDefinitions,
    rangeFiltersDefinition,
    savedDashboardInfo,
    weatherStations,
    timeInterval,
    dynamicColumnsConfig,
  })

  const {
    min,
    max,
    isInitializing,
    from,
    to,
    setFromDate,
    setToDate,
    dateRange,
  } = dates

  const {
    dimensionFilters,
    timeFilters,
    rangeFilters,
    onChange: onFilterChange,
    isInitializing: areFiltersInitializing,
  } = filters

  return (
    <BaseDashboard
      dashboardId={savedDashboardInfo.dashboardId}
      calculatorLabelAccessor={(option) => {
        const selectedOptionLabel = getSelectedOptionLabel(option)
        return [selectedOptionLabel, option.title, option.subtitle]
          .filter((e) => e)
          .join(' ')
      }}
      calculatorDataSeriesOptions={calculatorDataSeriesOptions}
      buildCalculatorDataSeries={buildCalculatedDataSeries}
      meterGrouping={dynamicColumnsConfig?.meterIdColumn}
      calculators={savedDashboardInfo.calculators}
      isInitializing={isInitializing || areFiltersInitializing}
      isLoading={!dateRange}
      cardsDataSeriesManager={manager}
      defaultCards={defaultCards}
      filtersDefinitions={filtersDefinitions}
      areFiltersLoading={false}
      filters={dimensionFilters.filters}
      selectedTimeFilters={timeFilters.selectedFilters}
      timeFilters={timeFilters.filters}
      rangeFilters={rangeFilters.filters}
      onRangeFilterChange={rangeFilters.onChange}
      onFilterChange={onFilterChange}
      timeInterval={timeInterval}
      defaultNewCardGrouping={Group.Hourly}
      selectedTimezone={selectedTimezone}
      onTimezoneChange={(e, newTimezone) => setTimezone(newTimezone)}
      isHourEndingSelected={isHourEndingSelected}
      onIsHourEndingSelectedChange={(e, newIsHourEndingSelected) =>
        setIsHourEndingSelected(newIsHourEndingSelected)
      }
      onSave={(e, cards, extras) =>
        onSave(e, {
          cards,
          ...extras,
          filters: dimensionFilters.filters,
          rangeFilters: rangeFilters.filters.map(({ name, value }) => ({
            name,
            value,
          })),
          timeFilters: timeFilters.filters,
          timezone: selectedTimezone,
          isHourEndingSelected,
          from: from || undefined,
          to: to || undefined,
        })
      }
      extraOptions={
        min &&
        max &&
        from &&
        to && (
          <>
            <DateSelector
              startData={min}
              endData={max}
              quantity={from.quantity}
              multiplier={from.multiplier}
              identifier={from.identifier}
              dateType={from.dateType}
              value={from.value}
              onChange={setFromDate}
              label={`Start (${
                from.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
            <DateSelector
              startData={min}
              endData={max}
              quantity={to.quantity}
              multiplier={to.multiplier}
              identifier={to.identifier}
              dateType={to.dateType}
              value={to.value}
              onChange={setToDate}
              label={`End (${
                to.dateType === 'relative' ? 'Relative' : 'Fixed'
              })`}
            />
          </>
        )
      }
    >
      <SideBarMenu title="time series" isLoading={!dateRange}>
        {dynamicColumnsConfig?.meterIdColumn && (
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select
              value={calculationType}
              name="seriesType"
              onChange={(e) => {
                setCalculationType(e.target.value as CalculationTypes)
              }}
              margin="dense"
            >
              {[
                { label: 'Load', value: 'load' as CalculationTypes },
                {
                  label: 'Meter Count',
                  value: 'meterCount' as CalculationTypes,
                },
              ].map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        <DraggableMenu
          isCollapsible={false}
          innerMenus={timeSeriesInnerMenu}
          onDrag={(e, metadata) => {
            forecastsDataSeriesHandlers.prepareDataTransfer(
              e.dataTransfer,
              metadata.id,
              metadata.title,
              splitOptions,
              calculationType === 'meterCount'
                ? DataSeriesType.Dimensionless
                : getLoadDataSeriesTypeByCommodity(commodityType),
              undefined,
              undefined,
              undefined,
              undefined,
              calculationType === 'meterCount',
            )
          }}
        />
      </SideBarMenu>
      <SideBarMenu title="metrics">
        <DraggableMenu
          title="MAPE"
          innerMenus={metricsInnerMenu}
          defaultMenuIsOpen
          onDrag={(e, metadata) => {
            setDataSeriesData(e.dataTransfer, {
              id: `${metadata.id}_mape`,
              type: DataSeriesType.Dimensionless,
              label: `${metadata.title}`,
              splitOptions,
              selectedSplit: null,
              extras: {
                mapeType: metadata.id,
              },
            })
          }}
        />
      </SideBarMenu>
      <WeatherMenu
        showActual
        showForecast
        showNormal
        stations={weatherStations}
        onDrag={(e, { id, series, station }) => {
          weatherDataSeriesHandlers.prepareDatatransfer(
            e.dataTransfer,
            id,
            `${station.label} ${series}`,
            { station: station.id, series, stationLabel: station.label },
          )
        }}
        selectedStation={selectedStation}
        onStationChange={(e, station) => setWeatherStation(station)}
      />
    </BaseDashboard>
  )
}

export default ShortTermPerformance
