import { useState, useEffect, useContext } from 'react'
import { DashboardConfigItem } from 'shared/types'
import DashboardsConfigsContext from 'shared/contexts/DashboardsConfigsContext'

const useDashboardConfigItems = (dashboardType?: string) => {
  const dashboardsConfigsContext = useContext(DashboardsConfigsContext)
  const [
    dashboardConfigItems,
    setDashboardConfigItems,
  ] = useState<Array<DashboardConfigItem> | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (dashboardsConfigsContext.isLoading) {
      setIsLoading(true)
    }
    const config = dashboardsConfigsContext.configs.find(
      (c) => c.dashboardType === dashboardType,
    )
    setDashboardConfigItems(config?.items || null)
    setIsLoading(false)
  }, [dashboardsConfigsContext, dashboardType])

  return {
    dashboardConfigItems,
    isLoading,
  }
}
export default useDashboardConfigItems
