import { DataSeries } from 'shared/types'
import generateDragAndDropUtilities from './generateDragAndDropUtilities'

const {
  setDataItem,
  getDataItemsSuffixes,
  getDataItems,
} = generateDragAndDropUtilities(
  'x-data-series-',
  (dataSeries: DataSeries) => dataSeries.type,
)

export {
  setDataItem as setDataSeriesData,
  getDataItemsSuffixes as getDataSeriesTypes,
  getDataItems as getDataSeriesData,
}
