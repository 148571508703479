import React, { ChangeEvent, ReactEventHandler, useState } from 'react'
import {
  Button,
  Dialog,
  Theme,
  makeStyles,
  Typography,
  Divider,
  MenuItem,
  styled,
  TextField,
  Box,
  DialogContent,
  withStyles,
} from '@material-ui/core'
import { ValidAdjustmentCalculations } from '../../../../../shared/types'

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0, 1, 0),
  },
}))(DialogContent)

const StyledInput = styled(TextField)(() => ({
  display: 'flex',
  width: '100%',
  marginTop: 0,
}))

const StyledSelect = styled(TextField)(() => ({
  margin: 0,
  width: '550px',
}))

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    width: '600px',
    minHeight: '200px',
    padding: theme.spacing(3),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  footer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    '& > :first-child': {
      marginRight: 'auto',
    },
  },
  header: {},
  label: {
    fontSize: '14px',
    fontWeight: 500,
  },
  selectLabel: {
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
  margin: {
    marginBottom: theme.spacing(0.5),
  },
  cancel: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
}))

interface ModelData {
  calculation: ValidAdjustmentCalculations
  parsedPastedData: number[]
}

export interface Props {
  onClose: ReactEventHandler
  selectedRowCount: number
  onConfirm: (data: ModelData) => void
}

const PasteFromClipDialog = ({
  onClose,
  onConfirm,
  selectedRowCount,
}: Props) => {
  const classes = useStyles()

  const [isParsing, setIsParsing] = useState<boolean>(false)
  const [pastedData, setPastedData] = useState<string>('')
  const [error, setError] = useState<string>('')

  const [model, setModel] = useState<ModelData>({
    calculation: ValidAdjustmentCalculations.Addition,
    parsedPastedData: [],
  })

  const { calculation, parsedPastedData } = model

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name: fieldName, value } = event.target
    setModel((prevModel) => ({ ...prevModel, [fieldName]: value }))
  }

  const handlePastedDataChange = (e) => {
    setError('')
    const { value } = e.target
    setPastedData(value)
    setIsParsing(true)
    // split on newline and remove anything that is not numeric or a decimal point
    const newParsedData = value
      .split('\n')
      .map((val) => parseFloat(val.replace(/[^0-9.-]/g, '')))
      .filter((val) => !Number.isNaN(val))
    if (newParsedData.length !== selectedRowCount) {
      setError('The pasted data does not match the amount of rows selected')
    }
    setModel((prevModel) => ({ ...prevModel, parsedPastedData: newParsedData }))
    setIsParsing(false)
  }

  const isValid = () => {
    return (
      !isParsing &&
      calculation &&
      parsedPastedData.length > 0 &&
      parsedPastedData.length === selectedRowCount
    )
  }

  const handleConfirm = () => {
    onConfirm({
      calculation,
      parsedPastedData,
    })
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      classes={{ paperWidthMd: classes.dialogPaper }}
    >
      <header className={classes.header}>
        <>
          <Typography variant="h6" className={classes.margin}>
            Paste From Spreadsheet
          </Typography>
          <Typography variant="caption">
            {selectedRowCount > 0
              ? `This action will apply to all selected ${
                  selectedRowCount > 1 ? 'items' : 'item'
                } items`
              : 'This action will apply 0 items'}
          </Typography>
        </>
      </header>
      <Divider light className={classes.margin} />
      <StyledDialogContent>
        {selectedRowCount > 0 ? (
          <>
            <Typography variant="overline" className={classes.margin}>
              Select the adjustment calculation to apply
            </Typography>
            <Box display="flex" style={{ marginBottom: '8px' }}>
              <StyledSelect
                data-testid="paste-type-select"
                size="small"
                select
                margin="dense"
                variant="outlined"
                name="calculation"
                value={calculation}
                onChange={handleChange}
              >
                <MenuItem data-testid="select-menu-item" value={undefined}>
                  <em>None</em>
                </MenuItem>
                <MenuItem
                  data-testid="select-menu-item"
                  value={ValidAdjustmentCalculations.Addition}
                >
                  Add to Forecast
                </MenuItem>
                <MenuItem
                  data-testid="select-menu-item"
                  value={ValidAdjustmentCalculations.Percent}
                >
                  Percent of Forecast
                </MenuItem>
                <MenuItem
                  data-testid="select-menu-item"
                  value={ValidAdjustmentCalculations.Multiplication}
                >
                  Multiply Forecast by
                </MenuItem>
                <MenuItem
                  data-testid="select-menu-item"
                  value={ValidAdjustmentCalculations.Division}
                >
                  Divide Forecast By
                </MenuItem>
                <MenuItem
                  data-testid="select-menu-item"
                  value={ValidAdjustmentCalculations.Custom}
                >
                  Custom Value
                </MenuItem>
              </StyledSelect>
            </Box>
            <Typography variant="overline" className={classes.margin}>
              Paste values from spreadsheet here
            </Typography>
            <Box display="flex">
              <StyledInput
                data-testid="paste-input"
                name="pasted"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                error={!!error}
                multiline
                rows={4}
                value={pastedData}
                onChange={handlePastedDataChange}
                disabled={isParsing}
              />
            </Box>
            <Typography variant="caption" component="div" color="error">
              {error}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle2" className={classes.margin}>
            You must have selected at least one element in order to apply any
            adjustments
          </Typography>
        )}
      </StyledDialogContent>
      <footer className={classes.footer}>
        {selectedRowCount > 0 ? (
          <>
            <Button
              data-testid="cancel"
              onClick={onClose}
              variant="outlined"
              className={classes.cancel}
            >
              Cancel
            </Button>
            <Button
              data-testid="confirm"
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={!isValid()}
            >
              Confirm
            </Button>
          </>
        ) : (
          <Button
            data-testid="cancel-no-rows"
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            I Understand
          </Button>
        )}
      </footer>
    </Dialog>
  )
}

export default PasteFromClipDialog
