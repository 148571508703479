import React, { ReactNode, MouseEventHandler } from 'react'
import { styled, Theme } from '@material-ui/core/styles'
import { AddCircleRounded } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { Save } from 'react-feather'

/**
 *
 * Builds a page layout with help of grids
 *
 */
const PageLayout = styled('div')(({ theme }) => ({
  minHeight: '90vh',
  display: 'grid',
  gridTemplateRows: 'auto 60px 1fr',
  gridTemplateColumns: '15% auto 15%',
  gridTemplateAreas: `
  "left-menu header right-menu"
  "left-menu options right-menu"
	"left-menu body right-menu"`,
  gap: theme.spacing(4, 4),
  padding: theme.spacing(2),
}))

/**
 *
 * Page header - shows the page name (long term forecast) and is of fixed 70px
 *
 */
export const Header = styled('div')(() => ({
  gridArea: 'header',
}))

/**
 * Common code and behavior for Side menus
 *  */
const SideMenu = styled(({ children, className }) => (
  <div className={className}>
    <div>{children}</div>
  </div>
))<Theme, { position: 'left' | 'right' }>(({ theme, position }) => ({
  gridArea: `${position}-menu`,
  position: 'relative',
  zIndex: 100,
  paddingTop: '70px',
  '& > div': {
    position: 'sticky',
    top: theme.spacing(1),
    maxHeight: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  '& > div > *': {
    marginBottom: theme.spacing(4),
  },
}))

/**
 *
 * SideLeftMenuContainers - create a grid inside main page grid for left side bar menu
 *
 */
export const LeftMenu = ({ children }: { children: ReactNode }) => (
  <SideMenu position="left">{children}</SideMenu>
)

/**
 *
 * SideRightMenuContainers - create a grid inside main page grid for right side bar menu
 *
 */
export const RightMenu = ({ children }: { children: ReactNode }) => (
  <SideMenu position="right">{children}</SideMenu>
)

/**
 *
 * Options - this creates a grid that holds date and other options
 *
 */
export const Options = styled('div')(() => ({
  gridArea: 'options',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}))

export const LeftSideOptions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& > *:not(:last-child)': {
    marginRight: theme.spacing(2),
  },
  '& > *': {
    minWidth: '150px',
  },
}))

interface StyledSaveIconProps {
  disabled?: boolean
  theme: Theme
}

export const StyledSaveIcon = styled(Save)(
  ({ theme, disabled }: StyledSaveIconProps) => ({
    backgroundColor: disabled
      ? theme.palette.grey[400]
      : theme.palette.secondary.main,
    color: theme.palette.grey[100],
    borderRadius: '50%',
    padding: '10px',
    marginLeft: 'auto',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
  }),
)

/**
 *
 * ChartArea -  this is the actual chart area in the middle , build up of n / 2 grid
 * where n is number of rows and 2 is number of columns.
 * you just need to pass number of charts to this and it will build a middle grid
 *
 */
export const Body = styled('div')(({ theme }) => ({
  gridArea: 'body',
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    marginBottom: theme.spacing(4),
  },
  overflow: 'hidden',
}))

/**
 *
 * ChartArea -  this is the actual chart area in the middle , build up of n / 2 grid
 * where n is number of rows and 2 is number of columns.
 * you just need to pass number of charts to this and it will build a middle grid
 *
 */
export const HorizontalBody = styled('div')(({ theme }) => ({
  gridArea: 'body',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

/**
 *
 * CreateNewCard - always renders in the end by looking at the total number of charts to render
 *
 */
const CreateNewCard = styled(Button)({
  height: '50px',
  borderWidth: `1.5px`,
  borderStyle: `dashed`,
  gridColumn: '1/3',
})

const AddMoreChartIcon = styled(AddCircleRounded)(() => ({
  verticalAlign: 'middle',
  display: 'inline-flex',
}))

export const CreateNewCardButton = ({
  onClick,
}: {
  onClick: MouseEventHandler
}) => (
  <CreateNewCard variant="outlined" color="primary" onClick={onClick}>
    <AddMoreChartIcon /> Add More Visualization
  </CreateNewCard>
)

export default PageLayout
