import { useMemo } from 'react'
import FlexSearch, { CreateOptions } from 'flexsearch'

type Options = Array<any>
type Query = string

/**
 * Tiny hook that helps uses the FlexSearch package to search through data.
 * Please reference FlexSearch documentation for additional information on settings
 * https://github.com/nextapps-de/flexsearch#docs
 */
const useFlexSearch = (
  options: Options,
  query: Query,
  settings: CreateOptions,
) => {
  // Search index is used to fuzzysearch items
  const index = useMemo(() => {
    const settingsCopy = { ...settings }
    settingsCopy.profile = settings.profile ?? 'speed'
    return FlexSearch.create(settingsCopy).add(options)
  }, [options, settings])

  const results = useMemo(() => {
    return query ? index.search(query) : options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, options])
  return { index, results }
}

export default useFlexSearch
