import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import Overview from 'base/pages/Overview'
import MenuHeader from 'shared/components/MenuHeader'

const menuOptions = [
  {
    label: 'Home',
    path: '/',
    component: () => <div>Home</div>,
  },
  {
    label: 'Overview',
    path: '/overview',
    component: Overview,
  },
]

export const MenuNav = () => {
  return <MenuHeader routes={menuOptions} />
}

const Menu = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${menuOptions[0].path}`} />
      </Route>
      {menuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default Menu
