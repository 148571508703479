import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as DemandAnalyticsIcon } from 'icons/demand-icon.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'
import DemandAnalyticsDashboard from 'modules/demand/products/demandAnalytics/DemandAnalyticsDashboard'

const loadForecatingMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: DemandAnalyticsDashboard,
  },
]

export const DemandAnalyticsMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Demand Analytics"
      color="demand"
      basePath={url}
      icon={<DemandAnalyticsIcon />}
      routes={loadForecatingMenuOptions}
    />
  )
}

const DemandAnalytics = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${loadForecatingMenuOptions[0].path}`} />
      </Route>
      {loadForecatingMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default DemandAnalytics
