import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { ReactComponent as DemandAnalyticsIcon } from 'icons/demand-icon.svg'
import ProductModuleHeader from 'shared/components/ProductModuleHeader'

const renewableManagementMenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    component: (<div> renewable Content In Development </div>) as any,
  },
]

export const RenewableManagementMenu = () => {
  const { url } = useRouteMatch()
  return (
    <ProductModuleHeader
      title="Renewable Management"
      color="demand"
      basePath={url}
      icon={<DemandAnalyticsIcon />}
      routes={renewableManagementMenuOptions}
    />
  )
}

const RenewableManagement = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Redirect to={`${url}${renewableManagementMenuOptions[0].path}`} />
      </Route>
      {renewableManagementMenuOptions.map((val) => (
        <Route
          path={`${url}${val.path}`}
          component={val.component}
          key={`${val.label}`}
        />
      ))}
    </Switch>
  )
}

export default RenewableManagement
