import React, { ReactElement, useEffect } from 'react'
import { Box, Typography, withStyles } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import {
  Adjustment,
  Forecast,
  Level,
  Market,
} from '../../../../modules/demand/products/loadForecast/components/LoadForecastTypes'
import SummaryListOptions from './SummaryListOptions'
import SummaryListItems from './SummaryListItems'

interface Props {
  items?: Forecast[] | Adjustment[]
  availableMarkets: Market[]
  availableLevels: Level[]
  isLoading?: boolean
  isInitialized: boolean
  hasData?: boolean
  onLoadMore: () => void
  canLoadMore?: boolean
  filteredLoadMore?: boolean
  onSelectMarkets: (markets: string[]) => void
  onSelectLevel: (level: string) => void
  selectedMarkets: string[]
  selectedLevel?: string
  renderCard: (item) => ReactElement
  renderCardSkeleton: (index) => ReactElement
}

export const CardList = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gridArea: 'body',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))(Box) as typeof Box

const StyledDiv = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const NoDataAvailable = () => (
  <StyledDiv>
    <Typography
      data-testid="no-data-available"
      variant="h6"
      color="textSecondary"
    >
      Sorry! No Settlement Level Data is available.
    </Typography>
  </StyledDiv>
)

const SummaryList = ({
  availableMarkets = [],
  availableLevels = [],
  items = [],
  onSelectMarkets,
  onSelectLevel,
  selectedMarkets = [],
  selectedLevel = '',
  isLoading,
  canLoadMore,
  onLoadMore,
  isInitialized,
  filteredLoadMore,
  hasData,
  renderCard,
  renderCardSkeleton,
}: Props) => {
  useEffect(() => {
    // Load the initial set when the component is loaded
    if (isInitialized && !hasData) {
      onLoadMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, isInitialized])

  return (
    <Box width="100%">
      <SummaryListOptions
        isInitialized={isInitialized}
        isLoading={isLoading}
        availableMarkets={availableMarkets}
        availableLevels={availableLevels}
        selectedMarkets={selectedMarkets}
        selectedLevel={selectedLevel}
        onSelectMarkets={onSelectMarkets}
        onSelectLevel={onSelectLevel}
      />
      {hasData && !availableLevels.length && <NoDataAvailable />}
      {availableLevels.length > 0 && (
        <SummaryListItems
          items={items}
          onLoadMore={onLoadMore}
          canLoadMore={canLoadMore}
          filteredLoadMore={filteredLoadMore}
          hasData={hasData}
          loading={isLoading}
          renderCard={renderCard}
          renderCardSkeleton={renderCardSkeleton}
        />
      )}
    </Box>
  )
}

export default SummaryList
