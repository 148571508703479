import React, { ReactNode, Children, ReactElement } from 'react'
import { Responsive, WidthProvider, Layout } from 'react-grid-layout'
import { LayoutMinusI } from 'shared/types'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface Props {
  /** Event handler when the layout changes. Layout changes on both resizing and moving elements. */
  onLayoutChange: (layoutByKey: LayoutByKey, layout: Layout[]) => void
  /**
   * Elements to resize and allow dragging.
   *
   * **IMPORTANT**: The layout for each element will be identified by the child's key, thus, if you care about mapping elements to a layout you need to pass a key (even when not mapping through an array)
   * */
  children: ReactNode
  /**
   * Exact same as react-grid-layout's draggableHandle... documentation is copied here for simpliciation:
   *
   * A CSS selector for tags that will act as the draggable handle. For example: `draggableHandle = '.MyDragHandleClassName'` If you forget the leading . (period) it will not work.
   * */
  draggableHandle?: string
  /** Default layout to use in case child does not contain a layout prop */
  defaultLayout?: LayoutMinusI
}

export type LayoutByKey = { [key: string]: Layout }

function getLayoutByKey(layout: Array<Layout>) {
  const layoutByKey: LayoutByKey = {}
  layout.forEach((elem) => {
    const id = elem.i.replace(/^.\$/, '')
    layoutByKey[id] = elem
  })
  return layoutByKey
}

/**
 * Simple wrapper around [react-grid-layout](https://github.com/STRML/react-grid-layout). This component allows users to drag and resize passed children. It is responsinve and tries to simplify its use by allowing only one event listener (`onLayoutChange`).
 *
 * Children can be passed a layout (see `Layout` props), otherwise a developer can provide a default layout. If no default layout is provided and children has no layout passed as a prop then this component provides a default layout that is intended to work best with `<DashboardCard />`.
 *
 * If a draggableHandle is passed only a child element that contains an elment with said CSS tag will be able to trigger a drag event. See react-grid-layout documentation for more information.
 */
const DraggableResizableGrid = ({
  children,
  draggableHandle,
  onLayoutChange,
  defaultLayout = {
    x: 0,
    /**
     * Always send to bottom. Works because we are vertically compacted by default
     * see for more info: https://github.com/STRML/react-grid-layout/wiki/Users-recipes#provide-an-initial-widthheight-to-new-items
     */
    y: Infinity,
    w: 12,
    h: 4,
    minW: 0,
  },
}: Props) => {
  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        // Per react-grid-layout documentation you must pass the classname with the period "."
        draggableHandle={draggableHandle}
        onLayoutChange={(currentLayout) => {
          /*
           * The i in layout === the key, however keys get renamed by react-grid-layout and appended a ".$".
           * Since currentLayout is an array AND we have to typically modify all children with this new layout
           * AND we cannot guarantee order, we give the developer a map of the layout for quick access.
           */
          const layoutByKey = getLayoutByKey(currentLayout)
          onLayoutChange(layoutByKey, currentLayout)
        }}
      >
        {children &&
          Children.map(children, (child) => {
            const modChild = child as ReactElement
            return (
              <div data-grid={modChild.props.layout || defaultLayout}>
                {child}
              </div>
            )
          })}
      </ResponsiveGridLayout>
    </>
  )
}

interface GridItemProps {
  // eslint-disable-next-line react/no-unused-prop-types
  layout?: LayoutMinusI
  children: ReactNode
}
/**
 * Helper wrapper component that provides an optional layout prop.
 * This is useful if a developer wants to use the DraggableResizeable Component and
 * wants to pass a layout without modifying a component that may not contain said prop.
 */
export const GridItem = ({ children }: GridItemProps) => {
  return <>{children}</>
}

export default DraggableResizableGrid
