import { useState, useEffect, useReducer } from 'react'
import { nanoid } from 'nanoid'
import {
  getDashboards,
  deleteDashboard,
  updateDashboardMeta,
  addNewDashboard,
  shareDashboard,
  shareOrgDashboard,
  unShareOrgDashboard,
  getSettingsFieldForBackend,
} from 'shared/apiClient'
import { NewDashboardModel } from 'shared/components/AddNewDashboardDialog'
import { Dashboard, DraggableResizableCard } from 'shared/types'

interface ActionState {
  isLoading: boolean
  actionResultSeverity: null | string
  actionError: null | Error
  httpErrorStatusCode?: number
}

const initialActionState: ActionState = {
  isLoading: false,
  actionResultSeverity: null,
  actionError: null,
}

type Action =
  | { type: 'loading' }
  | { type: 'success' }
  | { type: 'error'; error: Error; httpErrorStatusCode?: number }

const LOADING_ERROR = new Error('Working on previous action')

function actionReducer(state: ActionState, action: Action) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        actionResultSeverity: null,
        isLoading: true,
      }
    case 'success':
      return {
        isLoading: false,
        actionResultSeverity: 'success',
        actionError: null,
      }
    case 'error':
      return {
        isLoading: false,
        actionResultSeverity: 'error',
        actionError: action.error,
        httpErrorStatusCode: action.httpErrorStatusCode,
      }
    default:
      return state
  }
}

function parseError(error) {
  const errorObj = { error, httpErrorStatusCode: undefined }
  if (error.isAxiosError) {
    errorObj.error = error.message
    errorObj.httpErrorStatusCode = error.response.status
  }
  return errorObj
}

/**
 * Hook that allows users to get, delete, update, and create dashboards. Provides appropriate functions and an action state that can be used to determine the action's state (loading, success, error), where an action = delete, update, and create. Takes in an optional dashboardType to fetch dashboards by it. If no dashboardType is passed then all dashboards are fetched.
 */
const useDashboards = (dashboardType?) => {
  const [dashboards, setDashboards] = useState<Array<Dashboard>>([])

  const [actionState, actionDispatch] = useReducer(
    actionReducer,
    initialActionState,
  )

  const [isFetchingDashboards, setIsFetchingDashboards] = useState<boolean>(
    false,
  )

  useEffect(() => {
    setIsFetchingDashboards(true)
    getDashboards(dashboardType)
      .then((d) => {
        setDashboards(d)
      })
      .finally(() => {
        setIsFetchingDashboards(false)
      })
  }, [dashboardType])

  const handleDeleteDashboard = (
    id: string,
    cb?: (actionState: ActionState) => void,
  ) => {
    if (actionState.isLoading) {
      throw LOADING_ERROR
    }
    actionDispatch({ type: 'loading' })
    deleteDashboard(id)
      .then(() => {
        setDashboards((prev) =>
          prev.filter(({ dashboardId }) => dashboardId !== id),
        )
        actionDispatch({ type: 'success' })
      })
      .catch((error) => {
        actionDispatch({ type: 'error', ...parseError(error) })
      })
      .finally(() => {
        if (cb) {
          cb(actionState)
        }
      })
  }

  const handleUpdateDashboard = (id: string, updateObj, cb?: () => void) => {
    if (actionState.isLoading) {
      throw LOADING_ERROR
    }
    actionDispatch({ type: 'loading' })
    updateDashboardMeta(id, updateObj)
      .then(() => {
        setDashboards((prev) =>
          prev.map((dashboard) => {
            if (dashboard.dashboardId !== id) {
              return dashboard
            }
            return { ...dashboard, ...updateObj }
          }),
        )
        actionDispatch({ type: 'success' })
      })
      .catch((error) => {
        actionDispatch({ type: 'error', ...parseError(error) })
      })
      .finally(() => {
        if (cb) {
          cb()
        }
      })
  }

  const handleAddNewDashboard = (
    model: NewDashboardModel,
    type: string,
    cb?: (dashboardId: string) => void,
  ) => {
    if (actionState.isLoading) {
      throw LOADING_ERROR
    }
    actionDispatch({ type: 'loading' })
    const newDashboard = {
      ...model,
      cards: [],
      filters: [],
    }
    let dashboardId
    addNewDashboard(newDashboard)
      .then((id) => {
        actionDispatch({ type: 'success' })
        dashboardId = id
      })
      .catch((error) => {
        actionDispatch({ type: 'error', ...parseError(error) })
      })
      .finally(() => {
        if (cb) {
          cb(dashboardId)
        }
      })
  }

  const cleanCardIds = (
    cards: DraggableResizableCard[] | undefined,
  ): DraggableResizableCard[] => {
    if (!cards) {
      return []
    }
    return cards.map((val) => ({ ...val, id: nanoid() }))
  }

  const handleCopyDashboard = (
    model: NewDashboardModel,
    dashboard: Dashboard,
    cb?: (dashboardId: string) => void,
  ) => {
    if (actionState.isLoading) {
      throw LOADING_ERROR
    }
    actionDispatch({ type: 'loading' })
    const newDashboard = {
      cards: cleanCardIds(dashboard?.cards),
      filters: dashboard?.filters,
      rangeFilters: dashboard?.rangeFilters || [],
      timeFilters: dashboard?.timeFilters || [],
      settings: getSettingsFieldForBackend(dashboard),
      ...model,
    }
    let dashboardId
    addNewDashboard(newDashboard)
      .then((id) => {
        actionDispatch({ type: 'success' })
        dashboardId = id
      })
      .catch((error) => {
        actionDispatch({ type: 'error', ...parseError(error) })
      })
      .finally(() => {
        if (cb) {
          cb(dashboardId)
        }
      })
  }

  const handleShareDashboard = (
    dashboardId,
    sharedUsers,
    cb?: (actionState: ActionState) => void,
  ) => {
    if (actionState.isLoading) {
      throw LOADING_ERROR
    }
    actionDispatch({ type: 'loading' })
    shareDashboard(dashboardId, sharedUsers)
      .then(() => {
        setDashboards((prev) => {
          return prev.map((dash) => {
            if (dashboardId !== dash.dashboardId) return dash
            return {
              ...dash,
              sharedUsers: [...sharedUsers.add],
            }
          })
        })
        actionDispatch({ type: 'success' })
      })
      .catch((error) => {
        actionDispatch({ type: 'error', ...parseError(error) })
      })
      .finally(() => {
        if (cb) {
          cb(actionState)
        }
      })
  }

  const handleGroupShareDashboard = (
    dashboardId,
    org,
    cb?: (actionState: ActionState) => void,
  ) => {
    if (actionState.isLoading) {
      throw LOADING_ERROR
    }
    actionDispatch({ type: 'loading' })
    shareOrgDashboard(dashboardId)
      .then(() => {
        setDashboards((prev) => {
          return prev.map((dash) => {
            if (dashboardId !== dash.dashboardId) return dash
            return {
              ...dash,
              org,
            }
          })
        })
        actionDispatch({ type: 'success' })
      })
      .catch((error) => {
        actionDispatch({ type: 'error', ...parseError(error) })
      })
      .finally(() => {
        if (cb) {
          cb(actionState)
        }
      })
  }

  const handleGroupUnShareDashboard = (
    dashboardId,
    cb?: (actionState: ActionState) => void,
  ) => {
    if (actionState.isLoading) {
      throw LOADING_ERROR
    }
    actionDispatch({ type: 'loading' })
    unShareOrgDashboard(dashboardId)
      .then(() => {
        setDashboards((prev) => {
          return prev.map((dash) => {
            if (dashboardId !== dash.dashboardId) return dash
            return {
              ...dash,
              org: null,
            }
          })
        })
        actionDispatch({ type: 'success' })
      })
      .catch((error) => {
        actionDispatch({ type: 'error', ...parseError(error) })
      })
      .finally(() => {
        if (cb) {
          cb(actionState)
        }
      })
  }

  return {
    dashboards,
    ...actionState,
    isFetchingDashboards,
    handleAddNewDashboard,
    handleCopyDashboard,
    handleDeleteDashboard,
    handleUpdateDashboard,
    handleShareDashboard,
    handleGroupShareDashboard,
    handleGroupUnShareDashboard,
  }
}

export default useDashboards
