import { ProductModuleConfig } from 'shared/types'
import Pricing, {
  PricingMenu,
  getEnhancedNotificationDescription,
} from './Main'

const productModuleConfig: ProductModuleConfig = {
  HeaderContent: PricingMenu,
  MainContent: Pricing,
  getNotificationExtraContent: getEnhancedNotificationDescription,
}

export default productModuleConfig
