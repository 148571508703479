import React from 'react'
import DashboardRoutes from 'shared/pages/DashboardRoutes'
import { RenderDashboard } from 'shared/pages/DashboardDetail'
import LongTermForecast from './LongTermForecast'
import ShortTermForecast from './ShortTermForecast'
import ShortTermPerformance from './ShortTermPerformance'
import ScenarioAnalysis from './ScenarioAnalysis'

const DASHBOARD_TYPE = 'prod_load_forecast'

const content = ({
  handleSave,
  dashboard,
  dashboardConfigItem,
}: RenderDashboard) => {
  const {
    category,
    datasource,
    filters,
    weatherStations,
    dynamicColumnsConfig,
    timeInterval,
    rangeFilters,
  } = dashboardConfigItem
  switch (category.name) {
    case 'longTermForecast':
      return (
        <LongTermForecast
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          filtersDefinitions={filters}
          timeInterval={timeInterval}
          weatherStations={weatherStations || []}
          savedDashboardInfo={dashboard}
          rangeFiltersDefinition={rangeFilters}
        />
      )
    case 'shortTermForecast':
      return (
        <ShortTermForecast
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          filtersDefinitions={filters}
          weatherStations={weatherStations || []}
          timeInterval={timeInterval}
          savedDashboardInfo={dashboard}
          rangeFiltersDefinition={rangeFilters}
          dynamicColumnsConfig={dynamicColumnsConfig}
        />
      )
    case 'shortTermPerformance':
      return (
        <ShortTermPerformance
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          filtersDefinitions={filters}
          weatherStations={weatherStations || []}
          timeInterval={timeInterval}
          savedDashboardInfo={dashboard}
          rangeFiltersDefinition={rangeFilters}
          dynamicColumnsConfig={dynamicColumnsConfig}
        />
      )
    case 'scenarioAnalysis':
      return (
        <ScenarioAnalysis
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          scenariosConfig={dynamicColumnsConfig}
          weatherStations={weatherStations || []}
          filtersDefinitions={filters}
          timeInterval={timeInterval}
          savedDashboardInfo={dashboard}
          rangeFiltersDefinition={rangeFilters}
        />
      )
    case 'dataInsights':
      return (
        <ScenarioAnalysis
          key={dashboard.dashboardId}
          onSave={handleSave}
          datasource={datasource}
          scenariosConfig={dynamicColumnsConfig}
          weatherStations={weatherStations || []}
          filtersDefinitions={filters}
          timeInterval={timeInterval}
          savedDashboardInfo={dashboard}
          rangeFiltersDefinition={rangeFilters}
        />
      )
    default:
      return (
        <div>
          <p>{`Category "${category.label}" not is not supported by any page on the Demand component`}</p>
        </div>
      )
  }
}

/**
 * Demand Dashboard page component. It takes care of the
 * routing.
 */
const DemandDashboard = () => {
  return <DashboardRoutes content={content} dashboardType={DASHBOARD_TYPE} />
}

export default DemandDashboard
